<h5>
  {{ historyData | historyChangeSummary: historySubject }}
  <button mat-dialog-close type="button" class="orms-close-button"></button>
</h5>
<div class="history-content">
  <table
    class="history-list-container"
    *ngIf="standardElements.length"
    attr.aria-label="{{ 'general.history' | translate }}"
    role="presentation"
  >
    <ng-container *ngFor="let details of standardElements">
      <tr class="history-row">
        <td class="history-name">{{ details.itemName | translate | fromCamelCase }}</td>
        <td class="history-value">
          {{ details.newValue | formatHistoryValue: details.itemName }}
        </td>
      </tr>
    </ng-container>
  </table>
  <ng-container *ngFor="let details of richTextBoxes">
    <div class="rich-text-box" [class.first-row]="!standardElements.length">
      <div class="history-name">{{ details.itemName | fromCamelCase }}</div>
      <div class="rich-text-content" [innerHtml]="details.newValue"></div>
    </div>
  </ng-container>
</div>
