import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/root-store/app-state';
import { RequestType } from '../change-request-cart-state/cart-item.model';
import {
  getFullCRForCurrentContext,
  getMoveRequests,
} from '../change-request-cart-state/change-requests/change-request.selectors';
import { ChangeRequestSubmitComponent } from '../change-request-submit/change-request-submit.component';

@Component({
  selector: 'app-submit-request-snack-bar',
  templateUrl: './submit-request-snack-bar.component.html',
  styleUrls: ['./submit-request-snack-bar.component.scss'],
})
export class SubmitRequestSnackBarComponent implements OnInit, OnDestroy {
  changesCount = 0;
  dialogOpened = false;
  @Input() changesCountSelector: any;
  @Input() removeChangesAction: any;
  @Input() snackBarTitle: string;
  @Input() requestType: RequestType;
  private subscription = new Subscription();

  constructor(
    private dialog: MatLegacyDialog,
    private store$: Store<AppState>,
  ) {}

  ngOnInit() {
    this.subscription.add(this.store$.select(this.changesCountSelector).subscribe((x) => (this.changesCount = x)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit(): void {
    const mrData = {
      title: 'changeRequest.moveRequestSummaryForSubmission',
      errorHeader: 'moveDeliverableModal.moveRequestFailed',
      placeholder: 'changeRequest.enterTheTitleOfYourRequest',
      getRequestSelector: getMoveRequests,
      requestType: this.requestType,
    };
    const crData = {
      title: 'changeRequest.changeRequestSummaryForSubmission',
      errorHeader: 'changeRequest.failed',
      placeholder: 'changeRequest.enterTheTitleOfYourRequest',
      getRequestSelector: getFullCRForCurrentContext,
      requestType: this.requestType,
    };

    const dialogRef = this.dialog.open(ChangeRequestSubmitComponent, {
      data: this.requestType === RequestType.Change ? crData : mrData,
      panelClass: 'orms-change-request-submit',
      disableClose: true,
    });

    this.dialogOpened = true;

    dialogRef.afterClosed().subscribe(() => {
      this.dialogOpened = false;
    });
  }

  cancel(): void {
    this.store$.dispatch(this.removeChangesAction());
  }
}
