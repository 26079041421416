import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-step-footer',
  templateUrl: './multi-step-footer.component.html',
  styleUrls: ['./multi-step-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiStepFooterComponent {
  @Input() nextStepLabel: string;
  @Input() cancelLabel: string;
  @Input() previousStepLabel: string;
  @Input() nextStepDisabled: boolean;
  @Input() errorMessage: string;

  @Output() nextStepClick = new EventEmitter<void>();
  @Output() previousStepClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();
}
