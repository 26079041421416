import { Pipe, PipeTransform } from '@angular/core';
import { CodeTypes, NodeTypeMap } from 'src/app/common-models/node-types/node-types';

@Pipe({
  name: 'navigateToHierarchy',
})
export class NavigateToHierarchyPipe implements PipeTransform {
  transform(entityId: number, entityTypeCode: CodeTypes, projectId: number): string {
    return `/project-management/${projectId}/${NodeTypeMap.get(entityTypeCode)}/${entityId}/detail`;
  }
}
