<ng-container *ngIf="detailData$ | async as detailData">
  <app-budged-section [detailData]="detailData" [underChangeControl]="underChangeControl$ | async"></app-budged-section>
  <hr />
  <app-info-section
    [detailData]="detailData"
    [editableFields]="detailData.editableFields"
    [entityType]="nodeType$ | async"
  ></app-info-section>
  <hr />
  <app-intent-section
    [detailData]="detailData"
    [editableFields]="detailData.editableFields"
    [entityId]="nodeId$ | async"
  ></app-intent-section>
  <hr />
  <app-package-relationships
    [packageHierarchyId]="detailData.functionalHierarchyId"
    [startDate]="detailData.planStartDate"
    [endDate]="detailData.planEndDate"
  ></app-package-relationships>
</ng-container>
<app-spinner *ngIf="loading$ | async" [absoluteOverlay]="true"></app-spinner>
