import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChangeRequestPackage } from '../cart-item.model';
import * as fromState from '../state';
import { ChangeRequestCartState } from '../state';
import { crPackageAdapter } from './package.reducer';

export const changeRequestState = createFeatureSelector<fromState.ChangeRequestCartState>('cartItems');

const { selectEntities, selectAll, selectTotal } = crPackageAdapter.getSelectors();

export const selectPackageEntities = selectEntities;
export const selectAllPackages = selectAll;
export const selectPackagesTotal = selectTotal;

const crPackagesStateSelector = createSelector(changeRequestState, (state) => state.packages);

const crPackagesEntitiesSelector = createSelector(crPackagesStateSelector, selectPackageEntities);

export const checkPackageChangeRequestExists = (props: { functionalHierarchyId: number }) =>
  createSelector(changeRequestState, (state: ChangeRequestCartState) => {
    return !!Object.values(state.packages.entities).find(
      (p) => p.functionalHierarchyId === props.functionalHierarchyId,
    );
  });

export const getCrPackage = (props: { functionalHierarchyId: number }) =>
  createSelector(crPackagesEntitiesSelector, (entities: Dictionary<ChangeRequestPackage>) => {
    return entities[props.functionalHierarchyId];
  });
