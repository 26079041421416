import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

if (environment.production) {
  enableProdMode();
}

// The chart annotations plugin has to be registered
Chart.register(annotationPlugin);

// Registering Syncfusion license key
registerLicense(environment.syncfusion_license_key);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
