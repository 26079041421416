import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-cell-simple',
  templateUrl: './ag-cell-simple.component.html',
  styleUrls: ['./ag-cell-simple.component.scss'],
})
export class AgCellSimpleComponent {
  value: any;

  agInit(params: ICellRendererParams): void {
    this.value = params.formatValue ? params.formatValue(params.value) : params.value;
  }

  refresh(params: ICellRendererParams) {
    this.value = params.formatValue ? params.formatValue(params.value) : params.value;
    return false;
  }
}
