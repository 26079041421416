import { createAction, props } from '@ngrx/store';
import { ChangeRequestPackage } from '../cart-item.model';

export enum ChangeRequestPackageActions {
  NOOP = '[Change Request Package] NOOP',
  ADD_PACKAGE_CHANGE_TO_CART = '[Change Request Package] Add Package Change',
  REMOVE_PACKAGE_CHANGE_FROM_CART = '[Change Request Package] Remove Package Change',
  REMOVE_ALL_PACKAGE_CHANGES_FOR_FUNCTION = '[Change Request Package] Remove All Package Changes For Function',
  REMOVE_ALL_PACKAGE_MOVES_FOR_FUNCTION = '[Change Request Package] Remove All Package Moves For Function',
}

export const addPackageChange = createAction(
  ChangeRequestPackageActions.ADD_PACKAGE_CHANGE_TO_CART,
  props<{ crPackage: ChangeRequestPackage }>(),
);
export const removePackageChange = createAction(
  ChangeRequestPackageActions.REMOVE_PACKAGE_CHANGE_FROM_CART,
  props<{ packageId: number }>(),
);

export const removeAllPackageChangesForFunction = createAction(
  ChangeRequestPackageActions.REMOVE_ALL_PACKAGE_CHANGES_FOR_FUNCTION,
);

export const removeAllPackageMovesForFunction = createAction(
  ChangeRequestPackageActions.REMOVE_ALL_PACKAGE_MOVES_FOR_FUNCTION,
);

export const noOP = createAction(ChangeRequestPackageActions.NOOP);
