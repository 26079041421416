<ng-container *ngIf="favoritePackages; else loadingFavorites">
  <mat-accordion class="orms-expansion-panel">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'general.favoritePackages' | translate }} ({{
            !favoritePackages.length ? firstFivePackages.length : favoritePackages.length
          }})
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table
        *ngIf="firstFivePackages.length"
        mat-table
        [dataSource]="firstFivePackages"
        attr.aria-label="{{ 'general.favoritePackages' | translate }}"
      >
        <ng-container matColumnDef="packageCode">
          <th id="favoritePackages_packageCode" mat-header-cell class="orms-dl-120" *matHeaderCellDef>
            {{ 'general.code' | translate }}
          </th>
          <td mat-cell class="orms-dl-120" title="{{ element.packageCode }}" *matCellDef="let element">
            <div class="project-name-wrapper">
              <div class="project-name">
                <a (click)="navigateTo(element)">{{ element.packageCode | ellipsis: 15 }}</a>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th id="favoritePackages_title" mat-header-cell class="orms-dl-150" *matHeaderCellDef>
            {{ 'general.title' | translate }}
          </th>
          <td mat-cell class="orms-dl-150" title="{{ element.title }}" *matCellDef="let element">
            <div class="project-name-wrapper">
              <div class="project-name">
                <a (click)="navigateTo(element)">{{ element.title | ellipsis: 60 }}</a>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th id="favoritePackages_status" mat-header-cell class="orms-dl-120" *matHeaderCellDef>
            {{ 'general.status' | translate }}
          </th>
          <td mat-cell class="orms-dl-120" *matCellDef="let element">
            <app-status-chip [statusDetails]="element.statusDetails"></app-status-chip>
          </td>
        </ng-container>
        <ng-container matColumnDef="responsible">
          <th id="favoritePackages_responsible" mat-header-cell class="orms-dl-80" *matHeaderCellDef>
            {{ 'general.responsible' | translate }}
          </th>
          <td mat-cell class="orms-dl-80" *matCellDef="let element">
            {{ element.responsible }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="orms-deliverable-element-row"></tr>
      </table>
      <div *ngIf="favoritePackages.length > firstFiveItems">
        <mat-expansion-panel class="mat-elevation-z0 show-more" [expanded]="false" (opened)="showMore()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'general.showMore' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </div>
      <div *ngIf="firstFivePackages && !firstFivePackages.length" class="no-packages">
        {{ 'general.noPackages' | translate }}
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<ng-template #loadingFavorites>
  <mat-accordion class="orms-expansion-panel">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'general.favoritePackages' | translate }} (?) </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
  <app-spinner></app-spinner>
</ng-template>
