import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, mergeMap, pluck, switchMap, tap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { Detail } from '../../common/navigation-tabs/detail/detail.model';
import { DetailService } from '../../common/navigation-tabs/detail/detail.service';
import { SignalRService } from '../../signalr/signalr.service';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.scss'],
})
export class PackageDetailComponent implements OnInit {
  detailData$: Observable<Detail>;
  nodeId$: Observable<number>;
  nodeType$: Observable<EntityTypes>;
  underChangeControl$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store$: Store<AppState>,
    private detailService: DetailService,
    private route: ActivatedRoute,
    private signalrService: SignalRService,
  ) {}

  ngOnInit(): void {
    this.nodeId$ = this.store$.select(routerSelectors.getIdSelector);

    this.underChangeControl$ = combineLatest([
      this.store$.select(routerSelectors.getIdSelector),
      this.signalrService.kpiUpdated(),
    ]).pipe(
      filter(([id, nodeChangedIds]) => nodeChangedIds.includes(id)),
      switchMap(([id]) => this.detailService.getDetailData(id)),
      map((x) => x.underChangeControl),
    );

    this.detailData$ = this.store$.select(routerSelectors.getIdSelector).pipe(
      filter((id) => !!id),
      tap(() => this.loading$.next(true)),
      mergeMap((id) => this.detailService.getDetailData(id)),
      mergeMap((details) =>
        this.route.data.pipe(
          pluck('type'),
          map((type) => ({ ...details, type })),
        ),
      ),
      tap(() => this.loading$.next(false)),
    );

    this.nodeType$ = this.route.data.pipe(pluck('type'));
  }
}
