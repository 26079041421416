import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-editable-textbox',
  templateUrl: './editable-textbox.component.html',
  styleUrls: ['./editable-textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableTextboxComponent {
  @Input() invalid = false;
  @Input() value: string | number;
  @Output() valueChange = new EventEmitter<string>();
  @Input() editable: boolean;
  @Input() maxLength = 100;
  @Input() customValidator: (inputValue: string) => boolean = () => true;

  notifyValueChange(event): void {
    if (this.customValidator(event.target.value)) {
      this.valueChange.emit(event);
    }
  }
}
