<div class="orms-cr-submission-form small-container">
  <h2>
    {{ 'projectImport.projectImport' | translate }}
  </h2>
  <h5>{{ 'projectImport.plsProvideTitleJustification' | translate }}</h5>

  <form class="change-request-justification-form" autocomplete="off">
    <div class="section-label">{{ 'projectImport.title' | translate }}</div>
    <div class="custom-textbox">
      <input
        cdkFocusInitial
        autocomplete="off"
        type="text"
        class="form-control shadow-input orms-simple-input"
        maxlength="150"
        [placeholder]="'projectImport.enterTitle' | translate"
        [formControl]="getChangeRequestControl('title')"
        [class.is-invalid]="showChangeRequestControlInvalidState('title')"
      />
      <div class="alert">
        <ng-container *ngIf="changeRequestForm.get('title').invalid && changeRequestForm.get('title').touched">
          <div *ngIf="changeRequestForm.get('title').errors?.['required']">
            {{ 'projectImport.titleRequired' | translate }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="section-label">{{ 'projectImport.justification' | translate }}</div>
    <div class="editor-container">
      <editor
        [init]="editorConfig"
        class="orms-editor justification-editor"
        [formControl]="getChangeRequestControl('description')"
        [class.is-invalid]="showChangeRequestControlInvalidState('description')"
      >
      </editor>
    </div>
    <div class="alert">
      <ng-container
        *ngIf="changeRequestForm.get('description').invalid && changeRequestForm.get('description').touched"
      >
        <div *ngIf="changeRequestForm.get('description').errors?.['required']">
          {{ 'projectImport.justificationRequired' | translate }}
        </div>
      </ng-container>
    </div>
    <app-checkbox
      class="auto-approve-checkbox"
      [control]="getChangeRequestControl('autoApprove')"
      [label]="'projectImport.autoApproveCRs' | translate"
    >
    </app-checkbox>
  </form>

  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        class="orms-button orms-button-primary submit-button"
        type="button"
        [disabled]="isSubmitDisabled"
        (click)="submitChangeRequest()"
      >
        {{ 'general.continue' | translate }}
      </button>
      <button class="orms-button orms-button-secondary" type="button" (click)="cancel()">
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
