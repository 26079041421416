import { createAction, props } from '@ngrx/store';

export enum CurrentContextFunctionActions {
  SET_CURRENT_CONTEXT_FUNCTION = '[Current Context Function] Set Current Context Function',
  SET_CURRENT_CONTEXT_FUNCTION_FOR_DELIVERABLE = '[Current Context Function] Set Current Context Function For Deliverable',
  SET_CURRENT_CONTEXT_FUNCTION_FOR_PACKAGE = '[Current Context Function] Set Current Context Function For Package',
}

export const setCurrentContextFunction = createAction(
  CurrentContextFunctionActions.SET_CURRENT_CONTEXT_FUNCTION,
  props<{ functionHierarchyId: number }>(),
);

export const setCurrentContextFunctionForDeliverable = createAction(
  CurrentContextFunctionActions.SET_CURRENT_CONTEXT_FUNCTION_FOR_DELIVERABLE,
  props<{ packageHierarchyId: number }>(),
);

export const setCurrentContextFunctionForPackage = createAction(
  CurrentContextFunctionActions.SET_CURRENT_CONTEXT_FUNCTION_FOR_PACKAGE,
  props<{ packageHierarchyId: number }>(),
);
