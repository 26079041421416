import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isString } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  error(message: string, title?: string, dismiss = false): void {
    const translatedMessage = this.translateService.instant(isString(message) ? message : 'general.unknownError');
    const translatedTitle = this.translateService.instant(title ? title : 'general.error');
    this.toastr.error(translatedMessage, translatedTitle, {
      disableTimeOut: dismiss,
      closeButton: dismiss,
    });
  }

  warning(message: string, title?: string, dismiss = false): void {
    const translatedMessage = this.translateService.instant(message);
    const translatedTitle = this.translateService.instant(title ? title : 'general.warning');
    this.toastr.success(translatedMessage, translatedTitle, {
      disableTimeOut: dismiss,
      closeButton: dismiss,
    });
  }

  success(message: string, title?: string, dismiss = false): void {
    const translatedMessage = this.translateService.instant(message);
    const translatedTitle = this.translateService.instant(title ? title : 'general.success');
    this.toastr.success(translatedMessage, translatedTitle, {
      disableTimeOut: dismiss,
      closeButton: dismiss,
    });
  }
}
