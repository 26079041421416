import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginFailedComponent } from '../auth/login-failed/login-failed.component';

import { LoginComponent } from '../auth/login/login.component';

import { EmptyComponentComponent } from '../empty-component/empty-component.component';

import { PrintNodeComponent } from '../print-node/print-node.component';

import { PrintPackageComponent } from '../print-package/print-package.component';

import { HomePageGuard } from '../project-management/home-page/home-page.guard';

import { TermsPrivacyComponent } from '../terms-privacy/terms-privacy.component';

import { AccessDeniedComponent } from './access-denied/access-denied.component';

import { NotFoundComponent } from './not-found/not-found.component';

import { UnlicensedComponent } from './unlicensed/unlicensed.component';

const routes: Routes = [
  {
    // Angular 16 using @azure/msal-angular 3.x is producing a route with
    //     /state%3D<some state value>
    // which is not matched by the path: 'state' entry (below), thus the need for a custom matcher to deal with it.
    matcher: (url) => {
      if (url[0].path.slice(0, 5) == 'state') {
        return {
          consumed: url,
        };
      }
      return null;
    },
    redirectTo: '',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'terms&privacy',
    component: TermsPrivacyComponent,
  },
  {
    path: '402',
    component: UnlicensedComponent,
  },
  {
    path: '403',
    component: AccessDeniedComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'error',
    component: LoginFailedComponent,
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: 'print/deliverable/:id',
    component: PrintNodeComponent,
  },
  {
    path: 'print/package/:id',
    component: PrintPackageComponent,
  },
  {
    path: 'redirect',
    canActivate: [HomePageGuard],
    component: EmptyComponentComponent,
  },
  {
    path: 'code',
    redirectTo: '',
  },
  {
    path: 'state',
    redirectTo: '',
  },
  {
    path: 'project-setup',
    loadChildren: () => import('../project-setup/project-setup.module').then((m) => m.ProjectSetupModule),
  },
  {
    path: 'advanced-search',
    loadChildren: () => import('../advanced-search/advanced-search.module').then((m) => m.AdvancedSearchModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  providers: [HomePageGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
