<div class="confirmation-summary" *ngIf="(isLoading$ | async) === false">
  <ng-container
    *ngIf="projectImportResult === ProcessingResult.Success"
    [ngTemplateOutlet]="confirmationIconAndText"
    [ngTemplateOutletContext]="{
      iconClass: 'success-icon',
      message: 'projectImport.projectImportSuccessfullyCompleted'
    }"
  >
  </ng-container>
  <ng-container
    *ngIf="projectImportResult === ProcessingResult.Failure"
    [ngTemplateOutlet]="confirmationIconAndText"
    [ngTemplateOutletContext]="{
      iconClass: 'error-icon',
      message: 'projectImport.projectImportCompletedWithFailure'
    }"
  >
  </ng-container>
</div>

<ng-template #confirmationIconAndText let-iconClass="iconClass" let-message="message">
  <div [ngClass]="iconClass"></div>
  <div class="message">
    {{ message | translate }}
  </div>
  <div class="errorlist-container">
    <ul>
      <li *ngFor="let err of executionErrors">{{ err.errorRef | translate: err.params }}</li>
    </ul>
  </div>
</ng-template>

<app-spinner *ngIf="isLoading$ | async"></app-spinner>

<app-multi-step-footer
  *ngIf="(isLoading$ | async) === false"
  [nextStepLabel]="'general.done'"
  (nextStepClick)="nextStepClick.emit()"
>
</app-multi-step-footer>
