import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { MAT_LEGACY_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/legacy-core';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { EmptyComponentComponent } from './empty-component/empty-component.component';
import { HeaderModule } from './header/header.module';
import { ApiVersionInterceptor } from './interceptors/api-version-interceptor';
import { CurrentUserInfoInterceptor } from './interceptors/current-user-info.interceptor';
import { CacheInterceptor } from './interceptors/no-cache-interceptor';
import { PrintNodeComponent } from './print-node/print-node.component';
import { PrintPackageComponent } from './print-package/print-package.component';
import { ProjectManagementModule } from './project-management/project-management.module';
import { CustomSerializer } from './root-store/custom-route-serializer';
import { RootStoreModule } from './root-store/root-store.module';
import { SharedControlsModule } from './shared-controls/shared-controls.module';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { AccessDeniedInterceptor } from './shared/access-denied/access-denied.interceptor';
import { AppRoutingModule } from './shared/app-routing.module';
import { ErrorComponent } from './shared/error/error.component';
import { GlobalRippleOptions } from './shared/global-ripple-options/global-ripple-options.service';
import { MsalFunctions } from './shared/msal-config/msal-functions';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { NotFoundInterceptor } from './shared/not-found/not-found.interceptor';
import { AppConfigService } from './shared/services/app.config.service';
import { globalToastOptions } from './shared/toastr-global-options/toastr-global-options';
import { MissingTranslationsHandler } from './shared/translation/missing-translations-handler';
import { TranslateLanguageLoader } from './shared/translation/translate.language.loader';
import { UiShowcaseModule } from './shared/ui-showcase/ui-showcase.module';
import { UnlicensedComponent } from './shared/unlicensed/unlicensed.component';
import { UnlicensedInterceptor } from './shared/unlicensed/unlicensed.interceptor';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TermsPrivacyComponent } from './terms-privacy/terms-privacy.component';

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    UnlicensedComponent,
    NotFoundComponent,
    ErrorComponent,
    TermsPrivacyComponent,
    EmptyComponentComponent,
    SidebarComponent,
    PrintNodeComponent,
    PrintPackageComponent,
  ],
  imports: [
    CommonModule,
    MsalModule,
    ProjectManagementModule,
    UiShowcaseModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RootStoreModule,
    HeaderModule,
    MatSidenavModule,
    MatLegacySnackBarModule,
    SharedControlsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationsHandler,
      },
    }),
    ToastrModule.forRoot(globalToastOptions),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MsalFunctions.MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalFunctions.MSALGuardConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalFunctions.MSALInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessDeniedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnlicensedInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: NotFoundInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CurrentUserInfoInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [AppConfigService, TranslateService, Injector],
      multi: true,
    },
    { provide: MAT_LEGACY_RIPPLE_GLOBAL_OPTIONS, useExisting: GlobalRippleOptions },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

export function initializer(appConfigService: AppConfigService, translate: TranslateService, injector: Injector): any {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    const defaultLang = 'en';
    translate.setDefaultLang(defaultLang);
    await translate.use(defaultLang).toPromise();
    return appConfigService.load();
  };
}
