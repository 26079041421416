import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { User } from 'src/app/common-models/user.model';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { PermissionType } from '../permission-type';
import { PermissionsService } from '../permissions.service';

@Component({
  selector: 'app-permissions-header',
  templateUrl: './permissions-header.component.html',
  styleUrls: ['./permissions-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsHeaderComponent implements OnInit, OnDestroy {
  users$: Observable<User[]>;
  userInput$ = new Subject<string>();
  loading$ = new BehaviorSubject<boolean>(false);
  selectedPeople = [];
  form = new UntypedFormGroup({
    selectedUsers: new UntypedFormControl([]),
    type: new UntypedFormControl(PermissionType.canRead),
  });
  typeControl = new UntypedFormControl(PermissionType.canRead);
  PermissionType = PermissionType;
  @Input() projectId: number;
  @Output() grantPermission = new EventEmitter<{
    userIds: number[];
    type: PermissionType;
  }>();

  private subscription = new Subscription();

  constructor(
    private usersService: UsersService,
    private permissionsService: PermissionsService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.loadUsers();
    const sub = this.permissionsService.clearUsersFromSearch.subscribe((userId) => {
      const { selectedUsers } = this.form.value;
      this.form.get('selectedUsers').setValue(selectedUsers.filter((user: User) => user.userId !== userId));
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadUsers(): void {
    this.users$ = concat(
      of([]),
      this.userInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.loading$.next(true)),
        switchMap((term) =>
          this.usersService.getUsersForSearch(this.projectId, term).pipe(
            catchError((err: HttpErrorResponse) => {
              showHttpErrorResponse(this.notificationService, err);
              return of([]);
            }),
            tap(() => this.loading$.next(false)),
          ),
        ),
      ),
    );
  }

  onSubmit(): void {
    const { selectedUsers, type } = this.form.value;
    if (!selectedUsers.length) {
      return;
    }
    this.grantPermission.emit({
      userIds: selectedUsers.map((user: User) => user.userId),
      type,
    });
  }
}
