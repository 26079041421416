import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { CurrentUser } from '../shared/current-user/currentUser';
import { AppState } from './app-state';
import {
  clearCurrentUser,
  includeCancelledItems,
  setCurrentUserWithPortfolioPermission,
  setCurrentUsersPortfolio,
} from './root-store.actions';

export interface CurrentUserState {
  profile: CurrentUser;
  canManagePortfolio: boolean;
  includeCancelledItems: boolean;
}

const initialUserState: CurrentUserState = {
  profile: null,
  canManagePortfolio: false,
  includeCancelledItems: false,
};

export const currentUserReducer = createReducer(
  initialUserState,
  on(setCurrentUserWithPortfolioPermission, (state, { profile, canManagePortfolio: canManagePortfolio }) => {
    return {
      ...state,
      profile,
      canManagePortfolio,
    };
  }),
  on(setCurrentUsersPortfolio, (state, { currentPortfolioID, currentPortfolioTitle, canManagePortfolio }) => {
    const profile = { ...state.profile };
    profile.lastPortfolioId = currentPortfolioID;
    profile.lastPortfolioTitle = currentPortfolioTitle;
    return {
      ...state,
      profile,
      canManagePortfolio,
    };
  }),
  on(includeCancelledItems, (state, { includeCancelledItems }) => {
    return {
      ...state,
      includeCancelledItems,
    };
  }),
  on(clearCurrentUser, (state) => {
    return {
      ...state,
      profile: null,
      canManagePortfolio: false,
      includeCancelledItems: false,
    };
  }),
);

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  currentUser: currentUserReducer,
};
