<ng-container [formGroup]="changeRequestForm">
  <div>
    <h6 class="orms-label orms-label-margin">
      {{ 'general.budgetHours' | translate }}
      <app-cr-warning [cr]="cr" currentKey="oldHours" requestedKey="newHours" type="number"></app-cr-warning>
    </h6>
    <input
      autocomplete="off"
      class="form-control orms-shadow-input"
      [class.is-invalid]="showCRInvalidState('hours')"
      type="number"
      formControlName="hours"
      placeholder="0"
      [title]="changeRequestForm.get('hours').value"
    />
  </div>
  <div>
    <h6 class="orms-label orms-label-margin">
      {{ 'general.budgetCost' | translate }}
      <app-cr-warning [cr]="cr" currentKey="oldCost" requestedKey="newCost" type="number"></app-cr-warning>
    </h6>
    <div class="input-group">
      <div class="input-group-prepend orms-shadow-input-prepend">
        <div class="input-group-text">$</div>
      </div>
      <input
        autocomplete="off"
        class="form-control orms-shadow-input"
        type="number"
        [class.is-invalid]="showCRInvalidState('cost')"
        formControlName="cost"
        [title]="changeRequestForm.get('cost').value"
      />
    </div>
  </div>
  <div>
    <h6 class="orms-label orms-label-margin">
      {{ 'general.curveProfile' | translate }}
      <app-cr-warning
        [cr]="cr"
        currentKey="oldCurveProfileCode"
        requestedKey="newCurveProfileCode"
        type="curve-profile"
        [curveProfiles]="curveProfiles$ | async"
      ></app-cr-warning>
    </h6>
    <div class="mat-select-wrapper">
      <mat-select class="orms-select" panelClass="orms-select-options" formControlName="curveProfileCode">
        <mat-option *ngFor="let w of curveProfiles$ | async" [value]="w.code">
          {{ w.description }}
        </mat-option>
      </mat-select>
    </div>
  </div>
</ng-container>
