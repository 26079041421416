import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { TreeSelectors } from '../../tree-navigation/tree-state/tree.selectors';
import { DeliverableSelectors } from '../deliverable-state/deliverable.selectors';

@Component({
  selector: 'app-deliverable-detail',
  templateUrl: './deliverable-detail.component.html',
  styleUrls: ['./deliverable-detail.component.scss'],
})
export class DeliverableDetailComponent implements OnInit {
  deliverableDetail$: Observable<DeliverableDetail>;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.deliverableDetail$ = this.store$.select(routerSelectors.getIdSelector).pipe(
      tap(() => this.loading$.next(true)),
      switchMap((deliverableId) =>
        this.store$.select(
          DeliverableSelectors.getDeliverableDetailsById({
            id: deliverableId,
          }),
        ),
      ),
      filter((deliverableDetails) => deliverableDetails !== null),
      switchMap((details) => {
        return this.store$
          .select(
            TreeSelectors.getTreeNodeByFunctionalHierarchyId({
              functionalHierarchyId: details.packageFunctionalHierarchyId,
            }),
          )
          .pipe(
            filter((p) => !!p.packageId),
            take(1),
            map(({ underChangeControl, status }) => {
              return {
                ...details,
                underChangeControl,
                packageStatus: status,
              };
            }),
          );
      }),
      tap(() => this.loading$.next(false)),
    );
  }
}
