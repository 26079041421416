import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { clearProjectRelatedData } from 'src/app/root-store/root-store.actions';
import { ChangeRequestDeliverable, MoveRequestDeliverable } from '../cart-item.model';
import * as actions from './crDeliverable.actions';

export const crDeliverableAdapter: EntityAdapter<ChangeRequestDeliverable> =
  createEntityAdapter<ChangeRequestDeliverable>({
    selectId: (model) => model.deliverableId.toString(),
    sortComparer: (a: ChangeRequestDeliverable, b: ChangeRequestDeliverable): number =>
      b.deliverableId.toString().localeCompare(a.deliverableId.toString()),
  });

export const ChangeRequestDeliverableState = crDeliverableAdapter.getInitialState({});

export const ChangeRequestDeliverableReducer = createReducer(
  ChangeRequestDeliverableState,
  on(actions.addDeliverableChange, (state, { crDeliverable }) => {
    return crDeliverableAdapter.upsertOne(crDeliverable, state);
  }),
  on(actions.removeDeliverableChange, (state, { deliverableId }) => {
    return crDeliverableAdapter.removeOne(deliverableId.toString(), state);
  }),
  on(actions.removeAllDeliverableChangesForFunction, (state) => {
    return crDeliverableAdapter.removeAll(state);
  }),
  on(clearProjectRelatedData, () => {
    return crDeliverableAdapter.getInitialState({});
  }),
);

export const mrDeliverableAdapter: EntityAdapter<MoveRequestDeliverable> = createEntityAdapter<MoveRequestDeliverable>({
  selectId: (model) => model.deliverableId.toString(),
  sortComparer: (a: MoveRequestDeliverable, b: MoveRequestDeliverable): number =>
    b.deliverableId.toString().localeCompare(a.deliverableId.toString()),
});

export const MoveRequestDeliverableState = mrDeliverableAdapter.getInitialState({});

export const MoveRequestDeliverableReducer = createReducer(
  MoveRequestDeliverableState,
  on(actions.addDeliverableMoveRequest, (state, { mrDeliverable }) => {
    return mrDeliverableAdapter.upsertOne(mrDeliverable, state);
  }),
  on(actions.removeDeliverableMoveRequest, (state, { deliverableId }) => {
    return mrDeliverableAdapter.removeOne(deliverableId.toString(), state);
  }),
  on(actions.removeAllDeliverableMoveChangesForFunction, (state) => {
    return mrDeliverableAdapter.removeAll(state);
  }),
  on(clearProjectRelatedData, () => {
    return mrDeliverableAdapter.getInitialState({});
  }),
);
