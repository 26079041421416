import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-dialogue',
  templateUrl: './confirmation-dialogue.component.html',
  styleUrls: ['./confirmation-dialogue.component.scss'],
})
export class ConfirmationDialogueComponent {
  submissionInProgress = false;
  constructor(
    public dialogRef: MatLegacyDialogRef<ConfirmationDialogueComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      message: string;
      title: string;
      callBack: () => Observable<any>;
    },
  ) {}

  confirm(): void {
    this.submissionInProgress = true;
    this.data
      .callBack()
      .pipe(finalize(() => (this.submissionInProgress = false)))
      .subscribe({
        next: () => this.dialogRef.close(),
        error: () => this.dialogRef.close(),
        complete: () => this.dialogRef.close(),
      });
  }
}
