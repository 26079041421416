<div [ngClass]="{ bordered: bordered }">
  <mat-form-field class="orms-datepicker-shadow-input" [hideRequiredMarker]="true">
    <mat-label></mat-label>
    <input
      [placeholder]="placeholder | translate"
      autocomplete="off"
      matInput
      [matDatepicker]="datePicker"
      [formControl]="control"
      (click)="datePicker.open()"
      [class.is-invalid]="isInvalid"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker" class="mat-suffix-focus-overlay"></mat-datepicker-toggle>
    <mat-datepicker panelClass="orms-datepicker" [calendarHeaderComponent]="datepickerActionsComponent" #datePicker>
    </mat-datepicker>
  </mat-form-field>
</div>
