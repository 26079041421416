import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectMenuAction } from '../header/menu-action';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent {
  @Input() items: ProjectMenuAction[];
  @ViewChild('buildMenu', { static: true }) public buildMenu;

  constructor(public router: Router) {}
}
