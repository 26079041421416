import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { isEmpty } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

export interface InputDialogData {
  title: string;
  callback: (value: string) => Observable<any>;
  placeholder?: string;
  submitLabel?: string;
  cancelLabel?: string;
  maxlength?: number;
  valueValidator?: (value: string) => boolean;
}

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit, OnDestroy {
  value = '';
  private submissionInProgress = false;
  private dialogSub: Subscription;

  constructor(
    public dialogRef: MatLegacyDialogRef<InputDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: InputDialogData,
  ) {}

  ngOnInit(): void {
    this.dialogSub = this.dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        if (
          !isEmpty(this.value) &&
          !this.submissionInProgress &&
          (this.data.valueValidator == null || this.data.valueValidator(this.value))
        ) {
          this.executeCallback();
        } else {
          this.dialogRef.close();
        }
      });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (
      !isEmpty(this.value) &&
      event.key === 'Enter' &&
      !event.shiftKey &&
      (this.data.valueValidator == null || this.data.valueValidator(this.value))
    ) {
      this.executeCallback();
    }

    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  executeCallback(): void {
    this.submissionInProgress = true;
    this.data
      .callback(this.value)
      .pipe(finalize(() => (this.submissionInProgress = false)))
      .subscribe(() => this.dialogRef.close());
  }

  get isDisabled(): boolean {
    return (
      this.submissionInProgress ||
      !this.value.trim().length ||
      (this.data.valueValidator != null && !this.data.valueValidator(this.value))
    );
  }

  ngOnDestroy(): void {
    this.dialogSub?.unsubscribe();
  }
}
