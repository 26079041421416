import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CodeTypes, TreeLevelTypes } from 'src/app/common-models/node-types/node-types';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { StatusCode, StatusCodesMap } from '../../../shared-controls/status/statusCodesMap';
import { TreeNode } from '../tree-structure.model';
import * as fromTree from './tree.reducer';

export const selectTreeState = createFeatureSelector<fromTree.TreeState>('navigationTree');

const selectAllTreeEntities = createSelector(selectTreeState, fromTree.selectTreeNodeEntities);

const getProjectHierarchyRules = createSelector(selectTreeState, (state: fromTree.TreeState) => {
  return state.projectHierarchyRules;
});

const getTreeNodeByFunctionalHierarchyId = (props: { functionalHierarchyId: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const result = state.entities[props.functionalHierarchyId];
    if (result === undefined) {
      return new TreeNode();
    }
    return result;
  });

const getTopProjectNode = createSelector(selectTreeState, (state: fromTree.TreeState) => {
  const levelOneNodes = Object.values(state.entities).filter((entity) => {
    return entity.typeCode === CodeTypes.Project;
  });
  return levelOneNodes[0];
});

const getCurrentTreeNode = createSelector(
  selectTreeState,
  routerSelectors.getIdSelector,
  (state: fromTree.TreeState, nodeId: number) => {
    const result = state.entities[nodeId];
    if (result === undefined) {
      return new TreeNode();
    }
    return result;
  },
);

const getFunctionalHierarchyPathByFunctionalHierarchyId = (props: { functionalHierarchyId: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    let path = [];
    let currentNode = state.entities[props.functionalHierarchyId];
    while (currentNode) {
      path = [currentNode, ...path];
      currentNode = currentNode.parentId ? state.entities[currentNode.parentId] : null;
    }
    return path;
  });

const uniqueCode = (props: { code: string; entityId: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const codeOccurrences = Object.values(state.entities).filter((entity) => {
      return entity.code === props.code && Number(entity.functionalHierarchyId) !== Number(props.entityId); // exclude current entity
    });
    return !codeOccurrences.length;
  });

const getStatusDetails = (props: { id: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const result = state.entities[props.id];
    if (result === undefined) {
      return StatusCodesMap.get(null);
    }
    const statusDetail = StatusCodesMap.get(result.status);
    return statusDetail ? statusDetail : StatusCodesMap.get(null);
  });

const getParentId = (props: { id: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const result = state.entities[props.id];
    if (result === undefined) {
      return null;
    }

    return result.parentId;
  });

const getParentIdBasedOnCurrentNode = createSelector(
  routerSelectors.getIdSelector,
  selectTreeState,
  (id: number, state: fromTree.TreeState) => {
    const result = state.entities[id];
    if (result === undefined) {
      return null;
    }

    return result.parentId;
  },
);

const getParentNodeManager = createSelector(
  getParentIdBasedOnCurrentNode,
  selectTreeState,
  (id: number, state: fromTree.TreeState) => {
    const result = state.entities[id];
    if (result === undefined) {
      return '';
    }

    return result.manager;
  },
);

const hasManagePermissionOnCurrentNode = createSelector(
  routerSelectors.getIdSelector,
  selectTreeState,
  (id: number, state: fromTree.TreeState) => {
    if (!state.initialLoad) {
      return null;
    }
    const node = state.entities[id];
    return node ? node.managePermission : false;
  },
);

const hasManagePermissionOnProjectNode = createSelector(
  getTopProjectNode,
  selectTreeState,
  (node: TreeNode, state: fromTree.TreeState) => {
    return node ? node.managePermission : false;
  },
);

const getAllChildrenNodeIdsSortedByLevel = (props: { functionalHierarchyId: number; ascending: boolean }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState): number[] => {
    const result: TreeNode[] = [];
    findAllNodesWithParent(Object.values(state.entities), [props.functionalHierarchyId], result);
    return [
      props.functionalHierarchyId,
      ...result
        .sort((a, b) => (props.ascending ? b.treeLevel - a.treeLevel : a.treeLevel - b.treeLevel))
        .map((n) => n.functionalHierarchyId),
    ];
  });

const getAllChildrenNodeIds = (props: { functionalHierarchyId: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState): number[] => {
    const result: number[] = [];
    findAllWithParent(Object.values(state.entities), [props.functionalHierarchyId], result);
    return result;
  });

const getNodeIdWithAllChildrenId = (props: { functionalHierarchyId: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState): number[] => {
    const result: number[] = [props.functionalHierarchyId];
    findAllWithParent(Object.values(state.entities), [props.functionalHierarchyId], result);
    return result;
  });

const getNodesByFunctionalHierarchyIds = (props: { functionalHierarchyIds: number[] }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    return Object.values(state.entities).filter((p) => props.functionalHierarchyIds.includes(p.functionalHierarchyId));
  });

const getPackagesWithNameStartingFrom = createSelector(
  selectTreeState,
  (state: fromTree.TreeState, props: { term: string; exclude: number[] }) => {
    return Object.values(state.entities).filter((entry) => {
      const searchTerm = props.term?.toUpperCase();
      return (
        entry.typeCode === CodeTypes.Package &&
        entry.status !== StatusCode.Cancelled &&
        !props.exclude.includes(entry.functionalHierarchyId) &&
        (entry.title.toUpperCase().includes(searchTerm) || entry.code?.toUpperCase().includes(searchTerm))
      );
    });
  },
);

const getPackagesWithNameStartingFromForMoveDeliverable = (props: { term: string; packageFhIdToExclude: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const sourcePackage = Object.values(state.entities).filter((entry) => {
      return entry.functionalHierarchyId === props.packageFhIdToExclude;
    })[0];
    const searchTerm = props.term?.toUpperCase();
    return Object.values(state.entities)
      .filter((x) => x.typeCode === CodeTypes.Package)
      .filter((node) => {
        const bothDraft = sourcePackage.status === StatusCode.Draft && node.status === StatusCode.Draft;
        const bothAreNotDraft = sourcePackage.status !== StatusCode.Draft && node.status !== StatusCode.Draft;
        const bothNotUnderChangedControl = !sourcePackage.underChangeControl && !node.underChangeControl;
        return (
          props.packageFhIdToExclude !== node.functionalHierarchyId &&
          (node.title.toUpperCase().includes(searchTerm) || node.code?.toUpperCase().includes(searchTerm)) &&
          (bothDraft || (bothAreNotDraft && bothNotUnderChangedControl))
        );
      });
  });

const getPackagesForCRWithNameStartingFromForMoveDeliverable = (props: {
  term: string;
  packageFhIdToExclude: number;
}) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const searchTerm = props.term?.toUpperCase();
    return Object.values(state.entities)
      .filter((x) => x.typeCode === CodeTypes.Package)
      .filter((node) => {
        return (
          node.underChangeControl &&
          props.packageFhIdToExclude !== node.functionalHierarchyId &&
          (node.title.toUpperCase().includes(searchTerm) || node.code?.toUpperCase().includes(searchTerm))
        );
      })
      .map((x) => {
        const parentManager = state.entities[x.parentId].manager;
        return { ...x, manager: parentManager };
      });
  });

const getAllParentsOfPackages = createSelector(selectTreeState, (state: fromTree.TreeState): TreeNode[] => {
  const allLevels = state.projectHierarchyRules.map((x) => x.level);
  const lastSubLevel = Math.max(...allLevels);
  return Object.values(state.entities).filter(
    (x) => x.treeLevel === lastSubLevel, //Tribal knowledge - tree hierarchy rules are defined from 0, where data in  the tree is from 1
  );
});

const getAllParentsOfPackagesWhichHaveLevel1MangerRights = createSelector(
  selectTreeState,
  (state: fromTree.TreeState): TreeNode[] => {
    const allLevels = state.projectHierarchyRules.map((x) => x.level);
    const lastSubLevel = Math.max(...allLevels);
    const allPackageParents = Object.values(state.entities).filter((x) => x.treeLevel === lastSubLevel);
    return allPackageParents.filter((f) => hasLevel1Manager(state.entities, f));
  },
);

const getPackagesParentSubfunctionsWithNameStartingFrom = (props: {
  term: string;
  parentFunctionIdToExclude: number;
}) =>
  createSelector(getAllParentsOfPackagesWhichHaveLevel1MangerRights, (nodes: TreeNode[]) => {
    const searchTerm = props.term?.toUpperCase();
    return nodes.filter(
      (node) =>
        !node.underChangeControl &&
        props.parentFunctionIdToExclude !== node.functionalHierarchyId &&
        (node.title.toUpperCase().includes(searchTerm) || node.code?.toUpperCase().includes(searchTerm)),
    );
  });

const getPackagesParentSubfunctionsForCrWithNameStartingFrom = (props: {
  term: string;
  parentFunctionIdToExclude: number;
}) =>
  createSelector(getAllParentsOfPackages, (nodes: TreeNode[]) => {
    return nodes.filter((node) => {
      const searchTerm = props.term?.toUpperCase();
      return (
        props.parentFunctionIdToExclude !== node.functionalHierarchyId &&
        (node.title.toUpperCase().includes(searchTerm) || node.code?.toUpperCase().includes(searchTerm)) &&
        node.underChangeControl &&
        node.status !== StatusCode.Cancelled &&
        node.status !== StatusCode.PendingCancellation
      );
    });
  });

const getSubfunctionsWithNameStartingFromForMoveSubfunction = (props: { term: string; sourceSubfunctionId: number }) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const sourceSubfunction = Object.values(state.entities).filter((entry) => {
      return entry.functionalHierarchyId === props.sourceSubfunctionId;
    })[0];
    const searchTerm = props.term?.toUpperCase();
    return Object.values(state.entities).filter(
      (node) =>
        sourceSubfunction.parentId !== node.functionalHierarchyId &&
        (node.typeCode === CodeTypes.Function || node.typeCode === CodeTypes.SubFunction) &&
        (node.title.toUpperCase().includes(searchTerm) || node.code?.toUpperCase().includes(searchTerm)) &&
        node.treeLevel === sourceSubfunction.treeLevel - 1 &&
        !node.underChangeControl &&
        hasLevel1Manager(state.entities, node),
    );
  });

const getSubfunctionsCRWithNameStartingFromForMoveSubfunction = (props: {
  term: string;
  sourceSubfunctionId: number;
}) =>
  createSelector(selectTreeState, (state: fromTree.TreeState) => {
    const isProjectNodeUnderChangeControl = checkIfProjectNodeUnderChangeControl(state.entities);
    const sourceSubfunction = Object.values(state.entities).filter((entry) => {
      return entry.functionalHierarchyId === props.sourceSubfunctionId;
    })[0];
    const searchTerm = props.term?.toUpperCase();
    return Object.values(state.entities).filter(
      (node) =>
        sourceSubfunction.parentId !== node.functionalHierarchyId &&
        (node.typeCode === CodeTypes.Function || node.typeCode === CodeTypes.SubFunction) &&
        (node.title.toUpperCase().includes(searchTerm) || node.code?.toUpperCase().includes(searchTerm)) &&
        node.treeLevel === sourceSubfunction.treeLevel - 1 &&
        (node.underChangeControl || (!node.underChangeControl && isProjectNodeUnderChangeControl)) &&
        node.status !== StatusCode.Cancelled &&
        node.status !== StatusCode.PendingCancellation &&
        hasLevel1Manager(state.entities, node),
    );
  });

const hasLevel1Manager = (entities: Dictionary<TreeNode>, node: TreeNode): boolean => {
  if (!node) {
    return false;
  }
  if (node.treeLevel == TreeLevelTypes.Function) {
    return node.managePermission;
  }
  const parentToCheck = entities[node.parentId];
  return hasLevel1Manager(entities, parentToCheck);
};

const checkIfProjectNodeUnderChangeControl = (entities: Dictionary<TreeNode>): boolean => {
  return Object.values(entities).filter((entity) => {
    return entity.typeCode === CodeTypes.Project;
  })[0].underChangeControl;
};

const findAllWithParent = (nodesToSearch: TreeNode[], parentIds: number[], accumulator: number[]): void => {
  if (parentIds.length === 0) {
    return;
  } else {
    const childrenNodesIds = nodesToSearch
      .filter((node) => parentIds.includes(node.parentId))
      .map((node) => node.functionalHierarchyId);
    childrenNodesIds.forEach((id) => accumulator.push(id));
    findAllWithParent(nodesToSearch, childrenNodesIds, accumulator);
  }
};

const findAllNodesWithParent = (nodesToSearch: TreeNode[], parentIds: number[], accumulator: TreeNode[]): void => {
  if (parentIds.length === 0) {
    return;
  } else {
    const childrenNodes = nodesToSearch.filter((node) => parentIds.includes(node.parentId));

    childrenNodes.forEach((node) => accumulator.push(node));

    findAllNodesWithParent(
      nodesToSearch,
      childrenNodes.map((node) => node.functionalHierarchyId),
      accumulator,
    );
  }
};

export const TreeSelectors = {
  selectAllTreeEntities,
  getProjectHierarchyRules,
  getTreeNodeByFunctionalHierarchyId,
  getTopProjectNode,
  getCurrentTreeNode,
  getFunctionalHierarchyPathByFunctionalHierarchyId,
  uniqueCode,
  getStatusDetails,
  getParentId,
  getParentIdBasedOnCurrentNode,
  getParentNodeManager,
  hasManagePermissionOnCurrentNode,
  hasManagePermissionOnProjectNode,
  getAllChildrenNodeIdsSortedByLevel,
  getAllChildrenNodeIds,
  getNodeIdWithAllChildrenId,
  getNodesByFunctionalHierarchyIds,
  getPackagesWithNameStartingFrom,
  getPackagesWithNameStartingFromForMoveDeliverable,
  getPackagesForCRWithNameStartingFromForMoveDeliverable,
  getAllParentsOfPackages,
  getPackagesParentSubfunctionsWithNameStartingFrom,
  getPackagesParentSubfunctionsForCrWithNameStartingFrom,
  getSubfunctionsWithNameStartingFromForMoveSubfunction,
  getSubfunctionsCRWithNameStartingFromForMoveSubfunction,
};
