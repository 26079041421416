import { Injectable } from '@angular/core';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem<T>(key: string, item: T): void {
    window.localStorage.setItem(key, JSON.stringify(item));
  }

  getItem<T>(key: string): T {
    const stringifiedItem = window.localStorage.getItem(key);

    if (!stringifiedItem) {
      return undefined;
    }
    return JSON.parse(stringifiedItem);
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }
}
