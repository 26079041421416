import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import * as currentContextFunctionSelectors from 'src/app/project-management/change-request-cart/change-request-cart-state/current-context-function/current-context-function.selectors';
import * as crDeliverableActions from 'src/app/project-management/change-request-cart/change-request-cart-state/deliverables/crDeliverable.actions';
import { CrDeliverableSelectors } from 'src/app/project-management/change-request-cart/change-request-cart-state/deliverables/crDeliverable.selectors';
import { AppState } from 'src/app/root-store/app-state';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormUpdateConfig,
  watchControls,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { StatusCode } from '../../../shared-controls/status/statusCodesMap';
import {
  ChangeRequestDeliverable,
  RequestType,
} from '../../change-request-cart/change-request-cart-state/cart-item.model';
import { RefreshCRTriggerService } from '../../change-request-cart/refresh-cr-trigger.service';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { TreeActionsService } from '../../tree-navigation/tree-actions-menu/tree-actions.service';
import { updateDeliverableProperty } from '../deliverable-state/deliverable.actions';

@Component({
  selector: 'app-deliverable-cr-update-section',
  templateUrl: './deliverable-cr-update-section.component.html',
  styleUrls: ['./deliverable-cr-update-section.component.scss'],
})
export class DeliverableCrUpdateSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  deliverableDetail: DeliverableDetail;

  curveProfiles$: Observable<CurveProfile[]>;
  private subscription = new Subscription();
  private crSubscription = new Subscription();
  private directUpdateSub = new Subscription();
  private currentContextFunction: number;
  currentDeliverableId: number;
  changeRequestForm: UntypedFormGroup;
  cr: ChangeRequestDeliverable;

  constructor(
    private curveProfilesService: CurveProfileService,
    private store$: Store<AppState>,
    private refreshCRTriggerService: RefreshCRTriggerService,
    private treeActionsService: TreeActionsService,
  ) {
    this.initChangeRequestForm();
  }

  ngOnInit(): void {
    this.curveProfiles$ = this.curveProfilesService.curveProfiles$;

    const currentContextFunctionSubscription = this.store$
      .select(currentContextFunctionSelectors.selectCurrentContextFunction)
      .subscribe((x) => (this.currentContextFunction = x));

    this.subscription.add(currentContextFunctionSubscription);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('deliverableDetail' in changes) {
      this.crSubscription.unsubscribe();
      this.crSubscription = combineLatest([
        this.store$.select(
          CrDeliverableSelectors.getCrDeliverable({
            deliverableId: this.deliverableDetail.deliverableId,
          }),
        ),
        this.refreshCRTriggerService.refreshDeliverablesTrigger$,
      ]).subscribe(([cr]) => {
        this.cr = cr;
        this.setControlWatchers();
        this.fillForms();
        disableFormFieldsBaseOnPermissions(this.changeRequestForm, this.isEditable.bind(this));
      });
    }
  }

  updateCRProperty(): void {
    const crd = this.createCrDeliverable();

    if (this.deliverableDetail.underChangeControl) {
      this.store$.dispatch(
        crDeliverableActions.sendDeliverableChangeToCart({
          crDeliverable: crd,
        }),
      );
    } else {
      const update: Update<any> = {
        id: this.deliverableDetail.deliverableId,
        changes: {},
      };

      if (crd.newCost !== crd.oldCost) {
        update.changes.cost = crd.newCost;
      }

      if (crd.newHours !== crd.oldHours) {
        update.changes.hours = crd.newHours;
      }

      if (crd.newCurveProfileCode !== crd.oldCurveProfileCode) {
        update.changes.curveProfileCode = crd.newCurveProfileCode;
      }

      if (!Object.keys(update.changes).length) {
        return;
      }

      this.store$.dispatch(
        updateDeliverableProperty({
          key: 'deliverableCRProperties',
          update: this.treeActionsService.mergeDeliverableIdUpdates(update),
          parentId: this.deliverableDetail.packageFunctionalHierarchyId,
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.directUpdateSub?.unsubscribe();
  }

  showCRInvalidState(name: string): boolean {
    const control = this.changeRequestForm.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  private createCrDeliverable(): ChangeRequestDeliverable {
    const crd = new ChangeRequestDeliverable();
    crd.deliverableId = this.deliverableDetail.deliverableId;
    crd.functionId = this.currentContextFunction;
    crd.packageFunctionalHierarchyId = this.deliverableDetail.packageFunctionalHierarchyId;
    crd.documentNumber = this.deliverableDetail.documentNumber;
    crd.packageCode = this.deliverableDetail.packageCode;
    crd.title = this.deliverableDetail.title;
    crd.request = RequestType.Change;
    crd.oldCost = this.deliverableDetail.cost;
    crd.newCost = this.changeRequestForm.get('cost').value;
    crd.oldHours = this.deliverableDetail.hours;
    crd.newHours = this.changeRequestForm.get('hours').value;
    crd.oldCurveProfileCode = this.deliverableDetail.curveProfileCode;
    crd.newCurveProfileCode = this.changeRequestForm.get('curveProfileCode').value;

    return crd;
  }

  private isCancelled(): boolean {
    return this.deliverableDetail.status === StatusCode.Cancelled;
  }

  private isEditable(name: string): boolean {
    return !this.isCancelled() && this.deliverableDetail?.editableFields?.includes(name.toLocaleLowerCase());
  }

  private initChangeRequestForm(): void {
    this.changeRequestForm = new UntypedFormGroup({
      hours: new UntypedFormControl(null, {
        validators: [Validators.min(0)],
        updateOn: 'blur',
      }),
      cost: new UntypedFormControl(null, {
        validators: [Validators.min(0), Validators.required],
        updateOn: 'blur',
      }),
      curveProfileCode: new UntypedFormControl(),
    });
  }

  private fillForms(): void {
    let { hours, cost, curveProfileCode } = this.deliverableDetail;

    if (this.cr) {
      hours = this.cr?.newHours ?? hours;
      cost = this.cr?.newCost ?? cost;
      curveProfileCode = this.cr?.newCurveProfileCode ?? curveProfileCode;
    } else if (
      this.currentDeliverableId === this.deliverableDetail.deliverableId &&
      !this.deliverableDetail.underChangeControl
    ) {
      const cr = this.createCrDeliverable();
      hours = cr.newHours ?? hours;
      cost = cr.newCost ?? cost;
      curveProfileCode = cr.newCurveProfileCode ?? curveProfileCode;
    }
    this.currentDeliverableId = this.deliverableDetail.deliverableId;

    this.changeRequestForm.patchValue(
      {
        hours: hours || 0,
        cost: cost || 0,
        curveProfileCode: curveProfileCode || 0,
      },
      { ...shadowFormUpdateConfig },
    );
  }

  private setControlWatchers(): void {
    this.subscription.add(watchControls(this.changeRequestForm).subscribe(() => this.updateCRProperty()));
  }
}
