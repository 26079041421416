import { Component } from '@angular/core';
import { AppConfigService } from 'src/app/shared/services/app.config.service';

@Component({
  selector: 'app-terms-privacy',
  templateUrl: './terms-privacy.component.html',
  styleUrls: ['./terms-privacy.component.scss'],
})
export class TermsPrivacyComponent {
  appVersion: string;
  currentDate = new Date();

  constructor(private appConfigService: AppConfigService) {
    this.appVersion = this.appConfigService.settings.version.number + this.appConfigService.settings.version.build;
  }
}
