<ng-select
  [items]="users$ | async"
  bindLabel="displayName"
  [minTermLength]="MinLengthTypeAhead"
  [formControl]="userControl"
  [clearable]="clearable"
  [loading]="loading"
  [typeahead]="userInput$"
  (change)="selectUser(); select.blur()"
  #select
  [placeholder]="'general.unassigned' | translate"
>
  <ng-template ng-option-tmp let-item="item">
    <div>{{ item.displayName }}</div>
    <div *ngIf="item.userName.indexOf('@') !== -1" class="orms-sub-text">{{ item.userName }}</div>
    <div *ngIf="item.userName.indexOf('@') === -1" class="orms-sub-text">
      {{ 'placeholderUsers.placeholder' | translate }}
    </div>
  </ng-template>
</ng-select>
