<div class="orms-cr-snack-bar" [class.orms-show-cr-snack-bar]="changesCount > 0 && !dialogOpened">
  <h5 class="orms-cr-snack-bar-text">
    {{ snackBarTitle | translate }}
    {{ changesCount }}
    {{ (changesCount === 1 ? 'general.item' : 'general.items') | translate }}
  </h5>
  <button type="button" (click)="cancel()" class="orms-cr-snack-bar-cancel"></button>
  <button
    type="button"
    (click)="submit()"
    class="orms-cr-snack-bar-send"
    [matTooltip]="'changeRequest.submitForApproval' | translate"
    matTooltipPosition="above"
    [matTooltipClass]="['orms-tooltip', 'orms-cr-snack-bar-tooltip']"
  ></button>
</div>
