import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { LegacyDateAdapter, MAT_LEGACY_DATE_FORMATS, MatLegacyDateFormats } from '@angular/material/legacy-core';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datepicker-actions',
  templateUrl: './datepicker-actions.component.html',
  styleUrls: ['./datepicker-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerActionsComponent<T> implements OnDestroy {
  private subscription = new Subscription();

  constructor(
    private calendar: MatCalendar<T>,
    private dateAdapter: LegacyDateAdapter<T>,
    private picker: MatDatepicker<T>,
    @Inject(MAT_LEGACY_DATE_FORMATS) private dateFormats: MatLegacyDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    const stateSubscription = calendar.stateChanges.subscribe(() => cdr.markForCheck());
    this.subscription.add(stateSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get periodLabel(): string {
    return this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year'): any {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year'): any {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }

  setToNow(): void {
    this.picker.select(moment.utc().startOf('day') as any);
    this.picker.close();
  }

  clear(): void {
    this.picker.select(null);
    this.picker.close();
  }

  close(): void {
    this.picker.close();
  }
}
