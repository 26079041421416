<form [formGroup]="form" autocomplete="off" class="orms-cr-submission-form" (ngSubmit)="onSubmit()">
  <h2>
    {{ 'project.createProject' | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <p>{{ 'project.addHint' | translate }}</p>
  <div class="add-project-form">
    <mat-form-field appearance="outline" class="orms-outline-input projectTitle">
      <mat-label>{{ 'project.projectName' | translate }}</mat-label>
      <input
        autocomplete="off"
        matInput
        placeholder="{{ 'project.projectName' | translate }}"
        formControlName="title"
        maxlength="255"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'project.code' | translate }}</mat-label>
      <input
        autocomplete="off"
        matInput
        placeholder="{{ 'project.code' | translate }}"
        formControlName="code"
        maxlength="100"
      />
    </mat-form-field>
    <app-user-dropdown-mat
      [dataSource]="dataSource"
      [inputControl]="projectManager"
      label="projectDetails.manager"
      [clearable]="false"
      [required]="true"
      [id]="portfolioId"
    ></app-user-dropdown-mat>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'project.hierarchyDepth' | translate }}</mat-label>
      <mat-select formControlName="hierarchyDepth">
        <mat-option *ngFor="let l of hierarchyDepths" [value]="l">
          {{ l }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'general.curveProfile' | translate }}</mat-label>
      <mat-select formControlName="curveProfile">
        <mat-option *ngFor="let w of curveProfiles$ | async" [value]="w.curveProfileId">
          {{ w.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        type="submit"
        class="orms-button orms-button-primary submit-button"
        [disabled]="(isSubmitting$ | async) === true"
      >
        {{ 'general.confirm' | translate }}
      </button>
      <button
        type="button"
        class="orms-button orms-button-secondary"
        [disabled]="(isSubmitting$ | async) === true"
        mat-dialog-close
      >
        {{ 'general.cancel' | translate }}
      </button>
      <app-spinner [diameter]="26" *ngIf="(isSubmitting$ | async) === true"></app-spinner>
    </div>
    <div class="required"><span>*</span> {{ 'general.fieldIsRequired' | translate }}</div>
  </div>
</form>
