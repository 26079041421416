import { Component, Input } from '@angular/core';
import { BreadcrumbOption } from './breadcrumbs.interfaces';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() options: BreadcrumbOption[] = [];
}
