import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { AppConfigService } from '../../shared/services/app.config.service';

@Injectable({ providedIn: 'root' })
export class CurveProfileService {
  curveProfiles$: Observable<CurveProfile[]>;

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
  ) {
    const { baseUrl, curveProfiles } = this.appConfigService.settings.api.endpoints;
    this.curveProfiles$ = this.httpClient.get<CurveProfile[]>(baseUrl + curveProfiles).pipe(shareReplay());
  }
}
