import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { TreeNode } from 'src/app/project-management/tree-navigation/tree-structure.model';
import { AppState } from 'src/app/root-store/app-state';
import { ChangeRequest } from '../cart-item.model';
import * as currentContextFunctionSelectors from '../current-context-function/current-context-function.selectors';
import * as deliverableActions from '../deliverables/crDeliverable.actions';
import { CrDeliverableSelectors } from '../deliverables/crDeliverable.selectors';
import * as packageActions from '../packages/package.actions';
import { getCrPackage } from '../packages/package.selectors';
import * as changeRequestActions from './change-request.actions';

@Injectable()
export class ChangeRequestEffects {
  constructor(
    private actions: Actions,
    private store$: Store<AppState>,
  ) {}

  createChangeEffect = createEffect(() =>
    this.actions.pipe(
      ofType(changeRequestActions.createChange),
      switchMap((action) =>
        this.store$
          .select(
            TreeSelectors.getTreeNodeByFunctionalHierarchyId({
              functionalHierarchyId: action.functionalHierarchyId,
            }),
          )
          .pipe(take(1)),
      ),
      switchMap((node: TreeNode) => {
        const cr = new ChangeRequest();
        cr.contextElementId = node.functionalHierarchyId;
        cr.manager = node.manager;
        return of(changeRequestActions.addChange({ change: cr }));
      }),
    ),
  );

  removeChangeInRequestEffect = createEffect(() =>
    this.actions.pipe(
      ofType(changeRequestActions.removeChange),
      switchMap(() =>
        of(
          packageActions.removeAllPackageChangesForFunction(),
          deliverableActions.removeAllDeliverableChangesForFunction(),
        ),
      ),
    ),
  );

  removeChangeInMoveRequestEffect = createEffect(() =>
    this.actions.pipe(
      ofType(changeRequestActions.removeMoveChanges),
      switchMap(() =>
        of(
          packageActions.removeAllPackageMovesForFunction(),
          deliverableActions.removeAllDeliverableMoveChangesForFunction(),
        ),
      ),
    ),
  );

  removeChangeInCurrentContextRequestEffect = createEffect(() =>
    this.actions.pipe(
      ofType(changeRequestActions.removeChangeInCurrentContext),
      switchMap(() => this.store$.select(currentContextFunctionSelectors.selectCurrentContextFunction).pipe(take(1))),
      switchMap(() => of(changeRequestActions.removeChange())),
    ),
  );

  removeChangeInCurrentContextMoveRequestEffect = createEffect(() =>
    this.actions.pipe(
      ofType(changeRequestActions.removeMoveChangeInCurrentContext),
      switchMap(() => of(changeRequestActions.removeMoveChanges())),
    ),
  );

  checkIfLastDeliverableEffect = createEffect(() =>
    this.actions.pipe(
      ofType(deliverableActions.removeDeliverableChange),
      switchMap((action) =>
        forkJoin([
          this.store$
            .select(
              CrDeliverableSelectors.isLastCrDeliverableForPackageFunctionalHierarchyId({
                packageFunctionalHierarchyId: action.packageFunctionalHierarchyId,
              }),
            )
            .pipe(take(1)),
          of(action),
        ]),
      ),
      switchMap(([lastDeliverable, action]) => {
        if (!lastDeliverable) {
          return of(null);
        }
        return this.store$
          .select(
            getCrPackage({
              functionalHierarchyId: action.packageFunctionalHierarchyId,
            }),
          )
          .pipe(take(1));
      }),
      switchMap((crPackage) => {
        if (!crPackage || Object.keys(crPackage).length > 3) {
          return of(changeRequestActions.noOP());
        }
        return of(
          packageActions.removePackageChange({
            packageId: crPackage.functionalHierarchyId,
          }),
        );
      }),
    ),
  );
}
