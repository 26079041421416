<ng-select
  bindLabel="name"
  [items]="list"
  [multiple]="true"
  [formControl]="control"
  [clearable]="false"
  [closeOnSelect]="false"
  [placeholder]="placeholderText | translate"
  [selectableGroup]="true"
  #select
  (open)="onOpen()"
  (change)="onChangeFilter($event)"
  (search)="onSearch($event)"
  class="orms-multiselect"
>
  <ng-template ng-header-tmp>
    <input
      #input
      class="orms-multiselect-search"
      (input)="select.filter(input.value)"
      (compositionstart)="select.onCompositionStart()"
      (compositionend)="select.onCompositionEnd(input.value)"
      (focus)="select.onInputFocus($event)"
      (blur)="select.onInputBlur($event)"
      (change)="$event.stopPropagation()"
      [placeholder]="placeholderSearchText | translate"
    />
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="custom-control custom-checkbox orms-multiselect-checkbox">
      <input type="checkbox" class="custom-control-input" id="item-{{ index }}" [ngModel]="item$.selected" />
      <label class="custom-control-label" for="item-{{ index }}">{{ $any(item).name }}</label>
    </div>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items">
    <ng-container *ngIf="items.length === 1">
      <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
        <span class="ng-value-label">
          {{ $any(item).name }}
        </span>
      </div>
    </ng-container>
    <div class="ng-value" *ngIf="items.length > 1">
      <span class="ng-value-label"> {{ defaultValue | translate }} ({{ items.length }}) </span>
    </div>
  </ng-template>
  <ng-template ng-footer-tmp>
    <div *ngIf="showSelectAll" class="many-footer-buttons">
      <ng-container *ngTemplateOutlet="selectAllButton"></ng-container>
      <ng-container *ngTemplateOutlet="clearButton"></ng-container>
    </div>
    <ng-container *ngIf="!showSelectAll">
      <ng-container *ngTemplateOutlet="clearButton"></ng-container>
    </ng-container>
  </ng-template>
</ng-select>

<ng-template #clearButton>
  <button
    *ngIf="control.value.length"
    type="button"
    class="orms-button orms-button-secondary"
    (click)="select.handleClearClick()"
  >
    {{ 'general.clear' | translate }}
    <img src="/assets/icons/icon-close.svg" alt="{{ 'imgAlt.close' | translate }}" />
  </button>
</ng-template>

<ng-template #selectAllButton>
  <button type="button" class="orms-button orms-button-secondary" (click)="onSelectAll()">
    {{ 'general.selectAll' | translate }}
  </button>
</ng-template>
