import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CurveChartPoint } from '../common/navigation-tabs/curve/curve-chart.model';
import { AddToLink, CreateSharePackage, SharePackage, SharedFunctionalHierarchy } from './share/share.model';

@Injectable({
  providedIn: 'root',
})
export class SharedPackageService {
  private lastAccessedPackage = {
    linkIdentifier: null,
    headerValue: null,
  };

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  addPackageLink(sharePackage: CreateSharePackage): Observable<SharePackage> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const addPackageLinkUrl = this.appConfigService.settings.api.endpoints.addPackageLink;
    return this.httpClient.post<SharePackage>(baseUrl + addPackageLinkUrl, sharePackage);
  }

  addUsersToPackageLink(addToLink: AddToLink): Observable<void> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const addUsersToPackageLinkUrl = this.appConfigService.settings.api.endpoints.addUsersToPackageLink;
    return this.httpClient.post<void>(baseUrl + addUsersToPackageLinkUrl, addToLink).pipe(
      catchError((response) => {
        showHttpErrorResponse(this.notificationService, response);
        return throwError(response);
      }),
    );
  }

  getFhPath(linkId: string): Observable<SharedFunctionalHierarchy[]> {
    const params = new HttpParams().set('linkIdentifier', linkId);

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getFhPath = this.appConfigService.settings.api.endpoints.getFhPath;
    return this.httpClient.get<SharedFunctionalHierarchy[]>(baseUrl + getFhPath, {
      params,
    });
  }

  getLinkForPackage(projectId: number, packageId: number): Observable<SharePackage[]> {
    const params = new HttpParams().set('projectId', projectId.toString()).set('packageId', packageId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getLinkForPackageUrl = this.appConfigService.settings.api.endpoints.getLinkForPackage;
    return this.httpClient.get<SharePackage[]>(baseUrl + getLinkForPackageUrl, {
      params,
    });
  }

  disableLink(linkId: number): Observable<void> {
    const params = new HttpParams().set('linkId', linkId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const disableLink = this.appConfigService.settings.api.endpoints.disableLink;
    return this.httpClient.delete<void>(baseUrl + disableLink, {
      params,
    });
  }

  revokeAccess(linkId: number, email: string): Observable<void> {
    const params = new HttpParams().set('linkId', linkId.toString()).set('email', email);

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const revokeAccess = this.appConfigService.settings.api.endpoints.revokeAccess;
    return this.httpClient.delete<void>(baseUrl + revokeAccess, {
      params,
    });
  }

  changeExpirationDate(linkId: number, newExpirationDate: string): Observable<void> {
    const params = new HttpParams().set('linkId', linkId.toString()).set('newExpirationDate', newExpirationDate);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const changeExpirationDate = this.appConfigService.settings.api.endpoints.changeExpirationDate;
    return this.httpClient.post<void>(
      baseUrl + changeExpirationDate,
      {},
      {
        params,
      },
    );
  }

  accessPackage(linkIdentifier: string): Observable<any> {
    if (this.lastAccessedPackage?.linkIdentifier === linkIdentifier) {
      return of(this.lastAccessedPackage.headerValue);
    }

    const params = new HttpParams().set('linkIdentifier', linkIdentifier);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const sharedPackageHeader = this.appConfigService.settings.api.endpoints.accessPackage;

    return this.httpClient.post<any>(baseUrl + sharedPackageHeader, {}, { params }).pipe(
      tap((headerValue) => {
        this.lastAccessedPackage = {
          linkIdentifier,
          headerValue,
        };
      }),
    );
  }

  getViewPackageDetails(linkIdentifier: string): Observable<any> {
    const params = new HttpParams().set('linkIdentifier', linkIdentifier);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const sharedPackageDetails = this.appConfigService.settings.api.endpoints.getViewPackageDetails;

    return this.httpClient.get<any>(baseUrl + sharedPackageDetails, { params });
  }

  getViewDeliverables(linkIdentifier: string): Observable<any> {
    const params = new HttpParams().set('linkIdentifier', linkIdentifier);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableListUrl = this.appConfigService.settings.api.endpoints.getViewDeliverables;
    return this.httpClient
      .get<any>(baseUrl + deliverableListUrl, { params })
      .pipe(map((res) => res.map((x) => ({ ...x, editableFields: [] }))));
  }

  getDeliverableViewDetailData(linkIdentifier: string, deliverableId: number): Observable<any> {
    const params = new HttpParams()
      .set('linkIdentifier', linkIdentifier)
      .set('deliverableId', deliverableId.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getViewDetailUrl = this.appConfigService.settings.api.endpoints.getDeliverableViewDetailData;
    return this.httpClient.get<any>(baseUrl + getViewDetailUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }

  getCurveChartViewData(linkIdentifier: string, atDate: string): Observable<CurveChartPoint[]> {
    const params = new HttpParams().set('linkIdentifier', linkIdentifier).set('atDate', atDate);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const curveChartDataUrl = this.appConfigService.settings.api.endpoints.getViewCurveChartData;
    return this.httpClient.get<any>(baseUrl + curveChartDataUrl, { params }).pipe(
      catchError((err) => {
        showHttpErrorResponse(this.notificationService, err);
        return [];
      }),
      map((ccList) => {
        return ccList.calculationCurvePoints;
      }),
    );
  }
}
