import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InputColumnEntity } from '../review-import-step.component';

@Component({
  selector: 'app-review-import-change-details',
  templateUrl: './review-import-change-details.component.html',
  styleUrls: ['./review-import-change-details.component.scss'],
})
export class ReviewImportChangeDetailsComponent {
  inputColumnDefs: string[] = ['key', 'newValue'];
  inputColumnsDataSource: InputColumnEntity[];

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private data: {
      discreteAction: string;
      inputColumns: InputColumnEntity[];
    },
    private dialogRef: MatLegacyDialogRef<ReviewImportChangeDetailsComponent>,
  ) {
    this.inputColumnsDataSource = data.inputColumns.filter((x) => x.newValue !== x.oldValue);
    if (data.discreteAction !== 'New') {
      this.inputColumnDefs.push('oldValue');
    }
  }
}
