<div class="navigation" role="navigation">
  <a [routerLink]="!viewOnly ? '/' : null">
    <img src="/assets/images/logo.svg" alt="{{ 'imgAlt.hatchLogo' | translate }}" />
  </a>
  <span class="page-header-product-name">
    {{ 'general.productName' | translate }}
  </span>
  <div class="spacer"></div>
  <app-language-chooser *ngIf="languageSelection"></app-language-chooser>
  <a class="help-button" href="https://ready-help.hatch.com" target="_blank"></a>
  <div *ngIf="loginDisplay" class="user-menu">
    <button mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #userMenu="matMenu" class="orms-menu profile-menu">
      <div class="organization">
        <span class="icon-container">
          <img class="icon" src="/assets/icons/icon-portfolio.svg" alt="{{ 'imgAlt.menuIcon' | translate }}" />
        </span>
        <span class="label-container">
          {{ organizationName }}
        </span>
      </div>
      <ng-container *ngFor="let action of actions">
        <button
          type="button"
          (click)="action.callBack()"
          [ngClass]="{ 'menu-item-border-top': action.menuItemBorder }"
          mat-menu-item
        >
          <span class="icon-container">
            <img class="icon" [src]="action.icon" alt="{{ 'imgAlt.menuIcon' | translate }}" />
          </span>
          <span class="label-container">
            {{ action.label | translate }}
            {{ action.addUserInfo ? ' (' + username + ')' : '' }}
          </span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
