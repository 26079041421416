import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ChangeRequestType } from 'src/app/project-management/common/navigation-tabs/change-request-list/cr-type';
import { clearProjectRelatedData } from 'src/app/root-store/root-store.actions';
import { ChangeRequest } from '../cart-item.model';
import * as actions from './change-request.actions';

export const changeRequestAdapter: EntityAdapter<ChangeRequest> = createEntityAdapter<ChangeRequest>({
  selectId: (model) => model.contextElementId,
});

export const ChangeRequestState = changeRequestAdapter.getInitialState({});

export const changeRequestReducer = createReducer(
  ChangeRequestState,
  on(actions.addChange, (state, { change }) => {
    return changeRequestAdapter.upsertOne(change, state);
  }),
  on(actions.removeChange, (state) => {
    const ids = Object.entries(state.entities)
      .filter((x) => x[1].changeRequestType !== ChangeRequestType.MovePackage)
      .map((x) => x[0]);
    return changeRequestAdapter.removeMany(ids, state);
  }),
  on(actions.removeMoveChanges, (state) => {
    const ids = Object.entries(state.entities)
      .filter((x) => x[1].changeRequestType === ChangeRequestType.MovePackage)
      .map((x) => x[0]);
    return changeRequestAdapter.removeMany(ids, state);
  }),
  on(actions.removeMoveChange, (state, { deliverableId }) => {
    const ids = Object.entries(state.entities)
      .filter((x) => x[1].contextElementId === deliverableId)
      .map((x) => x[0]);
    return changeRequestAdapter.removeMany(ids, state);
  }),
  on(actions.updateChange, (state, { update }) => {
    return changeRequestAdapter.updateOne(update, state);
  }),
  on(clearProjectRelatedData, () => {
    return changeRequestAdapter.getInitialState({});
  }),
);
