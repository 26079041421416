import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { BreakdownEntity } from '../review-import-step.component';

@Component({
  selector: 'app-review-import-breakdown',
  templateUrl: './review-import-breakdown.component.html',
  styleUrls: ['./review-import-breakdown.component.scss'],
})
export class ReviewImportBreakdownComponent {
  breakdownColumns: string[] = ['sheet', 'added', 'updated', 'deleted', 'unknown'];
  breakdownDataSource: BreakdownEntity[];

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private data: {
      breakdown: BreakdownEntity[];
    },
    private dialogRef: MatLegacyDialogRef<ReviewImportBreakdownComponent>,
  ) {
    this.breakdownDataSource = data.breakdown;
  }
}
