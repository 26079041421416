import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { intersection } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AccountInfoService {
  constructor(private authService: MsalService) {}

  getOrganizationId(): string {
    const account = this.getFirstAccount();
    if (!account) {
      return null;
    }
    return (account.idTokenClaims as any).organization_id;
  }

  getOrganizationName(): string {
    const account = this.getFirstAccount();
    if (!account) {
      return null;
    }
    return (account.idTokenClaims as any).organization_name;
  }

  getUserId(): string {
    const account = this.getFirstAccount();
    if (!account) {
      return null;
    }
    return (account.idTokenClaims as any).uid;
  }

  getWorkspaceChoice(): string {
    const account = this.getFirstAccount();
    if (!account) {
      return null;
    }
    return (account.idTokenClaims as any).workspaceChoice;
  }

  getUserEmail(): string {
    const account = this.getFirstAccount();
    if (!account) {
      return null;
    }
    return (account.idTokenClaims as any).email;
  }

  getUserRoles(): string[] {
    const account = this.getFirstAccount();
    if (!account) {
      return [];
    }
    return (account.idTokenClaims as any)?.roles ?? [];
  }

  hasRole(roles: string | string[]): boolean {
    const userRoles = this.getUserRoles();
    const checkingRoles = Array.isArray(roles) ? roles : [roles];
    return intersection(userRoles, checkingRoles).length > 0;
  }

  getUserName(): string {
    const account = this.getFirstAccount();
    if (!account) {
      return null;
    }
    let username = null;
    const userNameArray = account.name?.split(',');
    if (userNameArray && userNameArray.length === 2) {
      username = [...userNameArray].reverse().join(' ').trim();
    } else {
      username = account.name;
    }
    return username;
  }

  private getFirstAccount() {
    return this.authService.instance.getAllAccounts()?.[0];
  }
}
