<div class="header">
  <img src="/assets/images/logo.svg" alt="{{ 'imgAlt.hatchLogo' | translate }}" />
  <span class="page-header-product-name">
    {{ 'general.productName' | translate }}
  </span>
</div>
<hr />

<div class="main-title">{{ 'print.packageReport' | translate }}</div>

<div class="breadcrumbs-section">
  <app-breadcrumbs [options]="breadcrumbOptions"></app-breadcrumbs>
</div>

<div class="details" *ngIf="headerDetails">
  <div class="deliverable-title">{{ headerDetails?.title }}</div>

  <div class="code">
    <span>{{ headerDetails.code }}</span>
  </div>

  <div class="data-section">
    <div class="column">
      <div class="label">
        <div class="first-section">
          <span>{{ 'general.managerUser' | translate }}:</span>
          <span>{{ 'general.status' | translate }}:</span>
          <span>{{ 'general.actualComplete' | translate }}:</span>
          <span>{{ 'general.plannedComplete' | translate }}:</span>
        </div>
        <div class="second-section">
          <span>{{ 'general.priority' | translate }}:</span>
          <span>{{ 'package.criticalPackage' | translate }}:</span>
        </div>
        <div class="third-section"></div>
      </div>
      <div class="value">
        <div class="first-section">
          <span>{{ packageDetails.manager ? packageDetails.manager : ('print.notAssigned' | translate) }} </span>
          <span>{{ packageStatusDetails.text | translate | capitalizeFirstLetter }} </span>
          <span>{{ packageDetails.actualComplete | number: '1.0-0' }}%</span>
          <span>{{ packageDetails.plannedComplete | number: '1.0-0' }}%</span>
        </div>
        <div class="second-section">
          <span>{{
            packageDetails?.packagePriority ? packageDetails?.packagePriority : ('print.none' | translate)
          }}</span>
          <span>{{ packageDetails?.criticalPackage ? ('general.yes' | translate) : ('general.no' | translate) }}</span>
        </div>
        <div class="third-section"></div>
      </div>
    </div>
    <div class="column">
      <div class="label">
        <div class="first-section">
          <span>{{ 'history.budgetHours' | translate }}:</span>
          <span>{{ 'history.budgetCost' | translate }}:</span>
          <span>{{ 'general.package' | translate }} {{ 'general.start' | translate }}:</span>
          <span>{{ 'general.package' | translate }} {{ 'general.end' | translate }}:</span>
        </div>
        <div class="second-section"></div>
        <div class="third-section"></div>
      </div>
      <div class="value">
        <div class="first-section">
          <span>{{ packageDetails.budgetHours }}</span>
          <span>${{ packageDetails.budgetCost }}</span>
          <span>{{ packageDetails.startDate | date: 'dd-MMM-yy' : 'UTC' }} </span>
          <span>{{ packageDetails.endDate | date: 'dd-MMM-yy' : 'UTC' }} </span>
        </div>
        <div class="second-section"></div>
        <div class="third-section"></div>
      </div>
    </div>
  </div>
  <div>
    <span>{{ 'general.intent' | translate }}: </span>
  </div>
  <div>
    <span [innerHTML]="notes"></span>
  </div>
  <div class="deliverable-section">
    <span>{{ 'advancedSearch.deliverables' | translate }}: </span>
  </div>

  <table>
    <tr>
      <th class="doc-number">#</th>
      <th>{{ 'bulkOperation.title' | translate }}</th>
      <th>{{ 'bulkOperation.responsible' | translate }}</th>
      <th class="date">{{ 'general.start' | translate }}</th>
      <th class="date">{{ 'general.end' | translate }}</th>
      <th class="other">{{ 'bulkOperation.hours' | translate }}</th>
      <th class="other">{{ 'general.actualComplete' | translate }}</th>
      <th class="other">{{ 'bulkOperation.controlled' | translate }}</th>
    </tr>
    <tr *ngFor="let deliverable of headerDetails.deliverables">
      <td>{{ deliverable.documentNumber }}</td>
      <td>{{ deliverable.title }}</td>
      <td>{{ deliverable?.responsible?.displayName }}</td>
      <td>{{ deliverable?.startDate | date: 'dd-MMM-yy' : 'UTC' }}</td>
      <td>{{ deliverable?.endDate | date: 'dd-MMM-yy' : 'UTC' }}</td>
      <td>{{ deliverable?.budgetHours }}</td>
      <td>{{ deliverable?.percentageCompleted | number: '1.0-0' }}%</td>
      <td>
        {{ deliverable?.controlledDocument ? ('general.yes' | translate) : ('general.no' | translate) }}
      </td>
    </tr>
  </table>
</div>
