import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Tag } from 'src/app/common-models/tag.model';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent {
  editableMode = false;
  @Output() tagAdded = new EventEmitter<Tag>();
  @Output() tagRemoved = new EventEmitter<Tag>();
  @Input() remoteSearch: (value: string) => Observable<{ recentTags: Tag[]; tags: Tag[] }>;
  @Input() tags: Tag[];
  @Input() readonly = true;
  @Input() allowNewTagAdding = true;

  tagControl = new UntypedFormControl([]);

  @ViewChild('chipList', { read: ElementRef })
  public chipList: ElementRef;

  addTag(tag: Tag): void {
    this.editableMode = false;
    const tagName = tag.name?.trim();
    if (
      tagName.length > 0 &&
      (this.allowNewTagAdding || tag.id != 0) &&
      !this.tags.some((x) => x.name?.toLowerCase() === tagName.toLowerCase())
    ) {
      this.tagAdded.emit({
        ...tag,
        name: tagName,
      });
    }
  }

  remove(tag: Tag): void {
    this.editableMode = false;
    this.tagRemoved.emit(tag);
  }
}
