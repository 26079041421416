<div class="header">
  <img src="/assets/images/logo.svg" alt="{{ 'imgAlt.hatchLogo' | translate }}" />
  <span class="page-header-product-name">
    {{ 'general.productName' | translate }}
  </span>
</div>
<hr />

<div class="main-title">{{ 'deliverable.report' | translate }}</div>

<div class="breadcrumbs-section">
  <app-breadcrumbs [options]="breadcrumbOptions"></app-breadcrumbs>
</div>

<div class="details" *ngIf="headerDetails">
  <div class="deliverable-title">{{ headerDetails?.title }}</div>

  <div class="data-section">
    <div class="column">
      <div class="label">
        <div class="first-section">
          <span>{{ 'general.responsible' | translate }}:</span>
          <span>{{ 'general.status' | translate }}:</span>
          <span>{{ 'general.actualComplete' | translate }}:</span>
          <span>{{ 'general.plannedComplete' | translate }}:</span>
          <span>{{ 'deliverable.workflowStep' | translate }}:</span>
        </div>
        <div class="second-section">
          <span>{{ 'history.budgetHours' | translate }}:</span>
          <span>{{ 'history.budgetCost' | translate }}:</span>
          <span>{{ 'advancedSearch.plannedStart' | translate }}:</span>
          <span>{{ 'advancedSearch.plannedEnd' | translate }}:</span>
        </div>
        <div class="third-section">
          <span>{{ 'general.priority' | translate }}:</span>
          <span>{{ 'deliverable.controlledDocument' | translate }}:</span>
        </div>
      </div>
      <div class="value">
        <div class="first-section">
          <span>{{ headerDetails.responsible ? headerDetails.responsible : ('print.notAssigned' | translate) }} </span>
          <span>{{ statusDetails.text | translate | capitalizeFirstLetter }} </span>
          <span>{{ packageDetails.actualComplete | number: '1.0-0' }}%</span>
          <span>{{ packageDetails.plannedComplete | number: '1.0-0' }}%</span>
          <span
            >{{
              headerDetails?.progressWorkflow ? headerDetails?.progressWorkflow : ('print.notApplicable' | translate)
            }}
          </span>
        </div>
        <div class="second-section">
          <span>{{ headerDetails?.hours }}</span>
          <span>${{ headerDetails?.cost }}</span>
          <span [ngClass]="{ packageDate: !headerDetails?.startDate }">{{
            $any(headerDetails?.startDate ? headerDetails?.startDate : headerDetails?.plannedStartDate)
              | date: 'dd-MMM-yy' : 'UTC'
          }}</span>
          <span [ngClass]="{ packageDate: !headerDetails?.endDate }">{{
            $any(headerDetails?.endDate ? headerDetails?.endDate : headerDetails?.plannedEndDate)
              | date: 'dd-MMM-yy' : 'UTC'
          }}</span>
        </div>
        <div class="third-section">
          <span>{{ headerDetails?.priority ? headerDetails?.priority : ('print.none' | translate) }}</span>
          <span>{{
            headerDetails?.controlledDocument ? ('general.yes' | translate) : ('general.no' | translate)
          }}</span>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="label">
        <div class="first-section">
          <span>{{ 'general.package' | translate }} {{ 'general.managerUser' | translate }}:</span>
          <span>{{ 'general.package' | translate }} {{ 'print.health' | translate }}:</span>
          <span>{{ 'general.package' | translate }} {{ 'general.actualComplete' | translate }}:</span>
          <span>{{ 'general.package' | translate }} {{ 'general.plannedComplete' | translate }}:</span>
        </div>
        <div class="second-section">
          <span>{{ 'general.package' | translate }} {{ 'general.start' | translate }}:</span>
          <span>{{ 'general.package' | translate }} {{ 'general.end' | translate }}:</span>
        </div>
        <div class="third-section">
          <span>{{ 'general.package' | translate }} {{ 'general.priority' | translate }}:</span>
          <span>{{ 'package.criticalPackage' | translate }}:</span>
        </div>
      </div>
      <div class="value">
        <div class="first-section">
          <span>{{ packageDetails.manager ? packageDetails.manager : ('print.notAssigned' | translate) }} </span>
          <span>{{ packageStatusDetails.text | translate | capitalizeFirstLetter }} </span>
          <span>{{ packageDetails.actualComplete | number: '1.0-0' }}%</span>
          <span>{{ packageDetails.plannedComplete | number: '1.0-0' }}%</span>
        </div>
        <div class="second-section">
          <span>{{ packageDetails.startDate | date: 'dd-MMM-yy' : 'UTC' }} </span>
          <span>{{ packageDetails.endDate | date: 'dd-MMM-yy' : 'UTC' }} </span>
        </div>
        <div class="third-section">
          <span>{{
            packageDetails?.packagePriority ? packageDetails?.packagePriority : ('print.none' | translate)
          }}</span>
          <span>{{ packageDetails?.criticalPackage ? ('general.yes' | translate) : ('general.no' | translate) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <span>{{ 'deliverable.notes' | translate }}: </span><span [innerHTML]="notes"></span>
  </div>
  <div *ngIf="headerDetails?.holdReason">
    <span>{{ 'deliverable.onHoldReason' | translate }}: {{ headerDetails.holdReason }}</span>
  </div>
</div>
