import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyInput } from '@angular/material/legacy-input';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Tag } from 'src/app/common-models/tag.model';

enum KeyCode {
  Space = 32,
  Enter = 13,
  Escape = 27,
}

@Component({
  selector: 'app-searchable-input',
  templateUrl: './searchable-input.component.html',
  styleUrls: ['./searchable-input.component.scss'],
})
export class SearchableInputComponent implements AfterViewInit {
  optionCtrl = new UntypedFormControl();
  filteredOptions: Observable<{ group: string; tags: Tag[] }[]>;
  @Input() placeholder: string;
  @Input() remoteSearch: (value: string) => Observable<{ recentTags: Tag[]; tags: Tag[] }>;
  @Output() edited = new EventEmitter();
  @Output() optionSelected = new EventEmitter<Tag>();
  @ViewChild(MatLegacyAutocomplete) matAutocomplete;
  @ViewChildren(MatLegacyInput) matInput: QueryList<any>;

  groupedOption: any;

  constructor(private translateService: TranslateService) {}

  ngAfterViewInit(): void {
    setTimeout((_) => {
      this.matInput.first._elementRef.nativeElement.focus();
      this.filteredOptions = this.optionCtrl.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        switchMap((value: string) => this.remoteSearch(value)),
        map((option) => {
          return [
            {
              group: this.translateService.instant('general.recent').toLocaleUpperCase(),
              tags: option.recentTags,
            },
            {
              group: this.translateService.instant('general.otherTags').toLocaleUpperCase(),
              tags: option.tags,
            },
          ];
        }),
      );
    });
  }

  save(): void {
    setTimeout(() => {
      if (this.optionCtrl.value != null) {
        this.edited.emit();
        this.emitBasedOnType();
      } else {
        this.edited.emit();
      }
    }, 300);
  }

  displayFn(tag: Tag): string {
    return tag && tag.name ? tag.name : '';
  }

  onKeyPressed($event): void {
    if ($event.keyCode === KeyCode.Escape) {
      this.edited.emit();
      return;
    }

    if ($event.keyCode === KeyCode.Enter) {
      this.edited.emit();
      this.emitBasedOnType();
      $event.preventDefault();
      return;
    }

    if ($event.keyCode === KeyCode.Space) {
      $event.stopPropagation();
    }
  }

  private emitBasedOnType(): void {
    if (typeof this.optionCtrl.value === 'string') {
      this.optionSelected.emit({ id: 0, name: this.optionCtrl.value });
    } else {
      this.optionSelected.emit(this.optionCtrl.value);
    }
  }
}
