<div class="download-template-container">
  <span class="borderless-button" (click)="downloadProjectImportTemplate()">
    {{ 'projectImport.downloadTemplate' | translate }}
    <mat-icon class="arrow-downward-icon">arrow_downward</mat-icon>
  </span>
</div>

<form [formGroup]="form" class="file-path-container">
  <div class="file-path-input-container">
    <mat-form-field appearance="outline" floatLabel="always" class="orms-outline-input">
      <mat-label>{{ 'projectImport.file' | translate }}</mat-label>
      <input #fileInput type="file" (change)="onFileSelected($event)" class="file-input" accept=".xlsx" />
      <input matInput formControlName="filePath" [placeholder]="'projectImport.noFileChosen' | translate" readonly />
    </mat-form-field>
  </div>

  <button type="button" class="orms-button orms-button-secondary cancel-button" (click)="fileInput.click()">
    {{ 'projectImport.chooseFile' | translate }}
  </button>
</form>

<app-multi-step-footer
  [nextStepLabel]="'general.continue'"
  [cancelLabel]="'general.cancel'"
  [nextStepDisabled]="isNextStepDisabled"
  (cancelClick)="cancel()"
  (nextStepClick)="next()"
>
</app-multi-step-footer>
