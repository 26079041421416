<form [formGroup]="form" autocomplete="off">
  <h2>
    {{ 'general.move' | translate }}
    {{ originalNodeTitle }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <p>
    {{ 'moveSubfunctionModal.selectDestination' | translate }}
    {{ originalNodeTitle }}
    {{ data.subfunctionName }}:
  </p>
  <div class="orms-label small">{{ 'moveSubfunctionModal.destination' | translate }} {{ destinationNodeTitle }}</div>
  <div class="search-box">
    <ng-select
      appendTo="body"
      [items]="treeParent$ | async"
      bindLabel="displayName"
      [placeholder]="'moveSubfunctionModal.typeCodeOrName' | translate"
      [minTermLength]="2"
      formControlName="selectedHierarchy"
      [hideSelected]="true"
      [clearable]="false"
      [multiple]="false"
      [loading]="loading"
      [typeahead]="treeParentInput$"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label subfunction-pill">{{ item.displayName }}</span>
        <span class="ng-value-icon right subfunction-clear" (click)="clear(item)" aria-hidden="true">x</span>
      </ng-template>
    </ng-select>
  </div>
  <div class="label">{{ 'movePackageModal.justification' | translate }}</div>
  <div class="justification-section">
    <editor
      [init]="editorConfig"
      formControlName="justification"
      class="orms-editor"
      [class.is-invalid]="showInvalidState('justification')"
    ></editor>
  </div>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        class="orms-button orms-button-primary submit-button"
        type="button"
        (click)="confirm()"
        [disabled]="!form.valid || submissionInProgress"
      >
        {{ 'general.confirm' | translate }}
      </button>
      <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </button>
      <app-spinner [diameter]="26" *ngIf="submissionInProgress"></app-spinner>
    </div>
  </div>
</form>
