import { Component, Input, OnInit } from '@angular/core';
import { RelationshipType } from '../PackageRelationship.model';

@Component({
  selector: 'app-relationship-type-icon',
  templateUrl: './relationship-type-icon.component.html',
})
export class RelationshipTypeIconComponent implements OnInit {
  @Input() type: RelationshipType;
  urlSource = '/assets/icons/icon-successor.svg';
  tooltipTranslationText = '';
  iconsOrangeMap = new Map([
    [RelationshipType.Related, '/assets/icons/icon-related-orange.svg'],
    [RelationshipType.Successor, '/assets/icons/icon-successor-orange.svg'],
    [RelationshipType.Predecessor, '/assets/icons/icon-predecessor-orange.svg'],
  ]);

  iconsMap = new Map([
    [RelationshipType.Related, '/assets/icons/icon-related.svg'],
    [RelationshipType.Successor, '/assets/icons/icon-successor.svg'],
    [RelationshipType.Predecessor, '/assets/icons/icon-predecessor.svg'],
  ]);

  ngOnInit(): void {
    this.urlSource = this.iconsMap.get(this.type);
    switch (this.type) {
      case RelationshipType.Related:
        this.tooltipTranslationText = 'relationships.related';
        break;
      case RelationshipType.Predecessor:
        this.tooltipTranslationText = 'relationships.predecessor';
        break;
      case RelationshipType.Successor:
        this.tooltipTranslationText = 'relationships.successor';
        break;
    }
  }

  mouseOver(): void {
    this.urlSource = this.iconsOrangeMap.get(this.type);
  }

  mouseOut(): void {
    this.urlSource = this.urlSource = this.iconsMap.get(this.type);
  }
}
