<div class="upload-details">
  <h2>{{ 'projectTemplateUpload.uploadTemplate' | translate }}</h2>
  <input hidden type="file" #uploader (change)="uploadStructure($event)" accept=".xlsx" />
  <div class="table-container" *ngIf="!changeRequestRequired">
    <app-error-box *ngIf="validationErrors" [errorMessage]="'projectTemplateUpload.bulkUploadFailed' | translate">
    </app-error-box>
    <app-spinner *ngIf="fileProcessing" [absoluteOverlay]="true"></app-spinner>
    <cdk-virtual-scroll-viewport class="error-list" tvsItemSize="64" *ngIf="validationErrors">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="line">
          <th id="treestructure_line" mat-header-cell class="table-date" *matHeaderCellDef>
            {{ 'projectTemplateUpload.lineNumber' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.line }}
          </td>
        </ng-container>
        <ng-container matColumnDef="message">
          <th id="treestructure_message" mat-header-cell *matHeaderCellDef>
            {{ 'projectTemplateUpload.message' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.error | translate }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="errorColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: errorColumns"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
  <div class="upload-tree-change-request-section" *ngIf="changeRequestRequired">
    <div class="header">
      {{ 'projectTemplateUpload.changeRequestRequiredTitle' | translate }}
    </div>
    <form autocomplete="off">
      <div class="section-label">{{ 'bulkOperation.title' | translate }}</div>
      <div class="custom-textbox">
        <input
          cdkFocusInitial
          autocomplete="off"
          type="text"
          class="form-control shadow-input"
          maxlength="150"
          [placeholder]="'bulkOperation.titleOfChangeRequestPlaceholder' | translate"
          [formControl]="getChangeRequestControl('title')"
          [class.is-invalid]="showChangeRequestControlInvalidState('title')"
        />
      </div>
      <div class="section-label">{{ 'bulkOperation.justification' | translate }}</div>
      <editor
        [init]="editorConfig"
        class="orms-editor justification-editor"
        [formControl]="getChangeRequestControl('description')"
        [class.is-invalid]="showChangeRequestControlInvalidState('description')"
      >
      </editor>
    </form>
  </div>
  <div class="action-buttons">
    <div class="manage-buttons">
      <button
        [disabled]="fileProcessing"
        class="orms-button"
        [ngClass]="{
          'orms-button-primary submit-button': !changeRequestRequired,
          'orms-button-secondary': changeRequestRequired
        }"
        type="button"
        (click)="uploader.click()"
      >
        {{ 'projectTemplateUpload.browseFile' | translate }}
      </button>
      <button
        *ngIf="changeRequestRequired"
        [disabled]="fileProcessing || changeRequestForm.invalid"
        class="orms-button orms-button-primary submit-button"
        type="button"
        (click)="uploadStructureWithCR()"
      >
        {{ 'general.submit' | translate }}
      </button>
      <div class="submit-spinner-wrapper">
        <app-spinner [diameter]="26" *ngIf="fileProcessing && changeRequestRequired"></app-spinner>
      </div>
    </div>
    <button [disabled]="fileProcessing" class="orms-button orms-button-secondary" type="button" mat-dialog-close>
      {{ 'general.cancel' | translate }}
    </button>
  </div>
</div>
