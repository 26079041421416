import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromCamelCase',
})
export class FromCamelCasePipe implements PipeTransform {
  transform(camelCaseValue: string): string {
    if (camelCaseValue) {
      const result = camelCaseValue.replace(/([A-Z])/g, ' $1').trim();
      return result.replace(result[0], result[0].toUpperCase());
    }
    return '';
  }
}
