import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { getHealthValue } from './healthColorMap';
import { DeliverableStatus, getStatusDetailIncludingHealthValues, StatusCode } from './statusCodesMap';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent implements OnChanges {
  @Input() status: StatusCode | DeliverableStatus;
  @Input() health: number;

  displayStatus: string;
  healthValue: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status || changes.health) {
      const statusDetails = getStatusDetailIncludingHealthValues(this.status, this.health);
      this.displayStatus = statusDetails.text;
      this.healthValue = getHealthValue(statusDetails.healthValue);
    }
  }
}
