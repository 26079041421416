import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diff',
})
export class DiffPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  transform(value: number, prepend?: string, ...args: unknown[]): unknown {
    if (!value) {
      return '-';
    }
    const val = Math.abs(value);
    return `${value > 0 ? '+' : '-'}${prepend ? prepend : ''}${
      prepend ? this.decimalPipe.transform(val, '1.0-0') : val
    }`;
  }
}
