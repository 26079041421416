<ng-container *ngIf="crs && canDisplayList">
  <mat-accordion class="orms-expansion-panel">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'general.pendingChangeRequests' | translate }} ({{ numberOfCrs }}) </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="firstFiveCrs.length" class="bulk-actions">
        <span *ngIf="selection?.selected?.length"
          ><b>({{ selection.selected.length }})</b> {{ 'general.of' | translate }} {{ crs.length }}
          {{ 'general.selected' | translate }}</span
        >
        <span *ngIf="!selection?.selected?.length"
          >{{ selection.selected.length }} {{ 'general.selected' | translate }}</span
        >
        <ng-select
          [items]="availableActions"
          bindLabel="label"
          placeholder="{{ 'general.action' | translate }}"
          (change)="onBulkChanged($event)"
          [hideSelected]="true"
          [searchable]="false"
          [clearable]="false"
        >
          <ng-template ng-label-tmp let-item="item">
            <img [src]="item.icon" width="14px" height="14px" />
            {{ item.label }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <img [src]="item.icon" width="14px" height="14px" />
            {{ item.label }}
          </ng-template>
        </ng-select>
        <button
          [disabled]="!selection.selected.length || !selectedAction"
          class="orms-button orms-button-primary"
          (click)="submit()"
        >
          {{ 'general.submit' | translate }}
        </button>
      </div>
      <table
        class="cr-list"
        *ngIf="firstFiveCrs.length"
        mat-table
        [dataSource]="firstFiveCrs"
        attr.aria-label="{{ 'general.pendingChangeRequests' | translate }}"
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (change)="onCheckBoxChange($event, row); ctrlSelection($event, row.changeNoticeId)"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-header-cell class="orms-dl-120" *matHeaderCellDef scope="col">
            {{ 'general.code' | translate }}
          </th>
          <td mat-cell class="orms-dl-120" title="{{ element.code }}" *matCellDef="let element">
            {{ element.code | ellipsis: 15 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell class="orms-dl-50 title" *matHeaderCellDef scope="col">
            {{ 'general.packageOrFunction' | translate }}
          </th>
          <td mat-cell class="orms-dl-50 title" *matCellDef="let element">
            <a
              class="orms-link-button"
              title="{{ element.title }}"
              (click)="navigateTo(element.projectId, element.functionalHierarchyId, element.packageId)"
            >
              {{ element.title | ellipsis: 20 }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="changeNoticeTitle">
          <th mat-header-cell class="orms-dl-150" *matHeaderCellDef scope="col">
            {{ 'general.title' | translate }}
          </th>
          <td mat-cell class="orms-dl-150" *matCellDef="let element">
            <a
              class="orms-link-button"
              title="{{ element.changeNoticeTitle }}"
              (click)="viewDetails(element.changeNoticeId, element.canReject, element.canApprove, element.projectId)"
            >
              {{ element.changeNoticeTitle | ellipsis: 50 }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="approver">
          <th mat-header-cell class="orms-dl-120" *matHeaderCellDef scope="col">
            {{ 'changeRequest.approver' | translate }}
          </th>
          <td mat-cell class="orms-dl-120" *matCellDef="let element">
            {{ element.approver }}
          </td>
        </ng-container>
        <ng-container matColumnDef="daysOutstanding">
          <th mat-header-cell class="orms-dl-80" *matHeaderCellDef scope="col">
            {{ 'general.daysOutstanding' | translate }}
          </th>
          <td mat-cell class="orms-dl-80" *matCellDef="let element">
            {{ element.daysOutstanding }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="orms-dl-50" scope="col"></th>
          <td mat-cell *matCellDef="let element" class="orms-dl-50">
            <app-action-menu [item]="element" [actions]="actions">
              <mat-icon>more_vert</mat-icon>
            </app-action-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="orms-deliverable-element-row"></tr>
      </table>
      <div *ngIf="crs.length > firstFiveItems">
        <mat-expansion-panel class="mat-elevation-z0 show-more" [expanded]="false" (opened)="showMore()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'general.showMore' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </div>
      <div *ngIf="!firstFiveCrs.length" class="no-requests">
        {{ 'general.noChangeRequests' | translate }}
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
