<form [formGroup]="form" autocomplete="off" class="orms-cr-submission-form" (ngSubmit)="onSubmit()">
  <h2>
    {{ 'changeRequest.cancellationChangeRequest' | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <input
    class="form-control orms-simple-input"
    formControlName="title"
    [placeholder]="'changeRequest.enterTheTitleOfYourRequest' | translate"
    maxlength="150"
    [class.is-invalid]="showInvalidState('title')"
  />
  <mat-tab-group #tabGroup class="orms-tabs cr-submit-tabs">
    <mat-tab label="{{ 'changeRequest.justification' | translate }}" class="orms-tab">
      <editor
        [init]="editorConfig"
        formControlName="justification"
        class="orms-editor justification-editor"
        [class.is-invalid]="showInvalidState('justification')"
      ></editor>
    </mat-tab>
  </mat-tab-group>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button class="orms-button orms-button-primary submit-button" type="submit" [disabled]="submissionInProgress">
        {{ 'general.submit' | translate }}
      </button>
      <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </button>
      <app-spinner [diameter]="26" *ngIf="submissionInProgress"></app-spinner>
    </div>
    <div>{{ 'changeRequest.approver' | translate }}: {{ approver$ | async }}</div>
  </div>
</form>
