import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { getShareIdSelector } from 'src/app/root-store/root-store.selector';
import { AppConfigService } from '../services/app.config.service';
import { CurrentUser } from './currentUser';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUser$: Observable<CurrentUser>;

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private store$: Store<AppState>,
  ) {
    this.store$
      .select(getShareIdSelector)
      .pipe(
        filter((x) => !!x && x.hasState),
        map((x) => x.id),
      )
      .subscribe((id) => {
        if (!id) {
          this.currentUser$ = this.getCurrentUser().pipe(shareReplay());
        }
      });
  }

  private getCurrentUser(): Observable<CurrentUser> {
    const { baseUrl, currentUser } = this.appConfigService.settings.api.endpoints;
    return this.httpClient.get<CurrentUser>(baseUrl + currentUser);
  }
}
