<div class="vertical-center">
  <div class="top-row">
    <button *ngIf="canManagePortfolio$ | async" class="orms-button orms-button-primary" (click)="addProject()">
      {{ 'project.addProject' | translate }}
    </button>
  </div>

  <ng-container *ngIf="portfolioId">
    <app-change-requests-pending-approval [portfolioId]="portfolioId"> </app-change-requests-pending-approval>
    <div class="section-spacer"></div>
    <app-favorite-packages [portfolioId]="portfolioId"> </app-favorite-packages>
    <div class="section-spacer"></div>
    <app-recent-projects [portfolioId]="portfolioId"> </app-recent-projects>
  </ng-container>
</div>

<app-spinner id="main-spinner" *ngIf="!portfolioId"></app-spinner>
