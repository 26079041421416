import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { getPriorities } from 'src/app/common-models/priority.model';
import { AppState } from 'src/app/root-store/app-state';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormUpdateConfig,
  watchControlsWithoutValidation,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import * as treeActions from '../../../../tree-navigation/tree-state/tree.actions';
import { Detail } from '../detail.model';

@Component({
  selector: 'app-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoSectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() detailData: Detail;
  @Input() entityType: EntityTypes;
  @Input() editableFields: string[];

  priorities: Map<number, string>;
  subscription = new Subscription();
  form: UntypedFormGroup;
  private controlsValueSubscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private translateService: TranslateService,
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.priorities = getPriorities(this.translateService);
    const translationSub = this.translateService.onLangChange.subscribe(() => {
      this.priorities = getPriorities(this.translateService);
    });

    this.subscription.add(translationSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('detailData' in changes) {
      this.setControlWatchers();
      this.fillForm();
      disableFormFieldsBaseOnPermissions(this.form, this.isEditable.bind(this));
    }
  }

  ngOnDestroy(): void {
    this.controlsValueSubscription.unsubscribe();
    this.subscription?.unsubscribe();
  }

  private isEditable(name: string): boolean {
    return this.editableFields?.includes(name.toLocaleLowerCase());
  }

  updateProperty(key: string, value: any): void {
    if (key === 'priority' && value === 0) {
      value = null;
    }

    const update: Update<Detail> = {
      id: this.detailData.functionalHierarchyId,
      changes: {
        [key]: value,
      },
    };

    this.store$.dispatch(
      treeActions.updateDetailProperty({
        entityType: this.entityType,
        key,
        update,
      }),
    );
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        priority: new UntypedFormControl(),
        isCritical: new UntypedFormControl(),
      },
      { updateOn: 'change' },
    );
  }

  private fillForm(): void {
    const { priority, isCritical } = this.detailData;
    this.form.patchValue(
      {
        priority: priority || 0,
        isCritical: isCritical || 0,
      },
      { ...shadowFormUpdateConfig },
    );
  }

  private setControlWatchers(): void {
    this.controlsValueSubscription.unsubscribe();
    this.controlsValueSubscription = watchControlsWithoutValidation(this.form).subscribe(({ name, value }) =>
      this.updateProperty(name, value),
    );
  }
}
