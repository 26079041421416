export class ProjectListItem {
  projectId: number;
  projectTitle: string;
  lastVisited: Date;
  projectManager: string;
  logo: string;
  src: string;
}

export const FileTypeMap = new Map<string, string>([
  ['/', 'data:image/jpg;base64,'],
  ['i', 'data:image/png;base64,'],
  ['R', 'data:image/gif;base64,'],
  ['U', 'data:image/webp;base64,'],
]);
