import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { dashDayDateFormat, timezoneUTC } from 'src/app/shared/date-options/short-date-format';

@Pipe({
  name: 'formatHistoryValue',
})
export class FormatHistoryValuePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(value: string | number | Date, fieldName?: string): string {
    if (fieldName === 'PercentageCompleted') {
      return `${value}%`;
    }
    if (moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
      return this.datePipe.transform(new Date(value), dashDayDateFormat, timezoneUTC);
    }
    return value ? value.toString() : `''`;
  }
}
