import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { ChangeRequestDeliverable, RequestType } from '../../change-request-cart-state/cart-item.model';
import { FullChangeRequestModel } from '../full-change-request';

@Component({
  selector: 'app-cr-impact',
  templateUrl: './cr-impact.component.html',
  styleUrls: ['./cr-impact.component.scss'],
})
export class CrImpactComponent implements OnChanges {
  @Input() changeRequest: FullChangeRequestModel;

  impactDataSource = [];
  impactColumns = [
    'code',
    'startDate',
    'endDate',
    'duration',
    'newDeliverables',
    'cancelledDeliverables',
    'hours',
    'cost',
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if ('changeRequest' in changes) {
      this.calculateImpact();
    }
  }

  private calculateImpact(): void {
    const { packages, deliverables } = this.changeRequest;
    this.impactDataSource = packages.reduce((impactData, crPackage) => {
      const { oldPlanStartDate, oldPlanEndDate, newPlanStartDate, newPlanEndDate, packageCode, functionalHierarchyId } =
        crPackage;
      const packageDeliverables = deliverables.filter((d) => d.packageFunctionalHierarchyId === functionalHierarchyId);
      const impactElement = {
        code: packageCode,
        startDate: getDifferenceInDays(oldPlanStartDate, newPlanStartDate),
        endDate: getDifferenceInDays(oldPlanEndDate, newPlanEndDate),
        duration: null,
        newDeliverables: packageDeliverables.filter((d) => d.request === RequestType.New).length,
        cancelledDeliverables: packageDeliverables.filter((d) => d.request === RequestType.Cancel).length,
        hours: countDeliverablesFieldChange(packageDeliverables, 'oldHours', 'newHours'),
        cost: countDeliverablesFieldChange(packageDeliverables, 'oldCost', 'newCost'),
      };
      impactElement.duration = (impactElement.endDate ?? 0) - (impactElement.startDate ?? 0);
      impactData.push(impactElement);
      return impactData;
    }, []);
  }
}

export const countDeliverablesFieldChange = (
  packageDeliverables: ChangeRequestDeliverable[],
  oldKey: keyof ChangeRequestDeliverable,
  newKey: keyof ChangeRequestDeliverable,
): number => {
  return packageDeliverables.reduce((sum, deliverable) => {
    sum += getImpactBasedOnRequestType(
      deliverable[oldKey] as number,
      deliverable[newKey] as number,
      deliverable.request,
    );
    return sum;
  }, 0);
};

export const getDifferenceInDays = (oldDate: Date | string, newDate: Date | string): number => {
  if (!oldDate || !newDate) {
    return null;
  }
  return differenceInDays(new Date(newDate), new Date(oldDate));
};

export const getImpactBasedOnRequestType = (oldValue: number, newValue: number, requestType: RequestType) => {
  switch (requestType) {
    case RequestType.New:
      return newValue;
    case RequestType.Change:
      return newValue - oldValue;
    case RequestType.Cancel:
      return -oldValue;
    case RequestType.Move:
      return 0;
  }
};
