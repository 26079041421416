import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector, getShareIdSelector } from 'src/app/root-store/root-store.selector';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { SharedPackageService } from '../../node-header/shared.service';

@Component({
  selector: 'app-deliverables-view-route',
  templateUrl: './deliverables-view-route.component.html',
  styleUrls: ['./deliverables-view-route.component.scss'],
})
export class DeliverablesViewRouteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() nodeId: number;
  @Input() displayedColumns: string[];
  data$ = new BehaviorSubject<DeliverableDetail[]>(null);
  projectId: number;
  curveProfiles$: Observable<CurveProfile[]>;
  shareId: string;
  private subscription = new Subscription();
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private curveProfilesService: CurveProfileService,
    private sharedService: SharedPackageService,
  ) {}

  ngOnInit(): void {
    const sub = combineLatest([
      this.store$.select(getProjectIdSelector),
      this.store$.select(getShareIdSelector),
    ]).subscribe(([projectId, { id }]) => {
      this.projectId = projectId;
      this.shareId = id;

      this.sharedService.getViewDeliverables(id).subscribe((deliverables) => {
        this.data$.next(
          deliverables.map((x) => ({
            ...x,
            packageUrl: ['/project/', projectId, 'share', id, EntityTypes.package, this.nodeId, 'detail'],
            deliverableUrl: ['/project/', projectId, 'share', id, EntityTypes.deliverable, x.deliverableId, 'detail'],
          })),
        );
      });
    });

    this.subscription.add(sub);

    this.curveProfiles$ = this.curveProfilesService.curveProfiles$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('nodeId' in changes) {
      this.data$.next(null);
    }
  }

  navigateToDeliverable(deliverableId: number): void {
    const projectIdSubscription = this.store$
      .select(getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.router.navigate(
          ['/project/', projectId, 'share', this.shareId, EntityTypes.deliverable, deliverableId, 'detail'],
          {
            state: {
              returnId: this.nodeId,
            },
          },
        );
      });
    this.subscription.add(projectIdSubscription);
  }

  navigateToPackage(packageFunctionalHierarchyId: number): void {
    const projectIdSubscription = this.store$
      .select(getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.router.navigate([
          '/project/',
          projectId,
          'share',
          this.shareId,
          EntityTypes.deliverable,
          packageFunctionalHierarchyId,
          'detail',
        ]);
      });
    this.subscription.add(projectIdSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
