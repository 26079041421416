import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { ChangeRequestPackage } from 'src/app/project-management/change-request-cart/change-request-cart-state/cart-item.model';
import * as packageActions from 'src/app/project-management/change-request-cart/change-request-cart-state/packages/package.actions';
import * as packageSelectors from 'src/app/project-management/change-request-cart/change-request-cart-state/packages/package.selectors';
import { RefreshCRTriggerService } from 'src/app/project-management/change-request-cart/refresh-cr-trigger.service';
import { TreeActionsService } from 'src/app/project-management/tree-navigation/tree-actions-menu/tree-actions.service';
import { updateNodeProperty } from 'src/app/project-management/tree-navigation/tree-state/tree.actions';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormConfig,
  shadowFormUpdateConfig,
  watchControls,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { Detail, DetailKeys } from '../detail.model';

@Component({
  selector: 'app-budged-section',
  templateUrl: './budged-section.component.html',
  styleUrls: ['./budged-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgedSectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() detailData: Detail;
  @Input() applyChangeRequestValues = false;
  @Input() entityType = null;
  @Input() underChangeControl: boolean;
  form: UntypedFormGroup;
  parentId: number;
  nodeCode: string;
  changeRequestPackage: ChangeRequestPackage;
  datepickerActionsComponent = DatepickerActionsComponent;

  private crSubscription = new Subscription();
  private controlsValueSubscription = new Subscription();
  private parentIdSubscription = new Subscription();
  private subscription = new Subscription();
  constructor(
    private store$: Store<AppState>,
    private refreshCRTriggerService: RefreshCRTriggerService,
    private treeActionsService: TreeActionsService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ('underChangeControl' in changes) {
      if (this.detailData?.functionalHierarchyId && this.underChangeControl !== null) {
        this.detailData = {
          ...this.detailData,
          underChangeControl: this.underChangeControl,
        };
      }
    }
    if ('detailData' in changes) {
      this.setControlWatchers();

      this.crSubscription.unsubscribe();
      this.crSubscription = this.refreshCRTriggerService.refreshPackagesTrigger$
        .pipe(
          switchMap(() =>
            this.store$.select(
              packageSelectors.getCrPackage({
                functionalHierarchyId: this.detailData.functionalHierarchyId,
              }),
            ),
          ),
        )
        .subscribe((changeRequestPackage) => {
          this.changeDetector.markForCheck();
          this.changeRequestPackage = changeRequestPackage;
          this.fillForm();
          disableFormFieldsBaseOnPermissions(this.form, this.isEditable.bind(this));
        });

      this.parentIdSubscription.unsubscribe();
      this.parentIdSubscription = this.store$
        .select(
          TreeSelectors.getParentId({
            id: this.detailData.functionalHierarchyId,
          }),
        )
        .subscribe((parent) => (this.parentId = parent));
    }
  }

  ngOnInit(): void {
    const routerIdSelectSub = this.store$
      .select(routerSelectors.getIdSelector)
      .pipe(
        switchMap((id) =>
          this.store$.select(
            TreeSelectors.getTreeNodeByFunctionalHierarchyId({
              functionalHierarchyId: id,
            }),
          ),
        ),
        // put in one selector
      )
      .subscribe((treeNode) => (this.nodeCode = treeNode.code));
    this.subscription.add(routerIdSelectSub);
  }

  ngOnDestroy(): void {
    this.parentIdSubscription?.unsubscribe();
    this.crSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  updateProperty(): void {
    const crp = new ChangeRequestPackage();
    crp.packageCode = this.nodeCode;
    crp.functionalHierarchyId = this.detailData.functionalHierarchyId;
    crp.functionId = this.parentId;
    crp.oldPlanStartDate = this.detailData.planStartDate;
    crp.newPlanStartDate = this.form.get(DetailKeys.planStartDate).value;
    crp.oldPlanEndDate = this.detailData.planEndDate;
    crp.newPlanEndDate = this.form.get(DetailKeys.planEndDate).value;
    crp.oldForecastEndDate = this.detailData.forecastEndDate;
    crp.newForecastEndDate = this.form.get(DetailKeys.forecastEndDate).value;
    if (this.detailData.underChangeControl) {
      this.store$.dispatch(
        packageActions.addPackageChange({
          crPackage: crp,
        }),
      );
    } else {
      const update: Update<any> = {
        id: this.detailData.functionalHierarchyId,
        changes: {},
      };

      if (crp.newPlanStartDate !== crp.oldPlanStartDate) {
        update.changes.startDate = new Date(crp.newPlanStartDate);
      }
      if (crp.newPlanEndDate !== crp.oldPlanEndDate) {
        update.changes.endDate = new Date(crp.newPlanEndDate);
      }
      if (crp.newForecastEndDate !== crp.oldForecastEndDate) {
        update.changes.forecastEndDate = new Date(crp.newForecastEndDate);
      }
      if (!Object.keys(update.changes).length) {
        return;
      }

      this.store$.dispatch(
        updateNodeProperty({
          key: 'packageDates',
          entityType: EntityTypes.package,
          update: this.treeActionsService.mergeFunctionalHierarchyIdUpdates(update),
        }),
      );
    }
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        planStartDate: new UntypedFormControl(null, Validators.required),
        planEndDate: new UntypedFormControl(null, Validators.required),
        forecastEndDate: new UntypedFormControl(),
      },
      { ...shadowFormConfig },
    );
  }

  private fillForm(): void {
    if (!this.detailData) {
      this.form.patchValue(
        {
          planStartDate: null,
          planEndDate: null,
          forecastEndDate: null,
        },
        { ...shadowFormUpdateConfig },
      );
      return;
    }

    let { planStartDate, planEndDate, forecastEndDate } = this.detailData;

    if (this.changeRequestPackage) {
      planStartDate = this.changeRequestPackage.newPlanStartDate || planStartDate;
      planEndDate = this.changeRequestPackage.newPlanEndDate || planEndDate;
      forecastEndDate = this.changeRequestPackage.newForecastEndDate || forecastEndDate;
    }

    this.form.patchValue(
      {
        planStartDate,
        planEndDate,
        forecastEndDate,
      },
      { ...shadowFormUpdateConfig },
    );
  }

  private isEditable(name: string): boolean {
    return this.detailData?.editableFields?.includes(name.toLocaleLowerCase());
  }

  private setControlWatchers(): void {
    this.controlsValueSubscription.unsubscribe();
    this.form.controls[DetailKeys.planStartDate].setErrors({ incorrect: true });
    this.form.controls[DetailKeys.planEndDate].setErrors({ incorrect: true });

    this.controlsValueSubscription = watchControls(this.form).subscribe(() => this.updateProperty());
  }
}
