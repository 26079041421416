import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../shared/services/app.config.service';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
  apiVersion: string;
  apiBaseUrl: string;
  constructor(appConfigService: AppConfigService) {
    this.apiVersion = appConfigService.settings.api.apiVersion;
    this.apiBaseUrl = appConfigService.settings.api.endpoints.baseUrl;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(this.apiBaseUrl)) {
      return next.handle(req);
    }
    const requestWithApiVersion = req.clone({
      params: req.params.set('api-version', this.apiVersion),
    });

    return next.handle(requestWithApiVersion);
  }
}
