import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromState from '../state';
import { ChangeRequestCartState } from '../state';
import { crDeliverableAdapter } from './crDeliverable.reducer';

export const changeRequestState = createFeatureSelector<fromState.ChangeRequestCartState>('cartItems');

const { selectEntities, selectAll, selectTotal } = crDeliverableAdapter.getSelectors();

export const selectDeliverableEntities = selectEntities;
export const selectAllDeliverables = selectAll;
export const selectDeliverablesTotal = selectTotal;

const getAllCrDeliverables = createSelector(changeRequestState, (state: ChangeRequestCartState) => {
  return Object.values(state.deliverables.entities);
});

const getAllMrDeliverables = createSelector(changeRequestState, (state: ChangeRequestCartState) => {
  return Object.values(state.moveDeliverables.entities);
});

const getCrDeliverable = (props: { deliverableId: number }) =>
  createSelector(changeRequestState, (state: ChangeRequestCartState) => {
    return Object.values(state.deliverables.entities).find((p) => p.deliverableId === props.deliverableId);
  });

const getMrDeliverable = (props: { deliverableId: number }) =>
  createSelector(changeRequestState, (state: ChangeRequestCartState) => {
    return Object.values(state.moveDeliverables.entities).find((p) => p.deliverableId === props.deliverableId);
  });

const isLastCrDeliverableForPackageFunctionalHierarchyId = (props: { packageFunctionalHierarchyId: number }) =>
  createSelector(changeRequestState, (state: ChangeRequestCartState) => {
    return !Object.values(state.deliverables.entities).find(
      (p) => p.packageFunctionalHierarchyId === props.packageFunctionalHierarchyId,
    );
  });

export const CrDeliverableSelectors = {
  getAllMrDeliverables,
  getAllCrDeliverables,
  getCrDeliverable,
  getMrDeliverable,
  isLastCrDeliverableForPackageFunctionalHierarchyId,
};
