export enum EntityTypes {
  project = 'project',
  function = 'function',
  subFunction = 'subFunction',
  package = 'package',
  deliverable = 'deliverable',
}

export enum TreeLevelTypes {
  Project = 1,
  Function = 2,
}

export enum CodeTypes {
  Project = 'PR',
  Function = 'FN',
  SubFunction = 'SF',
  Package = 'PK',
  Deliverable = 'DL',
}

export const NodeTypeMap = new Map<string, EntityTypes>([
  ['PR', EntityTypes.project],
  ['FN', EntityTypes.function],
  ['SF', EntityTypes.function],
  ['PK', EntityTypes.package],
  ['DL', EntityTypes.deliverable],
]);

export const getKeyByValue = (val): string => {
  return [...NodeTypeMap].find(([_, value]) => val === value)?.[0];
};
