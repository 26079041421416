import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { AppConfigService } from '../services/app.config.service';

export function loggerCallback(logLevel: LogLevel, message: string) {
  // This is the place to add logging if needed to debug msal. Does nothing unless changed.
}

export class MsalFunctions {
  static MSALInstanceFactory(appConfigService: AppConfigService): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: appConfigService.settings.msal.publicClient.clientId,
        redirectUri: appConfigService.settings.msal.publicClient.redirectUri,
        postLogoutRedirectUri: appConfigService.settings.msal.publicClient.postLogoutRedirectUri,
        authority: appConfigService.settings.msal.publicClient.authority,
        knownAuthorities: appConfigService.settings.msal.publicClient.knownAuthorities,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  }

  static MSALInterceptorConfigFactory(appConfigService: AppConfigService): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(
      appConfigService.settings.msal.interceptor.endpoint,
      appConfigService.settings.msal.interceptor.scopes,
    );
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        authority: appConfigService.settings.msal.publicClient.authority,
      },
      protectedResourceMap,
    };
  }

  static MSALGuardConfigFactory(appConfigService: AppConfigService): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: appConfigService.settings.msal.guard.requestScopes,
      },
      loginFailedRoute: appConfigService.settings.msal.guard.loginFailedRoute,
    };
  }
}
