import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { getIncludeCancelledItems } from 'src/app/root-store/root-store.selector';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectService } from 'src/app/shared/services/project-service';
import { ShadowInputValidationService } from 'src/app/shared/services/shadow-input-validation.service';
import { ApiService } from '../../common/api.service';
import { TreeActionsService } from '../../tree-navigation/tree-actions-menu/tree-actions.service';
import { TreeNode } from '../tree-structure.model';
import { TreeStructureService } from '../tree-structure.service';
import * as treeActions from './tree.actions';
import { cleanDataForFiltering } from './tree.reducer';

@Injectable()
export class TreeStoreEffects {
  constructor(
    private store$: Store<AppState>,
    private actions: Actions,
    private apiService: ApiService,
    private treeService: TreeStructureService,
    private treeActionsService: TreeActionsService,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private shadowInputValidationService: ShadowInputValidationService,
  ) {}

  getProjectHierarchyRuleDataRequest = createEffect(() =>
    this.actions.pipe(
      ofType(treeActions.getProjectHierarchyRulesData),
      filter(({ projectId }) => !!projectId),
      switchMap(({ projectId }) =>
        this.projectService.getProjectHierarchyRuleData(projectId).pipe(
          map((projectHierarchyRules) => {
            return treeActions.getProjectHierarchyRulesDataSuccess({
              projectHierarchyRules,
            });
          }),
          catchError((error) => {
            showHttpErrorResponse(this.notificationService, error, 'general.getProjectHierarchyRuleDataFailed');
            return of(treeActions.getProjectHierarchyRulesDataFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updatePartialDataRequest = createEffect(() =>
    this.actions.pipe(
      ofType(treeActions.updateProjectPartialData),
      filter(({ projectId }) => !!projectId),
      mergeMap(({ projectId, functionalHierarchiesIds }) =>
        this.treeService.getTreePartialData(projectId, functionalHierarchiesIds).pipe(
          tap((treeData) => {
            treeData.forEach((x) => {
              const update: Update<TreeNode> = {
                id: x.functionalHierarchyId,
                changes: x,
              };
              this.treeService.treeNodeChanged$.next(update);
            });
          }),
          map((treeData) => {
            return treeActions.updatePartialTreeData({
              projectTree: treeData,
            });
          }),
          catchError((error) => {
            showHttpErrorResponse(this.notificationService, error, 'general.gettingProjectDataFailed');
            return of(treeActions.getProjectDataFailure({ error }));
          }),
        ),
      ),
    ),
  );

  reloadTreeData = createEffect(() =>
    this.actions.pipe(
      ofType(treeActions.reloadProjectData),
      filter(({ projectId }) => !!projectId),
      map(({ projectId }) => projectId),
      withLatestFrom(this.store$.select(getIncludeCancelledItems)),
      mergeMap(([projectId, includeCancelled]) =>
        this.treeService.getTreeData(projectId, includeCancelled).pipe(
          map((treeData) => {
            this.treeService.reloadTreeData$.next(cleanDataForFiltering(treeData));
            return treeActions.updatePartialTreeData({
              projectTree: treeData,
            });
          }),
          catchError((error) => {
            showHttpErrorResponse(this.notificationService, error, 'general.gettingProjectDataFailed');
            return of(treeActions.getProjectDataFailure({ error }));
          }),
        ),
      ),
    ),
  );

  moveNode = createEffect(() =>
    this.actions.pipe(
      ofType(treeActions.moveNode),
      switchMap(({ nodeMoveData }) => {
        this.treeService.treeMove$.next(nodeMoveData);
        return of(treeActions.moveSuccess(nodeMoveData));
      }),
    ),
  );

  updateNodeRequest = createEffect(() =>
    this.actions.pipe(
      ofType(treeActions.updateNodeProperty),
      switchMap(({ entityType, update, key }) =>
        this.apiService.updateProperty(entityType, update).pipe(
          map((patchedEntity) => {
            this.shadowInputValidationService.clearError(entityType, update.id, key);
            return treeActions.patchPropertySuccess({
              entityType,
              entity: patchedEntity,
            });
          }),
          catchError((error) => {
            // NOTE: apiService.updateProperty displays BUT DOES NOT bubble any errors up
            //       so this code is currently redundant!
            this.shadowInputValidationService.addError(entityType, update.id, key, error);
            showHttpErrorResponse(this.notificationService, error, 'general.updateFailed');
            return of(treeActions.updateNodePropertyError({ error }));
          }),
        ),
      ),
    ),
  );

  updateDetailRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(treeActions.updateDetailProperty),
        mergeMap(({ entityType, update, key }) =>
          this.apiService.updateProperty(entityType, update).pipe(
            tap(() => {
              this.shadowInputValidationService.clearError(entityType, update.id, key);
            }),
            catchError((error) => {
              this.shadowInputValidationService.addError(entityType, update.id, key, error);
              showHttpErrorResponse(this.notificationService, error, 'general.updateFailed');
              return of(null);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  // Each time the apiService's updateProperty() method is invoked the apiService merges update(s)
  // to the entity in a local map, and passes the merges updates to the back end. So that the entity's
  // updates map does not persist indefinitey we need to remove successful updates from the map,
  // otherwise they'll be re-sent to the back end on every patch.
  completePatchCycleEffect = createEffect(
    () =>
      this.actions.pipe(
        ofType(treeActions.patchPropertySuccess),
        map(({ entityType, entity }) => {
          this.treeActionsService.completeEntityPatch(entityType, entity);
        }),
      ),
    { dispatch: false },
  );
}
