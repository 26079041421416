<div class="access-denied">
  <div>
    <h1>{{ 'general.loginFailed' | translate }}</h1>
    <p>{{ 'general.pleaseContactManager' | translate }}</p>
  </div>
  <img src="/assets/images/access-denied.svg" alt="{{ 'imgAlt.accessDenied' | translate }}" />
</div>
<div class="return-bar-container">
  <div class="return-bar">
    <button class="orms-button orms-button-secondary return-button" type="button" (click)="backToMainPage()">
      {{ 'general.tryAgain' | translate }}
    </button>
  </div>
</div>
