import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppState } from './root-store/app-state';
import { getProjectIdSelector, getShareIdSelector } from './root-store/root-store.selector';
import { AppConfigService } from './shared/services/app.config.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { StorageKey } from './shared/services/storage-keys';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  projectId$: Observable<number>;
  shareId$: Observable<string>;
  constructor(
    private store$: Store<AppState>,
    private localStorage: LocalStorageService,
    private appConfigService: AppConfigService,
    private authService: MsalService,
  ) {}

  ngOnInit(): void {
    this.projectId$ = this.store$.select(getProjectIdSelector);
    this.shareId$ = this.store$.select(getShareIdSelector).pipe(
      tap((x) => {
        if (x && x.hasState && !x.id) {
          this.invalidateCachedTokens();
        }
      }),
      filter((x) => !!x && x.hasState),
      map((x) => x.id),
    );
  }

  invalidateCachedTokens() {
    const appVersionNumber = this.appConfigService.settings.version.number;
    const config = this.localStorage.getItem<{ versionNumber: string }>(StorageKey.appConfig);

    if (appVersionNumber !== config?.versionNumber) {
      this.localStorage.setItem<{ versionNumber: string }>(StorageKey.appConfig, { versionNumber: appVersionNumber });
      this.authService.logoutRedirect();
    }
  }
}
