import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import { PackageRelationshipItem, RelationshipType } from '../PackageRelationship.model';
import { PackageRelationshipsService } from '../package-relationships.service';
import { PackageBasicDetails } from './PackageBasicDetails.model';

@Component({
  selector: 'app-add-package-relationship',
  templateUrl: './add-package-relationship.component.html',
  styleUrls: ['./add-package-relationship.component.scss'],
})
export class AddPackageRelationshipComponent implements OnInit, OnDestroy {
  packages$: Observable<PackageBasicDetails[]>;
  packageInput$ = new Subject<string>();
  loading = false;
  form = new UntypedFormGroup({
    selectedPackages: new UntypedFormControl([]),
    type: new UntypedFormControl(RelationshipType.Predecessor),
  });

  RelationshipType = RelationshipType;
  @Input() relationships: PackageRelationshipItem[];
  @Input() currentPackageFunctionalId: number;
  @Output() addRelationship = new EventEmitter<{
    packageIds: number[];
    type: RelationshipType;
  }>();

  private subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private packageRelationshipsService: PackageRelationshipsService,
  ) {}

  ngOnInit(): void {
    this.loadPackages();

    const sub = this.packageRelationshipsService.clearRelationshipsFromSearch.subscribe(() => {
      this.form.get('selectedPackages').setValue([]);
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadPackages(): void {
    this.packages$ = concat(
      of([]),
      this.packageInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap((searchTerm) =>
          this.store$
            .select(TreeSelectors.getPackagesWithNameStartingFrom, {
              term: searchTerm,
              exclude: [this.currentPackageFunctionalId, ...this.relationships.map((r) => r.packageHierarchyId)],
            })
            .pipe(
              map((packageNodes) => {
                return packageNodes.map(
                  ({ functionalHierarchyId, title, code }) =>
                    ({
                      packageHierarchyId: functionalHierarchyId,
                      title,
                      code,
                      displayName: this.displayPackageTile(title, code),
                    }) as PackageBasicDetails,
                );
              }),
              catchError(() => of([])),
              tap(() => (this.loading = false)),
            ),
        ),
      ),
    );
  }

  onSubmit(): void {
    const { selectedPackages, type } = this.form.value;
    if (!selectedPackages.length) {
      return;
    }
    this.addRelationship.emit({
      packageIds: selectedPackages.map((p: PackageBasicDetails) => p.packageHierarchyId),
      type,
    });
  }

  private displayPackageTile(title: string, code: string): string {
    const codePart = code ? ' <' + code + '>' : '';
    return title + codePart;
  }
}
