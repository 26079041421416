import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import 'chartjs-adapter-moment';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject, Subscription, combineLatest, merge } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { SignalRService } from 'src/app/project-management/signalr/signalr.service';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { StatusCode } from 'src/app/shared-controls/status/statusCodesMap';
import { ISOdateFormat, longDateFormat, timezoneUTC } from 'src/app/shared/date-options/short-date-format';
import { CurveChartPoint, colors } from './curve-chart.model';
import { CurveChartService } from './curve-chart.service';
@Component({
  selector: 'app-curve-chart',
  templateUrl: './curve-chart.component.html',
  styleUrls: ['./curve-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurveChartComponent implements OnInit, OnDestroy {
  @Input() entityType: EntityTypes;
  isDraft = false;
  dataSource: CurveChartPoint[];
  currentUTCDate: string;
  loading$ = new BehaviorSubject<boolean>(false);

  lineChartLabels: string[] = [];
  lineChartData: ChartDataset[];
  lineChartOptions: ChartOptions;
  lineChartLegend = true;
  lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  private chartDataSubscription = new Subscription();

  constructor(
    private curveChartService: CurveChartService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private signalrService: SignalRService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.currentUTCDate = this.datePipe.transform(new Date(), ISOdateFormat, timezoneUTC);
    this.applyChartStyling();
    this.resetLineChartData();

    const updateSub = combineLatest([
      this.store$.select(routerSelectors.getIdSelector),
      this.signalrService.curveUpdated(),
    ]).pipe(
      map(([id, ids]) => {
        if (this.entityType === EntityTypes.package && ids.includes(id)) {
          return id;
        }
        return null;
      }),
    );

    this.chartDataSubscription = combineLatest([
      this.store$.select(routerSelectors.getProjectIdSelector),
      merge(this.store$.select(routerSelectors.getIdSelector, updateSub)).pipe(
        mergeMap((id) =>
          this.store$.select(
            TreeSelectors.getTreeNodeByFunctionalHierarchyId({
              functionalHierarchyId: id,
            }),
          ),
        ),
      ),
    ])
      .pipe(
        filter(([, { functionalHierarchyId }]) => !!functionalHierarchyId),
        switchMap(([projectId, { functionalHierarchyId, status }]) => {
          this.loading$.next(true);
          this.isDraft = status === StatusCode.Draft;
          return this.curveChartService.getCurveChartData(projectId, functionalHierarchyId, this.currentUTCDate);
        }),
      )
      .subscribe((data) => {
        this.dataSource = data;
        this.applyData();
        this.loading$.next(false);
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.chartDataSubscription.unsubscribe();
  }

  applyData(): void {
    this.resetLineChartData();
    let cancelled = false;
    let originalStarted = false;
    let originalReached100Index = 0;
    let plannedReached100Index = 0;

    this.dataSource.forEach((o: CurveChartPoint, i) => {
      this.lineChartLabels.push(this.datePipe.transform(new Date(o.forDate), ISOdateFormat, timezoneUTC));
      if ((o.sumOriginalValue / o.sumOriginalBudget) * 100 < 100) {
        originalReached100Index = i;
      }
      if (!originalStarted && o.sumOriginalValue && i > 0) {
        this.lineChartData[3].data[i - 1] = 0;
        originalStarted = true;
      }
      this.lineChartData[3].data.push((o.sumOriginalValue / o.sumOriginalBudget) * 100);
      if ((o.sumPlannedValue / o.sumPlannedBudget) * 100 < 100) {
        plannedReached100Index = i;
      }
      if (o.sumPlannedBudget === 0 && cancelled === false) {
        this.lineChartData[2].data.push(0);
        this.lineChartData[1].data.push(0);
        cancelled = true;
      } else {
        this.lineChartData[2].data.push((o.sumPlannedValue / o.sumPlannedBudget) * 100);
        if (o.sumEarnedValue !== null) {
          this.lineChartData[1].data.push((o.sumEarnedValue / o.sumPlannedBudget) * 100);
        }
      }
      if (o.sumForecastValue !== null) {
        this.lineChartData[0].data.push((o.sumForecastValue / o.sumPlannedBudget) * 100);
      } else {
        this.lineChartData[0].data.push(o.sumForecastValue);
      }
    });
    this.lineChartData[3].data.splice(originalReached100Index + 2);
    this.lineChartData[2].data.splice(plannedReached100Index + 2);
  }

  private applyChartStyling(): void {
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              hour: 'DD-MMM-YY',
              day: 'DD-MMM-YY',
              week: 'DD-MMM-YY',
              month: 'MMM-YY',
              quarter: 'MMM-YY',
              year: 'MMM-YY',
            },
            unit: 'day',
          },
          ticks: {
            autoSkipPadding: 10,
            font: { family: 'Source Sans Pro' },
          },
        },
        y: {
          min: 0,
          max: 110,
          ticks: {
            autoSkipPadding: 17,
            stepSize: 10,
            font: { family: 'Source Sans Pro' },
          },
          position: 'left',
        },
      },
      layout: {
        padding: {
          bottom: 5,
          right: 20,
          left: 10,
        },
      },
      plugins: {
        legend: {
          reverse: true,
          position: 'bottom',
          labels: {
            padding: 20,
            font: { family: 'Source Sans Pro' },
          },
          align: 'start',
        },
        tooltip: {
          callbacks: {
            title: (context): string => {
              return this.datePipe.transform(
                new Date(this.dataSource[context[0].dataIndex].forDate),
                longDateFormat,
                timezoneUTC,
              );
            },
            label: (context) => {
              return context.dataset.label + ': ' + Math.round((context.parsed.y as number) * 1e2) / 1e2 + '%';
            },
          },
          mode: 'index',
          displayColors: false,
          backgroundColor: 'rgba(255,255,255,0.9)',
          bodyColor: colors.black,
          borderColor: colors.secondaryGrey,
          borderWidth: 1,
          caretPadding: 15,
          enabled: true,
          intersect: false,
          titleColor: colors.black,
          titleMarginBottom: 10,
          padding: { x: 10, y: 10 },
          titleAlign: 'center',
        },
        annotation: {
          annotations: {
            dateline: {
              type: 'line',
              scaleID: 'x',
              value: this.currentUTCDate,
              borderColor: colors.successMain,
              borderWidth: 2,
              borderDash: [5, 2],
              label: {
                display: true,
                content: this.datePipe.transform(new Date(), longDateFormat, timezoneUTC),
                drawTime: 'afterDraw',
                position: 'start',
                yAdjust: 3,
                color: colors.successMain,
                backgroundColor: 'white',
                font: { family: 'Source sans Pro' },
              },
            },
          },
        },
      },
    };
  }

  private resetLineChartData(): void {
    this.lineChartData = [
      {
        data: [],
        label: this.translateService.instant('curve.forecast'),
        fill: false,
        pointRadius: 0,
        borderDash: [5, 2],
        borderWidth: 2,
        borderColor: colors.darkYellow,
        backgroundColor: colors.darkYellow,
        tension: 0,
      },
      {
        data: [],
        label: this.translateService.instant('curve.actual'),
        fill: false,
        pointRadius: 0,
        borderWidth: 2,
        borderColor: colors.darkYellow,
        backgroundColor: colors.darkYellow,
        tension: 0,
      },
      {
        data: [],
        label: this.translateService.instant('curve.current'),
        fill: false,
        pointRadius: 0,
        borderWidth: 2,
        borderColor: colors.brandOrange,
        backgroundColor: colors.brandOrange,
        tension: 0,
      },
      {
        data: [],
        label: this.translateService.instant('curve.original'),
        fill: false,
        pointRadius: 0,
        borderWidth: 2,
        borderColor: colors.baselineBlue,
        backgroundColor: colors.baselineBlue,
        tension: 0,
      },
    ];
    this.lineChartLabels = [];
  }
}
