import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent } from '@angular/material/legacy-tabs';
import { Update } from '@ngrx/entity';
import { MemoizedSelector, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { watchControlsWithoutValidation } from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EditorOptions } from 'tinymce';
import { ChangeRequestListService } from '../../common/navigation-tabs/change-request-list/change-request-list.service';
import { ChangeRequest, RequestType } from '../change-request-cart-state/cart-item.model';
import * as changeRequestActions from '../change-request-cart-state/change-requests/change-request.actions';
import { FullChangeRequestModel } from './full-change-request';
import { mapCRForSubmission, mapMRForSubmission } from './submit-change-request.helpers';

export const Multiple = 'Multiple';
@Component({
  selector: 'app-change-request-submit',
  templateUrl: './change-request-submit.component.html',
  styleUrls: ['./change-request-submit.component.scss'],
})
export class ChangeRequestSubmitComponent implements OnInit, OnDestroy, AfterViewInit {
  editorConfig: EditorOptions;
  request: FullChangeRequestModel;
  form: UntypedFormGroup;
  @ViewChild('tabGroup', { static: false }) tabGroup: any;
  activeTabIndex: number;
  submissionInProgress = false;
  private showErrors = false;
  private subscription = new Subscription();
  requestType = RequestType;
  tabs = [];

  validationErrors = new TableVirtualScrollDataSource<{
    line: string;
    error: string;
  }>();

  errorColumns = ['title', 'message'];

  constructor(
    appConfigService: AppConfigService,
    private store$: Store<AppState>,
    private changeRequestListService: ChangeRequestListService,
    private notificationService: NotificationService,
    public dialogRef: MatLegacyDialogRef<ChangeRequestSubmitComponent>,
    public translationService: TranslateService,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      errorHeader: string;
      title: string;
      placeholder: string;
      getRequestSelector: MemoizedSelector<object, any>;
      requestType: RequestType;
    },
  ) {
    this.tabs = this.data.requestType === RequestType.Move ? ['deliverables'] : ['impact', 'schedule', 'deliverables'];
    this.editorConfig = {
      ...appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...appConfigService.settings.richTextConfig.crJustificationOptions,
    };
    this.initForm();
    this.setControlWatchers();
  }

  ngOnInit(): void {
    const requestSubscription = this.store$.select(this.data.getRequestSelector).subscribe((request) => {
      if (request) {
        if (request.manager === Multiple) {
          request.manager = this.translationService.instant('changeRequest.multiple');
        }
        this.request = request;
        this.fillForm();
      }
    });
    this.subscription.add(requestSubscription);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.activeTabIndex = this.tabGroup.selectedIndex;
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleTabChange(event: MatLegacyTabChangeEvent): void {
    this.activeTabIndex = event.index;
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.showErrors = true;
      return;
    }

    if (this.request.packages.length === 0 && this.request.deliverables.length === 0) {
      this.notificationService.error('changeRequest.changeRequestCannotBeEmpty');
      return;
    }

    this.submissionInProgress = true;
    this.request.title = this.form.get('title').value;
    this.request.description = this.form.get('description').value;
    let request =
      this.data.requestType === RequestType.Move
        ? this.changeRequestListService.submitMoveDeliverableCr(mapMRForSubmission(this.request as any))
        : this.changeRequestListService.submitChangeRequest(mapCRForSubmission(this.request));

    const submitSub = request.pipe(finalize(() => (this.submissionInProgress = false))).subscribe(
      () => {
        if (this.data.requestType === RequestType.Move) {
          this.notificationService.success('changeRequest.moveRequestSuccessfullySubmitted');
          this.store$.dispatch(changeRequestActions.removeMoveChanges());
        } else {
          this.notificationService.success('changeRequest.successfullySubmitted');
          this.request.contextElementIds.forEach(() => {
            this.store$.dispatch(changeRequestActions.removeChange());
          });
        }
        this.dialogRef.close();
      },
      (error) => showHttpErrorResponse(this.notificationService, error),
    );
    this.subscription.add(submitSub);
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return this.showErrors && control.invalid;
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        title: new UntypedFormControl('', Validators.required),
        description: new UntypedFormControl('', Validators.required),
      },
      { updateOn: 'blur' },
    );
  }

  private fillForm(): void {
    const { title, description } = this.request;
    this.form.patchValue(
      {
        title,
        description,
      },
      { emitEvent: false },
    );
  }

  private setControlWatchers(): void {
    const valueChangesSubscription = watchControlsWithoutValidation(this.form).subscribe(({ name, value }) =>
      this.updateProperty(name, value),
    );
    this.subscription.add(valueChangesSubscription);
  }

  private updateProperty(fieldName: string, value: string): void {
    const update: Update<ChangeRequest> = {
      id: this.request.contextElementIds[0],
      changes: {
        [fieldName]: value,
      },
    };
    this.store$.dispatch(changeRequestActions.updateChange({ update }));
  }
}
