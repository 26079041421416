<div class="footer-actions">
  <div class="footer-left-container">
    <button
      *ngIf="nextStepLabel"
      type="button"
      [disabled]="nextStepDisabled"
      (click)="nextStepClick.emit()"
      class="orms-button orms-button-primary submit-button"
    >
      {{ nextStepLabel | translate }}
    </button>
    <button
      *ngIf="previousStepLabel"
      type="button"
      (click)="previousStepClick.emit()"
      class="orms-button orms-button-secondary"
    >
      {{ previousStepLabel | translate }}
    </button>
    <div *ngIf="errorMessage" class="error-message-container">{{ errorMessage | translate }}</div>
  </div>

  <button *ngIf="cancelLabel" type="button" (click)="cancelClick.emit()" class="orms-button orms-button-secondary">
    {{ cancelLabel | translate }}
  </button>
</div>
