import { createAction, props } from '@ngrx/store';
import { CurrentUser } from '../shared/current-user/currentUser';

export enum RootStoreActions {
  SetCurrentUser = '[CurrentUser] Set current user',
  SetCurrentUsersPortfolio = `[CurrentUser] Set current user's portfolio`,
  ClearCurrentUser = '[CurrentUser] Clear current user',
  ClearProjectRelatedData = '[CurrentProject] Clear project related data',
  IncludeCancelledItems = '[CurrentProject] Include cancelled items',
}

export const setCurrentUserWithPortfolioPermission = createAction(
  RootStoreActions.SetCurrentUser,
  props<{ profile: CurrentUser; canManagePortfolio: boolean }>(),
);

export const clearCurrentUser = createAction(RootStoreActions.ClearCurrentUser);

export const setCurrentUsersPortfolio = createAction(
  RootStoreActions.SetCurrentUsersPortfolio,
  props<{
    currentPortfolioID: number;
    currentPortfolioTitle: string;
    canManagePortfolio: boolean;
  }>(),
);

export const clearProjectRelatedData = createAction(RootStoreActions.ClearProjectRelatedData);

export const includeCancelledItems = createAction(
  RootStoreActions.IncludeCancelledItems,
  props<{ includeCancelledItems: boolean }>(),
);
