import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { ChangeRequestListService } from 'src/app/project-management/common/navigation-tabs/change-request-list/change-request-list.service';
import { FromCamelCasePipe } from 'src/app/project-management/history/from-camel-case.pipe';
import { AppState } from 'src/app/root-store/app-state';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { EditorOptions } from 'tinymce';
import { TreeSelectors } from '../../tree-state/tree.selectors';
import { TreeActionsService } from '../tree-actions.service';

@Component({
  selector: 'app-tree-action-cr-submit',
  templateUrl: './tree-action-cr-submit.component.html',
  styleUrls: ['./tree-action-cr-submit.component.scss'],
})
export class TreeActionCrSubmitComponent implements OnInit {
  form: UntypedFormGroup;
  editorConfig: EditorOptions;
  private showErrors = false;
  submissionInProgress = false;
  approver$: Observable<string>;
  constructor(
    appConfigService: AppConfigService,
    public dialogRef: MatLegacyDialogRef<TreeActionCrSubmitComponent>,
    private treeActionsService: TreeActionsService,
    private store$: Store<AppState>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private data: {
      functionalHierarchyId: number;
      entityType: EntityTypes;
      title: string;
    },
    private fromCamelCasePipe: FromCamelCasePipe,
    private translateService: TranslateService,
    private crListService: ChangeRequestListService,
  ) {
    this.editorConfig = {
      ...appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...appConfigService.settings.richTextConfig.crJustificationOptions,
    };
  }

  ngOnInit(): void {
    this.initForm();
    this.approver$ = this.store$.select(TreeSelectors.getParentNodeManager);
    const cancellationRequestForText = this.translateService.instant('changeRequest.CancellationRequestFor');
    this.form
      .get('title')
      .setValue(
        `${cancellationRequestForText} ${this.fromCamelCasePipe.transform(this.data.entityType)} '${this.data.title}'`,
      );
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.showErrors = true;
      return;
    }
    this.submissionInProgress = true;
    this.treeActionsService
      .cancelFunctionOrPackage(
        this.data.functionalHierarchyId,
        this.form.get('title').value,
        this.form.get('justification').value,
      )
      .pipe(finalize(() => (this.submissionInProgress = false)))
      .subscribe(() => {
        this.dialogRef.close();
        this.crListService.refreshChangeRequestList();
      });
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return this.showErrors && control.invalid;
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        title: new UntypedFormControl('', Validators.required),
        justification: new UntypedFormControl('', Validators.required),
      },
      { updateOn: 'blur' },
    );
  }
}
