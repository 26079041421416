import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { EditorOptions } from 'tinymce';
import { ChangeRequestListService } from '../../common/navigation-tabs/change-request-list/change-request-list.service';

import { TreeSelectors } from '../tree-state/tree.selectors';

@Component({
  selector: 'app-tree-action-move-package-cr',
  templateUrl: './tree-action-move-package-cr.component.html',
  styleUrls: ['./tree-action-move-package-cr.component.scss'],
})
export class TreeActionMovePackageCrComponent implements OnInit, OnDestroy {
  submissionInProgress = false;
  editorConfig: EditorOptions;

  form = new UntypedFormGroup(
    {
      selectedFunction: new UntypedFormControl(null, Validators.required),
      justification: new UntypedFormControl('', Validators.required),
    },
    { updateOn: 'change' },
  );

  nodeTitle: string;
  packagesParentSubfunctions$: Observable<any[]>;
  packagesParentSubfunctionsInput$ = new Subject<string>();
  loading = false;
  projectHierarchyRulesSubscription: Subscription;

  constructor(
    appConfigService: AppConfigService,
    public dialogRef: MatLegacyDialogRef<TreeActionMovePackageCrComponent>,
    private crListService: ChangeRequestListService,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      packageFunctionalHierarchyId: number;
      parentFunctionalHierarchyId: number;
      packageName: string;
    },
    private store$: Store<AppState>,
  ) {
    this.editorConfig = {
      ...appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...appConfigService.settings.richTextConfig.crJustificationOptions,
    };
  }

  ngOnInit(): void {
    this.packagesParentSubfunctions$ = concat(
      of([]),
      this.packagesParentSubfunctionsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap((searchTerm) =>
          this.store$
            .select(
              TreeSelectors.getPackagesParentSubfunctionsForCrWithNameStartingFrom({
                term: searchTerm,
                parentFunctionIdToExclude: this.data.parentFunctionalHierarchyId,
              }),
            )
            .pipe(
              map((packageNodes) => {
                return packageNodes.map(({ functionalHierarchyId, title, code, manager }) => ({
                  packageHierarchyId: functionalHierarchyId,
                  title,
                  code,
                  manager,
                  displayName: this.displayPackageTile(title, code),
                }));
              }),
              catchError(() => of([])),
              tap(() => (this.loading = false)),
            ),
        ),
      ),
    );

    this.projectHierarchyRulesSubscription = this.store$
      .select(TreeSelectors.getProjectHierarchyRules)
      .pipe(filter((x) => !!x.length))
      .subscribe((rules) => {
        const maxSubFunctionLevel = Math.max(...rules.map((r) => r.level)) - 1;
        this.nodeTitle = rules.find((r) => r.level === maxSubFunctionLevel).title;
      });
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid;
  }

  private displayPackageTile(title: string, code: string): string {
    const codePart = code ? ' <' + code + '>' : '';
    return title + codePart;
  }

  confirm() {
    if (this.form.invalid) {
      return;
    }

    this.submissionInProgress = true;

    this.crListService
      .submitMovePackageCr(
        this.data.packageFunctionalHierarchyId,
        this.form.get('selectedFunction').value.packageHierarchyId,
        this.form.get('justification').value,
      )
      .pipe(finalize(() => (this.submissionInProgress = false)))
      .subscribe(() => {
        this.dialogRef.close();
        this.crListService.refreshChangeRequestList();
      });
  }

  ngOnDestroy(): void {
    this.projectHierarchyRulesSubscription?.unsubscribe();
  }
}
