<div class="error-page">
  <div class="message-wrapper">
    <div class="header">{{ 'general.sorry' | translate }}</div>
    <ng-content></ng-content>
    <button type="button" (click)="returnBack()" class="orms-new-button orms-new-button-primary">
      <mat-icon class="left-icon">arrow_back</mat-icon>
      {{ 'general.back' | translate }}
    </button>
  </div>
  <img src="/assets/images/sorry.svg" alt="{{ 'imgAlt.accessDenied' | translate }}" />
</div>
