<form [formGroup]="form" autocomplete="off" class="grant-permission" (ngSubmit)="onSubmit()">
  <div class="search-box">
    <ng-select
      class="permissions-user-search"
      [items]="users$ | async"
      bindLabel="displayName"
      placeholder="{{ 'permissions.typeUserNameToGrantPermission' | translate }}"
      [minTermLength]="3"
      formControlName="selectedUsers"
      [hideSelected]="true"
      [clearable]="false"
      [multiple]="true"
      [loading]="(loading$ | async) === true"
      [typeahead]="userInput$"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label user-pill">{{ item.displayName }}</span>
        <span class="ng-value-icon right user-clear" (click)="clear(item)" aria-hidden="true">×</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div>{{ item.displayName }}</div>
        <div *ngIf="item.userName.indexOf('@') !== -1" class="orms-sub-text">{{ item.userName }}</div>
        <div *ngIf="item.userName.indexOf('@') === -1" class="orms-sub-text">
          {{ 'placeholderUsers.placeholder' | translate }}
        </div>
      </ng-template>
    </ng-select>
    <mat-select
      class="orms-select permissions-search-type-select"
      formControlName="type"
      panelClass="permissions-search-type-select"
      [disableOptionCentering]="true"
    >
      <mat-option [value]="PermissionType.canRead">
        {{ 'permissions.canRead' | translate }}
      </mat-option>
      <mat-option [value]="PermissionType.canUpdate">
        {{ 'permissions.canUpdate' | translate }}
      </mat-option>
      <mat-option [value]="PermissionType.canAdminister">
        {{ 'permissions.canAdminister' | translate }}
      </mat-option>
    </mat-select>
  </div>
  <button class="orms-button orms-button-primary" type="submit">
    {{ 'permissions.grantPermission' | translate }}
  </button>
</form>
