import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Observable, Subject, of } from 'rxjs';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NodeMoveData, ProjectStructureBulkUploadResult, TreeNode } from './tree-structure.model';

@Injectable({
  providedIn: 'root',
})
export class TreeStructureService {
  public treeNodeChanged$: Subject<Update<TreeNode>> = new Subject();
  public reloadTreeData$: Subject<TreeNode[]> = new Subject();
  public treeMove$: Subject<NodeMoveData> = new Subject();

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
  ) {}

  getTreeData(projectId: number, includeCancelled: boolean): Observable<TreeNode[]> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.hierarchyTree;
    const params = new HttpParams().set('projectId', projectId.toString()).set('includeCancelled', includeCancelled);

    return this.httpClient.get<TreeNode[]>(baseUrl + endpoint, { params });
  }

  addTreeNode(parentId: number, title: string): Observable<TreeNode> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.hierarchyTreeAddNode;

    return this.httpClient.post<TreeNode>(baseUrl + endpoint, {
      parentId,
      title,
    });
  }

  getTreePartialData(projectId: number, functionalHierarchiesIds: number[]): Observable<TreeNode[]> {
    if (functionalHierarchiesIds.length > 0) {
      const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
      const endpoint = this.appConfigService.settings.api.endpoints.partialHierarchyTree;
      let params = new HttpParams().set('projectId', projectId.toString());

      functionalHierarchiesIds.forEach((id) => {
        params = params.append('functionalHierarchyIds', id.toString());
      });

      return this.httpClient.get<TreeNode[]>(baseUrl + endpoint, { params });
    }

    return of([]);
  }

  downloadExcel(projectId: number): Observable<HttpResponse<Blob>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.excelDownload;
    const params = new HttpParams().set('projectId', projectId.toString());

    return this.httpClient.get<Blob>(baseUrl + endpoint, {
      params,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  downloadProjectStructureTemplate(): Observable<HttpResponse<Blob>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectStructureUploadTemplate;

    return this.httpClient.get<Blob>(baseUrl + endpoint, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  uploadProjectStructure(
    projectId: number,
    file: File,
    changeRequestTitle: string = null,
    changeRequestDescription: string = null,
  ): Observable<ProjectStructureBulkUploadResult> {
    const formData = new FormData();
    formData.append('UploadedFile', file);
    formData.append('projectId', projectId.toString());

    if (changeRequestTitle && changeRequestDescription) {
      formData.append('changeRequestTitle', changeRequestTitle);
      formData.append('changeRequestDescription', changeRequestDescription);
    }

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectStructureUpload;

    return this.httpClient.post<ProjectStructureBulkUploadResult>(baseUrl + endpoint, formData);
  }

  doesProjectStructureUploadRequireChangeRequest(projectId: number, file: File): Observable<boolean> {
    const formData = new FormData();
    formData.append('UploadedFile', file);
    formData.append('projectId', projectId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.doesProjectStructureUploadRequireChangeRequest;

    return this.httpClient.post<boolean>(baseUrl + endpoint, formData);
  }
}
