import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChangeRequestEffects } from './change-requests/change-request.effects';
import { CurrentContextFunctionEffects } from './current-context-function/current-context-function.effects';
import { currentContextFunctionReducer } from './current-context-function/current-context-function.reducer';
import { ChangeRequestDeliverableEffects } from './deliverables/crDeliverable.effects';
import { ChangeRequestPackageEffects } from './packages/package.effects';
import { changeRequestCartReducerMap } from './state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('cartItems', changeRequestCartReducerMap),
    StoreModule.forFeature('currentContextFunction', currentContextFunctionReducer),
    EffectsModule.forFeature([
      ChangeRequestPackageEffects,
      CurrentContextFunctionEffects,
      ChangeRequestEffects,
      ChangeRequestDeliverableEffects,
    ]),
  ],
})
export class ChangeRequestCartStateModule {}
