import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';
import {
  DeliverableFilterType,
  DeliverablesFilters,
  DeliverablesViewState,
  initializeDeliverablesViewState,
} from './model';

@Injectable({
  providedIn: 'root',
})
export class DeliverablesFiltersService {
  private filters: BehaviorSubject<DeliverablesFilters>;

  constructor(sessionStorageService: SessionStorageService) {
    let filters = sessionStorageService.getItem<DeliverablesViewState>(StorageKey.deliverablesList)?.deliverableFilters;
    filters = filters ?? initializeDeliverablesViewState(sessionStorageService).deliverableFilters;

    this.filters = new BehaviorSubject<DeliverablesFilters>(filters);
  }

  setFilter(type: DeliverableFilterType, value: any): void {
    this.filters.next({
      ...this.filters.value,
      [type]: value,
    });
  }

  getFilters(): Observable<DeliverablesFilters> {
    return this.filters.asObservable();
  }
}
