<span class="icon-container">
  <img
    matTooltip="{{ tooltipTranslationText | translate }}"
    [src]="urlSource"
    (mouseover)="mouseOver()"
    (mouseout)="mouseOut()"
    matTooltipPosition="above"
    matTooltipClass="orms-tooltip orms-cr-tooltip"
    alt="{{ 'general.relationshipType' | translate }}"
  />
</span>
