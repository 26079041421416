import { DeliverableStatus, StatusCode } from '../../shared-controls/status/statusCodesMap';

export class TreeNode {
  functionalHierarchyId: number;
  parentId: number;
  typeCode: string;
  treeLevel: number;
  code: string;
  title: string;
  text: string;
  manager: string;
  managerId: number;
  status: StatusCode | DeliverableStatus;
  statusDescription: string;
  startDate: Date | moment.Moment;
  endDate: Date | moment.Moment;
  plannedComplete: number;
  actualComplete: number;
  spi: number;
  health: number;
  budgetHours: number;
  isFavoritePackage: boolean;
  underChangeControl: boolean;
  managePermission: boolean;
  updatePermission: boolean;
  viewPermission: boolean;
  editableFields: string[];
  canAdd: boolean;
  canPublish: boolean;
  canBaseline: boolean;
  canCancel: boolean;
  canDelete: boolean;
  canMove: boolean;
  packageId: number;
}

export interface NodeMoveData {
  functionalHierarchyId: number;
  functionDestinationFunctionalHierarchyId: number;
}

export interface TreeNodeAction {
  icon: (x) => string;
  label: string;
  callBack: (x) => void;
  isDisabled: (x) => boolean;
  isSvg: boolean;
  itemGroupBorder: boolean;
  withSubtitle?: boolean;
  subTitle?: string;
  confirm?: string;
  showConfirm?: boolean;
  confirmLabel?: string;
}

export interface ProjectStructureBulkUploadResult {
  addedRecordsCount: number;
  changeRequestCreated: boolean;
}
