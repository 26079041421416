import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';

export interface ColumnsSize {
  code: string;
  number: string;
  title: string;
  status: string;
  startDate: string;
  endDate: string;
  duration: string;
  deliverables: string;
  hours: string;
  cost: string;
  createdBy: string;
  createdDate: string;
  approver: string;
  approvedDate: string;
  actions: string;
}

export interface CRFilters {
  status: CRStatusFilterName;
}

export interface Approver {
  userId: number;
  userName: number;
}

export enum CRFilterType {
  Status = 'status',
  FullText = 'full-text',
  Approver = 'approver',
}

export enum CRStatusFilterName {
  Pending = 'pending',
  Approved = 'approved',
  Cancelled = 'cancelled',
  All = 'all',
}

export interface ChangeRequestsViewState {
  columns: ColumnsSize;
  crFilters: CRFilters;
}

export const initializeChangeRequestsViewState = (sessionService: SessionStorageService): ChangeRequestsViewState => {
  const changeRequestsViewState: ChangeRequestsViewState = {
    crFilters: {
      status: CRStatusFilterName.Pending,
    },
    columns: {
      code: '120px',
      number: '50px',
      title: '120px',
      status: '100px',
      startDate: '70px',
      endDate: '70px',
      duration: '100px',
      deliverables: '100px',
      hours: '80px',
      cost: '70px',
      createdBy: '100px',
      createdDate: '100px',
      approver: '100px',
      approvedDate: '70px',
      actions: '36px',
    },
  };

  sessionService.setItem(StorageKey.changeRequestsList, changeRequestsViewState);

  return changeRequestsViewState;
};
