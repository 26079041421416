import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private accountInfoService: AccountInfoService,
  ) {}

  public canUserManagePortfolio(): boolean {
    return this.accountInfoService.hasRole(UmsRoles.PowerUser);
  }

  public logPortfolioVisit(portfolioId: number): Observable<void> {
    const params = new HttpParams().set('portfolioId', portfolioId.toString());
    const { baseUrl, logPortfolioVisit } = this.appConfigService.settings.api.endpoints;
    const url = `${baseUrl}${logPortfolioVisit}`;
    return this.httpClient.post<void>(url, {}, { params });
  }
}
