import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-favorite-icon',
  templateUrl: './favorite-icon.component.html',
  styleUrls: ['./favorite-icon.component.scss'],
})
export class FavoriteIconComponent {
  @Input() IsFavorite: boolean;
  @Output() favoriteToggled = new EventEmitter<any>();

  @HostListener('click', ['$event'])
  onClick(): void {
    this.toggleFavorite();
  }

  toggleFavorite(): void {
    this.IsFavorite = !this.IsFavorite;
    this.favoriteToggled.emit(this.IsFavorite);
  }
}
