import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelect } from '@angular/material/legacy-select';

export interface Entity {
  value: string;
  viewValue: string;
}

export interface Group {
  disabled?: boolean;
  name: string;
  option: Entity[];
}

@Component({
  selector: 'app-multiselect-grouped',
  templateUrl: './multiselect-grouped.component.html',
  styleUrls: ['./multiselect-grouped.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectGroupedComponent implements AfterViewInit {
  @ViewChild('select') select: MatLegacySelect;
  @Input() control: UntypedFormControl;
  @Input() groupOptions: Group[];
  @Input() label: string;

  ngAfterViewInit() {
    this.select.optionSelectionChanges.subscribe((res) => {
      if (!this.control.value.length) {
        this.control.setValue([res.source.value]);
      }
    });
  }
}
