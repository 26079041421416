import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatLegacyOption } from '@angular/material/legacy-core';
import { MatLegacySelect } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

export interface Option {
  value: any;
  viewValue: string;
  iconCssClass?: string;
  templateContext?: any;
}

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('select') select: MatLegacySelect;
  @ViewChild('allSelected') private allSelected: MatLegacyOption;

  @Input() label: string;
  @Input() value: any[];
  @Input() options: Option[];
  @Input() panelClass = '';
  @Input() optionTemplate: TemplateRef<any>;

  @Output()
  valueChange = new EventEmitter<any[]>();

  calculatedLabel = '';
  selectedOptions = [];
  SELECT_ALL = 'SELECT_ALL';

  private onDestroy$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$), startWith(this.translateService.currentLang))
      .subscribe(() => this.refreshLabel());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes || 'options' in changes) {
      this.selectedOptions = [...(this.value ?? [])];
      if (this.value?.length === this.options?.length) {
        this.selectedOptions.push(this.SELECT_ALL);
      }
    }
    this.refreshLabel();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggle() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    } else if (this.selectedOptions.length === this.options.length) {
      this.allSelected.select();
    }
    this.emitValue();
    this.refreshLabel();
  }

  toggleAll() {
    if (this.allSelected.selected) {
      this.selectedOptions = [...this.options.map((option) => option.value), this.SELECT_ALL];
    } else {
      this.selectedOptions = [];
    }
    this.emitValue();
    this.refreshLabel();
  }

  private emitValue(): void {
    this.valueChange.emit(this.selectedOptions.filter((x) => x !== this.SELECT_ALL));
  }

  private refreshLabel(): void {
    const userSelectedOptions = this.selectedOptions.filter((x) => x !== this.SELECT_ALL);
    if (userSelectedOptions.length === 0) {
      this.calculatedLabel = this.translateService.instant(this.label);
    } else if (userSelectedOptions.length === 1) {
      this.calculatedLabel = this.translateService.instant(
        this.options?.find((x) => x.value === userSelectedOptions[0])?.viewValue ?? this.label,
      );
    } else {
      this.calculatedLabel = `${this.translateService.instant(this.label)} (${userSelectedOptions.length})`;
    }
  }
}
