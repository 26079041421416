import { HealthColor } from './healthColorMap';

export interface StatusCodeDetail {
  code: StatusCode;
  class: string;
  text: string;
  icon?: string;
  healthValue: HealthColor;
}

export enum StatusCode {
  Draft = 0,
  AtRisk = 1,
  Cancelled = 2,
  Closed = 3,
  Complete = 4,
  Healthy = 5,
  Monitor = 6,
  NoPlan = 7,
  NotStarted = 8,
  OnHold = 9,
  OverDue = 10,
  PendingCancellation = 11,
  Published = 12,
  PendingMove = 14,
  NoStatus = 100,
  Healthy2 = 101,
  Healthy3 = 102,
  InProgress = 103,
}

export enum DeliverableStatus {
  Draft = 'DR',
  NotStarted = 'NS',
  InProgress = 'IP',
  OnHold = 'OH',
  Cancelled = 'CA',
  Complete = 'CO',
  Missing = 'MI',
  Moved = 'MV',
}

export const StatusCodesMap = new Map<number | DeliverableStatus, StatusCodeDetail>([
  [
    StatusCode.Draft,
    {
      code: StatusCode.Draft,
      class: 'label-light-grey',
      text: 'status.draft',
      icon: 'mode_edit',
      healthValue: HealthColor.LightGrey,
    },
  ],
  [
    StatusCode.Healthy,
    {
      code: StatusCode.Healthy,
      class: 'label-green',
      text: 'status.healthy',
      icon: 'emoji_emotions',
      healthValue: HealthColor.LightGreen,
    },
  ],
  [
    StatusCode.Healthy2,
    {
      code: StatusCode.Healthy2,
      class: 'label-green',
      text: 'status.healthy',
      icon: 'moving',
      healthValue: HealthColor.LightGreen,
    },
  ],
  [
    StatusCode.Healthy3,
    {
      code: StatusCode.Healthy3,
      class: 'label-green',
      text: 'status.healthy',
      icon: 'moving',
      healthValue: HealthColor.LightGreen,
    },
  ],
  [
    StatusCode.AtRisk,
    {
      code: StatusCode.AtRisk,
      class: 'label-light-red',
      text: 'status.atRisk',
      icon: 'warning_amber',
      healthValue: HealthColor.LightRed,
    },
  ],
  [
    StatusCode.Monitor,
    {
      code: StatusCode.Monitor,
      class: 'label-dark-yellow',
      text: 'status.monitor',
      icon: 'remove_red_eye',
      healthValue: HealthColor.DarkYellow,
    },
  ],
  [
    StatusCode.Published,
    {
      code: StatusCode.Published,
      class: 'label-light-grey',
      text: 'status.published',
      icon: 'verified',
      healthValue: HealthColor.LightGrey,
    },
  ],
  [
    StatusCode.NoPlan,
    {
      code: StatusCode.NoPlan,
      class: 'label-grey-600',
      text: 'status.noPlan',
      icon: 'report',
      healthValue: HealthColor.Grey600,
    },
  ],
  [
    StatusCode.NotStarted,
    {
      code: StatusCode.NotStarted,
      class: 'label-grey-5',
      text: 'status.notStarted',
      icon: 'stop_circle',
      healthValue: HealthColor.Grey5,
    },
  ],
  [
    StatusCode.OverDue,
    {
      code: StatusCode.OverDue,
      class: 'label-dark-red',
      text: 'status.overdue',
      icon: 'warning',
      healthValue: HealthColor.DarkRed,
    },
  ],
  [
    StatusCode.Complete,
    {
      code: StatusCode.Complete,
      class: 'label-dark-green',
      text: 'status.complete',
      icon: 'check_circle',
      healthValue: HealthColor.DarkGreen,
    },
  ],
  [
    StatusCode.Cancelled,
    {
      code: StatusCode.Cancelled,
      class: 'label-grey-6',
      text: 'status.cancelled',
      icon: 'cancel',
      healthValue: HealthColor.Grey6,
    },
  ],
  [
    StatusCode.OnHold,
    {
      code: StatusCode.OnHold,
      class: 'label-dark-yellow',
      text: 'status.onHold',
      icon: 'block_flipped',
      healthValue: HealthColor.DarkYellow,
    },
  ],
  [
    StatusCode.Closed,
    {
      code: StatusCode.Closed,
      class: 'label-green',
      text: 'status.closed',
      healthValue: HealthColor.Green,
    },
  ],
  [
    StatusCode.PendingCancellation,
    {
      code: StatusCode.PendingCancellation,
      class: 'label-light-yellow',
      text: 'status.pendingCancellation',
      icon: 'pending',
      healthValue: HealthColor.LightYellow,
    },
  ],
  [
    StatusCode.PendingMove,
    {
      code: StatusCode.PendingMove,
      class: 'label-light-yellow',
      text: 'status.pendingMove',
      icon: 'drive_file_move',
      healthValue: HealthColor.LightYellow,
    },
  ],
  [
    StatusCode.InProgress,
    {
      code: StatusCode.InProgress,
      class: 'label-dark-blue',
      text: 'status.inProgress',
      icon: 'moving',
      healthValue: HealthColor.DarkBlue,
    },
  ],
  [
    null,
    {
      code: StatusCode.NoStatus,
      class: 'label-default',
      text: 'status.noStatus',
      healthValue: HealthColor.None,
    },
  ],
  // Deliverable statuses
  [
    DeliverableStatus.NotStarted,
    {
      code: StatusCode.NotStarted,
      class: 'label-grey-5',
      text: 'status.notStarted',
      icon: 'stop_circle',
      healthValue: HealthColor.Grey5,
    },
  ],
  [
    DeliverableStatus.Draft,
    {
      code: StatusCode.Draft,
      class: 'label-light-grey',
      text: 'status.draft',
      icon: 'mode_edit',
      healthValue: HealthColor.LightGrey,
    },
  ],
  [
    DeliverableStatus.InProgress,
    {
      code: StatusCode.InProgress,
      class: 'label-dark-blue',
      text: 'status.inProgress',
      icon: 'moving',
      healthValue: HealthColor.DarkBlue,
    },
  ],
  [
    DeliverableStatus.OnHold,
    {
      code: StatusCode.OnHold,
      class: 'label-dark-yellow',
      text: 'status.onHold',
      icon: 'block_flipped',
      healthValue: HealthColor.DarkYellow,
    },
  ],
  [
    DeliverableStatus.Cancelled,
    {
      code: StatusCode.Cancelled,
      class: 'label-grey-6',
      text: 'status.cancelled',
      icon: 'cancel',
      healthValue: HealthColor.Grey6,
    },
  ],
  [
    DeliverableStatus.Complete,
    {
      code: StatusCode.Complete,
      class: 'label-dark-green',
      text: 'status.complete',
      icon: 'check_circle',
      healthValue: HealthColor.DarkGreen,
    },
  ],
  [
    DeliverableStatus.Missing,
    {
      code: StatusCode.NoPlan,
      class: 'label-dark-green',
      text: 'status.missing',
      icon: 'check_circle',
      healthValue: HealthColor.Grey600,
    },
  ],
  [
    DeliverableStatus.Moved,
    {
      code: StatusCode.NoPlan,
      class: 'label-dark-grey',
      text: 'status.moved',
      icon: 'drive_file_move',
      healthValue: HealthColor.DarkGrey,
    },
  ],
]);

export const getStatusDetail = (statusCode: StatusCode | DeliverableStatus): StatusCodeDetail => {
  if (statusCode === undefined) {
    return StatusCodesMap.get(null);
  }
  const statusDetail = StatusCodesMap.get(statusCode);
  return statusDetail ? statusDetail : StatusCodesMap.get(null);
};

export const getStatusDetailIncludingHealthValues = (
  statusCode: StatusCode | DeliverableStatus,
  health: number,
): StatusCodeDetail => {
  if (statusCode === undefined) {
    return StatusCodesMap.get(null);
  }
  const statusDetail = StatusCodesMap.get(getStatusCode(statusCode, health));
  return statusDetail ? statusDetail : StatusCodesMap.get(null);
};

export const getStatusCode = (
  statusCode: StatusCode | DeliverableStatus,
  health: number,
): StatusCode | DeliverableStatus => {
  if (statusCode === StatusCode.Healthy && health > 1 && health < 4) {
    switch (health) {
      case 2:
        return StatusCode.Healthy2;
      case 3:
        return StatusCode.Healthy3;
    }
  }

  return statusCode;
};
