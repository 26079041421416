<div>
  <h2>
    <ng-container>
      {{ 'projectImport.inputColumnsTitle' | translate }}
    </ng-container>
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <table
    class="resizable-table"
    mat-table
    [dataSource]="inputColumnsDataSource"
    attr.aria-label="{{ 'projectImport.breakdownTitle' | translate }}"
  >
    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef>{{ 'projectImport.key' | translate | capitalizeFirstLetter }}</th>
      <td mat-cell *matCellDef="let entity">{{ entity.key }}</td>
      <!-- 
        I think we will want to translate the propertiy names
        <td mat-cell *matCellDef="let entity">{{ 'projectImport.' + entity.key | translate | capitalizeFirstLetter }}</td>
      -->
    </ng-container>

    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'projectImport.newValue' | translate | capitalizeFirstLetter }}
      </th>
      <td mat-cell *matCellDef="let entity">{{ entity.newValue | stripZeroTimeFromDate }}</td>
    </ng-container>

    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'projectImport.oldValue' | translate | capitalizeFirstLetter }}
      </th>
      <td mat-cell *matCellDef="let entity">{{ entity.oldValue | stripZeroTimeFromDate }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="inputColumnDefs"></tr>
    <tr mat-row *matRowDef="let row; columns: inputColumnDefs"></tr>
  </table>
</div>
