<div *ngIf="totalChanges$ | async" class="summary-container">
  <div *ngFor="let entity of summaryBlocks" class="entity-block">
    <div class="title">
      <span>{{ entity.title | translate | uppercase }}</span>
    </div>
    <a
      *ngIf="entity.hasBreakdown"
      class="value orms-link-button"
      routerLink="."
      (click)="displayBreakdown(entity.title)"
    >
      {{ entity.value$ | async }}
    </a>
    <div *ngIf="!entity.hasBreakdown" class="value">
      <span>{{ entity.value$ | async }}</span>
    </div>
  </div>
</div>

<div class="advanced-search-filters">
  <app-review-import-filters *ngIf="projectId > 0" [projectId]="projectId" id="advanced-search-filters">
  </app-review-import-filters>
</div>

<ag-grid-angular
  class="ag-theme-alpine custom-ag-grid"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowData]="rowData$ | async"
  [rowSelection]="rowSelection"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [noRowsOverlayComponent]="noRowsOverlayComponent"
  [animateRows]="true"
  [rowHeight]="64"
  [rowBuffer]="100"
  (gridReady)="onGridReady($event)"
  (modelUpdated)="onModelUpdated()"
>
</ag-grid-angular>
<app-multi-step-footer
  [previousStepLabel]="'general.back'"
  [nextStepLabel]="'general.continue'"
  [cancelLabel]="'general.cancel'"
  [nextStepDisabled]="isNextStepDisabled"
  [errorMessage]="(totalErrors$ | async) > 0 ? 'projectImport.resolveErrorsAndReimport' : ''"
  (cancelClick)="cancel()"
  (previousStepClick)="previous()"
  (nextStepClick)="next()"
>
</app-multi-step-footer>
