import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PendingChangeRequestList } from './change-requests-pending-approval/pending-change-request-list.model';
import { FavoritePackage } from './favorite-packages/favorite-packages.component';
import { FileTypeMap, ProjectListItem } from './project-list-item.model';

@Injectable({
  providedIn: 'root',
})
export class HomePageService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  getUserProjects(): Observable<ProjectListItem[]> {
    const { baseUrl, projectList } = this.appConfigService.settings.api.endpoints;

    return this.httpClient.get<ProjectListItem[]>(baseUrl + projectList, {}).pipe(
      map((projectItems) => {
        // return from API empty list instead of blank response
        (projectItems || []).forEach((item) => {
          const { logo } = item;
          if (logo && logo.length > 0) {
            item.src = FileTypeMap.get(logo[0]) + logo;
          } else {
            item.src = '/assets/images/logo-red.svg';
          }
        });
        return projectItems || [];
      }),
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of([]);
      }),
    );
  }

  getProjectListByPortfolio(portfolioId: number): Observable<ProjectListItem[]> {
    const { baseUrl, projectListByPortfolio } = this.appConfigService.settings.api.endpoints;

    const params = new HttpParams().set('portfolioId', portfolioId.toString());

    return this.httpClient.get<ProjectListItem[]>(baseUrl + projectListByPortfolio, { params }).pipe(
      map((projectItems) => {
        // return from API empty list instead of blank response
        (projectItems || []).forEach((item) => {
          const { logo } = item;
          if (logo && logo.length > 0) {
            item.src = FileTypeMap.get(logo[0]) + logo;
          } else {
            item.src = '/assets/images/logo-red.svg';
          }
        });
        return projectItems || [];
      }),
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of([]);
      }),
    );
  }

  getUserChangeRequestsPendingApproval(portfolioId: number): Observable<PendingChangeRequestList> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const pendingChangeRequestUrl = this.appConfigService.settings.api.endpoints.pendingChangeRequests;
    const params = new HttpParams().set('portfolioId', portfolioId.toString());

    return this.httpClient.get<PendingChangeRequestList>(baseUrl + pendingChangeRequestUrl, { params });
  }

  getFavoritePackages(portfolioId: number): Observable<FavoritePackage[]> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const favoritePackagesUrl = this.appConfigService.settings.api.endpoints.favoritePackagesList;
    const params = new HttpParams().set('portfolioId', portfolioId.toString());
    return this.httpClient.get<FavoritePackage[]>(baseUrl + favoritePackagesUrl, { params });
  }
}
