export enum HistoryAction {
  none = 0,
  created = 1,
  updated = 2,
  deleted = 3,
  crCreated = 4,
  crUpdated = 5,
  crDeleted = 6,
  progressed = 7,
  grantedRead = 8,
  grantedUpdate = 9,
  grantedAdminister = 10,
  revokedRead = 11,
  revokedUpdate = 12,
  revokedAdminister = 13,
  deletedChild = 14,
  restoredChild = 15,
  addedTag = 16,
  deletedTag = 17,
  deliverableMoved = 18,
  addedRelationship = 19,
  deletedRelationship = 20,
  packageMoved = 21,
  subfunctionMoved = 22,
  deliverableAdded = 23,
  deliverableRemoved = 24,
}
