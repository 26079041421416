import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { ProgressWorkflowStep } from '../../../../common-models/progress-workflow-step.model';
import * as deliverableActions from '../../../deliverable/deliverable-state/deliverable.actions';
import { DeliverableDetail } from '../deliverables/DeliverableDetail';
import { DeliverablesService } from '../deliverables/deliverables.service';
@Component({
  selector: 'app-deliverable-percentage-complete',
  templateUrl: './deliverable-percentage-complete.component.html',
  styleUrls: ['./deliverable-percentage-complete.component.scss'],
})
export class DeliverablePercentageCompleteComponent implements OnInit, OnDestroy {
  detailData: DeliverableDetail;
  isTaskBased = false;
  form: UntypedFormGroup;
  originalEarnedValue: number;
  progressRegression = false;
  workflowSteps$: Observable<ProgressWorkflowStep[]>;
  workflowStepsSubscription: any;
  private subscription = new Subscription();
  constructor(
    private dialogRef: MatLegacyDialogRef<DeliverablePercentageCompleteComponent>,
    private deliverablesService: DeliverablesService,
    private store$: Store<AppState>,
    @Inject(MAT_LEGACY_DIALOG_DATA) data: DeliverableDetail,
  ) {
    this.detailData = data;
    this.isTaskBased = !this.detailData.progressWorkflowId;
  }

  ngOnInit(): void {
    if (this.isTaskBased) {
      this.originalEarnedValue = this.detailData.percentageCompleted;
      this.form = new UntypedFormGroup(
        {
          progress: new UntypedFormControl(this.detailData.percentageCompleted || 0, {
            validators: [Validators.maxLength(3), Validators.min(0), Validators.max(100)],
          }),
          reason: new UntypedFormControl(''),
        },
        {
          updateOn: 'change',
        },
      );

      const valueChangedSubscription = this.form
        .get('progress')
        .valueChanges.pipe(
          map((newValue) => this.checkRegression(newValue)),
          tap((isRegression) => this.updateValidator(isRegression)),
          debounceTime(500),
        )
        .subscribe((isRegression) => (this.progressRegression = isRegression));

      this.subscription.add(valueChangedSubscription);
    } else {
      this.workflowSteps$ = this.deliverablesService
        .getProjectWorkflowSteps(this.detailData.progressWorkflowId)
        .pipe(
          tap(
            (steps) =>
              (this.originalEarnedValue =
                steps.find((s) => s.progressWorkflowStatusId === this.detailData.progressWorkflowStatusId)
                  ?.earnedValue ?? 0),
          ),
        );

      this.form = new UntypedFormGroup({
        progress: new UntypedFormControl(this.detailData.progressWorkflowStatusId),
        reason: new UntypedFormControl(''),
      });
    }
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.form.get('reason').markAllAsTouched();
      return;
    }
    const name = this.isTaskBased ? 'percentageCompleted' : 'progressWorkflowStatusId';

    const update: Update<DeliverableDetail> = {
      id: this.detailData.deliverableId,
      changes: {
        [name]: this.form.value.progress,
        progressChangeNotes: this.form.value.reason,
      },
    };

    this.store$.dispatch(
      deliverableActions.updateDeliverableProperty({
        key: name,
        update,
        parentId: this.detailData.packageFunctionalHierarchyId,
      }),
    );

    this.dialogRef.close(this.detailData.deliverableId);
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  workflowStepProgressChanged(progressWorkflowStatusId: number, workflowSteps): void {
    const earnedValue =
      workflowSteps.find((s) => s.progressWorkflowStatusId === progressWorkflowStatusId)?.earnedValue ?? 0;
    this.progressRegression = this.checkRegression(earnedValue);
    this.updateValidator(this.progressRegression);
  }

  private checkRegression(newValue: number): boolean {
    return this.originalEarnedValue > newValue;
  }

  private updateValidator(isRegression: boolean): void {
    if (isRegression) {
      this.form.get('reason').setValidators(Validators.required);
    } else {
      this.form.get('reason').clearValidators();
    }

    this.form.get('reason').updateValueAndValidity();
  }
}
