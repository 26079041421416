<div>
  <h2>
    <ng-container>
      {{ 'projectImport.breakdownTitle' | translate }}
    </ng-container>
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <table
    class="resizable-table"
    mat-table
    [dataSource]="breakdownDataSource"
    attr.aria-label="{{ 'projectImport.breakdownTitle' | translate }}"
  >
    <ng-container matColumnDef="sheet">
      <th mat-header-cell *matHeaderCellDef>{{ 'projectImport.sheet' | translate | capitalizeFirstLetter }}</th>
      <td mat-cell *matCellDef="let entity">{{ 'general.' + entity.sheet | translate | capitalizeFirstLetter }}</td>
    </ng-container>

    <ng-container matColumnDef="added">
      <th mat-header-cell *matHeaderCellDef>{{ 'projectImport.added' | translate | capitalizeFirstLetter }}</th>
      <td mat-cell *matCellDef="let entity">{{ entity.added }}</td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef>{{ 'projectImport.updated' | translate | capitalizeFirstLetter }}</th>
      <td mat-cell *matCellDef="let entity">{{ entity.updated }}</td>
    </ng-container>

    <ng-container matColumnDef="deleted">
      <th mat-header-cell *matHeaderCellDef>{{ 'projectImport.deleted' | translate | capitalizeFirstLetter }}</th>
      <td mat-cell *matCellDef="let entity">{{ entity.deleted }}</td>
    </ng-container>

    <ng-container matColumnDef="unknown">
      <th mat-header-cell *matHeaderCellDef>{{ 'projectImport.unknown' | translate | capitalizeFirstLetter }}</th>
      <td mat-cell *matCellDef="let entity">{{ entity.unknown }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="breakdownColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: breakdownColumns"></tr>
  </table>
</div>
