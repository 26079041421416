<mat-form-field
  class="orms-outline-input"
  [ngClass]="{ 'label-unassigned': !inputControl?.value?.userId }"
  appearance="outline"
  floatLabel="always"
>
  <mat-label>{{ label | translate }}</mat-label>
  <mat-icon *ngIf="!inputControl?.value?.userId" matPrefix>person_off</mat-icon>
  <input
    type="text"
    #input
    matInput
    class="input"
    [placeholder]="
      inputControl?.value?.displayName ? inputControl?.value?.displayName : ('general.unassigned' | translate)
    "
    (input)="onChange($event)"
    (blur)="onBlur($event)"
    [matAutocomplete]="auto"
    [formControl]="inputControl"
  />
  <button
    *ngIf="clearable && inputControl?.value?.displayName"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clear()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event.option.value)"
  >
    <mat-option *ngFor="let option of users$ | async" [value]="option">
      <div class="orms-multiline-option">{{ option.displayName }}</div>
      <div *ngIf="option.userName.indexOf('@') !== -1" class="orms-multiline-option orms-sub-text">
        {{ option.userName }}
      </div>
      <div *ngIf="option.userName.indexOf('@') === -1" class="orms-multiline-option orms-sub-text">
        {{ 'placeholderUsers.placeholder' | translate }}
      </div>
    </mat-option>
    <ng-container *ngIf="users$ | async as users">
      <mat-option *ngIf="users.length === 0 && !isLoading">
        {{ 'general.noUserFound' | translate }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <app-spinner [diameter]="26" *ngIf="isLoading"></app-spinner>
</mat-form-field>
