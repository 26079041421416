import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatLegacySelect } from '@angular/material/legacy-select';
import { BehaviorSubject } from 'rxjs';

export interface Option {
  value: string;
  viewValue: string;
  iconCssClass?: string;
}

@Component({
  selector: 'app-boolean-select',
  templateUrl: './boolean-select.component.html',
  styleUrls: ['./boolean-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanSelectComponent implements OnChanges {
  @ViewChild('select') select: MatLegacySelect;

  @Input() defaultLabel: string;
  @Input() labelTrue: string;
  @Input() labelFalse: string;
  @Input() optionTrueText = 'general.yes';
  @Input() optionFalseText = 'general.no';
  @Input() value: boolean;
  @Input() panelClass: string;

  label$ = new BehaviorSubject<string>('');

  @Output()
  valueChange = new EventEmitter<boolean>();

  ngOnChanges(): void {
    this.refreshLabel();
  }

  onValueChange(): void {
    this.valueChange.emit(this.value);
    this.refreshLabel();
  }

  private refreshLabel(): void {
    this.label$.next(this.value === true ? this.labelTrue : this.value === false ? this.labelFalse : this.defaultLabel);
  }
}
