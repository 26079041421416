import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
  isProjectImport = false;
  constructor(private router: Router) {
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    this.isProjectImport = currentNavigationState?.isProjectImport;
  }
}
