import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RelationshipType } from '../PackageRelationship.model';

@Component({
  selector: 'app-relationship-type-option',
  templateUrl: './relationship-type-option.component.html',
  styleUrls: ['./relationship-type-option.component.scss'],
})
export class RelationshipTypeOptionComponent implements OnChanges {
  @Input() type: RelationshipType;
  urlSource = '/assets/icons/icon-predecessor.svg';
  translationText = 'relationships.predecessor';

  iconsMap = new Map([
    [RelationshipType.Related, '/assets/icons/icon-related.svg'],
    [RelationshipType.Successor, '/assets/icons/icon-successor.svg'],
    [RelationshipType.Predecessor, '/assets/icons/icon-predecessor.svg'],
  ]);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.urlSource = this.iconsMap.get(this.type);
      switch (this.type) {
        case RelationshipType.Related:
          this.translationText = 'relationships.related';
          break;
        case RelationshipType.Predecessor:
          this.translationText = 'relationships.predecessor';
          break;
        case RelationshipType.Successor:
          this.translationText = 'relationships.successor';
          break;
      }
    }
  }
}
