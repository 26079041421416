import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { getIdSelector } from 'src/app/root-store/root-store.selector';
import { StatusCode } from '../../../shared-controls/status/statusCodesMap';
import { TreeSelectors } from '../../tree-navigation/tree-state/tree.selectors';

@Component({
  selector: 'app-package-curve',
  templateUrl: './package-curve.component.html',
  styleUrls: ['./package-curve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageCurveComponent implements OnInit, OnDestroy {
  entityType = EntityTypes.package;
  isDraft: boolean;

  private subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const sub = this.store$
      .select(getIdSelector)
      .pipe(
        mergeMap((id) =>
          this.store$.select(
            TreeSelectors.getTreeNodeByFunctionalHierarchyId({
              functionalHierarchyId: id,
            }),
          ),
        ),
      )
      .subscribe((x) => {
        this.isDraft = x.status === StatusCode.Draft;
      });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
