import { EntityTypes } from 'src/app/common-models/node-types/node-types';

export class Detail {
  functionalHierarchyId: number;
  budgetCost: number;
  budgetHours: number;
  planStartDate: Date;
  planEndDate: Date;
  deliverableCount: number;
  forecastEndDate: Date;
  intent: string;
  isCritical: boolean;
  priority: number;
  editableFields: string[];
  controlledDocuments: boolean;
  underChangeControl: boolean;
  curveProfileCode: string;
  type?: EntityTypes;
}

export enum DetailKeys {
  planStartDate = 'planStartDate',
  planEndDate = 'planEndDate',
  forecastEndDate = 'forecastEndDate',
}
