<div
  class="menu-trigger"
  [matMenuTriggerFor]="menu"
  (menuOpened)="setMenuIsOpen(true)"
  (menuClosed)="setMenuIsOpen(false)"
  #menuTrigger
>
  <span class="label">
    {{ label$ | async }}
  </span>
  <mat-icon>arrow_drop_down</mat-icon>
</div>
<mat-menu #menu="matMenu">
  <form [formGroup]="form" (keydown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
    <mat-select
      formControlName="operation"
      [placeholder]="'filter.selectOperation' | translate"
      (click)="$event.stopPropagation()"
      class="orms-select custom-mat-select numeric-filter-select"
      panelClass="orms-select-options"
    >
      <mat-option [value]="NumericFilterOperation.GreaterThan">
        {{ 'filter.greaterThan' | translate }}
      </mat-option>
      <mat-option [value]="NumericFilterOperation.LessThan">
        {{ 'filter.lessThan' | translate }}
      </mat-option>
      <mat-option [value]="NumericFilterOperation.GreaterThanOrEquals">
        {{ 'filter.greaterThanOrEquals' | translate }}
      </mat-option>
      <mat-option [value]="NumericFilterOperation.LessThanOrEquals">
        {{ 'filter.lessThanOrEquals' | translate }}
      </mat-option>
      <mat-option [value]="NumericFilterOperation.Between">
        {{ 'filter.between' | translate }}
      </mat-option>
    </mat-select>

    <ng-container *ngIf="form.get('operation').value !== NumericFilterOperation.Between; else between">
      <input
        (click)="$event.stopPropagation()"
        class="form-control orms-simple-input numeric-filter-input"
        type="number"
        formControlName="arg1"
        [placeholder]="'filter.filter' | translate"
      />
    </ng-container>

    <ng-template #between>
      <input
        (click)="$event.stopPropagation()"
        class="form-control orms-simple-input numeric-filter-input"
        type="number"
        formControlName="arg1"
        [placeholder]="'filter.from' | translate"
      />

      <input
        (click)="$event.stopPropagation()"
        class="form-control orms-simple-input numeric-filter-input"
        type="number"
        formControlName="arg2"
        [placeholder]="'filter.to' | translate"
      />
    </ng-template>

    <button
      *ngIf="form.get('operation').value"
      type="button"
      class="orms-button orms-button-secondary"
      (click)="onClearClick($event)"
    >
      {{ 'general.clear' | translate }}
      <img src="/assets/icons/icon-close.svg" alt="{{ 'imgAlt.close' | translate }}" />
    </button>
  </form>
</mat-menu>
