import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { UsersService } from 'src/app/shared/services/users.service';
import { User } from '../../common-models/user.model';
import { MinLengthTypeAhead } from '../constants';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input() projectId: number;
  @Input() clearable = false;
  @Input() manager: User;
  @Input() dataSource: (projectId: number, term: string) => Observable<any>;

  @Output() valueChange = new EventEmitter<any>();
  userControl = new UntypedFormControl();
  touched = false;
  users$: Observable<User[]>;
  userInput$ = new Subject<string>();
  loading = false;
  readonly MinLengthTypeAhead = MinLengthTypeAhead;

  trackByFn(user: User): number {
    return user.userId;
  }

  private loadUsers(): void {
    this.users$ = concat(
      of([]),
      this.userInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap((term) =>
          (this.dataSource
            ? this.dataSource(this.projectId, term)
            : this.usersService.getUsersForSearch(this.projectId, term)
          ).pipe(
            catchError(() => of([])),
            tap(() => (this.loading = false)),
          ),
        ),
      ),
    );
  }

  onChange = (user: User) => {};
  onTouched = () => {};

  constructor(
    private usersService: UsersService,
    controlDirective: NgControl,
  ) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.manager && changes.manager.currentValue && changes.manager.currentValue.userId === null) {
      this.userControl.setValue(
        changes.manager.currentValue.displayName ? changes.manager.currentValue.displayName : null,
        {
          emitEvent: false,
        },
      );
    }
  }

  selectUser(): void {
    const selectedUser = this.userControl.value;
    this.onChange({
      userId: selectedUser?.userId,
      displayName: selectedUser?.displayName,
    });
    this.onTouched();
  }

  writeValue(user: User): void {
    if (user && user.userId) {
      this.userControl.setValue(user, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.userControl.disable();
    } else {
      this.userControl.enable();
    }
  }
}
