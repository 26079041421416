import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getIdSelector } from 'src/app/root-store/root-store.selector';
import { DeliverableListResponse } from '../deliverables.service';
import * as deliverablesListReducer from './deliverables-list.reducer';
import * as fhDeliverableIdsReducer from './deliverables-listfh.reducer';

export const selectDeliverablesListState =
  createFeatureSelector<deliverablesListReducer.DeliverablesListState>('deliverablesList');

export const selectFunctionalHierarchyDeliverableIdsState =
  createFeatureSelector<fhDeliverableIdsReducer.FHDeliverableIdsState>('fhDeliverableIds');

export const getDeliverablesListForCurrentNode = createSelector(
  getIdSelector,
  selectFunctionalHierarchyDeliverableIdsState,
  selectDeliverablesListState,
  (id, deliverableIdsState, deliverablesListState) => {
    const fhDeliverableIds = deliverableIdsState.entities[id];
    const deliverables = fhDeliverableIds
      ? Object.entries(deliverablesListState.entities)
          .filter(([, v]) => fhDeliverableIds.deliverableIds.includes(v.deliverableId))
          .map(([, v]) => v)
      : undefined;
    const deliverableListResponse: DeliverableListResponse = {
      deliverables: deliverables,
      canAdd: fhDeliverableIds?.canAdd,
      canDelete: fhDeliverableIds?.canDelete,
      canMove: fhDeliverableIds?.canMove,
      parentId: id,
    };
    return deliverableListResponse;
  },
);
