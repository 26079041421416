import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { getIdSelector, getShareIdSelector } from 'src/app/root-store/root-store.selector';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { SharedPackageService } from '../../node-header/shared.service';

@Component({
  selector: 'app-deliverable-view-detail',
  templateUrl: './deliverable-view-detail.component.html',
  styleUrls: ['./deliverable-view-detail.component.scss'],
})
export class DeliverableViewDetailComponent implements OnInit {
  deliverableDetail$: Observable<DeliverableDetail>;
  constructor(
    private store$: Store<AppState>,
    private shareService: SharedPackageService,
  ) {}

  ngOnInit(): void {
    this.deliverableDetail$ = combineLatest([
      this.store$.select(getIdSelector),
      this.store$.select(getShareIdSelector).pipe(
        filter((x) => !!x && x.hasState),
        map((x) => x.id),
      ),
    ]).pipe(
      switchMap(([deliverableId, shareId]) => this.shareService.getDeliverableViewDetailData(shareId, deliverableId)),
    );
  }
}
