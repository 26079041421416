import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { richTextFields } from '../history-common-functions';
import { HistoryData } from '../history-data.model';
import { HistoryDetail } from '../history-detail.model';

@Component({
  selector: 'app-difference-dialog',
  templateUrl: './difference-dialog.component.html',
  styleUrls: ['./difference-dialog.component.scss'],
})
export class DifferenceDialogComponent {
  historyData: HistoryData;
  standardElements: HistoryDetail[];
  richTextBoxes: HistoryDetail[];
  historySubject: EntityTypes;
  init: any;
  fieldsToDisplayAtEnd = richTextFields;
  constructor(
    appConfigService: AppConfigService,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    data: { historyData: HistoryData; historySubject: EntityTypes },
  ) {
    this.historyData = data.historyData;
    this.standardElements = data.historyData.details.filter((d) => !this.fieldsToDisplayAtEnd.includes(d.itemName));
    this.richTextBoxes = data.historyData.details.filter((d) => this.fieldsToDisplayAtEnd.includes(d.itemName));
    this.historySubject = data.historySubject;
    this.init = {
      ...appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...appConfigService.settings.richTextConfig.richDialogConfig,
    };
  }
}
