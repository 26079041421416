<div class="vertical-center">
  <div class="header">
    <div class="logo-title">
      <img src="/assets/images/logo.svg" class="logo" alt="{{ 'imgAlt.hatchLogo' | translate }}" />
      <div class="title">{{ 'general.productName' | translate }}</div>
    </div>
    <div class="description">{{ 'general.productDescription' | translate }}</div>
  </div>

  <div class="content">
    <button *ngIf="!isUserLogged" class="orms-button orms-button-primary login-logout-button" (click)="login()">
      {{ 'general.signIn' | translate }}
    </button>
    <button *ngIf="isUserLogged" class="orms-button orms-button-primary login-logout-button" (click)="logout()">
      {{ 'general.logout' | translate }}
    </button>
    <a class="orms-link-button" href="mailto:{{ mailTo }}">
      {{ 'general.needHelp' | translate }}
    </a>
    <div *ngIf="!isUserLogged && false">
      <span class="text">
        {{ 'general.needNewWorkspace' | translate }}
      </span>
      <a class="orms-link-button" [href]="getStartedLink" target="_blank">
        {{ 'general.getStarted' | translate }}
      </a>
    </div>
  </div>
  <div class="footer">
    <div class="app-version">{{ 'general.version' | translate }} {{ appVersion }}</div>
    <img src="/assets/images/logo-red.svg" class="logo-red" alt="{{ 'imgAlt.hatchLogo' | translate }}" />
    <div class="copyright">
      <span>
        {{ 'general.copyright' | translate }} {{ currentDate | date: 'yyyy' }}.
        {{ 'general.rightsReserved' | translate }}
      </span>
      <span class="separator">|</span>
      <a class="orms-link-button terms-button" routerLink="/terms&privacy">
        {{ 'terms&privacy.title' | translate }}
      </a>
    </div>
  </div>
</div>
