<div class="filters">
  <div class="input-group filter-search">
    <input
      autocomplete="off"
      class="form-control orms-simple-input search-input"
      placeholder="{{ 'general.search' | translate }}"
      [ngModel]="searchFilterValue"
      (ngModelChange)="changeFilter(CRFilterType.FullText, $event)"
    />
    <div class="input-group-append orms-simple-input-append">
      <div class="input-group-text search-icon">
        <img src="/assets/icons/icon-search.svg" alt="{{ 'imgAlt.search' | translate }}" />
      </div>
    </div>
  </div>
  <mat-select
    class="orms-select status-filter"
    [disableOptionCentering]="true"
    [ngModel]="statusFilterValue"
    (ngModelChange)="changeFilter(CRFilterType.Status, $event)"
    panelClass="deliverables-status-filter-select orms-select-options"
  >
    <mat-option *ngFor="let statusFilter of statusFilters" [value]="statusFilter.key">
      {{ statusFilter.text | translate }}
    </mat-option>
  </mat-select>

  <ng-select
    bindLabel="userName"
    [items]="approverList"
    [multiple]="true"
    [formControl]="userControl"
    [clearable]="false"
    [closeOnSelect]="false"
    [placeholder]="'changeRequest.approver' | translate"
    [selectableGroup]="true"
    #select
    (change)="changeFilter(CRFilterType.Approver, $event)"
    class="orms-multiselect"
  >
    <ng-template ng-header-tmp>
      <input
        #headerInput
        class="orms-multiselect-search"
        (input)="select.filter(headerInput.value)"
        (compositionstart)="select.onCompositionStart()"
        (compositionend)="select.onCompositionEnd(headerInput.value)"
        (focus)="select.onInputFocus($event)"
        (blur)="select.onInputBlur($event)"
        (change)="$event.stopPropagation()"
        [placeholder]="'general.search' | translate"
      />
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <div class="custom-control custom-checkbox orms-multiselect-checkbox">
        <input type="checkbox" class="custom-control-input" id="item-{{ index }}" [ngModel]="item$.selected" />
        <label class="custom-control-label" for="item-{{ index }}">{{ $any(item).userName }}</label>
      </div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items">
      <ng-container *ngIf="items.length === 1">
        <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
          <span class="ng-value-label"> {{ $any(item).userName }}</span>
        </div>
      </ng-container>
      <div class="ng-value" *ngIf="items.length > 1">
        <span class="ng-value-label">{{ 'changeRequest.approver' | translate }} ({{ items.length }})</span>
      </div>
    </ng-template>
    <ng-template ng-footer-tmp>
      <button
        *ngIf="userControl.value.length"
        type="button"
        class="orms-button orms-button-secondary"
        (click)="select.handleClearClick()"
      >
        {{ 'general.clear' | translate }}
        <img src="/assets/icons/icon-close.svg" alt="{{ 'imgAlt.close' | translate }}" />
      </button>
    </ng-template>
  </ng-select>
</div>
