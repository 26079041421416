import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EntityTypes, getKeyByValue } from 'src/app/common-models/node-types/node-types';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TreeNode } from '../tree-navigation/tree-structure.model';
import { DeliverableDetail } from './navigation-tabs/deliverables/DeliverableDetail';
import { Detail } from './navigation-tabs/detail/detail.model';
import { PathRequest } from './path.model';
import { PropertyPatch } from './propert-patch.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  updateProperty(entityType: EntityTypes, update: Update<TreeNode | Detail | DeliverableDetail>): Observable<any> {
    const reverseType = getKeyByValue(entityType);

    const changes: PropertyPatch[] = [];
    const changedProperties = Object.keys(update.changes);
    changedProperties.forEach((p) => {
      const change = new PropertyPatch();
      change.PropertyName = p;
      change.PropertyValue = update.changes[p];
      changes.push(change);
    });

    const requestBody: PathRequest = {
      Id: update.id,
      PatchDTOs: changes,
      EntityType: reverseType,
    };

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const updatePropUrl = this.getUpdateUrl(entityType);
    return this.httpClient.patch(baseUrl + updatePropUrl, requestBody).pipe(
      catchError((error) => {
        showHttpErrorResponse(this.notificationService, error, 'general.updateFailed');
        return of(EMPTY);
      }),
    );
  }

  private getUpdateUrl(entityType: EntityTypes): string {
    return entityType === EntityTypes.deliverable
      ? this.appConfigService.settings.api.endpoints.updateDeliverableProperty
      : this.appConfigService.settings.api.endpoints.updateProperty;
  }
}
