import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectDetails } from 'src/app/common-models/project.model';
import { ProjectState } from './project.reducer';

export const selectProjectState = createFeatureSelector<ProjectState>('project');

export const getProjectDetails = createSelector(selectProjectState, (state: ProjectState) => {
  return state?.details;
});

export const getProjectDefaultCurveProfileCode = createSelector(getProjectDetails, (details: ProjectDetails) => {
  return details?.curveProfileCode;
});

export const getProjectPortfolioId = createSelector(getProjectDetails, (details: ProjectDetails) => {
  return details?.portfolioId;
});

export const isCurrentUserLevelOneManager = createSelector(getProjectDetails, (details: ProjectDetails) => {
  return details?.isCurrentUserL1Manager;
});

export const isCurrentUserProjectManager = createSelector(getProjectDetails, (details: ProjectDetails) => {
  return details?.isCurrentUserProjectManager;
});
