import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { dashDayDateFormat, timezoneUTC } from 'src/app/shared/date-options/short-date-format';
import { isDifferentDay } from 'src/app/shared/is-different-day/is-different-day';
import { ChangeRequestPackage } from '../../change-request-cart-state/cart-item.model';

@Component({
  selector: 'app-cr-schedule',
  templateUrl: './cr-schedule.component.html',
  styleUrls: ['./cr-schedule.component.scss'],
})
export class CrScheduleComponent implements OnChanges {
  constructor(private datePipe: DatePipe) {}

  @Input() crPackages: ChangeRequestPackage[] = [];
  scheduleDataSource = [];
  scheduleColumns = ['packageCode', 'newPlanStartDate', 'newPlanEndDate', 'newForecastEndDate'];
  shortDateFormat = dashDayDateFormat;
  ngOnChanges(changes: SimpleChanges): void {
    if ('crPackages' in changes) {
      this.createScheduleDataSource();
    }
  }

  dateOrDash(compareDate: Date, toFormatDate: Date): string {
    if (toFormatDate === null) {
      return '-';
    }
    return isDifferentDay(compareDate, toFormatDate)
      ? this.datePipe.transform(toFormatDate, dashDayDateFormat, timezoneUTC)
      : '-';
  }

  private createScheduleDataSource(): void {
    this.scheduleDataSource = this.crPackages.reduce((scheduleDataSource, crPackage) => {
      const {
        oldPlanStartDate,
        newPlanStartDate,
        oldPlanEndDate,
        newPlanEndDate,
        oldForecastEndDate,
        newForecastEndDate,
        packageCode,
        status,
      } = crPackage;

      const scheduleDataItem = {
        packageCode,
        status,
        newPlanStartDate: this.dateOrDash(oldPlanStartDate, newPlanStartDate),
        oldPlanStartDate: this.dateOrDash(newPlanStartDate, oldPlanStartDate),
        planStartDateTooltipDisplay: isDifferentDay(newPlanStartDate, oldPlanStartDate),

        newPlanEndDate: this.dateOrDash(oldPlanEndDate, newPlanEndDate),
        oldPlanEndDate: this.dateOrDash(newPlanEndDate, oldPlanEndDate),
        planEndDateTooltipDisplay: isDifferentDay(newPlanEndDate, oldPlanEndDate),

        newForecastEndDate: this.dateOrDash(oldForecastEndDate, newForecastEndDate),
        oldForecastEndDate: this.dateOrDash(newForecastEndDate, oldForecastEndDate),
        forecastEndDateTooltipDisplay: isDifferentDay(newForecastEndDate, oldForecastEndDate),
      };
      scheduleDataSource.push(scheduleDataItem);
      return scheduleDataSource;
    }, []);
  }
}
