<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>
<p>paragraph</p>
<div>normal text</div>
<small>small text</small>
<br />
<strong>bold text</strong>
<br />
<span class="orms-label">orms-label</span>
<hr />
<button
  type="button"
  mat-flat-button
  color="primary"
  matTooltip="Tooltip over"
  matTooltipPosition="above"
  matTooltipClass="orms-tooltip"
>
  Tooltip over
</button>

<hr />
<h5>Shadow input</h5>
<input class="form-control orms-shadow-input orms-node-title" placeholder="Title" />
<br />

<h5>Invalid shadow input</h5>
<input class="form-control orms-shadow-input orms-node-title" [class.is-invalid]="true" placeholder="Title" />
<br />

<h5>Readonly shadow input</h5>
<input class="form-control orms-shadow-input orms-node-title" [readonly]="true" placeholder="Title" />
<hr />

<h5>Dropdown</h5>
<mat-select class="orms-select" panelClass="orms-select-options" [(value)]="selectValue">
  <mat-option value="option1"> Option 1 </mat-option>
  <mat-option value="option2"> Option 2 </mat-option>
</mat-select>
<hr />

<h5>Disabled Dropdown</h5>
<mat-select class="orms-select" panelClass="orms-select-options" [(value)]="selectValue" disabled>
  <mat-option value="option1"> Option 1 </mat-option>
  <mat-option value="option2"> Option 2 </mat-option>
</mat-select>
<hr />

<h5>Prepended shadow input ($)</h5>
<div class="input-group">
  <div class="input-group-prepend orms-shadow-input-prepend">
    <div class="input-group-text">$</div>
  </div>
  <input class="form-control orms-shadow-input" placeholder="0" />
</div>
<hr />

<h5>Append simple input (%)</h5>
<div class="input-group">
  <input class="form-control orms-simple-input" placeholder="0" />
  <div class="input-group-append orms-simple-input-append">
    <div class="input-group-text">%</div>
  </div>
</div>
<hr />

<h5>Toggle switch</h5>
<div class="custom-control custom-switch orms-toggle-switch-container">
  <input type="checkbox" class="custom-control-input orms-toggle-switch" id="onHold" />
  <label class="custom-control-label" for="onHold"></label>
</div>

<hr />
<h5>Notifications</h5>
<button type="button" class="mr-1" mat-flat-button color="accent" (click)="openErrorToast()">Open error toast</button>
<button type="button" class="mr-1" mat-flat-button color="warn" (click)="openWarningToast()">Open warning toast</button>
<button type="button" mat-flat-button color="primary" (click)="openSuccessToast()">Open success toast</button>

<hr />
<h5>Simple input</h5>
<input class="form-control orms-simple-input" placeholder="EnterTheTitleOfYourRequest" />

<hr />
<h5>Tabs with links</h5>
<nav mat-tab-nav-bar>
  <a mat-tab-link class="tab orms-link-tab orms-active-link-tab" defaultTabIndex="0"> link </a>
  <a mat-tab-link class="tab orms-link-tab orms-active-tab" defaultTabIndex="0"> link2 </a>
  <a mat-tab-link class="tab orms-link-tab orms-active-tab" defaultTabIndex="0"> link3 </a>
  <a mat-tab-link class="tab orms-link-tab orms-active-tab" defaultTabIndex="0"> link4 </a>
</nav>

<hr />
<h5>Tabs</h5>
<mat-tab-group class="orms-tabs" animationDuration="0ms">
  <mat-tab label="First" class="orms-tab"> Content 1 </mat-tab>
  <mat-tab label="Second"> Content 2 </mat-tab>
  <mat-tab label="Third"> Content 3 </mat-tab>
</mat-tab-group>

<hr />
<div class="buttons-section">
  <h5>Buttons</h5>
  <button class="orms-button orms-button-primary">Primary</button>
  <button class="orms-button orms-button-secondary">Secondary</button>
  <button class="orms-link-button">Link button</button>
  <h5>Disabled buttons</h5>
  <button disabled class="orms-button orms-button-primary">Primary</button>
  <button disabled class="orms-button orms-button-secondary">Secondary</button>
  <button disabled class="orms-link-button">Link button</button>

  <h5>Button with href</h5>
  <a class="orms-button orms-button-a-primary" [href]="'http://wwww.google.com'" target="_blank">Click me</a>
  <a
    class="orms-button orms-button-a-primary orms-button-a-primary-disabled"
    [href]="'http://wwww.google.com'"
    target="_blank"
    >I'm disabled</a
  >
</div>

<hr />
<h5>Shadow datepicker</h5>
<mat-form-field class="orms-datepicker-shadow-input">
  <input matInput [matDatepicker]="sampleDatepicker" [value]="sampleDate" (click)="sampleDatepicker.open()" />
  <mat-datepicker panelClass="orms-datepicker" #sampleDatepicker [calendarHeaderComponent]="datepickerActionsComponent">
  </mat-datepicker>
</mat-form-field>
<h5>Shadow datepicker with footer</h5>
<mat-form-field class="orms-datepicker-shadow-input">
  <input matInput [matDatepicker]="plannedStart" [value]="sampleDate" (click)="plannedStart.open()" />
  <mat-datepicker panelClass="orms-datepicker" [calendarHeaderComponent]="datepickerActionsComponent" #plannedStart>
  </mat-datepicker>
</mat-form-field>
<h5>Shadow datepicker disabled</h5>
<mat-form-field class="orms-datepicker-shadow-input">
  <input
    matInput
    [matDatepicker]="sampleDisabledDatepicker"
    disabled
    [value]="sampleDate"
    (click)="sampleDisabledDatepicker.open()"
  />
  <mat-datepicker
    panelClass="orms-datepicker"
    #sampleDisabledDatepicker
    [calendarHeaderComponent]="datepickerActionsComponent"
  ></mat-datepicker>
</mat-form-field>

<hr />
<div class="dialogs-section">
  <h5>Dialogs</h5>
  <button class="orms-button orms-button-primary" (click)="openDifferenceDialog()">Difference Dialog</button>
</div>
