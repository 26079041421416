<form [formGroup]="form" autocomplete="off">
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.budgetHours' | translate }}
    </h6>
    <h5 class="fake-input">{{ detailData.budgetHours | number: '1.0-0' }}</h5>
  </div>
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.budgetCost' | translate }}
    </h6>
    <h5 class="fake-input">${{ detailData.budgetCost | number: '1.0-0' }}</h5>
  </div>
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.deliverables' | translate }}
    </h6>
    <h5 class="fake-input">
      {{ detailData.deliverableCount | number: '1.0-0' }}
    </h5>
  </div>
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.plannedStart' | translate }}
      <app-cr-warning
        [cr]="changeRequestPackage"
        currentKey="oldPlanStartDate"
        requestedKey="newPlanStartDate"
        type="date"
      ></app-cr-warning>
    </h6>
    <mat-form-field class="orms-datepicker-shadow-input">
      <input
        matInput
        [matDatepicker]="plannedStart"
        formControlName="planStartDate"
        (click)="plannedStart.open()"
        [class.is-invalid]="showInvalidState('planStartDate')"
      />
      <mat-datepicker panelClass="orms-datepicker" [calendarHeaderComponent]="datepickerActionsComponent" #plannedStart>
      </mat-datepicker>
    </mat-form-field>
  </div>
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.plannedEnd' | translate }}
      <app-cr-warning
        [cr]="changeRequestPackage"
        currentKey="oldPlanEndDate"
        requestedKey="newPlanEndDate"
        type="date"
      ></app-cr-warning>
    </h6>
    <mat-form-field class="orms-datepicker-shadow-input">
      <input
        matInput
        [matDatepicker]="plannedEnd"
        formControlName="planEndDate"
        (click)="plannedEnd.open()"
        [class.is-invalid]="showInvalidState('planEndDate')"
      />
      <mat-datepicker panelClass="orms-datepicker" [calendarHeaderComponent]="datepickerActionsComponent" #plannedEnd>
      </mat-datepicker>
    </mat-form-field>
  </div>
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.forecastEnd' | translate }}
      <app-cr-warning
        [cr]="changeRequestPackage"
        currentKey="oldForecastEndDate"
        requestedKey="newForecastEndDate"
        type="date"
      ></app-cr-warning>
    </h6>
    <mat-form-field class="orms-datepicker-shadow-input">
      <input
        matInput
        [matDatepicker]="forecastEndDate"
        formControlName="forecastEndDate"
        (click)="forecastEndDate.open()"
      />
      <mat-datepicker
        panelClass="orms-datepicker"
        [calendarHeaderComponent]="datepickerActionsComponent"
        #forecastEndDate
      >
      </mat-datepicker>
    </mat-form-field>
  </div>
</form>
