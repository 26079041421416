<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <h2>{{ 'deliverable.deliverableStatusChange' | translate }}</h2>
  <div class="orms-section">
    <div *ngIf="isTaskBased" class="deliverable-column input-group">
      <input
        onfocus="this.select()"
        class="form-control orms-simple-input percentage-complete-input"
        [class.is-invalid]="showInvalidState('progress')"
        formControlName="progress"
        placeholder="%"
      />
      <div class="input-group-append orms-simple-input-append">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <ng-container *ngIf="workflowSteps$ | async as workflowSteps; else loading">
      <div *ngIf="!isTaskBased" class="deliverable-column">
        <mat-select
          formControlName="progress"
          (selectionChange)="workflowStepProgressChanged($event.value, workflowSteps)"
        >
          <mat-option *ngFor="let w of workflowSteps" [value]="w.progressWorkflowStatusId">
            {{ w.title }}
          </mat-option>
        </mat-select>
      </div>
    </ng-container>
    <div [style.display]="progressRegression ? 'block' : 'none'" class="deliverable-column">
      <h6 class="orms-label orms-label-margin">
        {{ 'deliverable.provideReasonForRegression' | translate }}
      </h6>
      <input
        autocomplete="off"
        class="form-control orms-simple-input"
        type="text"
        [class.is-invalid]="showInvalidState('reason')"
        formControlName="reason"
        [title]="'deliverable.provideReasonForRegression' | translate"
        [placeholder]="'general.typeHere' | translate"
      />
    </div>
  </div>
  <div class="buttons-div">
    <button class="orms-button orms-button-primary submit-button" type="submit">
      {{ 'general.submit' | translate }}
    </button>
    <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
      {{ 'general.cancel' | translate }}
    </button>
  </div>
</form>
<ng-template #loading>
  <app-spinner *ngIf="!isTaskBased"></app-spinner>
</ng-template>
