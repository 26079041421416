import { Component, Input } from '@angular/core';
import { TreeNodeAction } from 'src/app/project-management/tree-navigation/tree-structure.model';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent {
  @Input() actions: TreeNodeAction[] = [];
  @Input() display = true;
  @Input() item: any;
  @Input() indicator: number;
  @Input() isMatButtonIcon = true;
  @Input() quantityIndicator = true;
  @Input() borderedIcon = false;

  callBackWrapper(element, action, event) {
    if (action.confirm) {
      event.stopPropagation();
      action.showConfirm = true;
      return;
    }

    action.callBack(element);
    action.showConfirm = false;
  }

  confirm(element, action) {
    action.callBack(element);
    action.showConfirm = false;
  }

  cancel(action) {
    action.showConfirm = false;
  }
}
