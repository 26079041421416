<ng-container *ngIf="treeHierarchySize$ | async as treeHierarchySize"
  ><div class="details">
    <div class="cf-functions-header">
      {{
        (treeHierarchySize > hierarchySizeWithoutSubFunctions
          ? 'changeRequest.originalSubFunction'
          : 'changeRequest.originalFunction'
        ) | translate
      }}
    </div>
    <div class="cr-function-info">{{ changeRequest.moveFromCode ?? '' }}{{ ' - ' + changeRequest.moveFromTitle }}</div>
    <div class="cf-functions-header">
      {{
        (treeHierarchySize > hierarchySizeWithoutSubFunctions
          ? 'changeRequest.newSubFunction'
          : 'changeRequest.newFunction'
        ) | translate
      }}
    </div>
    <div class="cr-function-info">{{ changeRequest.moveToCode ?? '' }}{{ ' - ' + changeRequest.moveToTitle }}</div>
  </div>
</ng-container>
