import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DiagramComponent } from '@syncfusion/ej2-angular-diagrams';
import { DataManager } from '@syncfusion/ej2-data';
import {
  ContextMenuSettingsModel,
  DataBinding,
  Diagram,
  DiagramTools,
  NodeConstraints,
  NodeModel,
  PageSettingsModel,
  RadialTree,
  SnapConstraints,
  SnapSettingsModel,
  ZoomOptions,
} from '@syncfusion/ej2-diagrams';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { RelationshipType } from './visualization-container.component';

export interface DataInfo {
  [key: string]: string;
}
Diagram.Inject(DataBinding, RadialTree);

@Component({
  selector: 'app-visualization',
  templateUrl: 'visualization.component.html',
  styleUrls: ['visualization.component.scss'],
})
export class VisualizationComponent implements OnInit, AfterViewInit {
  @ViewChild('diagram')
  public diagram: DiagramComponent;
  public contextMenuSettings: ContextMenuSettingsModel;
  public pageSettings: PageSettingsModel;
  public tool: DiagramTools = DiagramTools.ZoomPan;
  @Input() treeData;
  public data = {};

  public layout = {
    type: 'RadialTree',
    verticalSpacing: 30,
    horizontalSpacing: 30,
    root: 'parent',
  };

  public snapSettings: SnapSettingsModel = {
    constraints: SnapConstraints.None,
  };

  ngOnInit(): void {
    this.contextMenuSettings = {
      show: true,
    };
    this.pageSettings = {};
  }

  ngAfterViewInit(): void {
    this.data = {
      id: 'id',
      parentId: 'parentVisualizationId',
      dataSource: new DataManager(this.treeData as any),
      doBinding: (nodeModel: NodeModel, data: any, diagram: Diagram) => {
        nodeModel.width = 300;
        nodeModel.height = 120;
        data.icon = 'work';
        data.iconOutlined = false;
        nodeModel.shape = { type: 'HTML' };
        nodeModel.constraints = (NodeConstraints.Default & ~NodeConstraints.InheritTooltip) | NodeConstraints.Tooltip;
        nodeModel.tooltip = {
          content: data.title + '<br/>' + data.relationshipType,
          relativeMode: 'Object',
          position: 'TopCenter',
          showTipPointer: true,
        };

        if (data.relationshipType === RelationshipType.Child) {
          data.icon = 'description';
          data.iconOutlined = true;
        } else if (data.relationshipType === RelationshipType.Parent) {
          data.icon = 'account_tree';
          data.iconOutlined = true;
        }
      },
    };
  }

  onDataLoaded(event): void {
    event.diagram.connectors.forEach((connector) => {
      connector.annotations = [{ content: '' }];
      const node = event.diagram.nodes.find((x) => x.properties.id === connector.properties.targetID);
      if (node && connector.annotations[0]) {
        if (node.data.relationshipType === RelationshipType.Predecessor) {
          connector.annotations[0].properties.content = 'Predecessor';
        } else if (node.data.relationshipType === RelationshipType.Related) {
          connector.annotations[0].properties.content = 'Related';
        } else if (node.data.relationshipType === RelationshipType.Successor) {
          connector.annotations[0].properties.content = 'Successor';
        } else if (node.data.relationshipType === RelationshipType.Child) {
          connector.annotations[0].properties.content = 'Child';
        } else if (node.data.relationshipType === RelationshipType.Parent) {
          connector.annotations[0].properties.content = 'Parent';
        } else {
          connector.annotations[0].properties.content = 'Unknown';
        }
      }
    });
  }

  public create(args: any): void {
    this.diagram.fitToPage();
    this.diagram.dataBind();
  }

  public onItemClick(args: ClickEventArgs): void {
    switch (args.item.id) {
      case 'ZoomIn':
        const zoomin: ZoomOptions = { type: 'ZoomIn', zoomFactor: 0.2 };
        this.diagram.zoomTo(zoomin);
        this.diagram.dataBind();
        break;
      case 'ZoomOut':
        const zoomout: ZoomOptions = { type: 'ZoomOut', zoomFactor: 0.2 };
        this.diagram.zoomTo(zoomout);
        this.diagram.dataBind();
        break;
      case 'Reset':
        this.diagram.reset();
        this.diagram.fitToPage();
        break;
    }
  }
}
