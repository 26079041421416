<ng-container *ngIf="projectDetails$ | async as projectDetails">
  <app-node-header
    *ngIf="nodeType !== entityTypes.deliverable; else deliverableHeader"
    [entityId]="nodeId"
    [entityType]="nodeType"
    [hasProjectManagementPermission]="projectDetails.isCurrentUserProjectManager"
  >
    <app-breadcrumbs [options]="breadcrumbOptions"></app-breadcrumbs>
  </app-node-header>
</ng-container>
<ng-template #deliverableHeader>
  <app-deliverable-header [entityId]="nodeId" [breadcrumbOptions]="breadcrumbOptions"> </app-deliverable-header>
</ng-template>
<nav mat-tab-nav-bar [tabPanel]="">
  <a
    mat-tab-link
    *ngFor="let link of links"
    routerLink="{{ link.path }}"
    class="tab orms-link-tab"
    routerLinkActive="orms-active-link-tab"
    defaultTabIndex="0"
  >
    {{ link.display | translate }}
  </a>
</nav>
<router-outlet></router-outlet>
