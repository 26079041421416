import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ChangeRequestListService } from 'src/app/project-management/common/navigation-tabs/change-request-list/change-request-list.service';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { PackageRelationshipItem, RelationshipType } from './PackageRelationship.model';
import { calculateOverlapAccordingToType } from './package-relationship.helper';
import { PackageRelationshipsService } from './package-relationships.service';

@Component({
  selector: 'app-package-relationships',
  templateUrl: './package-relationships.component.html',
  styleUrls: ['./package-relationships.component.scss'],
})
export class PackageRelationshipsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() packageHierarchyId: number;
  @Input() startDate: Date;
  @Input() endDate: Date;
  canModifyRelations: boolean;
  dataSource: PackageRelationshipItem[];
  relationships: PackageRelationshipItem[];
  projectId: number;
  subscription = new Subscription();
  constructor(
    private packageRelationshipsService: PackageRelationshipsService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private changeRequestListService: ChangeRequestListService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('packageHierarchyId' in changes) {
      this.loadData();
    }
  }

  ngOnInit(): void {
    this.store$
      .select(TreeSelectors.hasManagePermissionOnCurrentNode)
      .subscribe((canManage) => (this.canModifyRelations = canManage));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadData(): void {
    const days = this.translateService.instant('general.days');

    const sub = this.store$
      .select(getProjectIdSelector)
      .pipe(
        filter((projectId) => !!projectId),
        tap((projectId) => ((this.relationships = []), (this.projectId = projectId))),
        take(1),
        switchMap((projectId) =>
          this.packageRelationshipsService.getRelatedPackages(this.packageHierarchyId, projectId).pipe(
            take(1),
            map((relationships) => {
              return relationships.map((rel) => {
                const overlap = calculateOverlapAccordingToType(
                  rel.startDate,
                  rel.endDate,
                  this.startDate,
                  this.endDate,
                  rel.relationType,
                );

                return { ...rel, overlap: overlap ? `${overlap} ${days}` : '-' };
              });
            }),
          ),
        ),
        switchMap((relationships) =>
          this.changeRequestListService
            .getChangeRequestsPendingApproval(
              this.projectId,
              relationships.map((x) => x.packageHierarchyId),
            )
            .pipe(
              take(1),
              map((crs) => {
                return {
                  relationships: relationships.map((x) => ({
                    ...x,
                    isPendingScheduleCR: crs
                      .filter((cr) => cr.functionalHierarchyId === x.packageHierarchyId)
                      .some((cr) => cr.newStartDate || cr.newEndDate),
                  })),
                };
              }),
            ),
        ),
        map(({ relationships }) => {
          this.relationships = relationships;
        }),
      )
      .subscribe();

    this.subscription.add(sub);
  }

  removeRelationship(relationship: PackageRelationshipItem): void {
    const sub = this.store$
      .select(getProjectIdSelector)
      .pipe(
        filter((projectId) => !!projectId),
        switchMap((projectId) => {
          return this.packageRelationshipsService.removeRelationship(
            this.packageHierarchyId,
            relationship.packageHierarchyId,
            projectId,
          );
        }),
      )
      .subscribe(() => {
        this.loadData();
      });

    this.subscription.add(sub);
  }

  addRelationship(relationships: { packageIds: number[]; type: RelationshipType }): void {
    const sub = this.store$
      .select(getProjectIdSelector)
      .pipe(
        switchMap((projectId) => {
          return this.packageRelationshipsService.addRelationship(
            this.packageHierarchyId,
            relationships.packageIds,
            relationships.type,
            projectId,
          );
        }),
      )
      .subscribe(() => {
        this.packageRelationshipsService.clearRelationshipsFromSearch.next();
        this.loadData();
      });

    this.subscription.add(sub);
  }
}
