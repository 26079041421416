import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { getShareIdSelector } from 'src/app/root-store/root-store.selector';
import { shadowFormConfig, shadowFormUpdateConfig } from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { StatusCodeDetail, getStatusDetailIncludingHealthValues } from '../../../shared-controls/status/statusCodesMap';
import { NodeHeaderService } from '../../common/node-header-service';
import { NodeHeaderModel } from '../../common/node-header.model';
import { SharePackage } from '../../node-header/share/share.model';
import { SharedPackageService } from '../../node-header/shared.service';
import { TreeNode } from '../../tree-navigation/tree-structure.model';

@Component({
  selector: 'app-node-header-view',
  templateUrl: './node-header-view.component.html',
  styleUrls: ['./node-header-view.component.scss'],
})
export class NodeHeaderViewComponent implements OnInit, OnDestroy {
  @Input() entityId: number;
  @Input() entityType: EntityTypes;
  @Input() viewOnly = false;
  headerDetails: NodeHeaderModel;
  treeNode: TreeNode;
  nodeHeaderService: NodeHeaderService;
  projectId: number;
  expirationDaysCount: number;
  lessOrEqualThreeDays: boolean;
  statusDetails: StatusCodeDetail;
  form: UntypedFormGroup;
  entityTypes = EntityTypes;
  actions = [];
  sharePackage: SharePackage;

  private headerDetailsSubscription = new Subscription();
  private projectIdSubscription = new Subscription();
  private controlsValueSubscription = new Subscription();
  private serverValidatorsSubscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    public dialog: MatLegacyDialog,
    private sharedPackageService: SharedPackageService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.headerDetailsSubscription = this.store$
      .select(getShareIdSelector)
      .pipe(switchMap(({ id }) => this.sharedPackageService.accessPackage(id)))
      .subscribe((treeNode) => {
        this.treeNode = treeNode;
        this.headerDetails = NodeHeaderModel.FromTreeNode(treeNode);
        this.statusDetails = getStatusDetailIncludingHealthValues(
          this.headerDetails?.status,
          this.headerDetails?.health,
        );
        if (this.headerDetails.nodeId) {
          this.fillForm();
          Object.keys(this.form.controls).forEach((controlName) => {
            const control = this.form.get(controlName);
            control.disable({ emitEvent: false });
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.projectIdSubscription.unsubscribe();
    this.serverValidatorsSubscription.unsubscribe();
    this.controlsValueSubscription.unsubscribe();
    this.headerDetailsSubscription.unsubscribe();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        title: new UntypedFormControl(),
        code: new UntypedFormControl(),
        manager: new UntypedFormControl(),
      },
      { ...shadowFormConfig },
    );
  }

  private fillForm(): void {
    if (!this.headerDetails) {
      this.form.reset(
        {
          title: null,
          code: null,
          manager: null,
        },
        { ...shadowFormUpdateConfig },
      );
      return;
    }

    const { title, code, managerId, managerName } = this.headerDetails;
    this.form.patchValue(
      {
        title,
        code,
        manager: {
          userId: managerId,
          displayName: managerName,
        },
      },
      { ...shadowFormUpdateConfig },
    );
  }
}
