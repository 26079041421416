import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProjectImportColumns } from '../../project-import.models';
import {
  createCategoryFilter,
  createCodeFilter,
  createFeedbackFilter,
  createResultFilter,
  createSheetFilter,
} from './review-import-filters.helpers';
import { ReviewImportFilterState, ReviewImportViewStateService } from './review-import-view-state.service';

export interface ReviewImportFilter {
  filterId: ProjectImportColumns;
  predicate: (row: any) => boolean;
  state?: any;
}
@Injectable()
export class ReviewImportFiltersService implements OnDestroy {
  private filters$ = new BehaviorSubject<ReviewImportFilter[]>([]);
  private rowDataNodes$ = new BehaviorSubject<any[]>(null);

  private onDestroy$ = new Subject<void>();

  constructor(private viewStateService: ReviewImportViewStateService) {
    combineLatest([this.rowDataNodes$, this.viewStateService.filtersState$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([nodes, filtersState]) => {
        this.filters$.next(
          Array.isArray(filtersState)
            ? filtersState.map((filter) => this.createFilter(filter.filterId, filter.state, nodes))
            : [],
        );
      });
  }

  private createFilter(filterId: ProjectImportColumns, state: any, nodes: any[]): ReviewImportFilter {
    let newFilter: ReviewImportFilter;
    switch (filterId) {
      case ProjectImportColumns.Result: {
        newFilter = createResultFilter(state, nodes);
        break;
      }
      case ProjectImportColumns.Code: {
        newFilter = createCodeFilter(state, nodes);
        break;
      }
      case ProjectImportColumns.Sheet: {
        newFilter = createSheetFilter(state, nodes);
        break;
      }
      case ProjectImportColumns.Feedback: {
        newFilter = createFeedbackFilter(state, nodes);
        break;
      }
      case ProjectImportColumns.Category: {
        newFilter = createCategoryFilter(state, nodes);
        break;
      }
      default:
        throw new Error('unsupported filter type');
    }
    return newFilter;
  }

  clearFilter(filterId: ProjectImportColumns): void {
    const remainingFilters = this.filters$.value.filter((f) => f.filterId !== filterId);

    this.viewStateService.setFiltersState(this.getFiltersStates(remainingFilters));
  }

  getNodes(): Observable<any[]> {
    return this.rowDataNodes$.asObservable();
  }

  setNodes(rowDataNodes: any[]) {
    this.rowDataNodes$.next(rowDataNodes);
  }

  getFilters(): Observable<ReviewImportFilter[]> {
    return this.filters$.asObservable();
  }

  getFilterState<T>(filterId: ProjectImportColumns): Observable<T> {
    return this.getFilters().pipe(
      map((filters) => {
        const filter = filters?.find((f) => f.filterId === filterId);
        return filter ? filter.state : null;
      }),
      map((filters) => filters as T),
    );
  }

  setFilterState(filterId: ProjectImportColumns, state: any): void {
    const remainingFilters = this.filters$.value.filter((filter) => filter.filterId !== filterId);

    this.viewStateService.setFiltersState([...this.getFiltersStates(remainingFilters), { filterId, state }]);
  }

  getFiltersStates(filters: ReviewImportFilter[]): ReviewImportFilterState[] {
    return filters.map((f) => ({
      filterId: f.filterId,
      state: f.state,
    }));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
