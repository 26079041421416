<mat-tab-group #tabs (selectedIndexChange)="tabChange($event)" [@.disabled]="true">
  <mat-tab label="{{ data.wizardTitle | translate }}"></mat-tab>
  <mat-tab *ngFor="let step of data.steps">
    <ng-template mat-tab-label>
      <div class="image-wrapper">
        <img class="image-color" *ngIf="!step.visited; else visited" src="/assets/icons/circle.svg" />
      </div>
      <ng-container
        [ngTemplateOutlet]="step.labelTemplate || defaultLabelTemplate"
        [ngTemplateOutletContext]="data.context"
      >
      </ng-container>
      <ng-template #defaultLabelTemplate>
        <div>
          <div>{{ step.label | translate }}</div>
          <div class="sub-label" *ngIf="step.visited && step.subLabel">
            <span>{{ step.subLabel }}</span>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <ng-template matTabContent>
      <ng-container [ngTemplateOutlet]="step.stepHeaderTemplate || defaultStepHeaderTemplate"> </ng-container>
      <ng-template #defaultStepHeaderTemplate>
        <div class="header-title" *ngIf="data.projectTitle">
          <img class="title-icon" src="/assets/icons/project.svg" />
          <div>{{ data.projectTitle }}</div>
        </div>
        <div class="step-content" *ngIf="step.title">
          <div class="step-content-title">{{ step.title | translate }}</div>
          <div class="step-content-sub-title" *ngIf="step.subTitle">
            {{ step.subTitle | translate }}
          </div>
        </div>
      </ng-template>
      <div class="content-container" #container></div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ng-template #visited>
  <img src="/assets/icons/circle-checked.svg" class="image-color" />
</ng-template>
