import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../../root-store/app-state';
import { getIdSelector } from '../../../root-store/root-store.selector';
import { addChange } from '../../change-request-cart/change-request-cart-state/change-requests/change-request.actions';
import { selectCurrentContextFunction } from '../../change-request-cart/change-request-cart-state/current-context-function/current-context-function.selectors';
import { sendDeliverableMoveRequestToCart } from '../../change-request-cart/change-request-cart-state/deliverables/crDeliverable.actions';
import { ChangeRequestType } from '../../common/navigation-tabs/change-request-list/cr-type';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { getDeliverablesByFunctionalId } from '../../common/navigation-tabs/deliverables/deliverables-list-state/deliverables-list.actions';
import { DeliverablesService } from '../../common/navigation-tabs/deliverables/deliverables.service';
import { HierarchySearchCallback } from '../hierarchy-search-callback.model';
import { TreeSelectors } from '../tree-state/tree.selectors';

@Component({
  selector: 'app-tree-action-move-deliverable',
  templateUrl: './tree-action-move-deliverable.component.html',
  styleUrls: ['./tree-action-move-deliverable.component.scss'],
})
export class TreeActionMoveDeliverableComponent implements OnInit, OnDestroy {
  submissionInProgress = false;
  form = new UntypedFormGroup({
    selectedHierarchy: new UntypedFormControl(null, Validators.required),
  });
  treeParent$: Observable<any[]>;
  treeParentInput$ = new Subject<string>();
  loading = false;
  destinationTitle: string;
  private subscription = new Subscription();
  private currentContextFunction: number;
  private nodeId: number;

  constructor(
    public dialogRef: MatLegacyDialogRef<TreeActionMoveDeliverableComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      deliverableDetail: DeliverableDetail;
      underChangeControl: boolean;
    },
    private store$: Store<AppState>,
    private deliverablesService: DeliverablesService,
  ) {}

  ngOnInit(): void {
    this.treeParent$ = concat(
      of([]),
      this.treeParentInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap((searchTerm) =>
          this.callBackSearch(searchTerm).pipe(
            catchError(() => of([])),
            tap(() => (this.loading = false)),
          ),
        ),
      ),
    );

    this.subscription.add(
      this.store$.select(selectCurrentContextFunction).subscribe((x) => (this.currentContextFunction = x)),
    );

    this.subscription.add(this.store$.select(getIdSelector).subscribe((x) => (this.nodeId = x)));

    this.subscription.add(
      this.store$
        .select(TreeSelectors.getProjectHierarchyRules)
        .pipe(filter((x) => !!x.length))
        .subscribe((hierarchyRules) => {
          const maxLevel = Math.max(...hierarchyRules.map((x) => x.level));
          this.destinationTitle = hierarchyRules.find((x) => x.level === maxLevel).title;
        }),
    );
  }

  confirm() {
    this.submissionInProgress = true;

    const { selectedHierarchy } = this.form.value;
    if (!selectedHierarchy) {
      return;
    }
    this.data.underChangeControl
      ? this.callBackUnderChangeControl(selectedHierarchy)
      : this.callBack(selectedHierarchy);
  }

  private callBackUnderChangeControl(entity: HierarchySearchCallback): void {
    this.store$.dispatch(
      addChange({
        change: {
          changeRequestType: ChangeRequestType.MovePackage,
          manager: entity.manager,
          contextElementId: this.data.deliverableDetail.deliverableId,
        },
      }),
    );
    this.store$.dispatch(
      sendDeliverableMoveRequestToCart({
        mrDeliverable: {
          functionId: this.currentContextFunction,
          documentNumber: this.data.deliverableDetail.documentNumber,
          newCost: this.data.deliverableDetail.cost,
          newHours: this.data.deliverableDetail.hours,
          title: this.data.deliverableDetail.title,
          newPackageCode: entity.code,
          packageCode: this.data.deliverableDetail.packageCode,
          deliverableId: this.data.deliverableDetail.deliverableId,
          packageFunctionalHierarchyId: this.data.deliverableDetail.packageFunctionalHierarchyId,
          newPackageFunctionalHierarchyId: entity.hierarchyId,
        },
      }),
    );
    this.dialogRef.close();
  }

  private callBack(entity: HierarchySearchCallback): void {
    this.deliverablesService.moveDeliverable(this.data.deliverableDetail.deliverableId, entity.hierarchyId).subscribe(
      () => {
        this.store$.dispatch(
          getDeliverablesByFunctionalId({
            functionalId: this.nodeId,
          }),
        );
        this.dialogRef.close({ deliverableChanged: true });
      },
      () => {
        this.dialogRef.close();
      },
    );
  }

  private callBackSearch(searchTerm: string): Observable<HierarchySearchCallback[]> {
    const selector = this.data.underChangeControl
      ? TreeSelectors.getPackagesForCRWithNameStartingFromForMoveDeliverable({
          packageFhIdToExclude: this.data.deliverableDetail.packageFunctionalHierarchyId,
          term: searchTerm,
        })
      : TreeSelectors.getPackagesWithNameStartingFromForMoveDeliverable({
          packageFhIdToExclude: this.data.deliverableDetail.packageFunctionalHierarchyId,
          term: searchTerm,
        });

    return this.store$.select(selector).pipe(
      map((packageNodes) => {
        return packageNodes.map(
          ({ functionalHierarchyId, title, code, manager, parentId }) =>
            ({
              hierarchyId: functionalHierarchyId,
              title,
              code,
              displayName: this.displayPackageTile(title, code),
              manager,
              parentId,
            }) as HierarchySearchCallback,
        );
      }),
    );
  }

  private displayPackageTile(title: string, code: string): string {
    const codePart = code ? ' <' + code + '>' : '';
    return title + codePart;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
