<mat-sidenav-container class="settings-container" autosize>
  <mat-sidenav #sidenav class="settings-sidenav" mode="side" opened="true">
    <mat-nav-list *ngFor="let navItem of navItems">
      <mat-list-item
        class="parent"
        routerLinkActive="active"
        [routerLink]="navItem.link"
        [routerLinkActiveOptions]="{ exact: navItem.exact }"
        [title]="navItem.title | translate"
      >
        <img src="{{ navItem.icon }}" [ngClass]="navItem.iconClass" alt="{{ 'imgAlt.menuIcon' | translate }}" />
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
