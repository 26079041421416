import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { NodeTypeMap } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import * as rootStoreSelectors from 'src/app/root-store/root-store.selector';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { TreeSelectors } from '../tree-navigation/tree-state/tree.selectors';
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private routeMap: Map<string, Array<string>>;
  private sub = new Subscription();
  constructor(
    private store$: Store<AppState>,
    private router: Router,
  ) {
    this.routeMap = this.preparePossibleNavigation(this.router);
  }

  navigate(functionalHierarchyId: number): void {
    const currentTab = this.getCurrentTab();
    this.sub.add(
      combineLatest([
        this.store$.select(
          TreeSelectors.getTreeNodeByFunctionalHierarchyId({
            functionalHierarchyId,
          }),
        ),
        this.store$.select(getProjectIdSelector),
        this.store$.select(rootStoreSelectors.getIdSelector),
      ]).subscribe(([targetNode, projectId, currentId]) => {
        const destinationNodeType = NodeTypeMap.get(targetNode.typeCode);
        const newTabName = this.routeMap.get(destinationNodeType)?.includes(currentTab) ? currentTab : 'detail';
        this.sub.unsubscribe();
        if (currentId !== functionalHierarchyId && projectId) {
          this.router.navigate([
            'project-management/',
            projectId,
            destinationNodeType,
            functionalHierarchyId,
            newTabName,
          ]);
        }
      }),
    );
  }

  getDestinationUrl(projectId: number, functionalHierarchyId: number, typeCode: string): string {
    const currentTab = this.getCurrentTab();
    const destinationNodeType = NodeTypeMap.get(typeCode);

    const newTabName = this.routeMap.get(destinationNodeType)?.includes(currentTab) ? currentTab : 'detail';

    const relativeUrl = this.router
      .createUrlTree(['project-management', projectId, destinationNodeType, functionalHierarchyId, newTabName])
      .toString();
    const currentUrlFragments = this.router.routerState.snapshot.url.split('/');
    let destination;
    if (currentUrlFragments?.length > 1) {
      destination = window.location.href.replace(new RegExp(`\/${currentUrlFragments[1]}.*`), relativeUrl);
    } else {
      destination = (window.location.href + relativeUrl).replace('//', '/');
    }
    return destination;
  }

  private getCurrentTab(): string {
    const currentUrl = this.router.routerState.snapshot.url.split('/');
    if (currentUrl && currentUrl.length > 0) {
      if (currentUrl.includes('deliverable')) {
        return 'deliverables';
      }
      return currentUrl[currentUrl.length - 1];
    }
    return null;
  }

  private preparePossibleNavigation(routerParam: Router): Map<string, Array<string>> {
    const pathsMap = new Map<string, Array<string>>();
    const projectManagementPaths = routerParam.config.find((conf) =>
      conf.path.startsWith('project-management'),
    ).children;
    projectManagementPaths.forEach((path) => {
      const paths = path.children.map((p) => p.path);
      pathsMap.set(path.data.type, paths);
    });
    return pathsMap;
  }
}
