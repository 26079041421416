import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import * as treeActions from 'src/app/project-management/tree-navigation/tree-state/tree.actions';
import { AppState } from 'src/app/root-store/app-state';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EditorOptions } from 'tinymce';
import { UploadStructureErrorModel } from '../../../common-models/upload-structure-error.model';
import { TreeStructureService } from '../tree-structure.service';

@Component({
  selector: 'app-upload-tree-structure',
  templateUrl: './upload-tree-structure.component.html',
  styleUrls: ['./upload-tree-structure.component.scss'],
})
export class UploadTreeStructureComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('uploader') uploader;
  fileProcessing = false;
  validationErrors = false;
  changeRequestRequired = false;
  dataSource = new TableVirtualScrollDataSource<UploadStructureErrorModel>();
  errorColumns = ['line', 'message'];
  changeRequestForm: UntypedFormGroup;
  editorConfig: EditorOptions;

  private file: any;
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public dialogData: { projectId: number },
    public dialogRef: MatLegacyDialogRef<UploadTreeStructureComponent>,
    public notificationService: NotificationService,
    public store$: Store<AppState>,
    private treeService: TreeStructureService,
    private translationService: TranslateService,
    private appConfigService: AppConfigService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => this.dialogRef.close());
    this.initChangeRequestForm();
  }

  ngAfterViewInit(): void {
    this.uploader.nativeElement.click();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  uploadStructure($event: any): void {
    this.validationErrors = false;
    this.changeRequestRequired = false;
    this.fileProcessing = true;
    this.file = $event.target.files[0];

    this.treeService
      .doesProjectStructureUploadRequireChangeRequest(this.dialogData.projectId, this.file)
      .pipe(
        tap((changeRequestRequired) => (this.changeRequestRequired = changeRequestRequired)),
        switchMap(() => {
          if (this.changeRequestRequired)
            return of({
              changeRequestCreated: false,
            });
          else return this.treeService.uploadProjectStructure(this.dialogData.projectId, this.file);
        }),
      )
      .subscribe(
        async (result) => {
          this.fileProcessing = false;
          if (!this.changeRequestRequired) {
            this.reloadTree();
            this.showSuccessNotification(result.changeRequestCreated);
            this.dialogRef.close();
          }
        },
        (error) => {
          this.fileProcessing = false;
          this.validationErrors = true;
          this.changeRequestRequired = false;
          this.reloadTree();
          this.handleError(error);
        },
      );
    this.uploader.nativeElement.value = '';
  }

  uploadStructureWithCR(): void {
    this.validationErrors = false;
    this.fileProcessing = true;
    this.getChangeRequestControl('title').disable();
    this.getChangeRequestControl('description').disable();

    this.treeService
      .uploadProjectStructure(
        this.dialogData.projectId,
        this.file,
        this.getChangeRequestControl('title').value,
        this.getChangeRequestControl('description').value,
      )
      .subscribe(
        async (result) => {
          this.fileProcessing = false;
          this.reloadTree();
          this.showSuccessNotification(result.changeRequestCreated);
          this.dialogRef.close();
        },
        (error) => {
          this.fileProcessing = false;
          this.changeRequestRequired = false;
          this.validationErrors = true;
          this.reloadTree();
          this.handleError(error);
          this.getChangeRequestControl('title').enable();
          this.getChangeRequestControl('description').enable();
        },
      );
  }

  getChangeRequestControl(name: string): UntypedFormControl {
    return this.changeRequestForm.get(name) as UntypedFormControl;
  }

  showChangeRequestControlInvalidState(name: string): boolean {
    const control = this.getChangeRequestControl(name);
    return control.invalid && (control.dirty || control.touched);
  }

  private handleError(error: any): void {
    if (
      Array.isArray(error.error.errors) &&
      error.error.errors[0].line !== undefined &&
      error.error.errors[0].line !== null &&
      error.error.errors[0].error
    ) {
      this.dataSource.data = error.error.errors;
    } else {
      this.dataSource.data = [];
      this.notificationService.error('projectTemplateUpload.errorOnFileUpload');
    }
  }

  private reloadTree(): void {
    this.store$.dispatch(treeActions.reloadProjectData({ projectId: this.dialogData.projectId }));
  }

  private initChangeRequestForm(): void {
    this.changeRequestForm = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required),
    });
    this.editorConfig = {
      ...this.appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...this.appConfigService.settings.richTextConfig.crJustificationOptions,
      placeholder: this.translationService.instant('bulkOperation.descriptionOfChangeRequestPlaceholder'),
    };

    this.changeRequestForm.statusChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.changeDetector.markForCheck());
  }

  private showSuccessNotification(changeRequestCreated: boolean): void {
    this.notificationService.success(
      changeRequestCreated
        ? 'projectTemplateUpload.bulkUploadSuccessAndChangeRequestSubmittedForApproval'
        : 'projectTemplateUpload.bulkUploadSuccess',
    );
  }
}
