import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { HierarchyRule } from 'src/app/common-models/HierarchyRule.model';
import { Project, ProjectDetails } from 'src/app/common-models/project.model';
import { PathRequest } from 'src/app/project-management/common/path.model';
import { PropertyPatch } from 'src/app/project-management/common/propert-patch.model';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private canManageProjects = new Map<number, Observable<boolean>>();
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  public canCurrentUserManageProject$ = new BehaviorSubject<boolean>(false);

  addProject(project: Project, portfolioId: number): Observable<number> {
    const { baseUrl, addProject } = this.appConfigService.settings.api.endpoints;

    return this.httpClient.post<number>(baseUrl + addProject, {
      ...project,
      portfolioId,
    });
  }

  getProjectStatuses(): any {
    const { baseUrl, getProjectStatuses } = this.appConfigService.settings.api.endpoints;

    return this.httpClient.get<any>(`${baseUrl}${getProjectStatuses}`);
  }

  getProjectPackageHealthCalculationBasis(): any {
    const { baseUrl, getProjectPackageHealthCalculationBasis } = this.appConfigService.settings.api.endpoints;

    return this.httpClient.get<any>(`${baseUrl}${getProjectPackageHealthCalculationBasis}`);
  }

  canCurrentUserManageProject(projectId: number): Observable<boolean> {
    if (!this.canManageProjects.get(projectId)) {
      const params = new HttpParams().set('projectId', projectId.toString());
      const { baseUrl, canCurrentUserManageProject } = this.appConfigService.settings.api.endpoints;

      const canManageProject = this.httpClient
        .get<any>(`${baseUrl}${canCurrentUserManageProject}`, { params })
        .pipe(shareReplay());

      this.canManageProjects.set(projectId, canManageProject);
    }

    return this.canManageProjects.get(projectId);
  }

  getProjectHierarchyRuleData(projectId: number): Observable<HierarchyRule[]> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.hierarchyRule;
    const params = new HttpParams().set('projectId', projectId.toString());

    return this.httpClient.get<HierarchyRule[]>(baseUrl + endpoint, {
      params,
    });
  }

  updateProjectDetails(update: Update<any>): Observable<any> {
    const changes: PropertyPatch[] = [];
    const changedProperties = Object.keys(update.changes);
    changedProperties.forEach((p) => {
      const change = new PropertyPatch();
      change.PropertyName = p;
      change.PropertyValue = update.changes[p];
      changes.push(change);
    });

    const requestBody: PathRequest = {
      Id: update.id,
      PatchDTOs: changes,
    };

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const updatePropUrl = this.appConfigService.settings.api.endpoints.updateProjectDetails;
    return this.httpClient.patch(baseUrl + updatePropUrl, requestBody).pipe(
      catchError((error) => {
        showHttpErrorResponse(this.notificationService, error);
        return EMPTY;
      }),
    );
  }

  getProjectDetails(projectId: number): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectDetails;
    const params = new HttpParams().set('projectId', projectId.toString());

    return this.httpClient
      .get<ProjectDetails>(baseUrl + endpoint, {
        params,
      })
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return EMPTY;
        }),
      );
  }

  reloadProject(projectId: number): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.clearProjectTreeCache;
    const params = new HttpParams().set('projectId', projectId.toString());

    return this.httpClient
      .get<ProjectDetails>(baseUrl + endpoint, {
        params,
      })
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return EMPTY;
        }),
      );
  }

  getProjectLogo(projectId: number): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.getProjectLogo;
    const params = new HttpParams().set('projectId', projectId.toString());

    return this.httpClient
      .get(baseUrl + endpoint, {
        responseType: 'blob',
        params,
      })
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return EMPTY;
        }),
      );
  }

  changeProjectLogo(projectId: number, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('parentId', projectId.toString());
    formData.append('image', file);

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.changeProjectLogo;

    return this.httpClient.put<any>(baseUrl + endpoint, formData).pipe(
      catchError((error) => {
        showHttpErrorResponse(this.notificationService, error);
        return throwError(error);
      }),
    );
  }

  removeProjectLogo(projectId: number): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.removeProjectLogo;
    const params = new HttpParams().set('projectId', projectId.toString());

    return this.httpClient
      .delete(baseUrl + endpoint, {
        params,
      })
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return EMPTY;
        }),
      );
  }
}
