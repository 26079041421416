<form [formGroup]="form" class="package-selection" autocomplete="off">
  <h2>
    {{
      (this.data.underChangeControl ? 'moveDeliverableModal.moveRequestTitle' : 'moveDeliverableModal.moveTitle')
        | translate
    }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <p>
    {{ 'moveDeliverableModal.selectDestination' | translate }}
    {{ data.deliverableDetail.title }}:
  </p>
  <div class="orms-label small">{{ 'moveDeliverableModal.destination' | translate }} {{ this.destinationTitle }}</div>
  <div class="search-box">
    <ng-select
      appendTo="body"
      class="package-search"
      [items]="treeParent$ | async"
      bindLabel="displayName"
      [placeholder]="'moveDeliverableModal.typeCodeOrName' | translate"
      [minTermLength]="2"
      formControlName="selectedHierarchy"
      [hideSelected]="true"
      [clearable]="false"
      [multiple]="false"
      [loading]="loading"
      [typeahead]="treeParentInput$"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label package-pill">{{ item.displayName }}</span>
        <span class="ng-value-icon right package-clear" (click)="clear(item)" aria-hidden="true">x</span>
      </ng-template>
    </ng-select>
  </div>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        class="orms-button orms-button-primary submit-button"
        type="button"
        (click)="confirm()"
        [disabled]="!form.get('selectedHierarchy').value || submissionInProgress"
      >
        {{ 'general.confirm' | translate }}
      </button>
      <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </button>
      <app-spinner [diameter]="26" *ngIf="submissionInProgress"></app-spinner>
    </div>
  </div>
</form>
