<form
  [formGroup]="form"
  autocomplete="off"
  class="orms-share-form"
  *ngIf="!isManageSettings; else manageSettings"
  (ngSubmit)="onSubmit()"
>
  <h2>
    {{ 'share.title' | translate }}
    <button (click)="close()" type="button" class="orms-close-button"></button>
  </h2>
  <ng-container *ngIf="!showEdit; else edit">
    <p>{{ 'share.description' | translate }}</p>
    <div class="share-form">
      <mat-form-field appearance="outline" class="orms-outline-input popup-title">
        <mat-label>{{ 'share.emails' | translate }} </mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="{{ 'share.generateLinkPlaceholder' | translate }}"
          formControlName="emails"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="orms-outline-input expiration">
        <mat-label>{{ 'share.expirationDate' | translate }} </mat-label>
        <input
          autocomplete="off"
          matInput
          [matDatepicker]="expireDate"
          formControlName="expires"
          (click)="expireDate.open()"
          class="orms-datepicker-input"
          [class.is-invalid]="showInvalidState('expires')"
        />
        <mat-datepicker-toggle matSuffix [for]="expireDate" class="mat-suffix-focus-overlay"></mat-datepicker-toggle>
        <mat-datepicker panelClass="orms-datepicker" [calendarHeaderComponent]="datepickerActionsComponent" #expireDate>
        </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="share-footer">
      <button [disabled]="submitting" type="submit" class="orms-button orms-button-primary submit-button">
        {{ 'share.generateLink' | translate }}
      </button>
      <button type="button" class="orms-button orms-button-secondary" (click)="close()">
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </ng-container>
</form>

<ng-template #edit
  ><form [formGroup]="successForm" autocomplete="off" (ngSubmit)="grantAccess()">
    <ng-container *ngIf="showSuccess; else showActiveLink">
      <div class="successful-submission">
        <mat-icon class="success-icon">check_circle</mat-icon>
        <p class="success-message">
          {{ 'share.generateSuccess' | translate }}
        </p>
      </div>
    </ng-container>
    <div class="success-subtitle">
      <b>{{ 'share.reviewAndUpdate' | translate }} {{ data.entityUnderChangeTitle }}</b>
    </div>
    <div *ngIf="!showSuccess" class="grant-access">
      <mat-form-field appearance="outline" class="orms-outline-input popup-title">
        <mat-label>{{ 'share.emails' | translate }} </mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="{{ 'share.grantAccessPlaceholder' | translate }}"
          formControlName="addEmails"
        />
      </mat-form-field>
      <button type="submit" class="orms-button orms-button-primary submit-button">
        {{ 'share.grantAccess' | translate }}
      </button>
    </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="peopleWithAccess">
        <th id="peopleWithAccess" mat-header-cell class="table-date" *matHeaderCellDef>
          {{ 'share.peopleWithAccess' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="user-email">
            <ng-container *ngFor="let person of element.peopleWithAccess">
              <div>{{ person.email }}</div>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="daysActive">
        <th id="daysActive" mat-header-cell class="table-date" *matHeaderCellDef>
          {{ 'share.daysActive' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!element.isExpired; else expired">
            {{ element.daysActive }} {{ 'share.days' | translate }}
          </ng-container>
          <ng-template #expired>
            {{ 'share.expired' | translate }}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="expiryDate">
        <th id="expiryDate" mat-header-cell class="table-date" *matHeaderCellDef>
          {{ 'share.expiryDate' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.expiryDate | date: shortDateFormat : timezoneUTC }}
        </td>
      </ng-container>
      <ng-container matColumnDef="publicUrl">
        <th id="publicUrl" mat-header-cell *matHeaderCellDef>
          {{ 'share.publicUrl' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="outline" class="orms-outline-input">
            <mat-label>{{ 'share.link' | translate }}</mat-label>
            <input autocomplete="off" matInput formControlName="link" readonly="true" class="orms-datepicker-input" />
            <mat-icon
              class="clipboard-icon"
              [cdkCopyToClipboard]="successForm.get('link').value"
              [matTooltip]="'general.copyToClipboard' | translate"
              matTooltipPosition="above"
              matTooltipClass="orms-tooltip-common"
              matSuffix
            >
              content_copy
            </mat-icon>
          </mat-form-field>
          <app-action-menu [item]="element" [actions]="actions">
            <mat-icon>more_vert</mat-icon>
          </app-action-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </form>
  <div class="share-footer">
    <button type="button" class="orms-button orms-button-primary" (click)="close()">
      {{ 'general.done' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #showActiveLink>
  {{ 'share.activeLink' | translate }}
</ng-template>

<ng-template #manageSettings>
  <form
    *ngIf="!isNewLink; else createLink"
    [formGroup]="manageSettingForm"
    autocomplete="off"
    class="orms-share-form orms-manage-settings-form"
  >
    <h2>
      <button
        class="orms-link-button back-button"
        (click)="backToShare()"
        title="{{ 'share.backToShare' | translate }}"
      ></button>
      <span class="manage-title">{{ 'share.manageSettings' | translate }}</span>
      <button (click)="close()" type="button" class="orms-close-button"></button>
    </h2>
    <div class="success-subtitle">
      <b>{{ 'share.reviewAndUpdate' | translate }} {{ data.entityUnderChangeTitle }}</b>
    </div>
    <table mat-table [dataSource]="manageSettingsDataSource">
      <ng-container matColumnDef="createdBy">
        <th id="createdBy" mat-header-cell class="table-date" *matHeaderCellDef>
          {{ 'share.createdBy' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.createdBy }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="daysActive">
        <th id="daysActive" mat-header-cell class="table-date" *matHeaderCellDef>
          {{ 'share.daysActive' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!element.isExpired; else expired">
            {{ element.daysActive }}
          </ng-container>
          <ng-template #expired>
            {{ 'share.expired' | translate }}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="expiryDate">
        <th id="expiryDate" mat-header-cell class="table-date" *matHeaderCellDef>
          {{ 'share.expiryDate' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="outline" class="orms-outline-input">
            <mat-label>{{ 'share.expirationDate' | translate }} </mat-label>
            <input
              autocomplete="off"
              matInput
              [matDatepicker]="expireDate"
              formControlName="expires"
              (dateChange)="changeExpiryDate($event)"
              (click)="expireDate.open()"
              class="orms-datepicker-input"
              [class.is-invalid]="showInvalidState('expires')"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="expireDate"
              class="mat-suffix-focus-overlay"
            ></mat-datepicker-toggle>
            <mat-datepicker
              panelClass="orms-datepicker"
              [calendarHeaderComponent]="datepickerActionsComponent"
              #expireDate
            >
            </mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="publicUrl">
        <th id="publicUrl" mat-header-cell *matHeaderCellDef>
          {{ 'share.publicUrl' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="outline" class="orms-outline-input">
            <mat-label>{{ 'share.link' | translate }}</mat-label>
            <input autocomplete="off" matInput formControlName="link" readonly="true" class="orms-datepicker-input" />
            <mat-icon
              class="clipboard-icon"
              [cdkCopyToClipboard]="successForm.get('link').value"
              [matTooltip]="'general.copyToClipboard' | translate"
              matTooltipPosition="above"
              matTooltipClass="orms-tooltip-common"
              matSuffix
            >
              content_copy
            </mat-icon>
          </mat-form-field>
          <app-action-menu [item]="element" [actions]="shareWithActions">
            <mat-icon>more_vert</mat-icon>
          </app-action-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="managedSettingsDisplayedColumns"></tr>
      <tr class="settings-table" mat-row *matRowDef="let row; columns: managedSettingsDisplayedColumns"></tr>
    </table>
    <div class="shared-with">
      <b>{{ 'share.sharedWith' | translate }}</b>
    </div>
    <div class="shared-with-container">
      <table mat-table [dataSource]="sharedWithDataSource">
        <ng-container matColumnDef="name">
          <th id="name" mat-header-cell class="table-date" *matHeaderCellDef>
            {{ 'share.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div>{{ element.email }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastAccessDate">
          <th id="lastAccessDate" mat-header-cell class="table-date" *matHeaderCellDef>
            {{ 'share.lastAccessDate' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lastAccessed | date: shortDateFormat : timezoneUTC }}
          </td>
        </ng-container>
        <ng-container matColumnDef="numberOfVisits">
          <th id="numberOfVisits" mat-header-cell class="table-date" *matHeaderCellDef>
            {{ 'share.numberOfVisits' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="actions">
            {{ element.accessedCount }}
            <app-action-menu [item]="element" [actions]="manageSettingsActions">
              <mat-icon>more_vert</mat-icon>
            </app-action-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="sharedWithDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: sharedWithDisplayedColumns"></tr>
      </table>
    </div>
  </form>
</ng-template>

<ng-template #createLink>
  <div class="orms-share-form">
    <h2>
      {{ 'share.title' | translate }}
      <button (click)="close()" type="button" class="orms-close-button"></button>
    </h2>
    <p>{{ 'share.description' | translate }}</p>
    <p class="no-active-link">
      <b>{{ 'general.noActiveLinks' | translate }}</b>
    </p>
    <hr class="link-hr" />
    <div class="share-footer no-link">
      <button
        [disabled]="submitting"
        type="button"
        (click)="generateNewLink()"
        class="orms-button orms-button-primary submit-button button-with-icon"
      >
        <mat-icon>add</mat-icon>
        {{ 'share.createNewLink' | translate }}
      </button>
      <button type="button" class="orms-button orms-button-secondary" (click)="close()">
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>
