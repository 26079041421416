import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { HomePageGuard } from 'src/app/project-management/home-page/home-page.guard';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { getProjectDetails } from 'src/app/shared-state/project/project.actions';
import { checkAndSetActiveAccount } from 'src/app/shared/msal-config/msal-helpers';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';
import FeatureFlags from '../../../assets/feature-flags.json';
import { MenuAction } from './menu-action';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [HomePageGuard],
})
export class HeaderComponent implements OnInit, OnDestroy {
  viewOnly = true;
  loginDisplay = false;
  username = '';
  organizationName = '';
  actions: MenuAction[] = [];
  languageSelection = FeatureFlags.languageSelection;

  private projectIdSubscription = new Subscription();
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private appConfigService: AppConfigService,
    private authService: MsalService,
    private accountInfoService: AccountInfoService,
    private msalBroadcastService: MsalBroadcastService,
    private translateService: TranslateService,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.addMenuActions();

    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this.onDestroy$),
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        this.setLoginDisplay();
        checkAndSetActiveAccount(this.authService);
        this.initialize();
      });

    this.setLoginDisplay();

    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$), startWith(this.translateService.currentLang))
      .subscribe(() => {
        this.initialize();
      });
  }

  private initialize() {
    this.addMenuActions();
    this.viewOnly = false;
    this.initializeProjectDetails();
  }

  private addMenuActions(): void {
    const actions = [];
    this.addSwitchWorkspaceMenuItem(actions);
    this.addAdminConsoleMenuItem(actions);
    this.addUserProfileMenuItem(actions);
    this.addLogoutMenuItem(actions);
    this.actions = actions;
  }

  private initializeProjectDetails(): void {
    this.projectIdSubscription.unsubscribe();
    this.projectIdSubscription = this.store$
      .select(getProjectIdSelector)
      .pipe(filter((x) => !!x))
      .subscribe((projectId) => {
        this.store$.dispatch(getProjectDetails({ projectId }));
      });
  }

  private setLoginDisplay(): void {
    const userName = this.accountInfoService.getUserName();
    if (userName) {
      this.loginDisplay = true;
      this.username = userName;
    }
    this.organizationName = this.accountInfoService.getOrganizationName();
  }

  private logout(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/login',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  private addSwitchWorkspaceMenuItem(menuActions: MenuAction[]): void {
    menuActions.push({
      icon: '/assets/icons/icon-switch-account.svg',
      label: this.translateService.instant('general.switchWorkspace'),
      callBack: () => this.switchWorkspace(),
      addUserInfo: false,
      menuItemBorder: false,
    });
  }

  private switchWorkspace(): void {
    const account = this.authService.instance.getAllAccounts()?.[0];

    const accessTokenRequest = {
      account: account,
      forceRefresh: true,
      scopes: this.appConfigService.settings.msal.guard.requestScopes,
      redirectStartPage: '/',
    };

    this.authService.acquireTokenRedirect(accessTokenRequest).subscribe();
  }

  private addUserProfileMenuItem(menuActions: MenuAction[]): void {
    menuActions.push({
      icon: '/assets/icons/icon-person.svg',
      label: this.translateService.instant('general.userProfile'),
      callBack: () => this.openUserProfile(),
      addUserInfo: false,
      menuItemBorder: true,
    });
  }

  private addLogoutMenuItem(menuActions: MenuAction[]): void {
    menuActions.push({
      icon: '/assets/icons/icon-logout.svg',
      label: this.translateService.instant('general.logout'),
      callBack: () => this.logout(),
      addUserInfo: true,
      menuItemBorder: false,
    });
  }

  private addAdminConsoleMenuItem(menuActions: MenuAction[]): void {
    if (
      !this.accountInfoService
        .getUserRoles()
        .some((role) => role == UmsRoles.WorkspaceOwner || role == UmsRoles.WorkspaceUserAdministrator)
    )
      return;

    menuActions.push({
      icon: '/assets/icons/icon-manage-accounts.svg',
      label: this.translateService.instant('general.adminConsole'),
      callBack: () => this.openAdminConsole(),
      addUserInfo: false,
      menuItemBorder: false,
    });
  }

  private openAdminConsole(): void {
    const settingsHpac = this.appConfigService.settings.hpac;
    window.open(settingsHpac.baseUrl + settingsHpac.homeReady, '_blank');
  }

  private openUserProfile(): void {
    const settingsHpac = this.appConfigService.settings.hpac;
    window.open(settingsHpac.baseUrl + settingsHpac.userProfile, '_blank');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
