import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PackageRelationshipItem, RelationshipType } from './PackageRelationship.model';

@Injectable({
  providedIn: 'root',
})
export class PackageRelationshipsService {
  clearRelationshipsFromSearch = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  getRelatedPackages(packageFunctionalHierarchyId: number, projectId: number): Observable<PackageRelationshipItem[]> {
    const params = new HttpParams()
      .set('packageFunctionalHierarchyId', packageFunctionalHierarchyId.toString())
      .set('projectId', projectId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const packageRelationshipsUrl = this.appConfigService.settings.api.endpoints.getPackageRelationships;
    return this.httpClient
      .get<PackageRelationshipItem[]>(baseUrl + packageRelationshipsUrl, {
        params,
      })
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error, 'relationships.gettingRelationshipsFailed');
          return of([]);
        }),
      );
  }

  removeRelationship(
    sourcePackageFunctionalHierarchyId: number,
    targetPackageFunctionalHierarchyId: number,
    projectId: number,
  ): Observable<void> {
    const params = new HttpParams()
      .set('sourcePackageFunctionalHierarchyId', sourcePackageFunctionalHierarchyId.toString())
      .set('targetPackageFunctionalHierarchyId', targetPackageFunctionalHierarchyId.toString())
      .set('projectId', projectId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const removePackageRelationshipsUrl = this.appConfigService.settings.api.endpoints.removePackageRelationship;

    return this.httpClient.delete<void>(baseUrl + removePackageRelationshipsUrl, { params }).pipe(
      catchError((error: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, error, 'relationships.gettingRelationshipsFailed');
        return of(null);
      }),
    );
  }

  addRelationship(
    sourcePackageFunctionalHierarchyId: number,
    targetPackageFunctionalHierarchyIds: number[],
    type: RelationshipType,
    projectId: number,
  ): Observable<void> {
    const body = {
      sourcePackageFunctionalHierarchyId,
      targetPackageFunctionalHierarchyIds,
      relationshipType: type,
      projectId,
    };

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const addPackageRelationshipUrl = this.appConfigService.settings.api.endpoints.addPackageRelationship;

    return this.httpClient.post<void>(baseUrl + addPackageRelationshipUrl, body).pipe(
      catchError((error: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, error, 'relationships.addingRelationshipsFailed');
        return of(null);
      }),
    );
  }
}
