<div mat-dialog-content>
  <h2>
    {{ data.label | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>

  <mat-form-field appearance="fill">
    <input
      matInput
      #title
      cdkFocusInitial
      autocomplete="off"
      [(ngModel)]="node.title"
      placeholder="{{
        this.data.isPackage
          ? ('treeItemMenu.TypePackageName' | translate)
          : ('treeItemMenu.TypeFunctionName' | translate)
      }}"
    />
  </mat-form-field>

  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        class="orms-button orms-button-primary submit-button"
        title="{{ 'treeItemMenu.Enter' | translate }}"
        type="button"
        (click)="addItem()"
        [disabled]="submissionInProgress || !node.title"
      >
        {{ 'treeItemMenu.Commit' | translate }}
      </button>
      <button
        class="orms-button orms-button-primary submit-button"
        title="{{ 'treeItemMenu.ShiftEnter' | translate }}"
        type="button"
        (click)="addAndAnotherItem()"
        [disabled]="submissionInProgress || !node.title"
      >
        {{ 'treeItemMenu.CommitAndAnother' | translate }}
      </button>
      <button
        class="orms-button orms-button-secondary"
        title="{{ 'treeItemMenu.Escape' | translate }}"
        type="button"
        mat-dialog-close
      >
        {{ 'treeItemMenu.Cancel' | translate }}
      </button>
      <app-spinner [diameter]="26" *ngIf="submissionInProgress"></app-spinner>
    </div>
  </div>
</div>
