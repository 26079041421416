import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays } from 'date-fns';
import format from 'date-fns/format';
import { shortDateFormat } from 'src/app/shared/date-options/short-date-format';

@Pipe({
  name: 'lastVisited',
  pure: false,
})
export class LastVisitedPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: any, args?: unknown[]): string {
    if (!date) {
      return this.translateService.instant('general.lastVisitedUnknown');
    }

    const noOfDays = differenceInDays(new Date(), new Date(date));
    switch (noOfDays) {
      case 0: {
        return this.translateService.instant('general.lastVisitedToday');
      }
      case 1: {
        return this.translateService.instant('general.lastVisitedYesterday');
      }
      default: {
        return this.translateService.instant('general.lastVisited') + ' ' + format(new Date(date), shortDateFormat);
      }
    }
  }
}
