<div class="cr-details" *ngIf="changeRequestDetails$ | async as changeRequestDetails; else loading">
  <h2 [ngSwitch]="changeRequestType">
    <ng-container *ngSwitchCase="crTypeEnum.Cancel">
      {{ 'changeRequest.cancellationChangeRequestDetails' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="crTypeEnum.Change">
      {{ 'changeRequest.changeRequestDetails' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="crTypeEnum.MovePackage">
      {{ 'changeRequest.moveChangeRequestDetails' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="crTypeEnum.MoveDeliverable">
      {{ 'changeRequest.moveChangeRequestDetails' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="crTypeEnum.MoveSubFunction">
      {{ 'changeRequest.moveDetails' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="crTypeEnum.PublishUnderChangeControl">
      {{ 'changeRequest.publishedUnderChangeControlChangeRequestDetails' | translate }}
    </ng-container>
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <strong>{{ changeRequestDetails?.title }}</strong>
  <mat-tab-group class="orms-tabs" animationDuration="0ms">
    <mat-tab label="{{ 'changeRequest.justification' | translate }}" class="orms-tab">
      <div class="description" [innerHtml]="changeRequestDetails?.description"></div>
    </mat-tab>
    <ng-container *ngIf="changeRequestType === crTypeEnum.Change">
      <mat-tab label="{{ 'changeRequest.impact' | translate }}">
        <app-cr-impact [changeRequest]="changeRequestDetails" *ngIf="changeRequestDetails"></app-cr-impact>
      </mat-tab>
      <mat-tab label="{{ 'changeRequest.schedule' | translate }}">
        <app-cr-schedule [crPackages]="changeRequestDetails?.packages" *ngIf="changeRequestDetails"></app-cr-schedule>
      </mat-tab>
      <mat-tab label="{{ 'changeRequest.deliverables' | translate }}">
        <app-request-deliverables
          [displayOnly]="true"
          [requestType]="requestType.Change"
          [crDeliverables]="changeRequestDetails?.deliverables"
          *ngIf="changeRequestDetails"
        ></app-request-deliverables>
      </mat-tab>
    </ng-container>
    <ng-container
      *ngIf="changeRequestType === crTypeEnum.MovePackage || changeRequestType === crTypeEnum.MoveSubFunction"
    >
      <mat-tab label="{{ 'changeRequest.details' | translate }}">
        <app-cr-move-details [changeRequest]="changeRequestDetails" *ngIf="changeRequestDetails"></app-cr-move-details>
      </mat-tab>
    </ng-container>
    <ng-container *ngIf="changeRequestDetails.changeRequestType === crTypeEnum.MoveDeliverable">
      <mat-tab label="{{ 'changeRequest.deliverables' | translate }}">
        <app-request-deliverables
          [displayOnly]="true"
          [requestType]="requestType.Move"
          [crDeliverables]="changeRequestDetails?.deliverables"
        ></app-request-deliverables>
      </mat-tab>
    </ng-container>
    <ng-container *ngIf="changeRequestDetails?.packages.length">
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'general.relationships' | translate }}
          <img
            *ngIf="hasPendingCrWithScheduleChange"
            class="warning-icon"
            src="/assets/icons/icon-warning.svg"
            [matTooltip]="'relationships.pendingScheduleChangeRequest' | translate"
            matTooltipPosition="above"
            matTooltipClass="orms-tooltip orms-cr-tooltip orms-tooltip-left"
            alt="{{ 'imgAlt.warning' | translate }}"
          />
        </ng-template>
        <p class="warning-info" *ngIf="scheduleImpactWarningMessage && changeRequestDetails?.relationships?.length">
          <mat-icon>warning_amber</mat-icon>
          <span>
            {{ scheduleImpactWarningMessage }}
          </span>
        </p>
        <app-package-relationships-table
          [relationships]="changeRequestDetails.relationships"
          [projectId]="projectId"
          [crView]="true"
        ></app-package-relationships-table>
      </mat-tab>
    </ng-container>
    <mat-tab label="{{ 'changeRequest.history' | translate }}">
      <app-cr-history [statusChanges]="changeRequestDetails?.statusChanges"></app-cr-history>
    </mat-tab>
  </mat-tab-group>
  <div class="action-buttons">
    <div class="manage-buttons" *ngIf="changeRequestDetails?.status !== crStatusCode.Logged">
      <button
        [disabled]="!canApprove"
        class="orms-button orms-button-primary submit-button"
        type="button"
        [mat-dialog-close]="'approve'"
      >
        {{ 'general.approve' | translate }}
      </button>
      <button
        [disabled]="!canReject"
        class="orms-button orms-button-secondary"
        type="button"
        [mat-dialog-close]="'reject'"
      >
        {{ 'general.reject' | translate }}
      </button>
    </div>
    <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
      {{ 'general.close' | translate }}
    </button>
  </div>
</div>
<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
