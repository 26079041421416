import { Pipe, PipeTransform } from '@angular/core';
import { isDateInRange } from 'src/app/shared/is-date-in-range/is-date-in-range';

@Pipe({
  name: 'isDateInRange',
})
export class IsDateInRangePipe implements PipeTransform {
  transform(date: Date | moment.Moment, rangeFrom: Date | moment.Moment, rangeTo: Date | moment.Moment): boolean {
    return isDateInRange(date, rangeFrom, rangeTo);
  }
}
