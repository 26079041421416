import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Detail } from './detail.model';

@Injectable({
  providedIn: 'root',
})
export class DetailService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  getDetailData(id: number): Observable<Detail> {
    const params = new HttpParams().set('functionalHierarchyId', id.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getDetailUrl = this.appConfigService.settings.api.endpoints.detailData;

    return this.httpClient.get<Detail>(baseUrl + getDetailUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }

  getPackageDataForPrint(packageId: number) {
    const params = new HttpParams().set('packageId', packageId.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getPackageDataForPrintUrl = this.appConfigService.settings.api.endpoints.getPackageDataForPrint;

    return this.httpClient.get<any>(baseUrl + getPackageDataForPrintUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }

  getVisualizationForPackage(id: number): Observable<any> {
    const params = new HttpParams().set('packageFunctionalHierarchyId', id.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getDetailUrl = this.appConfigService.settings.api.endpoints.getVisualizationForPackage;
    return this.httpClient.get<any>(baseUrl + getDetailUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }
}
