import { AbstractControl, AbstractControlOptions, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export const watchControlsWithoutValidation = (
  formGroup: UntypedFormGroup,
): Observable<{ name: string; value: any }> => {
  const controlWatchers = Object.keys(formGroup.controls).map((controlName) => {
    const control = formGroup.get(controlName);

    return watchSingleControlWithoutValidation(control).pipe(map((value) => ({ name: controlName, value })));
  });
  return merge(...controlWatchers);
};

export const watchSingleControlWithoutValidation = (control: UntypedFormControl | AbstractControl) => {
  return control.valueChanges.pipe(distinctUntilChanged());
};

export const watchControls = (formGroup: UntypedFormGroup): Observable<{ name: string; value: any }> => {
  const controlWatchers = Object.keys(formGroup.controls).map((controlName) => {
    const control = formGroup.get(controlName);
    return watchSingleControl(control).pipe(map((value) => ({ name: controlName, value })));
  });
  return merge(...controlWatchers);
};

export const watchSingleControl = (control: UntypedFormControl | AbstractControl) => {
  return control.statusChanges.pipe(
    filter((status) => status === 'VALID'),
    map(() => control.value),
    distinctUntilChanged(),
  );
};

export const disableFormFieldsBaseOnPermissions = (
  form: UntypedFormGroup,
  isEditable: (name: string) => boolean,
): void => {
  Object.keys(form.controls).forEach((controlName) => {
    const control = form.get(controlName);
    isEditable(controlName) ? control.enable({ emitEvent: false }) : control.disable({ emitEvent: false });
  });
};

export const shadowFormConfig: AbstractControlOptions = {
  updateOn: 'blur',
};

export const shadowFormUpdateConfig = {
  emitEvent: false,
};
