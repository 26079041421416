import { Component, EventEmitter, Output } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-no-changes-dialog',
  templateUrl: './no-changes-dialog.component.html',
  styleUrls: ['./no-changes-dialog.component.scss'],
})
export class NoChangesDialogComponent {
  @Output() previousStepClick = new EventEmitter<void>();

  constructor(public dialogRef: MatLegacyDialogRef<NoChangesDialogComponent>) {}

  ok(): void {
    this.dialogRef.close();
  }
}
