import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  ColumnMenuService,
  EditSettingsModel,
  FilterService,
  FilterSettingsModel,
  ReorderService,
  ResizeService,
  SelectionSettingsModel,
  TreeGridComponent,
} from '@syncfusion/ej2-angular-treegrid';
import { DateRangePicker } from '@syncfusion/ej2-calendars';
import { RowDataBoundEventArgs } from '@syncfusion/ej2-grids';
import { saveAs } from 'file-saver';
import { cloneDeep, isArray, isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, Subscription, defer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { HierarchyRule } from 'src/app/common-models/HierarchyRule.model';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { ProjectDetails } from 'src/app/common-models/project.model';
import { AppState } from 'src/app/root-store/app-state';
import { clearProjectRelatedData, includeCancelledItems } from 'src/app/root-store/root-store.actions';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { getCurrentUser, getIncludeCancelledItems } from 'src/app/root-store/root-store.selector';
import * as projectSelectors from 'src/app/shared-state/project/project.selectors';
import { CurrentUser } from 'src/app/shared/current-user/currentUser';
import { dashDayDateFormat } from 'src/app/shared/date-options/short-date-format';
import FeatureFlags from '../../../assets/feature-flags.json';
import { getCrAffectedIds } from '../change-request-cart/change-request-cart-state/change-requests/change-request.selectors';
import { Action, TreeGridActions, TreeGridEvent, setGridMinWidth } from '../common/tree-grid-helper';
import { DeliverableSelectors } from '../deliverable/deliverable-state/deliverable.selectors';
import { SignalRService } from '../signalr/signalr.service';
import { NavigationService } from './navigation.service';
import * as treeActions from './tree-state/tree.actions';
import { cleanDataForFiltering } from './tree-state/tree.reducer';
import { TreeSelectors } from './tree-state/tree.selectors';
import { NodeMoveData, TreeNode } from './tree-structure.model';
import { TreeStructureService } from './tree-structure.service';
import { UploadTreeStructureComponent } from './upload-tree-structure/upload-tree-structure.component';

@Component({
  selector: 'app-tree-navigation',
  templateUrl: './tree-navigation.component.html',
  styleUrls: ['./tree-navigation.component.scss'],
  providers: [ReorderService, ColumnMenuService, FilterService, ResizeService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeNavigationComponent implements OnInit, OnDestroy {
  @ViewChild('treegrid')
  treegrid: TreeGridComponent;

  @ViewChild('filterItemTemplate')
  filterTemplate: TemplateRef<{}>;

  treeData: TreeNode[];
  loading = true;
  action: Action;
  excelLoading = false;
  selectedRowFunctionalHierarchyId: number;
  editSettings: EditSettingsModel;
  isCurrentUserLevelOneFunctionManagerSubscription$: Observable<ProjectDetails>;
  projectHierarchyRulesSubscription$: Observable<HierarchyRule[]>;
  isCurrentUserAdminSubscription$: Observable<CurrentUser>;
  projectId: number;
  currentlySelectedPrimaryKey: number;
  customAttributes: object;
  initialDataBoundDataLoaded = false;
  manualSelection = false;
  isCurrentUserLevelOneManager = false;
  isCurrentUserProjectManager = false;
  selectionOptions: SelectionSettingsModel = { enableToggle: false };
  crAffectedIds: number[] = [];
  dateRangePicker: DateRangePicker;
  titleWidth = '224';
  codeWidth = '90';
  statusWidth = '107';
  responsibleWidth = '90';
  hoursWidth = '60';
  plannedWidth = '60';
  actualWidth = '60';
  spiWidth = '60';
  dateWidth = '80';
  dateFormat = dashDayDateFormat;
  hasProjectManageRights$ = new BehaviorSubject<boolean>(false);
  projectRules: HierarchyRule[];
  isPackageIncorrect = false;
  areCancelledIncluded: boolean;

  filterSettings: FilterSettingsModel;
  statusFilter: object;
  dateFilter: object;
  responsibleFilter: object;
  filterDateTemplate: any;
  filterEndDate: Date;
  customFilter = false;
  routeType: EntityTypes;
  firstLoad = true;
  rowSelected$ = new Subject<any>();

  private bulkCollapseOrExpandInProgress = false;
  private scrollLeft: number;
  private projectTitle: string;
  private subscription = new Subscription();
  useImportFeatureFlag: boolean = FeatureFlags.useImport;

  constructor(
    private treeService: TreeStructureService,
    private store$: Store<AppState>,
    private navigationService: NavigationService,
    private dialog: MatLegacyDialog,
    private signalrService: SignalRService,
    private translationService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      newRowPosition: 'Child',
      allowNextRowEdit: false,
      mode: 'Cell',
      allowEditOnDblClick: false,
    };

    const rowSelectedSub = this.rowSelected$
      .pipe(
        debounceTime(500),
        switchMap((event: any) => {
          return this.defer$(event);
        }),
      )
      .subscribe();

    const includeCancelledSubscription = this.store$
      .select(getIncludeCancelledItems)
      .subscribe((areCancelledIncluded) => {
        this.areCancelledIncluded = areCancelledIncluded;
      });

    const projectIdSubscription = this.store$.select(routerSelectors.getProjectIdSelector).subscribe((projectId) => {
      if (projectId !== this.projectId) {
        this.store$.dispatch(clearProjectRelatedData());
      }
      this.projectId = projectId;
      this.resetLoading();
      this.store$.dispatch(treeActions.reloadProjectData({ projectId }));
      this.store$.dispatch(treeActions.getProjectHierarchyRulesData({ projectId }));
    });

    this.projectHierarchyRulesSubscription$ = this.store$
      .select(TreeSelectors.getProjectHierarchyRules)
      .pipe(filter((x) => !!x.length));

    this.isCurrentUserAdminSubscription$ = this.store$.select(getCurrentUser).pipe(filter((x) => !!x));

    this.isCurrentUserLevelOneFunctionManagerSubscription$ = this.store$
      .select(projectSelectors.getProjectDetails)
      .pipe(filter((x) => !!x));

    const projectNodeSubscription = this.store$
      .select(TreeSelectors.getTopProjectNode)
      .pipe(map((node) => node?.title))
      .subscribe((title) => {
        this.projectTitle = title;
      });

    const manageRightsSubscription = this.store$
      .select(TreeSelectors.hasManagePermissionOnProjectNode)
      .subscribe((hasManageRights) => {
        this.hasProjectManageRights$.next(hasManageRights);
        this.changeDetector.markForCheck();
      });

    const routeDataSubscription = this.store$.select(routerSelectors.getRouteType).subscribe((routeType) => {
      this.routeType = routeType;
    });

    const hierarchyIdsSubscription = this.store$
      .select(routerSelectors.getIdSelector)
      .pipe(
        switchMap((id) => {
          if (this.isDeliverablePath()) {
            return this.store$
              .select(DeliverableSelectors.getDeliverablePackageHierarchyIdByDeliverableId({ id }))
              .pipe(filter((x) => !!x));
          }
          return of(id);
        }),
        filter((id) => !!id),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      )
      .subscribe((idToExpand) => {
        this.currentlySelectedPrimaryKey = idToExpand;
        if (idToExpand && this.treegrid && !this.manualSelection) {
          this.selectAndExpandTreeHierarchy(idToExpand);
        }
        this.manualSelection = false;
      });

    const affectedIdsSub = this.store$
      .select(getCrAffectedIds)
      .pipe(distinctUntilChanged())
      .subscribe((ids) => {
        this.crAffectedIds = ids;
        this.changeDetector.markForCheck();
      });

    const treeUpdateSubscription = this.treeService.treeNodeChanged$.subscribe((updated: Update<TreeNode>) =>
      this.updateTreeData(updated),
    );

    const treeReloadSubscription = this.treeService.reloadTreeData$.subscribe((refreshedTree: TreeNode[]) => {
      this.reloadTree(refreshedTree);
    });
    this.subscription.add(treeReloadSubscription);

    const nodeMoveSubscription = this.treeService.treeMove$.subscribe((nodeMoveData: NodeMoveData) => {
      let rowIndex = this.treegrid.grid.getRowIndexByPrimaryKey(nodeMoveData.functionalHierarchyId);
      let element = this.treegrid.grid.getCurrentViewRecords()[rowIndex] as any;

      this.treeData[rowIndex] = {
        ...this.treegrid.grid.dataSource[rowIndex],
      };

      const destinationRowIndex = this.treegrid.grid.getRowIndexByPrimaryKey(
        nodeMoveData.functionDestinationFunctionalHierarchyId,
      );
      this.treegrid.expandByKey(nodeMoveData.functionDestinationFunctionalHierarchyId);

      this.treegrid.reorderRows([rowIndex], destinationRowIndex, 'child');
      let selectionIndex = this.treegrid.grid.getRowIndexByPrimaryKey(element.parentId);
      this.treegrid.selectRow(selectionIndex);
      this.action = Action.Reordered;
    });
    this.subscription.add(nodeMoveSubscription);

    const nodeAddedSub = this.signalrService.nodeAdded().subscribe(async (fhid: number) => this.addNode(fhid));

    const nodeRemovedSub = this.signalrService.nodeDeleted().subscribe((ids: number[]) => {
      this.removeNodeFromTree(ids);
    });

    this.filterSettings = {
      type: 'Menu',
      hierarchyMode: 'Parent',
      mode: 'Immediate',
      columns: [],
    };
    this.statusFilter = {
      itemTemplate: this.filterTemplate,
      type: 'CheckBox',
    };
    this.responsibleFilter = {
      itemTemplate: this.filterTemplate,
      type: 'CheckBox',
    };
    this.dateFilter = {
      itemTemplate: this.filterTemplate,
      type: 'Excel',
    };

    const translationChangeSubscription = this.translationService.onLangChange.subscribe(() => {
      this.refreshColumnHeadersTranslations();
    });

    this.subscription.add(includeCancelledSubscription);
    this.subscription.add(projectIdSubscription);
    this.subscription.add(hierarchyIdsSubscription);
    this.subscription.add(routeDataSubscription);
    this.subscription.add(affectedIdsSub);
    this.subscription.add(projectNodeSubscription);
    this.subscription.add(manageRightsSubscription);
    this.subscription.add(treeUpdateSubscription);
    this.subscription.add(nodeRemovedSub);
    this.subscription.add(nodeAddedSub);
    this.subscription.add(translationChangeSubscription);
    this.subscription.add(rowSelectedSub);

    this.customAttributes = { class: 'titlecolumncss' };
  }

  onRowSelected(event: any) {
    if (event.isInteracted === false && event.rowIndex !== 0) {
      return of(null);
    }

    const data = isArray(event.data) ? event.data[0] : event.data;
    this.navigationService.navigate(data.functionalHierarchyId);

    this.restoreScrollLeftPosition();

    return of(null);
  }

  defer$(event: any): Observable<any> {
    return defer(() => this.onRowSelected(event));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store$.dispatch(treeActions.clearTreeData());
  }

  reloadTree(tree: TreeNode[]): void {
    this.treeData = cloneDeep(tree);
    this.loading = false;
    this.action = Action.DataLoaded;
    this.changeDetector.markForCheck();
  }

  async addNode(fhid) {
    const node = await this.store$
      .select(
        TreeSelectors.getTreeNodeByFunctionalHierarchyId({
          functionalHierarchyId: fhid,
        }),
      )
      .pipe(
        filter((x) => !!x.functionalHierarchyId),
        take(1),
      )
      .toPromise();

    const rowIndex = this.treegrid.grid.getRowIndexByPrimaryKey(node.parentId);
    const row = this.treegrid.getRows()[rowIndex] as any;
    if (!JSON.parse(row.ariaExpanded)) {
      this.treegrid.expandRow(row);
    }

    this.action = Action.NodeAdded;
    this.selectedRowFunctionalHierarchyId = node.functionalHierarchyId;
    this.treeData.push({ ...node });
    this.treegrid.addRecord({ ...node }, rowIndex);
  }

  resetLoading(): void {
    this.treeData = cloneDeep([]);
    this.loading = true;
    this.changeDetector.markForCheck();
  }

  onActionComplete(event: TreeGridEvent): void {
    if (event.requestType === TreeGridActions.columnstate || event.requestType === TreeGridActions.refresh) {
      setGridMinWidth();
    }

    if (event.requestType === TreeGridActions.refresh && this.firstLoad) {
      this.treegrid.refreshColumns();
      this.firstLoad = false;
    }

    if (event.requestType === TreeGridActions.delete) {
      this.action = Action.NodeDeleted;
    }
    if (event.requestType === TreeGridActions.add) {
      this.action = Action.NodeAdded;
    }
  }

  onResizeStop(): void {
    setGridMinWidth();
  }

  rowSelecting(event: any): void {
    if (this.action === Action.NodeDeleted) {
      event.cancel = true;
      this.action = Action.NotSet;
      this.treegrid.selectRow(0);
    }

    if (this.action === Action.NodeAdded) {
      event.cancel = true;
      this.treegrid.endEdit();
      this.action = Action.NotSet;
      this.selectByPrimaryKeyId();
    }
    this.manualSelection = true;
    this.rememberScrollLeftPosition();
  }

  selectRow(functionalHierarchyId): void {
    this.selectedRowFunctionalHierarchyId = functionalHierarchyId;
  }

  expandNode(functionalHierarchyId): void {
    this.bulkCollapseOrExpandInProgress = true;
    this.toggleNode(functionalHierarchyId, false);
    this.bulkCollapseOrExpandInProgress = false;
  }

  collapseNode(functionalHierarchyId): void {
    this.bulkCollapseOrExpandInProgress = true;
    this.toggleNode(functionalHierarchyId, true);
    this.bulkCollapseOrExpandInProgress = false;
  }

  expandAll(): void {
    this.bulkCollapseOrExpandInProgress = true;
    this.treegrid.expandAll();
    this.bulkCollapseOrExpandInProgress = false;
  }

  collapseAll(): void {
    this.bulkCollapseOrExpandInProgress = true;
    this.treegrid.collapseAll();
    this.bulkCollapseOrExpandInProgress = false;
  }

  onCollapsing(): void {
    if (this.bulkCollapseOrExpandInProgress) return;
    this.rememberScrollLeftPosition();
  }

  onCollapsed(): void {
    if (this.bulkCollapseOrExpandInProgress) return;
    this.restoreScrollLeftPosition();
  }

  onExpanding(): void {
    if (this.bulkCollapseOrExpandInProgress) return;
    this.rememberScrollLeftPosition();
  }

  onExpanded(): void {
    if (this.bulkCollapseOrExpandInProgress) return;
    this.restoreScrollLeftPosition();
  }

  private updateTreeData(updated: Update<TreeNode>): void {
    const functionalNodeId = updated.id;

    const selectedRowIndex = (this.treegrid.dataSource as TreeNode[]).findIndex(
      (x) => x.functionalHierarchyId === functionalNodeId,
    );

    if (selectedRowIndex >= 0) {
      const formattedData = cleanDataForFiltering([updated.changes as TreeNode]);
      const rowData = Object.assign({}, this.treegrid.dataSource[selectedRowIndex]);

      try {
        this.treegrid.setRowData(functionalNodeId, {
          ...rowData,
          ...formattedData[0],
        });
      } catch (ex) {
        // syncfusion grid throws exception because of incorrect index
        // gotten from this.getCurrentViewRecords(); so we need to ignore that
      }

      let index = this.treeData.findIndex((x) => x.functionalHierarchyId === functionalNodeId);
      this.treeData[index] = { ...formattedData[0] };
      this.action = Action.NodeUpdated;
    }
  }

  private removeNodeFromTree(ids: number[]): void {
    if (ids.length === 0) {
      return;
    }

    ids.forEach((id) => {
      let index = this.treeData.findIndex((x) => x.functionalHierarchyId === id);
      if (index > -1) {
        this.treeData.splice(index, 1);
      }
    });

    const objectsToDelete: { functionalHierarchyId: number }[] = [];
    ids.forEach((id) => objectsToDelete.push({ functionalHierarchyId: id }));
    this.treegrid.deleteRecord('functionalHierarchyId', objectsToDelete);
    this.action = Action.NodeDeleted;
  }

  exportExcel(): void {
    this.excelLoading = true;
    this.changeDetector.markForCheck();

    this.treeService.downloadExcel(this.projectId).subscribe(
      (response: HttpResponse<Blob>) => {
        const filename = this.projectTitle;
        const pattern = /[\\.!$^~#%&*{}/:<>?|\"-()_]/g;
        const sanitizedName = filename.replace(pattern, '');
        saveAs(response.body, `${sanitizedName} Project Export.xlsx`);
      },
      () => {
        this.excelLoading = false;
        this.changeDetector.markForCheck();
      },
      () => {
        this.excelLoading = false;
        this.changeDetector.markForCheck();
      },
    );
  }

  downloadProjectStructureTemplate(): void {
    this.treeService.downloadProjectStructureTemplate().subscribe((response: HttpResponse<Blob>) => {
      const filename = this.projectTitle;
      const pattern = /[\\.!$^~#%&*{}/:<>?|\"-()_]/g;
      const sanitizedName = filename.replace(pattern, '');
      saveAs(response.body, `${sanitizedName} Project Structure Template.xlsx`);
    });
  }

  openUploadStructureDialog(): void {
    this.dialog.open(UploadTreeStructureComponent, {
      data: { projectId: this.projectId },
      panelClass: 'orms-upload-template',
      disableClose: true,
    });
  }

  openImportWizard(): void {
    this.router.navigate(['./import'], {
      relativeTo: this.route,
      state: {
        projectTitle: this.projectTitle,
      },
    });
  }

  resetColumns(): void {
    this.treegrid.getColumnByField('title').width = this.titleWidth;
    this.treegrid.getColumnByField('title').visible = true;

    this.treegrid.getColumnByField('code').width = this.codeWidth;
    this.treegrid.getColumnByField('code').visible = true;

    this.treegrid.getColumnByField('status').width = this.statusWidth;
    this.treegrid.getColumnByField('status').visible = true;

    this.treegrid.getColumnByField('manager').width = this.responsibleWidth;
    this.treegrid.getColumnByField('manager').visible = true;

    this.treegrid.getColumnByField('budgetHours').width = this.hoursWidth;
    this.treegrid.getColumnByField('budgetHours').visible = true;

    this.treegrid.getColumnByField('plannedComplete').width = this.plannedWidth;
    this.treegrid.getColumnByField('plannedComplete').visible = true;

    this.treegrid.getColumnByField('actualComplete').width = this.actualWidth;
    this.treegrid.getColumnByField('actualComplete').visible = true;

    this.treegrid.getColumnByField('spi').width = this.spiWidth;
    this.treegrid.getColumnByField('spi').visible = true;

    this.treegrid.getColumnByField('startDate').width = this.dateWidth;
    this.treegrid.getColumnByField('startDate').visible = true;

    this.treegrid.getColumnByField('endDate').width = this.dateWidth;
    this.treegrid.getColumnByField('endDate').visible = true;

    this.treegrid.refreshColumns(true);
    this.treegrid.reorderColumns(
      [
        'title',
        'code',
        'status',
        'manager',
        'plannedComplete',
        'actualComplete',
        'spi',
        'startDate',
        'endDate',
        'budgetHours',
      ],
      'title',
    );
  }

  refreshColumnHeadersTranslations() {
    if (this.treeData && this.treeData.length) {
      this.treegrid.getColumnByField('title').headerText = this.translationService.instant('treeHeader.title');
      this.treegrid.getColumnByField('code').headerText = this.translationService.instant('treeHeader.code');
      this.treegrid.getColumnByField('status').headerText = this.translationService.instant('treeHeader.status');
      this.treegrid.getColumnByField('manager').headerText = this.translationService.instant('treeHeader.responsible');
      this.treegrid.getColumnByField('budgetHours').headerText = this.translationService.instant('treeHeader.hours');
      this.treegrid.getColumnByField('plannedComplete').headerText =
        this.translationService.instant('treeHeader.planned');
      this.treegrid.getColumnByField('actualComplete').headerText =
        this.translationService.instant('treeHeader.actual');
      this.treegrid.getColumnByField('spi').headerText = this.translationService.instant('treeHeader.spi');
      this.treegrid.getColumnByField('startDate').headerText = this.translationService.instant('treeHeader.startDate');
      this.treegrid.getColumnByField('endDate').headerText = this.translationService.instant('treeHeader.endDate');
      this.treegrid.refreshColumns();
    }
  }

  includeCancelledItems() {
    this.resetLoading();
    this.store$.dispatch(includeCancelledItems({ includeCancelledItems: !this.areCancelledIncluded }));
    this.store$.dispatch(treeActions.reloadProjectData({ projectId: this.projectId }));
  }

  filterAction = (args) => {
    // This is required for filtering by second date in range
    if (
      args.requestType === 'filtering' &&
      (args.currentFilteringColumn === 'startDate' || args.currentFilteringColumn === 'endDate') &&
      this.customFilter
    ) {
      this.customFilter = false;
      args.columns.push({
        actualFilterValue: {},
        actualOperator: {},
        field: args.currentFilteringColumn,
        ignoreAccent: false,
        isForeignKey: false,
        matchCase: false,
        operator: 'lessthanorequal',
        predicate: 'and',
        uid: this.treegrid.getColumnByField(args.currentFilteringColumn).uid,
        value: this.filterEndDate,
      });
    }
  };

  onTreeDataBound(): void {
    if (this.initialDataBoundDataLoaded === false) {
      this.initialDataBoundDataLoaded = true;
      this.treegrid.grid.resizeSettings.mode = 'Auto';
    }

    if (this.treeData && this.treeData.length) {
      if (this.action === Action.DataLoaded) {
        // this is for this specific url https://localhost:4200/#/project-management/211
        let navigationWithoutIdSub = this.store$
          .select(routerSelectors.isProjectManagementRouteWithoutId)
          .pipe(
            filter((x) => x),
            take(1),
          )
          .subscribe(() => {
            this.treegrid.selectRow(0);
          });
        this.subscription.add(navigationWithoutIdSub);
      }
    }

    if (!(this.action === Action.NotSet || this.action === Action.NodeAdded)) {
      this.manualSelection = false;
      this.selectAndExpandTreeHierarchy(this.currentlySelectedPrimaryKey);
    }

    try {
      this.treegrid
        .getRows()
        .filter((x: any) => JSON.parse(x.ariaExpanded))
        .forEach((x) => this.treegrid.expandRow(x));
    } catch (ex) {
      // syncfusion grid throws exception because of incorrect index
    }
  }

  rowDataBound(args: RowDataBoundEventArgs): void {
    const node = args.data as TreeNode;
    args.row.id = node.functionalHierarchyId.toString();
  }

  private toggleNode(functionalHierarchyId: number, collapse: boolean): void {
    const allChildrenFhIdsSub = this.store$
      .select(
        TreeSelectors.getAllChildrenNodeIdsSortedByLevel({
          functionalHierarchyId,
          ascending: collapse,
        }),
      )
      .subscribe((fhids) => {
        this.toggleChildNodes(
          fhids,
          collapse ? this.treegrid.collapseRow.bind(this.treegrid) : this.treegrid.expandRow.bind(this.treegrid),
        );
      });
    allChildrenFhIdsSub.unsubscribe();
  }

  private toggleChildNodes(functionalHierarchyIds: number[], toggleFunction: (row: HTMLTableRowElement) => void): void {
    functionalHierarchyIds.forEach((fhid) => {
      const rowIndex = this.treegrid.grid.getRowIndexByPrimaryKey(fhid);
      const row = this.treegrid.getRows()[rowIndex];
      if (row) {
        toggleFunction(row);
      }
    });
  }

  private selectAndExpandTreeHierarchy(primaryKey: number): void {
    if (primaryKey) {
      this.treegrid.expandByKey(primaryKey);
      const rowIndex = this.treegrid.grid.getRowIndexByPrimaryKey(primaryKey);
      if (rowIndex >= 0) {
        this.treegrid.selectRow(rowIndex);
      }
    }
  }

  private selectByPrimaryKeyId(): void {
    if (!this.selectedRowFunctionalHierarchyId) {
      return;
    }
    const rowIndex = this.treegrid.grid.getRowIndexByPrimaryKey(this.selectedRowFunctionalHierarchyId);
    if (rowIndex) {
      this.treegrid.selectRow(rowIndex);
      this.navigationService.navigate(this.selectedRowFunctionalHierarchyId);
      this.selectedRowFunctionalHierarchyId = null;
    }
  }

  private isDeliverablePath(): boolean {
    return this.routeType === EntityTypes.deliverable;
  }

  public columnMenuOpen(args: any): void {
    if (args.column.field !== 'title' && args.column.field !== 'code') {
      for (const item of args.items) {
        if (item.text === 'Sort Ascending' || item.text === 'Sort Descending') {
          item.hide = true;
        }
      }
    }
  }

  private rememberScrollLeftPosition() {
    this.scrollLeft = document.getElementById('treegrid-stretch-container').scrollLeft;
  }

  private restoreScrollLeftPosition() {
    setTimeout(() => {
      if (this.scrollLeft != undefined) {
        document.getElementById('treegrid-stretch-container').scrollLeft = this.scrollLeft;
        this.scrollLeft = undefined;
      }
    }, 0);
  }
}
