import { getMonth, getYear } from 'date-fns';
import { DeliverableDetail } from './DeliverableDetail';

export function filterBySoon(data: DeliverableDetail[], prop: string, date: Date = new Date()): DeliverableDetail[] {
  // filter 10 closest deliverables and plus deliverables with the same month as 10nth result
  const filteredDataByToday = data.filter((deliverable) => new Date(deliverable[prop]) > date);
  if (filteredDataByToday.length < 10) {
    return filteredDataByToday;
  }

  const sortedResultsIds = [...filteredDataByToday]
    .sort((prev, next) => {
      const prevDate = prev[prop];
      const nextDate = next[prop];
      return new Date(prevDate) > new Date(nextDate) ? 1 : -1;
    })
    .map((deliverable) => deliverable.deliverableId);

  const lastItem = filteredDataByToday.find((deliverable) => deliverable.deliverableId === sortedResultsIds[9]);
  const lastItemDate = new Date(lastItem[prop]);
  const lastItemMonth = getMonth(lastItemDate);
  const lastItemYear = getYear(lastItemDate);
  const itemIdsFromTheSameMonth = filteredDataByToday
    .filter((deliverable) => {
      const month = getMonth(new Date(deliverable[prop]));
      const year = getYear(new Date(deliverable[prop]));
      return lastItemMonth === month && lastItemYear === year;
    })
    .map((deliverable) => deliverable.deliverableId);
  const resultsIds = new Set([...sortedResultsIds.slice(0, 9), ...itemIdsFromTheSameMonth]);
  return filteredDataByToday.filter((deliverable) => Array.from(resultsIds).includes(deliverable.deliverableId));
}
