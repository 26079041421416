import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ErrorRefWithParams } from 'src/app/common-models/error-ref-with-params.model';
import { ProcessingResult } from 'src/app/shared-controls/shared-enums';
import { WizardStepComponent } from 'src/app/shared-controls/wizard/wizard.component';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectImportWizardContext } from '../project-import.models';
import { ProjectImportService } from '../project-import.service';

@Component({
  selector: 'app-import-confirmation-step',
  templateUrl: './import-confirmation-step.component.html',
  styleUrls: ['./import-confirmation-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportConfirmationStepComponent implements OnInit, WizardStepComponent {
  nextStepClick = new EventEmitter<void>();
  previousStepClick = new EventEmitter<void>();
  cancelClick = new EventEmitter<void>();

  context: ProjectImportWizardContext;
  projectImportResult: ProcessingResult;
  ProcessingResult = ProcessingResult; // Declare ProcessingResult enum as a property so we can reference it in the template
  isLoading$ = new BehaviorSubject<boolean>(true);
  executionErrors: ErrorRefWithParams[] = [];

  constructor(
    private projectImportService: ProjectImportService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.projectImportService
      .executeProjectImport(this.context.payload)
      .pipe(
        tap({
          next: (response: HttpResponse<null>) => {
            // We don't need to check the http status here as the only success response the API issues is a 202 - Accepted
            this.projectImportResult = ProcessingResult.Success;
          },
        }),
        catchError((err: HttpErrorResponse) => {
          // The API will issue a "MultipleItemApiBusinessRuleException", which uses status code 400, if the execute encounters errors executing the import
          // The errors are an array of string that need translation
          if (err.status === 400) {
            this.projectImportResult = ProcessingResult.Failure;
            if (err.error.errors[0].message !== undefined && err.error.errors[0].message !== null) {
              err.error.errors.forEach((item: any) => {
                const parts = item.message.split('|');
                this.executionErrors.push({ errorRef: parts[0], params: { ...parts.slice(1) } });
              });
            } else {
              showHttpErrorResponse(this.notificationService, err, '', true);
            }

            // Having notified the user of anticipated errors we want to continue rather than fail, so return "of(null)""
            return of(null);
          }

          // other errors that we were not anticipating should bubble up
          return throwError(() => err);
        }),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe();
  }
}
