<mat-accordion class="orms-expansion-panel">
  <mat-expansion-panel class="mat-elevation-z0" [expanded]="isExpanded" (opened)="opened()" (closed)="closed()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5 class="orms-label">
          {{ 'general.intent' | translate }}
        </h5></mat-panel-title
      >
    </mat-expansion-panel-header>
    <editor
      class="orms-editor"
      [(ngModel)]="detailData.intent"
      [disabled]="(isEditable$ | async) === false"
      [initialValue]="detailData.intent"
      [init]="init"
      (onFocusOut)="change($event)"
    ></editor>
  </mat-expansion-panel>
</mat-accordion>
