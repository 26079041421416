import moment from 'moment';

export const objectKeysToLowerCase = (input) => {
  if (typeof input !== 'object') {
    return input;
  }
  if (Array.isArray(input)) {
    return input.map(objectKeysToLowerCase);
  }
  return Object.keys(input).reduce((newObj, key) => {
    const val = input[key];
    const newVal = typeof val === 'object' && val !== null ? objectKeysToLowerCase(val) : val;
    newObj[key[0].toLowerCase() + key.slice(1)] = newVal;
    return newObj;
  }, {});
};

export const rewriteDateFromUTC = (date: Date | moment.Moment, includeTime = true): Date => {
  if (!date) {
    return date as Date;
  }

  const momentDate = moment.utc(date).toDate();
  if (includeTime) {
    return new Date(
      momentDate.getUTCFullYear(),
      momentDate.getUTCMonth(),
      momentDate.getUTCDate(),
      momentDate.getUTCHours(),
      momentDate.getUTCMinutes(),
      momentDate.getUTCSeconds(),
    );
  } else {
    return new Date(momentDate.getUTCFullYear(), momentDate.getUTCMonth(), momentDate.getUTCDate());
  }
};
