import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dashDayDateFormat, timeFormat } from 'src/app/shared/date-options/short-date-format';
import { ChangeRequestStatusChange } from './change-request-status-change.model';

@Component({
  selector: 'app-cr-history',
  templateUrl: './cr-history.component.html',
  styleUrls: ['./cr-history.component.scss'],
})
export class CrHistoryComponent implements OnChanges {
  @Input() statusChanges: ChangeRequestStatusChange[];
  shortDateFormat = dashDayDateFormat;
  timeFormat = timeFormat;
  historyColumns = ['eventDate', 'description'];

  constructor(public translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('statusChanges' in changes && this.statusChanges) {
      this.statusChanges = this.statusChanges.map((sc) => {
        const levelTranslationKey = 'changeRequest.' + sc.level.toLowerCase();
        let level = sc.level ? this.translateService.instant(levelTranslationKey) : '';
        if (level === levelTranslationKey) {
          level = sc.level;
        }
        const status: ChangeRequestStatusChange = {
          eventDate: sc.eventDate,
          manager: sc.manager,
          level: level,
          status: this.translateService.instant('changeRequest.' + sc.status.toLowerCase()),
        };
        return status;
      });
    }
  }
}
