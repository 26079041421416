import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

export interface NumericFilterValue {
  operation: NumericFilterOperation;
  arg1: number;
  arg2: number;
}

export enum NumericFilterOperation {
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  GreaterThanOrEquals = 'GreaterThanOrEquals',
  LessThanOrEquals = 'LessThanOrEquals',
  Between = 'Between',
}

@Component({
  selector: 'app-numeric-filter',
  templateUrl: './numeric-filter.component.html',
  styleUrls: ['./numeric-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string;

  @Input() value: NumericFilterValue;

  @Output()
  valueChange = new EventEmitter<NumericFilterValue>();

  NumericFilterOperation = NumericFilterOperation;
  form: UntypedFormGroup;
  label$ = new BehaviorSubject<string>('');
  menuIsOpen = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private decimalPipe: DecimalPipe,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      operation: new UntypedFormControl(null),
      arg1: new UntypedFormControl(null),
      arg2: new UntypedFormControl(null),
    });

    this.form.patchValue(this.value, { emitEvent: false });

    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$), startWith(this.translateService.currentLang))
      .subscribe(() => this.refreshLabel());

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.valueChange.emit(this.isCorrectValue() ? value : null);
        this.refreshLabel();
      });

    this.form
      .get('operation')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.form.patchValue({
          arg1: null,
          arg2: null,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && 'value' in changes && !this.menuIsOpen) {
      this.patchForm(this.value, false);
      this.refreshLabel();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onClearClick(e): void {
    this.patchForm(null, true);
    e.stopPropagation();
  }

  setMenuIsOpen(isOpen: boolean): void {
    this.menuIsOpen = isOpen;
  }

  private patchForm(value: NumericFilterValue, emitEvent: boolean): void {
    this.form.patchValue(
      {
        operation: value?.operation,
        arg1: value?.arg1,
        arg2: value?.arg2,
      },
      { emitEvent },
    );
  }

  private isCorrectValue(): boolean {
    if (this.form.get('operation').value == null) return false;
    if (this.form.get('arg1').value == null) return false;
    if (this.form.get('operation').value == NumericFilterOperation.Between && this.form.get('arg2').value == null)
      return false;

    return true;
  }

  private refreshLabel(): void {
    let label = this.translateService.instant(this.label);

    const operation = this.form.get('operation').value;
    const arg1 = this.decimalPipe.transform(this.form.get('arg1').value, '1.0-10');
    const arg2 = this.decimalPipe.transform(this.form.get('arg2').value, '1.0-10');

    if (arg1 == null) {
      this.label$.next(label);
      return;
    }

    switch (operation) {
      case NumericFilterOperation.GreaterThan:
        label = `${label} > ${arg1}`;
        break;
      case NumericFilterOperation.LessThan:
        label = `${label} < ${arg1}`;
        break;
      case NumericFilterOperation.GreaterThanOrEquals:
        label = `${label} ≥ ${arg1}`;
        break;
      case NumericFilterOperation.LessThanOrEquals:
        label = `${label} ≤ ${arg1}`;
        break;
      case NumericFilterOperation.Between:
        if (arg2) {
          label = `${arg1} ≤ ${label} ≤ ${arg2}`;
        }
        break;
    }
    this.label$.next(label);
  }
}
