<form [formGroup]="form" autocomplete="off" class="node-header-container">
  <ng-content></ng-content>
  <div class="title-action-section">
    <img class="package-title-icon" src="/assets/icons/package.svg" />
    <input
      class="form-control orms-shadow-input orms-node-title"
      formControlName="title"
      [placeholder]="'general.title' | translate"
      [title]="form.get('title').value ? form.get('title').value : ('general.title' | translate)"
      maxlength="120"
    />
  </div>
  <div class="code-kpi-section">
    <div class="code">
      <input
        class="form-control orms-shadow-input orms-node-code"
        formControlName="code"
        [placeholder]="(treeNode?.packageId ? 'general.codeRequired' : 'general.code') | translate"
        [title]="form.get('code').value ? form.get('code').value : ('general.code' | translate)"
        maxlength="20"
      />
      <div class="details mt-2">
        <img
          *ngIf="headerDetails?.underChangeControl"
          class="baseline-icon"
          src="/assets/icons/baseline.svg"
          alt="{{ 'imgAlt.baseline' | translate }}"
        />
        <app-status-chip [statusDetails]="statusDetails"></app-status-chip>
        <app-user-dropdown
          [clearable]="true"
          [projectId]="projectId"
          [manager]="form.get('manager').value"
          formControlName="manager"
        >
        </app-user-dropdown>
      </div>
    </div>
    <div class="kpi">
      <div class="box">
        <h6 class="orms-label">{{ 'general.plannedComplete' | translate }}</h6>
        <h3>
          {{ headerDetails?.plannedComplete | statusPercent: headerDetails?.status : '1.1-2' }}
        </h3>
      </div>
      <div class="box">
        <h6 class="orms-label">{{ 'general.actualComplete' | translate }}</h6>
        <h3>
          {{ headerDetails?.actualComplete | statusPercent: headerDetails?.status : '1.1-2' }}
        </h3>
      </div>
      <div class="box">
        <h6 class="orms-label">SPI</h6>
        <h3>
          {{ headerDetails?.spi | statusNumber: headerDetails?.status : '1.1-2' }}
        </h3>
      </div>
    </div>
  </div>
</form>
