import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { ChangeRequestDeliverable, RequestType } from '../../change-request-cart-state/cart-item.model';
import { removeMoveChange } from '../../change-request-cart-state/change-requests/change-request.actions';
import * as cdDeliverableActions from '../../change-request-cart-state/deliverables/crDeliverable.actions';

@Component({
  selector: 'app-request-deliverables',
  templateUrl: './request-deliverables.component.html',
  styleUrls: ['./request-deliverables.component.scss'],
})
export class RequestDeliverablesComponent implements OnInit {
  @Input() crDeliverables: ChangeRequestDeliverable[] = [];
  @Input() requestType: RequestType;
  @Input() displayOnly = false;

  curveProfiles$ = new Observable<Map<string, string>>();
  requestTypeEnum = RequestType;
  private subscription = new Subscription();

  commonColumns = ['packageCode', 'documentNumber', 'title', 'newHours', 'newCost'];

  deliverablesColumns: string[];

  constructor(
    private store$: Store<AppState>,
    private curveProfilesService: CurveProfileService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.deliverablesColumns =
      this.requestType === RequestType.Change
        ? this.commonColumns.concat(['request', 'newCurveProfileCode'])
        : this.commonColumns.concat(['destinationPackage']);

    this.curveProfiles$ = this.curveProfilesService.curveProfiles$.pipe(
      map((curveProfiles) => new Map(curveProfiles.map((cp) => [cp.code, cp.description]))),
    );
    if (!this.displayOnly) {
      this.deliverablesColumns.push('delete');
    }
  }

  removeDeliverable(crDeliverable: ChangeRequestDeliverable): void {
    if (this.requestType === RequestType.Change) {
      this.store$.dispatch(
        cdDeliverableActions.removeDeliverableChange({
          deliverableId: crDeliverable.deliverableId,
          packageFunctionalHierarchyId: crDeliverable.packageFunctionalHierarchyId,
        }),
      );
    } else {
      this.store$.dispatch(
        cdDeliverableActions.removeDeliverableMoveRequest({
          deliverableId: crDeliverable.deliverableId,
          packageFunctionalHierarchyId: crDeliverable.packageFunctionalHierarchyId,
        }),
      );
      this.store$.dispatch(
        removeMoveChange({
          deliverableId: crDeliverable.deliverableId,
        }),
      );
    }
  }

  navigateToPackage(packageFunctionalHierarchyId: number): void {
    const projectIdSubscription = this.store$
      .select(getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.router.navigate([
          'project-management/',
          projectId,
          EntityTypes.package,
          packageFunctionalHierarchyId,
          'detail',
        ]);
      });
    this.subscription.add(projectIdSubscription);
  }
}
