import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { User } from '../../common-models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
  ) {}

  public getUsersForSearch(projectId: number, search: string | any[] = ''): Observable<User[]> {
    if (typeof search === 'object') {
      return of([]);
    }
    const params = new HttpParams().set('projectId', projectId.toString()).set('filter', search);

    const { baseUrl, userTypeAhead } = this.appConfigService.settings.api.endpoints;
    const url = `${baseUrl}${userTypeAhead}`;
    return this.httpClient.get<User[]>(url, { params });
  }

  public getUsersForPortfolioSearch(portfolioId: number, search = ''): Observable<User[]> {
    if (typeof search === 'object') {
      return of([]);
    }
    const params = new HttpParams().set('portfolioId', portfolioId.toString()).set('filter', search);

    const { baseUrl, userTypeAheadForPortfolio } = this.appConfigService.settings.api.endpoints;
    const url = `${baseUrl}${userTypeAheadForPortfolio}`;
    return this.httpClient.get<User[]>(url, { params });
  }
}
