import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { User } from '../../common-models/user.model';
import { UsersService } from '../../shared/services/users.service';
import { MinLengthTypeAhead } from '../constants';

@Component({
  selector: 'app-user-dropdown-mat',
  templateUrl: './user-dropdown-mat.component.html',
  styleUrls: ['./user-dropdown-mat.component.scss'],
})
export class UserDropdownMatComponent implements OnInit {
  @Input() id: number;
  @Input() label = '';
  @Input() inputControl: UntypedFormControl;
  @Input() clearable = true;
  @Input() required = false;
  @Input() dataSource: (projectId: number, term: string | any[]) => Observable<any>;
  @ViewChild('input') input: ElementRef;
  input$ = new Subject<string>();
  isLoading = false;

  @Output() valueChange = new EventEmitter<any>();
  users$: Observable<User[]>;

  constructor(private usersService: UsersService) {}

  ngOnInit(): void {
    this.users$ = concat(of([]), this.input$).pipe(
      distinctUntilChanged(),
      filter((term) => !!term && term.length >= MinLengthTypeAhead),
      debounceTime(300),
      tap(() => (this.isLoading = true)),
      switchMap((term) =>
        (this.dataSource ? this.dataSource(this.id, term) : this.usersService.getUsersForSearch(this.id, term)).pipe(
          finalize(() => {
            this.isLoading = false;
          }),
          catchError(() => of([])),
        ),
      ),
    );
  }

  onOptionSelected(value: User): void {
    this.inputControl.setValue(value);
    this.input.nativeElement.blur();
  }

  clear(): void {
    this.inputControl.setValue({ userId: null, displayName: null });
  }

  onBlur(event): void {
    event.target.value = '';
  }

  onChange(event): void {
    this.input$.next(event.target.value);
  }

  displayFn(user: User): string {
    if (!user) {
      return null;
    }
    return user.displayName;
  }
}
