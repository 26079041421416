import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, HeaderValueGetterParams } from 'ag-grid-community';
import { cloneDeep, isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ProvideTemplateNameDialogComponent } from 'src/app/project-setup/project-templates/provide-template-name-dialog/provide-template-name-dialog.component';
import { WizardStepComponent } from 'src/app/shared-controls/wizard/wizard.component';
import { ProjectService } from 'src/app/shared/services/project-service';
import { getColumnDefs } from '../project-import-helper';
import {
  ProjectImportFeedbackTypes,
  ProjectImportMode,
  ProjectImportResultTypes,
  ProjectImportWizardContext,
} from '../project-import.models';
import { ProjectImportService } from '../project-import.service';
import { CustomLoadingOverlayComponent } from './ag-custom-components/ag-custom-overlay.component';
import { CustomNoRowsOverlayComponent } from './ag-custom-components/ag-no-rows.component';
import { ChangeRequestDialogComponent } from './change-request-dialog/change-request-dialog.component';
import { NoChangesDialogComponent } from './no-changes-dialog/no-changes-dialog.component';
import { ReviewImportBreakdownComponent } from './review-import-breakdown/review-import-breakdown.component';
import { ReviewImportFiltersService } from './services/review-import-filters.service';
import { ReviewImportViewStateService } from './services/review-import-view-state.service';

export interface SummaryEntity {
  title: string;
  value$: BehaviorSubject<number>;
  hasBreakdown: boolean;
}

export interface BreakdownEntity {
  sheet: string;
  added: number;
  updated: number;
  deleted: number;
  unknown: number;
}

export interface InputColumnEntity {
  key: string;
  newValue: string;
  oldValue: string;
}

@Component({
  selector: 'app-review-import-step',
  templateUrl: './review-import-step.component.html',
  styleUrls: ['./review-import-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReviewImportViewStateService, ReviewImportFiltersService],
})
export class ReviewImportStepComponent implements OnInit, OnDestroy, WizardStepComponent {
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  private gridApi!: GridApi;

  columnDefs: ColDef[];
  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    lockVisible: true,
    headerValueGetter: (params) => this.getHeaderValue(params),
  };

  rowSelection: 'single' | 'multiple' = null;

  breakdownBySheet: BreakdownEntity[] = null;

  breakdownColumns: string[] = ['sheet', 'added', 'updated', 'deleted', 'unknown'];

  totalChanges$ = new BehaviorSubject<number>(null);
  totalErrors$ = new BehaviorSubject<number>(null);
  totalWarnings$ = new BehaviorSubject<number>(null);
  totalAlerts$ = new BehaviorSubject<number>(null);
  changeNoticeRequired = false;

  summaryBlocks: SummaryEntity[] = [
    {
      title: 'projectImport.totalChanges',
      value$: this.totalChanges$,
      hasBreakdown: true,
    },
    {
      title: 'projectImport.totalErrors',
      value$: this.totalErrors$,
      hasBreakdown: false,
    },
    {
      title: 'projectImport.totalWarnings',
      value$: this.totalWarnings$,
      hasBreakdown: false,
    },
    {
      title: 'projectImport.totalAlerts',
      value$: this.totalAlerts$,
      hasBreakdown: false,
    },
  ];

  loadingOverlayComponent = CustomLoadingOverlayComponent;
  noRowsOverlayComponent = CustomNoRowsOverlayComponent;
  rowData$: Observable<any[]>;

  private getHeaderValue({ colDef }: HeaderValueGetterParams) {
    return colDef.headerName ? this.translateService.instant(colDef.headerName) : '';
  }

  nextStepClick = new EventEmitter<void>();
  previousStepClick = new EventEmitter<void>();
  cancelClick = new EventEmitter<void>();
  context: ProjectImportWizardContext;

  projectId: number;
  file: File;
  showLoadingOverlay$ = new BehaviorSubject<boolean>(true);

  private onDestroy$ = new Subject<void>();

  constructor(
    private projectImportService: ProjectImportService,
    private translateService: TranslateService,
    private filtersService: ReviewImportFiltersService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatLegacyDialog,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    const { projectId, file } = this.context;
    this.projectId = projectId;
    this.file = file;

    this.columnDefs = getColumnDefs();
    this.gridApi?.setColumnDefs(this.columnDefs);

    this.projectImportService
      .analyseProjectImport(this.projectId, this.context.file, false, this.context.mode)
      .pipe(
        take(1),
        catchError(() => of(null)),
        tap((result) => {
          if (!result) {
            this.router.navigate(['/404'], { skipLocationChange: true });
          }
        }),
        map((validationResult) => {
          this.context.payload = validationResult;

          return validationResult.importSummary.map((rowNode) => {
            let actionTranslationKey = 'general.unknown';
            rowNode.discreteAction = ProjectImportResultTypes.Unknown;
            const actions = rowNode.action.split(',').map((action) => action.trim());
            // The actions is expected to include one, and only one, of either New, Update or Delete
            // however it might contain none of the above. For example if the row does not inclide a Code value
            // then the analyzer can not determine if the user is referring to an existing item or not.
            if (actions.includes(ProjectImportResultTypes.New)) {
              actionTranslationKey = 'general.new';
              rowNode.discreteAction = ProjectImportResultTypes.New;
            } else if (actions.includes(ProjectImportResultTypes.Update)) {
              actionTranslationKey = 'general.update';
              rowNode.discreteAction = ProjectImportResultTypes.Update;
            } else if (actions.includes(ProjectImportResultTypes.Delete)) {
              actionTranslationKey = 'general.delete';
              rowNode.discreteAction = ProjectImportResultTypes.Delete;
            }

            rowNode.result = actions.includes(ProjectImportResultTypes.Error)
              ? `${this.translateService.instant('general.error')} [${this.translateService.instant(
                  actionTranslationKey,
                )}]`
              : `${this.translateService.instant(actionTranslationKey)}`;
            return rowNode;
          });
        }),
        tap((result) => {
          this.showLoadingOverlay$.next(false);
          this.filtersService.setNodes(result);

          const breakdownBySheet = result.reduce((acc, node) => {
            let found = acc.find((x) => x.sheet === node.sheet.toLocaleLowerCase());
            if (!found) {
              found = { sheet: node.sheet.toLocaleLowerCase(), added: 0, updated: 0, deleted: 0, unknown: 0 };
              acc.push(found);
            }

            switch (node.discreteAction) {
              case ProjectImportResultTypes.New: {
                found.added++;
                break;
              }
              case ProjectImportResultTypes.Update: {
                found.updated++;
                break;
              }
              case ProjectImportResultTypes.Delete: {
                found.deleted++;
                break;
              }
              case ProjectImportResultTypes.Unknown: {
                found.unknown++;
                break;
              }
            }
            return acc;
          }, []);

          const flattenedFeedback = result.flatMap((node) => node.feedback);

          const feedbackCounts = flattenedFeedback.reduce(
            (acc, feedback) => {
              switch (feedback.feedbackType) {
                case ProjectImportFeedbackTypes.Error: {
                  acc.totalErrors++;
                  break;
                }
                case ProjectImportFeedbackTypes.Warning: {
                  acc.totalWarnings++;
                  break;
                }
                case ProjectImportFeedbackTypes.Alert: {
                  acc.totalAlerts++;
                  break;
                }
              }
              return acc;
            },
            {
              totalErrors: 0,
              totalWarnings: 0,
              totalAlerts: 0,
            },
          );

          this.totalChanges$.next(result.length);
          this.totalErrors$.next(feedbackCounts.totalErrors);
          this.totalWarnings$.next(feedbackCounts.totalWarnings);
          this.totalAlerts$.next(feedbackCounts.totalAlerts);

          this.breakdownBySheet = breakdownBySheet;
        }),
        tap(() => this.changeDetectorRef.markForCheck()),
      )
      .subscribe();

    this.totalChanges$.pipe(filter(() => this.totalChanges$.value === 0)).subscribe(() => {
      const dialogRef = this.dialog.open(NoChangesDialogComponent, {
        panelClass: 'orms-no-changes-dialog',
        disableClose: true,
      });

      dialogRef
        .afterClosed()
        .pipe(tap(() => this.changeDetectorRef.markForCheck()))
        .subscribe(() => {
          this.previousStepClick.emit();
        });
    });

    this.rowData$ = combineLatest([
      this.filtersService.getNodes().pipe(map((data) => cloneDeep(data))),
      this.filtersService.getFilters(),
    ]).pipe(
      takeUntil(this.onDestroy$),
      filter(([data]) => !isEmpty(data)),
      map(([data, filters]) => {
        return data.filter((row) => filters.every((filter) => filter.predicate(row)));
      }),
    );
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onModelUpdated(): void {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }

  gatherChangeRequestFields(): void {
    const dialogRef = this.dialog.open(ChangeRequestDialogComponent, {
      panelClass: 'orms-change-request-dialog',
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((dialogPayload) => !isEmpty(dialogPayload)),
        tap((dialogPayload) => {
          this.changeDetectorRef.markForCheck();
          this.context.payload.changeNoticeTitle = dialogPayload.title;
          this.context.payload.changeNoticeReason = dialogPayload.description;
          this.context.payload.autoApprove = dialogPayload.autoApprove;
          this.nextStepClick.emit();
        }),
      )
      .subscribe();
  }

  gatherTemplateName(templateName: string): void {
    const dialogRef = this.dialog.open(ProvideTemplateNameDialogComponent, {
      panelClass: 'provide-template-name',
      data: { templateName: '' },
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((dialogPayload) => !isEmpty(dialogPayload)),
        tap((dialogPayload) => {
          this.changeDetectorRef.markForCheck();
          this.context.payload.templateName = dialogPayload.templateName;
          this.nextStepClick.emit();
        }),
      )
      .subscribe();
  }

  next(): void {
    if (!this.projectService.canCurrentUserManageProject$.value) {
      this.context.onAccessDeniedAction();
    } else {
      if (this.context.mode === ProjectImportMode.Import) {
        // When in "import" mode check if a change request is required, and if so gather the change request processing details
        if (this.context.payload.changeNoticeRequired) {
          this.gatherChangeRequestFields();
        } else {
          this.nextStepClick.emit();
        }
      } else {
        // When creating a template prompt the user for the template name
        this.gatherTemplateName(this.context.payload.templateName);
      }
    }
  }

  previous(): void {
    this.previousStepClick.emit();
  }

  cancel(): void {
    this.cancelClick.emit();
  }

  get isNextStepDisabled(): boolean {
    return !!this.totalErrors$.value || this.showLoadingOverlay$.value;
  }

  @HostListener('window:resize')
  resizeTableOnResizeWindow() {
    this.gridApi?.sizeColumnsToFit();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  displayBreakdown(entityName: string): void {
    if (entityName === 'projectImport.totalChanges') {
      const dialogRef = this.dialog.open(ReviewImportBreakdownComponent, {
        data: { breakdown: this.breakdownBySheet },
      });
      dialogRef.afterClosed().subscribe((disregardAction?) => {
        this.router.navigate(['.'], { relativeTo: this.route });
      });
    }
  }
}
