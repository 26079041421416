<div class="chartWrapper">
  <ng-container *ngIf="!isDraft; else packageNotPublished">
    <h4 class="curveHeader">{{ 'curve.complete' | translate }}</h4>
    <div class="chartAreaWrapper">
      <canvas
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [legend]="lineChartLegend"
        [type]="lineChartType"
      >
      </canvas>
    </div>
  </ng-container>
  <app-spinner *ngIf="loading" [absoluteOverlay]="true"></app-spinner>
  <ng-template #packageNotPublished><div class="message-container">Package is not Published!</div></ng-template>
</div>
