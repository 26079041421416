import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { PermissionEntry } from '../permission-entry';
import { PermissionType } from '../permission-type';

@Component({
  selector: 'app-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsTableComponent implements OnChanges {
  @Input() permissions: PermissionEntry[];
  @Input() nodeId: number;
  @Output() revokePermission = new EventEmitter<PermissionEntry>();
  @Output() changePermission = new EventEmitter<{
    permission: PermissionEntry;
    permissionType: PermissionType;
  }>();

  dataSource: MatLegacyTableDataSource<PermissionEntry>;
  PermissionType = PermissionType;
  displayedColumns = ['displayName', 'permissionType', 'actions'];

  constructor(private changeDetector: ChangeDetectorRef) {
    this.dataSource = new MatLegacyTableDataSource([]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('permissions' in changes) {
      this.dataSource.data = this.permissions;
    }
    this.changeDetector.markForCheck();
  }
}
