<input
  title="{{ value }}"
  *ngIf="editable"
  autocomplete="off"
  type="text"
  value="{{ value }}"
  [ngClass]="{ 'input-invalid': invalid }"
  (change)="notifyValueChange($event)"
  [attr.maxlength]="maxLength"
/>
<span title="{{ value }}" *ngIf="!editable">{{ value }}</span>
