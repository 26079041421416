import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  SubmitChangeRequestModel,
  SubmitMoveRequestModel,
  SubmitPackageMoveRequestModel,
} from 'src/app/common-models/submit-change-request.model';
import { FullChangeRequestModel } from 'src/app/project-management/change-request-cart/change-request-submit/full-change-request';
import {
  BulkApproval,
  BulkRejection,
  BulkResult,
} from 'src/app/project-management/home-page/bulk-action-submission/bulk.model';
import { PendingChangeRequestForRelationship } from 'src/app/project-management/home-page/change-requests-pending-approval/penging-change-request.model';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ChangeRequestListItem } from './change-request-list-item';
import { CRStatusCode, CRStatusCodesMap } from './cr-status';

@Injectable({
  providedIn: 'root',
})
export class ChangeRequestListService {
  private refreshListSubject = new BehaviorSubject(0);

  constructor(
    private appConfigService: AppConfigService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
  ) {}

  refreshChangeRequestList(): void {
    this.refreshListSubject.next(0);
  }

  changeRequestListRefreshed(): Observable<any> {
    return this.refreshListSubject.asObservable();
  }

  submitChangeRequest(changeRequest: SubmitChangeRequestModel): Observable<void> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const submitChangeRequestUrl = this.appConfigService.settings.api.endpoints.submitChangeRequest;
    return this.httpClient.post<void>(baseUrl + submitChangeRequestUrl, changeRequest).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  submitMovePackageCr(packageId: number, newParentId: number, justification: string): Observable<any> {
    const request: SubmitPackageMoveRequestModel = {
      OriginPackageFunctionalHierarchyId: packageId,
      DestinationSubFunctionId: newParentId,
      Reason: justification,
    };

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.movePackageCr;

    return this.httpClient.post<void>(baseUrl + endpoint, request).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return throwError(err);
      }),
    );
  }

  submitMoveDeliverableCr(moveRequest: SubmitMoveRequestModel): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.moveDeliverableCr;
    return this.httpClient.post<void>(baseUrl + endpoint, moveRequest).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  getChangeRequestList(
    functionalHierarchyId: number,
    changeNoticeStatusModes: CRStatusCode[],
  ): Observable<ChangeRequestListItem[]> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const params = new HttpParams()
      .set('functionalHierarchyId', functionalHierarchyId.toString())
      .set('changeNoticeStatusModes', changeNoticeStatusModes.toString())
      .set('pageNumber', '1')
      .set('orderByColumn', 'changeNoticeId')
      .set('pageSize', '99999');
    const changeRequestListUrl = this.appConfigService.settings.api.endpoints.changeRequestList;
    return this.httpClient
      .get<ChangeRequestListItem[]>(baseUrl + changeRequestListUrl, {
        params,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          showHttpErrorResponse(this.notificationService, err);
          return of([]);
        }),
        map((crList: ChangeRequestListItem[]) =>
          crList.map((crListItem) => ({
            ...crListItem,
            statusDetail: CRStatusCodesMap.get(crListItem.status),
          })),
        ),
      );
  }

  getChangeRequestsPendingApproval(
    projectId: number,
    functionalHierarchyIds: number[],
  ): Observable<PendingChangeRequestForRelationship[]> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getLoggedChangeNoticesByProject =
      this.appConfigService.settings.api.endpoints.getLoggedChangeNoticesByProject;
    let params = new HttpParams().set('projectId', projectId.toString());

    functionalHierarchyIds.forEach((id) => {
      params = params.append('functionalHierarchyIds', id.toString());
    });

    return this.httpClient.get<PendingChangeRequestForRelationship[]>(baseUrl + getLoggedChangeNoticesByProject, {
      params,
    });
  }

  getChangeRequestDetails(id: number): Observable<FullChangeRequestModel> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const params = new HttpParams().set('changeNoticeId', id.toString());

    return this.httpClient
      .get<FullChangeRequestModel>(baseUrl + this.appConfigService.settings.api.endpoints.changeRequestDetails, {
        params,
      })
      .pipe(
        catchError((err) => {
          showHttpErrorResponse(this.notificationService, err);
          return [];
        }),
      );
  }

  approve(changeNoticeId: number): Observable<ChangeRequestListItem> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    return this.httpClient
      .post(baseUrl + this.appConfigService.settings.api.endpoints.approveChangeNotice, { changeNoticeId })
      .pipe(
        tap(() => this.notificationService.success('changeRequest.successfullyApproved')),
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return of([]);
        }),
        map((changeRequestListItem: ChangeRequestListItem) => ({
          ...changeRequestListItem,
          statusDetail: CRStatusCodesMap.get(changeRequestListItem.status),
        })),
      );
  }

  bulkApprove(approvals: BulkApproval[]): any {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    return this.httpClient
      .post(baseUrl + this.appConfigService.settings.api.endpoints.approveBulkChangeNotice, approvals)
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return throwError(error);
        }),
      );
  }

  bulkReject(rejection: BulkRejection): Observable<BulkResult> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    return this.httpClient
      .post<BulkResult>(baseUrl + this.appConfigService.settings.api.endpoints.rejectBulkChangeNotice, rejection)
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return throwError(error);
        }),
      );
  }

  reject(changeNoticeId: number, reason: string): Observable<BulkResult> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    return this.httpClient
      .post<BulkResult>(baseUrl + this.appConfigService.settings.api.endpoints.rejectChangeNotice, {
        changeNoticeId,
        reason,
      })
      .pipe(
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error);
          return throwError(error);
        }),
        tap(() => this.notificationService.success('changeRequest.successfullyRejected')),
      );
  }

  cancel(changeRequestId: number): Observable<void> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const cancelChangeRequestUrl = this.appConfigService.settings.api.endpoints.cancelChangeRequest;
    return this.httpClient
      .post<void>(baseUrl + cancelChangeRequestUrl, {
        changeNoticeId: changeRequestId,
        reason: '',
      })
      .pipe(
        tap(() => this.notificationService.success('changeRequest.successfullyCancelled')),
        catchError((err) => {
          showHttpErrorResponse(this.notificationService, err);
          return [];
        }),
      );
  }
}
