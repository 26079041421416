import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { getShareIdSelector } from 'src/app/root-store/root-store.selector';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import { Filter } from 'src/app/shared-controls/searchable-dropdown/searchable-dropdown.component';
import { CurrentUserService } from 'src/app/shared/current-user/current-user.service';
import { longDateFormat, timezoneUTC } from 'src/app/shared/date-options/short-date-format';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';
import { DeliverableDetail } from '../DeliverableDetail';
import { DeliverablesFiltersService } from '../deliverables-filters.service';
import {
  DeliverableFilterType,
  DeliverableStatusFilterName,
  DeliverablesViewState,
  LookAheadFilterType,
} from '../model';

@Component({
  selector: 'app-deliverables-filters',
  templateUrl: './deliverables-filters.component.html',
  styleUrls: ['./deliverables-filters.component.scss'],
})
export class DeliverablesFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: DeliverableDetail[];
  statusFilterValue: DeliverableStatusFilterName;
  statusFilters = [
    { key: DeliverableStatusFilterName.Current, text: 'general.current' },
    { key: DeliverableStatusFilterName.Incomplete, text: 'general.incomplete' },
    { key: DeliverableStatusFilterName.Overdue, text: 'general.overdue' },
    { key: DeliverableStatusFilterName.OutOfPlan, text: 'general.outOfPlan' },
    { key: DeliverableStatusFilterName.Complete, text: 'general.complete' },
    { key: DeliverableStatusFilterName.All, text: 'general.all' },
  ];
  searchFilterValue: string;
  startsBeforeControl = new UntypedFormControl();
  dueBeforeControl = new UntypedFormControl();
  lookAheadFilterText: string;
  DeliverableFilterType = DeliverableFilterType;
  userControl = new UntypedFormControl([]);
  tagControl = new UntypedFormControl([]);
  responsibleList: Filter[] = [];
  tagList: Filter[] = [];
  subscription = new Subscription();
  datepickerActionsComponent = DatepickerActionsComponent;
  constructor(
    private deliverablesFiltersService: DeliverablesFiltersService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private currentUserService: CurrentUserService,
    private sessionStorageService: SessionStorageService,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    const { status } = this.sessionStorageService.getItem<DeliverablesViewState>(StorageKey.deliverablesList)
      ?.deliverableFilters;
    this.statusFilterValue = status ?? DeliverableStatusFilterName.Current;
    this.deliverablesFiltersService.getFilters().subscribe((filters) => {
      if (DeliverableFilterType.Status in filters) {
        this.statusFilterValue = filters[DeliverableFilterType.Status as string];
      }
      if (DeliverableFilterType.LookAhead in filters) {
        const lookAheadFilter = filters[DeliverableFilterType.LookAhead as string];
        switch (lookAheadFilter.type) {
          case LookAheadFilterType.dueSoon:
            this.lookAheadFilterText = this.translateService.instant('deliverable.dueSoon');
            break;
          case LookAheadFilterType.startsSoon:
            this.lookAheadFilterText = this.translateService.instant('deliverable.startsSoon');
            break;
          case LookAheadFilterType.dueBefore:
            const dueLabel = this.translateService.instant('deliverable.dueBefore');
            const dueFormattedDate = this.datePipe.transform(lookAheadFilter.value, longDateFormat, timezoneUTC);
            this.lookAheadFilterText = `${dueLabel} ${dueFormattedDate}`;
            break;
          case LookAheadFilterType.startsBefore:
            const startsLabel = this.translateService.instant('deliverable.startsBefore');
            const startsFormattedDate = this.datePipe.transform(lookAheadFilter.value, longDateFormat, timezoneUTC);
            this.lookAheadFilterText = `${startsLabel} ${startsFormattedDate}`;
            break;
          case null:
            this.lookAheadFilterText = null;
            break;
        }
      }
      if (DeliverableFilterType.FullText in filters) {
        this.searchFilterValue = filters[DeliverableFilterType.FullText as string];
      }
    });

    const translationSub = this.translateService.onLangChange.subscribe(() => {
      this.updateResponsibleListTranslations();
    });

    this.subscription.add(translationSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      this.createResponsibleList();
      this.createTagList();
    }
  }

  private createResponsibleList(): void {
    this.store$
      .select(getShareIdSelector)
      .pipe(
        filter((x) => !!x && x.hasState),
        map((x) => x.id),
      )
      .subscribe((id) => {
        if (id) {
          if (this.data !== null) {
            let responsibleList = this.data.reduce((list, deliverable) => {
              const { responsible, responsibleId } = deliverable;
              if (responsible && !list.find((el) => el.id === responsibleId)) {
                list.push({
                  id: responsibleId,
                  name: responsible,
                });
              }
              return list;
            }, []);
            if (this.userControl.value.length) {
              responsibleList = uniqBy([...responsibleList, ...this.userControl.value], 'userId');
            }
            this.responsibleList = responsibleList;
          }
        } else {
          this.currentUserService.currentUser$.pipe(take(1)).subscribe((currentUser) => {
            let responsibleList = [
              {
                id: currentUser.userId,
                name: this.translateService.instant('general.assignedToMe'),
              },
              {
                id: null,
                name: this.translateService.instant('general.unassigned'),
              },
            ];
            if (this.data !== null) {
              responsibleList = this.data.reduce((list, deliverable) => {
                const { responsible, responsibleId } = deliverable;
                if (responsible && !list.find((el) => el.id === responsibleId)) {
                  list.push({
                    id: responsibleId,
                    name: responsible,
                  });
                }
                return list;
              }, responsibleList);
              if (this.userControl.value.length) {
                responsibleList = uniqBy([...responsibleList, ...this.userControl.value], 'userId');
              }
            }
            this.responsibleList = responsibleList;
          });
        }
      });
  }

  private updateResponsibleListTranslations(): void {
    this.store$
      .select(getShareIdSelector)
      .pipe(
        filter((x) => !!x && x.hasState),
        map((x) => x.id),
      )
      .subscribe((id) => {
        if (!id) {
          this.currentUserService.currentUser$.pipe(take(1)).subscribe((currentUser) => {
            this.responsibleList.forEach((el, i, arr) => {
              if (el.id === currentUser.userId) {
                arr[i].name = this.translateService.instant('general.assignedToMe');
              }

              if (el.id === null) {
                arr[i].name = this.translateService.instant('general.unassigned');
              }
            });
          });
        }
      });
  }

  private createTagList(): void {
    if (this.data !== null) {
      let tagList = [];
      const flatTags = this.data.flatMap((x) => x.tags).filter((x) => x !== undefined);
      tagList = Array.from(new Set(flatTags.map((s) => s.id))).map((id) => {
        return {
          id,
          name: flatTags.find((s) => s.id === id).name,
        };
      });

      if (this.tagControl.value.length) {
        tagList = uniqBy([...tagList, ...this.tagControl.value], 'id');
      }
      this.tagList = tagList;
    }
  }

  changeFilter(type: DeliverableFilterType, value: any): void {
    this.deliverablesFiltersService.setFilter(type, value);
  }

  changeStartsBeforeLookAheadFilter(value: Date): void {
    this.changeFilter(DeliverableFilterType.LookAhead, {
      type: 'startsBefore',
      value,
    });
    this.dueBeforeControl.setValue(null, { emitEvent: false });
  }

  changeDueBeforeLookAheadFilter(value: Date): void {
    this.changeFilter(DeliverableFilterType.LookAhead, {
      type: 'dueBefore',
      value,
    });
    this.startsBeforeControl.setValue(null, { emitEvent: false });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
