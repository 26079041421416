import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { HierarchySearchCallback } from '../hierarchy-search-callback.model';
import { TreeActionsService } from '../tree-actions-menu/tree-actions.service';
import { TreeSelectors } from '../tree-state/tree.selectors';

@Component({
  selector: 'app-tree-action-move-package',
  templateUrl: './tree-action-move-package.component.html',
  styleUrls: ['./tree-action-move-package.component.scss'],
})
export class TreeActionMovePackageComponent implements OnInit, OnDestroy {
  submissionInProgress = false;
  form = new UntypedFormGroup({
    selectedHierarchy: new UntypedFormControl(null, Validators.required),
  });
  nodeTitle: string;
  treeParent$: Observable<any[]>;
  treeParentInput$ = new Subject<string>();
  loading = false;
  projectHierarchyRulesSubscription: Subscription;

  constructor(
    public dialogRef: MatLegacyDialogRef<TreeActionMovePackageComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      packageId: number;
      packageName: string;
      parentFunctionalHierarchyId: number;
    },
    private store$: Store<AppState>,
    private treeActionsService: TreeActionsService,
  ) {}

  ngOnInit(): void {
    this.treeParent$ = concat(
      of([]),
      this.treeParentInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap((searchTerm) =>
          this.hierarchySearch(searchTerm).pipe(
            catchError(() => of([])),
            tap(() => (this.loading = false)),
          ),
        ),
      ),
    );

    this.projectHierarchyRulesSubscription = this.store$
      .select(TreeSelectors.getProjectHierarchyRules)
      .pipe(filter((x) => !!x.length))
      .subscribe((rules) => {
        const maxSubFunctionLevel = Math.max(...rules.map((r) => r.level)) - 1;
        this.nodeTitle = rules.find((r) => r.level === maxSubFunctionLevel).title;
      });
  }

  confirm() {
    this.submissionInProgress = true;

    const { selectedHierarchy } = this.form.value;
    if (!selectedHierarchy) {
      return;
    }

    this.movePackage(selectedHierarchy);
  }

  private hierarchySearch(searchTerm: string): Observable<HierarchySearchCallback[]> {
    return this.store$
      .select(
        TreeSelectors.getPackagesParentSubfunctionsWithNameStartingFrom({
          term: searchTerm,
          parentFunctionIdToExclude: this.data.parentFunctionalHierarchyId,
        }),
      )
      .pipe(
        map((packageNodes) => {
          return packageNodes.map(
            ({ functionalHierarchyId, title, code }) =>
              ({
                hierarchyId: functionalHierarchyId,
                title,
                code,
                displayName: this.displayPackageTile(title, code),
              }) as HierarchySearchCallback,
          );
        }),
      );
  }

  private movePackage(entity: HierarchySearchCallback) {
    this.treeActionsService.movePackage(this.data.packageId, entity.hierarchyId).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private displayPackageTile(title: string, code: string): string {
    const codePart = code ? ' <' + code + '>' : '';
    return title + codePart;
  }

  ngOnDestroy(): void {
    this.projectHierarchyRulesSubscription?.unsubscribe();
  }
}
