import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { HistoryData } from './history-data.model';

@Injectable({
  providedIn: 'root',
})
export class HistoryDataService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  getDeliverableHistory(deliverableId: number): Observable<HistoryData[]> {
    const params = new HttpParams().set('deliverableId', deliverableId.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableHistoryUrl = this.appConfigService.settings.api.endpoints.deliverableHistory;

    return this.httpClient.get<HistoryData[]>(baseUrl + deliverableHistoryUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }

  getPackageHistory(packageFHId: number): Observable<HistoryData[]> {
    const params = new HttpParams().set('packageFHId', packageFHId.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const packageHistoryUrl = this.appConfigService.settings.api.endpoints.packageHistory;

    return this.httpClient.get<HistoryData[]>(baseUrl + packageHistoryUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }

  getFunctionHistory(functionFHId: number): Observable<HistoryData[]> {
    const params = new HttpParams().set('functionalHierarchyId', functionFHId.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const functionHistoryUrl = this.appConfigService.settings.api.endpoints.functionHistory;

    return this.httpClient.get<HistoryData[]>(baseUrl + functionHistoryUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }
}
