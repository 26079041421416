import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-cell-feedback',
  templateUrl: './ag-cell-feedback.component.html',
  styleUrls: ['./ag-cell-feedback.component.scss'],
})
export class AgCellFeedbackComponent {
  params: any;
  entityId: number;
  feedback: any;

  agInit(params: ICellRendererParams): void {
    params.api.sizeColumnsToFit();
    this.params = params;
    this.feedback = params.data.feedback.map((feedbackEntry) => {
      return {
        feedbackType: feedbackEntry.feedbackType,
        feedbackTypeTranslationKey: `general.${feedbackEntry.feedbackType.toLocaleLowerCase()}`,
        translateCode: feedbackEntry.content[0],
        translateParams: { ...feedbackEntry.content.slice(1) },
      };
    });
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return false;
  }
}
