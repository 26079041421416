import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export enum RelationshipType {
  Related = 1,
  Successor,
  Predecessor,
  Child,
  Parent,
}

@Component({
  selector: 'app-visualization-container',
  templateUrl: './visualization-container.component.html',
  styleUrls: ['./visualization-container.component.scss'],
})
export class VisualizationContainerComponent implements OnInit, OnDestroy {
  packagesData$: Observable<any>;
  dialogueSub: Subscription;

  constructor(
    public dialogRef: MatLegacyDialogRef<VisualizationContainerComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      functionalHierarchyId: number;
      visualization: any;
      title: string;
    },
  ) {}

  ngOnInit(): void {
    this.dialogueSub = this.dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnDestroy(): void {
    this.dialogueSub?.unsubscribe();
  }
}
