import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { ChangeRequestDetailsComponent } from '../../common/navigation-tabs/change-request-list/change-request-details/change-request-details.component';
import { ChangeRequestListService } from '../../common/navigation-tabs/change-request-list/change-request-list.service';
import { CrRejectPromptComponent } from '../../common/navigation-tabs/change-request-list/cr-reject-prompt/cr-reject-prompt.component';
import { BulkActionSubmissionComponent } from '../bulk-action-submission/bulk-action-submission.component';
import { HomePageService } from '../home-page.service';
import { PendingChangeRequestList } from './pending-change-request-list.model';
import { PendingChangeRequest } from './penging-change-request.model';

export enum BulkAction {
  Approve = 'Approve',
  Reject = 'Reject',
}

@Component({
  selector: 'app-change-requests-pending-approval',
  templateUrl: './change-requests-pending-approval.component.html',
  styleUrls: ['./change-requests-pending-approval.component.scss'],
})
export class ChangeRequestsPendingApprovalComponent implements OnInit, OnDestroy {
  @Input() portfolioId: number;

  firstFiveItems = 5;
  firstFiveCrs: PendingChangeRequest[];
  crs: PendingChangeRequest[];
  actions: any[] = [];
  canDisplayList: boolean;
  numberOfCrs = 0;
  selectedAction: BulkAction;
  lastSelected = null;
  isShiftKeyPressed = false;
  showMoreActive = false;

  displayedColumns: string[] = [
    'select',
    'code',
    'title',
    'changeNoticeTitle',
    'approver',
    'daysOutstanding',
    'actions',
  ];

  availableActions: any[] = [
    {
      label: 'general.approve',
      icon: '/assets/icons/icon-green-check-circle.svg',
    },
    { label: 'general.reject', icon: '/assets/icons/icon-red-cancel.svg' },
  ];
  private subscription = new Subscription();

  selection = new SelectionModel<PendingChangeRequest>(true, []);

  constructor(
    private router: Router,
    private dialog: MatLegacyDialog,
    private changeRequestListService: ChangeRequestListService,
    private homePageService: HomePageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.availableActions = this.availableActions.map((x) => ({
      ...x,
      label: this.translateService.instant(x.label),
    }));
    this.actions = [
      {
        icon: (x) =>
          x.canApprove ? '/assets/icons/icon-green-check-circle.svg' : '/assets/icons/icon-gray-check-circle.svg',
        label: 'changeRequest.approveItem',
        callBack: (x) => this.approve(x.changeNoticeId),
        isDisabled: (x) => !x.canApprove,
        isSvg: true,
      },
      {
        icon: (x) => (x.canReject ? '/assets/icons/icon-red-cancel.svg' : '/assets/icons/icon-grey-cancel.svg'),
        label: 'general.rejectItem',
        callBack: (x) => this.reject(x.changeNoticeId),
        isDisabled: (x) => !x.canReject,
        isSvg: true,
      },
      {
        icon: () => '/assets/icons/icon-info.svg',
        label: 'changeRequest.viewDetails',
        callBack: (x) => this.viewDetails(x.changeNoticeId, x.canReject, x.canApprove, x.projectId),
        isDisabled: (x) => false,
        isSvg: true,
      },
      {
        icon: (x) => (x.canCancel ? '/assets/icons/icon-black-cancel.svg' : '/assets/icons/icon-grey-cancel.svg'),
        label: 'changeRequest.cancelSelectedItems',
        callBack: (x) => this.cancel(x.changeNoticeId),
        isDisabled: (x) => !x.canCancel,
        isSvg: true,
      },
    ];
    const crSub = this.homePageService
      .getUserChangeRequestsPendingApproval(this.portfolioId)
      .subscribe((pendingCrs) => {
        this.initCrs(pendingCrs);
      });
    this.subscription.add(crSub);
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }

  initCrs({ pendingChangeNoticeList, canDisplayList }) {
    this.canDisplayList = canDisplayList;
    this.crs = pendingChangeNoticeList;
    this.numberOfCrs = this.crs.length;
    if (this.crs.length > this.firstFiveItems) {
      this.firstFiveCrs = this.crs.slice(0, 5);
      return;
    }

    this.firstFiveCrs = this.crs;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.firstFiveCrs.length;
    return numSelected === numRows;
  }

  masterToggle() {
    debugger;
    this.isAllSelected() ? this.selection.clear() : this.firstFiveCrs.forEach((row) => this.selection.select(row));
  }

  navigateTo(projectId: number, fhi: number, packageId: number): void {
    this.router.navigate([
      'project-management/',
      projectId,
      packageId ? EntityTypes.package : EntityTypes.function,
      fhi,
      'detail',
    ]);
  }

  viewDetails(id: number, canReject: boolean, canApprove: boolean, projectId: number): void {
    const dialogRef = this.dialog.open(ChangeRequestDetailsComponent, {
      data: { id, canReject, canApprove, projectId },
      panelClass: 'orms-change-request-details',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((action?: BulkAction) => {
      switch (action) {
        case BulkAction.Approve:
          this.approve(id);
          break;
        case BulkAction.Reject:
          this.reject(id);
          break;
      }
    });
  }

  approve(id: number): void {
    const sub = this.changeRequestListService.approve(id).subscribe((changeRequestListItem) => {
      this.removeCrFromList(changeRequestListItem.changeNoticeId);
    });
    this.subscription.add(sub);
  }

  reject(changeNoticeId: number): void {
    const dialogRef = this.dialog.open(CrRejectPromptComponent, {
      data: { changeNoticeId },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((action?: 'rejected' | 'closed') => {
      switch (action) {
        case 'rejected':
          this.removeCrFromList(changeNoticeId);
          break;
        case 'closed':
          break;
      }
    });
  }

  submit() {
    const callback = () => {
      return this.homePageService.getUserChangeRequestsPendingApproval(this.portfolioId);
    };
    const dialogRef = this.dialog
      .open(BulkActionSubmissionComponent, {
        data: {
          requests: this.selection.selected,
          action: this.selectedAction,
          callback,
        },
        panelClass: 'orms-change-request-bulk',
        disableClose: true,
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((pendingCrs: PendingChangeRequestList) => {
        const selectedCrs = this.firstFiveCrs.filter((cr) => {
          if (this.selection.selected.some((x) => x.changeNoticeId === cr.changeNoticeId)) {
            this.selection.deselect(cr);
            return true;
          }
          return false;
        });
        this.initCrs(pendingCrs);

        if (this.showMoreActive) {
          this.showMore();
        }

        this.firstFiveCrs.forEach((cr) => {
          if (selectedCrs.some((x) => cr.changeNoticeId === x.changeNoticeId)) {
            this.selection.select(cr);
          }
        });
      });
  }

  onBulkChanged(action) {
    this.selectedAction = action.label;
  }

  cancel(id: number): void {
    const sub = this.changeRequestListService.cancel(id).subscribe(() => this.removeCrFromList(id));
    this.subscription.add(sub);
  }

  showMore(): void {
    this.firstFiveCrs = [...this.crs];
    this.crs = [];
    this.showMoreActive = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private removeCrFromList(crId: number): void {
    this.crs = this.crs.filter((c) => c.changeNoticeId !== crId);
    this.firstFiveCrs = this.firstFiveCrs.filter((c) => c.changeNoticeId !== crId);
    if (this.crs.length >= this.firstFiveItems) {
      this.firstFiveCrs = this.crs.slice(0, 5);
    }
  }

  ctrlSelection(event, itemIdCurrent) {
    if (!this.lastSelected) {
      this.lastSelected = itemIdCurrent;
      return;
    }
    if (this.isShiftKeyPressed) {
      var selectCurrentRow = false;

      this.firstFiveCrs.forEach((item) => {
        if (item?.changeNoticeId) {
          if (item.changeNoticeId === itemIdCurrent || item.changeNoticeId === this.lastSelected) {
            if (selectCurrentRow) {
              selectCurrentRow = !selectCurrentRow; //stop selection
            } else if (!selectCurrentRow) {
              selectCurrentRow = !selectCurrentRow; //start selection
            }
          }

          if (selectCurrentRow || item.changeNoticeId === itemIdCurrent || item.changeNoticeId === this.lastSelected) {
            const selected = this.firstFiveCrs.find((x) => x.changeNoticeId === item.changeNoticeId);
            if (!event.checked) {
              this.selection.deselect(selected);
            } else {
              this.selection.select(selected);
            }
          }
        }
      });
    }
    this.lastSelected = itemIdCurrent;
  }

  onCheckBoxChange(event, row) {
    let some = event ? this.selection.toggle(row) : null;
    return some;
  }

  @HostListener('document:keydown.shift', ['$event'])
  shiftDown(_) {
    this.isShiftKeyPressed = true;
  }

  @HostListener('document:keyup.shift', ['$event'])
  shiftUp(_) {
    this.isShiftKeyPressed = false;
  }
}
