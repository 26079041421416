import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { HistoryData } from '../../history/history-data.model';
import { HistoryDataService } from '../../history/history-data.service';

@Component({
  selector: 'app-package-history',
  templateUrl: './package-history.component.html',
  styleUrls: ['./package-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageHistoryComponent implements OnInit {
  historyData$: Observable<HistoryData[]>;
  entityType = EntityTypes.package;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private historyService: HistoryDataService,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.historyData$ = this.store$.select(routerSelectors.getIdSelector).pipe(
      filter((id) => !!id),
      tap(() => this.loading$.next(true)),
      switchMap((id) => this.historyService.getPackageHistory(id)),
      tap(() => this.loading$.next(false)),
    );
  }
}
