import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import {
  LegacyDateAdapter,
  MAT_LEGACY_DATE_FORMATS,
  MAT_LEGACY_DATE_LOCALE,
  MatLegacyNativeDateModule,
} from '@angular/material/legacy-core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbAllModule } from '@syncfusion/ej2-angular-navigations';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { StatusBasedValuePipe } from '../project-management/common/status-based-value.pipe';
import { NumberStatusPipe } from '../project-management/common/status-number-value.pipe';
import { StatusPercentPipe } from '../project-management/common/status-percent-value.pipe';
import { FormatHistoryValuePipe } from '../project-management/history/format-history-value.pipe';
import { FromCamelCasePipe } from '../project-management/history/from-camel-case.pipe';
import { HistoryChangeSummaryPipe } from '../project-management/history/history-change-summary.pipe';
import { NodeIconTypePipe } from '../project-management/tree-navigation/node-icon.pipe';
import { BooleanSelectComponent } from './boolean-select/boolean-select.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ConfirmationDialogueComponent } from './confirmation-dialogue/confirmation-dialogue.component';
import { CrWarningComponent } from './cr-warning/cr-warning.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { DatepickerActionsComponent } from './datepicker-actions/datepicker-actions.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DayPipe } from './day-pipe/day.pipe';
import { PipeDebugPipe } from './debug.pipe';
import { DiffPipe } from './diff-pipe/diff.pipe';
import { EditableTextboxComponent } from './editable-textbox/editable-textbox.component';
import { ErrorBoxComponent } from './error-box/error-box.component';
import { FavoriteIconComponent } from './favorite-icon/favorite-icon.component';
import { FirstLetterUppercasePipe } from './first-letter-uppercase-pipe/first-letter-uppercase.pipe';
import { HasRoleDirective } from './has-role/has-role.directive';
import { HealthIconComponent } from './health-icon/health-icon.component';
import { IncludesPipe } from './includes-pipe/includes.pipe';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { IsDateInRangePipe } from './is-date-in-range-pipe/is-date-in-range.pipe';
import { LabelWithChangeTooltipComponent } from './label-with-change-tooltip/label-with-change-tooltip.component';
import { LastVisitedPipe } from './last-visited/last-visited.pipe';
import { ActionMenuComponent } from './menu/action-menu.component';
import { MultiStepFooterComponent } from './multi-step-footer/multi-step-footer.component';
import { MultiselectGroupedComponent } from './multiselect-grouped/multiselect-grouped.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { NavigateToHierarchyPipe } from './navigate-to-hierarchy-pipe/navigate-to-hierarchy.pipe';
import { NumericFilterComponent } from './numeric-filter/numeric-filter.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ResizeColumnDirective } from './resize-columns/resize-column.directive';
import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';
import { SearchableInputComponent } from './searchable-input/searchable-input.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StaticLabelComponent } from './static-label/static-label.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { StatusComponent } from './status/status.component';
import { StatusCodePipe } from './status/status.pipe';
import { StripZeroTimeFromDatePipe } from './strip-zero-time-from-date-pipe/strip-zero-time-from-date.pipe';
import { TagListComponent } from './tag-list/tag-list.component';
import { ToastComponent } from './toast/toast.component';
import { TrimPipe } from './trim-pipe/trim.pipe';
import { UserDropdownMatComponent } from './user-dropdown-mat/user-dropdown-mat.component';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { WarningIconComponent } from './warning-icon/warning-icon.component';
import { WizardComponent } from './wizard/wizard.component';

export const ORMS_DATE_FORMATS = {
  parse: {
    dateInput: 'D MMM YYYY',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'mmmm-yyyy',
  },
};
@NgModule({
  declarations: [
    PipeDebugPipe,
    FavoriteIconComponent,
    StaticLabelComponent,
    EditableTextboxComponent,
    UserDropdownComponent,
    EllipsisPipe,
    HealthIconComponent,
    DatepickerActionsComponent,
    DayPipe,
    DiffPipe,
    LastVisitedPipe,
    TrimPipe,
    SpinnerComponent,
    ToastComponent,
    CrWarningComponent,
    ResizeColumnDirective,
    LabelWithChangeTooltipComponent,
    StatusChipComponent,
    StatusComponent,
    TagListComponent,
    SearchableInputComponent,
    SearchableDropdownComponent,
    ConfirmationDialogueComponent,
    ErrorBoxComponent,
    UserDropdownMatComponent,
    ActionMenuComponent,
    BreadcrumbsComponent,
    StatusCodePipe,
    HistoryChangeSummaryPipe,
    FromCamelCasePipe,
    FormatHistoryValuePipe,
    StatusBasedValuePipe,
    StatusPercentPipe,
    NumberStatusPipe,
    NodeIconTypePipe,
    MultiselectComponent,
    MultiselectGroupedComponent,
    MultiStepFooterComponent,
    WizardComponent,
    DatepickerComponent,
    CheckboxComponent,
    BooleanSelectComponent,
    HasRoleDirective,
    IncludesPipe,
    NumericFilterComponent,
    DateFilterComponent,
    NavigateToHierarchyPipe,
    InputDialogComponent,
    WarningIconComponent,
    IsDateInRangePipe,
    FirstLetterUppercasePipe,
    StripZeroTimeFromDatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacySelectModule,
    MatLegacyAutocompleteModule,
    MatLegacyInputModule,
    TranslateModule,
    MatDatepickerModule,
    MatLegacyProgressSpinnerModule,
    NgSelectModule,
    MatLegacyTooltipModule,
    MatLegacyChipsModule,
    MatLegacyDialogModule,
    MatLegacyListModule,
    MatLegacyCardModule,
    MatLegacyProgressBarModule,
    MatLegacyTableModule,
    TableVirtualScrollModule,
    MatSortModule,
    ScrollingModule,
    MatLegacyMenuModule,
    ClipboardModule,
    MatLegacyCheckboxModule,
    BreadcrumbAllModule,
    MatLegacyTabsModule,
  ],
  exports: [
    PipeDebugPipe,
    CommonModule,
    FavoriteIconComponent,
    StaticLabelComponent,
    EditableTextboxComponent,
    UserDropdownComponent,
    EllipsisPipe,
    HealthIconComponent,
    DragDropModule,
    DayPipe,
    DiffPipe,
    LastVisitedPipe,
    TrimPipe,
    SpinnerComponent,
    ToastComponent,
    CrWarningComponent,
    ResizeColumnDirective,
    LabelWithChangeTooltipComponent,
    StatusChipComponent,
    StatusComponent,
    TagListComponent,
    MatLegacyCheckboxModule,
    SearchableInputComponent,
    SearchableDropdownComponent,
    ConfirmationDialogueComponent,
    ErrorBoxComponent,
    MatLegacyListModule,
    MatIconModule,
    MatLegacyInputModule,
    MatLegacyMenuModule,
    MatLegacyButtonModule,
    MatLegacyAutocompleteModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacySelectModule,
    MatLegacyTooltipModule,
    MatLegacyDialogModule,
    MatDatepickerModule,
    MatLegacyNativeDateModule,
    MatExpansionModule,
    NgSelectModule,
    MatLegacyCardModule,
    MatLegacyProgressBarModule,
    UserDropdownMatComponent,
    MatLegacyTableModule,
    TableVirtualScrollModule,
    MatSortModule,
    ScrollingModule,
    ActionMenuComponent,
    ClipboardModule,
    BreadcrumbsComponent,
    StatusCodePipe,
    HistoryChangeSummaryPipe,
    FromCamelCasePipe,
    FormatHistoryValuePipe,
    StatusBasedValuePipe,
    StatusPercentPipe,
    NumberStatusPipe,
    NodeIconTypePipe,
    MultiselectComponent,
    MultiselectGroupedComponent,
    MultiStepFooterComponent,
    MatLegacyTabsModule,
    WizardComponent,
    DatepickerComponent,
    CheckboxComponent,
    BooleanSelectComponent,
    HasRoleDirective,
    IncludesPipe,
    NumericFilterComponent,
    DateFilterComponent,
    NavigateToHierarchyPipe,
    InputDialogComponent,
    WarningIconComponent,
    IsDateInRangePipe,
    FirstLetterUppercasePipe,
    StripZeroTimeFromDatePipe,
  ],
  providers: [
    { provide: MAT_LEGACY_DATE_FORMATS, useValue: ORMS_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: LegacyDateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_LEGACY_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    PipeDebugPipe,
    DatePipe,
    DecimalPipe,
    NumberStatusPipe,
    StatusBasedValuePipe,
    PercentPipe,
    StatusPercentPipe,
    FromCamelCasePipe,
    FormatHistoryValuePipe,
    HasRoleDirective,
  ],
})
export class SharedControlsModule {}
