import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { get, isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';

export type ShadowInputError = {
  [key in EntityTypes]: {
    [key: string]: HttpErrorResponse;
  };
};

@Injectable({ providedIn: 'root' })
export class ShadowInputValidationService {
  private errors = new BehaviorSubject<ShadowInputError>({
    project: {},
    function: {},
    subFunction: {},
    package: {},
    deliverable: {},
  });

  addError(entityType: EntityTypes, id: string | number, key: string, error: HttpErrorResponse): void {
    const errors = this.errors.value;
    this.errors.next({
      ...errors,
      [entityType]: {
        ...errors[entityType],
        [id]: {
          ...(get(errors, [entityType, id]) || {}),
          [key]: error.error,
        },
      },
    });
  }

  clearError(entityType: EntityTypes, id: string | number, key: string): void {
    const errors = this.errors.value;
    const entityTypeErrors = { ...errors[entityType] };
    const errorToClear = get(entityTypeErrors, [id, key]);
    if (errorToClear) {
      delete entityTypeErrors[id][key];
      if (isEmpty(entityTypeErrors[id])) {
        delete entityTypeErrors[id];
      }
    }
    this.errors.next({
      ...errors,
      [entityType]: entityTypeErrors,
    });
  }

  getFieldValidation(entityType: EntityTypes, id: string | number, key: string): Observable<string | undefined> {
    return this.errors.pipe(map((errors) => get(errors, [entityType, id, key])));
  }

  watchServerValidation(
    formGroup: UntypedFormGroup,
    entityType: EntityTypes,
    entityId: string | number,
  ): Observable<{ name: string; error: string | undefined }> {
    const serverValidators = Object.keys(formGroup.controls).map((controlName) => {
      return this.getFieldValidation(entityType, entityId, controlName).pipe(
        map((error) => ({ name: controlName, error })),
      );
    });
    return merge(...serverValidators);
  }
}
