import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { TreeNode } from '../../tree-structure.model';
import { TreeActionsService } from '../tree-actions.service';

@Component({
  selector: 'app-tree-action-add',
  templateUrl: './tree-action-add.component.html',
  styleUrls: ['./tree-action-add.component.scss'],
})
export class TreeActionAddComponent implements OnInit, OnDestroy {
  submissionInProgress = false;
  node = { title: '' };
  @ViewChild('title', { static: false }) title: ElementRef;
  dialogueSub: Subscription;

  constructor(
    private treeActionsService: TreeActionsService,
    public dialogRef: MatLegacyDialogRef<TreeActionAddComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      functionalHierarchyId: number;
      title: string;
      label: string;
      isPackage: boolean;
    },
  ) {}

  ngOnInit(): void {
    this.dialogueSub = this.dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        if (this.node.title && !this.submissionInProgress) {
          this.addItem();
        } else {
          this.dialogRef.close();
        }
      });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.shiftKey && event.key === 'Enter') {
      this.addAndAnotherItem();
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      this.addItem();
    }

    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  addItem(): void {
    this.submissionInProgress = true;
    this.treeActionsService
      .addTreeNode(this.data.functionalHierarchyId, this.node.title)
      .pipe(finalize(() => (this.submissionInProgress = false)))
      .subscribe((x: TreeNode) => this.dialogRef.close(x.functionalHierarchyId));
  }

  addAndAnotherItem(): void {
    this.submissionInProgress = true;
    this.treeActionsService
      .addTreeNode(this.data.functionalHierarchyId, this.node.title)
      .pipe(finalize(() => (this.submissionInProgress = false)))
      .subscribe(() => {
        this.node = { title: '' };
        this.title.nativeElement.focus();
      });
  }

  ngOnDestroy(): void {
    this.dialogueSub?.unsubscribe();
  }
}
