import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { clearProjectRelatedData } from 'src/app/root-store/root-store.actions';
import * as deliverablesListActions from './deliverables-list.actions';
import { deleteDeliverableSuccess } from './deliverables-list.actions';

// In Ready the deliverables displayed at a particular level of the hierarchy tree are all deliverables
// inherited from the node's descendants. The Ready API has an end point that returns all the relevant
// deliverables for a given parent node, it returns the full deliverable list, but to avoid storing duplicate
// copies of the returned deliverables the node level store just needs to store the deliverables Ids.
// The deliverables themselves will be sored under a deliverables list state.

// The FHDeliverableIds is designed to hold the lists of deliverable Ids per hierarchy node.
// Because we need to support "addedDeliverable" we need to store the package Id's under a
// node so that we can determine which lists to add the added deliverable id too.
export interface FHDeliverableIds {
  fhNodeId: number; // The functional hierarchy branch node id of the request/response
  packageNodeIds: number[]; // The distinct package functional hierarchy node ids of the deliverables in the response
  deliverableIds: number[]; // Deliverables ids of all the descendants of the functional hierarchy branch node id of the request/response
  canAdd: boolean;
  canDelete: boolean;
  canMove: boolean;
}

export interface FHDeliverableIdsState extends EntityState<FHDeliverableIds> {}

export const fhDeliverableIdsAdapter: EntityAdapter<FHDeliverableIds> = createEntityAdapter<FHDeliverableIds>({
  selectId: (model) => model.fhNodeId,
});

const initialState: FHDeliverableIdsState = fhDeliverableIdsAdapter.getInitialState({});

export const fhDeliverableIdsReducer = createReducer(
  initialState,

  // getDeliverablesByFunctionalIdSuccess
  // When the API returns a list of deliverables for a parent node update the state with the list of deliverables Id's and Package node Ids
  on(deliverablesListActions.getDeliverablesByFunctionalIdSuccess, (state, { deliverablesListResponse }) => {
    const currentDeliverableIds = state.entities[deliverablesListResponse.parentId]?.deliverableIds;
    const updatedDeliverableIds = deliverablesListResponse.deliverables.map((d) => d.deliverableId);

    if (currentDeliverableIds && updatedDeliverableIds) {
      const difference = currentDeliverableIds
        .filter((x) => !updatedDeliverableIds.includes(x))
        .concat(updatedDeliverableIds.filter((x) => !currentDeliverableIds.includes(x)));

      if (difference.length == 0) {
        return state;
      }
    }

    const entity: FHDeliverableIds = {
      fhNodeId: deliverablesListResponse.parentId,
      packageNodeIds: [...new Set(deliverablesListResponse.deliverables.map((d) => d.packageFunctionalHierarchyId))],
      deliverableIds: deliverablesListResponse.deliverables.map((d) => d.deliverableId),
      canAdd: deliverablesListResponse.canAdd,
      canDelete: deliverablesListResponse.canDelete,
      canMove: deliverablesListResponse.canMove,
    };
    return fhDeliverableIdsAdapter.upsertOne(entity, state);
  }),

  // updateDeliverableSuccess
  // NOTE: We are just maintaining the list of deliverable Ids so we don't have to do anything for updateDeliverableSuccess

  // addDeliverableSuccess
  // NOTE: After a deliverable is added getDeliverablesByFunctionalId is called, thus we don't respond
  // to the addDeliverableSuccess because the getDeliverablesByFunctionalIdSuccess action is on it's way

  // deleteDeliverableSuccess
  // Remove the deliverable Id from any fh level list in which it appears
  on(deleteDeliverableSuccess, (state, { deliverableId }) => {
    let updatedState = state;
    Object.entries(state.entities)
      .filter(([, v]) => v.deliverableIds.includes(deliverableId))
      .forEach(([, entity]) => {
        const fhdids = { ...entity, deliverableIds: entity.deliverableIds.filter((x) => x !== deliverableId) };
        updatedState = fhDeliverableIdsAdapter.upsertOne(fhdids, updatedState);
      });
    return {
      ...updatedState,
    };
  }),

  on(clearProjectRelatedData, () => {
    return fhDeliverableIdsAdapter.getInitialState();
  }),
);

const { selectEntities } = fhDeliverableIdsAdapter.getSelectors();
export const selectDeliverablesSummaryEntities = selectEntities;
