import { isNumber } from 'lodash-es';
import {
  SubmitChangeRequestModel,
  SubmitCRDeliverableModel,
  SubmitCRPackageModel,
  SubmitMoveRequestModel,
} from 'src/app/common-models/submit-change-request.model';
import { isDifferentDay } from 'src/app/shared/is-different-day/is-different-day';
import { ChangeRequestDeliverable, ChangeRequestPackage } from '../change-request-cart-state/cart-item.model';
import { FullChangeRequestModel, FullMoveRequestModel } from './full-change-request';

export const mapCRForSubmission = (changeRequest: FullChangeRequestModel): SubmitChangeRequestModel => {
  const { deliverables, packages, contextElementIds, title, description } = changeRequest;
  return {
    title,
    description,
    functionId: contextElementIds[0],
    packages: mapCRPackagesForSubmission(packages),
    deliverables: mapCRDeliverablesForSubmission(deliverables),
  };
};

export const mapMRForSubmission = (moveRequest: FullMoveRequestModel): SubmitMoveRequestModel => {
  const { deliverables, title, description } = moveRequest;
  return {
    title,
    reason: description,
    payload: deliverables.map((del) => ({
      deliverableId: del.deliverableId,
      destinationPackageFunctionalHierarchyId: del.newPackageFunctionalHierarchyId,
    })),
  };
};

export const mapCRPackagesForSubmission = (crPackages: ChangeRequestPackage[]): SubmitCRPackageModel[] => {
  return crPackages.map((crPackage) => {
    const {
      functionalHierarchyId,
      newPlanStartDate,
      newPlanEndDate,
      newForecastEndDate,
      oldPlanStartDate,
      oldPlanEndDate,
      oldForecastEndDate,
    } = crPackage;
    return {
      functionalHierarchyId,
      planStartDate: isDifferentDay(newPlanStartDate, oldPlanStartDate) ? newPlanStartDate : null,
      planEndDate: isDifferentDay(newPlanEndDate, oldPlanEndDate) ? newPlanEndDate : null,
      forecastEndDate: isDifferentDay(newForecastEndDate, oldForecastEndDate) ? newForecastEndDate : null,
    };
  });
};

export const mapCRDeliverablesForSubmission = (
  crDeliverables: ChangeRequestDeliverable[],
): SubmitCRDeliverableModel[] => {
  return crDeliverables.map((crDeliverable) => {
    const {
      packageFunctionalHierarchyId,
      request,
      title,
      deliverableId,
      documentNumber,
      newHours,
      newCost,
      newCurveProfileCode,
      progressWorkflowId,
    } = crDeliverable;
    return {
      packageFunctionalHierarchyId,
      request,
      title,
      deliverableId: isNumber(deliverableId) ? deliverableId : null,
      documentNumber,
      progressWorkflowId,
      hours: newHours,
      cost: newCost,
      curveProfileCode: newCurveProfileCode,
    };
  });
};
