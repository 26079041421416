<div class="tree-actions">
  <app-spinner *ngIf="excelLoading" [absoluteOverlay]="true"></app-spinner>
  <div class="toolbar">
    <span class="header">{{ 'treeMenu.Hierarchy' | translate }}</span>
    <button
      mat-icon-button
      #treeMenubutton="matMenuTrigger"
      [ngClass]="treeMenubutton.menuOpen ? 'tree-actions-button tree-menu-open' : 'tree-actions-button'"
      [matMenuTriggerFor]="treeMenu"
      [disabled]="treeData === null"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  <mat-menu #treeMenu="matMenu" class="tree-menu orms-menu">
    <button mat-menu-item [matMenuTriggerFor]="expandMenu" class="tree-menu-item">
      <span class="icon-container">
        <img
          class="tree-menu-icon"
          src="/assets/icons/icon-expand-collapse.svg"
          alt="{{ 'treeMenu.ExpandCollapse' | translate }}"
        />
      </span>
      {{ 'treeMenu.ExpandCollapse' | translate }}
      <mat-menu #expandMenu="matMenu" class="tree-menu orms-menu">
        <button mat-menu-item (click)="expandAll()" class="tree-menu-item">
          <span class="icon-container">
            <img
              class="tree-menu-icon"
              src="/assets/icons/icon-expand.svg"
              alt="{{ 'treeMenu.ExpandAll' | translate }}"
            />
          </span>
          {{ 'treeMenu.ExpandAll' | translate }}
        </button>
        <button mat-menu-item (click)="collapseAll()" class="tree-menu-item">
          <span class="icon-container">
            <img
              class="tree-menu-icon"
              src="/assets/icons/icon-collapse.svg"
              alt="{{ 'treeMenu.CollapseAll' | translate }}"
            />
          </span>
          {{ 'treeMenu.CollapseAll' | translate }}
        </button>
      </mat-menu>
    </button>
    <button mat-menu-item (click)="exportExcel()" class="tree-menu-item">
      <span class="icon-container">
        <img class="tree-menu-icon" src="/assets/icons/icon-excel.svg" alt="{{ 'treeMenu.ExportExcel' | translate }}" />
      </span>
      {{ 'treeMenu.ExportExcel' | translate }}
    </button>
    <button mat-menu-item (click)="resetColumns()" class="tree-menu-item">
      <span class="icon-container">
        <img
          class="tree-menu-icon"
          src="/assets/icons/icon-reset.svg"
          alt="{{ 'treeMenu.ResetColumnLayout' | translate }}"
        />
      </span>
      {{ 'treeMenu.ResetColumnLayout' | translate }}
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="includeCancelledItems()" class="tree-menu-item">
      <span class="icon-container">
        <img
          class="tree-menu-icon"
          src="/assets/icons/icon-grey-cancel.secondary-color.svg"
          alt="{{ 'treeMenu.IncludeCancelledItems' | translate }}"
        />
      </span>
      {{ 'treeMenu.IncludeCancelledItems' | translate }}
      <img *ngIf="areCancelledIncluded" class="tree-menu-icon-right" src="/assets/icons/icon-tickmark.svg" />
    </button>
    <mat-divider></mat-divider>
    <button
      *ngIf="(hasProjectManageRights$ | async) && !useImportFeatureFlag"
      mat-menu-item
      (click)="downloadProjectStructureTemplate()"
      class="tree-menu-item"
    >
      <span class="icon-container">
        <img
          class="tree-menu-icon"
          src="/assets/icons/icon-download.svg"
          alt="{{ 'treeMenu.DownloadTemplate' | translate }}"
        />
      </span>
      {{ 'treeMenu.DownloadTemplate' | translate }}
    </button>
    <button
      *ngIf="(hasProjectManageRights$ | async) && !useImportFeatureFlag"
      mat-menu-item
      (click)="openUploadStructureDialog()"
      class="tree-menu-item"
    >
      <span class="icon-container">
        <img
          class="tree-menu-icon"
          src="/assets/icons/icon-upload.svg"
          alt="{{ 'treeMenu.UploadStructure' | translate }}"
        />
      </span>
      {{ 'treeMenu.UploadStructure' | translate }}
    </button>
    <button
      *ngIf="(hasProjectManageRights$ | async) && useImportFeatureFlag"
      mat-menu-item
      (click)="openImportWizard()"
      class="tree-menu-item"
    >
      <span class="icon-container">
        <img class="tree-menu-icon" src="/assets/icons/icon-import.svg" alt="{{ 'treeMenu.Import' | translate }}" />
      </span>
      {{ 'treeMenu.Import' | translate }}
    </button>
  </mat-menu>
</div>
<div class="grid-strech" id="treegrid-stretch-container">
  <ejs-treegrid
    [style.display]="treeData && treeData.length ? 'initial' : 'none'"
    #treegrid
    class="tree-grid-obj"
    [dataSource]="treeData"
    [treeColumnIndex]="0"
    parentIdMapping="parentId"
    idMapping="functionalHierarchyId"
    (rowSelected)="rowSelected$.next($event)"
    height="100%"
    allowFiltering="true"
    [filterSettings]="filterSettings"
    allowReordering="true"
    showColumnMenu="true"
    enableCollapseAll="true"
    [enableImmutableMode]="true"
    [editSettings]="editSettings"
    [allowRowDragAndDrop]="true"
    [selectionSettings]="selectionOptions"
    [allowExcelExport]="true"
    [allowResizing]="true"
    [allowSorting]="true"
    (rowSelecting)="rowSelecting($event)"
    (actionBegin)="filterAction($event)"
    (dataBound)="onTreeDataBound()"
    (actionComplete)="onActionComplete($event)"
    (resizeStop)="onResizeStop()"
    (columnMenuOpen)="columnMenuOpen($event)"
    (rowDataBound)="rowDataBound($event)"
    (collapsing)="onCollapsing()"
    (collapsed)="onCollapsed()"
    (expanding)="onExpanding()"
    (expanded)="onExpanded()"
  >
    <e-columns>
      <e-column
        field="title"
        headerText="{{ 'treeHeader.title' | translate }}"
        textAlign="Left"
        [width]="titleWidth"
        [customAttributes]="customAttributes"
      >
        <ng-template #template let-data>
          <div class="title-column-container" [class.has-cr]="crAffectedIds.includes(data.functionalHierarchyId)">
            <span
              class="{{ data.typeCode }}"
              [ngClass]="{
                incorrect: !data.isPackageValid
              }"
              >icons</span
            >
            <div
              class="cr-icon"
              [matTooltip]="'changeRequest.submissionInProgress' | translate"
              matTooltipPosition="above"
              matTooltipClass="orms-tooltip"
            ></div>
            <span title="{{ data.title }}" class="title-display-text">
              {{ data.title }}
            </span>
          </div>
          <ng-container
            *ngIf="{
              permissions: isCurrentUserLevelOneFunctionManagerSubscription$ | async,
              rules: projectHierarchyRulesSubscription$ | async,
              portfolio: isCurrentUserAdminSubscription$ | async
            } as menu"
          >
            <app-tree-actions-menu
              *ngIf="menu.permissions && menu.rules && menu.portfolio"
              (nodeAdded)="selectRow($event)"
              (expandNode)="expandNode($event)"
              (collapseNode)="collapseNode($event)"
              [treeNode]="data"
              [treeData]="treeData"
              [projectId]="projectId"
              [isCurrentUserLevelOneManager]="menu.permissions.isCurrentUserL1Manager"
              [isCurrentUserProjectManager]="menu.permissions.isCurrentUserProjectManager"
              [isCurrentUserAdmin]="menu.portfolio.isAdmin"
              [hasChangeRequest]="crAffectedIds.includes(data.functionalHierarchyId)"
              [projectRules]="menu.rules"
            ></app-tree-actions-menu
          ></ng-container>
        </ng-template>
      </e-column>
      <e-column field="code" headerText="{{ 'treeHeader.code' | translate }}" textAlign="Left" [width]="codeWidth">
        <ng-template #template let-data>
          <span title="{{ data.code }}"> {{ data.code }} </span>
        </ng-template>
      </e-column>
      <e-column field="health" [visible]="false" [showInColumnChooser]="false"> </e-column>
      <e-column field="canDelete" [visible]="false" [showInColumnChooser]="false"></e-column>
      <e-column field="canCancel" [visible]="false" [showInColumnChooser]="false"></e-column>
      <e-column
        field="status"
        headerText="{{ 'treeHeader.status' | translate }}"
        textAlign="Left"
        [width]="statusWidth"
        [filter]="statusFilter"
        [allowSorting]="false"
      >
        <ng-template #template let-data>
          <app-status [status]="data.status" [health]="data.health"></app-status>
        </ng-template>
        <ng-template #filterItemTemplate let-data>{{ data.status | statusCode }}</ng-template>
      </e-column>
      <e-column
        field="manager"
        headerText="{{ 'treeHeader.responsible' | translate }}"
        textAlign="Left"
        [width]="responsibleWidth"
        [filter]="responsibleFilter"
        [allowSorting]="false"
      >
        <ng-template #filterItemTemplate let-data>{{ data.manager }}</ng-template>
      </e-column>
      <e-column
        field="plannedComplete"
        headerText="{{ 'treeHeader.planned' | translate }}"
        textAlign="Left"
        [width]="plannedWidth"
        type="number"
        [allowSorting]="false"
      >
        <ng-template #template let-data>{{ data.plannedComplete | statusPercent: data.status : '1.0-2' }}</ng-template>
      </e-column>
      <e-column
        field="actualComplete"
        headerText="{{ 'treeHeader.actual' | translate }}"
        textAlign="Left"
        [width]="actualWidth"
        format="0.##'%'"
        type="number"
        [allowSorting]="false"
      >
        <ng-template #template let-data>{{ data.actualComplete | statusPercent: data.status : '1.0-2' }}</ng-template>
      </e-column>
      <e-column
        field="spi"
        headerText="{{ 'treeHeader.spi' | translate }}"
        textAlign="Left"
        [width]="spiWidth"
        format="0.##"
        type="number"
        [filter]="dateFilter"
        [allowSorting]="false"
      >
        <ng-template #template let-data>{{ data.spi | statusNumber: data.status }}</ng-template>
      </e-column>
      <e-column
        field="startDate"
        headerText="{{ 'treeHeader.startDate' | translate }}"
        textAlign="Left"
        [width]="spiWidth"
        [format]="dateFormat"
        type="date"
        [filter]="dateFilter"
        [allowSorting]="true"
      >
        <ng-template #template let-data>{{
          data.startDate | date: dateFormat | statusBasedValue: data.status
        }}</ng-template>
      </e-column>
      <e-column
        field="endDate"
        headerText="{{ 'treeHeader.endDate' | translate }}"
        textAlign="Left"
        [width]="spiWidth"
        [format]="dateFormat"
        type="date"
        [allowSorting]="true"
      >
        <ng-template #template let-data>{{
          data.endDate | date: dateFormat | statusBasedValue: data.status
        }}</ng-template>
      </e-column>
      <e-column
        field="budgetHours"
        headerText="{{ 'treeHeader.hours' | translate }}"
        textAlign="Left"
        [width]="hoursWidth"
        format="N"
        type="number"
        [allowSorting]="false"
      >
        <ng-template #template let-data>{{
          data.budgetHours | statusNumber: data.status : '1.0-0' : 'en'
        }}</ng-template>
      </e-column>
      <e-column
        field="functionalHierarchyId"
        textAlign="Left"
        [isPrimaryKey]="true"
        [visible]="false"
        [showInColumnChooser]="false"
      >
        <ng-template #headerTemplate>{{ 'treeHeader.id' | translate }}</ng-template>
      </e-column>
    </e-columns>
  </ejs-treegrid>
  <p class="no-records" *ngIf="treeData && !treeData.length && !loading">
    {{ 'general.noRecordsToDisplay' | translate }}
  </p>
  <app-spinner *ngIf="loading"></app-spinner>
</div>
