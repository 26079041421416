import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { ProjectImportMode, ProjectImportPayload, ProjectTemplate } from '../project-import/project-import.models';

export interface MaintainTemplateDto {
  projectId: number;
  importRequestId: string;
  templateName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectImportService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
  ) {}

  downloadProjectImportTemplate(
    projectId: number,
    includeData: boolean,
    mode: ProjectImportMode,
  ): Observable<HttpResponse<Blob>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectImportDownloadTemplate;
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('includeData', includeData.toString())
      .set('mode', mode);

    return this.httpClient.get<Blob>(baseUrl + endpoint, {
      params,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  analyseProjectImport(
    projectId: number,
    file: File,
    includeUnchangedData: boolean,
    mode: ProjectImportMode,
  ): Observable<ProjectImportPayload> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpointImport = this.appConfigService.settings.api.endpoints.projectImportAnalyse;
    const endpointTemplate = this.appConfigService.settings.api.endpoints.projectTemplateAnalyse;
    const params = new HttpParams().set('includeUnchangedData', includeUnchangedData.toString());

    const formData = new FormData();
    formData.append('UploadedFile', file);
    formData.append('projectId', projectId.toString());

    return this.httpClient.post<ProjectImportPayload>(
      baseUrl + (mode === ProjectImportMode.Import ? endpointImport : endpointTemplate),
      formData,
      { params },
    );
  }

  executeProjectImport(payload: ProjectImportPayload): Observable<HttpResponse<null>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectImportStageAndExecute;

    return this.httpClient.post<null>(baseUrl + endpoint, payload, {
      observe: 'response',
    });
  }

  getProjectTemplates(projectId: number): Observable<ProjectTemplate[]> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectTemplatesList;
    const params = new HttpParams().set('projectId', projectId.toString());
    return this.httpClient.get<ProjectTemplate[]>(baseUrl + endpoint, { params });
  }

  createProjectTemplate(payload: ProjectImportPayload): Observable<HttpResponse<null>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectTemplatesCreate;

    return this.httpClient.post<null>(baseUrl + endpoint, payload, {
      observe: 'response',
    });
  }

  applyProjectTemplate(projectId: number, templateId: string): Observable<HttpResponse<null>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectTemplatesApply;
    const payload: MaintainTemplateDto = { projectId, importRequestId: templateId, templateName: null };

    return this.httpClient.post<null>(baseUrl + endpoint, payload, {
      observe: 'response',
    });
  }

  deleteProjectTemplate(projectId: number, templateId: string): Observable<HttpResponse<null>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectTemplatesDelete;
    const payload: MaintainTemplateDto = { projectId, importRequestId: templateId, templateName: null };

    return this.httpClient.post<null>(baseUrl + endpoint, payload, {
      observe: 'response',
    });
  }

  downloadProjectTemplate(projectId: number, templateId: string): Observable<HttpResponse<Blob>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectTemplatesDownload;
    const params = new HttpParams().set('projectId', projectId.toString()).set('templateId', templateId);

    return this.httpClient.get<Blob>(baseUrl + endpoint, {
      params,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  renameProjectTemplate(projectId: number, templateId: string, templateName: string): Observable<HttpResponse<null>> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.projectTemplatesRename;
    const payload: MaintainTemplateDto = { projectId, importRequestId: templateId, templateName };

    return this.httpClient.post<null>(baseUrl + endpoint, payload, {
      observe: 'response',
    });
  }
}
