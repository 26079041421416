import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash-es';
import { Subscription, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CodeTypes, EntityTypes, NodeTypeMap } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { ConfirmationDialogueComponent } from 'src/app/shared-controls/confirmation-dialogue/confirmation-dialogue.component';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormUpdateConfig,
  watchControlsWithoutValidation,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { ShadowInputValidationService } from 'src/app/shared/services/shadow-input-validation.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';
import FeatureFlags from '../../../assets/feature-flags.json';
import {
  StatusCode,
  StatusCodeDetail,
  getStatusDetailIncludingHealthValues,
} from '../../shared-controls/status/statusCodesMap';
import { NodeHeaderService } from '../common/node-header-service';
import { NodeHeaderKeys, NodeHeaderModel } from '../common/node-header.model';
import * as deliverableActions from '../deliverable/deliverable-state/deliverable.actions';
import { TreeActionMovePackageCrComponent } from '../tree-navigation/tree-action-move-package-cr/tree-action-move-package-cr.component';
import { TreeActionMovePackageComponent } from '../tree-navigation/tree-action-move-package/tree-action-move-package.component';
import { TreeActionCrSubmitComponent } from '../tree-navigation/tree-actions-menu/tree-action-cr-submit/tree-action-cr-submit.component';
import { TreeActionDeleteSubmitComponent } from '../tree-navigation/tree-actions-menu/tree-action-delete-submit/tree-action-delete-submit.component';
import { TreeActionsService } from '../tree-navigation/tree-actions-menu/tree-actions.service';
import * as treeActions from '../tree-navigation/tree-state/tree.actions';
import { TreeSelectors } from '../tree-navigation/tree-state/tree.selectors';
import { TreeNode } from '../tree-navigation/tree-structure.model';
import { ShareComponent } from './share/share.component';
import { SharePackage, getExpirationDaysCount } from './share/share.model';
import { SharedPackageService } from './shared.service';

@Component({
  selector: 'app-node-header',
  templateUrl: './node-header.component.html',
  styleUrls: ['./node-header.component.scss'],
})
export class NodeHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entityId: number;
  @Input() entityType: EntityTypes;
  @Input() hasProjectManagementPermission: boolean;
  headerDetails: NodeHeaderModel;
  treeNode: TreeNode;
  nodeHeaderService: NodeHeaderService;
  projectId: number;
  expirationDaysCount: number;
  expiryTime: string;
  expiryMessage: string;
  betweenZeroAndThreeDays: boolean;
  statusDetails: StatusCodeDetail;
  form: UntypedFormGroup;
  entityTypes = EntityTypes;
  actions = [];
  sharedPackageFlag = FeatureFlags.sharePackage;
  sharePackage: SharePackage;

  private headerDetailsSubscription = new Subscription();
  private projectIdSubscription = new Subscription();
  private controlsValueSubscription = new Subscription();
  private serverValidatorsSubscription = new Subscription();
  private languageChangeSubscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private shadowInputValidationService: ShadowInputValidationService,
    private treeActionsService: TreeActionsService,
    public dialog: MatLegacyDialog,
    private sharedPackageService: SharedPackageService,
    private translationService: TranslateService,
    private accountInfoService: AccountInfoService,
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.languageChangeSubscription = this.translationService.onLangChange.subscribe((x) => {
      this.setExpiryMessage(this.expirationDaysCount);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('entityId' in changes) {
      this.headerDetailsSubscription.unsubscribe();
      this.headerDetailsSubscription = combineLatest([
        this.store$.select(
          TreeSelectors.getTreeNodeByFunctionalHierarchyId({
            functionalHierarchyId: this.entityId,
          }),
        ),
        this.store$.select(getProjectIdSelector),
      ])
        .pipe(
          take(1),
          tap(([treeNode, projectId]) => {
            this.projectId = projectId;
            if (!treeNode.title && this.entityId) {
              this.store$.dispatch(
                treeActions.updateProjectPartialData({
                  projectId: projectId,
                  functionalHierarchiesIds: [this.entityId],
                }),
              );
            }
          }),
        )
        .subscribe(([treeNode]) => {
          this.initializeViewValues(treeNode);
          this.fillForm(true);
        });

      this.store$
        .select(
          TreeSelectors.getTreeNodeByFunctionalHierarchyId({
            functionalHierarchyId: this.entityId,
          }),
        )
        .pipe(
          filter((x) => Object.keys(x).length !== 0),
          switchMap((treeNode) => {
            if (treeNode.managePermission && treeNode.typeCode === CodeTypes.Package) {
              return this.sharedPackageService.getLinkForPackage(this.projectId, this.entityId).pipe(
                map((sharePackage) => {
                  this.sharePackage = sharePackage.length
                    ? sharePackage[0]
                    : ({
                        users: [],
                        id: null,
                        enabled: null,
                        expirationDate: null,
                        createdAtUtc: null,
                        linkIdentifier: null,
                        packageId: null,
                        projectId: null,
                      } as SharePackage);
                  return treeNode;
                }),
              );
            }
            return of(treeNode);
          }),
        )
        .subscribe((treeNode) => {
          // If the user is switching quickly between nodes then this can be the retrieval for the prior change
          // in which case we no longer need it, because we've already moved to another node.
          if (treeNode.functionalHierarchyId === this.entityId) {
            this.initializeViewValues(treeNode);

            if (this.headerDetails.nodeId) {
              this.setControlWatchers();
              this.fillForm(false);
              disableFormFieldsBaseOnPermissions(this.form, this.isEditable.bind(this));
            }
          }
        });
    }

    if ('entityType' in changes) {
      this.setServerValidation();
    }
  }

  initializeViewValues(treeNode: TreeNode) {
    this.treeNode = treeNode;
    this.calculateExpirationDayCount();
    this.setExpiryMessage(this.expirationDaysCount);
    this.setActions(treeNode);
    this.headerDetails = NodeHeaderModel.FromTreeNode(treeNode);
    this.statusDetails = getStatusDetailIncludingHealthValues(this.headerDetails?.status, this.headerDetails?.health);
  }

  setActions(treeNode: TreeNode) {
    this.actions = [];

    if (treeNode.status !== undefined && treeNode.status !== StatusCode.Draft) {
      this.actions = [
        ...this.actions,
        {
          icon: () => (true ? 'share' : '/assets/icons/icon-gray-check-circle.svg'),
          label: 'share.title',
          callBack: () => this.share(),
          isDisabled: () => false,
          isSvg: false,
          subTitle: 'general.noActiveLinks',
        },
      ];
    }

    this.actions = [
      ...this.actions,
      {
        icon: () => (this.treeNode.canMove ? '/assets/icons/move.svg' : '/assets/icons/move-grey.svg'),
        label: 'general.move',
        callBack: (x) =>
          this.treeNode.underChangeControl
            ? this.movePackageCrAction(x.functionalHierarchyId, x.parentId)
            : this.movePackageAction(x.parentId),
        isDisabled: () => !this.treeNode.canMove,
        isSvg: true,
        itemGroupBorder: true,
      },
    ];

    // The "Cancel" menu item is a chameleon in that once an item has been cancelled it is transposed to "Reinstate"
    if (this.treeNode.status !== StatusCode.Cancelled) {
      this.actions = [
        ...this.actions,
        {
          icon: () =>
            this.treeNode.canCancel
              ? '/assets/icons/icon-grey-cancel.secondary-color.svg'
              : '/assets/icons/icon-grey-cancel.svg',
          label: 'treeItemMenu.Cancel',
          callBack: (x) => this.cancelAction(x.functionalHierarchyId, x.typeCode, x.title),
          isDisabled: () => !this.treeNode.canCancel,
          isSvg: true,
        },
      ];
    } else {
      // Reinstate
      this.actions = [
        ...this.actions,
        {
          icon: () =>
            this.hasProjectManagementPermission ? '/assets/icons/reinstate.svg' : '/assets/icons/reinstate-grey.svg',
          label: 'treeItemMenu.Reinstate',
          callBack: (x) => this.reinstateAction(x.functionalHierarchyId, x.parentId, x.typeCode),
          isDisabled: () => !this.hasProjectManagementPermission,
          isSvg: true,
        },
      ];
    }

    this.actions = [
      ...this.actions,
      {
        icon: () => (this.treeNode.canDelete ? '/assets/icons/icon-delete.svg' : '/assets/icons/icon-delete-grey.svg'),
        label: 'general.delete',
        callBack: (x) => this.deleteAction(x.functionalHierarchyId),
        isDisabled: () => !this.treeNode.canDelete,
        isSvg: true,
      },
    ];

    if (treeNode.typeCode === CodeTypes.Package) {
      this.actions.pop();
      const printAction = {
        icon: () => '/assets/icons/print.svg',
        label: 'general.print',
        callBack: (treeNode: TreeNode) =>
          window.open(`${window.origin}/#/print/package/${treeNode.packageId}`, '_blank'),
        isDisabled: () => false,
        isSvg: true,
        itemGroupBorder: true,
      };

      const deleteAction = {
        icon: () => (this.treeNode.canDelete ? '/assets/icons/icon-delete.svg' : '/assets/icons/icon-delete-grey.svg'),
        label: 'general.delete',
        callBack: (x) => this.deleteAction(x.functionalHierarchyId),
        isDisabled: () => !this.treeNode.canDelete,
        isSvg: true,
        itemGroupBorder: true,
      };

      this.actions = [...this.actions, printAction, deleteAction];
    }
  }

  calculateExpirationDayCount() {
    if (this.sharePackage?.expirationDate) {
      this.expirationDaysCount = getExpirationDaysCount(
        new Date(Date.now()),
        new Date(this.sharePackage?.expirationDate),
      );
      this.betweenZeroAndThreeDays = this.expirationDaysCount <= 3 && this.expirationDaysCount >= 0;
    } else {
      this.expirationDaysCount = null;
      this.betweenZeroAndThreeDays = null;
    }
  }

  setExpiryMessage(expirationDaysCount: number) {
    if (expirationDaysCount === 0) {
      this.expiryMessage = this.translationService.instant('share.sharedLinkExpires');
      this.expiryTime = this.translationService.instant('share.today');
    } else if (expirationDaysCount < 0) {
      this.expiryMessage = '';
      this.expiryTime = this.translationService.instant('share.expiredLink');
    } else if (expirationDaysCount === 1) {
      this.expiryMessage = this.translationService.instant('share.sharedLinkExpiresIn');
      this.expiryTime = this.translationService.instant('general.day');
    } else {
      this.expiryMessage = this.translationService.instant('share.sharedLinkExpiresIn');
      this.expiryTime = this.translationService.instant('general.days');
    }
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  toggleFavorite(addFavorite: boolean): void {
    this.store$.dispatch(
      addFavorite
        ? deliverableActions.addPackageToFavorites({
            functionalHierarchyPackageId: this.headerDetails.nodeId,
            projectId: this.projectId,
          })
        : deliverableActions.removePackageFromFavorites({
            functionalHierarchyPackageId: this.headerDetails.nodeId,
            projectId: this.projectId,
          }),
    );
  }

  updateProperty(key: string, value: any): void {
    const details = {
      oldTitle: null,
      newTitle: null,
      oldManagerId: null,
      newManager: null,
      oldCode: null,
      newCode: null,
    };
    details.oldCode = this.headerDetails.code;
    details.oldTitle = this.headerDetails.title;
    details.oldManagerId = this.headerDetails.managerId;

    details.newCode = this.form.get('code').value;
    details.newTitle = this.form.get('title').value;
    details.newManager = this.form.get('manager').value;

    const update: Update<TreeNode> = {
      id: this.entityId,
      changes: {},
    };

    if (details.newCode !== details.oldCode) {
      update.changes.code = details.newCode;
    }
    if (details.newTitle !== details.oldTitle) {
      update.changes.title = details.newTitle;
    }
    if (details?.newManager.userId !== details.oldManagerId) {
      update.changes.managerId = details.newManager.userId;
    }

    if (!Object.keys(update.changes).length) {
      return;
    }

    this.store$.dispatch(
      treeActions.updateNodeProperty({
        key,
        entityType: this.entityType,
        update: this.treeActionsService.mergeFunctionalHierarchyIdUpdates(update),
      }),
    );
  }

  share() {
    this.dialog
      .open(ShareComponent, {
        data: {
          projectId: this.projectId,
          entityUnderChangeTitle: this.form.get('title').value,
          share: this.sharePackage,
          packageId: this.entityId,
        },
        panelClass: 'orms-share-modal',
        autoFocus: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((share: any) => {
        this.sharePackage = share;
        this.calculateExpirationDayCount();
        this.setExpiryMessage(this.expirationDaysCount);
      });
  }

  private isEditable(name: string): boolean {
    if (!this.headerDetails || this.headerDetails.typeCode === CodeTypes.Project) {
      return false;
    }

    if (this.headerDetails.typeCode === CodeTypes.Package && this.headerDetails.status === StatusCode.Cancelled) {
      return false;
    }

    if (name === 'manager') {
      return (
        this.accountInfoService.hasRole(UmsRoles.PowerUser) || this.headerDetails?.editableFields?.includes('managerid')
      );
    }
    return this.headerDetails?.editableFields?.includes(name.toLocaleLowerCase());
  }

  private setServerValidation(): void {
    this.serverValidatorsSubscription.unsubscribe();

    this.serverValidatorsSubscription = this.shadowInputValidationService
      .watchServerValidation(this.form, this.entityType, this.entityId)
      .subscribe(({ name, error }) => {
        if (name === 'code') {
          this.form.get(name).setErrors(
            error
              ? {
                  serverError: error,
                }
              : { incorrect: true },
          );
        } else {
          this.form.get(name).setErrors(
            error
              ? {
                  serverError: error,
                }
              : null,
          );
        }
      });
  }

  private setControlWatchers(): void {
    this.controlsValueSubscription.unsubscribe();
    this.setErrors(NodeHeaderKeys.code, { incorrect: true });
    if (this.treeNode?.packageId) {
      this.form.controls[NodeHeaderKeys.code].setValidators([Validators.required]);
    }

    this.controlsValueSubscription = watchControlsWithoutValidation(this.form)
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(({ name, value }) => this.updateProperty(name, value));
  }

  setErrors(controlName, errors: ValidationErrors): void {
    this.form.controls[controlName].setErrors(errors);
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(),
      code: new UntypedFormControl(),
      manager: new UntypedFormControl(),
    });
  }

  private fillForm(forceOverwrite: boolean): void {
    if (!this.headerDetails) {
      this.form.reset(
        {
          title: null,
          code: null,
          manager: null,
        },
        { ...shadowFormUpdateConfig },
      );
      return;
    }

    const { title, code, managerId, managerName } = this.headerDetails;
    this.form.patchValue(
      {
        title: this.getActualInputValue('title-input', 'title', title, forceOverwrite),
        code: this.getActualInputValue('code-input', 'code', code, forceOverwrite),
        manager: {
          userId: managerId,
          displayName: managerName,
        },
      },
      { ...shadowFormUpdateConfig },
    );
  }

  private getActualInputValue(
    elementId: string,
    formPropertyId: string,
    serverValue: string,
    forceOverwrite: boolean,
  ): string {
    const activeElementId = document.activeElement.id;
    return activeElementId === elementId && !isEmpty(this.form.get(formPropertyId).value) && !forceOverwrite
      ? this.form.get(formPropertyId).value
      : serverValue;
  }

  movePackageAction(parentFunctionalHierarchyId: number): void {
    this.dialog.open(TreeActionMovePackageComponent, {
      data: {
        packageId: this.treeNode.packageId,
        packageName: this.treeNode.title,
        parentFunctionalHierarchyId: parentFunctionalHierarchyId,
      },
      panelClass: 'orms-move-package-dialogue',
      disableClose: true,
    });
  }

  movePackageCrAction(packageFunctionalHierarchyId: number, parentFunctionalHierarchyId: number): void {
    this.dialog.open(TreeActionMovePackageCrComponent, {
      data: {
        packageFunctionalHierarchyId,
        parentFunctionalHierarchyId,
        packageName: this.treeNode.title,
      },
      panelClass: 'orms-move-package-cr-dialogue',
      disableClose: false,
    });
  }

  private cancelAction(functionalHierarchyId: number, typeCode: string, title: string): void {
    const entityType = NodeTypeMap.get(typeCode);
    this.dialog.open(TreeActionCrSubmitComponent, {
      data: { functionalHierarchyId, entityType, title },
      panelClass: 'orms-cr-cancellation-form',
      disableClose: true,
    });
  }

  private reinstateAction(functionalHierarchyId: number, parentId: number, typeCode: string): void {
    this.store$
      .select(TreeSelectors.getTreeNodeByFunctionalHierarchyId({ functionalHierarchyId: parentId }))
      .pipe(take(1))
      .subscribe((parentNode) => {
        const title = 'treeItemMenu.ReinstateConfirmationTitle';
        const message = `${this.translationService.instant(
          parentNode.status === StatusCode.Cancelled
            ? 'treeItemMenu.ReinstateWithParentConfirmationMessage'
            : 'treeItemMenu.ReinstateConfirmationMessage',
        )}?`;
        const successMessage = `${this.translationService.instant(
          this.getTranslationByTypeCode(typeCode),
        )} ${this.translationService.instant('treeItemMenu.ReinstateSuccess')}`;

        const callBack = () =>
          this.treeActionsService.reinstateFunctionOrPackage(functionalHierarchyId, successMessage);
        this.dialog.open(ConfirmationDialogueComponent, {
          data: { title, message, callBack },
          panelClass: 'orms-node-dialogue',
          disableClose: true,
        });
      });
  }

  private getTranslationByTypeCode(code: string): string {
    const itemMenuMap = new Map<string, string>([
      ['PR', 'treeItemMenu.Project'],
      ['FN', 'treeItemMenu.Function'],
      ['PK', 'treeItemMenu.Package'],
      ['SF', 'treeItemMenu.SubFunction'],
    ]);
    return itemMenuMap.get(code);
  }

  private deleteAction(functionalHierarchyId: number): void {
    this.dialog.open(TreeActionDeleteSubmitComponent, {
      data: { functionalHierarchyId, status: this.treeNode.status },
      panelClass: 'orms-tree-dialogue',
      disableClose: true,
    });
  }

  ngOnDestroy(): void {
    this.headerDetailsSubscription?.unsubscribe();
    this.projectIdSubscription?.unsubscribe();
    this.serverValidatorsSubscription?.unsubscribe();
    this.controlsValueSubscription?.unsubscribe();
    this.languageChangeSubscription?.unsubscribe();
  }
}
