import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { getIncludeCancelledItems } from 'src/app/root-store/root-store.selector';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DeliverableListResponse, DeliverablesService } from '../deliverables.service';
import * as deliverablesListActions from './deliverables-list.actions';

@Injectable()
export class DeliverablesListStoreEffects {
  constructor(
    private actions: Actions,
    private deliverableService: DeliverablesService,
    private notificationService: NotificationService,
    private store$: Store<AppState>,
  ) {}

  getDeliverableRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverablesListActions.getDeliverablesByFunctionalId),
        withLatestFrom(this.store$.select(getIncludeCancelledItems)),
        switchMap(([{ functionalId }, includeCancelled]) =>
          this.deliverableService.getDeliverables(functionalId, includeCancelled).pipe(
            map((deliverablesListResponse: DeliverableListResponse) => {
              return deliverablesListActions.getDeliverablesByFunctionalIdSuccess({ deliverablesListResponse });
            }),
            catchError((error) => {
              showHttpErrorResponse(this.notificationService, error, 'deliverable.gettingDeliverableFailed');
              return of(deliverablesListActions.deliverablesListNoOpAction());
            }),
          ),
        ),
      ),
    { dispatch: true },
  );
}
