import moment from 'moment';
import { CRStatusCodeFull } from '../../common/navigation-tabs/change-request-list/cr-status';
import { ChangeRequestType } from '../../common/navigation-tabs/change-request-list/cr-type';

export class ChangeRequest {
  contextElementId?: number;
  manager: string;
  title?: string;
  description?: string;
  changeRequestType: ChangeRequestType;
}

export class ChangeRequestPackage {
  requestType: RequestType;
  functionId: number;
  packageCode: string;
  functionalHierarchyId: number;
  oldPlanStartDate: Date;
  newPlanStartDate: Date;
  oldPlanEndDate: Date;
  newPlanEndDate: Date;
  oldForecastEndDate: Date;
  newForecastEndDate: Date;
  status: CRStatusCodeFull;
}

export class ChangeRequestDeliverable {
  functionId: number;
  deliverableId: number | string;
  packageFunctionalHierarchyId: number;
  packageCode: string;
  documentNumber: string;
  title: string;
  progressWorkflowId?: number;
  oldProgressWorkflowId?: number;
  request: RequestType;
  oldHours: number;
  newHours: number;
  oldCost: number;
  newCost: number;
  oldCurveProfileCode: string;
  newCurveProfileCode: string;
  oldStartDate: Date | moment.Moment;
  newStartDate: Date | moment.Moment;
  oldEndDate: Date | moment.Moment;
  newEndDate: Date | moment.Moment;
  oldNotes: string;
  newNotes: string;
  oldUrl: string;
  newUrl: string;
  oldControlledDocument: boolean;
  newControlledDocument: boolean;
  oldPriority: number;
  newPriority: number;
  oldOnHold: boolean;
  newOnHold: boolean;
  oldHoldReason: string;
  newHoldReason: string;
}

export class MoveRequestDeliverable {
  functionId: number;
  deliverableId: number | string;
  packageFunctionalHierarchyId: number;
  newPackageFunctionalHierarchyId: number;
  packageCode: string;
  newPackageCode: string;
  title: string;
  newHours: number;
  newCost: number;
  documentNumber: string;
}

export enum RequestType {
  New = 'new',
  Change = 'change',
  Cancel = 'cancel',
  Move = 'move',
}
