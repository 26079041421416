import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as deliverableReducer from './deliverable.reducer';

export const selectDeliverableState = createFeatureSelector<deliverableReducer.DeliverableState>('deliverables');

const selectAllDeliverableItems = createSelector(selectDeliverableState, deliverableReducer.selectAllNodes);

const getDeliverableDetailsById = (props: { id: number }) =>
  createSelector(selectDeliverableState, (state: deliverableReducer.DeliverableState) => {
    return state.entities[props.id] || null;
  });

const getDeliverablePackageHierarchyIdByDeliverableId = (props: { id: number }) =>
  createSelector(selectDeliverableState, (state: deliverableReducer.DeliverableState) => {
    const deliverable = state.entities[props.id];
    return deliverable?.packageFunctionalHierarchyId ?? null;
  });

const getTagsForProjectDeliverableById = (props: { id: number }) =>
  createSelector(selectDeliverableState, (state: deliverableReducer.DeliverableState) => {
    const deliverable = state.entities[props.id];
    return deliverable?.tags ?? null;
  });

export const DeliverableSelectors = {
  selectAllDeliverableItems,
  getDeliverableDetailsById,
  getDeliverablePackageHierarchyIdByDeliverableId,
  getTagsForProjectDeliverableById,
};
