import { AfterViewInit, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjectService } from 'src/app/shared/services/project-service';
import { FullChangeRequestModel } from '../full-change-request';

@Component({
  selector: 'app-cr-move-details',
  templateUrl: './cr-move-details.component.html',
  styleUrls: ['./cr-move-details.component.scss'],
})
export class CrMoveDetailsComponent implements AfterViewInit {
  @Input() changeRequest: FullChangeRequestModel;
  treeHierarchySize$: Observable<number>;

  hierarchySizeWithoutSubFunctions = 3;

  constructor(private projectService: ProjectService) {}

  ngAfterViewInit(): void {
    this.treeHierarchySize$ = this.projectService.getProjectHierarchyRuleData(this.changeRequest.projectId).pipe(
      filter((x) => !!x.length),
      map((x) => x.length),
    );
  }
}
