import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { HierarchyRule } from 'src/app/common-models/HierarchyRule.model';
import { CodeTypes, TreeLevelTypes } from 'src/app/common-models/node-types/node-types';
import { rewriteDateFromUTC } from 'src/app/shared/common-formatters';
import { TreeNode } from '../tree-structure.model';
import * as treeActions from './tree.actions';

export interface TreeState extends EntityState<TreeNode> {
  initialLoad: boolean;
  projectHierarchyRules: HierarchyRule[];
}

export const treeStructureAdapter: EntityAdapter<TreeNode> = createEntityAdapter<TreeNode>({
  selectId: (model) => model.functionalHierarchyId,
  sortComparer: (a: TreeNode, b: TreeNode): number => a.title.localeCompare(b.title),
});

const initialState: TreeState = treeStructureAdapter.getInitialState({
  initialLoad: false,
  projectHierarchyRules: [],
  projectDetails: null,
});

export const treeReducer = createReducer(
  initialState,
  on(treeActions.getProjectDataSuccess, (state, { projectTree }) => {
    return {
      ...treeStructureAdapter.upsertMany(cleanDataForFiltering(projectTree), state),
      initialLoad: true,
    };
  }),
  on(treeActions.updatePartialTreeData, (state, { projectTree }) => {
    return {
      ...treeStructureAdapter.upsertMany(cleanDataForFiltering(projectTree), state),
      initialLoad: true,
    };
  }),
  on(treeActions.updateNodePropertySuccess, (state, { update }) => {
    if (update as Update<TreeNode>) {
      const updatedNode = {
        id: update.id,
        changes: cleanDataForFiltering([update.changes as TreeNode])[0],
      } as Update<TreeNode>;
      return treeStructureAdapter.updateOne(updatedNode, state);
    }
    return state;
  }),
  on(treeActions.clearTreeData, (state) => {
    return {
      ...treeStructureAdapter.removeAll(state),
      initialLoad: false,
      projectHierarchyRules: [],
    };
  }),
  on(treeActions.addNodeSuccess, (state, { node }) => {
    return {
      ...treeStructureAdapter.addOne(cleanDataForFiltering([node])[0], state),
      initialLoad: false,
    };
  }),
  on(treeActions.deleteNodeSuccess, (state, { nodeId }) => {
    return treeStructureAdapter.removeOne(nodeId, {
      ...state,
    });
  }),
  on(treeActions.moveSuccess, (state, { functionalHierarchyId, functionDestinationFunctionalHierarchyId }) => {
    let nodeToMove = {
      ...Object.entries(state.entities).find(([hierarchyId, _]) => hierarchyId === functionalHierarchyId.toString())[1],
    };
    nodeToMove.parentId = functionDestinationFunctionalHierarchyId;
    return treeStructureAdapter.upsertOne(nodeToMove, state);
  }),
  on(treeActions.getProjectHierarchyRulesDataSuccess, (state, { projectHierarchyRules }) => {
    return {
      ...state,
      projectHierarchyRules,
    };
  }),
);

export const cleanDataForFiltering = (data: TreeNode[]) => {
  return data.map((x) => {
    const copied = { ...x };
    remapTypeCode(copied);
    formatNumericData(copied);
    formatDateData(copied);
    return copied;
  });
};

export const formatNumericData = (data: TreeNode) => {
  if (data.budgetHours) {
    data.budgetHours = Math.round(data.budgetHours);
  }

  if (data.actualComplete) {
    data.actualComplete = Math.round(data.actualComplete * 1e2) / 1e2;
  }

  if (data.plannedComplete) {
    data.plannedComplete = Math.round(data.plannedComplete * 1e2) / 1e2;
  }

  if (data.spi) {
    const with2Decimals = data.spi.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    data.spi = Number(with2Decimals);
  }
};

export const remapTypeCode = (data: TreeNode) => {
  if (data.typeCode === CodeTypes.Function && data.treeLevel > TreeLevelTypes.Function) {
    data.typeCode = CodeTypes.SubFunction;
  }
};

export const formatDateData = (data: TreeNode) => {
  if (data.endDate) {
    data.endDate = rewriteDateFromUTC(data.endDate);
  }

  if (data.startDate) {
    data.startDate = rewriteDateFromUTC(data.startDate);
  }
};

const { selectAll, selectEntities } = treeStructureAdapter.getSelectors();
export const selectTreeNodeEntities = selectEntities;
export const selectAllNodes = selectAll;
