import { Moment } from 'moment';
import { DeliverableStatus, StatusCode } from 'src/app/shared-controls/status/statusCodesMap';

export class PackageRelationship {
  targetPackageId: number;
  relationshipType: RelationshipType;
}

export enum RelationshipType {
  Related = 1,
  Successor,
  Predecessor,
}

export class PackageRelationshipItem {
  constructor(
    packageHierarchyId: number,
    title: string,
    code: string,
    status: StatusCode | DeliverableStatus,
    health: number,
    responsible: string,
    percentageComplete: number,
    relationType: RelationshipType,
    canAccessPackage: boolean = false,
    startDate: Date | Moment,
    endDate: Date | Moment,
    overlap: string,
    isPendingScheduleCR: boolean,
  ) {
    this.packageHierarchyId = packageHierarchyId;
    this.title = title;
    this.code = code;
    this.status = status;
    this.health = health;
    this.responsible = responsible;
    this.percentageComplete = percentageComplete;
    this.relationType = relationType;
    this.canAccessPackage = canAccessPackage;
    this.startDate = startDate;
    this.endDate = endDate;
    this.overlap = overlap;
    this.isPendingScheduleCR = isPendingScheduleCR;
  }

  packageHierarchyId: number;
  title: string;
  code: string;
  status: StatusCode | DeliverableStatus;
  health: number;
  responsible: string;
  percentageComplete: number;
  relationType: RelationshipType;
  canAccessPackage: boolean;
  startDate: Date | Moment;
  endDate: Date | Moment;
  overlap: string;
  currentOverlap: string;
  predictedOverlap: string;
  isPendingScheduleCR: boolean;
}
