import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ChangeRequest } from '../cart-item.model';

export enum ChangeRequestActions {
  NOOP = '[Change Request] NOOP',
  ADD_CHANGE_TO_CART = '[Change Request] Add Change',
  CREATE_EMPTY_CHANGE = '[Change Request] Create Change',
  UPDATE_CHANGE_IN_CART = '[Change Request] Update Change',
  REMOVE_CHANGE_FROM_CART = '[Change Request] Remove Change',
  REMOVE_CHANGE_FROM_CART_IN_CURR_CONTEXT = '[Change Request] Remove Change in current context',
  REMOVE_MOVE_CHANGE_FROM_CART_IN_CURR_CONTEXT = '[Move Request] Remove Move change in current context',
  REMOVE_MOVES_COUNT_FROM_CART = '[Move Request] Remove moves count changes',
  REMOVE_MOVE_CHANGE_FROM_CART = '[Move Request] Remove move change from cart',
  REMOVE_MOVES_FROM_CART = '[Move Request] Remove moves changes',
}

export const addChange = createAction(ChangeRequestActions.ADD_CHANGE_TO_CART, props<{ change: ChangeRequest }>());
export const createChange = createAction(
  ChangeRequestActions.CREATE_EMPTY_CHANGE,
  props<{ functionalHierarchyId: number }>(),
);
export const updateChange = createAction(
  ChangeRequestActions.UPDATE_CHANGE_IN_CART,
  props<{ update: Update<ChangeRequest> }>(),
);
export const removeChange = createAction(ChangeRequestActions.REMOVE_CHANGE_FROM_CART);
export const removeChangeInCurrentContext = createAction(ChangeRequestActions.REMOVE_CHANGE_FROM_CART_IN_CURR_CONTEXT);
export const removeMoveChangeInCurrentContext = createAction(
  ChangeRequestActions.REMOVE_MOVE_CHANGE_FROM_CART_IN_CURR_CONTEXT,
);
export const removeMoveChanges = createAction(ChangeRequestActions.REMOVE_MOVES_COUNT_FROM_CART);
export const removeMoveChange = createAction(
  ChangeRequestActions.REMOVE_MOVE_CHANGE_FROM_CART,
  props<{ deliverableId: string | number }>(),
);

export const noOP = createAction(ChangeRequestActions.NOOP);
