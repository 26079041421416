import { createAction, props } from '@ngrx/store';
import { ChangeRequestDeliverable, MoveRequestDeliverable } from '../cart-item.model';

export enum ChangeRequestDeliverableActions {
  ADD_DELIVERABLE_CHANGE_TO_CART = '[Change Request Deliverable] Add Deliverable Change',
  SEND_DELIVERABLE_CHANGE_TO_CART = '[Change Request Deliverable] Send Deliverable Change To Cart',
  REMOVE_DELIVERABLE_CHANGE_FROM_CART = '[Change Request Deliverable] Remove Deliverable Change',
  REMOVE_ALL_DELIVERABLE_CHANGES_FOR_FUNCTION = '[Change Request Deliverable] Remove ALL Deliverable Changes',
  ADD_DELIVERABLE_MOVE_REQUEST_TO_CART = '[Move Request Deliverable] Add Deliverable Move Request',
  SEND_DELIVERABLE_MOVE_REQUEST_TO_CART = '[Move Request Deliverable] Send Deliverable Move Request To Cart',
  REMOVE_DELIVERABLE_MOVE_REQUEST_FROM_CART = '[Move Request Deliverable] Remove Deliverable Move Request',
  REMOVE_ALL_DELIVERABLE_MOVE_CHANGES_FOR_FUNCTION = '[Move Request Deliverable] Remove ALL Deliverable Move Changes',
}

export const addDeliverableChange = createAction(
  ChangeRequestDeliverableActions.ADD_DELIVERABLE_CHANGE_TO_CART,
  props<{ crDeliverable: ChangeRequestDeliverable }>(),
);
export const addDeliverableMoveRequest = createAction(
  ChangeRequestDeliverableActions.ADD_DELIVERABLE_MOVE_REQUEST_TO_CART,
  props<{ mrDeliverable: MoveRequestDeliverable }>(),
);
export const sendDeliverableChangeToCart = createAction(
  ChangeRequestDeliverableActions.SEND_DELIVERABLE_CHANGE_TO_CART,
  props<{ crDeliverable: ChangeRequestDeliverable }>(),
);
export const sendDeliverableMoveRequestToCart = createAction(
  ChangeRequestDeliverableActions.SEND_DELIVERABLE_MOVE_REQUEST_TO_CART,
  props<{ mrDeliverable: MoveRequestDeliverable }>(),
);
export const removeDeliverableChange = createAction(
  ChangeRequestDeliverableActions.REMOVE_DELIVERABLE_CHANGE_FROM_CART,
  props<{
    deliverableId: number | string;
    packageFunctionalHierarchyId: number;
  }>(),
);
export const removeDeliverableMoveRequest = createAction(
  ChangeRequestDeliverableActions.REMOVE_DELIVERABLE_MOVE_REQUEST_FROM_CART,
  props<{
    deliverableId: number | string;
    packageFunctionalHierarchyId: number;
  }>(),
);
export const removeAllDeliverableChangesForFunction = createAction(
  ChangeRequestDeliverableActions.REMOVE_ALL_DELIVERABLE_CHANGES_FOR_FUNCTION,
);
export const removeAllDeliverableMoveChangesForFunction = createAction(
  ChangeRequestDeliverableActions.REMOVE_ALL_DELIVERABLE_MOVE_CHANGES_FOR_FUNCTION,
);
