<div mat-dialog-content>
  <h2>
    {{ 'projectImport.downloadTemplate' | translate }}
  </h2>

  <form [formGroup]="form">
    <div class="include-data-container row-column">
      <div class="custom-control custom-switch orms-toggle-switch-container">
        <input
          type="checkbox"
          formControlName="includeData"
          class="custom-control-input orms-toggle-switch"
          id="includeData"
        />
        <label class="custom-control-label include-data-label" for="includeData">{{
          'projectImport.includeData' | translate
        }}</label>
      </div>
    </div>
  </form>

  <div class="orms-dialog-footer">
    <div>
      <button
        class="orms-button orms-button-primary submit-button"
        type="button"
        (click)="downloadTemplate()"
        [disabled]="isDisabled"
      >
        {{ 'projectImport.download' | translate }}
      </button>
      <button
        class="orms-button orms-button-secondary"
        title="{{ 'general.escape' | translate }}"
        type="button"
        mat-dialog-close
      >
        {{ 'general.cancel' | translate }}
      </button>
    </div>
    <div>
      <app-spinner *ngIf="submissionInProgress"></app-spinner>
    </div>
  </div>
</div>
