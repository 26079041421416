import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { CodeTypes, EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { getShareIdSelector } from 'src/app/root-store/root-store.selector';
import { BreadcrumbOption } from 'src/app/shared-controls/breadcrumbs/breadcrumbs.interfaces';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormConfig,
  shadowFormUpdateConfig,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { TagListComponent } from 'src/app/shared-controls/tag-list/tag-list.component';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { StatusCodeDetail, getStatusDetail } from '../../../shared-controls/status/statusCodesMap';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { NodeHeaderService } from '../../common/node-header-service';
import { SharedPackageService } from '../../node-header/shared.service';

@Component({
  selector: 'app-deliverable-header-view',
  templateUrl: './deliverable-header-view.component.html',
  styleUrls: ['./deliverable-header-view.component.scss'],
})
export class DeliverableHeaderViewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() entityId: number;
  @Input() entityType: EntityTypes;
  @Input() viewOnly = false;
  @Input() packageUrl: string;
  @Input() breadcrumbOptions: BreadcrumbOption[];

  @ViewChild('divToTrackWidthChanges') divToTrackWidthChanges: ElementRef;
  @ViewChild(TagListComponent) tagList: TagListComponent;
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  addTagButtonWidth = 34;
  expansionPanelLineHeight = 40;
  panelOpenState = false;
  containerWidth: number;
  expansionIndicatorHidden = true;
  headerDetails: DeliverableDetail;
  nodeHeaderService: NodeHeaderService;
  projectId$: Observable<number>;
  statusDetails: StatusCodeDetail;
  canFitChildren = true;
  containerObserver: ResizeObserver;
  tagListObserver: ResizeObserver;
  projectId: number;
  canManage = false;
  firstLoadWithData = false;
  curveProfiles$: Observable<CurveProfile[]>;
  deliverableBreadcrumbOptions: BreadcrumbOption[];

  form: UntypedFormGroup;
  private subscription = new Subscription();

  constructor(
    private zone: NgZone,
    private store$: Store<AppState>,
    private curveProfilesService: CurveProfileService,
    private shareService: SharedPackageService,
  ) {
    this.initForm();
    this.curveProfiles$ = this.curveProfilesService.curveProfiles$;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store$
        .select(getShareIdSelector)
        .pipe(
          filter((x) => !!x && x.hasState),
          map((x) => x.id),
          switchMap((x) => this.shareService.getDeliverableViewDetailData(x, this.entityId)),
        )
        .subscribe((headerDetails) => {
          this.headerDetails = headerDetails;
          this.statusDetails = getStatusDetail(this.headerDetails?.status);
          this.fillForm();
          disableFormFieldsBaseOnPermissions(this.form, this.isEditable.bind(this));
          this.calculateDeliverableBreadcrumbs();
        }),
    );

    this.subscription.add(
      this.store$
        .select(getShareIdSelector)
        .subscribe((shareId) => this.shareService.accessPackage(shareId.id).subscribe()),
    );
  }

  ngAfterViewInit(): void {
    setTimeout((_) => {
      let containerWidth = 0;
      let tagListWidth = 0;
      let tagListHeight = 0;
      if (!this.divToTrackWidthChanges || !this.tagList) {
        return;
      }

      this.containerObserver = new ResizeObserver((entries) => {
        this.zone.run(() => {
          containerWidth = entries[0].contentRect.width;
          this.closeExpansionPanelIfCanFitContent(containerWidth, tagListWidth, tagListHeight);
        });
      });

      this.tagListObserver = new ResizeObserver((entries) => {
        this.zone.run(() => {
          tagListWidth = this.canManage
            ? entries[0].contentRect.width + this.addTagButtonWidth
            : entries[0].contentRect.width;
          tagListHeight = entries[0].contentRect.height;
          this.closeExpansionPanelIfCanFitContent(containerWidth, tagListWidth, tagListHeight);
        });
      });
      this.containerObserver.observe(this.divToTrackWidthChanges?.nativeElement);
      this.tagListObserver.observe(this.tagList.chipList?.nativeElement);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('packageUrlParams' in changes || 'breadcrumbOptions' in changes) {
      this.calculateDeliverableBreadcrumbs();
    }
  }

  private closeExpansionPanelIfCanFitContent(
    containerWidth: number,
    tagListWidth: number,
    tagListHeight: number,
  ): void {
    this.canFitChildren = containerWidth > tagListWidth && tagListHeight < this.expansionPanelLineHeight;
    if (this.canFitChildren && this.expansionPanel) {
      this.expansionPanel.close();
    }
  }

  ngOnDestroy(): void {
    this.containerObserver?.unobserve(this.divToTrackWidthChanges.nativeElement);
    this.tagListObserver?.unobserve(this.tagList.chipList.nativeElement);
    this.subscription?.unsubscribe();
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  private isEditable(name: string): boolean {
    return false;
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        title: new UntypedFormControl(),
        documentNumber: new UntypedFormControl(),
        responsible: new UntypedFormControl(),
      },
      { ...shadowFormConfig },
    );
  }

  private fillForm(): void {
    if (!this.headerDetails) {
      this.form.reset(
        {
          title: null,
          documentNumber: null,
          responsible: null,
        },
        { ...shadowFormUpdateConfig },
      );
      return;
    }

    const { title, documentNumber, responsible, responsibleId } = this.headerDetails;
    this.form.patchValue(
      {
        title,
        documentNumber,
        responsible: {
          userId: responsibleId,
          displayName: responsible,
        },
      },
      { ...shadowFormUpdateConfig },
    );
  }

  private calculateDeliverableBreadcrumbs(): void {
    if (!this.breadcrumbOptions?.length || !this.packageUrl || !this.headerDetails) return;

    const parent = this.breadcrumbOptions[this.breadcrumbOptions.length - 1];
    this.deliverableBreadcrumbOptions = [
      ...this.breadcrumbOptions.filter((x) => x != parent),
      {
        ...parent,
        url: this.packageUrl,
        isDisabled: false,
      },
      {
        title: this.headerDetails.title,
        icon: CodeTypes.Deliverable,
      },
    ];
  }
}
