export enum ProjectImportMode {
  Import = 'import',
  Template = 'template',
}

export enum ProjectImportColumns {
  Title = 'title',
  Code = 'code',
  Result = 'result',
  Sheet = 'sheet',
  Row = 'row',
  Feedback = 'feedback',
  Category = 'category',
}

export enum ProjectImportResultTypes {
  New = 'New',
  Update = 'Update',
  Delete = 'Delete',
  Error = 'Error',
  Unknown = 'Unknown',
}

export enum ProjectImportFeedbackTypes {
  Alert = 'Alert',
  Warning = 'Warning',
  Error = 'Error',
}

export interface ProjectImportPayload {
  projectId: number;
  templateName: string;
  changeNoticeRequired: boolean;
  changeNoticeTitle: string;
  changeNoticeReason: string;
  autoApprove: boolean;
  importSummary: ProjectImportRowNode[];
}

export interface ProjectImportRowNode {
  discreteAction?: string; // We parse the response action to determine New/Update/Delete
  result?: string; // We translate the discrete action and combine with translation of Error if applicable to produce this result
  action: string;
  sheet: string;
  row: number;
  code: string;
  title: string;
  feedback: {
    feedbackType: ProjectImportFeedbackTypes;
    content: string[];
  }[];
  inputColumns: {
    key: string;
    oldValue: string;
    newValue: string;
    columnMapping?: any;
  }[];
}

export interface ProjectImportWizardContext {
  projectId: number;
  projectTitle: string;
  mode: ProjectImportMode;
  file?: File;
  onAccessDeniedAction: () => void;
  payload?: ProjectImportPayload;
}

export interface ProjectTemplate {
  importRequestId: string;
  templateName: string;
}
