import { Tag } from 'src/app/common-models/tag.model';
import {
  ChangeRequestDeliverable,
  MoveRequestDeliverable,
} from 'src/app/project-management/change-request-cart/change-request-cart-state/cart-item.model';
import { DeliverableStatus, StatusCode } from '../../../../shared-controls/status/statusCodesMap';

export interface AddTag {
  tagName: string;
  tagId: number;
  deliverableId: number;
  projectId: number;
}

export interface AddDeliverable {
  packageFunctionalHierarchyId: number;
  title: string;
  documentNumber: number;
  newHours: number;
  newCost: number;
  newCurveProfileCode: string;
  progressWorkflowId: number;
}

export class DeliverableDetail {
  deliverableId: number;
  packageId: number;
  packageFunctionalHierarchyId: number;
  packageCode: string;
  documentNumber: string;
  title: string;
  responsibleId: number;
  responsible: string;
  status: StatusCode | DeliverableStatus;
  packageStatus?: StatusCode | DeliverableStatus;
  hours: number;
  cost: number;
  startDate: Date | moment.Moment;
  endDate: Date | moment.Moment;
  plannedStartDate: Date;
  plannedEndDate: Date;
  percentageCompleted: number;
  onHold: boolean;
  notes?: string;
  controlledDocument: boolean;
  holdReason?: string;
  url?: string;
  progressMethodCode: string;
  curveProfileCode: string;
  progressWorkflowId: number;
  progressWorkflow?: string;
  progressWorkflowStatusId: number;
  progressWorkflowStatus?: string;
  progressWorkflowTitle?: string;
  priority?: number;
  managePermission: boolean;
  updatePermission: boolean;
  viewPermission: boolean;
  editableFields: string[];
  tags: Tag[];
  progressChangeNotes: string;
  cr?: ChangeRequestDeliverable;
  mr?: MoveRequestDeliverable;
  usesPackageEndDate?: boolean;
  usesPackageStartDate?: boolean;
  underChangeControl: boolean;
  canAdd: boolean;
  canDelete: boolean;
  canMove: boolean;
  canCancel: boolean;
}
