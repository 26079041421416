<ng-container *ngIf="detailData$ | async as detailData">
  <app-budged-section
    [detailData]="detailData"
    [underChangeControl]="detailData.underChangeControl"
  ></app-budged-section>
  <hr />
  <app-info-section
    [detailData]="detailData"
    [editableFields]="detailData.editableFields"
    [entityType]="nodeType$ | async"
  ></app-info-section>
  <hr />
  <app-intent-section
    [detailData]="detailData"
    [editableFields]="detailData.editableFields"
    [entityId]="nodeId$ | async"
  ></app-intent-section>
  <hr />
</ng-container>
