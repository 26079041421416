<div class="orms-cr-submission-form small-container">
  <h2>
    {{ 'general.deleteItem' | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <h5>{{ 'general.deleteItemConfirmation' | translate }}</h5>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        class="orms-button orms-button-primary submit-button"
        type="button"
        (click)="deleteItem()"
        [disabled]="submissionInProgress"
      >
        {{ 'general.confirm' | translate }}
      </button>
      <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </button>
      <app-spinner [diameter]="26" *ngIf="submissionInProgress"></app-spinner>
    </div>
  </div>
</div>
