import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { clearProjectRelatedData } from 'src/app/root-store/root-store.actions';
import { ChangeRequestPackage, RequestType } from '../cart-item.model';
import * as actions from './package.actions';

export const crPackageAdapter: EntityAdapter<ChangeRequestPackage> = createEntityAdapter<ChangeRequestPackage>({
  selectId: (model) => model.functionalHierarchyId,
  sortComparer: (a: ChangeRequestPackage, b: ChangeRequestPackage): number =>
    b.functionalHierarchyId.toString().localeCompare(a.functionalHierarchyId.toString()),
});

export const ChangeRequestPackageState = crPackageAdapter.getInitialState({});

export const ChangeRequestPackageReducer = createReducer(
  ChangeRequestPackageState,
  on(actions.addPackageChange, (state, { crPackage }) => {
    return crPackageAdapter.upsertOne(crPackage, state);
  }),
  on(actions.removePackageChange, (state, { packageId }) => {
    return crPackageAdapter.removeOne(packageId, state);
  }),
  on(actions.removeAllPackageChangesForFunction, (state) => {
    const ids = Object.entries(state.entities)
      .filter((x) => x[1].requestType !== RequestType.Move)
      .map((x) => x[0]);
    return crPackageAdapter.removeMany(ids, state);
  }),
  on(actions.removeAllPackageMovesForFunction, (state) => {
    const ids = Object.entries(state.entities)
      .filter((x) => x[1].requestType === RequestType.Move)
      .map((x) => x[0]);
    return crPackageAdapter.removeMany(ids, state);
  }),
  on(clearProjectRelatedData, () => {
    return crPackageAdapter.getInitialState({});
  }),
);
