import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';

import { ReviewImportChangeDetailsComponent } from '../../review-import-change-details/review-import-change-details.component';

@Component({
  selector: 'app-ag-cell-changes-link',
  templateUrl: './ag-cell-changes-link.component.html',
  styleUrls: ['./ag-cell-changes-link.component.scss'],
})
export class AgCellChangesLinkComponent {
  value: any;
  data: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatLegacyDialog,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.value = params.formatValue ? params.formatValue(params.value) : params.value;
    this.data = params.data;
  }

  refresh(params: ICellRendererParams) {
    this.value = params.formatValue ? params.formatValue(params.value) : params.value;
    this.data = params.data;
    return false;
  }

  displayChangeDetail() {
    const dialogRef = this.dialog.open(ReviewImportChangeDetailsComponent, {
      data: {
        discreteAction: this.data.discreteAction,
        inputColumns: this.data.inputColumns,
      },
    });
    dialogRef.afterClosed().subscribe((disregardAction?) => {
      this.router.navigate(['.'], { relativeTo: this.route });
    });
  }
}
