<table
  mat-table
  [dataSource]="scheduleDataSource"
  class="orms-table"
  attr.aria-label="{{ 'general.schedule' | translate }}"
>
  <ng-container matColumnDef="packageCode">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'general.packageCode' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.packageCode }}</td>
  </ng-container>
  <ng-container matColumnDef="newPlanStartDate">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'changeRequest.newPlannedStartDate' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <app-label-with-change-tooltip
        [status]="element.status"
        [text]="element.newPlanStartDate"
        [oldValue]="element.oldPlanStartDate"
        [newValue]="element.newPlanStartDate"
        [displayed]="element.planStartDateTooltipDisplay"
      >
      </app-label-with-change-tooltip>
    </td>
  </ng-container>
  <ng-container matColumnDef="newPlanEndDate">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'changeRequest.newPlannedEndDate' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <app-label-with-change-tooltip
        [status]="element.status"
        [text]="element.newPlanEndDate"
        [oldValue]="element.oldPlanEndDate"
        [newValue]="element.newPlanEndDate"
        [displayed]="element.planEndDateTooltipDisplay"
      >
      </app-label-with-change-tooltip>
    </td>
  </ng-container>
  <ng-container matColumnDef="newForecastEndDate">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'changeRequest.newForecastCompletionDate' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <app-label-with-change-tooltip
        [status]="element.status"
        [text]="element.newForecastEndDate"
        [oldValue]="element.oldForecastEndDate"
        [newValue]="element.newForecastEndDate"
        [displayed]="false"
      >
      </app-label-with-change-tooltip>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="scheduleColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: scheduleColumns"></tr>
</table>
