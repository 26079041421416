import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BulkAction } from '../change-requests-pending-approval/change-requests-pending-approval.component';

@Component({
  selector: 'app-partial-success',
  templateUrl: './partial-success.component.html',
  styleUrls: ['./partial-success.component.scss'],
})
export class PartialSuccessComponent {
  @Input() approver: string;
  @Input() failed: any[];
  @Input() success: any[];
  @Input() totalRequestNumber: number;
  @Input() action: BulkAction;
  @Output() byClose = new EventEmitter<void>();
  bulkAction = BulkAction;
  showPartialFailure = true;
  constructor() {}

  close() {
    this.byClose.emit();
  }
}
