import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CRStatusCodeFull } from 'src/app/project-management/common/navigation-tabs/change-request-list/cr-status';

@Component({
  selector: 'app-label-with-change-tooltip',
  templateUrl: './label-with-change-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelWithChangeTooltipComponent implements OnInit {
  @Input() text: string;
  @Input() oldValue: string;
  @Input() newValue: string;
  @Input() displayed: boolean;
  @Input() status: CRStatusCodeFull;
  tooltipText: string;
  private currentLabel: string;
  private requestedLabel: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentLabel =
      this.status === CRStatusCodeFull.Logged
        ? this.translateService.instant('changeRequest.current')
        : this.translateService.instant('changeRequest.original');
    this.requestedLabel = this.translateService.instant('changeRequest.requested');
    this.tooltipText = `
    ${this.currentLabel}: ${this.oldValue ?? '-'}
    ${this.requestedLabel}: ${this.newValue ?? '-'}
  `;
  }
}
