import { HttpResponse } from '@angular/common/http';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { ProjectImportMode } from '../../project-import.models';
import { ProjectImportService } from '../../project-import.service';

export interface IncludeDataDialogData {
  projectId: number;
  projectTitle: string;
  mode: ProjectImportMode;
}

@Component({
  selector: 'app-include-data-dialog',
  templateUrl: './include-data-dialog.component.html',
  styleUrls: ['./include-data-dialog.component.scss'],
})
export class IncludeDataDialogComponent implements OnInit, OnDestroy {
  submissionInProgress = false;
  private dialogSub: Subscription;

  form!: UntypedFormGroup;
  includeData: UntypedFormControl;

  constructor(
    public fb: UntypedFormBuilder,
    private projectImportService: ProjectImportService,
    public dialogRef: MatLegacyDialogRef<IncludeDataDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: IncludeDataDialogData,
  ) {}

  ngOnInit(): void {
    this.includeData = new UntypedFormControl(false, [Validators.required]);
    this.form = this.fb.group({
      includeData: this.includeData,
    });

    this.dialogSub = this.dialogRef
      .backdropClick()
      .pipe(
        take(1),
        tap(() => this.dialogRef.close()),
      )
      .subscribe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.downloadTemplate();
    }

    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  downloadTemplate(): void {
    this.submissionInProgress = true;
    const includeDataValue = this.form.get('includeData').value;
    this.projectImportService
      .downloadProjectImportTemplate(this.data.projectId, includeDataValue, this.data.mode)
      .pipe(
        take(1),
        tap((response: HttpResponse<Blob>) => {
          const filename = this.data.projectTitle;
          const pattern = /[\\.!$^~#%&*{}/:<>?|\"-()_]/g;
          const sanitizedName = filename.replace(pattern, '');
          saveAs(response.body, `${sanitizedName} Project Import Template.xlsx`);
        }),
        finalize(() => {
          this.submissionInProgress = false;
          this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  get isDisabled(): boolean {
    return this.submissionInProgress;
  }

  ngOnDestroy(): void {
    this.dialogSub?.unsubscribe();
  }
}
