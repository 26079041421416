<form autocomplete="off" [formGroup]="form" class="node-header-container">
  <app-breadcrumbs [options]="deliverableBreadcrumbOptions"></app-breadcrumbs>
  <div class="title-action-section">
    <img class="deliverable-title-icon" src="/assets/icons/deliverable.svg" />
    <input
      class="form-control orms-shadow-input orms-node-title"
      [class.is-invalid]="showInvalidState('title')"
      formControlName="title"
      placeholder="Title"
      [title]="form.get('title').value ? form.get('title').value : ('general.title' | translate)"
      maxlength="120"
    />
  </div>
  <div class="code-kpi-section">
    <div class="code">
      <input
        class="form-control orms-shadow-input orms-node-code"
        [class.is-invalid]="showInvalidState('documentNumber')"
        formControlName="documentNumber"
        placeholder="{{ 'general.DELIVERABLE#' | translate }}"
        [title]="
          form.get('documentNumber').value
            ? form.get('documentNumber').value
            : ('general.deliverableNumber' | translate)
        "
        maxlength="20"
      />
      <div class="details mt-2">
        <app-status-chip [statusDetails]="statusDetails"></app-status-chip>
        <app-user-dropdown [clearable]="true" [projectId]="projectId$ | async" formControlName="responsible">
        </app-user-dropdown>
        <div class="tag-list" *ngIf="projectId$ | async as projectId" #divToTrackWidthChanges>
          <mat-expansion-panel
            [disabled]="canFitChildren"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <app-tag-list [tags]="headerDetails?.tags" [readonly]="true"> </app-tag-list>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
    <div class="kpi">
      <div class="box">
        <h6 class="orms-label">{{ 'general.actualComplete' | translate }}</h6>
        <h3>
          {{ headerDetails?.percentageCompleted | statusPercent: statusDetails?.code : '1.1-2' }}
        </h3>
      </div>
    </div>
  </div>
</form>
