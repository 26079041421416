import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DatepickerActionsComponent } from '../datepicker-actions/datepicker-actions.component';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent {
  @Input() control: UntypedFormControl;
  @Input() isInvalid: boolean;
  @Input() bordered: boolean;
  @Input() placeholder: string;

  datepickerActionsComponent = DatepickerActionsComponent;
}
