<div class="menu-trigger" [matMenuTriggerFor]="menu" #menuTrigger>
  <span class="label">
    {{ label$ | async }}
  </span>
  <mat-icon>arrow_drop_down</mat-icon>
</div>
<mat-menu #menu="matMenu">
  <form
    class="date-filter-content"
    [formGroup]="form"
    (keydown)="$event.stopPropagation()"
    (click)="$event.stopPropagation()"
  >
    <mat-select
      formControlName="operation"
      [placeholder]="'filter.selectOperation' | translate"
      (click)="$event.stopPropagation()"
      class="orms-select custom-mat-select date-filter-select"
      panelClass="orms-select-options"
    >
      <mat-option [value]="DateFilterOperation.GreaterThan">
        {{ 'filter.greaterThan' | translate }}
      </mat-option>
      <mat-option [value]="DateFilterOperation.LessThan">
        {{ 'filter.lessThan' | translate }}
      </mat-option>
      <mat-option [value]="DateFilterOperation.GreaterThanOrEquals">
        {{ 'filter.greaterThanOrEquals' | translate }}
      </mat-option>
      <mat-option [value]="DateFilterOperation.LessThanOrEquals">
        {{ 'filter.lessThanOrEquals' | translate }}
      </mat-option>
      <mat-option [value]="DateFilterOperation.Between">
        {{ 'filter.between' | translate }}
      </mat-option>
    </mat-select>

    <ng-container *ngIf="form.get('operation').value !== DateFilterOperation.Between; else between">
      <app-datepicker [control]="getControl('arg1')" [bordered]="true" placeholder="filter.filter"> </app-datepicker>
    </ng-container>

    <ng-template #between>
      <app-datepicker [control]="getControl('arg1')" [bordered]="true" placeholder="filter.from"> </app-datepicker>

      <app-datepicker [control]="getControl('arg2')" [bordered]="true" placeholder="filter.to"> </app-datepicker>
    </ng-template>

    <button
      *ngIf="form.get('operation').value"
      type="button"
      class="orms-button orms-button-secondary"
      (click)="onClearClick($event)"
    >
      {{ 'general.clear' | translate }}
      <img src="/assets/icons/icon-close.svg" alt="{{ 'imgAlt.close' | translate }}" />
    </button>
  </form>
</mat-menu>
