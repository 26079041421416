import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty, uniqBy } from 'lodash-es';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Filter } from 'src/app/shared-controls/searchable-dropdown/searchable-dropdown.component';
import { ProjectImportColumns } from '../../project-import.models';
import { ReviewImportFiltersService } from '../services/review-import-filters.service';

@Component({
  selector: 'app-review-import-filters',
  templateUrl: './review-import-filters.component.html',
  styleUrls: ['./review-import-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewImportFiltersComponent implements OnInit, OnDestroy {
  @Input() projectId: number;

  ProjectImportColumns = ProjectImportColumns;

  resultOptions$ = new BehaviorSubject<Filter[]>(null);
  resultControl = new UntypedFormControl([]);
  codeOptions$ = new BehaviorSubject<Filter[]>(null);
  codeControl = new UntypedFormControl([]);
  sheetOptions$ = new BehaviorSubject<Filter[]>(null);
  sheetControl = new UntypedFormControl([]);
  categoryOptions$ = new BehaviorSubject<Filter[]>(null);
  categoryControl = new UntypedFormControl([]);
  feedbackOptions$ = new BehaviorSubject<Filter[]>(null);
  feedbackControl = new UntypedFormControl([]);

  private onDestroy$ = new Subject<void>();

  constructor(
    private filtersService: ReviewImportFiltersService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.changeDetector.markForCheck();

    this.setupResultFilter();
    this.setupCodeFilter();
    this.setupSheetFilter();
    this.setupCategoryFilter();
    this.setupFeedbackFilter();
  }

  setFilter(filterId: ProjectImportColumns, value: any, propertyNameSelector: string = null): void {
    if (value == null || (Array.isArray(value) && !value.length)) {
      this.filtersService.clearFilter(filterId);
      return;
    }

    let filterState = value;
    if (propertyNameSelector != null) {
      filterState = Array.isArray(value) ? value.map((x) => x[propertyNameSelector]) : value[propertyNameSelector];
    }
    this.filtersService.setFilterState(filterId, filterState);
  }

  private setupResultFilter() {
    combineLatest([
      this.filtersService.getNodes(),
      this.filtersService.getFilterState<string[]>(ProjectImportColumns.Result),
      this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(([nodes]) => nodes != null),
      )
      .subscribe(([nodes, filterState]) => {
        const resultOptionsFromNodes: any = uniqBy(
          (nodes ?? []).reduce(
            (resultsAcc, node) =>
              !isEmpty(node.result)
                ? [...resultsAcc, { id: node.result.toLocaleLowerCase(), name: node.result }]
                : resultsAcc,
            [] as Filter[],
          ),
          'id',
        );
        const allResultOptions = [...resultOptionsFromNodes.sort((a, b) => a.name.localeCompare(b.name))];
        const selectedResults = allResultOptions.filter((resultOption) =>
          (filterState ?? []).includes(resultOption.id as string),
        );
        this.resultOptions$.next(allResultOptions);
        this.resultControl.setValue(selectedResults);
      });
  }

  private setupCodeFilter() {
    combineLatest([
      this.filtersService.getNodes(),
      this.filtersService.getFilterState<string[]>(ProjectImportColumns.Code),
      this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(([nodes]) => nodes != null),
      )
      .subscribe(([nodes, filterState]) => {
        const codeOptionsFromNodes: any = uniqBy(
          (nodes ?? []).reduce(
            (codesAcc, node) =>
              !isEmpty(node.code) ? [...codesAcc, { id: node.code.toLocaleLowerCase(), name: node.code }] : codesAcc,
            [] as Filter[],
          ),
          'id',
        );
        const allCodeOptions = [
          {
            id: '',
            name: this.translateService.instant('general.blank'),
          },
          ...codeOptionsFromNodes.sort((a, b) => a.name.localeCompare(b.name)),
        ];
        const selectedCodes = allCodeOptions.filter((codeOption) =>
          (filterState ?? []).includes(codeOption.id as string),
        );
        this.codeOptions$.next(allCodeOptions);
        this.codeControl.setValue(selectedCodes);
      });
  }

  private setupSheetFilter() {
    combineLatest([
      this.filtersService.getNodes(),
      this.filtersService.getFilterState<string[]>(ProjectImportColumns.Sheet),
      this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(([nodes]) => nodes != null),
      )
      .subscribe(([nodes, filterState]) => {
        const sheetOptionsFromNodes: any = uniqBy(
          (nodes ?? []).reduce(
            (sheetsAcc, node) =>
              !isEmpty(node.sheet)
                ? [...sheetsAcc, { id: node.sheet.toLocaleLowerCase(), name: node.sheet }]
                : sheetsAcc,
            [] as Filter[],
          ),
          'id',
        );
        const allSheetOptions = [...sheetOptionsFromNodes.sort((a, b) => a.name.localeCompare(b.name))];
        const selectedSheets = allSheetOptions.filter((sheetOption) =>
          (filterState ?? []).includes(sheetOption.id as string),
        );
        this.sheetOptions$.next(allSheetOptions);
        this.sheetControl.setValue(selectedSheets);
      });
  }

  private setupCategoryFilter() {
    combineLatest([
      this.filtersService.getNodes(),
      this.filtersService.getFilterState<string[]>(ProjectImportColumns.Category),
      this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(([nodes]) => nodes != null),
      )
      .subscribe(([nodes, filterState]) => {
        const categoryOptionsFromNodes: any = uniqBy(
          (nodes ?? []).reduce(
            (categoryAcc, node) =>
              !isEmpty(node.feedback)
                ? [
                    ...categoryAcc,
                    ...node.feedback.map((feedbackEntry) => ({
                      id: feedbackEntry.feedbackType.toLocaleLowerCase(),
                      name: feedbackEntry.feedbackType,
                    })),
                  ]
                : categoryAcc,
            [] as Filter[],
          ),
          'id',
        );
        const allCategoryOptions = [
          {
            id: '',
            name: this.translateService.instant('general.blank'),
          },
          ...categoryOptionsFromNodes.sort((a, b) => a.name.localeCompare(b.name)),
        ];
        const selectedCategoryOptions = allCategoryOptions.filter((categoryOption) =>
          (filterState ?? []).includes(categoryOption.id as string),
        );
        this.categoryOptions$.next(allCategoryOptions);
        this.categoryControl.setValue(selectedCategoryOptions);
      });
  }

  private setupFeedbackFilter() {
    combineLatest([
      this.filtersService.getNodes(),
      this.filtersService.getFilterState<string[]>(ProjectImportColumns.Feedback),
      this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(([nodes]) => nodes != null),
      )
      .subscribe(([nodes, filterState]) => {
        const feedbackOptionsFromNodes: any = uniqBy(
          (nodes ?? []).reduce(
            (feedbackAcc, node) =>
              !isEmpty(node.feedback) // category is taken from the feedback's first content parameter which is a error reference literal
                ? [
                    ...feedbackAcc,
                    ...node.feedback.map((feedbackEntry) => ({
                      id: feedbackEntry.content[0],
                      name: this.translateService.instant(feedbackEntry.content[0], ['<X>', '<Y>']),
                    })),
                  ]
                : feedbackAcc,
            [] as Filter[],
          ),
          'id',
        );
        const allFeedbackOptions = [
          {
            id: '',
            name: this.translateService.instant('general.blank'),
          },
          ...feedbackOptionsFromNodes.sort((a, b) => a.name.localeCompare(b.name)),
        ];
        const selectedFeedbackOptions = allFeedbackOptions.filter((feedbackOption) =>
          (filterState ?? []).includes(feedbackOption.id as string),
        );
        this.feedbackOptions$.next(allFeedbackOptions);
        this.feedbackControl.setValue(selectedFeedbackOptions);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
