import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from 'src/app/shared/services/project-service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { AppState } from '../root-store/app-state';
import { getProjectIdSelector } from '../root-store/root-store.selector';
import { RequestType } from './change-request-cart/change-request-cart-state/cart-item.model';
import {
  removeChangeInCurrentContext,
  removeMoveChangeInCurrentContext,
} from './change-request-cart/change-request-cart-state/change-requests/change-request.actions';
import {
  crCountChanges,
  movesCount,
} from './change-request-cart/change-request-cart-state/change-requests/change-request.selectors';
import { setGridMinWidth } from './common/tree-grid-helper';
import {
  ProjectManagementState,
  getOrInitializeProjectManagementSessionState,
  setProjectManagementSessionState,
} from './project-management.helpers';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
})
export class ProjectManagementComponent implements OnInit, OnDestroy {
  doubleSnackBar = false;
  requestType = RequestType;
  treePercentageSize: number;
  rightPanePercentageSize: number;

  changeRequestChangesCountSelector = crCountChanges;
  changeRequestMovesCountSelector = movesCount;
  removeChangeRequestChangesAction = removeChangeInCurrentContext;
  removeMovesAction = removeMoveChangeInCurrentContext;

  private sub = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private projectService: ProjectService,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit() {
    const aggregateCountSub = combineLatest([
      this.store$.select(crCountChanges),
      this.store$.select(movesCount),
    ]).subscribe(([crCount, moveCount]) => {
      if (crCount && moveCount) {
        this.doubleSnackBar = true;
      } else {
        this.doubleSnackBar = false;
      }
    });
    this.sub.add(aggregateCountSub);

    const isCurrentUserProjectManagerSub = this.store$
      .select(getProjectIdSelector)
      .pipe(
        switchMap((projectId) => {
          if (projectId) {
            return this.projectService.canCurrentUserManageProject(projectId);
          }
          return of(false);
        }),
      )
      .subscribe((canCurrentUserManageProject) => {
        this.projectService.canCurrentUserManageProject$.next(canCurrentUserManageProject);
      });
    this.sub.add(isCurrentUserProjectManagerSub);

    const projectManagementSessionState = getOrInitializeProjectManagementSessionState(this.sessionStorageService);
    this.treePercentageSize = projectManagementSessionState.treePercentageSize;
    this.rightPanePercentageSize = projectManagementSessionState.rightPanePercentageSize;
  }

  onDragEnd(e): void {
    setGridMinWidth();
    const state: ProjectManagementState = {
      treePercentageSize: e.sizes[0],
      rightPanePercentageSize: e.sizes[1],
    };
    setProjectManagementSessionState(this.sessionStorageService, state);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
