import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { ProgressWorkflowStep } from 'src/app/common-models/progress-workflow-step.model';
import { ProgressWorkflow } from 'src/app/common-models/progress-workflow.model';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ApiService } from '../../api.service';
import { Detail } from '../detail/detail.model';
import { DeliverableDetail } from './DeliverableDetail';

export interface DeliverableListResponse {
  deliverables: DeliverableDetail[];
  canAdd: boolean;
  canDelete: boolean;
  canMove: boolean;
  parentId: number;
}

@Injectable({
  providedIn: 'root',
})
export class DeliverablesService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private apiService: ApiService,
    private notificationService: NotificationService,
  ) {}

  getDeliverables(nodeId: number, includeCancelled: boolean): Observable<DeliverableListResponse> {
    const params = new HttpParams()
      .set('functionalHierarchyId', nodeId.toString())
      .set('pageNumber', '1')
      .set('orderByColumn', 'packageId')
      .set('pageSize', '99999')
      .set('includeCancelled', includeCancelled);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableListUrl = this.appConfigService.settings.api.endpoints.deliverableList;
    return this.httpClient
      .get<DeliverableListResponse>(baseUrl + deliverableListUrl, { params })
      .pipe(map((res) => ({ ...res, parentId: nodeId })));
  }

  getDeliverableById(deliverableId: number): Observable<DeliverableDetail> {
    const params = new HttpParams().set('deliverableId', deliverableId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableListUrl = this.appConfigService.settings.api.endpoints.deliverableDetail;
    return this.httpClient.get<DeliverableDetail>(baseUrl + deliverableListUrl, { params });
  }

  getDeliverableByIdForPrint(deliverableId: number): Observable<any> {
    const params = new HttpParams().set('deliverableId', deliverableId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getDeliverableDataForPrint = this.appConfigService.settings.api.endpoints.getDeliverableDataForPrint;
    return this.httpClient.get<DeliverableDetail>(baseUrl + getDeliverableDataForPrint, { params });
  }

  getProjectWorkflowsForCurrentProject(): Observable<ProgressWorkflow[]> {
    return this.store$.select(getProjectIdSelector).pipe(
      switchMap((projectId) => {
        const params = new HttpParams().set('projectId', projectId.toString());
        const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
        const deliverableListUrl = this.appConfigService.settings.api.endpoints.progressWorkflowsLookupForProject;
        return forkJoin([
          this.httpClient.get<ProgressWorkflow[]>(baseUrl + deliverableListUrl, { params }),
          of(projectId),
        ]);
      }),
      switchMap(([projectWorkflows, projectId]) => {
        projectWorkflows.unshift({
          progressWorkflowId: 0,
          title: this.translateService.instant('deliverable.taskBased'),
          projectId,
          statuses: [],
        });
        return of(projectWorkflows);
      }),
      take(1),
    );
  }

  getProjectWorkflowSteps(progressWorkflowId: number): Observable<ProgressWorkflowStep[]> {
    if (!progressWorkflowId) {
      return of([]);
    }
    const params = new HttpParams().set('progressWorkflowId', progressWorkflowId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableListUrl = this.appConfigService.settings.api.endpoints.progressWorkflowsStatuses;
    return this.httpClient.get<ProgressWorkflowStep[]>(baseUrl + deliverableListUrl, { params });
  }

  addDeliverable(deliverable): Observable<DeliverableDetail> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableUrl = this.appConfigService.settings.api.endpoints.deliverableDetail;
    return this.httpClient.post<any>(baseUrl + deliverableUrl, deliverable);
  }

  deleteDeliverable(deliverableId: number): Observable<void> {
    const params = new HttpParams().set('deliverableId', deliverableId.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableUrl = this.appConfigService.settings.api.endpoints.deliverableDetail;
    return this.httpClient.delete<any>(baseUrl + deliverableUrl, { params });
  }

  changeDeliverableDetails(deliverableDetail: Detail): Observable<any> {
    const update: Update<any> = {
      id: deliverableDetail.functionalHierarchyId,
      changes: {},
    };

    if (deliverableDetail.budgetHours) {
      update.changes.Hours = deliverableDetail.budgetHours;
    }
    if (deliverableDetail.budgetCost) {
      update.changes.Cost = deliverableDetail.budgetCost;
    }
    if (deliverableDetail.curveProfileCode) {
      update.changes.CurveProfileCode = deliverableDetail.curveProfileCode;
    }

    return this.apiService.updateProperty(EntityTypes.deliverable, update);
  }

  moveDeliverable(deliverableId: number, packageFhId: number) {
    const params = new HttpParams()
      .set('packageFunctionalHierarchyId', packageFhId.toString())
      .set('deliverableId', deliverableId.toString());
    const { baseUrl, moveDeliverable } = this.appConfigService.settings.api.endpoints;
    return this.httpClient.put<any>(baseUrl + moveDeliverable, {}, { params }).pipe(
      tap(() => this.notificationService.success('deliverable.successfullyMoved')),
      catchError((response) => {
        showHttpErrorResponse(this.notificationService, response);
        return throwError(response);
      }),
    );
  }
}
