import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectImportColumns } from '../../project-import.models';

export interface ReviewImportViewState {
  filtersState?: ReviewImportFilterState[];
}

export interface ReviewImportFilterState {
  filterId: ProjectImportColumns;
  state: any;
}

@Injectable()
export class ReviewImportViewStateService implements OnDestroy {
  private _viewState$ = new BehaviorSubject<ReviewImportViewState>(null);

  private onDestroy$ = new Subject<void>();

  constructor() {
    this.setViewState(this.initializeViewState());
  }

  private initializeViewState(): ReviewImportViewState {
    const reviewImportViewState: ReviewImportViewState = {
      filtersState: [],
    };
    return reviewImportViewState;
  }

  get viewState$(): Observable<ReviewImportViewState> {
    return this._viewState$.asObservable().pipe(map((viewState) => cloneDeep(viewState)));
  }

  get filtersState$(): Observable<ReviewImportFilterState[]> {
    return this.viewState$.pipe(
      map((viewState) => viewState.filtersState),
      map((filterState) => cloneDeep(filterState)),
    );
  }

  setViewState(viewState: ReviewImportViewState): void {
    this._viewState$.next(cloneDeep(viewState));
  }

  setFiltersState(filtersState: ReviewImportFilterState[]): void {
    this.setViewState({
      ...this._viewState$.value,
      filtersState,
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
