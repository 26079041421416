import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { NavigationService } from 'src/app/project-management/tree-navigation/navigation.service';
import { PackageRelationshipItem, RelationshipType } from '../PackageRelationship.model';

@Component({
  selector: 'app-package-relationships-table',
  templateUrl: './package-relationships-table.component.html',
  styleUrls: ['./package-relationships-table.component.scss'],
})
export class PackageRelationshipsTableComponent implements OnInit, OnChanges {
  @Input() relationships: PackageRelationshipItem[];
  @Input() canModifyRelations: boolean;
  @Input() projectId: number;
  @Input() crView = false;
  @Output() removeRelationship = new EventEmitter<PackageRelationshipItem>();
  actions: any[] = [];
  constructor(private navigationService: NavigationService) {
    this.dataSource = new MatLegacyTableDataSource([]);
  }
  dataSource: MatLegacyTableDataSource<PackageRelationshipItem>;
  displayedColumns = ['type', 'packageCode'];

  ngOnChanges(changes: SimpleChanges): void {
    if ('relationships' in changes) {
      this.relationships.sort((a, b) => a.relationType - b.relationType);
      this.relationships = [
        ...this.relationships.filter((x) => x.relationType === RelationshipType.Predecessor),
        ...this.relationships.filter((x) => x.relationType === RelationshipType.Successor),
        ...this.relationships.filter((x) => x.relationType === RelationshipType.Related),
      ];
      this.dataSource.data = this.relationships;
    }

    if (changes.canModifyRelations) {
      this.actions = [
        {
          icon: () =>
            !this.canModifyRelations ? '/assets/icons/icon-delete-grey.svg' : '/assets/icons/icon-delete.svg',
          label: 'projectDetails.remove',
          callBack: (x) => this.removeRelationship.emit(x),
          isDisabled: () => !this.canModifyRelations,
          isSvg: true,
        },
      ];
    }
  }

  ngOnInit() {
    if (this.crView) {
      this.displayedColumns = [
        ...this.displayedColumns,
        'currentStart',
        'currentEnd',
        'currentOverlap',
        'predictedOverlap',
        'warning',
      ];
    } else {
      this.displayedColumns = [
        ...this.displayedColumns,
        'packageTitle',
        'packageStatus',
        'responsible',
        'complete',
        'startDate',
        'endDate',
        'overlap',
        'warning',
        'actions',
      ];
    }
  }

  navigateToPackage(functionalHierarchyId: number): void {
    this.navigationService.navigate(functionalHierarchyId);
  }
}
