import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-language-chooser',
  templateUrl: './language-chooser.component.html',
  styleUrls: ['./language-chooser.component.scss'],
})
export class LanguageChooserComponent implements OnInit {
  localesList = environment.localesList;
  currentLocale: string;

  constructor(
    private sessionStorageService: LocalStorageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const locales: string[] = [];
    this.localesList.forEach((locale) => {
      locales.push(locale.code);
    });
    this.translateService.addLangs(locales);
    this.translateService.setDefaultLang('en');

    this.currentLocale = this.getOrInitializeLanguage();
    this.translateService.use(this.currentLocale);
  }

  translateSite(language: string): void {
    this.sessionStorageService.setItem(StorageKey.selectedLanguage, language);
    this.translateService.use(language);
  }

  private getOrInitializeLanguage(): string {
    let langUsed: string = this.sessionStorageService.getItem(StorageKey.selectedLanguage);
    if (!langUsed) {
      langUsed = 'en';
      this.sessionStorageService.setItem(StorageKey.selectedLanguage, langUsed);
    }
    return langUsed;
  }
}
