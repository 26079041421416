import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { cloneDeepWith } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { ChangeNoticeSubFunctionMoveModel } from 'src/app/common-models/submit-change-request.model';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { TreeNode } from '../tree-structure.model';

@Injectable({
  providedIn: 'root',
})
export class TreeActionsService {
  // NOTE: Identifier domains for functional hierarchy Ids and deliverable Id intersect
  //       so we need seperate maps for the two domains.
  private functionalHierarchyIdUpdates: Map<string | number, Update<any>> = new Map();
  private deliverableIdUpdates: Map<string | number, Update<any>> = new Map();

  constructor(
    private appConfigService: AppConfigService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
  ) {}

  mergeFunctionalHierarchyIdUpdates(update: Update<any>): Update<any> {
    let mergeUpdate = this.functionalHierarchyIdUpdates.get(update.id);
    if (!mergeUpdate) {
      this.functionalHierarchyIdUpdates.set(update.id, cloneDeepWith(update));
    } else {
      mergeUpdate = {
        id: update.id as number,
        changes: { ...mergeUpdate.changes, ...update.changes },
      };
      this.functionalHierarchyIdUpdates.set(update.id, cloneDeepWith(mergeUpdate));
    }
    return this.functionalHierarchyIdUpdates.get(update.id);
  }

  mergeDeliverableIdUpdates(update: Update<any>): Update<any> {
    let mergeUpdate = this.deliverableIdUpdates.get(update.id);
    if (!mergeUpdate) {
      this.deliverableIdUpdates.set(update.id, cloneDeepWith(update));
    } else {
      mergeUpdate = {
        id: update.id as number,
        changes: { ...mergeUpdate.changes, ...update.changes },
      };
      this.deliverableIdUpdates.set(update.id, cloneDeepWith(mergeUpdate));
    }
    return this.deliverableIdUpdates.get(update.id);
  }

  cancelFunctionOrPackage(functionalHierarchyId: number, title: string, justification: string): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const cancelUrl = this.appConfigService.settings.api.endpoints.cancelFunctionalHierarchy;
    return this.httpClient
      .post<any>(baseUrl + cancelUrl, {
        functionalHierarchyId,
        title,
        justification,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          showHttpErrorResponse(this.notificationService, err);
          return of();
        }),
      );
  }

  reinstateFunctionOrPackage(functionalHierarchyId: number, successMessage: string): Observable<any> {
    const params = new HttpParams().set('functionalHierarchyId', functionalHierarchyId.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.reinstateFunctionalHierarchy;
    return this.httpClient.put<any>(baseUrl + endpoint, {}, { params }).pipe(
      map(() => this.notificationService.success(successMessage)),
      catchError((err) => {
        showHttpErrorResponse(this.notificationService, err, 'treeItemMenu.Reinstate');
        return of();
      }),
    );
  }

  publishFunctionOrPackage(functionalHierarchyId: number, successMessage: string, reason = ''): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const publishUrl = this.appConfigService.settings.api.endpoints.publishFunctionalHierarchy;
    return this.httpClient.post<any>(baseUrl + publishUrl, { functionalHierarchyId, reason }).pipe(
      map(() => this.notificationService.success(successMessage)),
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err, 'treeItemMenu.Publish');
        return of();
      }),
    );
  }

  baselineFunctionOrPackage(functionalHierarchyId: number, projectId: number, successMessage: string): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const baselineUrl = this.appConfigService.settings.api.endpoints.baselineFunctionalHierarchy;
    return this.httpClient
      .post<any>(baseUrl + baselineUrl, {
        functionalHierarchyId,
        projectId,
      })
      .pipe(
        map(() => this.notificationService.success(successMessage)),
        catchError((err: HttpErrorResponse) => {
          showHttpErrorResponse(this.notificationService, err, 'treeItemMenu.Baseline');
          return of();
        }),
      );
  }

  deleteFunctionOrPackage(functionalHierarchyId: number): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deleteUrl = this.appConfigService.settings.api.endpoints.deleteFunctionalHierarchy;
    return this.httpClient.post<any>(baseUrl + deleteUrl, functionalHierarchyId).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of();
      }),
    );
  }

  deleteDraftFunctionOrPackage(functionalHierarchyId: number): Observable<any> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deleteUrl = this.appConfigService.settings.api.endpoints.deleteDraftFunctionalHierarchy;
    return this.httpClient.post<any>(baseUrl + deleteUrl, functionalHierarchyId).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of();
      }),
    );
  }

  addTreeNode(functionalHierarchyId: number, title: string): Observable<TreeNode> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.addFunctionalHierarchy;

    return this.httpClient.post<TreeNode>(baseUrl + endpoint, {
      parentId: functionalHierarchyId,
      title,
    });
  }

  movePackage(packageId: number, parentId: number): Observable<any> {
    const params = new HttpParams().set('packageId', packageId.toString()).set('parentId', parentId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.movePackage;

    return this.httpClient.put<any>(baseUrl + endpoint, {}, { params }).pipe(
      tap(() => this.notificationService.success('package.successfullyMoved')),
      catchError((response) => {
        showHttpErrorResponse(this.notificationService, response);
        return of(response);
      }),
    );
  }

  moveSubfunction(functionalHierarchyId: number, parentId: number): Observable<any> {
    const params = new HttpParams()
      .set('functionalHierarchyId', functionalHierarchyId.toString())
      .set('parentId', parentId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.moveSubfunction;

    return this.httpClient.put<any>(baseUrl + endpoint, {}, { params }).pipe(
      tap(() => this.notificationService.success('moveSubfunctionModal.successfullyMoved')),
      catchError((response) => {
        showHttpErrorResponse(this.notificationService, response);
        return of(response);
      }),
    );
  }

  moveSubfunctionCr(
    functionFunctionalHierarchyId: number,
    destinationParentFunctionalHierarchyId: number,
    reason: string,
  ): Observable<any> {
    const request: ChangeNoticeSubFunctionMoveModel = {
      functionFunctionalHierarchyId,
      destinationParentFunctionalHierarchyId,
      reason,
    };

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const endpoint = this.appConfigService.settings.api.endpoints.moveSubfunctionCr;

    return this.httpClient.post<void>(baseUrl + endpoint, request).pipe(
      tap(() => this.notificationService.success('moveSubfunctionModal.successfullyMoved')),
      catchError((response: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, response);
        return of(response);
      }),
    );
  }

  completeDeliverablePatch(deliverable: DeliverableDetail): void {
    // As the API's "patch" routes return the whole entity we can simply remove the entry for the entity from the map
    this.deliverableIdUpdates.delete(deliverable.deliverableId);
  }

  completeEntityPatch(entityType: EntityTypes, entity: any): void {
    // As the API's "patch" routes return the whole entity we can simply remove the entry for the entity from the map
    if (entityType === EntityTypes.package) {
      this.functionalHierarchyIdUpdates.delete(entity.packageFunctionalHierarchyId);
    } else {
      this.functionalHierarchyIdUpdates.delete(entity.functionalHierarchyId);
    }
  }
}
