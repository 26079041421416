import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-no-rows-overlay',
  template: `<p class="no-records">
    {{ 'general.noRecordsToDisplay' | translate }}
  </p>`,
  styles: [
    `
      text-align: center;
    `,
  ],
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  public params!: INoRowsOverlayParams & { noRowsMessageFunc: () => string };

  agInit(params: INoRowsOverlayParams & { noRowsMessageFunc: () => string }): void {
    this.params = params;
  }
}
