<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <h2>
    {{ 'changeRequest.rejectionReason' | translate }}
    <button [mat-dialog-close]="'closed'" type="button" class="orms-close-button"></button>
  </h2>
  <div class="content">
    <textarea
      class="form-control orms-simple-input"
      formControlName="reason"
      [placeholder]="'changeRequest.reason' | translate"
      [class.is-invalid]="showInvalidState('reason')"
    ></textarea>
  </div>
  <div class="action-buttons">
    <button [disabled]="submitDisabled" class="orms-button orms-button-primary submit-button" type="submit">
      {{ 'general.reject' | translate }}
    </button>
    <button class="orms-button orms-button-secondary" type="button" [mat-dialog-close]="'closed'">
      {{ 'general.close' | translate }}
    </button>
  </div>
</form>
