<div class="vertical-center">
  <div class="header">
    <div class="logo-title">
      <img src="/assets/images/logo.svg" class="logo" alt="{{ 'imgAlt.hatchLogo' | translate }}" />
      <div class="title">{{ 'general.productName' | translate }}</div>
    </div>
    <div class="description">{{ 'terms&privacy.title' | translate }}</div>
  </div>

  <div class="content">
    <p>{{ 'terms&privacy.paragraph1' | translate }}</p>
    <p>{{ 'terms&privacy.paragraph2' | translate }}</p>
    <button class="orms-button orms-button-primary return-button" routerLink="/login">
      {{ 'general.returnToLogin' | translate }}
    </button>
  </div>
  <div class="footer">
    <div class="app-version">{{ 'general.version' | translate }} {{ appVersion }}</div>
    <img src="/assets/images/logo-red.svg" class="logo-red" alt="{{ 'imgAlt.hatchLogo' | translate }}" />
    <div class="copyright">
      <span>
        {{ 'general.copyright' | translate }} {{ currentDate | date: 'yyyy' }}.
        {{ 'general.rightsReserved' | translate }}
      </span>
    </div>
  </div>
</div>
