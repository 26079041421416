import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';

@Component({
  selector: 'app-function-curve',
  templateUrl: './function-curve.component.html',
  styleUrls: ['./function-curve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionCurveComponent {
  entityType = EntityTypes.function;
}
