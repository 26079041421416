import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { RefreshCRTriggerService } from '../../refresh-cr-trigger.service';
import * as crActions from '../change-requests/change-request.actions';
import * as packageActions from './package.actions';

@Injectable()
export class ChangeRequestPackageEffects {
  constructor(
    private actions: Actions,
    private store$: Store<AppState>,
    private refreshCRTriggerService: RefreshCRTriggerService,
  ) {}

  addPackageChangeRequestEffect = createEffect(() =>
    this.actions.pipe(
      ofType(packageActions.addPackageChange),
      map((action) =>
        crActions.createChange({
          functionalHierarchyId: action.crPackage.functionId,
        }),
      ),
    ),
  );

  removeAllPackagesChangesEffect = createEffect(
    () =>
      this.actions.pipe(
        ofType(packageActions.removeAllPackageChangesForFunction),
        tap(() => this.refreshCRTriggerService.refreshPackages()),
      ),
    { dispatch: false },
  );
}
