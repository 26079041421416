import { Filter } from 'src/app/shared-controls/searchable-dropdown/searchable-dropdown.component';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';

export enum SortDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export interface ColumnsSize {
  packageCode: string;
  documentNumber: string;
  title: string;
  startDate: string;
  endDate: string;
  responsible: string;
  hours: string;
  progressWorkflowStatus: string;
  percentageCompleted: string;
  controlledDocument: string;
  actions: string;
  deliverableId: string;
}

export enum DeliverableFilterType {
  Status = 'status',
  LookAhead = 'lookAhead',
  Responsible = 'responsible',
  FullText = 'full-text',
  Tags = 'tags',
}

export enum DeliverableStatusFilterName {
  Current = 'current',
  Incomplete = 'incomplete',
  Overdue = 'overdue',
  OutOfPlan = 'outOfPlan',
  Complete = 'complete',
  All = 'all',
}

export enum LookAheadFilterType {
  startsSoon = 'startsSoon',
  dueSoon = 'dueSoon',
  startsBefore = 'startsBefore',
  dueBefore = 'dueBefore',
}

export interface LookAheadFilter {
  type: LookAheadFilterType;
  value?: any;
}

export interface DeliverablesFilters {
  status: DeliverableStatusFilterName;
  lookAhead?: LookAheadFilter;
  responsible?: Filter[];
}

export interface DeliverablesViewState {
  columns: ColumnsSize;
  sortDirection: SortDirection;
  deliverableFilters: DeliverablesFilters;
}

export const initializeDeliverablesViewState = (sessionService: SessionStorageService): DeliverablesViewState => {
  const deliverablesViewState: DeliverablesViewState = {
    deliverableFilters: {
      status: DeliverableStatusFilterName.Current,
    },
    sortDirection: SortDirection.Descending,
    columns: {
      packageCode: '120px',
      documentNumber: '120px',
      title: '220px',
      responsible: '140px',
      startDate: '80px',
      endDate: '80px',
      hours: '50px',
      progressWorkflowStatus: '110px',
      percentageCompleted: '80px',
      controlledDocument: '70px',
      actions: '36px',
      deliverableId: '30px',
    },
  };

  sessionService.setItem(StorageKey.deliverablesList, deliverablesViewState);

  return deliverablesViewState;
};
