import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import { crDeliverableChangeCount } from '../../change-request-cart-functions';
import { RefreshCRTriggerService } from '../../refresh-cr-trigger.service';
import { ChangeRequestPackage, RequestType } from '../cart-item.model';
import * as crPackageActions from '../packages/package.actions';
import * as crPackageSelectors from '../packages/package.selectors';
import * as crDeliverableActions from './crDeliverable.actions';

@Injectable()
export class ChangeRequestDeliverableEffects {
  constructor(
    private actions: Actions,
    private store$: Store<AppState>,
    private refreshCRTriggerService: RefreshCRTriggerService,
  ) {}

  sendDeliverableChangeToCartEffect = createEffect(() =>
    this.actions.pipe(
      ofType(crDeliverableActions.sendDeliverableChangeToCart),
      map((action) => {
        if (crDeliverableChangeCount(action.crDeliverable) > 0) {
          return crDeliverableActions.addDeliverableChange({
            crDeliverable: action.crDeliverable,
          });
        } else {
          return crDeliverableActions.removeDeliverableChange({
            deliverableId: action.crDeliverable.deliverableId,
            packageFunctionalHierarchyId: action.crDeliverable.packageFunctionalHierarchyId,
          });
        }
      }),
    ),
  );

  sendDeliverableMoveRequestToCartEffect = createEffect(() =>
    this.actions.pipe(
      ofType(crDeliverableActions.sendDeliverableMoveRequestToCart),
      map(({ mrDeliverable }) => {
        if (mrDeliverable.packageCode !== mrDeliverable.newPackageCode) {
          return crDeliverableActions.addDeliverableMoveRequest({
            mrDeliverable,
          });
        } else {
          return crDeliverableActions.removeDeliverableMoveRequest({
            deliverableId: mrDeliverable.deliverableId,
            packageFunctionalHierarchyId: mrDeliverable.packageFunctionalHierarchyId,
          });
        }
      }),
    ),
  );

  addDeliverablePackageChangeRequestEffect = createEffect(() =>
    this.actions.pipe(
      ofType(crDeliverableActions.addDeliverableChange),
      mergeMap((action) =>
        this.store$
          .select(
            crPackageSelectors.checkPackageChangeRequestExists({
              functionalHierarchyId: action.crDeliverable.packageFunctionalHierarchyId,
            }),
          )
          .pipe(
            take(1),
            mergeMap((crPackageExists) => {
              if (crPackageExists) {
                return of(null);
              } else {
                return this.store$
                  .select(
                    TreeSelectors.getTreeNodeByFunctionalHierarchyId({
                      functionalHierarchyId: action.crDeliverable.packageFunctionalHierarchyId,
                    }),
                  )
                  .pipe(take(1));
              }
            }),
            map((packageDetails) => {
              if (packageDetails) {
                const cr = new ChangeRequestPackage();
                cr.functionId = action.crDeliverable.functionId;
                cr.functionalHierarchyId = action.crDeliverable.packageFunctionalHierarchyId;
                cr.packageCode = packageDetails.code;
                cr.requestType = RequestType.Change;
                return crPackageActions.addPackageChange({ crPackage: cr });
              }
              return crPackageActions.noOP();
            }),
          ),
      ),
    ),
  );

  addDeliverablePackageMoveRequestEffect = createEffect(() =>
    this.actions.pipe(
      ofType(crDeliverableActions.addDeliverableMoveRequest),
      mergeMap((action) =>
        this.store$
          .select(
            crPackageSelectors.checkPackageChangeRequestExists({
              functionalHierarchyId: action.mrDeliverable.packageFunctionalHierarchyId,
            }),
          )
          .pipe(
            take(1),
            mergeMap((crPackageExists) => {
              if (crPackageExists) {
                return of(null);
              } else {
                return this.store$
                  .select(
                    TreeSelectors.getTreeNodeByFunctionalHierarchyId({
                      functionalHierarchyId: action.mrDeliverable.packageFunctionalHierarchyId,
                    }),
                  )
                  .pipe(take(1));
              }
            }),
            map((packageDetails) => {
              if (packageDetails) {
                const cr = new ChangeRequestPackage();
                cr.functionId = action.mrDeliverable.functionId;
                cr.functionalHierarchyId = action.mrDeliverable.packageFunctionalHierarchyId;
                cr.packageCode = packageDetails.code;
                cr.requestType = RequestType.Move;
                return crPackageActions.addPackageChange({ crPackage: cr });
              }
              return crPackageActions.noOP();
            }),
          ),
      ),
    ),
  );

  removeDeliverablePackageChangeRequestEffect = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          crDeliverableActions.removeDeliverableChange,
          crDeliverableActions.removeAllDeliverableChangesForFunction,
        ),
        map(() => this.refreshCRTriggerService.refreshDeliverables()),
      ),
    { dispatch: false },
  );

  removeDeliverablePackageMoveRequestEffect = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          crDeliverableActions.removeDeliverableChange,
          crDeliverableActions.removeAllDeliverableChangesForFunction,
        ),
        map(() => this.refreshCRTriggerService.refreshDeliverables()),
      ),
    { dispatch: false },
  );
}
