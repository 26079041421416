import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from 'src/app/root-store/app-state';

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    let data: any;

    let params = {
      ...route.params,
    };
    while (route.firstChild) {
      route = route.firstChild;
      params = {
        ...params,
        ...route.params,
      };
      data = route.data;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;

    return { url, params, queryParams, data };
  }
}
