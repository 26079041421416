<app-node-header-view
  *ngIf="nodeType !== entityTypes.deliverable; else deliverableHeader"
  [entityId]="nodeId"
  [entityType]="nodeType"
>
  <app-breadcrumbs [options]="breadcrumbOptions"></app-breadcrumbs>
</app-node-header-view>
<ng-template #deliverableHeader>
  <app-deliverable-header-view
    [viewOnly]="true"
    [entityId]="nodeId"
    [entityType]="nodeType"
    [breadcrumbOptions]="breadcrumbOptions"
    [packageUrl]="packageUrl"
  >
  </app-deliverable-header-view>
</ng-template>
<nav mat-tab-nav-bar [tabPanel]="">
  <a
    mat-tab-link
    *ngFor="let link of links"
    routerLink="{{ link.path }}"
    class="tab orms-link-tab"
    routerLinkActive="orms-active-link-tab"
    defaultTabIndex="0"
  >
    {{ link.display | translate }}
  </a>
</nav>
<router-outlet></router-outlet>
