import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { FormatHistoryValuePipe } from './format-history-value.pipe';
import { FromCamelCasePipe } from './from-camel-case.pipe';
import {
  containsMoreThanOneChange,
  containsOnlyOneChange,
  containsRichTextChange,
  isChildAction,
  isCreatedAction,
  isDeliverableAdded,
  isDeliverableMovedAction,
  isDeliverableRemoved,
  isGrantPermissionAction,
  isPackageMovedAction,
  isRelationshipAction,
  isRevokePermissionAction,
  isSubfunctionMovedAction,
  isTagAction,
} from './history-common-functions';
import { HistoryData } from './history-data.model';
import { HistoryDetail } from './history-detail.model';
import { HistoryAction } from './history-enums';

@Pipe({
  name: 'historyChangeSummary',
})
export class HistoryChangeSummaryPipe implements PipeTransform {
  constructor(
    private fromCamelCasePipe: FromCamelCasePipe,
    private formatHistoryValuePipe: FormatHistoryValuePipe,
    private translateService: TranslateService,
  ) {}

  transform(data: HistoryData, historySubject: EntityTypes): string {
    if (data.changeNoticeId) {
      return `${data.userName} Approved Change Request`;
    }
    const entityName = this.fromCamelCasePipe.transform(EntityTypes[historySubject]);

    if (isDeliverableAdded(data)) {
      return `${data.userName} ${this.translateService.instant('history.addedDeliverable')}`;
    }

    if (isDeliverableRemoved(data)) {
      return `${data.userName} ${this.translateService.instant('history.deletedDeliverable')}`;
    }

    if (isDeliverableMovedAction(data)) {
      const details = data.details[0];
      return `${data.userName} Moved ${details.itemName} ${details.oldValue} ${details.newValue}`;
    }

    if (isPackageMovedAction(data)) {
      const details = data.details[0];
      return `${data.userName} Moved package from ${details.oldValue} to ${details.newValue}`;
    }

    if (isSubfunctionMovedAction(data)) {
      const details = data.details[0];
      return `${data.userName} Moved subfunction from ${details.oldValue} to ${details.newValue}`;
    }

    if (isCreatedAction(data)) {
      return `${data.userName} Created ${entityName}`;
    }

    if (containsRichTextChange(data)) {
      const itemName = this.fromCamelCasePipe.transform(data.details[0].itemName);
      return `${data.userName} ${HistoryAction[data.action]} ${itemName}`;
    }

    if (data.action === HistoryAction.progressed) {
      return this.formatSummaryForProgression(data);
    }

    if (isGrantPermissionAction(data)) {
      const permissionAction = this.fromCamelCasePipe.transform(HistoryAction[data.action]);
      return `${permissionAction} to ${data.userName}`;
    }

    if (isRevokePermissionAction(data)) {
      const permissionAction = this.fromCamelCasePipe.transform(HistoryAction[data.action]);
      return `${permissionAction} from ${data.userName}`;
    }

    if (isChildAction(data)) {
      const childAction = this.fromCamelCasePipe.transform(HistoryAction[data.action]);
      const details = data.details[0];
      return `${data.userName} ${childAction} ${details.itemName} ${details.newValue}`;
    }

    if (isTagAction(data)) {
      const childAction = this.fromCamelCasePipe.transform(HistoryAction[data.action]);
      const details = data.details[0];
      return `${data.userName} ${childAction} - ${details.newValue}`;
    }

    if (isRelationshipAction(data)) {
      const childAction = this.fromCamelCasePipe.transform(HistoryAction[data.action]);
      const details = data.details[0];
      return `${data.userName} ${childAction} - ${details.newValue}`;
    }

    if (containsOnlyOneChange(data)) {
      const details = data.details[0];
      return this.formatSingeItemChange(data.userName, data.action, details.itemName, details.newValue);
    }

    if (containsMoreThanOneChange(data)) {
      return `${data.userName} ${HistoryAction[data.action]} ${entityName}`;
    }

    return 'Not implemented - to check this case';
  }

  private formatSummaryForProgression(data: HistoryData): string {
    const progressItem = this.getProgressItem(data.details);
    return this.formatSingeItemChange(data.userName, data.action, progressItem.itemName, progressItem.newValue);
  }

  private getProgressItem(historyData: HistoryDetail[]): HistoryDetail {
    const workflow = historyData.find((d) => d.itemName === 'ProgressWorkflowStatus');
    if (workflow) {
      return workflow;
    }

    return historyData.find((d) => d.itemName === 'PercentageCompleted');
  }

  private formatSingeItemChange(
    userName: string,
    action: HistoryAction,
    itemName: string,
    newValue: string | number | Date,
  ): string {
    const name = this.fromCamelCasePipe.transform(itemName);
    const itemValue = this.formatHistoryValuePipe.transform(newValue, itemName);
    return `${userName} ${HistoryAction[action]} ${name} to ${itemValue}`;
  }
}
