<app-deliverables-list
  [data$]="data$"
  [displayedColumns]="displayedColumns"
  [underChangeControl]="underChangeControl"
  [curveProfiles]="curveProfiles$ | async"
  [lastVisitedDeliverableId]="lastVisitedDeliverableId"
  [projectId]="projectId"
  (cancelDeliverable)="cancelDeliverable($event)"
  (deleteDeliverable)="deleteDeliverable($event)"
  (navigateToDeliverable)="navigateToDeliverable($event)"
  (navigateToPackage)="navigateToPackage($event)"
  (percentageCompletedClicked)="percentageCompletedClicked($event)"
>
</app-deliverables-list>

<button *ngIf="canAdd" type="button" class="add-deliverable" (click)="addDeliverable()"></button>
