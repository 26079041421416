import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatusCode, getStatusDetail } from './statusCodesMap';

@Pipe({
  name: 'statusCode',
})
export class StatusCodePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(statusCode: StatusCode): string {
    const statusDetails = getStatusDetail(statusCode);
    return this.translateService.instant(statusDetails.text);
  }
}
