import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, skip, take } from 'rxjs/operators';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import {
  ChangeRequestDeliverable,
  MoveRequestDeliverable,
  RequestType,
} from 'src/app/project-management/change-request-cart/change-request-cart-state/cart-item.model';
import { addDeliverableChange } from 'src/app/project-management/change-request-cart/change-request-cart-state/deliverables/crDeliverable.actions';
import { CrDeliverableSelectors } from 'src/app/project-management/change-request-cart/change-request-cart-state/deliverables/crDeliverable.selectors';
import { AddDeliverableComponent } from 'src/app/project-management/deliverable/add-deliverable/add-deliverable.component';
import { addDeliverable } from 'src/app/project-management/deliverable/deliverable-state/deliverable.actions';
import { SignalRService } from 'src/app/project-management/signalr/signalr.service';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import { getIncludeCancelledItems, getProjectIdSelector, getRouteType } from 'src/app/root-store/root-store.selector';
import { ConfirmationDialogueComponent } from 'src/app/shared-controls/confirmation-dialogue/confirmation-dialogue.component';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { DeliverablePercentageCompleteComponent } from '../../deliverable-percentage-complete/deliverable-percentage-complete.component';
import { DeliverableDetail } from '../DeliverableDetail';
import * as deliverablesListActions from '../deliverables-list-state/deliverables-list.actions';
import { getDeliverablesListForCurrentNode } from '../deliverables-list-state/deliverables-list.selectors';
import { DeliverablesService } from '../deliverables.service';

@Component({
  selector: 'app-deliverables-route',
  templateUrl: './deliverables-route.component.html',
  styleUrls: ['./deliverables-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliverablesRouteComponent implements OnInit, OnDestroy {
  @Input() nodeId: number;
  @Input() displayedColumns: string[];

  data$ = new BehaviorSubject<DeliverableDetail[]>(null);
  newInstance: boolean;
  currentNodeType: string;
  functionId: number;
  canAdd = false;
  lastVisitedDeliverableId: number;
  destinationTitle: string;
  underChangeControl: boolean;
  projectId: number;
  curveProfiles$: Observable<CurveProfile[]>;
  private subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private dialog: MatLegacyDialog,
    private curveProfilesService: CurveProfileService,
    private deliverableService: DeliverablesService,
    private signalrService: SignalRService,
  ) {
    this.lastVisitedDeliverableId = Number(this.router.getCurrentNavigation()?.extras?.state?.lastVisitedDeliverable);
    this.curveProfiles$ = this.curveProfilesService.curveProfiles$;
  }

  ngOnInit(): void {
    this.newInstance = true;

    const currentNodeSubscription = this.store$.select(TreeSelectors.getCurrentTreeNode).subscribe((node) => {
      this.underChangeControl = node.underChangeControl;
    });
    this.subscription.add(currentNodeSubscription);

    const deliverablesListSubscription = combineLatest([
      this.store$.select(getRouteType),
      this.store$.select(getDeliverablesListForCurrentNode),
      this.store$.select(getProjectIdSelector),
      this.store$
        .select(CrDeliverableSelectors.getAllCrDeliverables)
        .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr))),
      this.store$
        .select(CrDeliverableSelectors.getAllMrDeliverables)
        .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr))),
    ]).subscribe(([routeType, deliverableListResponse, projectId, crDeliverables, mrDeliverables]) => {
      // NOTE: Combinelatest does not emit an initial value until each observable emits at least one value

      if (this.currentNodeType && this.currentNodeType !== routeType) {
        // Note that the deliverables-route component is embedded in project-deliverables, function-deliverables, and package-deliverables
        // components, as a result when the user navigates to nodes of different types the instance of this component is destroyed and
        // we are routed to a new instance. When this occurs then there is no value in processing the observables further because this
        // instance is about to disappear.
        return;
      }

      this.currentNodeType = routeType;
      this.projectId = projectId;

      if (this.newInstance || deliverableListResponse.parentId != this.nodeId) {
        // Clear grid data to indicate "loading" and dispatch a request to the server for a fresh list of deliverables.
        // Note that if we already have the deliverables cached in the store they will be loaded imediately from the
        // cache and when the fresh list is observed the grid data will be reloaded. Also note that if list returned
        // by the sever matches what is in the store the store state will not change (subscription will not be triggered).
        this.data$.next(null);
        this.store$.dispatch(
          deliverablesListActions.getDeliverablesByFunctionalId({
            functionalId: deliverableListResponse.parentId,
          }),
        );
      }

      this.newInstance = false;

      const { canAdd, deliverables } = deliverableListResponse;
      this.canAdd = canAdd;
      if (deliverables) {
        let augmentedDeliverables = crDeliverables.reduce(
          (prev, curr) => this.augmentDeliverablesWithCrMr(prev, curr, 'cr'),
          [...deliverables],
        );
        augmentedDeliverables = mrDeliverables.reduce(
          (prev, curr) => this.augmentDeliverablesWithCrMr(prev, curr, 'mr'),
          [...augmentedDeliverables],
        );

        this.data$.next(
          augmentedDeliverables.map((x) => ({
            ...x,
            packageUrl: [
              '/project-management/',
              projectId,
              EntityTypes.package,
              x.packageFunctionalHierarchyId,
              'detail',
            ],
            deliverableUrl: ['/project-management/', projectId, EntityTypes.deliverable, x.deliverableId, 'detail'],
          })),
        );
      }
    });
    this.subscription.add(deliverablesListSubscription);

    const parentIdSubscription = this.store$
      .select(TreeSelectors.getParentIdBasedOnCurrentNode)
      .subscribe((parentId) => (this.functionId = parentId));
    this.subscription.add(parentIdSubscription);

    const deliverablesListChangedSubscription = this.signalrService.nodeChanged().subscribe(() => {
      this.store$.dispatch(
        deliverablesListActions.getDeliverablesByFunctionalId({
          functionalId: this.nodeId,
        }),
      );
    });
    this.subscription.add(deliverablesListChangedSubscription);

    const includeCancelledChangedSubscription = this.store$
      .select(getIncludeCancelledItems)
      .pipe(skip(1))
      .subscribe(() => {
        this.store$.dispatch(
          deliverablesListActions.getDeliverablesByFunctionalId({
            functionalId: this.nodeId,
          }),
        );
      });
    this.subscription.add(includeCancelledChangedSubscription);
  }

  augmentDeliverablesWithCrMr(
    combinedDeliverables,
    request: MoveRequestDeliverable | ChangeRequestDeliverable,
    type: 'mr' | 'cr',
  ) {
    const matchingDeliverableIndex = combinedDeliverables.findIndex(
      (combinedDeliverable) => combinedDeliverable.deliverableId === request.deliverableId,
    );
    if (matchingDeliverableIndex !== -1) {
      combinedDeliverables[matchingDeliverableIndex] = {
        ...combinedDeliverables[matchingDeliverableIndex],
        [type]: request,
      };
    }
    return combinedDeliverables;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToDeliverable(deliverableId: number): void {
    const projectIdSubscription = this.store$
      .select(getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.router.navigate(['project-management/', projectId, EntityTypes.deliverable, deliverableId, 'detail'], {
          state: {
            returnId: this.nodeId,
          },
        });
      });
    this.subscription.add(projectIdSubscription);
  }

  navigateToPackage(packageFunctionalHierarchyId: number): void {
    const projectIdSubscription = this.store$
      .select(getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.router.navigate([
          'project-management/',
          projectId,
          EntityTypes.package,
          packageFunctionalHierarchyId,
          'detail',
        ]);
      });
    this.subscription.add(projectIdSubscription);
  }

  percentageCompletedClicked(clickInputData: {
    event: Event;
    element: DeliverableDetail;
    button: HTMLButtonElement;
  }): void {
    const { event, element, button } = clickInputData;
    this.stopEventPropagation(event);

    if (
      element.editableFields.includes('percentagecompleted') ||
      element.editableFields.includes('progressworkflowstatusid')
    ) {
      const dialogRef = this.dialog.open(DeliverablePercentageCompleteComponent, {
        data: element,
        panelClass: 'orms-deliverable-progress-dialog',
      });

      dialogRef.afterClosed().subscribe(() => {
        button.blur();
      });
    }
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }

  addDeliverable(): void {
    const dialogTitle = this.underChangeControl ? 'deliverable.requestNewDeliverable' : 'deliverable.addDeliverable';

    this.dialog
      .open(AddDeliverableComponent, {
        data: { title: dialogTitle },
        panelClass: 'orms-add-deliverable',
      })
      .afterClosed()
      .pipe(filter((crd) => crd))
      .subscribe((crd) => {
        if (!this.underChangeControl) {
          this.store$.dispatch(
            addDeliverable({
              deliverable: crd,
            }),
          );
        } else {
          this.store$.dispatch(
            addDeliverableChange({
              crDeliverable: crd,
            }),
          );
        }
      });
  }

  cancelDeliverable(element: DeliverableDetail): void {
    const crd = new ChangeRequestDeliverable();
    crd.deliverableId = element.deliverableId;
    crd.functionId = this.functionId;
    crd.packageFunctionalHierarchyId = element.packageFunctionalHierarchyId ?? this.nodeId;
    crd.documentNumber = element.documentNumber;
    crd.packageCode = element.packageCode;
    crd.request = RequestType.Cancel;
    crd.oldCost = crd.newCost = element.cost;
    crd.oldHours = crd.newHours = element.hours;
    crd.oldCurveProfileCode = crd.newCurveProfileCode = element.curveProfileCode;
    crd.progressWorkflowId = element.progressWorkflowId;
    crd.title = element.title;

    this.store$.dispatch(
      addDeliverableChange({
        crDeliverable: crd,
      }),
    );
  }

  deleteDeliverable(element: DeliverableDetail): void {
    this.dialog.open(ConfirmationDialogueComponent, {
      data: {
        title: 'deliverable.deleteDeliverable',
        message: 'deliverable.deleteDeliverableConfirmation',
        callBack: () => this.deliverableService.deleteDeliverable(element.deliverableId),
      },
      panelClass: 'orms-delete-deliverable-dialog',
      disableClose: true,
    });
  }
}
