import { createAction, props } from '@ngrx/store';
import { DeliverableListResponse } from '../deliverables.service';

export enum DeliverablesListActionTypes {
  GET_DELIVERABLES_BY_FUNCTIONAL_ID = '[Deliverables list] Get deliverables list by functional ID',
  GET_DELIVERABLES_BY_FUNCTIONAL_ID_SUCCESS = '[Deliverables list] Get deliverables by functional ID Success',
  DELETE_DELIVERABLE = '[Deliverables list] Delete deliverable',
  DELETE_DELIVERABLE_SUCCESS = '[Deliverables list] Delete deliverable success',
  DELIVERABLES_LIST_NO_OP = '[Deliverables list] No op action',
}

export const getDeliverablesByFunctionalId = createAction(
  DeliverablesListActionTypes.GET_DELIVERABLES_BY_FUNCTIONAL_ID,
  props<{ functionalId: number }>(),
);

export const getDeliverablesByFunctionalIdSuccess = createAction(
  DeliverablesListActionTypes.GET_DELIVERABLES_BY_FUNCTIONAL_ID_SUCCESS,
  props<{ deliverablesListResponse: DeliverableListResponse }>(),
);

export const deliverablesListNoOpAction = createAction(DeliverablesListActionTypes.DELIVERABLES_LIST_NO_OP);

export const deleteDeliverable = createAction(
  DeliverablesListActionTypes.DELETE_DELIVERABLE,
  props<{ deliverableId: number }>(),
);

export const deleteDeliverableSuccess = createAction(
  DeliverablesListActionTypes.DELETE_DELIVERABLE_SUCCESS,
  props<{ deliverableId: number }>(),
);
