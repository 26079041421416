import { createAction, props } from '@ngrx/store';
import { ProjectDetails } from 'src/app/common-models/project.model';

export enum ProjectActionTypes {
  GET_PROJECT_DETAILS = '[Project] Get Project Details',
  GET_PROJECT_DETAILS_SUCCESS = '[Project] Get Project Project Details success',
  GET_PROJECT_DETAILS_FAILURE = '[Project] Get Project Project Details failure',
  CLEAR_PROJECT_DETAILS = '[Project] Clear Project details',
}

export const clearProjectDetails = createAction(ProjectActionTypes.CLEAR_PROJECT_DETAILS);

export const getProjectDetails = createAction(ProjectActionTypes.GET_PROJECT_DETAILS, props<{ projectId: number }>());

export const getProjectDetailsSuccess = createAction(
  ProjectActionTypes.GET_PROJECT_DETAILS_SUCCESS,
  props<{ projectDetails: ProjectDetails }>(),
);

export const getProjectDetailsFailure = createAction(
  ProjectActionTypes.GET_PROJECT_DETAILS_FAILURE,
  props<{ error: string }>(),
);
