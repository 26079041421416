import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatLegacyTabGroup } from '@angular/material/legacy-tabs';
import { Subscription } from 'rxjs';

export interface Action {
  next: string;
  previous: string;
}

export interface Step {
  label: string;
  subLabel?: string;
  visited: boolean;
  title?: string;
  subTitle?: string;
  content: any;
  labelTemplate?: TemplateRef<any>;
  stepHeaderTemplate?: TemplateRef<any>;
}

export interface WizardData {
  wizardTitle: string;
  projectTitle: string;
  steps: Step[];
  onCancelAction: () => void;
  onCompleteAction: () => void;
  headerTemplate?: TemplateRef<any>;
  context?: any;
}

export interface WizardStepComponent {
  nextStepClick: EventEmitter<void>;
  previousStepClick: EventEmitter<void>;
  cancelClick: EventEmitter<void>;
  context?: any;
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardComponent implements AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef, static: false })
  public viewContainer: ViewContainerRef;
  @ViewChild('tabs') tabGroup: MatLegacyTabGroup;
  @Input() data: WizardData;
  hostedComponentRef: ComponentRef<WizardStepComponent>;

  public ngAfterViewInit(): void {
    this.tabGroup.selectedIndex = 1;
  }

  public tabChange(index: number) {
    setTimeout(() => {
      this.renderComponent(index);
    });
  }

  private renderComponent(index: number) {
    this.hostedComponentRef = this.viewContainer?.createComponent<WizardStepComponent>(
      this.data.steps[index - 1].content,
    );
    const sub = new Subscription();

    sub.add(
      this.hostedComponentRef.instance?.nextStepClick.subscribe(() => {
        if (this.data.steps.length === index) {
          this.data.onCompleteAction();
        } else {
          this.next();
        }
      }),
    );

    sub.add(this.hostedComponentRef.instance?.previousStepClick.subscribe(() => this.previous()));

    sub.add(this.hostedComponentRef.instance?.cancelClick.subscribe(() => this.data.onCancelAction()));

    this.hostedComponentRef?.onDestroy(() => {
      sub.unsubscribe();
    });

    if (this.hostedComponentRef.instance && this.data.context) {
      this.hostedComponentRef.instance.context = this.data.context;
    }

    this.hostedComponentRef.changeDetectorRef.detectChanges();
  }

  next() {
    this.data.steps[this.tabGroup.selectedIndex - 1].visited = true;
    this.tabGroup.selectedIndex = this.tabGroup.selectedIndex + 1;
  }

  previous() {
    this.tabGroup.selectedIndex = this.tabGroup.selectedIndex - 1;
  }
}
