import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { HierarchySearchCallback } from '../hierarchy-search-callback.model';
import { TreeActionsService } from '../tree-actions-menu/tree-actions.service';
import { TreeSelectors } from '../tree-state/tree.selectors';

@Component({
  selector: 'app-tree-action-move-subfunction',
  templateUrl: './tree-action-move-subfunction.component.html',
  styleUrls: ['./tree-action-move-subfunction.component.scss'],
})
export class TreeActionMoveSubfunctionComponent implements OnInit, OnDestroy {
  submissionInProgress = false;
  form = new UntypedFormGroup({
    selectedHierarchy: new UntypedFormControl(null, Validators.required),
  });
  originalNodeTitle: string;
  destinationNodeTitle: string;
  treeParent$: Observable<any[]>;
  treeParentInput$ = new Subject<string>();
  loading = false;
  private projectHierarchyRulesSubscription: Subscription;

  constructor(
    public dialogRef: MatLegacyDialogRef<TreeActionMoveSubfunctionComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      functionalHierarchyId: number;
      parentLevel: number;
      subfunctionName: string;
    },
    private store$: Store<AppState>,
    private treeActionsService: TreeActionsService,
  ) {}

  ngOnInit(): void {
    this.treeParent$ = concat(
      of([]),
      this.treeParentInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap((searchTerm) =>
          this.hierarchySearch(searchTerm).pipe(
            catchError(() => of([])),
            tap(() => (this.loading = false)),
          ),
        ),
      ),
    );

    this.projectHierarchyRulesSubscription = this.store$
      .select(TreeSelectors.getProjectHierarchyRules)
      .pipe(filter((x) => !!x.length))
      .subscribe((rules) => {
        this.originalNodeTitle = rules.find((r) => r.level === this.data.parentLevel + 1).title;
        this.destinationNodeTitle = rules.find((r) => r.level === this.data.parentLevel).title;
      });
  }

  confirm() {
    this.submissionInProgress = true;

    const { selectedHierarchy } = this.form.value;
    if (!selectedHierarchy) {
      return;
    }

    this.moveSubfunction(selectedHierarchy);
  }

  private hierarchySearch(searchTerm: string): Observable<HierarchySearchCallback[]> {
    return this.store$
      .select(
        TreeSelectors.getSubfunctionsWithNameStartingFromForMoveSubfunction({
          term: searchTerm,
          sourceSubfunctionId: this.data.functionalHierarchyId,
        }),
      )
      .pipe(
        map((nodes) => {
          return nodes.map(
            ({ functionalHierarchyId, title, code }) =>
              ({
                hierarchyId: functionalHierarchyId,
                title,
                code,
                displayName: this.displayTile(title, code),
              }) as HierarchySearchCallback,
          );
        }),
      );
  }

  private moveSubfunction(entity: HierarchySearchCallback) {
    this.treeActionsService.moveSubfunction(this.data.functionalHierarchyId, entity.hierarchyId).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private displayTile(title: string, code: string): string {
    const codePart = code ? ' <' + code + '>' : '';
    return title + codePart;
  }

  ngOnDestroy(): void {
    this.projectHierarchyRulesSubscription?.unsubscribe();
  }
}
