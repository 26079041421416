<div class="orms-cr-submission-form small-container">
  <h2>
    {{ 'projectImport.noChangesDetected' | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <h5>{{ 'projectImport.retryWithDifferentFile' | translate }}</h5>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button class="orms-button orms-button-primary submit-button" type="button" (click)="ok()">
        {{ 'projectImport.ok' | translate }}
      </button>
    </div>
  </div>
</div>
