import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { Scope } from './scope.model';
import { ScopeService } from './scope.service';

@Component({
  selector: 'app-package-scope',
  templateUrl: './package-scope.component.html',
  styleUrls: ['./package-scope.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageScopeComponent implements OnInit, OnDestroy {
  scopeData = new Scope();
  isEditable$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);
  public init: any;
  private scopeButtonApi: any;
  private editorApi: any;
  private functionalHierarchyId: number;
  private readonly subscription = new Subscription();

  constructor(
    private scopeService: ScopeService,
    private appConfigService: AppConfigService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
  ) {
    const component = this;
    this.init = {
      ...this.appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...this.appConfigService.settings.richTextConfig.scopeInitOptions,
    };

    this.init.setup = (editor: any) => {
      editor.ui.registry.addButton('scopeSaveButton', {
        text: this.translateService.instant('general.save'),
        onAction: () => {
          component.scopeButtonApi.setEnabled(false);
          const content = editor.getContent();
          component.saveClick(content);
        },
        onSetup: (buttonApi) => {
          component.scopeButtonApi = buttonApi;
          component.editorApi = editor;

          editor.on('Dirty', this.checkIsDirty);
          editor.on('SetContent', this.checkIsDirty);

          /* onSetup should always return the unbind handlers (as per docs of tiny) */
          return () => {
            editor.off('Dirty', this.checkIsDirty);
            editor.off('SetContent', this.checkIsDirty);
          };
        },
      });
    };
  }

  private checkIsDirty = () => {
    this.scopeButtonApi.setEnabled(this.editorApi.isDirty());
  };

  ngOnInit(): void {
    const functionalIdSub = this.store$
      .select(routerSelectors.getIdSelector)
      .pipe(
        switchMap((id) => {
          this.loading$.next(true);
          this.scopeData = new Scope();
          this.functionalHierarchyId = id;
          return this.scopeService.getScopeData(id);
        }),
      )
      .subscribe((scope) => {
        this.scopeData = scope;
        this.isEditable$.next(scope?.editableFields?.includes('scope'));
        this.loading$.next(false);
      });

    this.subscription.add(functionalIdSub);
  }

  saveClick(newValue: string): void {
    const updateScopeSubscription = this.scopeService.updateScope(this.functionalHierarchyId, newValue).subscribe(
      () => {
        this.editorApi.setDirty(false);
        this.checkIsDirty();
      },
      (_error) => {
        this.scopeButtonApi.setEnabled(true);
      },
    );
    this.subscription.add(updateScopeSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
