import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { clearCurrentUser } from 'src/app/root-store/root-store.actions';
import { checkAndSetActiveAccount } from 'src/app/shared/msal-config/msal-helpers';
import { AppConfigService } from 'src/app/shared/services/app.config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isUserLogged = false;
  username = '';
  currentDate = new Date();
  mailTo: string;
  appVersion: string;
  getStartedLink: string;

  private subscription = new Subscription();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appConfigService: AppConfigService,
    private router: Router,
    private translationService: TranslateService,
    private store$: Store<AppState>,
  ) {
    this.mailTo = this.appConfigService.settings.support.needHelpEmail;
    this.appVersion = this.appConfigService.settings.version.number + this.appConfigService.settings.version.build;
    const settingsHpac = this.appConfigService.settings.hpac;
    this.getStartedLink = settingsHpac.baseUrl + settingsHpac.getStartedReady;
  }

  ngOnInit(): void {
    const inProgressSubscription = this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe((x) => {
        this.setLoginDisplay();
        checkAndSetActiveAccount(this.authService);
      });

    this.subscription.add(inProgressSubscription);
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    const extraQueryParameters = {
      productName: this.translationService.instant('general.productName'),
    };
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
            extraQueryParameters,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
          extraQueryParameters,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout(): void {
    this.store$.dispatch(clearCurrentUser());
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/login',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setLoginDisplay(): void {
    const firstAccount = this.authService.instance.getAllAccounts()[0];
    if (firstAccount) {
      this.isUserLogged = true;
      this.username = firstAccount.name;
      this.router.navigate(['/']);
    }
  }
}
