import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { StatusCode } from 'src/app/shared-controls/status/statusCodesMap';
import { deleteNodeSuccess } from '../../tree-state/tree.actions';
import { TreeSelectors } from '../../tree-state/tree.selectors';
import { TreeActionsService } from '../tree-actions.service';

@Component({
  selector: 'app-tree-action-delete-submit',
  templateUrl: './tree-action-delete-submit.component.html',
  styleUrls: ['./tree-action-delete-submit.component.scss'],
})
export class TreeActionDeleteSubmitComponent {
  submissionInProgress = false;
  constructor(
    public dialogRef: MatLegacyDialogRef<TreeActionDeleteSubmitComponent>,
    private treeActionsService: TreeActionsService,
    private store$: Store<AppState>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private data: { functionalHierarchyId: number; status: StatusCode },
  ) {}

  deleteItem(): void {
    this.submissionInProgress = true;
    const isNodeDraft = this.data?.status === StatusCode.Draft;
    of('')
      .pipe(
        switchMap(() =>
          isNodeDraft
            ? this.treeActionsService.deleteDraftFunctionOrPackage(this.data.functionalHierarchyId)
            : this.treeActionsService.deleteFunctionOrPackage(this.data.functionalHierarchyId),
        ),
        finalize(() => (this.submissionInProgress = false)),
      )

      .subscribe(() => {
        this.store$.dispatch(deleteNodeSuccess({ nodeId: this.data.functionalHierarchyId }));
        this.store$.select(TreeSelectors.selectAllTreeEntities).subscribe({
          next: () => this.dialogRef.close(),
          error: () => this.dialogRef.close(),
          complete: () => this.dialogRef.close(),
        });
      });
  }
}
