import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, zip } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ShadowInputValidationService } from 'src/app/shared/services/shadow-input-validation.service';
import { ApiService } from '../../common/api.service';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { DeliverablesService } from '../../common/navigation-tabs/deliverables/deliverables.service';
import { TagsService } from '../../common/navigation-tabs/deliverables/tags.service';
import { FavoriteService } from '../../node-header/favorite.service';
import { SharedPackageService } from '../../node-header/shared.service';
import { TreeActionsService } from '../../tree-navigation/tree-actions-menu/tree-actions.service';
import * as treeActions from '../../tree-navigation/tree-state/tree.actions';
import * as deliverableActions from './deliverable.actions';
@Injectable()
export class DeliverableStoreEffects {
  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private deliverableService: DeliverablesService,
    private treeActionsService: TreeActionsService,
    private shareService: SharedPackageService,
    private notificationService: NotificationService,
    private tagsService: TagsService,
    private favoriteService: FavoriteService,
    private shadowInputValidationService: ShadowInputValidationService,
  ) {}

  getDeliverableRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.getDeliverableById),
        distinctUntilChanged((a, b) => !b.forceRefresh && a.deliverableId === b.deliverableId),
        mergeMap(({ deliverableId, projectId }) =>
          zip(
            this.deliverableService.getDeliverableById(deliverableId),
            this.tagsService.getTagsByDeliverableById(deliverableId, projectId),
          ).pipe(
            map((val) => {
              const deliverable = val[0];
              if (deliverable) {
                deliverable.tags = val[1];
              }
              return deliverableActions.getDeliverableByIdSuccess({
                deliverable,
              });
            }),
          ),
        ),
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error, 'deliverable.gettingDeliverableTagsFailed');
          return of(deliverableActions.deliveryNoOpAction());
        }),
      ),
    { dispatch: true },
  );

  getViewDeliverableRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.getViewDeliverableById),
        distinctUntilChanged((a, b) => a.deliverableId === b.deliverableId),
        switchMap(({ deliverableId, linkIdentifier }) =>
          this.shareService.getDeliverableViewDetailData(linkIdentifier, deliverableId).pipe(
            map((x) =>
              deliverableActions.getDeliverableByIdSuccess({
                deliverable: x,
              }),
            ),
          ),
        ),
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error, 'deliverable.gettingDeliverableTagsFailed');
          return of(deliverableActions.deliveryNoOpAction());
        }),
      ),
    { dispatch: true },
  );

  updateDeliverablePropertyRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.updateDeliverableProperty),
        switchMap(({ parentId, update, key }) =>
          this.apiService.updateProperty(EntityTypes.deliverable, update).pipe(
            map((deliverableResponse: DeliverableDetail) => {
              this.shadowInputValidationService.clearError(EntityTypes.deliverable, update.id, key);
              return deliverableActions.updateDeliverableSuccess({
                deliverable: deliverableResponse,
              });
            }),
            catchError((error) => {
              this.shadowInputValidationService.addError(EntityTypes.deliverable, update.id, key, error);
              showHttpErrorResponse(this.notificationService, error, 'general.updateFailed');
              return of(deliverableActions.deliveryNoOpAction());
            }),
          ),
        ),
      ),
    { dispatch: true },
  );

  addDeliverableRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.addDeliverable),
        mergeMap(({ deliverable }) =>
          this.deliverableService
            .addDeliverable({
              packageFunctionalHierarchyId: deliverable.packageFunctionalHierarchyId,
              title: deliverable.title,
              documentNumber: deliverable.documentNumber,
              hours: deliverable.newHours,
              cost: deliverable.newCost,
              curveProfileCode: deliverable.newCurveProfileCode,
              progressWorkflowId: deliverable.progressWorkflowId,
            })
            .pipe(
              map((deliverableResponse: DeliverableDetail) => {
                return deliverableActions.addDeliverableSuccess({
                  deliverable: deliverableResponse,
                });
              }),
              catchError((error) => {
                showHttpErrorResponse(this.notificationService, error, 'deliverable.addingDeliverableFailed');
                return of(deliverableActions.deliveryNoOpAction());
              }),
            ),
        ),
      ),
    { dispatch: true },
  );

  updateDeliverableRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.updateDeliverable),
        switchMap(({ deliverable }) =>
          this.deliverableService.changeDeliverableDetails(deliverable).pipe(
            map((deliverableResponse: DeliverableDetail) => {
              return deliverableActions.updateDeliverableSuccess({
                deliverable: deliverableResponse,
              });
            }),
          ),
        ),
        catchError((error) => {
          showHttpErrorResponse(this.notificationService, error, 'deliverable.updatingDeliverableFailed');
          return of(deliverableActions.deliveryNoOpAction());
        }),
      ),
    { dispatch: true },
  );

  addDeliverableTagRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.addDeliverableTag),
        mergeMap(({ tag }) =>
          this.tagsService.addTagForDeliverable(tag).pipe(
            map((createdTagId: number) => {
              return deliverableActions.addDeliverableTagSuccess({
                tag: { name: tag.tagName, id: createdTagId },
              });
            }),
            catchError((error) => {
              showHttpErrorResponse(this.notificationService, error, 'deliverable.addingDeliverableTagFailed');
              return of(deliverableActions.deliveryNoOpAction());
            }),
          ),
        ),
      ),
    { dispatch: true },
  );

  removeDeliverableTagRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.removeDeliverableTag),
        mergeMap(({ deliverableId, tagId, projectId }) =>
          this.tagsService.removeTagForDeliverable(deliverableId, tagId, projectId).pipe(
            map((_) => {
              return deliverableActions.removeDeliverableTagSuccess({
                tagId,
              });
            }),
            catchError((error) => {
              showHttpErrorResponse(this.notificationService, error, 'deliverable.removingDeliverableTagFailed');
              return of(deliverableActions.deliveryNoOpAction());
            }),
          ),
        ),
      ),
    { dispatch: true },
  );

  addPackageToUserFavoritesRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.addPackageToFavorites),
        mergeMap(({ functionalHierarchyPackageId, projectId }) =>
          this.favoriteService.addUserFavoritePackage(projectId, functionalHierarchyPackageId).pipe(
            map(() => {
              return treeActions.updateNodePropertySuccess({
                update: {
                  id: functionalHierarchyPackageId,
                  changes: { isFavoritePackage: true },
                },
              });
            }),
            catchError((error) => {
              showHttpErrorResponse(this.notificationService, error, 'deliverable.addPackageToFavoritesFailed');
              return of(deliverableActions.deliveryNoOpAction());
            }),
          ),
        ),
      ),
    { dispatch: true },
  );

  removePackageFromUserFavoritesRequest = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.removePackageFromFavorites),
        mergeMap(({ functionalHierarchyPackageId, projectId }) =>
          this.favoriteService.removeUserFavoritePackage(projectId, functionalHierarchyPackageId).pipe(
            map(() => {
              return treeActions.updateNodePropertySuccess({
                update: {
                  id: functionalHierarchyPackageId,
                  changes: { isFavoritePackage: false },
                },
              });
            }),
            catchError((error) => {
              showHttpErrorResponse(this.notificationService, error, 'deliverable.removePackageFromFavoritesFailed');
              return of(deliverableActions.deliveryNoOpAction());
            }),
          ),
        ),
      ),
    { dispatch: true },
  );

  // Each time the apiService's updateProperty() method is invoked the apiService merges update(s)
  // to the entity in a local map, and passes the merges updates to the back end. So that the entity's
  // updates map does not persist indefinitey we need to remove successful updates from the map,
  // otherwise they'll be re-sent to the back end on every patch.
  completePatchCycleEffect = createEffect(
    () =>
      this.actions.pipe(
        ofType(deliverableActions.updateDeliverableSuccess),
        map(({ deliverable }) => {
          this.treeActionsService.completeDeliverablePatch(deliverable);
        }),
      ),
    { dispatch: false },
  );
}
