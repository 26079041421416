import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import * as changeRequestSelectors from 'src/app/project-management/change-request-cart/change-request-cart-state/change-requests/change-request.selectors';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { createCaseInsensitiveSortWithCustomRules } from 'src/app/shared/caseInsensitiveSort/caseInsensitiveSort';
import { dashDayDateFormat } from 'src/app/shared/date-options/short-date-format';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';
import { ChangeRequestDetailsComponent } from './change-request-details/change-request-details.component';
import { ChangeRequestListItem } from './change-request-list-item';
import { ChangeRequestListService } from './change-request-list.service';
import {
  CRFiltersService,
  applyApproverFilter,
  applyFullTextFilter,
  getCRStatusCodesFromCRStatusFilterName,
} from './cr-filters.service';
import { CrRejectPromptComponent } from './cr-reject-prompt/cr-reject-prompt.component';
import { CRStatusCode, CRStatusCodesMap } from './cr-status';
import { ChangeRequestType } from './cr-type';
import { CRFilterType, ChangeRequestsViewState, initializeChangeRequestsViewState } from './model';

@Component({
  selector: 'app-change-request-list',
  templateUrl: './change-request-list.component.html',
  styleUrls: ['./change-request-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRequestListComponent implements OnDestroy, AfterViewInit {
  filteredDataSource = new TableVirtualScrollDataSource<ChangeRequestListItem>();
  displayedColumns = [
    'code',
    'number',
    'title',
    'status',
    'startDate',
    'endDate',
    'duration',
    'deliverables',
    'hours',
    'cost',
    'createdBy',
    'createdDate',
    'approver',
    'approvedDate',
    'actions',
  ];
  shortDateFormat = dashDayDateFormat;
  crStatusCode = CRStatusCode;
  loading$ = new BehaviorSubject<boolean>(false);
  projectId: number;
  data: ChangeRequestListItem[] = [];
  actions: any[] = [];
  @Input() isPackage = false;
  @ViewChild(MatSort) tableSort: MatSort;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  private subscription = new Subscription();

  viewState: ChangeRequestsViewState;
  sessionStorageKey = StorageKey.changeRequestsList;
  constructor(
    private dialog: MatLegacyDialog,
    private changeRequestListService: ChangeRequestListService,
    private store$: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private crFiltersService: CRFiltersService,
    private sessionStorageService: SessionStorageService,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.actions = [
      {
        icon: (x) =>
          x.canApprove ? '/assets/icons/icon-green-check-circle.svg' : '/assets/icons/icon-gray-check-circle.svg',
        label: 'changeRequest.approveItem',
        callBack: (x) => this.approve(x.changeNoticeId),
        isDisabled: (x) => !x.canApprove,
        isSvg: true,
      },
      {
        icon: (x) => (x.canReject ? '/assets/icons/icon-red-cancel.svg' : '/assets/icons/icon-grey-cancel.svg'),
        label: 'general.rejectItem',
        callBack: (x) => this.reject(x.changeNoticeId),
        isDisabled: (x) => !x.canReject,
        isSvg: true,
      },
      {
        icon: () => '/assets/icons/icon-info.svg',
        label: 'changeRequest.viewDetails',
        callBack: (x) => {
          this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: { changeNoticeId: x.changeNoticeId },
          });
          this.viewDetails(x.changeNoticeId, x.canReject, x.canApprove, x.changeRequestType);
        },
        isDisabled: () => false,
        isSvg: true,
      },
      {
        icon: (x) => (x.canCancel ? '/assets/icons/icon-black-cancel.svg' : '/assets/icons/icon-grey-cancel.svg'),
        label: 'changeRequest.cancelSelectedItems',
        callBack: (x) => this.cancel(x.changeNoticeId),
        isDisabled: (x) => !x.canCancel,
        isSvg: true,
      },
    ];
    const projectIdSubscription = this.store$
      .select(routerSelectors.getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.projectId = projectId;
      });
    this.subscription.add(projectIdSubscription);
    this.viewState = this.getOrInitializeViewState();
    const crList$ = combineLatest([
      this.store$.select(changeRequestSelectors.crExistsInCurrentContext),
      this.changeRequestListService.changeRequestListRefreshed(),
    ]).pipe(
      switchMap(() =>
        combineLatest([
          this.store$.select(routerSelectors.getIdSelector),
          this.crFiltersService.getFilters().pipe(
            map((x) => x.status),
            distinctUntilChanged(),
          ),
        ]),
      ),
      filter(([id]) => !!id),
      tap(() => {
        this.loading$.next(true);
        this.filteredDataSource.data = [];
      }),
      switchMap(([id, crStatus]) =>
        this.changeRequestListService.getChangeRequestList(id, getCRStatusCodesFromCRStatusFilterName(crStatus)),
      ),
      tap(() => {
        this.loading$.next(false);
      }),
    );

    const sub = combineLatest([
      crList$,
      this.crFiltersService
        .getFilters()
        .pipe(
          distinctUntilChanged(
            (prev, curr) =>
              isEqual(prev[CRFilterType.FullText], curr[CRFilterType.FullText]) &&
              isEqual(prev[CRFilterType.Approver], curr[CRFilterType.Approver]),
          ),
        ),
    ]).subscribe(([crList, crFilters]) => {
      this.data = crList;
      this.viewState.crFilters = crFilters;
      let filteredData = crList;

      // apply front-end side filters
      if (CRFilterType.FullText in crFilters) {
        filteredData = applyFullTextFilter(filteredData, crFilters[CRFilterType.FullText as string]);
      }
      if (CRFilterType.Approver in crFilters) {
        filteredData = applyApproverFilter(filteredData, crFilters[CRFilterType.Approver as string]);
      }
      this.filteredDataSource.data = filteredData;
      this.viewPort.checkViewportSize();
      const queryParamChangeNoticeId = this.route.snapshot.queryParams.changeNoticeId;
      if (queryParamChangeNoticeId) {
        const changeNoticeId = Number(queryParamChangeNoticeId);
        const cnItem = crList.find((elem) => elem.changeNoticeId === changeNoticeId);
        this.viewDetails(changeNoticeId, cnItem.canReject, cnItem.canApprove, cnItem.changeRequestType);
      }
    });

    this.subscription.add(sub);

    this.filteredDataSource.sortingDataAccessor = createCaseInsensitiveSortWithCustomRules();
    this.filteredDataSource.sort = this.tableSort;

    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateRow(id: number, newRow: ChangeRequestListItem): void {
    const updatedCrList = [...this.filteredDataSource.data];
    const matchedIndex = updatedCrList.findIndex((element) => element.changeNoticeId === id);
    if (matchedIndex === undefined) {
      return;
    }
    updatedCrList[matchedIndex] = { ...newRow };
    this.filteredDataSource.data = updatedCrList;
    this.cd.markForCheck();
  }

  disableCr(id: number, status: CRStatusCode): void {
    const rowToUpdate = this.filteredDataSource.data.find((crItem) => crItem.changeNoticeId === id);
    if (!rowToUpdate) {
      return;
    }
    this.updateRow(id, {
      ...rowToUpdate,
      canApprove: false,
      canCancel: false,
      canReject: false,
      approvedDate: null,
      approver: null,
      status,
      statusDetail: CRStatusCodesMap.get(status),
    });
  }

  approve(id: number): void {
    const sub = this.changeRequestListService.approve(id).subscribe((changeRequestListItem) => {
      this.updateRow(id, changeRequestListItem);
    });
    this.subscription.add(sub);
  }

  reject(changeNoticeId: number): void {
    const dialogRef = this.dialog.open(CrRejectPromptComponent, {
      data: { changeNoticeId },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((action?: 'rejected' | 'closed') => {
      switch (action) {
        case 'rejected':
          this.disableCr(changeNoticeId, CRStatusCode.Rejected);
          this.cd.markForCheck();
          break;
        case 'closed':
          break;
      }
    });
  }

  cancel(id: number): void {
    const sub = this.changeRequestListService.cancel(id).subscribe(() => {
      this.disableCr(id, CRStatusCode.Cancelled);
      this.cd.markForCheck();
    });
    this.subscription.add(sub);
  }

  navigateToPackage(packageFunctionalHierarchyId: number): void {
    this.router.navigate([
      'project-management/',
      this.projectId,
      EntityTypes.package,
      packageFunctionalHierarchyId,
      'detail',
    ]);
  }

  private getOrInitializeViewState(): ChangeRequestsViewState {
    return (
      this.sessionStorageService.getItem(this.sessionStorageKey) ||
      initializeChangeRequestsViewState(this.sessionStorageService)
    );
  }

  viewDetails(id: number, canReject: boolean, canApprove: boolean, changeRequestType: ChangeRequestType): void {
    const dialogRef = this.dialog.open(ChangeRequestDetailsComponent, {
      data: { id, canReject, canApprove, changeRequestType, projectId: this.projectId },
      panelClass: 'orms-change-request-details',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((action?: 'approve' | 'reject') => {
      switch (action) {
        case 'approve':
          this.approve(id);
          break;
        case 'reject':
          this.reject(id);
          break;
      }
      this.router.navigate(['.'], { relativeTo: this.route });
    });
  }
}
