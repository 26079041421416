<app-error>
  <ng-container *ngIf="isProjectImport; else readyAccessDenied">
    <div class="description">{{ 'projectImport.accessDenied' | translate }}</div>
    <div class="description">
      {{ 'projectImport.contactProjectManager' | translate }}
    </div>
  </ng-container>

  <ng-template #readyAccessDenied>
    <div class="description">{{ 'general.accessDenied' | translate }}</div>
    <div class="description">
      {{ 'general.accessDeniedEnterCredentials' | translate }}
    </div>
    <div class="description">
      {{ 'general.contactAdministrator' | translate }}
    </div>
  </ng-template>
</app-error>
