import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { HierarchyRule } from 'src/app/common-models/HierarchyRule.model';
import { CodeTypes, NodeTypeMap } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { ConfirmationDialogueComponent } from 'src/app/shared-controls/confirmation-dialogue/confirmation-dialogue.component';
import { StatusCode } from 'src/app/shared-controls/status/statusCodesMap';
import { getStatusDetail } from '../../../shared-controls/status/statusCodesMap';
import { addDeliverableChange } from '../../change-request-cart/change-request-cart-state/deliverables/crDeliverable.actions';
import { DeliverablesService } from '../../common/navigation-tabs/deliverables/deliverables.service';
import { DetailService } from '../../common/navigation-tabs/detail/detail.service';
import { AddDeliverableComponent } from '../../deliverable/add-deliverable/add-deliverable.component';
import { VisualizationContainerComponent } from '../../visualization/visualization-container.component';
import { TreeActionMovePackageCrComponent } from '../tree-action-move-package-cr/tree-action-move-package-cr.component';
import { TreeActionMovePackageComponent } from '../tree-action-move-package/tree-action-move-package.component';
import { TreeActionMoveSubfunctionCRComponent } from '../tree-action-move-subfunction-cr/tree-action-move-subfunction-cr.component';
import { TreeActionMoveSubfunctionComponent } from '../tree-action-move-subfunction/tree-action-move-subfunction.component';
import { TreeActionPublishNodeCrComponent } from '../tree-action-publish-package/tree-action-publish-package.component';
import { TreeNode, TreeNodeAction } from '../tree-structure.model';
import { TreeActionAddComponent } from './tree-action-add/tree-action-add.component';
import { TreeActionCrSubmitComponent } from './tree-action-cr-submit/tree-action-cr-submit.component';
import { TreeActionDeleteSubmitComponent } from './tree-action-delete-submit/tree-action-delete-submit.component';
import { TreeActionsService } from './tree-actions.service';

@Component({
  selector: 'app-tree-actions-menu',
  templateUrl: './tree-actions-menu.component.html',
  styleUrls: ['./tree-actions-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeActionsMenuComponent implements OnInit, OnDestroy {
  @Input() treeNode: TreeNode;
  @Input() treeData: TreeNode[];
  @Input() projectId: number;
  @Input() projectRules: HierarchyRule[];
  @Input() isCurrentUserLevelOneManager: boolean;
  @Input() isCurrentUserProjectManager: boolean;
  @Input() isCurrentUserAdmin: boolean;
  @Input() hasChangeRequest: boolean;
  @Output() nodeAdded = new EventEmitter<number>();
  @Output() expandNode = new EventEmitter<number>();
  @Output() collapseNode = new EventEmitter<number>();

  isProjectNode: boolean;
  actions: TreeNodeAction[] = [];

  private MAX_LEVEL_WITHOUT_SUBFUNCTION = 3;
  private langSub: Subscription;
  private addDeliverableSub: Subscription;
  private dialogRefSub: Subscription;

  constructor(
    private dialog: MatLegacyDialog,
    private deliverableService: DeliverablesService,
    private translateService: TranslateService,
    private router: Router,
    private treeActionsService: TreeActionsService,
    private detailService: DetailService,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.isProjectNode = this.treeNode.treeLevel === 1;

    this.langSub = this.translateService.onLangChange
      .pipe(startWith(this.translateService.currentLang))
      .subscribe(() => this.constructActions());
  }

  private constructActions(): void {
    this.actions = [
      {
        icon: () => (this.treeNode.canDelete ? '/assets/icons/icon-delete.svg' : '/assets/icons/icon-delete-grey.svg'),
        label: 'treeItemMenu.Delete',
        callBack: (x) => this.delete(x.functionalHierarchyId),
        isDisabled: () => !this.treeNode.canDelete,
        isSvg: true,
        itemGroupBorder: true,
      },
    ];

    if (this.treeNode.typeCode !== CodeTypes.Package) {
      this.actions = [
        {
          icon: () => '/assets/icons/icon-collapse-branch.svg',
          label: 'treeItemMenu.CollapseBranch',
          callBack: (x) => this.collapseNode.emit(x.functionalHierarchyId),
          isDisabled: () => false,
          isSvg: true,
          itemGroupBorder: false,
        },
        ...this.actions,
      ];

      this.actions = [
        {
          icon: () => '/assets/icons/icon-expand-branch.svg',
          label: 'treeItemMenu.ExpandBranch',
          callBack: (x) => this.expandNode.emit(x.functionalHierarchyId),
          isDisabled: () => false,
          isSvg: true,
          itemGroupBorder: true,
        },
        ...this.actions,
      ];
    }

    // The "Cancel" menu item is a chameleon in that once an item has been cancelled it is transposed to "Reinstate"
    if (this.treeNode.status !== StatusCode.Cancelled) {
      this.actions = [
        {
          icon: () =>
            this.treeNode.canCancel && !this.hasChangeRequest
              ? '/assets/icons/icon-grey-cancel.secondary-color.svg'
              : '/assets/icons/icon-grey-cancel.svg',
          label: 'treeItemMenu.Cancel',
          callBack: (x) => this.cancel(x.functionalHierarchyId, x.typeCode, x.title),
          isDisabled: () => !this.treeNode.canCancel || this.hasChangeRequest,
          isSvg: true,
          itemGroupBorder: ![CodeTypes.SubFunction.toString(), CodeTypes.Package.toString()].includes(
            this.treeNode.typeCode,
          ),
        },
        ...this.actions,
      ];
    } else {
      // Reinstate
      this.actions = [
        {
          icon: () =>
            this.isCurrentUserProjectManager ? '/assets/icons/reinstate.svg' : '/assets/icons/reinstate-grey.svg',
          label: 'treeItemMenu.Reinstate',
          callBack: (x) => this.reinstate(x.functionalHierarchyId, x.parentId),
          isDisabled: () => !this.isCurrentUserProjectManager,
          isSvg: true,
          itemGroupBorder: ![CodeTypes.SubFunction.toString(), CodeTypes.Package.toString()].includes(
            this.treeNode.typeCode,
          ),
        },
        ...this.actions,
      ];
    }

    if (this.treeNode.typeCode === CodeTypes.Package) {
      const isUnderChangeControl = this.treeNode.underChangeControl;
      this.actions = [
        {
          icon: () =>
            this.treeNode.canMove && !this.hasChangeRequest ? '/assets/icons/move.svg' : '/assets/icons/move-grey.svg',
          label: 'treeItemMenu.Move',
          callBack: (x) =>
            isUnderChangeControl
              ? this.movePackageCr(x.functionalHierarchyId, x.parentId)
              : this.movePackage(x.parentId),
          isDisabled: () => !this.treeNode.canMove || this.hasChangeRequest,
          isSvg: true,
          itemGroupBorder: true,
        },
        ...this.actions,
      ];
    }

    if (this.treeNode.typeCode === CodeTypes.SubFunction) {
      this.actions = [
        {
          icon: () =>
            this.treeNode.canMove && !this.hasChangeRequest ? '/assets/icons/move.svg' : '/assets/icons/move-grey.svg',
          label: 'treeItemMenu.Move',
          callBack: () => (this.treeNode.underChangeControl ? this.moveSubfunctionCR() : this.moveSubfunction()),
          isDisabled: () => !this.treeNode.canMove || this.hasChangeRequest,
          isSvg: true,
          itemGroupBorder: true,
        },
        ...this.actions,
      ];
    }

    this.actions = [
      {
        icon: () => (this.treeNode.canBaseline ? '/assets/icons/baseline.svg' : '/assets/icons/baseline-grey.svg'),
        label: 'treeItemMenu.Baseline',
        callBack: (x) => this.baseline(x.functionalHierarchyId),
        isDisabled: () => !this.treeNode.canBaseline,
        isSvg: true,
        itemGroupBorder: false,
      },
      ...this.actions,
    ];

    this.actions = [
      {
        icon: () => (this.treeNode.canPublish ? '/assets/icons/publish.svg' : '/assets/icons/publish-grey.svg'),
        label: 'treeItemMenu.Publish',
        callBack: (x) => this.publish(x.functionalHierarchyId),
        isDisabled: () => !this.treeNode.canPublish,
        isSvg: true,
        itemGroupBorder: true,
      },
      ...this.actions,
    ];

    if (this.treeNode.typeCode === CodeTypes.Package && this.isCurrentUserAdmin) {
      this.actions = [
        ...this.actions,
        {
          icon: () => 'schema',
          label: 'treeItemMenu.Visualize',
          callBack: (x) => this.visualize(x.functionalHierarchyId),
          isDisabled: () => false,
          isSvg: false,
          itemGroupBorder: true,
        },
      ];
    }

    if (!this.isCurrentUserLevelOneManager && !this.isCurrentUserProjectManager) {
      return;
    }

    this.actions = this.getActionItemByTypeCode(this.treeNode.typeCode);
  }

  private getActionItemByTypeCode(code: string): TreeNodeAction[] {
    const addSiblingDialogueLabel = `${this.translateService.instant('treeItemMenu.Add')} ${this.getNodeTitle(
      this.treeNode.treeLevel - 1,
    )}`;
    const addChildDialogueLabel = `${this.translateService.instant('treeItemMenu.Add')} ${this.getNodeTitle(
      this.treeNode.treeLevel,
    )}`;
    const addChildMenuLabel = `${addChildDialogueLabel} (${this.translateService.instant('treeItemMenu.Child')})`;
    const addSiblingMenuLabel = `${addSiblingDialogueLabel} (${this.translateService.instant('treeItemMenu.Sibling')})`;
    const addDeliverableLabel = `${this.translateService.instant(
      'treeItemMenu.AddDeliverable',
    )} (${this.translateService.instant('treeItemMenu.Child')})`;

    const rightArrow = '/assets/icons/right-arrow.svg';
    const rightArrowDisabled = '/assets/icons/right-arrow-grey.svg';
    const downArrow = '/assets/icons/down-arrow.svg';
    const downArrowDisabled = '/assets/icons/down-arrow-grey.svg';
    const mapDictionary = new Map<string, TreeNodeAction[]>([
      [
        CodeTypes.Project,
        [
          this.createActionItem(
            () => !this.treeNode.canAdd,
            () => downArrow,
            addChildMenuLabel,
            (x) => this.addFromDialog(x.functionalHierarchyId, addChildDialogueLabel),
          ),
          ...this.actions,
        ],
      ],
      [
        CodeTypes.Function,
        [
          this.createActionItem(
            () => !(this.treeNode.canAdd && this.isCurrentUserProjectManager),
            () => (this.treeNode.canAdd && this.isCurrentUserProjectManager ? rightArrow : rightArrowDisabled),
            addSiblingMenuLabel,
            (x) => this.addFromDialog(x.parentId, addSiblingDialogueLabel),
          ),
          this.projectRules?.length !== this.MAX_LEVEL_WITHOUT_SUBFUNCTION
            ? this.createActionItem(
                () => !this.treeNode.canAdd,
                () => (this.treeNode.canAdd ? downArrow : downArrowDisabled),
                addChildMenuLabel,
                (x) => this.addFromDialog(x.functionalHierarchyId, addChildDialogueLabel),
              )
            : this.createActionItem(
                () => !this.treeNode.canAdd,
                () => (this.treeNode.canAdd ? downArrow : downArrowDisabled),
                addChildMenuLabel,
                (x) => this.addFromDialog(x.functionalHierarchyId, addChildDialogueLabel, true),
              ),
          ...this.actions,
        ],
      ],
      [
        CodeTypes.Package,
        [
          this.createActionItem(
            () => !this.isParentNodeCanAdd(this.treeNode.parentId),
            () => (this.isParentNodeCanAdd(this.treeNode.parentId) ? rightArrow : rightArrowDisabled),
            addSiblingMenuLabel,
            (x) => this.addFromDialog(x.parentId, addSiblingDialogueLabel, true),
          ),
          this.createActionItem(
            () => !this.treeNode.canAdd,
            () => (this.treeNode.canAdd ? downArrow : downArrowDisabled),
            addDeliverableLabel,
            (x) => this.addDeliverable(x.functionalHierarchyId),
          ),
          ...this.actions,
        ],
      ],
      [
        CodeTypes.SubFunction,
        [
          this.createActionItem(
            () => !this.treeNode.canAdd,
            () => (this.treeNode.canAdd ? rightArrow : rightArrowDisabled),
            addSiblingMenuLabel,
            (x) => this.addFromDialog(x.parentId, addSiblingDialogueLabel),
          ),
          this.createActionItem(
            () => !this.treeNode.canAdd,
            () => (this.treeNode.canAdd ? downArrow : downArrowDisabled),
            addChildMenuLabel,
            (x) => this.addFromDialog(x.functionalHierarchyId, addChildDialogueLabel, true),
          ),
          ...this.actions,
        ],
      ],
    ]);

    return mapDictionary.get(code);
  }

  private createActionItem(
    isDisabled: () => boolean,
    icon: () => string,
    label: string,
    callBack: (x) => void,
    isSvg: boolean = true,
    itemGroupBorder: boolean = false,
  ): TreeNodeAction {
    return {
      icon,
      label,
      callBack,
      isDisabled,
      isSvg,
      itemGroupBorder,
    };
  }

  private isParentNodeCanAdd(parentId: number): boolean {
    return this.treeData.find((x) => x.functionalHierarchyId === parentId).canAdd;
  }

  private isParentNodeUnderChangeControl(parentId: number): boolean {
    if (!parentId) {
      return false;
    }
    return this.treeData.find((x) => x.functionalHierarchyId === parentId).underChangeControl;
  }

  private isParentNodeCancelled(parentId: number): boolean {
    if (!parentId) {
      return false;
    }
    return this.treeData.find((x) => x.functionalHierarchyId === parentId).status === StatusCode.Cancelled;
  }

  private getNodeTitle(level: number): string {
    return this.projectRules.find((x) => x.level === level)?.title;
  }

  cancel(functionalHierarchyId: number, typeCode: string, title: string): void {
    const entityType = NodeTypeMap.get(typeCode);
    this.dialog.open(TreeActionCrSubmitComponent, {
      data: { functionalHierarchyId, entityType, title },
      panelClass: 'orms-cr-cancellation-form',
      disableClose: true,
    });
  }

  reinstate(functionalHierarchyId: number, parentId: number): void {
    const title = 'treeItemMenu.ReinstateConfirmationTitle';
    const message = `${this.translateService.instant(
      this.isParentNodeCancelled(parentId)
        ? 'treeItemMenu.ReinstateWithParentConfirmationMessage'
        : 'treeItemMenu.ReinstateConfirmationMessage',
    )}?`;
    const successMessage = `${this.translateService.instant(
      this.getTranslationByTypeCode(this.treeNode.typeCode),
    )} ${this.translateService.instant('treeItemMenu.ReinstateSuccess')}`;

    const callBack = () => this.treeActionsService.reinstateFunctionOrPackage(functionalHierarchyId, successMessage);
    this.dialog.open(ConfirmationDialogueComponent, {
      data: { title, message, callBack },
      panelClass: 'orms-node-dialogue',
      disableClose: true,
    });
  }

  movePackage(parentFunctionalHierarchyId: number): void {
    this.dialog.open(TreeActionMovePackageComponent, {
      data: {
        packageId: this.treeNode.packageId,
        packageName: this.treeNode.title,
        parentFunctionalHierarchyId: parentFunctionalHierarchyId,
      },
      panelClass: 'orms-move-package-dialogue',
      disableClose: true,
    });
  }

  movePackageCr(packageFunctionalHierarchyId: number, parentFunctionalHierarchyId: number): void {
    this.dialog.open(TreeActionMovePackageCrComponent, {
      data: {
        packageFunctionalHierarchyId,
        parentFunctionalHierarchyId,
        packageName: this.treeNode.title,
      },
      panelClass: 'orms-move-package-cr-dialogue',
      disableClose: false,
    });
  }

  moveSubfunction(): void {
    this.dialog.open(TreeActionMoveSubfunctionComponent, {
      data: {
        functionalHierarchyId: this.treeNode.functionalHierarchyId,
        parentLevel: this.treeNode.treeLevel - 2, //treeNode levels are 1 based, functionalHierarchyRules are 0 based
        subfunctionName: this.treeNode.title,
      },
      panelClass: 'orms-move-subfunction-dialogue',
      disableClose: true,
    });
  }

  moveSubfunctionCR(): void {
    this.dialog.open(TreeActionMoveSubfunctionCRComponent, {
      data: {
        functionalHierarchyId: this.treeNode.functionalHierarchyId,
        parentLevel: this.treeNode.treeLevel - 2, //treeNode levels are 1 based, functionalHierarchyRules are 0 based
        subfunctionName: this.treeNode.title,
      },
      panelClass: 'orms-move-package-cr-dialogue',
      disableClose: false,
    });
  }

  publish(functionalHierarchyId: number): void {
    const title = 'treeItemMenu.PublishConfirmationTitle';
    const message = `${this.translateService.instant(
      'treeItemMenu.PublishConfirmationMessage',
    )} ${this.translateService.instant(this.getTranslationByTypeCode(this.treeNode.typeCode))}?`;
    const successMessage = `${this.translateService.instant(
      this.getTranslationByTypeCode(this.treeNode.typeCode),
    )} ${this.translateService.instant('treeItemMenu.SuccessfullyPublished')}`;

    if (this.isParentNodeUnderChangeControl(this.treeNode.parentId)) {
      this.dialog.open(TreeActionPublishNodeCrComponent, {
        data: { title, functionalHierarchyId: this.treeNode.functionalHierarchyId, successMessage },
        panelClass: 'orms-node-dialogue',
        disableClose: true,
      });
      return;
    }

    const callBack = () => this.treeActionsService.publishFunctionOrPackage(functionalHierarchyId, successMessage);
    this.dialog.open(ConfirmationDialogueComponent, {
      data: { title, message, callBack },
      panelClass: 'orms-node-dialogue',
      disableClose: true,
    });
  }

  baseline(functionalHierarchyId: number): void {
    const title = 'treeItemMenu.BaselineConfirmationTitle';
    const message = `${this.translateService.instant(
      'treeItemMenu.BaselineConfirmationMessage',
    )} ${this.translateService.instant(this.getTranslationByTypeCode(this.treeNode.typeCode))}?`;
    const successMessage = `${this.translateService.instant(
      this.getTranslationByTypeCode(this.treeNode.typeCode),
    )} ${this.translateService.instant('treeItemMenu.SuccessfullyBaselined')}`;

    const callBack = () =>
      this.treeActionsService.baselineFunctionOrPackage(functionalHierarchyId, this.projectId, successMessage);
    this.dialog.open(ConfirmationDialogueComponent, {
      data: { title, message, callBack },
      panelClass: 'orms-node-dialogue',
      disableClose: true,
    });
  }

  visualize(functionalHierarchyId: number): void {
    this.detailService
      .getVisualizationForPackage(functionalHierarchyId)
      .pipe(
        take(1),
        map((result) => {
          const { deliverables, parent, relatedPackages } = result;
          const combinedData = [
            ...deliverables.map((x) => ({
              ...x,
            })),
            result.package,
            parent,
            ...relatedPackages.map((x) => ({
              ...x,
            })),
          ];
          return {
            visualization: combinedData.map((x) => ({
              ...x,
              statusDetails: getStatusDetail(x.status),
            })),
            title: result.package.title,
          };
        }),
      )
      .subscribe(({ visualization, title }) =>
        this.dialog.open(VisualizationContainerComponent, {
          data: { functionalHierarchyId, title, visualization },
          panelClass: 'orms-node-visualization-dialogue',
          disableClose: true,
        }),
      );
  }

  private getTranslationByTypeCode(code: string): string {
    const itemMenuMap = new Map<string, string>([
      ['PR', 'treeItemMenu.Project'],
      ['FN', 'treeItemMenu.Function'],
      ['PK', 'treeItemMenu.Package'],
      ['SF', 'treeItemMenu.SubFunction'],
    ]);
    return itemMenuMap.get(code);
  }

  addFromDialog(functionalHierarchyId: number, label: string, isPackage = false): void {
    this.dialog
      .open(TreeActionAddComponent, {
        data: { functionalHierarchyId, label, isPackage },
        panelClass: 'orms-tree-dialogue',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((x) => this.nodeAdded.emit(x));
  }

  addDeliverable(functionalHierarchyId: number): void {
    const dialogTitle = this.treeNode.underChangeControl
      ? 'deliverable.requestNewDeliverable'
      : 'deliverable.addDeliverable';
    this.dialogRefSub = this.dialog
      .open(AddDeliverableComponent, {
        data: { functionalHierarchyId, title: dialogTitle },
        panelClass: 'orms-add-deliverable',
        disableClose: true,
      })
      .afterClosed()
      .pipe(filter((crd) => crd))
      .subscribe((crd) => {
        if (!this.treeNode.underChangeControl) {
          this.addDeliverableSub = this.deliverableService
            .addDeliverable({
              packageFunctionalHierarchyId: crd.packageFunctionalHierarchyId,
              title: crd.title,
              documentNumber: crd.documentNumber,
              hours: crd.newHours,
              cost: crd.newCost,
              curveProfileCode: crd.newCurveProfileCode,
              progressWorkflowId: crd.progressWorkflowId,
            })
            .subscribe(({ deliverableId }) => {
              this.router.navigateByUrl(`project-management/${this.projectId}/deliverable/${deliverableId}/detail`);
            });
        } else {
          this.store$.dispatch(
            addDeliverableChange({
              crDeliverable: crd,
            }),
          );
        }
      });
  }

  delete(functionalHierarchyId: number): void {
    this.dialog.open(TreeActionDeleteSubmitComponent, {
      data: { functionalHierarchyId, status: this.treeNode.status },
      panelClass: 'orms-tree-dialogue',
      disableClose: true,
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
    this.dialogRefSub?.unsubscribe();
    this.addDeliverableSub?.unsubscribe();
  }
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatLegacyDialog) {}

  public openDialog({
    positionRelativeToElement,
    hasBackdrop = true,
    height = '400px',
    width = '500px',
  }: {
    positionRelativeToElement: ElementRef;
    hasBackdrop?: boolean;
    height?: string;
    width?: string;
  }): MatLegacyDialogRef<TreeActionAddComponent> {
    const dialogRef: MatLegacyDialogRef<TreeActionAddComponent> = this.dialog.open(TreeActionAddComponent, {
      hasBackdrop,
      height,
      width,
      data: { positionRelativeToElement },
    });
    return dialogRef;
  }
}
