import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CodeTypes } from 'src/app/common-models/node-types/node-types';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PathRequest } from '../../common/path.model';
import { PropertyPatch } from '../../common/propert-patch.model';
import { Scope } from './scope.model';

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  getScopeData(id: number): Observable<Scope> {
    const params = new HttpParams().set('functionalHierarchyId', id.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getsScopeUrl = this.appConfigService.settings.api.endpoints.scopeData;
    return this.httpClient.get<Scope>(baseUrl + getsScopeUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        return of(null);
      }),
    );
  }

  updateScope(id: number, newValue: string): Observable<any> {
    const requestBody = this.prepareRequestBody(newValue, id);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const updateProperty = this.appConfigService.settings.api.endpoints.updateProperty;
    return this.httpClient.patch(baseUrl + updateProperty, requestBody).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        throw err;
      }),
    );
  }

  private prepareRequestBody(newValue: string, id: number): PathRequest {
    const reverseType = CodeTypes.Package;

    const propertyPatch: PropertyPatch = {
      PropertyName: 'scope',
      PropertyValue: newValue,
    };

    return {
      Id: id,
      PatchDTOs: [propertyPatch],
      EntityType: reverseType,
    };
  }
}
