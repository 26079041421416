import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'day',
  pure: false,
})
export class DayPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(daysCount: number, ...args: unknown[]): unknown {
    if (!daysCount) {
      return '-';
    }
    const daysAppendix =
      Math.abs(daysCount) === 1
        ? this.translateService.instant('general.day')
        : this.translateService.instant('general.days');
    return `${daysCount > 0 ? '+' : '-'}${Math.abs(daysCount)} ${daysAppendix}`;
  }
}
