<mat-label (click)="select.open()">
  <span>{{ label }}</span
  ><mat-icon>add</mat-icon>
</mat-label>
<mat-select
  #select
  [formControl]="control"
  multiple
  class="orms-select choose-columns"
  panelClass="advanced-search-choose-columns orms-select-options"
>
  <mat-select-trigger> </mat-select-trigger>
  <mat-optgroup *ngFor="let group of groupOptions" [label]="group.name" [disabled]="group.disabled">
    <mat-option *ngFor="let option of group.option" [value]="option.value">
      {{ option.viewValue }}
    </mat-option>
  </mat-optgroup>
</mat-select>
