<h5 class="orms-label">{{ 'relationships.related' | translate }}</h5>
<app-add-package-relationship
  [currentPackageFunctionalId]="packageHierarchyId"
  [relationships]="relationships"
  (addRelationship)="addRelationship($event)"
>
</app-add-package-relationship>
<app-package-relationships-table
  [relationships]="relationships"
  [projectId]="projectId"
  [canModifyRelations]="canModifyRelations"
  (removeRelationship)="removeRelationship($event)"
></app-package-relationships-table>
