import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './services/notification.service';

export const showHttpErrorResponse = (
  notificationService: NotificationService,
  response: HttpErrorResponse,
  fromAction?: string,
  dismiss?: boolean,
) => {
  getErrorMessages(response).forEach((error) => notificationService.error(error, fromAction, dismiss));
};

export const getErrorMessages = (response: HttpErrorResponse): string[] => {
  let errors = [];
  if (Array.isArray(response.error?.errors)) {
    errors = [...response.error?.errors];
  } else {
    errors = [response.error];
  }
  return errors.map((error) => error.message ?? error?.error ?? error);
};
