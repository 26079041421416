<table
  mat-table
  [dataSource]="impactDataSource"
  class="orms-table"
  attr.aria-label="{{ 'general.impact' | translate }}"
>
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'general.packageCode' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.code }}</td>
  </ng-container>
  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'general.startDate' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.startDate | day }}</td>
  </ng-container>
  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'general.endDate' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.endDate | day }}</td>
  </ng-container>
  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'general.duration' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.duration | day }}</td>
  </ng-container>
  <ng-container matColumnDef="newDeliverables">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'changeRequest.newDeliverables' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.newDeliverables | diff }}
    </td>
  </ng-container>
  <ng-container matColumnDef="cancelledDeliverables">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'changeRequest.cancelledDeliverables' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.cancelledDeliverables | diff }}
    </td>
  </ng-container>
  <ng-container matColumnDef="hours">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'general.hours' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.hours | diff }}</td>
  </ng-container>
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'general.cost' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.cost | diff: '$' }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="impactColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: impactColumns"></tr>
</table>
