<app-deliverables-filters [data]="data$ | async"></app-deliverables-filters>
<cdk-virtual-scroll-viewport class="deliverables-list" tvsItemSize="64">
  <table
    class="resizable-table"
    matSort
    mat-table
    [dataSource]="filteredData"
    attr.aria-label="{{ 'general.deliverables' | translate }}"
  >
    <ng-container matColumnDef="packageCode">
      <th
        resizeColumn="packageCode"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('packageCode')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'general.package' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          [routerLink]="element.packageUrl"
          class="orms-link-button"
          (click)="navigateToPackage.emit(element.packageFunctionalHierarchyId)"
        >
          {{ element.packageCode }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="documentNumber">
      <th
        resizeColumn="documentNumber"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('documentNumber')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>#</span>
      </th>
      <td mat-cell document-number *matCellDef="let element">
        <a
          [routerLink]="element.deliverableUrl"
          class="orms-link-button"
          (click)="navigateToDeliverable.emit(element.deliverableId)"
        >
          {{ element.documentNumber }}
        </a>
        <app-cr-warning [cr]="element.cr" [config]="crConfig" [curveProfiles]="curveProfiles"></app-cr-warning>
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th
        resizeColumn="title"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('title')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'general.title' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element" title="{{ element.title }}">
        <app-cr-warning
          [cr]="element.mr"
          [iconPath]="'/assets/icons/move-warning.svg'"
          [config]="mrConfig"
          [curveProfiles]="curveProfiles"
        ></app-cr-warning>
        <a
          [routerLink]="element.deliverableUrl"
          class="orms-link-button"
          (click)="navigateToDeliverable.emit(element.deliverableId)"
        >
          {{ element.title }}
        </a>
        <app-tag-list *ngIf="element?.tags?.length > 0" [tags]="element?.tags"></app-tag-list>
      </td>
    </ng-container>
    <ng-container matColumnDef="responsible">
      <th
        resizeColumn="responsible"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('responsible')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'general.responsible' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span [title]="element.responsible ? element.responsible : ''">
          {{ element?.responsible }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <th
        resizeColumn="startDate"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('startDate')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'general.start' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{ packageDate: element.usesPackageStartDate }">
        <span
          [ngClass]="{
            'date-out-of-plan':
              (element.startDate | isDateInRange: element.plannedStartDate : element.plannedEndDate) === false
          }"
        >
          {{ element.startDate | date: shortDateFormat : timezoneUTC | statusBasedValue: element.status }}
        </span>
        <app-warning-icon
          [showWarning]="
            (element.startDate | isDateInRange: element.plannedStartDate : element.plannedEndDate) === false
          "
          propertyName="general.startDate"
          tooltip="package.outsideOfPackagePlanDates"
        >
        </app-warning-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th
        resizeColumn="endDate"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('endDate')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'general.end' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{ packageDate: element.usesPackageEndDate }">
        <span
          [ngClass]="{
            'date-out-of-plan':
              (element.endDate | isDateInRange: element.plannedStartDate : element.plannedEndDate) === false
          }"
        >
          {{ element.endDate | date: shortDateFormat : timezoneUTC | statusBasedValue: element.status }}
        </span>
        <app-warning-icon
          [showWarning]="(element.endDate | isDateInRange: element.plannedStartDate : element.plannedEndDate) === false"
          propertyName="general.endDate"
          tooltip="package.outsideOfPackagePlanDates"
        >
        </app-warning-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="hours">
      <th
        resizeColumn="hours"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('hours')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'general.hours' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.hours | number: '1.0-0' | statusBasedValue: element.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="progressWorkflowStatus">
      <th
        resizeColumn="progressWorkflowStatus"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('progressWorkflowStatus')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'deliverable.workflowStep' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span
          title="{{ element.progressWorkflowStatus }}"
          [class.step-cancelled]="element?.status === statusCancelled || element?.status === statusMoved"
        >
          {{
            element?.status === statusCancelled || element?.status === statusMoved
              ? ((element?.status === statusCancelled ? 'changeRequest.cancelled' : 'changeRequest.moved') | translate)
              : (element.progressWorkflowStatus | statusBasedValue: element.status)
          }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="percentageCompleted">
      <th
        resizeColumn="percentageCompleted"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('percentageCompleted')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'deliverable.progress' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span
          *ngIf="
            element.status === Status.Draft || element.status === Status.Cancelled || element.status === Status.Moved
          "
          >-</span
        >
        <button
          *ngIf="
            element.status !== Status.Draft && element.status !== Status.Cancelled && element.status !== Status.Moved
          "
          type="button"
          #button
          class="orms-link-button"
          [disabled]="element?.status === statusCancelled || !element.editableFields.includes('percentagecompleted')"
          (click)="
            percentageCompletedClicked.emit({
              event: $event,
              element: element,
              button: button
            })
          "
        >
          {{ element.percentageCompleted | statusPercent: element.status }}
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="controlledDocument">
      <th
        resizeColumn="controlledDocument"
        [sessionStorageKey]="sessionStorageKey"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>{{ 'deliverable.controlled' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="custom-control custom-switch orms-toggle-switch-container">
          <input
            type="checkbox"
            [checked]="element.controlledDocument"
            [disabled]="true"
            class="custom-control-input orms-toggle-switch"
            id="controlledDocument"
          />
          <label class="custom-control-label" for="controlledDocument"></label>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th
        resizeColumn="actions"
        [sessionStorageKey]="sessionStorageKey"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      ></th>
      <td mat-cell *matCellDef="let element">
        <span>
          <app-action-menu [item]="element" [actions]="actions">
            <mat-icon>more_vert</mat-icon>
          </app-action-menu>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="deliverableId">
      <th
        resizeColumn="deliverableId"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('deliverableId')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span>Id.</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.deliverableId }}</span>
      </td>
    </ng-container>
    <tr class="orms-deliverable-header-row" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.last-visited]="row.deliverableId === lastVisitedDeliverableId"
      class="orms-deliverable-element-row"
    ></tr>
  </table>
  <app-spinner *ngIf="data$.value === null"></app-spinner>
  <div class="no-results" *ngIf="data$.value && !data$.value.length">
    {{ 'general.deliverablesListNoResults' | translate }}
  </div>
</cdk-virtual-scroll-viewport>
