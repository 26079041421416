export interface CurveChartPoint {
  forDate: string;
  sumOriginalBudget?: number;
  sumOriginalValue?: number;
  sumPlannedBudget?: number;
  sumPlannedValue?: number;
  sumEarnedValue?: number;
  sumForecastValue?: number;
}

export interface CurveChartPointList {
  result: any;
}

export const colors = {
  successMain: '#229E17',
  baselineBlue: '#377DF7',
  brandOrange: '#E7552F',
  darkYellow: '#E4AB18',
  secondaryGrey: '#9E9E9E',
  black: 'black',
};
