import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-component',
  templateUrl: './empty-component.component.html',
  styleUrls: ['./empty-component.component.scss'],
})
export class EmptyComponentComponent {
  constructor() {}
}
