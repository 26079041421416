import moment, { Moment } from 'moment';
import { RelationshipType } from 'src/app/project-management/visualization/visualization-container.component';

export const calculateOverlapAccordingToType = (
  start1: Date | Moment,
  end1: Date | Moment,
  start2: Date | Moment,
  end2: Date | Moment,
  relType: RelationshipType,
): number => {
  const startDate1 = start1 ? moment(start1) : null;
  const endDate1 = start1 ? moment(end1) : null;
  const startDate2 = start1 ? moment(start2) : null;
  const endDate2 = start1 ? moment(end2) : null;
  if (relType === RelationshipType.Predecessor) {
    return countDaysBetween(startDate1, endDate1, startDate2, endDate2);
  } else if (relType === RelationshipType.Successor) {
    return countDaysBetween(startDate2, endDate2, startDate1, endDate1);
  } else {
    return null;
  }
};

export const countDaysBetween = (start1: Moment, end1: Moment, start2: Moment, end2: Moment) => {
  if (end2 && start1 && start1.isAfter(end2)) {
    return start1.diff(end2, 'days');
  } else if (end1 && start2 && end1.isBefore(start2)) {
    return null;
  } else if (start2 && start1 && end2 && start2.isBefore(start1) && end2.isSameOrAfter(start1)) {
    return end2.diff(start1, 'days');
  } else if (end1 && start2 && start1 && start2 && end1.isSameOrAfter(start2) && start1.isBefore(start2)) {
    return end1.diff(start2, 'days');
  } else if (start1 && end1 && start2 && end2) {
    let overlapCount = 0;
    let currentDate = moment(start1);
    while (currentDate <= moment(end1)) {
      if (currentDate >= moment(start2) && currentDate <= moment(end2)) {
        overlapCount++;
      }
      currentDate.add(1, 'days');
    }
    return overlapCount;
  } else {
    return null;
  }
};
