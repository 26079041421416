<ng-container [formGroup]="form">
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'package.criticalPackage' | translate }}
    </h6>
    <div class="custom-control custom-switch orms-toggle-switch-container">
      <input
        type="checkbox"
        formControlName="isCritical"
        class="custom-control-input orms-toggle-switch"
        id="isCritical"
      />
      <label class="custom-control-label" for="isCritical"></label>
    </div>
  </div>
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'package.controlledDocuments' | translate }}
    </h6>
    <app-static-label
      [text]="detailData.controlledDocuments ? 'general.yes' : 'general.no'"
      class="controlled-document label-default"
    ></app-static-label>
  </div>
  <div class="row-column">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.priority' | translate }}
    </h6>
    <mat-select formControlName="priority" class="orms-select" panelClass="orms-select-options">
      <mat-option *ngFor="let p of priorities | keyvalue" [value]="p.key">
        {{ p.value }}
      </mat-option>
    </mat-select>
  </div>
</ng-container>
