<ng-container *ngIf="userProjects$ | async as userProjects; else loadingProjects">
  <mat-accordion class="orms-expansion-panel">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'general.recentProjects' | translate }} ({{ userProjects.length }}) </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngFor="let userProject of userProjects" class="project-section">
        <img [src]="userProject.src" class="logo" alt="{{ 'projectDetails.projectLogo' | translate }}" />
        <div class="project-name-wrapper">
          <div class="project-name">
            <a routerLink="{{ '/project-management/' + userProject.projectId }}">{{ userProject.projectTitle }}</a>
          </div>
          <h6 class="project-last-visited">
            {{ userProject.lastVisited | lastVisited }}
          </h6>
        </div>
        <div class="project-manager">{{ userProject.projectManager }}</div>
      </div>
      <div *ngIf="!userProjects.length">
        {{ 'general.noProjects' | translate }}
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<ng-template #loadingProjects>
  <mat-accordion class="orms-expansion-panel">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'general.recentProjects' | translate }} (?) </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
  <app-spinner></app-spinner>
</ng-template>
