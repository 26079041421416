<form [formGroup]="form" autocomplete="off">
  <h2>
    {{ data.title | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <div class="label">{{ 'movePackageModal.justification' | translate }}</div>
  <div class="justification-section">
    <editor
      [init]="editorConfig"
      formControlName="justification"
      class="orms-editor"
      [class.is-invalid]="showInvalidState('justification')"
    ></editor>
  </div>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        class="orms-button orms-button-primary submit-button"
        type="button"
        (click)="confirm()"
        [disabled]="!form.valid || submissionInProgress"
      >
        {{ 'general.confirm' | translate }}
      </button>
      <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </button>
      <app-spinner [diameter]="26" *ngIf="submissionInProgress"></app-spinner>
    </div>
  </div>
</form>
