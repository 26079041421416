import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { getPriorities } from 'src/app/common-models/priority.model';
import { ProgressWorkflowStep } from 'src/app/common-models/progress-workflow-step.model';
import { ProgressWorkflow } from 'src/app/common-models/progress-workflow.model';
import { AppState } from 'src/app/root-store/app-state';
import { UrlRegex } from 'src/app/shared-controls/constants';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormUpdateConfig,
  watchControls,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { ShadowInputValidationService } from 'src/app/shared/services/shadow-input-validation.service';
import { DeliverableStatus, StatusCode } from '../../../shared-controls/status/statusCodesMap';
import { ChangeRequestDeliverable } from '../../change-request-cart/change-request-cart-state/cart-item.model';
import { DeliverablePercentageCompleteComponent } from '../../common/navigation-tabs/deliverable-percentage-complete/deliverable-percentage-complete.component';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { DeliverablesService } from '../../common/navigation-tabs/deliverables/deliverables.service';
import { TreeActionsService } from '../../tree-navigation/tree-actions-menu/tree-actions.service';
import * as deliverableActions from '../deliverable-state/deliverable.actions';

@Component({
  selector: 'app-deliverable-soft-update-section',
  templateUrl: './deliverable-soft-update-section.component.html',
  styleUrls: ['./deliverable-soft-update-section.component.scss'],
})
export class DeliverableSoftUpdateSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  deliverableDetail: DeliverableDetail;

  Status = DeliverableStatus;
  init: any;
  isCompleteShown = false;
  isNotesFiledEditable: boolean;
  projectWorkflows$: Observable<ProgressWorkflow[]>;
  priorities: Map<number, string>;
  form: UntypedFormGroup;
  workflowStepName$ = new Subject<string>();
  reloadSteps$ = new Subject<number>();
  datepickerActionsComponent = DatepickerActionsComponent;
  isURLButtonEnabled = false;
  firstLoadWithData = false;
  private subscription = new Subscription();
  private controlsValueSubscription = new Subscription();

  private serverValidatorsSubscription = new Subscription();

  constructor(
    private deliverablesService: DeliverablesService,
    private appConfigService: AppConfigService,
    private store$: Store<AppState>,
    private shadowInputValidationService: ShadowInputValidationService,
    private translateService: TranslateService,
    private dialog: MatLegacyDialog,
    private treeActionsService: TreeActionsService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.priorities = getPriorities(this.translateService);

    const reloadStepsSub = this.reloadSteps$
      .pipe(
        filter((x) => !!x),
        switchMap((id) => this.deliverablesService.getProjectWorkflowSteps(id)),
        map((steps) => this.getStepName(steps)),
      )
      .subscribe((stepName) => {
        this.workflowStepName$.next(stepName);
      });
    this.subscription.add(reloadStepsSub);

    this.init = {
      ...this.appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...this.appConfigService.settings.richTextConfig.intentInitOptions,
      placeholder: this.translateService.instant('deliverable.notes'),
    };
    this.projectWorkflows$ = this.deliverablesService.getProjectWorkflowsForCurrentProject();

    const translationSub = this.translateService.onLangChange.subscribe(() => {
      this.projectWorkflows$ = this.deliverablesService.getProjectWorkflowsForCurrentProject();
      this.priorities = getPriorities(this.translateService);
    });

    this.subscription.add(translationSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('deliverableDetail' in changes) {
      if (
        this.form.get('progressWorkflowId').value !== this.deliverableDetail.progressWorkflowId ||
        this.form.get('progressWorkflowStatusId').value !== this.deliverableDetail.progressWorkflowStatusId
      ) {
        // We have to wait until ngOnInit inits reloadSteps$ as ngOnChanges is executed before ngOnInit
        setTimeout(() => this.reloadSteps$.next(this.deliverableDetail.progressWorkflowId), 0);
      }
      this.isCompleteShown = !this.deliverableDetail.progressWorkflowId;
      if (!this.firstLoadWithData && this.deliverableDetail?.deliverableId) {
        this.firstLoadWithData = true;
        this.setControlWatchers();
        this.fillForms();
        disableFormFieldsBaseOnPermissions(this.form, this.isEditable.bind(this));
        this.setServerValidation();
        this.isNotesFiledEditable = this.deliverableDetail?.editableFields?.includes('notes');
        this.updateUrlButtonEnabledStatus();
      }
    }
  }

  updateOnHold(key: string, value: any): void {
    const holdReasonControl = this.form.get('holdReason');
    const onHoldControl = this.form.get('onHold');
    if (onHoldControl.value && (holdReasonControl.invalid || !holdReasonControl.value)) {
      setTimeout(() => holdReasonControl.markAllAsTouched());
      return;
    }

    if (key === 'onHold' && value === false) {
      holdReasonControl.setValue(null, { emitEvent: false });
    }

    const update: Update<DeliverableDetail> = {
      id: this.deliverableDetail.deliverableId,
      changes: {
        onHold: onHoldControl.value,
        holdReason: holdReasonControl.value,
      },
    };

    this.updateProperty(key, update);
  }

  private updateProperty(key: string, value: any): void {
    const dlr = new ChangeRequestDeliverable();

    dlr.oldStartDate = this.deliverableDetail.startDate || null;
    dlr.oldEndDate = this.deliverableDetail.endDate || null;
    dlr.oldNotes = this.deliverableDetail.notes || null;
    dlr.oldUrl = this.deliverableDetail.url || null;
    dlr.oldControlledDocument = this.deliverableDetail.controlledDocument || false;
    dlr.oldOnHold = this.deliverableDetail.onHold || false;
    dlr.oldHoldReason = this.deliverableDetail.holdReason || null;
    dlr.oldPriority = this.deliverableDetail.priority;
    dlr.oldProgressWorkflowId = this.deliverableDetail.progressWorkflowId || 0;

    dlr.newStartDate = this.form.get('startDate').value;
    dlr.newEndDate = this.form.get('endDate').value;
    dlr.newNotes = this.form.get('notes').value;
    dlr.newUrl = this.form.get('url').value;
    dlr.newControlledDocument = this.form.get('controlledDocument').value;
    dlr.newOnHold = this.form.get('onHold').value;
    dlr.newHoldReason = this.form.get('holdReason').value;
    dlr.newPriority = this.form.get('priority').value === 0 ? null : this.form.get('priority').value;
    dlr.progressWorkflowId = this.form.get('progressWorkflowId').value;

    const update: Update<DeliverableDetail> = {
      id: this.deliverableDetail.deliverableId,
      changes: {},
    };

    if (
      !(dlr.newStartDate === null && dlr.oldStartDate === null) &&
      !moment(dlr.newStartDate).isSame(dlr.oldStartDate)
    ) {
      update.changes.startDate = dlr.newStartDate;
    }
    if (!(dlr.newEndDate === null && dlr.oldEndDate === null) && !moment(dlr.newEndDate).isSame(dlr.oldEndDate)) {
      update.changes.endDate = dlr.newEndDate;
    }
    if (dlr.newNotes !== dlr.oldNotes) {
      update.changes.notes = dlr.newNotes;
    }
    if (dlr.newUrl !== dlr.oldUrl) {
      update.changes.url = dlr.newUrl;
    }
    if (dlr.newControlledDocument !== dlr.oldControlledDocument) {
      update.changes.controlledDocument = dlr.newControlledDocument;
    }
    if (dlr.newPriority !== dlr.oldPriority) {
      update.changes.priority = dlr.newPriority;
    }
    if (dlr.newOnHold !== dlr.oldOnHold) {
      update.changes.onHold = dlr.newOnHold;
    }
    if (dlr.newHoldReason !== dlr.oldHoldReason) {
      update.changes.holdReason = dlr.newHoldReason;
    }
    if (dlr.progressWorkflowId !== dlr.oldProgressWorkflowId) {
      this.isCompleteShown = dlr.progressWorkflowId === 0;
      this.reloadSteps$.next(value);
      dlr.progressWorkflowId = this.isCompleteShown ? null : value;
      update.changes.progressWorkflowId = dlr.progressWorkflowId;
    }

    this.dispatchUpdate(key, update);
    this.updateUrlButtonEnabledStatus();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  showProgressUpdate(): void {
    this.dialog.open(DeliverablePercentageCompleteComponent, {
      data: this.deliverableDetail,
      panelClass: 'orms-deliverable-progress-dialog',
    });
  }

  private updateUrlButtonEnabledStatus(): void {
    this.isURLButtonEnabled = this.form.get('url').value && this.form.get('url').valid;
  }

  private getStepName(steps: ProgressWorkflowStep[]): string {
    const step = steps.find((s) => s.progressWorkflowStatusId === this.deliverableDetail.progressWorkflowStatusId);
    if (step) {
      return step.title;
    }
    return !this.isEditable('progressWorkflowStatusId')
      ? '-'
      : this.translateService.instant('deliverable.selectWorkflowStep');
  }

  private setServerValidation(): void {
    this.serverValidatorsSubscription.unsubscribe();

    this.serverValidatorsSubscription = this.shadowInputValidationService
      .watchServerValidation(this.form, EntityTypes.deliverable, this.deliverableDetail.deliverableId)
      .subscribe(({ name, error }) => {
        this.form.get(name).setErrors(
          error
            ? {
                serverError: error,
              }
            : null,
          { emitEvent: false },
        );
      });
  }

  private isCancelled(): boolean {
    return this.deliverableDetail.status === StatusCode.Cancelled;
  }

  isEditable(name: string): boolean {
    return !this.isCancelled() && this.deliverableDetail?.editableFields?.includes(name.toLocaleLowerCase());
  }

  private dispatchUpdate(key: string, update: Update<DeliverableDetail>): void {
    if (!Object.keys(update.changes).length) {
      return;
    }

    this.store$.dispatch(
      deliverableActions.updateDeliverableProperty({
        key,
        update: this.treeActionsService.mergeDeliverableIdUpdates(update),
        parentId: this.deliverableDetail.packageFunctionalHierarchyId,
      }),
    );
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      notes: new UntypedFormControl(
        {
          disabled: !this.isNotesFiledEditable,
        },
        {
          updateOn: 'blur',
        },
      ),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl(),
      progressWorkflowId: new UntypedFormControl(),
      progressWorkflowStatusId: new UntypedFormControl(),
      priority: new UntypedFormControl(),
      percentageCompleted: new UntypedFormControl(null, {
        validators: [Validators.maxLength(2), Validators.min(0), Validators.max(100)],
        updateOn: 'blur',
      }),
      controlledDocument: new UntypedFormControl(null, {
        updateOn: 'change',
      }),
      url: new UntypedFormControl(null, {
        validators: [Validators.pattern(UrlRegex)],
        updateOn: 'blur',
      }),
      onHold: new UntypedFormControl(),
      holdReason: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.minLength(1)],
        updateOn: 'blur',
      }),
    });
  }

  private fillForms(): void {
    const {
      progressWorkflowId,
      progressWorkflowStatusId,
      percentageCompleted,
      controlledDocument,
      url,
      onHold,
      holdReason,
      priority,
      startDate,
      endDate,
      notes,
    } = this.deliverableDetail;

    this.form.patchValue(
      {
        notes: notes || null,
        progressWorkflowId: progressWorkflowId || 0,
        progressWorkflowStatusId: progressWorkflowStatusId || 0,
        percentageCompleted: percentageCompleted || 0,
        controlledDocument: controlledDocument || false,
        priority: priority || 0,
        url: url || null,
        onHold: this.form.get('onHold').value ? this.form.get('onHold').value : onHold || false,
        holdReason: this.form.get('holdReason').value ? this.form.get('holdReason').value : holdReason || null,
        startDate: startDate || null,
        endDate: endDate || null,
      },
      { ...shadowFormUpdateConfig },
    );
  }

  private setControlWatchers(): void {
    this.controlsValueSubscription.unsubscribe();
    this.controlsValueSubscription = watchControls(this.form).subscribe(({ name, value }) => {
      if (name === 'holdReason' || name === 'onHold') {
        this.updateOnHold(name, value);
      } else {
        this.updateProperty(name, value);
      }
    });
  }
}
