<mat-chip-list #chipList>
  <ng-container *ngFor="let tag of tags">
    <mat-chip selectable="true" [removable]="!readonly" (removed)="remove(tag)">
      <span class="text" [attr.title]="tag.name.length > 20 ? tag.name : null">{{ tag.name }}</span>
      <button matChipRemove *ngIf="!readonly">
        <mat-icon [inline]="true">close</mat-icon>
      </button>
    </mat-chip>
  </ng-container>
  <ng-container *ngIf="!readonly">
    <app-searchable-input
      *ngIf="editableMode"
      [placeholder]="(allowNewTagAdding ? 'deliverable.addOrCreateTag' : 'deliverable.selectTag') | translate"
      (edited)="editableMode = false"
      (optionSelected)="addTag($event)"
      [remoteSearch]="remoteSearch"
    >
    </app-searchable-input>
    <button
      *ngIf="!editableMode && !readonly"
      title="{{ 'deliverable.addTag' | translate }}"
      mat-icon-button
      (click)="editableMode = true"
    >
      <mat-icon>add</mat-icon>
    </button>
  </ng-container>
</mat-chip-list>
