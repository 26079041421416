import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';

export interface ProjectManagementState {
  treePercentageSize: number;
  rightPanePercentageSize: number;
}

export const PROJECT_MANAGEMENT_STATE_DEFAULT: ProjectManagementState = {
  treePercentageSize: 28,
  rightPanePercentageSize: 72,
};

export const getOrInitializeProjectManagementSessionState = (
  sessionStorageService: SessionStorageService,
): ProjectManagementState => {
  let projectManagementSessionState = sessionStorageService.getItem<ProjectManagementState>(
    StorageKey.projectManagement,
  );
  if (projectManagementSessionState) {
    return projectManagementSessionState;
  }

  projectManagementSessionState = {
    ...PROJECT_MANAGEMENT_STATE_DEFAULT,
  };

  sessionStorageService.setItem<ProjectManagementState>(StorageKey.projectManagement, projectManagementSessionState);

  return projectManagementSessionState;
};

export const setProjectManagementSessionState = (
  sessionStorageService: SessionStorageService,
  state: ProjectManagementState,
): void => {
  sessionStorageService.setItem<ProjectManagementState>(StorageKey.projectManagement, state);
};
