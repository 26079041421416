import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CurrentUserService } from 'src/app/shared/current-user/current-user.service';
import { ChangeRequestListItem } from '../change-request-list-item';
import { CRFiltersService } from '../cr-filters.service';
import { CRFilterType, CRStatusFilterName } from '../model';

export interface Approver {
  userId: number;
  userName: number;
}

@Component({
  selector: 'app-cr-filters',
  templateUrl: './cr-filters.component.html',
  styleUrls: ['./cr-filters.component.scss'],
})
export class CrFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: ChangeRequestListItem[];
  CRFilterType = CRFilterType;
  statusFilterValue: CRStatusFilterName;
  searchFilterValue: string;
  statusFilters = [
    { key: CRStatusFilterName.Pending, text: 'changeRequest.pending' },
    { key: CRStatusFilterName.Approved, text: 'changeRequest.approved' },
    { key: CRStatusFilterName.Cancelled, text: 'changeRequest.cancelled' },
    { key: CRStatusFilterName.All, text: 'general.all' },
  ];
  userControl = new UntypedFormControl([]);
  approverList: Approver[] = [];
  subscription = new Subscription();

  constructor(
    private crFiltersService: CRFiltersService,
    private currentUserService: CurrentUserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.crFiltersService.getFilters().subscribe((filters) => {
      if (CRFilterType.Status in filters) {
        this.statusFilterValue = filters[CRFilterType.Status as string];
      }
      if (CRFilterType.FullText in filters) {
        this.searchFilterValue = filters[CRFilterType.FullText as string];
      }
      if (CRFilterType.Approver in filters) {
        this.userControl.setValue(filters[CRFilterType.Approver as string], {
          emitEvent: false,
        });
      }
    });

    const translationSub = this.translateService.onLangChange.subscribe(() => {
      this.createApproverList();
    });

    this.subscription.add(translationSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      this.createApproverList();
    }
  }

  changeFilter(type: CRFilterType, value: any): void {
    this.crFiltersService.setFilter(type, value);
  }

  private createApproverList(): void {
    this.currentUserService.currentUser$.pipe(take(1)).subscribe((currentUser) => {
      let approverList = [
        {
          userId: currentUser.userId,
          userName: this.translateService.instant('general.assignedToMe'),
        },
        {
          userId: null,
          userName: this.translateService.instant('general.unassigned'),
        },
      ];
      if (this.data !== null) {
        approverList = this.data.reduce((list, deliverable) => {
          const { approver, approverId } = deliverable;
          if (approver && !list.find((el) => el.userId === approverId)) {
            list.push({
              userId: approverId,
              userName: approver,
            });
          }
          return list;
        }, approverList);
        if (this.userControl.value.length) {
          approverList = uniqBy([...approverList, ...this.userControl.value], 'userId');
        }
      }
      this.approverList = approverList;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
