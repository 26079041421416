<app-searchable-dropdown
  [list]="resultOptions$ | async"
  [control]="resultControl"
  placeholderText="projectImport.result"
  placeholderSearchText="general.search"
  defaultValue="projectImport.result"
  [showSelectAll]="true"
  (filterChanged)="setFilter(ProjectImportColumns.Result, $event, 'id')"
>
</app-searchable-dropdown>

<app-searchable-dropdown
  [list]="codeOptions$ | async"
  [control]="codeControl"
  placeholderText="projectImport.code"
  placeholderSearchText="general.search"
  defaultValue="projectImport.code"
  [showSelectAll]="true"
  (filterChanged)="setFilter(ProjectImportColumns.Code, $event, 'id')"
>
</app-searchable-dropdown>

<app-searchable-dropdown
  [list]="sheetOptions$ | async"
  [control]="sheetControl"
  placeholderText="projectImport.sheet"
  placeholderSearchText="general.search"
  defaultValue="projectImport.sheet"
  [showSelectAll]="true"
  (filterChanged)="setFilter(ProjectImportColumns.Sheet, $event, 'id')"
>
</app-searchable-dropdown>

<app-searchable-dropdown
  [list]="categoryOptions$ | async"
  [control]="categoryControl"
  placeholderText="projectImport.category"
  placeholderSearchText="general.search"
  defaultValue="projectImport.category"
  [showSelectAll]="true"
  (filterChanged)="setFilter(ProjectImportColumns.Category, $event, 'id')"
  class="feedback-filter"
>
</app-searchable-dropdown>

<app-searchable-dropdown
  [list]="feedbackOptions$ | async"
  [control]="feedbackControl"
  placeholderText="projectImport.feedback"
  placeholderSearchText="general.search"
  defaultValue="projectImport.feedback"
  [showSelectAll]="true"
  (filterChanged)="setFilter(ProjectImportColumns.Feedback, $event, 'id')"
  class="feedback-filter"
>
</app-searchable-dropdown>
