import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HealthColor, HealthColorMap } from '../status/healthColorMap';

@Component({
  selector: 'app-health-icon',
  templateUrl: './health-icon.component.html',
  styleUrls: ['./health-icon.component.scss'],
})
export class HealthIconComponent implements OnInit, OnChanges {
  @Input() healthValue: number;

  iconColor: string;

  ngOnInit(): void {
    this.setColorClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.healthValue) {
      this.setColorClass();
    }
  }

  private setColorClass(): void {
    const color = HealthColorMap.get(this.healthValue);
    this.iconColor = color ? color : HealthColor.None;
  }
}
