import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CodeTypes, EntityTypes } from 'src/app/common-models/node-types/node-types';
import { ProjectDetails } from 'src/app/common-models/project.model';
import * as currentContextActions from 'src/app/project-management/change-request-cart/change-request-cart-state/current-context-function/current-context-function.actions';
import { NavigationService } from 'src/app/project-management/tree-navigation/navigation.service';
import { AppState } from 'src/app/root-store/app-state';
import { getIdSelector, getProjectIdSelector, getRouteType } from 'src/app/root-store/root-store.selector';
import { BreadcrumbOption } from 'src/app/shared-controls/breadcrumbs/breadcrumbs.interfaces';
import * as projectSelectors from 'src/app/shared-state/project/project.selectors';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';
import { getDeliverableById } from '../deliverable/deliverable-state/deliverable.actions';
import { DeliverableSelectors } from '../deliverable/deliverable-state/deliverable.selectors';
import { TreeSelectors } from '../tree-navigation/tree-state/tree.selectors';

@Component({
  selector: 'app-right-side-pane',
  templateUrl: './right-side-pane.component.html',
  styleUrls: ['./right-side-pane.component.scss'],
})
export class RightSidePaneComponent implements OnInit, OnDestroy {
  nodeId: number;
  projectId: number;
  projectDetails$: Observable<ProjectDetails>;
  nodeType: EntityTypes;
  canManage = false;
  links: any[] = [];
  breadcrumbOptions: BreadcrumbOption[];

  urlParams = [];
  entityTypes = EntityTypes;
  private routerSubscription = new Subscription();
  private deliverableDetailsSubscription = new Subscription();
  private breadcrumbOptionsSubscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private store$: Store<AppState>,
    private navigationService: NavigationService,
    private accountInfoService: AccountInfoService,
  ) {}

  ngOnInit(): void {
    this.projectDetails$ = this.store$.select(projectSelectors.getProjectDetails).pipe(filter((x) => !!x));

    this.routerSubscription = combineLatest([
      this.store$.select(getIdSelector),
      this.store$.select(getProjectIdSelector),
      this.store$.select(TreeSelectors.hasManagePermissionOnCurrentNode),
    ])
      .pipe(withLatestFrom(this.store$.select(getRouteType)))
      .subscribe(([[id, projectId, canManage], type]) => {
        this.nodeId = id;
        this.projectId = projectId;
        this.nodeType = type;
        this.canManage = canManage;
        if (this.nodeType === EntityTypes.deliverable) {
          this.store$.dispatch(getDeliverableById({ deliverableId: this.nodeId, projectId }));
        }
        this.setCurrentContextFunction();
        this.createTabLinks();
      });

    this.breadcrumbOptionsSubscription = this.subscribeBreadcrumbsOptions();
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.deliverableDetailsSubscription.unsubscribe();
    this.breadcrumbOptionsSubscription.unsubscribe();
  }

  private createTabLinks(): void {
    this.links = this.route.snapshot.routeConfig.children
      .filter((child) => {
        if ('restrictedToManager' in child.data) {
          return this.canManage || this.accountInfoService.hasRole(UmsRoles.PowerUser);
        } else {
          return true;
        }
      })
      .map((child) => ({
        path: child.path,
        display: child.data.display,
      }));
  }

  private setCurrentContextFunction(): void {
    switch (this.nodeType) {
      case EntityTypes.deliverable:
        this.deliverableDetailsSubscription.unsubscribe();
        this.deliverableDetailsSubscription = this.store$
          .select(
            DeliverableSelectors.getDeliverableDetailsById({
              id: this.nodeId,
            }),
          )
          .pipe(
            filter((deliverableDetails) => deliverableDetails !== null),
            take(1),
          )
          .subscribe((deliverableDetails) => {
            this.store$.dispatch(
              currentContextActions.setCurrentContextFunctionForDeliverable({
                packageHierarchyId: deliverableDetails.packageFunctionalHierarchyId,
              }),
            );
          });
        break;
      case EntityTypes.package:
        this.store$.dispatch(
          currentContextActions.setCurrentContextFunctionForPackage({
            packageHierarchyId: this.nodeId,
          }),
        );
        break;
      case EntityTypes.function:
        this.store$.dispatch(
          currentContextActions.setCurrentContextFunction({
            functionHierarchyId: this.nodeId,
          }),
        );
        break;
    }
  }

  private subscribeBreadcrumbsOptions(): Subscription {
    return this.store$
      .select(getIdSelector)
      .pipe(
        switchMap((entityId) =>
          combineLatest([
            this.store$.select(TreeSelectors.getTreeNodeByFunctionalHierarchyId({ functionalHierarchyId: entityId })),
            this.store$.select(DeliverableSelectors.getDeliverableDetailsById({ id: entityId })),
            this.store$.select(getProjectIdSelector),
          ]),
        ),
        switchMap(([treeNode, deliverable]) => {
          const functionalHierarchyId = treeNode.functionalHierarchyId
            ? treeNode.functionalHierarchyId
            : deliverable?.packageFunctionalHierarchyId;
          return combineLatest([
            this.store$.select(
              TreeSelectors.getFunctionalHierarchyPathByFunctionalHierarchyId({ functionalHierarchyId }),
            ),
            of(deliverable),
            this.store$.select(getProjectIdSelector),
          ]);
        }),
        map(([functionalHierarchyPath, deliverable, projectId]) => {
          let breadcrumbs: BreadcrumbOption[] = functionalHierarchyPath.map((fh) => ({
            title: fh.title,
            url: this.navigationService.getDestinationUrl(projectId, fh.functionalHierarchyId, fh.typeCode),
            icon: fh.typeCode,
            invalid: fh.typeCode === CodeTypes.Package && !fh.isPackageValid,
          }));
          if (deliverable) {
            const deliverableBreadcrumb: BreadcrumbOption = {
              title: deliverable.title,
              icon: CodeTypes.Deliverable,
            };
            breadcrumbs = [...breadcrumbs, deliverableBreadcrumb];
          }
          return breadcrumbs;
        }),
      )
      .subscribe((breadcrumbOptions) => (this.breadcrumbOptions = breadcrumbOptions));
  }
}
