import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HomePageService } from '../home-page.service';
import { ProjectListItem } from '../project-list-item.model';

@Component({
  selector: 'app-recent-projects',
  templateUrl: './recent-projects.component.html',
  styleUrls: ['./recent-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentProjectsComponent implements OnInit {
  @Input() portfolioId: number;
  userProjects$: Observable<ProjectListItem[]>;
  constructor(private homePageService: HomePageService) {}

  ngOnInit(): void {
    this.userProjects$ = this.homePageService.getProjectListByPortfolio(this.portfolioId);
  }
}
