import moment from 'moment';

export interface CreateSharePackage {
  projectId: number;
  packageId: number;
  expirationDate: Date;
  emails: string[];
}

export interface User {
  accessedCount: number;
  email: string;
  lastAccessed: Date;
}

export interface SharePackage {
  createdAtUtc: Date;
  enabled: boolean;
  expirationDate: Date;
  id: number;
  createdBy: string;
  linkIdentifier: string;
  packageId: number;
  projectId: number;
  users: User[];
}

export interface AddToLink {
  projectId: number;
  linkId: number;
  emails: string[];
}

export interface SharedFunctionalHierarchy {
  functionalHierarchyId: number;
  parentId: number;
  packageId: number;
  typeCode: string;
  code: string;
  title: string;
}

export const getExpirationDaysCount = (now: Date, expirationDate: Date) => {
  const currentTime = moment(now);
  const expiration = moment(expirationDate);
  return expiration.diff(currentTime, 'days');
};

export const getIsExpired = (expirationDate: Date) => {
  const today = moment(new Date()).utc();
  const expiration = moment(expirationDate);
  return expiration.diff(today, 'days') < 0;
};

export const getActiveDays = (createdAtUtc: Date) => {
  const created = moment(createdAtUtc);
  const today = moment(new Date()).utc();
  return today.diff(created, 'days');
};

export interface ShareLink {
  daysActive: number;
  expiryDate: Date;
  publicUrl: string;
  isExpired: boolean;
}

export interface ShareAccess extends ShareLink {
  peopleWithAccess: User[];
}

export interface ManageSettings extends ShareLink {
  createdBy: string;
}

export interface SharedWith {
  email: string;
  lastAccessed: Date;
  accessedCount: number;
}
