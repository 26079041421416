import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DeliverableStoreEffects } from './deliverable.effects';
import { deliverableReducer } from './deliverable.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('deliverables', deliverableReducer),
    EffectsModule.forFeature([DeliverableStoreEffects]),
  ],
})
export class DeliverableStateModule {}
