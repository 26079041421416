<div class="split-container">
  <as-split direction="horizontal" [gutterSize]="5" (dragEnd)="onDragEnd($event)">
    <as-split-area [size]="treePercentageSize" class="grid-container">
      <app-tree-navigation> </app-tree-navigation>
    </as-split-area>
    <as-split-area [size]="rightPanePercentageSize">
      <router-outlet></router-outlet>
    </as-split-area>
  </as-split>
</div>
<app-submit-request-snack-bar
  [changesCountSelector]="changeRequestChangesCountSelector"
  [removeChangesAction]="removeChangeRequestChangesAction"
  [requestType]="requestType.Change"
  snackBarTitle="changeRequest.changeRequestContains"
></app-submit-request-snack-bar>
<app-submit-request-snack-bar
  [ngClass]="doubleSnackBar ? 'stack' : ''"
  [requestType]="requestType.Move"
  [changesCountSelector]="changeRequestMovesCountSelector"
  [removeChangesAction]="removeMovesAction"
  snackBarTitle="changeRequest.moveRequestContains"
></app-submit-request-snack-bar>
