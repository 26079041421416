import { Component, Input } from '@angular/core';
import { BulkAction } from '../change-requests-pending-approval/change-requests-pending-approval.component';
import { PendingChangeRequest } from '../change-requests-pending-approval/penging-change-request.model';

@Component({
  selector: 'app-change-request-submission-table',
  templateUrl: './change-request-submission-table.component.html',
  styleUrls: ['./change-request-submission-table.component.scss'],
})
export class ChangeRequestSubmissionTableComponent {
  @Input() requests: PendingChangeRequest[];
  @Input() action: any;
  bulkAction = BulkAction;

  displayedColumns: string[] = ['select', 'code', 'title', 'changeNoticeTitle', 'approver', 'daysOutstanding'];
  constructor() {}
}
