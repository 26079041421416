<ng-container *ngIf="curveProfiles$ | async as curveProfiles">
  <table
    mat-table
    [dataSource]="crDeliverables"
    class="orms-table"
    attr.aria-label="{{ 'general.deliverables' | translate }}"
  >
    <ng-container matColumnDef="packageCode">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'general.package' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" title="{{ element.packageCode }}">
        <button
          type="button"
          class="orms-link-button"
          (click)="element.packageCode && navigateToPackage(element.packageFunctionalHierarchyId)"
        >
          {{ element.packageCode || '-' | ellipsis: 17 }}
        </button>
      </td>
    </ng-container>
    <ng-container *ngIf="requestType === requestTypeEnum.Change" matColumnDef="request">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'general.request' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ 'changeRequest.' + (element.request | lowercase) | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="documentNumber">
      <th mat-header-cell *matHeaderCellDef scope="col">#</th>
      <td mat-cell *matCellDef="let element" title="{{ element.documentNumber }}">
        {{ element.documentNumber | ellipsis: 20 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'general.title' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" title="{{ element.title }}">
        {{ element.title || '-' | ellipsis: 30 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="newHours">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'general.hours' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <app-label-with-change-tooltip
          [status]="element.status"
          [text]="element.newHours || '-'"
          [oldValue]="element.oldHours || '-'"
          [newValue]="element.newHours || '-'"
          [displayed]="(element.newHours ?? 0) !== (element.oldHours ?? 0)"
        >
        </app-label-with-change-tooltip>
      </td>
    </ng-container>
    <ng-container matColumnDef="newCost">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'general.cost' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <app-label-with-change-tooltip
          [status]="element.status"
          [text]="element.newCost && element.newCost !== '0' ? '$' + element.newCost : '-'"
          [oldValue]="element.newCost && element.newCost !== '0' ? '$' + element.oldCost : '-'"
          [newValue]="element.newCost && element.newCost !== '0' ? '$' + element.newCost : '-'"
          [displayed]="(element.newCost ?? 0) !== (element.oldCost ?? 0)"
        >
        </app-label-with-change-tooltip>
      </td>
    </ng-container>
    <ng-container *ngIf="requestType !== requestTypeEnum.Move" matColumnDef="newCurveProfileCode">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'general.profile' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <app-label-with-change-tooltip
          [status]="element.status"
          [text]="curveProfiles.get(element.newCurveProfileCode) || '-'"
          [oldValue]="curveProfiles.get(element.oldCurveProfileCode)"
          [newValue]="curveProfiles.get(element.newCurveProfileCode)"
          [displayed]="false"
        >
        </app-label-with-change-tooltip></td
    ></ng-container>
    <ng-container *ngIf="requestType === requestTypeEnum.Move" matColumnDef="destinationPackage">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'general.moveToPackage' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.newPackageCode }}
      </td></ng-container
    >

    <ng-container matColumnDef="delete" *ngIf="!displayOnly">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let element">
        <button type="button" class="delete-deliverable" (click)="removeDeliverable(element)"></button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="deliverablesColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: deliverablesColumns"></tr>
  </table>
</ng-container>
