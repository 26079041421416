import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { isArray } from 'lodash';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { Tag } from 'src/app/common-models/tag.model';
import { TreeActionMoveDeliverableComponent } from 'src/app/project-management/tree-navigation/tree-action-move-deliverable/tree-action-move-deliverable.component';
import { CrWarningConfig } from 'src/app/shared-controls/cr-warning/cr-warning.component';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import { Filter } from 'src/app/shared-controls/searchable-dropdown/searchable-dropdown.component';
import { createCaseInsensitiveSortWithCustomRules } from 'src/app/shared/caseInsensitiveSort/caseInsensitiveSort';
import { dashDayDateFormat, timezoneUTC } from 'src/app/shared/date-options/short-date-format';
import { isDateInRange } from 'src/app/shared/is-date-in-range/is-date-in-range';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';
import { DeliverableStatus, StatusCode } from '../../../../../shared-controls/status/statusCodesMap';
import { DeliverableDetail } from '../DeliverableDetail';
import { DeliverablesFiltersService } from '../deliverables-filters.service';
import { filterBySoon } from '../lookahead-filter';
import {
  DeliverableFilterType,
  DeliverableStatusFilterName,
  DeliverablesViewState,
  LookAheadFilter,
  LookAheadFilterType,
  initializeDeliverablesViewState,
} from '../model';

@Component({
  selector: 'app-deliverables-list',
  templateUrl: './deliverables-list.component.html',
  styleUrls: ['./deliverables-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliverablesListComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() data$: BehaviorSubject<DeliverableDetail[]>;
  @Input() displayedColumns: string[];
  @Input() lastVisitedDeliverableId: number;
  @Input() underChangeControl: boolean;
  @Input() curveProfiles: CurveProfile[];
  @Input() projectId: number;
  @Output() cancelDeliverable = new EventEmitter<DeliverableDetail>();
  @Output() deleteDeliverable = new EventEmitter<DeliverableDetail>();
  @Output() navigateToDeliverable = new EventEmitter<number>();
  @Output() navigateToPackage = new EventEmitter<number>();
  @Output() percentageCompletedClicked = new EventEmitter<{
    event: Event;
    element: DeliverableDetail;
    button: HTMLButtonElement;
  }>();

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @ViewChild(MatSort) tableSort: MatSort;

  actions: any[] = [];

  viewState: DeliverablesViewState;
  Status = DeliverableStatus;
  sessionStorageKey = StorageKey.deliverablesList;
  firstLoad = true;
  filteredData = new TableVirtualScrollDataSource<DeliverableDetail>();
  shortDateFormat = dashDayDateFormat;
  timezoneUTC = timezoneUTC;
  statusCancelled = DeliverableStatus.Cancelled;
  statusMoved = DeliverableStatus.Moved;
  destinationContextFunction: number;

  crConfig: CrWarningConfig = [
    {
      currentKey: 'oldHours',
      requestedKey: 'newHours',
      type: 'number',
      label: 'general.hours',
    },
    {
      currentKey: 'oldCost',
      requestedKey: 'newCost',
      type: 'number',
      label: 'general.cost',
    },
    {
      currentKey: 'oldCurveProfileCode',
      requestedKey: 'newCurveProfileCode',
      type: 'curve-profile',
      label: 'general.curveProfile',
    },
  ];

  mrConfig: CrWarningConfig = [
    {
      currentKey: 'packageCode',
      requestedKey: 'newPackageCode',
      type: 'number',
      label: 'general.parentCode',
    },
  ];
  datepickerActionsComponent = DatepickerActionsComponent;
  private subscription = new Subscription();
  private sortingRules: Map<string, any> = new Map([
    ['startDate', startDateSortRule],
    ['endDate', endDateSortRule],
  ]);

  constructor(
    private deliverablesFiltersService: DeliverablesFiltersService,
    private sessionStorageService: SessionStorageService,
    private dialog: MatLegacyDialog,
    private changeDetector: ChangeDetectorRef,
  ) {}

  private getOrInitializeViewState(): DeliverablesViewState {
    return (
      this.sessionStorageService.getItem(this.sessionStorageKey) ||
      initializeDeliverablesViewState(this.sessionStorageService)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.actions = [
      {
        icon: (deliverable: DeliverableDetail) =>
          deliverable.canMove ? '/assets/icons/move.svg' : '/assets/icons/move-grey.svg',
        label: 'deliverable.move',
        callBack: (deliverable: DeliverableDetail) => this.moveDeliverable(deliverable),
        isDisabled: (deliverable: DeliverableDetail) => !deliverable.canMove,
        isSvg: true,
      },
      {
        icon: (deliverable: DeliverableDetail) =>
          deliverable.canCancel
            ? '/assets/icons/icon-grey-cancel.secondary-color.svg'
            : '/assets/icons/icon-grey-cancel.svg',
        label: 'general.cancel',
        callBack: (deliverable: DeliverableDetail) => this.cancelDeliverable.emit(deliverable),
        isDisabled: (deliverable: DeliverableDetail) => !deliverable.canCancel,
        isSvg: true,
      },
      {
        icon: (deliverable: DeliverableDetail) =>
          deliverable.canDelete ? '/assets/icons/icon-delete.svg' : '/assets/icons/icon-delete-grey.svg',
        label: 'general.delete',
        callBack: (deliverable: DeliverableDetail) => this.deleteDeliverable.emit(deliverable),
        isDisabled: (deliverable) => !deliverable.canDelete,
        isSvg: true,
      },
    ];
  }

  ngAfterViewInit(): void {
    this.viewState = this.getOrInitializeViewState();

    this.filteredData.sortingDataAccessor = createCaseInsensitiveSortWithCustomRules(this.sortingRules);
    const sub = combineLatest([this.data$, this.deliverablesFiltersService.getFilters()]).subscribe(
      ([data, filters]) => {
        let filteredData = [];
        this.viewState.deliverableFilters = filters;
        if (data) {
          filteredData = data.map((deliverable) => {
            const updatedDeliverable = {} as DeliverableDetail;
            if (!deliverable.endDate) {
              updatedDeliverable.endDate = deliverable.plannedEndDate;
              updatedDeliverable.usesPackageEndDate = true;
            }
            if (!deliverable.startDate) {
              updatedDeliverable.startDate = deliverable.plannedStartDate;
              updatedDeliverable.usesPackageStartDate = true;
            }
            return { ...deliverable, ...updatedDeliverable };
          });
          if (DeliverableFilterType.Status in filters) {
            filteredData = this.applyStatusFilter(filters[DeliverableFilterType.Status as string], filteredData);
          }
          if (DeliverableFilterType.Responsible in filters) {
            filteredData = this.applyResponsibleFilter(
              filters[DeliverableFilterType.Responsible as string],
              filteredData,
            );
          }
          if (DeliverableFilterType.Tags in filters) {
            filteredData = this.applyTagsFilter(filters[DeliverableFilterType.Tags as string], filteredData);
          }
          if (DeliverableFilterType.LookAhead in filters) {
            filteredData = this.applyLookAheadFilter(filters[DeliverableFilterType.LookAhead as string], filteredData);
          }
          if (DeliverableFilterType.FullText in filters) {
            filteredData = this.applyFullTextFilter(filters[DeliverableFilterType.FullText as string], filteredData);
          }
        }
        this.filteredData.data = filteredData;
        this.viewPort.checkViewportSize();
        if (this.firstLoad) {
          this.scrollToLastVisited();
          this.firstLoad = false;
        }
        this.changeDetector.markForCheck();
      },
    );
    this.subscription.add(sub);
    this.filteredData.sort = this.tableSort;
  }

  scrollToLastVisited(): void {
    if (!this.lastVisitedDeliverableId) {
      return;
    }
    const lastVisitedIndex = this.filteredData.data?.findIndex(
      (deliverable) => deliverable.deliverableId === this.lastVisitedDeliverableId,
    );
    if (lastVisitedIndex !== -1) {
      setTimeout(() => {
        this.viewPort.scrollToIndex(lastVisitedIndex);
      });
    }
  }

  applyStatusFilter(status: DeliverableStatusFilterName, data: DeliverableDetail[]): DeliverableDetail[] {
    switch (status) {
      case DeliverableStatusFilterName.Current:
        return data.filter((deliverable) => this.isDisplayed(deliverable.status));
      case DeliverableStatusFilterName.Incomplete:
        return data.filter(
          (deliverable) => deliverable.percentageCompleted < 100 && this.isDisplayed(deliverable.status),
        );
      case DeliverableStatusFilterName.Overdue:
        return data.filter(
          (deliverable) =>
            deliverable.percentageCompleted < 100 &&
            new Date(deliverable.endDate as Date) < new Date() &&
            this.isDisplayed(deliverable.status),
        );
      case DeliverableStatusFilterName.OutOfPlan:
        return data.filter(
          (deliverable) =>
            isDateInRange(deliverable.startDate, deliverable.plannedStartDate, deliverable.plannedEndDate) === false ||
            isDateInRange(deliverable.endDate, deliverable.plannedStartDate, deliverable.plannedEndDate) === false,
        );
      case DeliverableStatusFilterName.Complete:
        return data.filter(
          (deliverable) => deliverable.percentageCompleted === 100 && this.isDisplayed(deliverable.status),
        );
      case DeliverableStatusFilterName.All:
        return data;
    }
  }

  private isDisplayed(status: DeliverableStatus | StatusCode) {
    return status !== DeliverableStatus.Cancelled && status !== DeliverableStatus.Moved;
  }

  applyResponsibleFilter(responsibles: Filter[], data: DeliverableDetail[]): DeliverableDetail[] {
    if (!isArray(responsibles) || !responsibles.length) {
      return data;
    }
    const responsibleIds = responsibles && responsibles.map((responsible) => responsible.id);
    return data.filter((deliverable) => responsibleIds.includes(deliverable.responsibleId));
  }

  applyTagsFilter(tags: Tag[], data: DeliverableDetail[]): DeliverableDetail[] {
    if (!isArray(tags) || !tags.length) {
      return data;
    }
    const tagIds = tags && tags.map((tag) => tag.id);
    return data.filter((deliverable) => deliverable.tags.some((tag) => tagIds.includes(tag.id)));
  }

  applyLookAheadFilter(lookAheadFilter: LookAheadFilter, data: DeliverableDetail[]): DeliverableDetail[] {
    switch (lookAheadFilter.type) {
      case LookAheadFilterType.dueSoon:
        return filterBySoon(data, 'endDate');
      case LookAheadFilterType.startsSoon:
        return filterBySoon(data, 'startDate');
      case LookAheadFilterType.dueBefore:
        return data.filter((deliverable) => new Date(deliverable.endDate as Date) < lookAheadFilter.value);
      case LookAheadFilterType.startsBefore:
        return data.filter((deliverable) => new Date(deliverable.startDate as Date) < lookAheadFilter.value);
      case null:
        return data;
    }
  }

  applyFullTextFilter(fullTextFilter: string, data: DeliverableDetail[]): DeliverableDetail[] {
    const textToSearch = fullTextFilter.toLowerCase();
    return data.filter(
      (deliverable) =>
        deliverable.title.toLowerCase().includes(textToSearch) ||
        deliverable.documentNumber?.toLowerCase().includes(textToSearch) ||
        deliverable.packageCode?.toLowerCase().includes(textToSearch),
    );
  }

  private moveDeliverable(deliverableDetail: DeliverableDetail): void {
    this.dialog.open(TreeActionMoveDeliverableComponent, {
      data: {
        deliverableDetail: deliverableDetail,
        underChangeControl: this.underChangeControl,
      },
      panelClass: 'orms-move-deliverable-dialogue',
      disableClose: true,
    });
  }
}

function startDateSortRule(item: DeliverableDetail, prop: string): any {
  return item[prop];
}

function endDateSortRule(item: DeliverableDetail, prop: string): any {
  return item[prop];
}
