import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { WizardStepComponent } from 'src/app/shared-controls/wizard/wizard.component';
import { ProjectService } from 'src/app/shared/services/project-service';
import { ProjectImportWizardContext } from '../project-import.models';
import { IncludeDataDialogComponent } from './include-data-dialog/include-data-dialog.component';

@Component({
  selector: 'app-choose-source-step',
  templateUrl: './choose-source-step.component.html',
  styleUrls: ['./choose-source-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseSourceStepComponent implements OnInit, WizardStepComponent {
  nextStepClick = new EventEmitter<void>();
  previousStepClick = new EventEmitter<void>();
  cancelClick = new EventEmitter<void>();

  context: ProjectImportWizardContext;
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatLegacyDialog,
    private projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    this.context.file = null;

    this.form = this.fb.group({
      filePath: new UntypedFormControl(),
    });
  }

  downloadProjectImportTemplate(): void {
    this.dialog.open(IncludeDataDialogComponent, {
      data: {
        projectId: this.context.projectId,
        projectTitle: this.context.projectTitle,
        mode: this.context.mode,
      },
      panelClass: 'orms-include-data-dialog',
    });
  }

  next(): void {
    this.projectService.canCurrentUserManageProject$.value
      ? this.nextStepClick.emit()
      : this.context.onAccessDeniedAction();
  }

  cancel(): void {
    this.cancelClick.emit();
  }

  get isNextStepDisabled(): boolean {
    return this.context.file === null;
  }

  onFileSelected($event) {
    const file = $event.target.files[0];
    this.form.get('filePath').setValue(`https://hatch.com/project${this.context.projectId}/${file.name}`);
    this.context.file = file;
  }
}
