import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshCRTriggerService {
  refreshPackagesTrigger$ = new BehaviorSubject(true);
  refreshDeliverablesTrigger$ = new BehaviorSubject(true);

  refreshPackages(): void {
    this.refreshPackagesTrigger$.next(true);
  }

  refreshDeliverables(): void {
    this.refreshDeliverablesTrigger$.next(true);
  }
}
