import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import * as treeActions from 'src/app/project-management/tree-navigation/tree-state/tree.actions';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { getIdSelector } from 'src/app/root-store/root-store.selector';
import * as projectActions from 'src/app/shared-state/project/project.actions';
import { AppConfigService } from '../../shared/services/app.config.service';
import { deleteDeliverableSuccess } from '../common/navigation-tabs/deliverables/deliverables-list-state/deliverables-list.actions';
import { SignalRToken } from './signalr-token.model';
import { ISignalRResponse, SignalRAction, SignalRSubject } from './signalr.model';

@Injectable({ providedIn: 'root' })
export class SignalRService {
  private MAX_NODES_TO_UPDATE = 60;
  private hubConnection: SignalR.HubConnection = null;
  private interval: NodeJS.Timeout;
  private readonly hubChannel = 'PMNotification';
  private kpiUpdatedSubject: Subject<number[]> = new Subject();
  private curveUpdatedSubject: Subject<number[]> = new Subject();
  private nodeDeletedSubject: Subject<number[]> = new Subject();
  private nodeAddedSubject: Subject<number> = new Subject();
  private nodeChangedSubject: Subject<number[]> = new Subject();

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private store$: Store<AppState>,
  ) {}

  public init(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.startConnection();
      resolve(true);
    });
  }

  public kpiUpdated(): Observable<number[]> {
    return this.kpiUpdatedSubject.asObservable();
  }

  public curveUpdated(): Observable<number[]> {
    return this.curveUpdatedSubject.asObservable();
  }

  public nodeDeleted(): Observable<number[]> {
    return this.nodeDeletedSubject.asObservable();
  }

  public nodeAdded(): Observable<number> {
    return this.nodeAddedSubject.asObservable();
  }

  public nodeChanged(): Observable<number[]> {
    return this.nodeChangedSubject.asObservable();
  }

  private startConnection(): void {
    this.store$
      .select(routerSelectors.getProjectIdSelector)
      .pipe(
        tap((projectId) => {
          if (!projectId && this.hubConnection) {
            this.hubConnection.off(this.hubChannel);
          }
        }),
        filter((projectId) => !!projectId),
        switchMap((projectId) => {
          return combineLatest([of(projectId), this.getSignalRClientToken(projectId)]);
        }),
        tap(([projectId, con]) => {
          if (this.hubConnection) {
            this.hubConnection.off(this.hubChannel);
          }

          const options = {
            accessTokenFactory: () => con.accessToken,
          };

          const { connectionTimeout, automaticReconnectOptions } = this.appConfigService.settings.signalr;

          this.hubConnection = new SignalR.HubConnectionBuilder()
            .withUrl(con.url, options)
            .configureLogging(SignalR.LogLevel.Critical)
            .withAutomaticReconnect(automaticReconnectOptions)
            .build();

          if (this.interval) {
            clearInterval(this.interval);
          }

          const fortyFiveMinutes = 2700000;

          this.interval = setInterval(() => {
            this.startConnection();
          }, fortyFiveMinutes);

          this.hubConnection.serverTimeoutInMilliseconds = connectionTimeout;
          this.hubConnection.keepAliveIntervalInMilliseconds = connectionTimeout;
        }),
        tap(() => this.hubConnection.start()),
      )
      .subscribe(([projectId]) => this.registerEvents(projectId));
  }

  private getSignalRClientToken(projectId: number): Observable<SignalRToken> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const getTokenUrl = this.appConfigService.settings.api.endpoints.signalRAuth;
    const params = new HttpParams().set('projectId', projectId.toString());

    return this.http.get<SignalRToken>(baseUrl + getTokenUrl, { params });
  }

  private registerEvents(projectId: number): void {
    this.hubConnection.on(this.hubChannel, (payload: ISignalRResponse) => {
      if (projectId !== payload.projectId) {
        return;
      }

      try {
        if (!(this.subjectIsAllowed(payload.subject) && this.actionIsAllowed(payload.action))) {
          console.error(
            'Unhandled SignalR notification, subject:',
            payload.subject,
            ' action:',
            payload.action,
            ' functional hierarchy ids:',
            payload.functionalHierarchyIds,
          );
        }

        switch (payload.subject) {
          case SignalRSubject.KPI:
            this.notifyKPIChange(projectId, payload.functionalHierarchyIds);
            break;
          case SignalRSubject.Node:
            if (payload.action === SignalRAction.Delete) {
              this.notifyNodeDeletion(payload.functionalHierarchyIds);
            } else if (payload.action === SignalRAction.New) {
              this.notifyNodeAddition(projectId, payload.functionalHierarchyIds);
            } else if (payload.action === SignalRAction.Change) {
              this.notifyNodeChange(projectId, payload.functionalHierarchyIds);
            } else if (payload.action === SignalRAction.Move) {
              this.store$.dispatch(
                treeActions.moveNode({
                  nodeMoveData: {
                    functionalHierarchyId: payload.functionalHierarchyIds[0],
                    functionDestinationFunctionalHierarchyId: payload.functionalHierarchyIds[1],
                  },
                }),
              );
            } else if (payload.action === SignalRAction.Reload) {
              // reload action is triggered after bulk update
              // only page of user who triggered bulk update is refreshed and updated functional hierarchies
              if (payload.functionalHierarchyIds != null && payload.functionalHierarchyIds.length > 0) {
                this.notifyNodeChange(projectId, payload.functionalHierarchyIds);
              }
            } else {
              this.dispatchUpdateAction(projectId, payload.functionalHierarchyIds);
            }
            break;
          case SignalRSubject.Project:
            this.store$.dispatch(
              projectActions.getProjectDetails({
                projectId: payload.projectId,
              }),
            );
            break;
          case SignalRSubject.Deliverable:
            if (payload.action === SignalRAction.Delete) {
              this.store$.dispatch(
                deleteDeliverableSuccess({
                  deliverableId: payload.functionalHierarchyIds[0],
                }),
              );
              this.store$
                .select(getIdSelector)
                .pipe(take(1))
                .toPromise()
                .then((x) => {
                  this.dispatchUpdateAction(projectId, [x]);
                });
            }
            break;
          case SignalRSubject.Curve:
            this.notifyCurveChange(payload.functionalHierarchyIds);
            break;
        }
      } catch {
        console.error('There was an error parsing a signalR message: ', payload);
      }
    });
  }

  private dispatchUpdateAction(projectId: number, functionalHierarchyIds: number[]): void {
    if (!functionalHierarchyIds?.length || functionalHierarchyIds.length > this.MAX_NODES_TO_UPDATE) {
      this.store$.dispatch(treeActions.reloadProjectData({ projectId }));
    } else {
      this.store$.dispatch(
        treeActions.updateProjectPartialData({
          projectId,
          functionalHierarchiesIds: functionalHierarchyIds,
        }),
      );
    }
  }

  private subjectIsAllowed(subject: SignalRSubject): boolean {
    return Object.values(SignalRSubject)?.includes(subject);
  }

  private actionIsAllowed(action: SignalRAction): boolean {
    return Object.values(SignalRAction)?.includes(action);
  }

  private notifyKPIChange(projectId: number, functionalHierarchyIds: number[]): void {
    this.dispatchUpdateAction(projectId, functionalHierarchyIds);
    this.kpiUpdatedSubject.next(functionalHierarchyIds);
  }

  private notifyCurveChange(functionalHierarchyIds: number[]): void {
    this.curveUpdatedSubject.next(functionalHierarchyIds);
  }

  private notifyNodeDeletion(functionalHierarchyIds: number[]): void {
    this.nodeDeletedSubject.next(functionalHierarchyIds);
  }

  private notifyNodeAddition(projectId: number, functionalHierarchyIds: number[]): void {
    this.dispatchUpdateAction(projectId, functionalHierarchyIds);
    this.nodeAddedSubject.next(functionalHierarchyIds[0]);
  }

  private notifyNodeChange(projectId: number, functionalHierarchyIds: number[]): void {
    this.dispatchUpdateAction(projectId, functionalHierarchyIds);
    this.nodeChangedSubject.next(functionalHierarchyIds);
  }
}
