export enum TreeGridActions {
  columnstate = 'columnstate',
  delete = 'delete',
  add = 'add',
  refresh = 'refresh',
}

export enum Action {
  DataLoaded,
  NodeAdded,
  NodeDeleted,
  NodeUpdated,
  NotSet,
  Reordered,
}

export interface TreeGridEvent {
  requestType: string;
}

export const setGridMinWidth = () => {
  const gridHeaders = Array.from(document.getElementById('_gridcontrolcolgroup').getElementsByTagName('col'));

  const visibleHeaders = gridHeaders.filter((x) => !x.style.display);
  const totalWidth = visibleHeaders.reduce((acc, curr) => {
    return acc + parseInt(curr.style.width, 10);
  }, 0);

  const gridElement = document.querySelectorAll<HTMLElement>('.e-grid')[0];

  gridElement.style.minWidth = `${totalWidth}px`;
};
