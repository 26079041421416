<app-header></app-header>
<div class="content" role="main">
  <ng-container
    *ngIf="{
      projectId: projectId$ | async,
      sharedId: shareId$ | async
    } as observables"
  >
    <app-sidebar *ngIf="observables.projectId && !observables.sharedId"></app-sidebar>
  </ng-container>
  <router-outlet></router-outlet>
</div>
