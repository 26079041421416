import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ChangeRequestListService } from '../change-request-list.service';

@Component({
  selector: 'app-cr-reject-prompt',
  templateUrl: './cr-reject-prompt.component.html',
  styleUrls: ['./cr-reject-prompt.component.scss'],
})
export class CrRejectPromptComponent implements OnDestroy {
  form: UntypedFormGroup;
  functionalHierarchyId: number;
  subscription = new Subscription();
  submitDisabled = false;
  private showErrors = false;
  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: { changeNoticeId: number },
    private changeRequestListService: ChangeRequestListService,
    private notificationService: NotificationService,
    public dialogRef: MatLegacyDialogRef<CrRejectPromptComponent>,
  ) {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required),
    });
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return this.showErrors && control.invalid;
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.showErrors = true;
      return;
    }
    this.submitDisabled = true;

    const sub = this.changeRequestListService.reject(this.data.changeNoticeId, this.form.get('reason').value).subscribe(
      () => this.dialogRef.close('rejected'),
      (err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err);
        this.submitDisabled = false;
      },
    );

    this.subscription.add(sub);
  }
}
