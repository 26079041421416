export interface CRStatus {
  class: string;
  text: string;
}

export enum CRStatusCode {
  Logged = 'LG',
  Approved = 'AP',
  Rejected = 'RJ',
  Cancelled = 'CA',
}

export enum CRStatusCodeFull {
  Logged = 'Logged',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
}

export const CRStatusCodesMap = new Map<string, CRStatus>([
  [
    CRStatusCode.Logged,
    {
      class: 'label-yellow',
      text: 'crStatus.logged',
    },
  ],
  [
    CRStatusCode.Approved,
    {
      class: 'label-green',
      text: 'crStatus.approved',
    },
  ],
  [
    CRStatusCode.Rejected,
    {
      class: 'label-red',
      text: 'crStatus.rejected',
    },
  ],
  [
    CRStatusCode.Cancelled,
    {
      class: 'label-orange',
      text: 'crStatus.cancelled',
    },
  ],
]);
