<hr />
<div class="table-container">
  <table mat-table [dataSource]="requests" attr.aria-label="{{ 'general.pendingChangeRequests' | translate }}">
    <ng-container matColumnDef="select">
      <th mat-header-cell class="orms-dl-120" *matHeaderCellDef scope="col"></th>
      <td mat-cell class="orms-dl-120" title="{{ element.code }}" *matCellDef="let element">
        <ng-container
          [ngTemplateOutlet]="action === bulkAction.Approve ? successIcon : rejectOrWarning"
          [ngTemplateOutletContext]="{ element }"
        >
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell class="orms-dl-120" *matHeaderCellDef scope="col">
        {{ 'general.code' | translate }}
      </th>
      <td mat-cell class="orms-dl-120" title="{{ element.code }}" *matCellDef="let element">
        {{ element.code | ellipsis: 15 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell class="orms-dl-50 title" *matHeaderCellDef scope="col">
        {{ 'general.packageOrFunction' | translate }}
      </th>
      <td mat-cell class="orms-dl-50 title" *matCellDef="let element">
        {{ element.title | ellipsis: 20 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="changeNoticeTitle">
      <th mat-header-cell class="orms-dl-150" *matHeaderCellDef scope="col">
        {{ 'general.title' | translate }}
      </th>
      <td mat-cell class="orms-dl-150" *matCellDef="let element">
        {{ element.changeNoticeTitle | ellipsis: 50 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="approver">
      <th mat-header-cell class="orms-dl-120" *matHeaderCellDef scope="col">
        {{ 'changeRequest.approver' | translate }}
      </th>
      <td mat-cell class="orms-dl-120" *matCellDef="let element">
        {{ element.approver }}
      </td>
    </ng-container>
    <ng-container matColumnDef="daysOutstanding">
      <th mat-header-cell class="orms-dl-80" *matHeaderCellDef scope="col">
        {{ 'general.daysOutstanding' | translate }}
      </th>
      <td mat-cell class="orms-dl-80" *matCellDef="let element">
        {{ element.daysOutstanding }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="orms-deliverable-element-row"></tr>
  </table>
</div>

<ng-template #successIcon let-element="element">
  <!-- <mat-icon class="success-icon">check_circle</mat-icon> -->
</ng-template>

<ng-template #rejectOrWarning let-element="element">
  <ng-container
    [ngTemplateOutlet]="action === bulkAction.Reject ? rejectIcon : warningIcon"
    [ngTemplateOutletContext]="{ element }"
  >
  </ng-container>
</ng-template>

<ng-template #rejectIcon let-element="element">
  <mat-icon
    *ngIf="element?.message"
    [matTooltip]="element.message"
    matTooltipPosition="above"
    matTooltipClass="orms-tooltip-common"
    class="reject-icon"
    >cancel</mat-icon
  >
</ng-template>
<ng-template #warningIcon let-element="element">
  <mat-icon
    *ngIf="element?.message"
    [matTooltip]="element.message"
    matTooltipPosition="above"
    matTooltipClass="orms-tooltip-common"
    class="warning-icon"
    >info</mat-icon
  >
</ng-template>
<hr />
