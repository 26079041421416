import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { longDateFormat, timezoneUTC } from 'src/app/shared/date-options/short-date-format';
import { isDifferentDay } from 'src/app/shared/is-different-day/is-different-day';

export type FieldType = 'date' | 'number' | 'curve-profile';

interface CrWarningConfigItem {
  currentKey: string;
  requestedKey: string;
  type: FieldType;
  label?: string;
}

export type CrWarningConfig = CrWarningConfigItem[];

@Component({
  selector: 'app-cr-warning',
  templateUrl: './cr-warning.component.html',
  styleUrls: ['./cr-warning.component.scss'],
})
export class CrWarningComponent implements OnChanges {
  @Input() cr: object;
  @Input() currentKey: string;
  @Input() requestedKey: string;
  @Input() curveProfiles: CurveProfile[];
  @Input() config: CrWarningConfig;
  @Input() type: FieldType;
  @Input() iconPath = '/assets/icons/icon-warning.svg';
  text = '';

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  ngOnChanges(): void {
    if (!this.cr) {
      this.text = '';
      return;
    }

    if (!this.config) {
      this.config = [
        {
          currentKey: this.currentKey,
          requestedKey: this.requestedKey,
          type: this.type,
        },
      ];
    }

    this.text = '';

    this.config.forEach((configItem) => this.findDiff(configItem));
  }

  findDiff(configItem: CrWarningConfigItem): void {
    const { requestedKey, currentKey, type } = configItem;
    if (requestedKey in this.cr) {
      let diff = false;
      switch (type) {
        case 'date':
          diff = isDifferentDay(this.cr[currentKey], this.cr[requestedKey]);
          break;
        case 'number':
          // tslint:disable-next-line: triple-equals
          diff = this.cr[currentKey] != this.cr[requestedKey];
          break;
        case 'curve-profile':
          diff = this.cr[currentKey] !== this.cr[requestedKey];
          break;
        default:
          throw new Error('unsupported cr type!');
      }
      if (diff) {
        this.addText(configItem);
      }
    }
  }

  addText(configItem: CrWarningConfigItem): void {
    const { requestedKey, currentKey, type, label } = configItem;
    let current = this.cr[currentKey];
    let requested = this.cr[requestedKey];
    if (type === 'date') {
      current = current ? this.datePipe.transform(new Date(current), longDateFormat, timezoneUTC) : '';
      requested = requested ? this.datePipe.transform(new Date(requested), longDateFormat, timezoneUTC) : '';
    }
    if (type === 'curve-profile') {
      current = this.curveProfiles.find((curveProfile) => curveProfile.code === current)?.description;
      requested = this.curveProfiles.find((curveProfile) => curveProfile.code === requested)?.description;
    }

    const currentLabel = this.translateService.instant('changeRequest.current');
    const requestedLabel = this.translateService.instant('changeRequest.requested');
    const fieldLabel = label ? ` ${this.translateService.instant(label).toLowerCase()}` : '';

    this.text += `
      ${currentLabel}${fieldLabel}: ${current}
      ${requestedLabel}${fieldLabel} : ${requested}
    `;
  }
}
