<ng-container>
  <table
    mat-table
    [dataSource]="statusChanges"
    class="orms-table"
    attr.aria-label="{{ 'general.history' | translate }}"
  >
    <ng-container matColumnDef="eventDate">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'changeRequest.eventDate' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; flex-direction: column">
          <span>{{ element?.eventDate | date: shortDateFormat }} </span>
          <span class="pale">{{ element?.eventDate | date: timeFormat }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'changeRequest.description' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element?.level === 'Package' && element?.status === 'Submitted'
            ? ('changeRequest.request' | translate)
            : element?.level
        }}
        {{ element?.status }}
        {{ 'changeRequest.by' | translate }} {{ element?.manager }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="historyColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: historyColumns"></tr>
  </table>
</ng-container>
