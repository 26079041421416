import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { clearProjectRelatedData } from 'src/app/root-store/root-store.actions';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import * as deliverableActions from './deliverable.actions';

export interface DeliverableState extends EntityState<DeliverableDetail> {}

export const deliverableStructureAdapter: EntityAdapter<DeliverableDetail> = createEntityAdapter<DeliverableDetail>({
  selectId: (model) => model.deliverableId,
  sortComparer: (a: DeliverableDetail, b: DeliverableDetail): number => a?.title?.localeCompare(b?.title),
});

const initialState: DeliverableState = deliverableStructureAdapter.getInitialState();

export const deliverableReducer = createReducer(
  initialState,
  on(deliverableActions.updateDeliverableSuccess, (state, { deliverable }) => {
    return deliverableStructureAdapter.upsertOne(deliverable, state);
  }),
  on(deliverableActions.getDeliverableByIdSuccess, (state, { deliverable }) => {
    return deliverableStructureAdapter.upsertOne(deliverable, state);
  }),
  on(deliverableActions.addDeliverableTagSuccess, (state, { tag }) => {
    const entities = Object.assign(
      {},
      ...Object.entries(state.entities).map(([hierarchyId, deliverable]) => {
        return {
          [hierarchyId]: { ...deliverable, tags: [...deliverable.tags, tag] },
        };
      }),
    );

    return {
      ...state,
      entities,
    };
  }),
  on(deliverableActions.removeDeliverableTagSuccess, (state, { tagId }) => {
    const entities = Object.assign(
      {},
      ...Object.entries(state.entities).map(([hierarchyId, deliverable]) => {
        return {
          [hierarchyId]: {
            ...deliverable,
            tags: deliverable.tags.filter((tag) => tag.id !== tagId),
          },
        };
      }),
    );

    return {
      ...state,
      entities,
    };
  }),
  on(clearProjectRelatedData, () => {
    return deliverableStructureAdapter.getInitialState();
  }),
);

const { selectAll, selectEntities } = deliverableStructureAdapter.getSelectors();
export const selectTreeNodeEntities = selectEntities;
export const selectAllNodes = selectAll;
