import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { dashDayDateFormat, timeFormat } from 'src/app/shared/date-options/short-date-format';
import { ChangeRequestDetailsComponent } from '../../common/navigation-tabs/change-request-list/change-request-details/change-request-details.component';
import { DifferenceDialogComponent } from '../difference-dialog/difference-dialog.component';
import { containsChangeRequest } from '../history-common-functions';
import { HistoryData } from '../history-data.model';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() historyData: HistoryData[] = [];
  @Input() entityType: EntityTypes;
  @Input() loading: boolean;
  shortDateFormat = dashDayDateFormat;
  timeFormat = timeFormat;
  historyColumns = ['occurredAt', 'description'];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new TableVirtualScrollDataSource<HistoryData>();
  projectId: number;
  subscription = new Subscription();

  constructor(
    private dialog: MatLegacyDialog,
    private store$: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const projectIdSubscription = this.store$
      .select(getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.projectId = projectId;
      });

    this.subscription.add(projectIdSubscription);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('historyData' in changes && this.historyData) {
      this.dataSource.data = [...this.historyData];
    }
    this.changeDetector.markForCheck();
  }

  showHistoryDetails(data: HistoryData): void {
    if (containsChangeRequest(data)) {
      this.openChangeRequestDialog(data.changeNoticeId);
      return;
    }

    this.openDiffDialog(data);
  }

  private openDiffDialog(data: HistoryData): void {
    this.dialog.open(DifferenceDialogComponent, {
      data: {
        historyData: data,
        historySubject: this.entityType,
      },
      panelClass: 'orms-difference-dialog',
    });
  }

  private openChangeRequestDialog(crId: number): void {
    this.dialog.open(ChangeRequestDetailsComponent, {
      data: { id: crId, canReject: false, canApprove: false, projectId: this.projectId },
      panelClass: 'orms-change-request-details',
      disableClose: true,
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
