<app-cr-filters [data]="data"></app-cr-filters>
<cdk-virtual-scroll-viewport class="cr-list" tvsItemSize="64">
  <table
    class="resizable-table"
    mat-table
    matSort
    [dataSource]="filteredDataSource"
    attr.aria-label="{{ 'general.changeRequests' | translate }}"
  >
    <ng-container matColumnDef="code">
      <th
        [hidden]="isPackage"
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="code"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('code')"
        scope="col"
      >
        <span mat-sort-header>{{ 'general.code' | translate }}</span>
      </th>
      <td [hidden]="isPackage" mat-cell *matCellDef="let element">
        <a
          [routerLink]="['/project-management', projectId, 'package', element.functionalHierarchyId, 'detail']"
          class="orms-link-button"
          (click)="element.code && navigateToPackage(element.functionalHierarchyId)"
        >
          {{ element.code || '-' }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th
        resizeColumn="number"
        [ngClass]="{ 'first-child': isPackage }"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('number')"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      >
        <span mat-sort-header>#</span>
      </th>
      <td mat-cell [ngClass]="{ 'first-child': isPackage }" *matCellDef="let element">
        <a
          class="orms-link-button"
          routerLink="."
          (click)="
            viewDetails(element.changeNoticeId, element.canReject, element.canApprove, element.changeRequestType)
          "
          [queryParams]="{ changeNoticeId: element.changeNoticeId }"
        >
          {{ element.number }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="title"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('title')"
        scope="col"
      >
        <span mat-sort-header>{{ 'general.title' | translate }}</span>
      </th>

      <td mat-cell *matCellDef="let element" title="{{ element.title }}">
        <a
          class="orms-link-button"
          routerLink="."
          (click)="
            viewDetails(element.changeNoticeId, element.canReject, element.canApprove, element.changeRequestType)
          "
          [queryParams]="{ changeNoticeId: element.changeNoticeId }"
        >
          {{ element.title }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="status"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('status')"
        scope="col"
      >
        <span mat-sort-header>{{ 'general.status' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element" class="cr-status">
        <span class="cr-status-icon" [ngClass]="element?.statusDetail?.class"></span>
        {{ element?.statusDetail?.text | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="startDate"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('startDate')"
        scope="col"
      >
        <span mat-sort-header
          >{{ 'general.start' | translate }} <br />
          ( +/ - )</span
        >
      </th>
      <td mat-cell *matCellDef="let element">{{ element.startDate | day }}</td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="endDate"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('endDate')"
        scope="col"
      >
        <span mat-sort-header
          >{{ 'general.end' | translate }} <br />
          ( +/ - )</span
        >
      </th>
      <td mat-cell *matCellDef="let element">{{ element.endDate | day }}</td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="duration"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('duration')"
        scope="col"
      >
        <span mat-sort-header
          >{{ 'general.duration' | translate }} <br />
          ( +/ - )</span
        >
      </th>
      <td mat-cell *matCellDef="let element">{{ element.duration | day }}</td>
    </ng-container>

    <ng-container matColumnDef="deliverables">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="deliverables"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('deliverables')"
        scope="col"
      >
        <span mat-sort-header
          >{{ 'general.deliverables' | translate }} <br />
          ( +/ - )</span
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.deliverables | diff }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hours">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="hours"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('hours')"
        scope="col"
      >
        <span mat-sort-header
          >{{ 'general.budgetHours' | translate }} <br />
          ( +/ - )</span
        >
      </th>
      <td mat-cell *matCellDef="let element">{{ element.hours | diff }}</td>
    </ng-container>

    <ng-container matColumnDef="cost">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="cost"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('cost')"
        scope="col"
      >
        <span mat-sort-header
          >{{ 'general.budgetCost' | translate }} <br />
          ( +/ - )</span
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.cost | diff: '$' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="createdBy"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('createdBy')"
        scope="col"
      >
        <span mat-sort-header>{{ 'general.createdBy' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="createdDate"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('createdDate')"
        scope="col"
      >
        <span mat-sort-header>{{ 'general.createdDate' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdDate | date: shortDateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="approver">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="approver"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('approver')"
        scope="col"
      >
        <span mat-sort-header>{{ 'changeRequest.approver' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.approver }}</td>
    </ng-container>

    <ng-container matColumnDef="approvedDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        resizeColumn="approvedDate"
        [sessionStorageKey]="sessionStorageKey"
        [index]="displayedColumns.indexOf('approvedDate')"
        scope="col"
      >
        <span mat-sort-header>{{ 'general.approvedDate' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.approvedDate | date: shortDateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        resizeColumn="actions"
        [sessionStorageKey]="sessionStorageKey"
        mat-header-cell
        *matHeaderCellDef
        scope="col"
      ></th>
      <td mat-cell *matCellDef="let element">
        <span>
          <app-action-menu [item]="element" [actions]="actions">
            <mat-icon>more_vert</mat-icon>
          </app-action-menu>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <app-spinner *ngIf="loading$ | async"></app-spinner>
  <div class="no-results" *ngIf="(loading$ | async) === false && !filteredDataSource.data.length">
    {{ 'changeRequest.noResults' | translate }}
  </div>
</cdk-virtual-scroll-viewport>
