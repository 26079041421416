import { isDifferentDay } from 'src/app/shared/is-different-day/is-different-day';
import { ChangeRequestDeliverable, ChangeRequestPackage } from './change-request-cart-state/cart-item.model';

export const crDeliverableChangeCount = (crd: ChangeRequestDeliverable): number => {
  return (
    Number(crd.oldCost !== crd.newCost) +
    Number(crd.oldHours !== crd.newHours) +
    Number(crd.oldCurveProfileCode !== crd.newCurveProfileCode)
  );
};

export const crPackageChangeCount = (crp: ChangeRequestPackage): number => {
  return (
    Number(isDifferentDay(crp.oldPlanEndDate, crp.newPlanEndDate)) +
    Number(isDifferentDay(crp.oldForecastEndDate, crp.newForecastEndDate)) +
    Number(isDifferentDay(crp.oldPlanStartDate, crp.newPlanStartDate))
  );
};
