import moment from 'moment';
import { rewriteDateFromUTC } from '../common-formatters';

export const isDateInRange = (
  date: Date | moment.Moment,
  rangeFrom: Date | moment.Moment,
  rangeTo: Date | moment.Moment,
): boolean => {
  const dateUTC = rewriteDateFromUTC(date, false);
  const rangeFromUTC = rewriteDateFromUTC(rangeFrom, false);
  const rangeToUTC = rewriteDateFromUTC(rangeTo, false);

  if (!dateUTC || !rangeFromUTC || !rangeToUTC) {
    return undefined;
  }

  return rangeFromUTC <= dateUTC && dateUTC <= rangeToUTC;
};
