import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ProjectTemplate } from 'src/app/project-management/project-import/project-import.models';
import { ProjectImportService } from 'src/app/project-management/project-import/project-import.service';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { ProvideTemplateNameDialogInput } from './provide-template-name-dialog.model';

@Component({
  selector: 'app-provide-template-name-dialog',
  templateUrl: './provide-template-name-dialog.component.html',
  styleUrls: ['./provide-template-name-dialog.component.scss'],
})
export class ProvideTemplateNameDialogComponent implements OnInit, OnDestroy {
  @Output() previousStepClick = new EventEmitter<void>();
  initialValues: ProvideTemplateNameDialogInput;
  form: UntypedFormGroup;
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatLegacyDialogRef<ProvideTemplateNameDialogComponent>,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private projectImportService: ProjectImportService,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_LEGACY_DIALOG_DATA) data: ProvideTemplateNameDialogInput,
  ) {
    this.initialValues = data;
  }

  ngOnInit(): void {
    this.initForm();

    // Get the list of templates then, when they are available add the uniqueTemplateName validator
    this.store$
      .select(getProjectIdSelector)
      .pipe(
        switchMap((projectId) => {
          return this.projectImportService.getProjectTemplates(projectId);
        }),
      )
      .subscribe((templates) => {
        const templateNameControl = this.getControl('templateName');
        templateNameControl.setValidators(
          Validators.compose([templateNameControl.validator, this.uniqueTemplateName(templates)]),
        );
        templateNameControl.updateValueAndValidity();
      });
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      templateName: new UntypedFormControl(this.initialValues.templateName, [Validators.required]),
    });

    this.form.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.changeDetector.markForCheck());
  }

  getControl(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  showControlInvalidState(name: string): boolean {
    const control = this.getControl(name);
    return control.invalid && (control.dirty || control.touched);
  }

  get isSubmitDisabled(): boolean {
    return this.form.invalid;
  }

  uniqueTemplateName(existingTemplates: ProjectTemplate[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const duplicate = existingTemplates.some((x) => x.templateName.toLowerCase() === control.value.toLowerCase());
      return duplicate ? { duplicateName: { value: control.value } } : null;
    };
  }

  onSubmit(): void {
    this.dialogRef.close(this.form.value);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
