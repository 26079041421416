import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  DeliverableStatus,
  StatusCode,
  StatusCodeDetail,
  getStatusDetail,
} from '../../../shared-controls/status/statusCodesMap';
import { HomePageService } from '../home-page.service';

export class FavoritePackage {
  functionalPackageId: number;
  projectId: number;
  packageCode: string;
  title: string;
  statusDetails: StatusCodeDetail;
  status: StatusCode | DeliverableStatus;
  responsible: string;
}

@Component({
  selector: 'app-favorite-packages',
  templateUrl: './favorite-packages.component.html',
  styleUrls: ['./favorite-packages.component.scss'],
})
export class FavoritePackagesComponent implements OnInit, OnDestroy {
  @Input() portfolioId: number;

  favoritePackages: FavoritePackage[];
  firstFiveItems = 5;
  firstFivePackages: FavoritePackage[];
  displayedColumns: string[] = ['packageCode', 'title', 'status', 'responsible'];

  private subscription = new Subscription();

  constructor(
    private homePageService: HomePageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const favoritesSub = this.homePageService.getFavoritePackages(this.portfolioId).subscribe((favorites) => {
      this.favoritePackages = favorites.map((favorite) => ({
        ...favorite,
        statusDetails: getStatusDetail(favorite.status),
      }));

      if (this.favoritePackages.length > this.firstFiveItems) {
        this.firstFivePackages = this.favoritePackages.slice(0, 5);
        return;
      }
      this.firstFivePackages = this.favoritePackages;
    });
    this.subscription.add(favoritesSub);
  }

  showMore(): void {
    this.firstFivePackages = [...this.favoritePackages];
    this.favoritePackages = [];
  }

  navigateTo(element: any): void {
    this.router.navigateByUrl(`/project-management/${element.projectId}/package/${element.functionalPackageId}/detail`);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
