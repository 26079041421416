import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';
import { AppState } from '../root-store/app-state';
import { getProjectIdSelector } from '../root-store/root-store.selector';
import { ProjectService } from '../shared/services/project-service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  navItems = [];
  projectId: number;
  projectDetailsSub = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private projectService: ProjectService,
    private accountInfoService: AccountInfoService,
  ) {}

  ngOnInit(): void {
    this.projectDetailsSub = combineLatest([
      this.store$.select(getProjectIdSelector).pipe(
        filter((x) => !!x),
        distinctUntilChanged(),
      ),
      this.projectService.canCurrentUserManageProject$,
    ]).subscribe(([projectId, hasManageRights]) => {
      this.projectId = projectId;
      this.navItems = [
        {
          title: 'general.home',
          icon: '/assets/icons/home.svg',
          iconClass: 'height-36',
          link: '/',
          exact: true,
        },
        {
          title: 'treeMenu.Hierarchy',
          icon: '/assets/icons/tree-view.svg',
          link: `project-management/${this.projectId}`,
          exact: false,
        },
        {
          title: 'advancedSearch.advancedSearch',
          icon: '/assets/icons/tabular-view.svg',
          link: `advanced-search/${this.projectId}`,
          exact: false,
        },
      ];
      if (hasManageRights || this.accountInfoService.hasRole(UmsRoles.PowerUser)) {
        this.navItems = [
          ...this.navItems,
          {
            title: 'projectDetails.projectSettings',
            icon: '/assets/icons/settings-view.svg',
            link: `/project-setup/${this.projectId}`,
            exact: false,
          },
        ];
      }
    });
  }

  ngOnDestroy(): void {
    this.projectDetailsSub?.unsubscribe();
  }
}
