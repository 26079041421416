import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EntityTypes, NodeTypeMap } from 'src/app/common-models/node-types/node-types';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import { HistoryData } from '../../history/history-data.model';
import { HistoryDataService } from '../../history/history-data.service';

@Component({
  selector: 'app-function-history',
  templateUrl: './function-history.component.html',
  styleUrls: ['./function-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionHistoryComponent implements OnInit {
  historyData$: Observable<HistoryData[]>;
  entityType = EntityTypes.function;
  loading$ = new BehaviorSubject<boolean>(false);
  constructor(
    private historyService: HistoryDataService,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.historyData$ = this.store$.select(TreeSelectors.getCurrentTreeNode).pipe(
      filter((treeNode) => !!treeNode.functionalHierarchyId),
      tap((treeNode) => {
        this.loading$.next(true);
        this.entityType = NodeTypeMap.get(treeNode.typeCode);
      }),
      switchMap((treeNode) => this.historyService.getFunctionHistory(treeNode.functionalHierarchyId)),
      tap(() => this.loading$.next(false)),
    );
  }
}
