<form [formGroup]="form" class="orms-cr-submission-form" autocomplete="off" (ngSubmit)="onSubmit()">
  <h2>
    {{ data.title | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <div class="content-container" *ngIf="request && validationErrors.data?.length === 0; else errorList">
    <input
      class="form-control orms-simple-input"
      formControlName="title"
      [placeholder]="data.placeholder | translate"
      maxlength="150"
      [class.is-invalid]="showInvalidState('title')"
    />
    <mat-tab-group #tabGroup class="orms-tabs" animationDuration="0ms" (selectedTabChange)="handleTabChange($event)">
      <mat-tab label="{{ 'changeRequest.justification' | translate }}" class="orms-tab">
        <editor
          *ngIf="activeTabIndex === 0"
          [init]="editorConfig"
          formControlName="description"
          class="orms-editor justification-editor"
          [class.is-invalid]="showInvalidState('description')"
        ></editor>
      </mat-tab>
      <mat-tab *ngFor="let tab of tabs" label="{{ 'changeRequest.' + tab | translate }}" [ngSwitch]="tab">
        <ng-template ngSwitchCase="impact">
          <app-cr-impact [changeRequest]="request"></app-cr-impact>
        </ng-template>
        <ng-template ngSwitchCase="schedule">
          <app-cr-schedule [crPackages]="request.packages"></app-cr-schedule>
        </ng-template>
        <ng-template ngSwitchCase="deliverables">
          <app-request-deliverables
            [requestType]="data.requestType"
            [crDeliverables]="request.deliverables"
          ></app-request-deliverables>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <div class="orms-dialog-footer">
      <div>
        <button class="orms-button orms-button-primary submit-button" type="submit" [disabled]="submissionInProgress">
          {{ 'general.submit' | translate }}
        </button>
        <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
          {{ 'general.cancel' | translate }}
        </button>
      </div>
      <div>{{ 'changeRequest.approver' | translate }}: {{ request.manager }}</div>
    </div>
    <app-spinner *ngIf="submissionInProgress" [absoluteOverlay]="true"></app-spinner>
  </div>
</form>
<ng-template #errorList>
  <div class="table-container">
    <app-error-box [errorMessage]="data.errorHeader | translate"> </app-error-box>
    <cdk-virtual-scroll-viewport class="error-list" tvsItemSize="64">
      <table mat-table [dataSource]="validationErrors">
        <ng-container matColumnDef="title">
          <th id="treestructure_line" mat-header-cell class="table-date" *matHeaderCellDef>
            {{ 'changeRequest.modalTitle' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.title }}
          </td>
        </ng-container>
        <ng-container matColumnDef="message">
          <th id="treestructure_message" mat-header-cell *matHeaderCellDef>
            {{ 'changeRequest.modalMessage' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.message | translate }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="errorColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: errorColumns"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
  <div class="action-buttons">
    <div class="manage-buttons">
      <button class="orms-button orms-button-secondary" type="button" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>
