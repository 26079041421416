import { Pipe, PipeTransform } from '@angular/core';
import { DeliverableStatus, StatusCode } from '../../shared-controls/status/statusCodesMap';

@Pipe({
  name: 'statusBasedValue',
})
export class StatusBasedValuePipe implements PipeTransform {
  transform(value: any, status: StatusCode | DeliverableStatus): any {
    if (
      value === null ||
      status === StatusCode.Cancelled ||
      status === DeliverableStatus.Cancelled ||
      status === DeliverableStatus.Moved ||
      (value === 0 && (status === DeliverableStatus.Draft || status === StatusCode.Draft))
    ) {
      return '-';
    }

    return value;
  }
}
