<ng-container *ngIf="isMatButtonIcon; else simpleButton">
  <button
    *ngIf="display"
    type="button"
    class="orms-tree-action-menu"
    mat-icon-button
    [matMenuTriggerFor]="actionMenu"
    [matMenuTriggerData]="{ element: item }"
  >
    <ng-container *ngTemplateOutlet="tempOutlet">
      <img src="/assets/icons/icon-more.svg" alt="{{ 'imgAlt.menuIcon' | translate }}"
    /></ng-container>
  </button>
</ng-container>
<ng-template #simpleButton>
  <button
    *ngIf="display"
    type="button"
    class="orms-tree-action-menu"
    [ngClass]="{ 'bordered-more-button': borderedIcon }"
    [matMenuTriggerFor]="actionMenu"
    [matMenuTriggerData]="{ element: item }"
  >
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
    <span class="indicator" *ngIf="indicator !== null && indicator !== undefined">{{ indicator }}</span>
  </button></ng-template
>
<ng-template #tempOutlet>
  <ng-content></ng-content>
</ng-template>
<mat-menu #actionMenu="matMenu" class="action-menu orms-menu">
  <ng-template matMenuContent let-element="element">
    <ng-container *ngFor="let action of actions">
      <button
        type="button"
        value="{{ action.label | translate }}"
        (click)="callBackWrapper(element, action, $event)"
        [disabled]="action.isDisabled(element)"
        [ngClass]="{
          'menu-item-border-top': action.itemGroupBorder,
          'with-subtitle': action.subTitle,
          'with-confirmation': action.confirm
        }"
        mat-menu-item
      >
        <span class="item-main-section">
          <span class="icon-container" *ngIf="!action.isSvg">
            <mat-icon [attr.disabled]="action.isDisabled(element)">{{ action.icon(element) }}</mat-icon>
          </span>
          <span class="icon-container" *ngIf="action.isSvg">
            <img class="icon" [src]="action.icon(element)" alt="{{ 'imgAlt.menuIcon' | translate }}" />
          </span>
          {{ action.label | translate }}</span
        >
        <!-- <span class="subtitle" *ngIf="action.subTitle">{{
          action.subTitle | translate
        }}</span> -->
        <ng-content *ngIf="action.subTitle" select="[subtitle]"></ng-content>
      </button>
      <div *ngIf="action.showConfirm" class="confirm">
        <span>{{ action.confirm | translate }}</span>
        <div class="footer">
          <button
            type="button"
            (click)="confirm(element, action)"
            class="orms-button orms-button-primary submit-button"
          >
            {{ action.confirmLabel | translate }}
          </button>
          <button type="button" (click)="cancel(action)" class="orms-button orms-button-secondary">
            {{ 'general.cancel' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>
</mat-menu>
