import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { PermissionGuard } from './common/navigation-tabs/permissions/permission.guard';
import { PermissionsComponent } from './common/navigation-tabs/permissions/permissions.component';
import { DeliverableDetailComponent } from './deliverable/deliverable-detail/deliverable-detail.component';
import { DeliverableHistoryComponent } from './deliverable/deliverable-history/deliverable-history.component';
import { FunctionChangeRequestsComponent } from './function/function-change-requests/function-change-requests.component';
import { FunctionCurveComponent } from './function/function-curve/function-curve.component';
import { FunctionDeliverablesComponent } from './function/function-deliverables/function-deliverables.component';
import { FunctionDetailComponent } from './function/function-detail/function-detail.component';
import { FunctionHistoryComponent } from './function/function-history/function-history.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HomePageGuard } from './home-page/home-page.guard';
import { DeliverableViewDetailComponent } from './package-view/deliverable-view-detail/deliverable-view-detail.component';
import { PackageCurveViewComponent } from './package-view/package-curve/package-curve-view.component';
import { PackageDeliverableViewComponent } from './package-view/package-deliverable/package-deliverable-view.component';
import { PackageDetailViewComponent } from './package-view/package-detail-view/package-detail-view.component';
import { RightSideViewPaneComponent } from './package-view/right-side-view-pane/right-side-view-pane.component';
import { PackageChangeRequestsComponent } from './package/package-change-requests/package-change-requests.component';
import { PackageCurveComponent } from './package/package-curve/package-curve.component';
import { PackageDeliverablesComponent } from './package/package-deliverables/package-deliverables.component';
import { PackageDetailComponent } from './package/package-detail/package-detail.component';
import { PackageHistoryComponent } from './package/package-history/package-history.component';
import { PackageScopeComponent } from './package/package-scope/package-scope.component';
import { ProjectImportComponent } from './project-import/project-import.component';
import { ProjectManagementComponent } from './project-management.component';
import { ProjectChangeRequestsComponent } from './project/project-change-requests/project-change-requests.component';
import { ProjectCurveComponent } from './project/project-curve/project-curve.component';
import { ProjectDeliverablesComponent } from './project/project-deliverables/project-deliverables.component';
import { ProjectDetailComponent } from './project/project-detail/project-detail.component';
import { RightSidePaneComponent } from './right-side-pane/right-side-pane.component';

const routes: Routes = [
  {
    path: 'project-management/:projectId',
    component: ProjectManagementComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: EntityTypes.project + '/:id',
        component: RightSidePaneComponent,
        data: { type: EntityTypes.project },
        children: [
          {
            path: 'detail',
            component: ProjectDetailComponent,
            data: { display: 'tabs.detail', type: EntityTypes.project },
          },
          {
            path: 'curve',
            component: ProjectCurveComponent,
            data: { display: 'tabs.curve', type: EntityTypes.project },
          },
          {
            path: 'deliverables',
            component: ProjectDeliverablesComponent,
            data: { display: 'tabs.deliverables', type: EntityTypes.project },
          },
          {
            path: 'change-requests',
            component: ProjectChangeRequestsComponent,
            data: { display: 'tabs.changeRequest', type: EntityTypes.project },
          },
          {
            canActivate: [PermissionGuard],
            path: 'permissions',
            component: PermissionsComponent,
            data: {
              display: 'tabs.permissions',
              type: EntityTypes.project,
              restrictedToManager: true,
            },
          },
        ],
      },
      {
        path: EntityTypes.function + '/:id',
        component: RightSidePaneComponent,
        data: { type: EntityTypes.function },
        children: [
          {
            path: 'detail',
            component: FunctionDetailComponent,
            data: { display: 'tabs.detail', type: EntityTypes.function },
          },
          {
            path: 'curve',
            component: FunctionCurveComponent,
            data: { display: 'tabs.curve', type: EntityTypes.function },
          },
          {
            path: 'deliverables',
            component: FunctionDeliverablesComponent,
            data: { display: 'tabs.deliverables', type: EntityTypes.function },
          },
          {
            path: 'change-requests',
            component: FunctionChangeRequestsComponent,
            data: { display: 'tabs.changeRequest', type: EntityTypes.function },
          },
          {
            canActivate: [PermissionGuard],
            path: 'permissions',
            component: PermissionsComponent,
            data: {
              display: 'tabs.permissions',
              type: EntityTypes.function,
              restrictedToManager: true,
            },
          },
          {
            path: 'history',
            component: FunctionHistoryComponent,
            data: { display: 'tabs.history', type: EntityTypes.function },
          },
        ],
      },
      {
        path: EntityTypes.package + '/:id',
        component: RightSidePaneComponent,
        data: { type: EntityTypes.package },
        children: [
          {
            path: 'detail',
            component: PackageDetailComponent,
            data: { display: 'tabs.detail', type: EntityTypes.package },
          },
          {
            path: 'curve',
            component: PackageCurveComponent,
            data: { display: 'tabs.curve', type: EntityTypes.package },
          },
          {
            path: 'scope',
            component: PackageScopeComponent,
            data: { display: 'tabs.scope', type: EntityTypes.package },
          },
          {
            path: 'deliverables',
            component: PackageDeliverablesComponent,
            data: { display: 'tabs.deliverables', type: EntityTypes.package },
          },
          {
            path: 'change-requests',
            component: PackageChangeRequestsComponent,
            data: { display: 'tabs.changeRequest', type: EntityTypes.package },
          },
          {
            canActivate: [PermissionGuard],
            path: 'permissions',
            component: PermissionsComponent,
            data: {
              display: 'tabs.permissions',
              type: EntityTypes.package,
              restrictedToManager: true,
            },
          },
          {
            path: 'history',
            component: PackageHistoryComponent,
            data: { display: 'tabs.history', type: EntityTypes.package },
          },
        ],
      },
      {
        path: EntityTypes.deliverable + '/:id',
        component: RightSidePaneComponent,
        data: { type: EntityTypes.deliverable },
        children: [
          {
            path: 'detail',
            component: DeliverableDetailComponent,
            data: { display: 'tabs.detail', type: EntityTypes.deliverable },
          },
          {
            path: 'history',
            component: DeliverableHistoryComponent,
            data: { display: 'tabs.history', type: EntityTypes.deliverable },
          },
        ],
      },
    ],
  },
  {
    path: 'project-management/:projectId/import',
    component: ProjectImportComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'project/:projectId/share/:shareId',
    canActivate: [MsalGuard],
    children: [
      {
        path: EntityTypes.package + '/:id',
        component: RightSideViewPaneComponent,
        data: { type: EntityTypes.package },
        children: [
          {
            path: 'detail',
            component: PackageDetailViewComponent,
            data: { display: 'tabs.detail', type: EntityTypes.package },
          },
          {
            path: 'curve',
            component: PackageCurveViewComponent,
            data: { display: 'tabs.curve', type: EntityTypes.package },
          },
          {
            path: 'deliverables',
            component: PackageDeliverableViewComponent,
            data: { display: 'tabs.deliverables', type: EntityTypes.package },
          },
        ],
      },
      {
        path: EntityTypes.deliverable + '/:id',
        component: RightSideViewPaneComponent,
        data: { type: EntityTypes.deliverable },
        children: [
          {
            path: 'detail',
            component: DeliverableViewDetailComponent,
            data: { display: 'tabs.detail', type: EntityTypes.deliverable },
          },
          {
            path: 'history',
            component: DeliverableHistoryComponent,
            data: { display: 'tabs.history', type: EntityTypes.deliverable },
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [HomePageGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ProjectManagementRoutingModule {}
