import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { DifferenceDialogComponent } from 'src/app/project-management/history/difference-dialog/difference-dialog.component';
import { HistoryAction } from 'src/app/project-management/history/history-enums';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import { NotificationService } from '../services/notification.service';
@Component({
  selector: 'app-ui-showcase',
  templateUrl: './ui-showcase.component.html',
  styleUrls: ['./ui-showcase.component.scss'],
})
export class UiShowcaseComponent {
  sampleDate = new Date();
  selectValue = 'option1';
  datepickerActionsComponent = DatepickerActionsComponent;

  constructor(
    private notificationService: NotificationService,
    private dialog: MatLegacyDialog,
  ) {}

  openErrorToast(): void {
    this.notificationService.error('general.ormsAccessDenied', 'general.error');
  }

  openWarningToast(): void {
    this.notificationService.warning('general.pleaseContactManager', 'general.warning');
  }

  openSuccessToast(): void {
    this.notificationService.success('general.lastVisitedYesterday', 'general.success');
  }

  openDifferenceDialog(): void {
    this.dialog.open(DifferenceDialogComponent, {
      data: {
        historyData: {
          action: HistoryAction.updated,
          occurredAt: new Date(),
          userName: 'Chuck Norris',
          changeNoticeId: null,
          details: [
            {
              itemName: 'fieldA',
              oldValue: 'something old',
              newValue: '2021-08-06T12:44:45.943Z',
            },
            {
              itemName: 'fieldB',
              oldValue: 'something old B',
              newValue: 'In publishing and graphic design, Lorem ipsum is a placeholder text common',
            },
            {
              itemName: 'PercentageCompleted',
              oldValue: null,
              newValue: 13,
            },
          ],
        },
        historySubject: EntityTypes.deliverable,
      },
      panelClass: 'orms-difference-dialog',
    });
  }
}
