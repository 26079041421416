import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { HistoryData } from '../../history/history-data.model';
import { HistoryDataService } from '../../history/history-data.service';

@Component({
  selector: 'app-deliverable-history',
  templateUrl: './deliverable-history.component.html',
  styleUrls: ['./deliverable-history.component.scss'],
})
export class DeliverableHistoryComponent implements OnInit {
  historyData$: Observable<HistoryData[]>;
  entityType = EntityTypes.deliverable;
  loading = true;
  constructor(
    private historyService: HistoryDataService,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.historyData$ = this.store$.select(routerSelectors.getIdSelector).pipe(
      filter((id) => !!id),
      tap(() => (this.loading = true)),
      switchMap((id) => this.historyService.getDeliverableHistory(id)),
      tap(() => (this.loading = false)),
    );
  }
}
