import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { Tag } from 'src/app/common-models/tag.model';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { BreadcrumbOption } from 'src/app/shared-controls/breadcrumbs/breadcrumbs.interfaces';
import { CrWarningConfig } from 'src/app/shared-controls/cr-warning/cr-warning.component';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormConfig,
  shadowFormUpdateConfig,
  watchControlsWithoutValidation,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import {
  DeliverableStatus,
  StatusCode,
  StatusCodeDetail,
  getStatusDetail,
} from 'src/app/shared-controls/status/statusCodesMap';
import { TagListComponent } from 'src/app/shared-controls/tag-list/tag-list.component';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { ShadowInputValidationService } from 'src/app/shared/services/shadow-input-validation.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';
import {
  ChangeRequestDeliverable,
  RequestType,
} from '../../change-request-cart/change-request-cart-state/cart-item.model';
import { selectCurrentContextFunction } from '../../change-request-cart/change-request-cart-state/current-context-function/current-context-function.selectors';
import { addDeliverableChange } from '../../change-request-cart/change-request-cart-state/deliverables/crDeliverable.actions';
import { CrDeliverableSelectors } from '../../change-request-cart/change-request-cart-state/deliverables/crDeliverable.selectors';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { DeliverablesService } from '../../common/navigation-tabs/deliverables/deliverables.service';
import { TagsService } from '../../common/navigation-tabs/deliverables/tags.service';
import { NodeHeaderService } from '../../common/node-header-service';
import { NavigationService } from '../../tree-navigation/navigation.service';
import { TreeActionMoveDeliverableComponent } from '../../tree-navigation/tree-action-move-deliverable/tree-action-move-deliverable.component';
import { TreeActionsService } from '../../tree-navigation/tree-actions-menu/tree-actions.service';
import { TreeSelectors } from '../../tree-navigation/tree-state/tree.selectors';
import { TreeNode } from '../../tree-navigation/tree-structure.model';
import * as deliverableActions from '../deliverable-state/deliverable.actions';
import { getDeliverableById } from '../deliverable-state/deliverable.actions';
import { DeliverableSelectors } from '../deliverable-state/deliverable.selectors';

@Component({
  selector: 'app-deliverable-header',
  templateUrl: './deliverable-header.component.html',
  styleUrls: ['./deliverable-header.component.scss'],
})
export class DeliverableHeaderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() entityId: number;
  @Input() viewOnly = false;
  @Input() breadcrumbOptions: BreadcrumbOption[];

  @ViewChild('divToTrackWidthChanges') divToTrackWidthChanges: ElementRef;
  @ViewChild(TagListComponent) tagList: TagListComponent;
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  addTagButtonWidth = 34;
  expansionPanelLineHeight = 40;
  panelOpenState = false;
  containerWidth: number;
  expansionIndicatorHidden = true;
  headerDetails: DeliverableDetail;
  nodeHeaderService: NodeHeaderService;
  projectId$: Observable<number>;
  statusDetails: StatusCodeDetail;
  canFitChildren = true;
  containerObserver: ResizeObserver;
  tagListObserver: ResizeObserver;
  projectId: number;
  currentContextFunctionId: number;
  canManage: boolean;
  underChangeControl: boolean;
  nodeStatus: StatusCode | DeliverableStatus;
  firstLoadWithData = false;
  mr: any;
  curveProfiles$: Observable<CurveProfile[]>;
  actions = [];

  mrConfig: CrWarningConfig = [
    {
      currentKey: 'packageCode',
      requestedKey: 'newPackageCode',
      type: 'number',
      label: 'general.parentCode',
    },
  ];

  form: UntypedFormGroup;
  private subscription = new Subscription();
  private controlsValueSubscription = new Subscription();
  private serverValidatorsSubscription = new Subscription();

  constructor(
    private zone: NgZone,
    private store$: Store<AppState>,
    private shadowInputValidationService: ShadowInputValidationService,
    private tagsService: TagsService,
    private curveProfilesService: CurveProfileService,
    private treeActionsService: TreeActionsService,
    private deliverableService: DeliverablesService,
    private navigationService: NavigationService,
    private dialog: MatLegacyDialog,
    private accountInfoService: AccountInfoService,
    private router: Router,
  ) {
    this.initForm();
    this.curveProfiles$ = this.curveProfilesService.curveProfiles$;
  }

  ngOnInit(): void {
    this.projectId$ = this.store$.select(getProjectIdSelector).pipe(
      map((projectId) => {
        this.projectId = projectId;
        return projectId;
      }),
    );

    const deliverableDetailsSubscription = this.store$
      .select(DeliverableSelectors.getDeliverableDetailsById({ id: this.entityId }))
      .subscribe((headerDetails) => {
        this.headerDetails = headerDetails;
        if (headerDetails?.packageFunctionalHierarchyId) {
          const permissionSub = this.store$
            .select(
              TreeSelectors.getTreeNodeByFunctionalHierarchyId({
                functionalHierarchyId: headerDetails?.packageFunctionalHierarchyId,
              }),
            )
            .subscribe((treeNode: TreeNode) => {
              this.canManage = treeNode.managePermission;
              this.underChangeControl = treeNode.underChangeControl;
              this.nodeStatus = treeNode.status;
              this.setActions();
            });
          this.subscription.add(permissionSub);
        }
        this.statusDetails = getStatusDetail(this.headerDetails?.status);
        if (!this.firstLoadWithData && this.headerDetails?.deliverableId) {
          this.firstLoadWithData = true;
          this.setControlWatchers();
          this.fillForm();
          disableFormFieldsBaseOnPermissions(this.form, this.isEditable.bind(this));
        }
      });

    const deliverableMrDetailsSubscription = this.store$
      .select(
        CrDeliverableSelectors.getMrDeliverable({
          deliverableId: this.entityId,
        }),
      )
      .subscribe((mr) => {
        this.mr = mr;
      });

    const currentContextFunctionSubscription = this.store$
      .select(selectCurrentContextFunction)
      .subscribe((functionId) => (this.currentContextFunctionId = functionId));

    this.subscription.add(deliverableDetailsSubscription);
    this.subscription.add(deliverableMrDetailsSubscription);
    this.subscription.add(currentContextFunctionSubscription);
  }

  remoteSearch(projectId: number): (term) => Observable<{ recentTags: Tag[]; tags: Tag[] }> {
    return (searchTerm) => this.tagsService.getTags(projectId, searchTerm);
  }

  ngAfterViewInit(): void {
    setTimeout((_) => {
      let containerWidth = 0;
      let tagListWidth = 0;
      let tagListHeight = 0;
      if (!this.divToTrackWidthChanges || !this.tagList) {
        return;
      }

      this.containerObserver = new ResizeObserver((entries) => {
        this.zone.run(() => {
          containerWidth = entries[0].contentRect.width;
          this.closeExpansionPanelIfCanFitContent(containerWidth, tagListWidth, tagListHeight);
        });
      });

      this.tagListObserver = new ResizeObserver((entries) => {
        this.zone.run(() => {
          tagListWidth = this.canManage
            ? entries[0].contentRect.width + this.addTagButtonWidth
            : entries[0].contentRect.width;
          tagListHeight = entries[0].contentRect.height;
          this.closeExpansionPanelIfCanFitContent(containerWidth, tagListWidth, tagListHeight);
        });
      });
      this.containerObserver.observe(this.divToTrackWidthChanges?.nativeElement);
      this.tagListObserver.observe(this.tagList.chipList?.nativeElement);
    });
  }

  addTag(tag: Tag): void {
    this.store$.dispatch(
      deliverableActions.addDeliverableTag({
        tag: {
          tagName: tag.name,
          tagId: tag.id,
          deliverableId: this.headerDetails.deliverableId,
          projectId: this.projectId,
        },
      }),
    );
  }

  removeTag(tag: Tag): void {
    this.store$.dispatch(
      deliverableActions.removeDeliverableTag({
        deliverableId: this.headerDetails.deliverableId,
        tagId: tag.id,
        projectId: this.projectId,
      }),
    );
  }

  private closeExpansionPanelIfCanFitContent(
    containerWidth: number,
    tagListWidth: number,
    tagListHeight: number,
  ): void {
    this.canFitChildren = containerWidth > tagListWidth && tagListHeight < this.expansionPanelLineHeight;
    if (this.canFitChildren && this.expansionPanel) {
      this.expansionPanel.close();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('entityId' in changes) {
      this.setServerValidation();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.containerObserver?.unobserve(this.divToTrackWidthChanges.nativeElement);
    this.tagListObserver?.unobserve(this.tagList.chipList.nativeElement);
    this.serverValidatorsSubscription?.unsubscribe();
    this.controlsValueSubscription?.unsubscribe();
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  private isEditable(name: string): boolean {
    if (this.viewOnly) {
      return false;
    }

    if (name === 'responsible') {
      return (
        this.accountInfoService.hasRole(UmsRoles.PowerUser) ||
        this.headerDetails?.editableFields?.includes('responsibleid')
      );
    }
    return this.headerDetails?.editableFields?.includes(name.toLocaleLowerCase());
  }

  updateProperty(key: string, value: any): void {
    const details = {
      oldTitle: null,
      newTitle: null,
      oldResponsibleId: null,
      newResponsible: null,
      oldDocumentNumber: null,
      newDocumentNumber: null,
    };
    details.oldDocumentNumber = this.headerDetails.documentNumber;
    details.oldTitle = this.headerDetails.title;
    details.oldResponsibleId = this.headerDetails.responsibleId;

    details.newDocumentNumber = this.form.get('documentNumber').value;
    details.newTitle = this.form.get('title').value;
    details.newResponsible = this.form.get('responsible').value;

    const update: Update<DeliverableDetail> = {
      id: this.entityId,
      changes: {},
    };

    if (details.newDocumentNumber !== details.oldDocumentNumber) {
      update.changes.documentNumber = details.newDocumentNumber;
    }
    if (details.newTitle !== details.oldTitle) {
      update.changes.title = details.newTitle;
    }
    if (details?.newResponsible.userId !== details.oldResponsibleId) {
      update.changes.responsibleId = details.newResponsible.userId;
    }

    if (!Object.keys(update.changes).length) {
      return;
    }

    this.store$.dispatch(
      deliverableActions.updateDeliverableProperty({
        key,
        update: this.treeActionsService.mergeDeliverableIdUpdates(update),
        parentId: this.entityId,
      }),
    );
  }

  private setServerValidation(): void {
    this.serverValidatorsSubscription.unsubscribe();

    this.serverValidatorsSubscription = this.shadowInputValidationService
      .watchServerValidation(this.form, EntityTypes.deliverable, this.entityId)
      .subscribe(({ name, error }) => {
        this.form.get(name).setErrors(
          error
            ? {
                serverError: error,
              }
            : null,
        );
      });
  }

  private setControlWatchers(): void {
    this.controlsValueSubscription.unsubscribe();
    this.controlsValueSubscription = watchControlsWithoutValidation(this.form).subscribe(({ name, value }) =>
      this.updateProperty(name, value),
    );
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        title: new UntypedFormControl(),
        documentNumber: new UntypedFormControl(),
        responsible: new UntypedFormControl(),
      },
      { ...shadowFormConfig },
    );
  }

  private fillForm(): void {
    if (!this.headerDetails) {
      this.form.reset(
        {
          title: null,
          documentNumber: null,
          responsible: null,
        },
        { ...shadowFormUpdateConfig },
      );
      return;
    }

    const { title, documentNumber, responsible, responsibleId } = this.headerDetails;
    this.form.patchValue(
      {
        title,
        documentNumber,
        responsible: {
          userId: responsibleId,
          displayName: responsible,
        },
      },
      { ...shadowFormUpdateConfig },
    );
  }

  private setActions(): void {
    this.actions = [
      {
        icon: (deliverable: DeliverableDetail) =>
          deliverable.canMove &&
          deliverable.status !== DeliverableStatus.Cancelled &&
          deliverable.status !== DeliverableStatus.Moved
            ? '/assets/icons/move.svg'
            : '/assets/icons/move-grey.svg',
        label: 'deliverable.move',
        callBack: (deliverable: DeliverableDetail) => this.moveDeliverable(deliverable),
        isDisabled: (deliverable: DeliverableDetail) =>
          !deliverable.canMove ||
          deliverable.status === DeliverableStatus.Cancelled ||
          deliverable.status === DeliverableStatus.Moved,
        isSvg: true,
      },
      {
        icon: (deliverable: DeliverableDetail) =>
          deliverable.canDelete &&
          this.underChangeControl &&
          deliverable.status !== DeliverableStatus.Cancelled &&
          deliverable.status !== DeliverableStatus.Moved
            ? '/assets/icons/icon-grey-cancel.secondary-color.svg'
            : '/assets/icons/icon-grey-cancel.svg',
        label: 'general.cancel',
        callBack: (deliverable: DeliverableDetail) => this.cancelDeliverable(deliverable),
        isDisabled: (deliverable: DeliverableDetail) =>
          !(deliverable.canDelete && this.underChangeControl) ||
          deliverable.status === DeliverableStatus.Cancelled ||
          deliverable.status === DeliverableStatus.Moved,
        isSvg: true,
      },
      {
        icon: () => '/assets/icons/print.svg',
        label: 'general.print',
        callBack: (deliverable: DeliverableDetail) =>
          window.open(`${window.origin}/#/print/deliverable/${deliverable.deliverableId}`, '_blank'),
        isDisabled: () => false,
        isSvg: true,
        itemGroupBorder: true,
      },
      {
        icon: (deliverable: DeliverableDetail) =>
          deliverable.canDelete &&
          !this.underChangeControl &&
          (this.nodeStatus === StatusCode.Published || this.nodeStatus === StatusCode.Draft)
            ? '/assets/icons/icon-delete.svg'
            : '/assets/icons/icon-delete-grey.svg',
        label: 'general.delete',
        callBack: (deliverable: DeliverableDetail) => this.deleteDeliverable(deliverable),
        isDisabled: (deliverable: DeliverableDetail) =>
          !(
            deliverable.canDelete &&
            !this.underChangeControl &&
            (this.nodeStatus === StatusCode.Published || this.nodeStatus === StatusCode.Draft)
          ),
        isSvg: true,
        itemGroupBorder: true,
      },
    ];
  }

  private moveDeliverable(deliverable: DeliverableDetail): void {
    this.dialog
      .open(TreeActionMoveDeliverableComponent, {
        data: {
          deliverableDetail: deliverable,
          underChangeControl: this.underChangeControl,
        },
        panelClass: 'orms-move-deliverable-dialogue',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.deliverableChanged) this.refreshDeliverable();
      });
  }

  private cancelDeliverable(deliverable: DeliverableDetail): void {
    const crd = new ChangeRequestDeliverable();
    crd.deliverableId = deliverable.deliverableId;
    crd.functionId = this.currentContextFunctionId;
    crd.packageFunctionalHierarchyId = deliverable.packageFunctionalHierarchyId;
    crd.documentNumber = deliverable.documentNumber;
    crd.packageCode = deliverable.packageCode;
    crd.request = RequestType.Cancel;
    crd.oldCost = crd.newCost = deliverable.cost;
    crd.oldHours = crd.newHours = deliverable.hours;
    crd.oldCurveProfileCode = crd.newCurveProfileCode = deliverable.curveProfileCode;
    crd.progressWorkflowId = deliverable.progressWorkflowId;
    crd.title = deliverable.title;

    this.store$.dispatch(
      addDeliverableChange({
        crDeliverable: crd,
      }),
    );
  }

  private deleteDeliverable(deliverable: DeliverableDetail): void {
    this.deliverableService
      .deleteDeliverable(deliverable.deliverableId)
      .subscribe(() => this.navigationService.navigate(deliverable.packageFunctionalHierarchyId));
  }

  private refreshDeliverable(): void {
    this.store$.dispatch(
      getDeliverableById({
        deliverableId: this.headerDetails.deliverableId,
        projectId: this.projectId,
        forceRefresh: true,
      }),
    );
  }
}
