<table
  class="relationships-table orms-table"
  mat-table
  [dataSource]="dataSource"
  attr.aria-label="{{ 'general.relationships' | translate }}"
>
  <ng-container matColumnDef="type">
    <th id="relationships_type" mat-header-cell *matHeaderCellDef>
      {{ 'relationships.type' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="relationship-icon">
      <app-relationship-type-icon [type]="element.relationType"></app-relationship-type-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="packageCode">
    <th id="relationships_packageCode" mat-header-cell *matHeaderCellDef>
      {{ 'general.code' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <a
        *ngIf="element.canAccessPackage"
        [routerLink]="['/project-management', projectId, 'package', element.packageHierarchyId, 'detail']"
        class="orms-link-button"
        (click)="navigateToPackage(element.packageHierarchyId)"
      >
        {{ element.code }}
      </a>
      <button
        *ngIf="!element.canAccessPackage"
        type="button"
        class="orms-link-button"
        matTooltip="{{ 'relationships.userHasNoPermissionToViewThisPackage' | translate }}"
        matTooltipPosition="above"
        matTooltipClass="orms-tooltip"
      >
        {{ element.code }}
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="packageTitle">
    <th id="relationships_packageTitle" mat-header-cell *matHeaderCellDef>
      {{ 'general.title' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <a
        *ngIf="element.canAccessPackage"
        [routerLink]="['/project-management', projectId, 'package', element.packageHierarchyId, 'detail']"
        class="orms-link-button"
        (click)="navigateToPackage(element.packageHierarchyId)"
      >
        {{ element.title }}
      </a>
      <button
        *ngIf="!element.canAccessPackage"
        type="button"
        class="orms-link-button"
        matTooltip="{{ 'relationships.userHasNoPermissionToViewThisPackage' | translate }}"
        matTooltipPosition="above"
        matTooltipClass="orms-tooltip"
      >
        {{ element.title }}
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="packageStatus">
    <th id="relationships_packageStatus" mat-header-cell *matHeaderCellDef>
      {{ 'general.status' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <app-status [status]="element.status" [health]="element.health"></app-status>
    </td>
  </ng-container>
  <ng-container matColumnDef="responsible">
    <th id="relationships_responsible" mat-header-cell *matHeaderCellDef>
      {{ 'general.responsible' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.responsible }}</td>
  </ng-container>
  <ng-container matColumnDef="complete">
    <th id="relationships_complete" mat-header-cell *matHeaderCellDef>
      {{ 'relationships.complete' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.percentageComplete | statusPercent: element.status : '1.0-2' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="startDate">
    <th id="relationships_startDate" mat-header-cell *matHeaderCellDef>
      {{ 'general.startDate' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.startDate | date: 'dd-MMM-yy' : 'UTC' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="endDate">
    <th id="relationships_endDate" mat-header-cell *matHeaderCellDef>
      {{ 'general.endDate' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.endDate | date: 'dd-MMM-yy' : 'UTC' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="currentStart">
    <th id="relationships_currentStart" mat-header-cell *matHeaderCellDef>
      {{ 'changeRequest.currentStart' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.startDate | date: 'dd-MMM-yy' : 'UTC' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="currentEnd">
    <th id="relationships_currentEnd" mat-header-cell *matHeaderCellDef>
      {{ 'changeRequest.currentEnd' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.endDate | date: 'dd-MMM-yy' : 'UTC' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="overlap">
    <th id="relationships_overlap" mat-header-cell *matHeaderCellDef>
      {{ 'relationships.overlap' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.overlap }}
    </td>
  </ng-container>
  <ng-container matColumnDef="currentOverlap">
    <th id="relationships_currentOverlap" mat-header-cell *matHeaderCellDef>
      {{ 'changeRequest.currentOverlap' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.currentOverlap }}
    </td>
  </ng-container>
  <ng-container matColumnDef="predictedOverlap">
    <th id="relationships_predictedOverlap" mat-header-cell *matHeaderCellDef>
      {{ 'changeRequest.predictedOverlap' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.predictedOverlap }}
    </td>
  </ng-container>
  <ng-container matColumnDef="warning">
    <th id="relationships_warning" mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <img
        *ngIf="element.isPendingScheduleCR"
        class="warning-icon"
        src="/assets/icons/icon-warning.svg"
        [matTooltip]="'relationships.pendingScheduleChangeRequest' | translate"
        matTooltipPosition="left"
        matTooltipClass="orms-tooltip orms-cr-tooltip orms-tooltip-left"
        alt="{{ 'imgAlt.warning' | translate }}"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th id="relationships_actions" mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <app-action-menu [item]="element" [actions]="actions">
        <mat-icon>more_vert</mat-icon>
      </app-action-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
