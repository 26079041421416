import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DeliverableStatus, StatusCode } from '../../shared-controls/status/statusCodesMap';
import { StatusBasedValuePipe } from './status-based-value.pipe';

@Pipe({
  name: 'statusPercent',
})
export class StatusPercentPipe implements PipeTransform {
  constructor(
    private percentPipe: PercentPipe,
    private statusBasedPipe: StatusBasedValuePipe,
  ) {}
  transform(value: any, status: StatusCode | DeliverableStatus, format?: string): any {
    value = this.statusBasedPipe.transform(value, status);

    if (isNaN(value)) {
      return value;
    }

    return this.percentPipe.transform(value / 100, format);
  }
}
