import { Component, Input } from '@angular/core';
import { StatusCodeDetail } from 'src/app/shared-controls/status/statusCodesMap';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
})
export class StatusChipComponent {
  @Input() statusDetails: StatusCodeDetail;
}
