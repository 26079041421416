import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';
import {
  getIdSelector,
  getProjectIdSelector,
  getRouteType,
  getShareIdSelector,
} from 'src/app/root-store/root-store.selector';
import { BreadcrumbOption } from 'src/app/shared-controls/breadcrumbs/breadcrumbs.interfaces';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';
import { getViewDeliverableById } from '../../deliverable/deliverable-state/deliverable.actions';
import { SharedPackageService } from '../../node-header/shared.service';
import { TreeSelectors } from '../../tree-navigation/tree-state/tree.selectors';

@Component({
  selector: 'app-right-side-view-pane',
  templateUrl: './right-side-view-pane.component.html',
  styleUrls: ['./right-side-view-pane.component.scss'],
})
export class RightSideViewPaneComponent implements OnInit, OnDestroy {
  nodeId: number;
  nodeType: EntityTypes;
  canManage = false;
  links: any[] = [];
  breadcrumbOptions: BreadcrumbOption[] = [];
  packageUrl: string;
  entityTypes = EntityTypes;
  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<AppState>,
    private shareService: SharedPackageService,
    private accountInfoService: AccountInfoService,
  ) {}

  ngOnInit(): void {
    this.routerSubscription = combineLatest([
      this.store$.select(getIdSelector),
      this.store$.select(getProjectIdSelector),
      this.store$.select(getShareIdSelector),
      this.store$.select(TreeSelectors.hasManagePermissionOnCurrentNode),
    ])
      .pipe(withLatestFrom(this.store$.select(getRouteType)))
      .subscribe(([[id, projectId, share, canManage], type]) => {
        this.nodeId = id;
        this.nodeType = type;
        this.shareService
          .getFhPath(share.id)
          .pipe(
            take(1),
            map((fhPath) =>
              fhPath.map((fh) => ({
                title: fh.title,
                icon: fh.typeCode,
                isDisabled: true,
              })),
            ),
          )
          .subscribe((breadcrumbs) => {
            this.breadcrumbOptions = breadcrumbs;
          });

        this.canManage = canManage;
        const packageUrlTree = this.router
          .createUrlTree(['project', projectId, 'share', share.id, 'package', this.nodeId, 'deliverables'])
          .toString();
        this.packageUrl = window.location.href.replace(this.router.url, '') + packageUrlTree;

        if (this.nodeType === EntityTypes.deliverable) {
          this.store$.dispatch(
            getViewDeliverableById({
              deliverableId: this.nodeId,
              linkIdentifier: share.id,
            }),
          );
        }
        this.createTabLinks();
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  private createTabLinks(): void {
    this.links = this.route.snapshot.routeConfig.children
      .filter((child) => {
        if ('restrictedToManager' in child.data) {
          return this.canManage || this.accountInfoService.hasRole(UmsRoles.PowerUser);
        } else {
          return true;
        }
      })
      .map((child) => ({
        path: child.path,
        display: child.data.display,
      }));
  }
}
