export class Project {
  title: string;
  code: string;
  curveProfileId: number;
  hierarchyDepth: number;
  projectManagerId: number;
}

export class ProjectDetails {
  projectId: number;
  code: string;
  portfolioId: number;
  title: string;
  managerId: number;
  manager: string;
  curveProfileCode: string;
  isCurrentUserProjectManager: boolean;
  isCurrentUserL1Manager: boolean;
}
