import { TranslateService } from '@ngx-translate/core';

export const getPriorities = (translationService: TranslateService) =>
  new Map<number, string>([
    [0, translationService.instant('general.unassigned')],
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5'],
  ]);
