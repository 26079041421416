import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PermissionType } from './permission-type';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  clearUsersFromSearch = new Subject();

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  getPermissions(functionalHierarchyId: number): Observable<any> {
    const { baseUrl, getPermissionsByHierarchyId } = this.appConfigService.settings.api.endpoints;
    const params = new HttpParams().set('functionalHierarchyId', functionalHierarchyId.toString());
    return this.httpClient.get<any>(baseUrl + getPermissionsByHierarchyId, {
      params,
    });
  }

  grantPermission(userIds: number[], permissionType: PermissionType, nodeId: number): Observable<any> {
    const { baseUrl, grantPermission } = this.appConfigService.settings.api.endpoints;
    return forkJoin(
      userIds.map((userId) =>
        this.httpClient
          .post(baseUrl + grantPermission, {
            userId,
            functionalHierarchyId: nodeId,
            permissionType,
          })
          .pipe(
            tap(() => this.clearUsersFromSearch.next(userId)),
            catchError((err: HttpErrorResponse) => {
              showHttpErrorResponse(this.notificationService, err);
              return of(null);
            }),
          ),
      ),
    );
  }

  revokePermission(userId: number, nodeId: number): Observable<any> {
    const { baseUrl, revokePermission } = this.appConfigService.settings.api.endpoints;
    return this.httpClient
      .post(baseUrl + revokePermission, {
        userId,
        functionalHierarchyId: nodeId,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          showHttpErrorResponse(this.notificationService, err);
          return of(null);
        }),
      );
  }
}
