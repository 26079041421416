import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Injectable()
export class HomePageGuard implements CanActivate {
  constructor(
    private authService: MsalService,
    public router: Router,
    private msalBroadcastService: MsalBroadcastService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      map(() => {
        if (!this.authService.instance.getActiveAccount()) {
          this.router.navigate(['/login']);
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
