<mat-select
  [(value)]="value"
  (valueChange)="onValueChange()"
  placeholder="{{ label$ | async | translate }}"
  class="orms-select boolean-select"
  [panelClass]="panelClass + ' boolean-select-panel orms-select-options'"
>
  <mat-select-trigger>{{ label$ | async | translate }}</mat-select-trigger>
  <mat-option [value]="true">
    {{ 'general.yes' | translate }}
  </mat-option>
  <mat-option [value]="false">
    {{ 'general.no' | translate }}
  </mat-option>
  <mat-divider></mat-divider>
  <mat-option [value]="null">
    {{ 'general.clear' | translate }}
  </mat-option>
</mat-select>
