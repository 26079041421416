import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Tag } from 'src/app/common-models/tag.model';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AddTag } from './DeliverableDetail';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  getTags(projectId: number, searchTerm: string): Observable<{ recentTags: Tag[]; tags: Tag[] }> {
    const params = new HttpParams().set('projectId', projectId.toString()).set('filter', searchTerm.toString());
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const projectUrl = this.appConfigService.settings.api.endpoints.projectTags;
    return this.httpClient.get<{ recentTags: Tag[]; tags: Tag[] }>(baseUrl + projectUrl, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        showHttpErrorResponse(this.notificationService, err, 'deliverable.gettingDeliverableTagsFailed');
        return of(null);
      }),
    );
  }

  getTagsByDeliverableById(deliverableId: number, projectId: number): Observable<Tag[]> {
    const params = new HttpParams()
      .set('deliverableId', deliverableId.toString())
      .set('projectId', projectId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const deliverableTagsUrl = this.appConfigService.settings.api.endpoints.deliverableTags;
    return this.httpClient.get<Tag[]>(baseUrl + deliverableTagsUrl, { params });
  }

  addTagForDeliverable(tag: AddTag): Observable<number> {
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const addTagForDeliverableUrl = this.appConfigService.settings.api.endpoints.addDeliverableTag;
    return this.httpClient.post<number>(baseUrl + addTagForDeliverableUrl, tag);
  }

  removeTagForDeliverable(deliverableId: number, tagId: number, projectId: number): Observable<void> {
    const params = new HttpParams()
      .set('deliverableId', deliverableId.toString())
      .set('tagId', tagId.toString())
      .set('projectId', projectId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const removeTagForDeliverableUrl = this.appConfigService.settings.api.endpoints.deleteDeliverableTag;
    return this.httpClient.delete<void>(baseUrl + removeTagForDeliverableUrl, {
      params,
    });
  }
}
