import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  // app.config.json by default in source contains settings for local development
  // This application will ALWAYS load contents from this file
  //
  // During release pipelines, file transforms are performed to copy the contents of the
  // respective app.config.XXX.json files (dev, qa, prod, etc) into this one
  //
  // Anywhere in the application, where app settings are required, bind the instance of
  // this service in its constructor
  //
  // e.g. constructor(private appConfigService: AppConfigService) { }
  // ...
  // const clientId = this.appConfigService.settings.clientId

  configUrl = `./assets/config/app.config.json`;

  private configSettings: any = null;
  private http: HttpClient;

  constructor(httpBackend: HttpBackend) {
    // HttpBackend is used to purposely ensure no Http Interceptors can interrupt
    // HttpClient have all its requests route through the Http Interceptor list.
    // Needed as initial processes such as MSAL will not be able to load as MSAL will attempt
    // to inject its own interceptor before its initialization is complete

    this.http = new HttpClient(httpBackend);
  }

  get settings(): any {
    return this.configSettings;
  }

  public load(): Promise<any> {
    const seed = new Date().getTime();
    return new Promise((resolve, reject) => {
      this.http.get(`${this.configUrl}?${seed}`).subscribe((response: any) => {
        this.configSettings = response;
        this.initAppMonitoring();
        resolve(true);
      });
    });
  }

  private initAppMonitoring(): void {
    const appVersion = this.configSettings.version.number + this.configSettings.version.build;

    datadogRum.init({
      ...this.configSettings.datadog,
      service: this.configSettings.app.name,
      env: this.configSettings.env,
      version: appVersion,
    });
    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
      clientToken: this.configSettings.datadog.clientToken,
      site: this.configSettings.datadog.site,
      sampleRate: this.configSettings.datadog.sampleRate,
      service: this.configSettings.app.name,
      env: this.configSettings.env,
      version: appVersion,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['info', 'warn', 'error'],
    });
  }
}
