import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { EditorOptions } from 'tinymce';

@Component({
  selector: 'app-change-request-dialog',
  templateUrl: './change-request-dialog.component.html',
  styleUrls: ['./change-request-dialog.component.scss'],
})
export class ChangeRequestDialogComponent implements OnInit, OnDestroy {
  @Output() previousStepClick = new EventEmitter<void>();
  justification: string = 'String';
  public init: any;
  changeRequestForm: UntypedFormGroup;
  editorConfig: EditorOptions;
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatLegacyDialogRef<ChangeRequestDialogComponent>,
    private appConfigService: AppConfigService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initChangeRequestForm();
  }

  private initChangeRequestForm(): void {
    this.changeRequestForm = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required),
      autoApprove: new UntypedFormControl(false),
    });
    this.editorConfig = {
      ...this.appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...this.appConfigService.settings.richTextConfig.crJustificationOptions,
      placeholder: this.translateService.instant('projectImport.enterJustification'),
    };

    this.changeRequestForm.statusChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.changeDetector.markForCheck());
  }

  getChangeRequestControl(name: string): UntypedFormControl {
    return this.changeRequestForm.get(name) as UntypedFormControl;
  }

  showChangeRequestControlInvalidState(name: string): boolean {
    const control = this.getChangeRequestControl(name);
    return control.invalid && (control.dirty || control.touched);
  }

  get isSubmitDisabled(): boolean {
    return this.changeRequestForm.invalid;
  }

  submitChangeRequest(): void {
    this.dialogRef.close(this.changeRequestForm.value);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
