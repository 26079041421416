import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { HierarchyRule } from 'src/app/common-models/HierarchyRule.model';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { Detail } from '../../common/navigation-tabs/detail/detail.model';
import { NodeMoveData, TreeNode } from '../tree-structure.model';

export enum TreeActionTypes {
  NOOP = '[Navigation Tree] NOOP',
  CLEAR_TREE_DATA = '[Navigation Tree] Clear Project tree data',
  GET_PROJECT_TREE_DATA = '[Navigation Tree] Get Project tree data',
  UPDATE_PROJECT_PARTIAL_TREE_DATA = '[Navigation Tree] Update Project partial tree data',
  GET_PROJECT_TREE_DATA_SUCCESS = '[Navigation Tree] Get Project tree data success',
  GET_PROJECT_TREE_DATA_FAILURE = '[Navigation Tree] Get Project tree data failure',
  SELECT_NODE = '[Navigation Tree] Select tree node',
  UPDATE_TITLE = '[Navigation Tree] Update title',
  UPDATE_PROPERTY = '[Navigation Tree] Update property',
  UPDATE_PROPERTY_SUCCESS = '[Navigation Tree] Update title success',
  UPDATE_PROPERTY_ERROR = '[Navigation Tree] Update title error',
  UPDATE_DETAIL_PROPERTY = '[Detail] Update property',
  UPDATE_PARTIAL_TREE_DATA = '[Navigation Tree] Update partial tree data',
  PATCH_PROPERTY_SUCCESS = '[Navigation Tree] Patch property success',
  ADD_NODE = '[Navigation Tree] Add node',
  ADD_NODE_SUCCESS = '[Navigation Tree] Add node success',
  DELETE_NODE = '[Navigation Tree] Delete node',
  DELETE_NODE_SUCCESS = '[Navigation Tree] Delete node success',

  GET_PROJECT_HIERARCHY_RULES_DATA = '[Navigation Tree] Get Project Hierarchy Rule data',
  GET_PROJECT_HIERARCHY_RULES_DATA_SUCCESS = '[Navigation Tree] Get Project Hierarchy Rule data success',
  GET_PROJECT_HIERARCHY_RULES_DATA_FAILURE = '[Navigation Tree] Get Project Hierarchy Rule data failure',

  RELOAD_PROJECT_TREE_DATA = '[Navigation Tree] Reload Project tree data',

  MOVE_NODE = '[Navigation Tree] Move node',
  MOVE_SUCCESS = '[Navigation Tree] Move success',
}

export const getProjectData = createAction(TreeActionTypes.GET_PROJECT_TREE_DATA, props<{ projectId: number }>());

export const updateProjectPartialData = createAction(
  TreeActionTypes.UPDATE_PROJECT_PARTIAL_TREE_DATA,
  props<{ projectId: number; functionalHierarchiesIds: number[] }>(),
);

export const reloadProjectData = createAction(TreeActionTypes.RELOAD_PROJECT_TREE_DATA, props<{ projectId: number }>());

export const moveNode = createAction(
  TreeActionTypes.MOVE_NODE,
  props<{
    nodeMoveData: NodeMoveData;
  }>(),
);

export const moveSuccess = createAction(
  TreeActionTypes.MOVE_SUCCESS,
  props<{
    functionalHierarchyId: number;
    functionDestinationFunctionalHierarchyId: number;
  }>(),
);

export const getProjectDataSuccess = createAction(
  TreeActionTypes.GET_PROJECT_TREE_DATA_SUCCESS,
  props<{ projectTree: TreeNode[] }>(),
);
export const getProjectDataFailure = createAction(
  TreeActionTypes.GET_PROJECT_TREE_DATA_FAILURE,
  props<{ error: string }>(),
);
export const updateNodeTitle = createAction(TreeActionTypes.UPDATE_TITLE, props<{ update: Update<TreeNode> }>());
export const updateNodeProperty = createAction(
  TreeActionTypes.UPDATE_PROPERTY,
  props<{
    entityType: EntityTypes;
    key: string;
    update: Update<TreeNode | DeliverableDetail>;
  }>(),
);
export const updateDetailProperty = createAction(
  TreeActionTypes.UPDATE_DETAIL_PROPERTY,
  props<{
    entityType: EntityTypes;
    key: string;
    update: Update<Detail>;
  }>(),
);

export const updateNodePropertySuccess = createAction(
  TreeActionTypes.UPDATE_PROPERTY_SUCCESS,
  props<{ update: Update<TreeNode> }>(),
);

export const updateNodePropertyError = createAction(TreeActionTypes.UPDATE_PROPERTY_ERROR, props<{ error: string }>());

export const clearTreeData = createAction(TreeActionTypes.CLEAR_TREE_DATA);

export const updatePartialTreeData = createAction(
  TreeActionTypes.UPDATE_PARTIAL_TREE_DATA,
  props<{ projectTree: TreeNode[] }>(),
);

export const patchPropertySuccess = createAction(
  TreeActionTypes.PATCH_PROPERTY_SUCCESS,
  props<{
    entityType: EntityTypes;
    entity: any;
  }>(),
);

export const addNode = createAction(TreeActionTypes.ADD_NODE, props<{ parentId: number; title: string }>());

export const addNodeSuccess = createAction(TreeActionTypes.ADD_NODE_SUCCESS, props<{ node: TreeNode }>());

export const deleteNode = createAction(TreeActionTypes.DELETE_NODE, props<{ nodeId: number }>());

export const deleteNodeSuccess = createAction(TreeActionTypes.DELETE_NODE_SUCCESS, props<{ nodeId: number }>());

export const noOP = createAction(TreeActionTypes.NOOP);

export const getProjectHierarchyRulesData = createAction(
  TreeActionTypes.GET_PROJECT_HIERARCHY_RULES_DATA,
  props<{ projectId: number }>(),
);

export const getProjectHierarchyRulesDataSuccess = createAction(
  TreeActionTypes.GET_PROJECT_HIERARCHY_RULES_DATA_SUCCESS,
  props<{ projectHierarchyRules: HierarchyRule[] }>(),
);

export const getProjectHierarchyRulesDataFailure = createAction(
  TreeActionTypes.GET_PROJECT_HIERARCHY_RULES_DATA_FAILURE,
  props<{ error: string }>(),
);
