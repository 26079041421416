<ejs-breadcrumb *ngIf="options && options.length" [enableNavigation]="true" overflowMode="Wrap">
  <e-breadcrumb-items>
    <ng-container *ngFor="let option of options">
      <e-breadcrumb-item
        [disabled]="option.isDisabled"
        [url]="option.url"
        iconCss="{{ option.icon + (option.invalid ? '--invalid' : '') }}"
        text="{{ option.title }}"
      >
      </e-breadcrumb-item>
    </ng-container>
  </e-breadcrumb-items>
  <ng-template #separatorTemplate>
    <span class="arrow"></span>
  </ng-template>
</ejs-breadcrumb>
