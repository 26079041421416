import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstLetter',
  pure: false,
})
export class FirstLetterUppercasePipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    if (typeof value === 'string') {
      if (!value.length) {
        return '';
      }

      let valueLowerCase = value.toLowerCase();
      return valueLowerCase.charAt(0).toUpperCase() + valueLowerCase.slice(1);
    }
    return value;
  }
}
