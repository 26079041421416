<ng-container [formGroup]="form">
  <div class="notes">
    <editor class="orms-editor" formControlName="notes" [init]="init"></editor>
  </div>
  <div class="start-date">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.startDate' | translate }}
      <app-warning-icon
        [showWarning]="
          (form.get('startDate').value
            | isDateInRange: deliverableDetail.plannedStartDate : deliverableDetail.plannedEndDate) === false
        "
        propertyName="general.startDate"
        tooltip="package.outsideOfPackagePlanDates"
      >
      </app-warning-icon>
    </h6>
    <mat-form-field class="orms-datepicker-shadow-input">
      <input matInput [matDatepicker]="startDate" formControlName="startDate" (click)="startDate.open()" />
      <mat-datepicker panelClass="orms-datepicker" [calendarHeaderComponent]="datepickerActionsComponent" #startDate>
      </mat-datepicker>
    </mat-form-field>
  </div>
  <div class="end-date">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.endDate' | translate }}
      <app-warning-icon
        [showWarning]="
          (form.get('endDate').value
            | isDateInRange: deliverableDetail.plannedStartDate : deliverableDetail.plannedEndDate) === false
        "
        propertyName="general.endDate"
        tooltip="package.outsideOfPackagePlanDates"
      >
      </app-warning-icon>
    </h6>
    <mat-form-field class="orms-datepicker-shadow-input">
      <input matInput [matDatepicker]="endDate" formControlName="endDate" (click)="endDate.open()" />
      <mat-datepicker panelClass="orms-datepicker" [calendarHeaderComponent]="datepickerActionsComponent" #endDate>
      </mat-datepicker>
    </mat-form-field>
  </div>
  <div class="progress-workflow">
    <h6 class="orms-label orms-label-margin">
      {{ 'deliverable.progressWorkflow' | translate }}
    </h6>
    <ng-container *ngIf="{ values: projectWorkflows$ | async } as workflows">
      <ng-container *ngFor="let w of workflows.values">
        <span
          class="text-button"
          *ngIf="w.progressWorkflowId === form.get('progressWorkflowId').value && !isEditable('progressWorkflowId')"
          >{{ w.title }}</span
        >
      </ng-container>
      <mat-select
        *ngIf="isEditable('progressWorkflowId')"
        class="orms-select"
        panelClass="orms-select-options"
        formControlName="progressWorkflowId"
      >
        <mat-option *ngFor="let w of workflows.values" [value]="w.progressWorkflowId">
          {{ w.title }}
        </mat-option>
      </mat-select>
    </ng-container>
  </div>
  <div *ngIf="!isCompleteShown" class="workflow-step">
    <h6 class="orms-label orms-label-margin">
      {{ 'deliverable.workflowStep' | translate }}
    </h6>
    <ng-container *ngIf="{ name: workflowStepName$ | async } as workflowStep">
      <span class="text-button" *ngIf="!isEditable('progressworkflowstatusid')">{{ workflowStep.name }}</span>
      <button
        *ngIf="isEditable('progressworkflowstatusid')"
        type="button"
        class="orms-link-button"
        (click)="showProgressUpdate()"
      >
        {{ workflowStep.name }}
      </button>
    </ng-container>
  </div>
  <div *ngIf="isCompleteShown" class="percentage-complete">
    <h6 class="orms-label orms-label-margin">
      {{ 'deliverable.percentageCompleted' | translate }}
    </h6>
    <span class="text-button" *ngIf="!isEditable('percentagecompleted')">-</span>
    <button
      *ngIf="isEditable('percentagecompleted')"
      type="button"
      class="orms-link-button text-button"
      [disabled]="!isEditable('percentagecompleted')"
      (click)="showProgressUpdate()"
    >
      {{ deliverableDetail.percentageCompleted }}%
    </button>
  </div>
  <div class="controlled-document">
    <h6 class="orms-label orms-label-margin">
      {{ 'deliverable.controlledDocument' | translate }}
    </h6>
    <div class="custom-control custom-switch orms-toggle-switch-container">
      <input
        type="checkbox"
        class="custom-control-input orms-toggle-switch"
        id="controlledDocument"
        formControlName="controlledDocument"
      />
      <label class="custom-control-label" for="controlledDocument"></label>
    </div>
  </div>
  <div class="priority" [class.priority-expanded]="isCompleteShown">
    <h6 class="orms-label orms-label-margin">
      {{ 'general.priority' | translate }}
    </h6>
    <mat-select class="orms-select" panelClass="orms-select-options" formControlName="priority">
      <mat-option *ngFor="let p of priorities | keyvalue" [value]="p.key">
        {{ p.value }}
      </mat-option>
    </mat-select>
  </div>
  <div class="on-hold">
    <div>
      <div>
        <h6 class="orms-label">{{ 'deliverable.onHold' | translate }}</h6>
        <div class="custom-control custom-switch orms-toggle-switch-container">
          <input type="checkbox" class="custom-control-input orms-toggle-switch" id="onHold" formControlName="onHold" />
          <label class="custom-control-label" for="onHold"></label>
        </div>
      </div>
      <div class="on-hold-reason" *ngIf="form.get('onHold').value">
        <h6 class="orms-label">{{ 'deliverable.onHoldReason' | translate }}</h6>
        <input
          autocomplete="off"
          type="text"
          class="form-control shadow-input mt-1"
          [placeholder]="'deliverable.enterReason' | translate"
          formControlName="holdReason"
          [class.is-invalid]="showInvalidState('holdReason')"
          maxlength="250"
        />
      </div>
    </div>
  </div>
  <div class="deliverable-url">
    <div>
      <mat-form-field appearance="outline" class="orms-outline-input" floatLabel="always">
        <mat-label>{{ 'deliverable.url' | translate }}</mat-label>
        <input autocomplete="off" formControlName="url" matInput [class.is-invalid]="showInvalidState('url')" />
      </mat-form-field>
      <button
        [disabled]="!isURLButtonEnabled"
        [cdkCopyToClipboard]="this.form.get('url').value"
        type="button"
        class="orms-button orms-button-secondary"
      >
        {{ 'general.copy' | translate }}
      </button>
      <a
        [class.orms-button-a-primary-disabled]="!isURLButtonEnabled"
        class="orms-button orms-button-a-primary"
        [href]="this.form.get('url').value"
        target="_blank"
        matTooltip="{{ 'general.openInNewTab' | translate }}"
        matTooltipPosition="above"
        matTooltipClass="orms-tooltip orms-tooltip-open-window"
        >{{ 'general.open' | translate }}</a
      >
    </div>
  </div>
</ng-container>
