<mat-select
  [(value)]="selectedOptions"
  placeholder="{{ label | translate }}"
  multiple
  class="orms-select custom-multiselect"
  [panelClass]="panelClass + ' custom-multiselect-panel orms-select-options'"
>
  <mat-select-trigger>{{ calculatedLabel }}</mat-select-trigger>
  <mat-option #allSelected (click)="toggleAll()" [value]="SELECT_ALL">
    {{ 'general.selectAll' | translate }}
  </mat-option>
  <mat-divider></mat-divider>
  <mat-option *ngFor="let option of options" [value]="option.value" (click)="toggle()">
    <ng-container *ngIf="optionTemplate; else defaultTemplate">
      <ng-container *ngTemplateOutlet="optionTemplate; context: { data: option.templateContext }"> </ng-container>
    </ng-container>
    <ng-template #defaultTemplate>
      <span *ngIf="option.iconCssClass" [ngClass]="option.iconCssClass"></span>
      {{ option.viewValue | translate }}
    </ng-template>
  </mat-option>
</mat-select>
