import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';

@Component({
  selector: 'app-package-deliverables',
  templateUrl: './package-deliverables.component.html',
  styleUrls: ['./package-deliverables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageDeliverablesComponent implements OnInit {
  nodeId$: Observable<number>;
  displayedColumns: string[] = [
    'documentNumber',
    'title',
    'responsible',
    'startDate',
    'endDate',
    'hours',
    'progressWorkflowStatus',
    'percentageCompleted',
    'controlledDocument',
    'actions',
  ];
  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.nodeId$ = this.store$.select(routerSelectors.getIdSelector);
  }
}
