import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateLoader } from '@ngx-translate/core';

import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslateLanguageLoader implements TranslateLoader {
  private http: HttpClient;
  private headers: HttpHeaders;

  constructor(private readonly httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
    this.headers = new HttpHeaders();
    this.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    this.headers.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
  }

  getTranslation(lang: string): Observable<object> {
    const seed = new Date().getTime();
    const basePath = `assets/i18n/${lang}`;
    return forkJoin([
      this.http.get(`${basePath}/errors.json?${seed}`, {
        headers: this.headers,
      }),
      this.http.get(`${basePath}/general.json?${seed}`, {
        headers: this.headers,
      }),
      this.http.get(`${basePath}/statusCodes.json?${seed}`, {
        headers: this.headers,
      }),
    ]).pipe(map((res: object[]) => Object.assign({}, ...res)));
  }
}
