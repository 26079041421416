import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  ChangeRequestPackage,
  RequestType,
} from 'src/app/project-management/change-request-cart/change-request-cart-state/cart-item.model';
import { FullChangeRequestModel } from 'src/app/project-management/change-request-cart/change-request-submit/full-change-request';
import { calculateOverlapAccordingToType } from 'src/app/project-management/package/package-detail/package-relationships/package-relationship.helper';
import { PackageRelationshipsService } from 'src/app/project-management/package/package-detail/package-relationships/package-relationships.service';
import { dashDayDateFormat } from 'src/app/shared/date-options/short-date-format';
import { ChangeRequestListService } from '../change-request-list.service';
import { CRStatusCode } from '../cr-status';
import { ChangeRequestType } from '../cr-type';

@Component({
  selector: 'app-change-request-details',
  templateUrl: './change-request-details.component.html',
  styleUrls: ['./change-request-details.component.scss'],
})
export class ChangeRequestDetailsComponent implements OnInit {
  changeRequestDetails$: Observable<FullChangeRequestModel>;
  crStatusCode = CRStatusCode;
  canReject: boolean;
  canApprove: boolean;
  changeRequestType: ChangeRequestType;
  requestType = RequestType;
  projectId: number;
  packageFhId: number;
  hasPendingCrWithScheduleChange = false;
  scheduleImpactWarningMessage: string;

  crTypeEnum = ChangeRequestType;
  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private data: {
      id: number;
      canReject: boolean;
      canApprove: boolean;
      changeRequestType: ChangeRequestType;
      projectId: number;
    },
    private dialogRef: MatLegacyDialogRef<ChangeRequestDetailsComponent>,
    private changeRequestListService: ChangeRequestListService,
    private packageRelationshipsService: PackageRelationshipsService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
  ) {
    this.canReject = data.canReject;
    this.canApprove = data.canApprove;
    this.changeRequestType = data.changeRequestType;
    this.projectId = data.projectId;
  }

  ngOnInit(): void {
    const days = this.translateService.instant('general.days');
    this.changeRequestDetails$ = this.changeRequestListService.getChangeRequestDetails(this.data.id).pipe(
      tap((cr) => {
        this.changeRequestType = cr.changeRequestType;
        this.packageFhId = cr?.packages.length && cr?.packages[0].functionalHierarchyId;
      }),
      switchMap((cr) => {
        if (cr.packages?.length) {
          return this.packageRelationshipsService.getRelatedPackages(this.packageFhId, this.projectId).pipe(
            switchMap((relationships) =>
              this.changeRequestListService
                .getChangeRequestsPendingApproval(this.projectId, [
                  ...relationships.map((x) => x.packageHierarchyId),
                  this.packageFhId,
                ])
                .pipe(
                  map((pendingCrs) => {
                    const crPackage = cr.packages[0];
                    this.setScheduleImpactMessage(crPackage);
                    this.hasPendingCrWithScheduleChange = pendingCrs.some(
                      (x) =>
                        relationships.map((rel) => rel.packageHierarchyId).includes(x.functionalHierarchyId) &&
                        (x.newStartDate || x.newEndDate),
                    );
                    return {
                      cr: { ...cr, relationships },
                      pendingCrs,
                      currentPackage: crPackage,
                    };
                  }),
                ),
            ),
          );
        } else {
          return of({ cr, pendingCrs: [], currentPackage: <ChangeRequestPackage>{} });
        }
      }),
      map(({ cr, pendingCrs, currentPackage }) => {
        const currentPackagePendingScheduleCrs = pendingCrs.filter(
          (pendingCr) =>
            (pendingCr.functionalHierarchyId === this.packageFhId && pendingCr.newStartDate) || pendingCr.newEndDate,
        );
        if (this.packageFhId && cr?.relationships?.length && currentPackagePendingScheduleCrs?.length) {
          const currentPackagePendingScheduleCrsSortedByLastCreatedDate = currentPackagePendingScheduleCrs.sort(
            (a, b) => {
              return new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf();
            },
          )[0];
          const relationships = cr.relationships.map((rel) => {
            const currentOverlap = calculateOverlapAccordingToType(
              rel.startDate,
              rel.endDate,
              currentPackage?.oldPlanStartDate,
              currentPackage.oldPlanEndDate,
              rel.relationType,
            );
            const predictedOverlap = calculateOverlapAccordingToType(
              rel.startDate,
              rel.endDate,
              currentPackagePendingScheduleCrsSortedByLastCreatedDate.newStartDate ?? currentPackage.oldPlanStartDate,
              currentPackagePendingScheduleCrsSortedByLastCreatedDate.newEndDate ?? currentPackage.oldPlanEndDate,
              rel.relationType,
            );
            const isPendingScheduleCR = pendingCrs
              .filter((x) => x.functionalHierarchyId === rel.packageHierarchyId)
              .some((x) => x.newStartDate || x.newEndDate);
            return {
              ...rel,
              predictedOverlap: predictedOverlap ? `${predictedOverlap} ${days}` : '-',
              currentOverlap: currentOverlap ? `${currentOverlap} ${days}` : '-',
              isPendingScheduleCR,
            };
          });
          return { ...cr, relationships };
        } else {
          return cr;
        }
      }),
      map((cr) => ({
        ...cr,
        packages: cr.packages.map((p) => ({
          ...p,
          oldPlanStartDate: p.newPlanStartDate ? p.oldPlanStartDate : null,
          oldPlanEndDate: p.newPlanEndDate ? p.oldPlanEndDate : null,
          status: cr.status as any,
        })),
        deliverables: cr.deliverables.map(
          (d) =>
            ({
              ...d,
              oldCost: this.decimalPipe.transform(d.oldCost, '1.0-0'),
              newCost: this.decimalPipe.transform(d.newCost, '1.0-0'),
              status: cr.status,
              newPackageCode: cr.moveToCode,
              request: d.request.toLowerCase(),
            }) as any,
        ),
      })),
    );
  }

  approve(): void {
    this.dialogRef.close('approve');
  }

  reject(): void {
    this.dialogRef.close('reject');
  }

  private setScheduleImpactMessage(crPackage: ChangeRequestPackage) {
    const startDate = this.translateService.instant('general.startDate').toLowerCase();
    const endDate = this.translateService.instant('general.endDate').toLowerCase();
    const to = this.translateService.instant('filter.to').toLowerCase();
    const and = this.translateService.instant('general.and').toLowerCase();
    const formattedStartDate = this.datePipe.transform(crPackage.newPlanStartDate, dashDayDateFormat, 'UTC');
    const formattedEndDate = this.datePipe.transform(crPackage.newPlanEndDate, dashDayDateFormat, 'UTC');
    const scheduleImpactWarningMessage = this.translateService.instant('changeRequest.scheduleImpactWarningMessage');

    if (formattedStartDate && formattedEndDate) {
      this.scheduleImpactWarningMessage = `${scheduleImpactWarningMessage} ${startDate} ${to} ${formattedStartDate} ${and} ${endDate} ${to} ${formattedEndDate}`;
    } else if (formattedStartDate) {
      this.scheduleImpactWarningMessage = `${scheduleImpactWarningMessage} ${startDate} ${to} ${formattedStartDate}`;
    } else if (formattedEndDate) {
      this.scheduleImpactWarningMessage = `${scheduleImpactWarningMessage} ${endDate} ${to} ${formattedEndDate}`;
    }
  }
}
