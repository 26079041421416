<div mat-dialog-content>
  <h2>
    {{ data.title | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>

  <mat-form-field appearance="fill">
    <input
      matInput
      cdkFocusInitial
      autocomplete="off"
      [maxlength]="data.maxlength ?? 50"
      [(ngModel)]="value"
      [placeholder]="data.placeholder ?? 'general.typeHere' | translate"
    />
  </mat-form-field>

  <div class="orms-dialog-footer">
    <div>
      <button
        class="orms-button orms-button-primary submit-button"
        title="{{ 'general.enter' | translate }}"
        type="button"
        (click)="executeCallback()"
        [disabled]="isDisabled"
      >
        {{ data.submitLabel ?? 'general.submit' | translate }}
      </button>
      <button
        class="orms-button orms-button-secondary"
        title="{{ 'general.escape' | translate }}"
        type="button"
        mat-dialog-close
      >
        {{ data.cancelLabel ?? 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
