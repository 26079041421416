import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { getProjectData } from 'src/app/project-management/tree-navigation/tree-state/tree.actions';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { AccountInfoService } from 'src/app/shared/services/account-info.service';
import { UmsRoles } from 'src/app/shared/ums-roles.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private store$: Store<AppState>,
    private accountInfoService: AccountInfoService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store$.select(TreeSelectors.hasManagePermissionOnCurrentNode).pipe(
      filter((permission) => {
        if (permission !== null) {
          return true;
        }
        this.loadTreeData();
        return false;
      }),
      map((permission) => permission || this.accountInfoService.hasRole(UmsRoles.PowerUser)),
    );
  }

  private loadTreeData(): void {
    this.store$
      .select(getProjectIdSelector)
      .pipe(take(1))
      .subscribe((projectId) => {
        this.store$.dispatch(getProjectData({ projectId }));
      });
  }
}
