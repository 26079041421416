import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AppState } from '../root-store/app-state';
import { setCurrentUserWithPortfolioPermission } from '../root-store/root-store.actions';
import { getCurrentUser, getShareIdSelector } from '../root-store/root-store.selector';
import { CurrentUserService } from '../shared/current-user/current-user.service';
import { AppConfigService } from '../shared/services/app.config.service';
import { PortfolioService } from '../shared/services/portfolio.service';

@Injectable()
export class CurrentUserInfoInterceptor implements HttpInterceptor {
  apiBaseUrl: string;
  currentUserEndpoint: string;

  constructor(
    private currentUserService: CurrentUserService,
    private store$: Store<AppState>,
    appConfigService: AppConfigService,
    private portfolioService: PortfolioService,
  ) {
    const { baseUrl, currentUser } = appConfigService.settings.api.endpoints;
    this.apiBaseUrl = baseUrl;
    this.currentUserEndpoint = currentUser;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(this.apiBaseUrl) && !request.url.endsWith(this.currentUserEndpoint)) {
      return combineLatest([
        this.store$.select(getCurrentUser),
        this.store$.select(getShareIdSelector).pipe(
          filter((x) => !!x && x.hasState),
          map((x) => x.id),
        ),
      ]).pipe(
        take(1), // this is important to avoid deamon/orphan/detached subscription here
        switchMap(([user, shareId]) => {
          if (user || shareId) {
            return next.handle(request);
          }
          return this.currentUserService.currentUser$.pipe(
            switchMap((currentUser) => {
              this.store$.dispatch(
                setCurrentUserWithPortfolioPermission({
                  profile: currentUser,
                  canManagePortfolio: this.portfolioService.canUserManagePortfolio(),
                }),
              );
              return next.handle(request);
            }),
          );
        }),
      );
    }

    return next.handle(request);
  }
}
