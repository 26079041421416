<div class="datepicker-header">
  <div>
    <button type="button" class="double-arrow-left" (click)="previousClicked('year')"></button>
    <button type="button" class="arrow-left" (click)="previousClicked('month')"></button>
  </div>
  <span class="period-label">{{ periodLabel }}</span>
  <div>
    <button type="button" class="arrow-right" (click)="nextClicked('month')"></button>
    <button type="button" class="double-arrow-right" (click)="nextClicked('year')"></button>
  </div>
</div>
<div class="datepicker-footer">
  <button type="button" class="orms-button" (click)="setToNow()">
    {{ 'general.now' | translate }}
  </button>
  <div>
    <button type="button" class="orms-button clear-button" (click)="clear()">
      {{ 'general.clear' | translate }}
    </button>
    <button type="button" class="orms-button orms-button-secondary" (click)="close()">
      {{ 'general.close' | translate }}
    </button>
  </div>
</div>
