import { HistoryData } from './history-data.model';
import { HistoryAction } from './history-enums';

export const richTextFields = ['Notes', 'Intent', 'Scope'];

export function isCreatedAction(data: HistoryData): boolean {
  return data.action === HistoryAction.created;
}

export function isGrantPermissionAction(data: HistoryData): boolean {
  return (
    data.action === HistoryAction.grantedRead ||
    data.action === HistoryAction.grantedUpdate ||
    data.action === HistoryAction.grantedAdminister
  );
}

export function isRevokePermissionAction(data: HistoryData): boolean {
  return (
    data.action === HistoryAction.revokedRead ||
    data.action === HistoryAction.revokedUpdate ||
    data.action === HistoryAction.revokedAdminister
  );
}

export function isChildAction(data: HistoryData): boolean {
  return data.action === HistoryAction.deletedChild || data.action === HistoryAction.restoredChild;
}

export function isDeliverableMovedAction(data: HistoryData): boolean {
  return data.action === HistoryAction.deliverableMoved;
}

export function isPackageMovedAction(data: HistoryData): boolean {
  return data.action === HistoryAction.packageMoved;
}

export function isSubfunctionMovedAction(data: HistoryData): boolean {
  return data.action === HistoryAction.subfunctionMoved;
}

export function containsRichTextChange(data: HistoryData): boolean {
  return data.details.length === 1 && richTextFields.includes(data.details[0].itemName);
}

export function containsOnlyOneChange(data: HistoryData): boolean {
  return data.details.length === 1;
}

export function containsMoreThanOneChange(data: HistoryData): boolean {
  return data.details.length > 1;
}

export function containsChangeRequest(data: HistoryData): boolean {
  return data.changeNoticeId != null;
}

export function isTagAction(data: HistoryData): boolean {
  return data.action === HistoryAction.addedTag || data.action === HistoryAction.deletedTag;
}

export function isRelationshipAction(data: HistoryData): boolean {
  return data.action === HistoryAction.addedRelationship || data.action === HistoryAction.deletedRelationship;
}

export function isDeliverableAdded(data: HistoryData): boolean {
  return data.action === HistoryAction.deliverableAdded;
}

export function isDeliverableRemoved(data: HistoryData): boolean {
  return data.action === HistoryAction.deliverableRemoved;
}
