import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectService } from 'src/app/shared/services/project-service';
import * as projectActions from './project.actions';

@Injectable()
export class ProjectEffects {
  constructor(
    private actions: Actions,
    private projectService: ProjectService,
    private notificationService: NotificationService,
  ) {}

  getProjectDetailsRequest = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.getProjectDetails),
      filter(({ projectId }) => !!projectId),
      switchMap(({ projectId }) =>
        this.projectService.getProjectDetails(projectId).pipe(
          map((projectDetails) => {
            return projectActions.getProjectDetailsSuccess({
              projectDetails,
            });
          }),
          catchError((error) => {
            showHttpErrorResponse(this.notificationService, error, 'general.getProjectDetailsFailed');
            return of(projectActions.getProjectDetailsFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
