<form [formGroup]="form" autocomplete="off" class="node-header-container">
  <div class="breadcrumbs-section">
    <ng-content></ng-content>
    <span
      class="package-menu"
      *ngIf="treeNode?.managePermission && treeNode?.typeCode === 'PK' && sharedPackageFlag && actions.length"
    >
      <app-action-menu
        [item]="treeNode"
        [actions]="actions"
        [indicator]="betweenZeroAndThreeDays ? expirationDaysCount : null"
        [isMatButtonIcon]="false"
        [borderedIcon]="true"
      >
        <img src="/assets/icons/icon-more.svg" alt="{{ 'imgAlt.menuIcon' | translate }}" /><span
          *ngIf="!sharePackage?.id"
          class="subtitle"
          subtitle
        >
          {{ 'general.noActiveLinks' | translate }}</span
        ><span *ngIf="sharePackage?.id" class="subtitle" subtitle>
          {{ expiryMessage }}
          <span class="important-text" [ngClass]="{ important: expirationDaysCount <= 3 }"
            >{{ expirationDaysCount > 0 ? expirationDaysCount : '' }} {{ expiryTime }}</span
          >
        </span></app-action-menu
      ></span
    >
  </div>
  <div class="title-action-section">
    <input
      class="form-control orms-shadow-input orms-node-title"
      [class.is-invalid]="showInvalidState('title')"
      formControlName="title"
      id="title-input"
      [placeholder]="'general.title' | translate"
      [title]="form.get('title').value ? form.get('title').value : ('general.title' | translate)"
      maxlength="120"
    />
  </div>
  <div class="code-kpi-section">
    <div class="code">
      <input
        class="form-control orms-shadow-input orms-node-code"
        [class.is-invalid]="showInvalidState('code')"
        formControlName="code"
        id="code-input"
        [placeholder]="(treeNode?.packageId ? 'general.codeRequired' : 'general.code') | translate"
        [title]="form.get('code').value ? form.get('code').value : ('general.code' | translate)"
        maxlength="20"
      />
      <div class="details mt-2">
        <app-favorite-icon
          *ngIf="entityType === entityTypes.package"
          class="details-item favorite-icon"
          [IsFavorite]="headerDetails?.isFavorite"
          (favoriteToggled)="toggleFavorite($event)"
        ></app-favorite-icon>
        <img
          *ngIf="headerDetails.underChangeControl"
          class="baseline-icon"
          src="/assets/icons/baseline.svg"
          alt="{{ 'imgAlt.baseline' | translate }}"
        />
        <app-status-chip [statusDetails]="statusDetails"></app-status-chip>
        <app-user-dropdown
          [clearable]="true"
          [projectId]="projectId"
          [manager]="form.get('manager').value"
          formControlName="manager"
        >
        </app-user-dropdown>
      </div>
    </div>
    <div class="kpi">
      <div class="box">
        <h6 class="orms-label">{{ 'general.plannedComplete' | translate }}</h6>
        <h3>
          {{ headerDetails?.plannedComplete | statusPercent: headerDetails.status : '1.1-2' }}
        </h3>
      </div>
      <div class="box">
        <h6 class="orms-label">{{ 'general.actualComplete' | translate }}</h6>
        <h3>
          {{ headerDetails?.actualComplete | statusPercent: headerDetails.status : '1.1-2' }}
        </h3>
      </div>
      <div class="box">
        <h6 class="orms-label">SPI</h6>
        <h3>
          {{ headerDetails?.spi | statusNumber: headerDetails.status : '1.1-2' }}
        </h3>
      </div>
    </div>
  </div>
</form>
