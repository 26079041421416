import { EntityState } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';
import {
  ChangeRequest,
  ChangeRequestDeliverable,
  ChangeRequestPackage,
  MoveRequestDeliverable,
} from './cart-item.model';
import { changeRequestReducer } from './change-requests/change-request.reducer';
import { ChangeRequestDeliverableReducer, MoveRequestDeliverableReducer } from './deliverables/crDeliverable.reducer';
import { ChangeRequestPackageReducer } from './packages/package.reducer';

export interface ChangeRequestCartState {
  changeRequests: EntityState<ChangeRequest>;
  packages: EntityState<ChangeRequestPackage>;
  deliverables: EntityState<ChangeRequestDeliverable>;
  moveDeliverables: EntityState<MoveRequestDeliverable>;
}

export const changeRequestCartReducerMap: ActionReducerMap<ChangeRequestCartState> = {
  changeRequests: changeRequestReducer,
  packages: ChangeRequestPackageReducer,
  deliverables: ChangeRequestDeliverableReducer,
  moveDeliverables: MoveRequestDeliverableReducer,
};
