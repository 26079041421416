<form [formGroup]="form" autocomplete="off" class="orms-cr-submission-form" (ngSubmit)="onSubmit()">
  <h2>
    {{ data?.title | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <div class="add-deliverable-form">
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'general.title' | translate }}</mat-label>
      <input
        autocomplete="off"
        matInput
        placeholder="{{ 'general.title' | translate }}"
        formControlName="title"
        maxlength="255"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'general.number' | translate }} </mat-label>
      <input
        autocomplete="off"
        matInput
        placeholder="{{ 'general.number' | translate }}"
        formControlName="documentNumber"
        maxlength="100"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'general.curveProfile' | translate }}</mat-label>
      <mat-select formControlName="curveProfile">
        <mat-option *ngFor="let w of curveProfiles$ | async" [value]="w.code">
          {{ w.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'deliverable.progressWorkflow' | translate }}</mat-label>
      <mat-select formControlName="progressWorkflow">
        <mat-option *ngFor="let w of projectWorkflows$ | async" [value]="w.progressWorkflowId">
          {{ w.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'general.budgetHours' | translate }}</mat-label>
      <input
        autocomplete="off"
        matInput
        placeholder="{{ 'general.budgetHours' | translate }}"
        type="number"
        formControlName="budgetHours"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="orms-outline-input">
      <mat-label>{{ 'general.budgetCost' | translate }}</mat-label>
      <input
        autocomplete="off"
        matInput
        placeholder="{{ 'general.budgetCost' | translate }}"
        type="number"
        formControlName="budgetCost"
      />
    </mat-form-field>
  </div>
  <div class="submit-row">
    <button type="submit" class="orms-button orms-button-primary submit-button">
      {{ 'general.confirm' | translate }}
    </button>
    <button type="button" class="orms-button orms-button-secondary" mat-dialog-close>
      {{ 'general.cancel' | translate }}
    </button>
    <div class="required"><span>*</span> {{ 'general.fieldIsRequired' | translate }}</div>
  </div>
</form>
