import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChangeRequestType } from 'src/app/project-management/common/navigation-tabs/change-request-list/cr-type';
import { crDeliverableChangeCount, crPackageChangeCount } from '../../change-request-cart-functions';
import { FullChangeRequestModel, FullMoveRequestModel } from '../../change-request-submit/full-change-request';
import { RequestType } from '../cart-item.model';
import * as currentContextFunctionSelectors from '../current-context-function/current-context-function.selectors';
import * as fromState from '../state';
import { ChangeRequestCartState } from '../state';
import { changeRequestAdapter } from './change-request.reducer';

export const changeRequestState = createFeatureSelector<fromState.ChangeRequestCartState>('cartItems');

const { selectEntities, selectAll, selectTotal } = changeRequestAdapter.getSelectors();

export const selectChangeRequestEntities = selectEntities;
export const selectAllChangeRequests = selectAll;
export const selectChangeRequestsTotal = selectTotal;

export const checkChangeRequestExists = (props: { functionId: number }) =>
  createSelector(changeRequestState, (state: ChangeRequestCartState) => {
    return !!state.changeRequests.entities[props.functionId];
  });

export const crExistsInCurrentContext = createSelector(
  currentContextFunctionSelectors.selectCurrentContextFunction,
  changeRequestState,
  (functionId: number, state: ChangeRequestCartState) => {
    return !!state.changeRequests.entities[functionId];
  },
);

export const crCountChanges = createSelector(
  currentContextFunctionSelectors.selectCurrentContextFunction,
  changeRequestState,
  (_, state: ChangeRequestCartState) => {
    return calculateCrChanges(state);
  },
);

export const movesCount = createSelector(
  currentContextFunctionSelectors.selectCurrentContextFunction,
  changeRequestState,
  (_, state: ChangeRequestCartState) => {
    return Object.values(state.changeRequests.entities).filter(
      (x) => x.changeRequestType === ChangeRequestType.MovePackage,
    ).length;
  },
);

export const getMoveRequests = createSelector(
  currentContextFunctionSelectors.selectCurrentContextFunction,
  changeRequestState,
  (_, state: ChangeRequestCartState): FullMoveRequestModel => {
    const moveRequest = Object.values(state.changeRequests.entities).filter(
      (x) => x.changeRequestType === ChangeRequestType.MovePackage,
    );
    if (!moveRequest.length) {
      return null;
    }

    const contextElementIds = moveRequest.map((x) => x.contextElementId);
    const title = moveRequest.map((x) => x.title)[0];
    const description = moveRequest.map((x) => x.description)[0];
    const distinctManagers = [...new Set(moveRequest.map((x) => x.manager))];

    const manager = getApproverLabel(distinctManagers.filter((x) => x !== null));

    const packages = Object.values(state.packages.entities);
    const deliverables = Object.values(state.moveDeliverables.entities);
    return {
      contextElementIds,
      title,
      description,
      manager,
      packages,
      deliverables,
      statusChanges: [],
    };
  },
);

export const getFullCRForCurrentContext = createSelector(
  currentContextFunctionSelectors.selectCurrentContextFunction,
  changeRequestState,
  (_, state: ChangeRequestCartState): FullChangeRequestModel => {
    const changeRequest = Object.values(state.changeRequests.entities).filter(
      (x) => x.changeRequestType !== ChangeRequestType.MovePackage,
    );
    if (!changeRequest.length) {
      return null;
    }

    const contextElementIds = changeRequest.map((x) => x.contextElementId);
    const title = changeRequest.map((x) => x.title)[0];
    const description = changeRequest.map((x) => x.description)[0];
    const distinctManagers = [...new Set(changeRequest.map((x) => x.manager))];

    const manager = getApproverLabel(distinctManagers.filter((x) => x !== null));

    const packages = Object.values(state.packages.entities);
    const deliverables = Object.values(state.deliverables.entities);
    return {
      contextElementIds,
      title,
      description,
      manager,
      packages,
      deliverables,
      statusChanges: [],
    };
  },
);

const getApproverLabel = (managers: string[]) => {
  if (!managers.length) {
    return '-';
  } else {
    return managers.length > 1 ? 'Multiple' : managers[0];
  }
};

export const getCrAffectedIds = createSelector(changeRequestState, (state: ChangeRequestCartState): number[] => {
  const functionsIds = Object.values(state.changeRequests.entities)
    .filter(() => calculateCrChanges(state) > 0)
    .map((cr) => cr.contextElementId);
  const affectedPackagesIds = Object.values(state.packages.entities)
    .filter((p) => functionsIds.includes(p.functionId))
    .map((p) => p.functionalHierarchyId);

  const uniqueIdsSet = new Set([...functionsIds, ...affectedPackagesIds]);

  return Array.from(uniqueIdsSet).map((id: string | number) => Number(id));
});

const calculateCrChanges = (state: ChangeRequestCartState) => {
  const packageChangesCount = Object.values(state.packages.entities)
    .map((x) => crPackageChangeCount(x))
    .reduce((sum, current) => sum + current, 0);

  const deliverableChangesCount = Object.values(state.deliverables.entities)
    .filter((d) => d.request === RequestType.Change)
    .map((x) => crDeliverableChangeCount(x))
    .reduce((sum, current) => sum + current, 0);

  const newDeliverablesCount = Object.values(state.deliverables.entities).filter(
    (d) => d.request === RequestType.New,
  ).length;
  const cancelledDeliverablesCount = Object.values(state.deliverables.entities).filter(
    (d) => d.request === RequestType.Cancel,
  ).length;

  return packageChangesCount + deliverableChangesCount + newDeliverablesCount + cancelledDeliverablesCount;
};
