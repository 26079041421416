import { Injectable } from '@angular/core';
import { isArray } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';
import { ChangeRequestListItem } from './change-request-list-item';
import { Approver } from './cr-filters/cr-filters.component';
import { CRStatusCode } from './cr-status';
import {
  CRFilterType,
  CRFilters,
  CRStatusFilterName,
  ChangeRequestsViewState,
  initializeChangeRequestsViewState,
} from './model';

@Injectable({
  providedIn: 'root',
})
export class CRFiltersService {
  private filters: BehaviorSubject<CRFilters>;

  constructor(sessionStorageService: SessionStorageService) {
    let filters = sessionStorageService.getItem<ChangeRequestsViewState>(StorageKey.changeRequestsList)?.crFilters;
    filters = filters ?? initializeChangeRequestsViewState(sessionStorageService).crFilters;
    this.filters = new BehaviorSubject<CRFilters>(filters);
  }

  setFilter(type: CRFilterType, value: any): void {
    this.filters.next({
      ...this.filters.value,
      [type]: value,
    });
  }

  getFilters(): Observable<CRFilters> {
    return this.filters.asObservable();
  }
}

export const applyApproverFilter = (data: ChangeRequestListItem[], approvers: Approver[]): ChangeRequestListItem[] => {
  if (!isArray(approvers) || !approvers.length) {
    return data;
  }
  const approverIds = approvers && approvers.map((approver) => approver.userId);
  return data.filter((cr) => approverIds.includes(cr.approverId));
};

export const applyFullTextFilter = (data: ChangeRequestListItem[], fullTextFilter: string): ChangeRequestListItem[] => {
  const textToSearch = fullTextFilter.toLowerCase();
  return data.filter(
    (cr) =>
      cr.title.toLowerCase().includes(textToSearch) ||
      cr.number.toString().toLowerCase().includes(textToSearch) ||
      cr.code.toString().toLowerCase().includes(textToSearch),
  );
};

export const getCRStatusCodesFromCRStatusFilterName = (status: CRStatusFilterName): CRStatusCode[] => {
  switch (status) {
    case CRStatusFilterName.Pending:
      return [CRStatusCode.Logged];
    case CRStatusFilterName.Approved:
      return [CRStatusCode.Approved];
    case CRStatusFilterName.Cancelled:
      return [CRStatusCode.Rejected, CRStatusCode.Cancelled];
    default:
      return [CRStatusCode.Logged, CRStatusCode.Approved, CRStatusCode.Rejected, CRStatusCode.Cancelled];
  }
};
