import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, merge } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import * as routerSelectors from 'src/app/root-store/root-store.selector';
import { Detail } from '../../common/navigation-tabs/detail/detail.model';
import { DetailService } from '../../common/navigation-tabs/detail/detail.service';
import { SignalRService } from '../../signalr/signalr.service';

@Component({
  selector: 'app-function-detail',
  templateUrl: './function-detail.component.html',
  styleUrls: ['./function-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionDetailComponent implements OnInit {
  detailData$: Observable<Detail>;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store$: Store<AppState>,
    private detailService: DetailService,
    private signalrService: SignalRService,
  ) {}

  ngOnInit(): void {
    const updateSub = combineLatest([
      this.store$.select(routerSelectors.getIdSelector),
      this.signalrService.kpiUpdated(),
    ]).pipe(
      map(([id, nodeChangedIds]) => {
        if (nodeChangedIds.includes(id)) {
          return id;
        }
        return null;
      }),
    );

    this.detailData$ = merge(this.store$.select(routerSelectors.getIdSelector), updateSub).pipe(
      filter((id) => !!id),
      tap(() => this.loading$.next(true)),
      mergeMap((id) => this.detailService.getDetailData(id)),
      tap(() => this.loading$.next(false)),
    );
  }
}
