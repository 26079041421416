import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyNativeDateModule } from '@angular/material/legacy-core';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { SharedControlsModule } from 'src/app/shared-controls/shared-controls.module';
import { UiShowcaseRoutingModule } from './ui-showcase-routing.module';
import { UiShowcaseComponent } from './ui-showcase.component';

@NgModule({
  declarations: [UiShowcaseComponent],
  imports: [
    CommonModule,
    UiShowcaseRoutingModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    MatLegacySnackBarModule,
    SharedControlsModule,
    MatLegacyTabsModule,
    MatDatepickerModule,
    MatLegacyNativeDateModule,
    MatLegacyInputModule,
    MatLegacySelectModule,
  ],
})
export class UiShowcaseModule {}
