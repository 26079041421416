import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { rewriteDateFromUTC } from 'src/app/shared/common-formatters';
import { dashDayDateFormat } from 'src/app/shared/date-options/short-date-format';

export interface DateFilterValue {
  operation: DateFilterOperation;
  arg1: Date | moment.Moment;
  arg2: Date | moment.Moment;
}

export enum DateFilterOperation {
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  GreaterThanOrEquals = 'GreaterThanOrEquals',
  LessThanOrEquals = 'LessThanOrEquals',
  Between = 'Between',
}

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string;

  @Input() value: DateFilterValue;

  @Output()
  valueChange = new EventEmitter<DateFilterValue>();

  DateFilterOperation = DateFilterOperation;
  form: UntypedFormGroup;
  label$ = new BehaviorSubject<string>('');

  private onDestroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      operation: new UntypedFormControl(null),
      arg1: new UntypedFormControl(null),
      arg2: new UntypedFormControl(null),
    });

    this.form.patchValue(this.value, { emitEvent: false });

    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$), startWith(this.translateService.currentLang))
      .subscribe(() => this.refreshLabel());

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.valueChange.emit(this.isCorrectValue() ? value : null);
        this.refreshLabel();
      });

    this.form
      .get('operation')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.form.patchValue({
          arg1: null,
          arg2: null,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && 'value' in changes) {
      this.patchForm(this.value, false);
      this.refreshLabel();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onClearClick(e): void {
    this.patchForm(null, true);
    e.stopPropagation();
  }

  getControl(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  private patchForm(value: DateFilterValue, emitEvent: boolean): void {
    this.form.patchValue(
      {
        operation: value?.operation,
        arg1: value?.arg1,
        arg2: value?.arg2,
      },
      { emitEvent },
    );
  }

  private isCorrectValue(): boolean {
    if (this.form.get('operation').value == null) return false;
    if (this.form.get('arg1').value == null) return false;
    if (this.form.get('operation').value == DateFilterOperation.Between && this.form.get('arg2').value == null)
      return false;

    return true;
  }

  private refreshLabel(): void {
    let label = this.translateService.instant(this.label);

    const operation = this.form.get('operation').value;
    const arg1 = this.formatDate(this.form.get('arg1').value);
    const arg2 = this.formatDate(this.form.get('arg2').value);

    if (arg1 == null) {
      this.label$.next(label);
      return;
    }

    switch (operation) {
      case DateFilterOperation.GreaterThan:
        label = `${label} > ${arg1}`;
        break;
      case DateFilterOperation.LessThan:
        label = `${label} < ${arg1}`;
        break;
      case DateFilterOperation.GreaterThanOrEquals:
        label = `${label} ≥ ${arg1}`;
        break;
      case DateFilterOperation.LessThanOrEquals:
        label = `${label} ≤ ${arg1}`;
        break;
      case DateFilterOperation.Between:
        if (arg2) {
          label = `${arg1} ≤ ${label} ≤ ${arg2}`;
        }
        break;
    }
    this.label$.next(label);
  }

  private formatDate(date: Date): string {
    return this.datePipe.transform(rewriteDateFromUTC(date), dashDayDateFormat);
  }
}
