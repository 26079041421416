import { intersection, isEmpty, uniq } from 'lodash-es';
import { ProjectImportColumns } from '../../project-import.models';
import { ReviewImportFilter } from './review-import-filters.service';

export const createResultFilter = (resultIds: string[], nodes: any[]): ReviewImportFilter => {
  const nodeResultIds = uniq(nodes.map((node) => node.result.toLocaleLowerCase()));
  const availableResultIds = intersection(
    nodeResultIds,
    resultIds.map((resultId) => resultId.toLocaleLowerCase()),
  );

  const predicate = (row: any) =>
    !availableResultIds.length || availableResultIds.includes(row.result.toLocaleLowerCase());

  const filter: ReviewImportFilter = {
    filterId: ProjectImportColumns.Result,
    predicate: predicate,
    state: resultIds,
  };

  return filter;
};

export const createCodeFilter = (codeIds: string[], nodes: any[]): ReviewImportFilter => {
  const nodeCodeIds = uniq(nodes.map((node) => (isEmpty(node.code) ? '' : node.code).toLocaleLowerCase()));
  const availableCodeIds = intersection(
    nodeCodeIds,
    codeIds.map((codeId) => codeId.toLocaleLowerCase()),
  );

  const predicate = (row: any) =>
    !availableCodeIds.length || availableCodeIds.includes((row.code ?? '').toLocaleLowerCase());

  const filter: ReviewImportFilter = {
    filterId: ProjectImportColumns.Code,
    predicate: predicate,
    state: codeIds,
  };

  return filter;
};

export const createSheetFilter = (sheetIds: string[], nodes: any[]): ReviewImportFilter => {
  const nodeSheetIds = uniq(nodes.map((node) => node.sheet.toLocaleLowerCase()));
  const availableSheetIds = intersection(
    nodeSheetIds,
    sheetIds.map((sheetId) => sheetId.toLocaleLowerCase()),
  );

  const predicate = (row: any) =>
    !availableSheetIds.length || availableSheetIds.includes(row.sheet.toLocaleLowerCase());

  const filter: ReviewImportFilter = {
    filterId: ProjectImportColumns.Sheet,
    predicate: predicate,
    state: sheetIds,
  };

  return filter;
};

export const createCategoryFilter = (categoryIds: string[], nodes: any[]): ReviewImportFilter => {
  const nodeCategoryIds = uniq(
    nodes
      .flatMap((node) => {
        const { feedback } = node;
        if (!isEmpty(feedback)) {
          return feedback;
        }

        return [''];
      })
      .map((feedbackEntry) =>
        (isEmpty(feedbackEntry.feedbackType) ? '' : feedbackEntry.feedbackType).toLocaleLowerCase(),
      ),
  );

  const availableFeedbackTypeIds = intersection(
    nodeCategoryIds,
    categoryIds.map((categoryId) => categoryId.toLocaleLowerCase()),
  );

  const predicate = (row: any) => {
    const { feedback } = row;
    const rowMatched = !feedback.length
      ? availableFeedbackTypeIds.includes('')
      : feedback.some((feedbackEntry) =>
          availableFeedbackTypeIds.includes(feedbackEntry.feedbackType.toLocaleLowerCase()),
        );

    return !availableFeedbackTypeIds.length || rowMatched;
  };

  const filter: ReviewImportFilter = {
    filterId: ProjectImportColumns.Category,
    predicate: predicate,
    state: categoryIds,
  };

  return filter;
};

export const createFeedbackFilter = (feedbackIds: string[], nodes: any[]): ReviewImportFilter => {
  const nodeFeedbackIds = uniq(
    nodes
      .flatMap((node) => {
        const { feedback } = node;
        if (!isEmpty(feedback)) {
          return feedback;
        }

        return [''];
      })
      .map((feedback) => (isEmpty(feedback) ? '' : feedback.content[0])),
  );

  const availableFeedbackIds = intersection(nodeFeedbackIds, feedbackIds);

  const predicate = (row: any) => {
    const { feedback } = row;
    const rowMatched = !feedback.length
      ? availableFeedbackIds.includes('')
      : feedback.some((item) => availableFeedbackIds.includes(item.content[0]));

    return !availableFeedbackIds.length || rowMatched;
  };

  const filter: ReviewImportFilter = {
    filterId: ProjectImportColumns.Feedback,
    predicate: predicate,
    state: feedbackIds,
  };

  return filter;
};
