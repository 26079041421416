<div class="filters">
  <div class="input-group filter-search">
    <input
      autocomplete="off"
      class="form-control orms-simple-input search-input"
      placeholder="{{ 'general.search' | translate }}"
      [ngModel]="searchFilterValue"
      (ngModelChange)="changeFilter(DeliverableFilterType.FullText, $event)"
    />
    <div class="input-group-append orms-simple-input-append">
      <div class="input-group-text search-icon">
        <img src="/assets/icons/icon-search.svg" alt="{{ 'imgAlt.search' | translate }}" />
      </div>
    </div>
  </div>
  <mat-select
    class="orms-select status-filter"
    [disableOptionCentering]="true"
    [ngModel]="statusFilterValue"
    (ngModelChange)="changeFilter(DeliverableFilterType.Status, $event)"
    panelClass="deliverables-status-filter-select orms-select-options"
  >
    <mat-option *ngFor="let statusFilter of statusFilters" [value]="statusFilter.key">
      {{ statusFilter.text | translate }}
    </mat-option>
  </mat-select>

  <app-searchable-dropdown
    [list]="responsibleList"
    [control]="userControl"
    placeholderText="general.responsible"
    placeholderSearchText="general.search"
    defaultValue="general.responsible"
    (filterChanged)="changeFilter(DeliverableFilterType.Responsible, $event)"
  >
  </app-searchable-dropdown>
  <app-searchable-dropdown
    [list]="tagList"
    [control]="tagControl"
    placeholderText="general.tags"
    placeholderSearchText="general.search"
    defaultValue="general.tags"
    (filterChanged)="changeFilter(DeliverableFilterType.Tags, $event)"
  >
  </app-searchable-dropdown>

  <button
    type="button"
    class="lookahead-button"
    [ngClass]="{
      'lookahead-button-selected': lookAheadFilterText
    }"
    mat-button
    [matMenuTriggerFor]="menu"
  >
    {{ lookAheadFilterText || 'deliverable.lookahead' | translate }}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <div class="lookahead-datepickers">
    <mat-form-field class="orms-datepicker-shadow-input">
      <input
        matInput
        [matDatepicker]="startsPicker"
        [formControl]="startsBeforeControl"
        (dateChange)="changeStartsBeforeLookAheadFilter($event.value.toDate())"
      />
      <mat-datepicker panelClass="orms-datepicker" #startsPicker [calendarHeaderComponent]="datepickerActionsComponent">
      </mat-datepicker>
    </mat-form-field>
    <mat-form-field class="orms-datepicker-shadow-input">
      <input
        matInput
        [matDatepicker]="duePicker"
        [formControl]="dueBeforeControl"
        (dateChange)="changeDueBeforeLookAheadFilter($event.value.toDate())"
      />
      <mat-datepicker panelClass="orms-datepicker" #duePicker [calendarHeaderComponent]="datepickerActionsComponent">
      </mat-datepicker>
    </mat-form-field>
  </div>
  <mat-menu #menu="matMenu" class="orms-menu lookahead-menu">
    <button type="button" mat-menu-item (click)="changeFilter(DeliverableFilterType.LookAhead, { type: 'startsSoon' })">
      {{ 'deliverable.startsSoon' | translate }}
    </button>
    <button type="button" mat-menu-item (click)="changeFilter(DeliverableFilterType.LookAhead, { type: 'dueSoon' })">
      {{ 'deliverable.dueSoon' | translate }}
    </button>
    <mat-divider></mat-divider>
    <button type="button" mat-menu-item (click)="startsPicker.open()" class="calendar-button">
      {{ 'deliverable.startsBefore' | translate }}
      <img src="/assets/icons/icon-calendar-dark.svg" alt="{{ 'imgAlt.calendar' | translate }}" />
    </button>
    <button type="button" mat-menu-item (click)="duePicker.open()" class="calendar-button">
      {{ 'deliverable.dueBefore' | translate }}
      <img src="/assets/icons/icon-calendar-dark.svg" alt="{{ 'imgAlt.calendar' | translate }}" />
    </button>
    <mat-divider></mat-divider>
    <button type="button" mat-menu-item (click)="changeFilter(DeliverableFilterType.LookAhead, { type: null })">
      {{ 'general.clear' | translate }}
    </button>
  </mat-menu>
</div>
