import { ColDef } from 'ag-grid-community';
import { ProjectImportColumns } from './project-import.models';
import { AgCellChangesLinkComponent } from './review-import-step/ag-custom-components/ag-cell-changes-link/ag-cell-changes-link.component';
import { AgCellFeedbackComponent } from './review-import-step/ag-custom-components/ag-cell-feedback/ag-cell-feedback.component';
import { AgCellSimpleComponent } from './review-import-step/ag-custom-components/ag-cell-simple/ag-cell-simple.component';

const wideColumnWidth = 150;
const narrowColumnWidth = 40;
const extraNarrowColumnWidth = 25;

export const getColumnDefs = (): ColDef[] => [
  {
    headerName: 'projectImport.result',
    field: ProjectImportColumns.Result,
    cellRenderer: AgCellChangesLinkComponent,
    comparator: stringComparator,
    width: narrowColumnWidth,
    resizable: true,
  },
  {
    headerName: 'projectImport.sheet',
    field: ProjectImportColumns.Sheet,
    cellRenderer: AgCellSimpleComponent,
    comparator: stringComparator,
    width: extraNarrowColumnWidth,
    resizable: true,
  },
  {
    headerName: 'projectImport.row',
    field: ProjectImportColumns.Row,
    cellRenderer: AgCellSimpleComponent,
    comparator: numberComparator,
    width: 10,
    resizable: true,
  },
  {
    headerName: 'projectImport.code',
    field: ProjectImportColumns.Code,
    cellRenderer: AgCellSimpleComponent,
    comparator: stringComparator,
    width: extraNarrowColumnWidth,
    resizable: true,
  },
  {
    headerName: 'projectImport.title',
    field: ProjectImportColumns.Title,
    cellRenderer: AgCellSimpleComponent,
    comparator: stringComparator,
    width: narrowColumnWidth,
    resizable: true,
  },
  {
    headerName: 'projectImport.feedback',
    field: ProjectImportColumns.Feedback,
    valueFormatter: feedbackFormatter,
    cellRenderer: AgCellFeedbackComponent,
    comparator: stringComparator,
    minWidth: wideColumnWidth,
    autoHeight: true,
    resizable: true,
  },
];

const feedbackFormatter = (params) => {
  // The value for the "feedback" column is an array of feedback, we utilize ag-cell-feedback.component to format it for the UI
  return params.value;
};

const stringComparator = (valueA, valueB) => {
  if (valueA === '' || valueA == null) return -1;
  if (valueB === '' || valueB == null) return 1;

  return valueA.toString().localeCompare(valueB.toString(), undefined, { sensitivity: 'base' });
};

const numberComparator = (valueA, valueB) => {
  if (isNaN(valueA)) return -1;
  if (isNaN(valueB)) return 1;

  return valueA - valueB;
};
