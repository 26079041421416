import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { TreeActionsService } from 'src/app/project-management/tree-navigation/tree-actions-menu/tree-actions.service';
import { updateNodeProperty } from 'src/app/project-management/tree-navigation/tree-state/tree.actions';
import { AppState } from 'src/app/root-store/app-state';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { StorageKey } from 'src/app/shared/services/storage-keys';
import { Detail } from '../detail.model';

@Component({
  selector: 'app-intent-section',
  templateUrl: './intent-section.component.html',
  styleUrls: ['./intent-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntentSectionComponent implements OnInit, OnChanges {
  @Input() detailData: Detail;
  @Input() entityId: number;
  @Input() editableFields: string[];
  isExpanded: boolean;

  isEditable$ = new BehaviorSubject<boolean>(false);
  public init: any;

  constructor(
    appConfigService: AppConfigService,
    private store$: Store<AppState>,
    translationService: TranslateService,
    private treeActionsService: TreeActionsService,
    private localStorageService: LocalStorageService,
  ) {
    this.init = {
      ...appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...appConfigService.settings.richTextConfig.intentInitOptions,
      placeholder: translationService.instant('general.intentPlaceholder'),
      content_style: `
	  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
		  color: #999;
	  }
	`,
    };
  }

  ngOnInit() {
    this.getOrInitializeIsIntentAreaExpanded();
  }

  private getOrInitializeIsIntentAreaExpanded(): boolean {
    this.isExpanded = this.localStorageService.getItem(StorageKey.isPackageDetailIntentSectionExpanded);
    if (this.isExpanded === undefined) {
      this.isExpanded = true;
      this.localStorageService.setItem(StorageKey.isPackageDetailIntentSectionExpanded, this.isExpanded);
    }

    return this.isExpanded;
  }

  public opened() {
    this.localStorageService.setItem(StorageKey.isPackageDetailIntentSectionExpanded, true);
  }

  public closed() {
    this.localStorageService.setItem(StorageKey.isPackageDetailIntentSectionExpanded, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('entityId' in changes || 'editableFields' in changes) {
      this.isEditable$.next(this.editableFields?.includes('intent'));
    }
  }

  change(value): void {
    if (!value.editor.isDirty()) {
      return;
    }

    const newIntent = value.editor.getContent();

    const update: Update<any> = {
      id: this.entityId,
      changes: {
        intent: newIntent,
      },
    };

    this.store$.dispatch(
      updateNodeProperty({
        key: 'intent',
        entityType: EntityTypes.package,
        update: this.treeActionsService.mergeFunctionalHierarchyIdUpdates(update),
      }),
    );

    value.editor.setDirty(false);
  }
}
