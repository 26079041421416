import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { updateDeliverableSuccess } from 'src/app/project-management/deliverable/deliverable-state/deliverable.actions';
import { clearProjectRelatedData } from 'src/app/root-store/root-store.actions';
import { DeliverableDetail } from '../DeliverableDetail';
import * as deliverablesListActions from './deliverables-list.actions';
import { deleteDeliverableSuccess } from './deliverables-list.actions';

export interface DeliverablesListState extends EntityState<DeliverableDetail> {}

export const deliverablesListAdapter: EntityAdapter<DeliverableDetail> = createEntityAdapter<DeliverableDetail>({
  selectId: (model) => model.deliverableId,
});

const initialState: DeliverablesListState = deliverablesListAdapter.getInitialState();

export const deliverablesListReducer = createReducer(
  initialState,
  on(deliverablesListActions.getDeliverablesByFunctionalIdSuccess, (state, { deliverablesListResponse }) => {
    let updatedState = state;
    deliverablesListResponse.deliverables.forEach((deliverable) => {
      const existingDeliverable = state.entities[deliverable.deliverableId];
      if (!existingDeliverable) {
        updatedState = deliverablesListAdapter.addOne(deliverable, updatedState);
        return true;
      } else if (
        deliverable.documentNumber !== existingDeliverable.documentNumber ||
        deliverable.title !== existingDeliverable.title ||
        deliverable.responsibleId !== existingDeliverable.responsibleId ||
        deliverable.startDate !== existingDeliverable.startDate ||
        deliverable.endDate !== existingDeliverable.endDate ||
        deliverable.hours !== existingDeliverable.hours ||
        deliverable.cost !== existingDeliverable.cost ||
        deliverable.status !== existingDeliverable.status ||
        deliverable.progressWorkflowId !== existingDeliverable.progressWorkflowId ||
        deliverable.progressWorkflowStatusId !== existingDeliverable.progressWorkflowStatusId ||
        deliverable.percentageCompleted !== existingDeliverable.percentageCompleted ||
        deliverable.controlledDocument !== existingDeliverable.controlledDocument ||
        deliverable.canAdd !== existingDeliverable.canAdd ||
        deliverable.canMove !== existingDeliverable.canMove ||
        deliverable.canDelete !== existingDeliverable.canDelete ||
        deliverable.canCancel !== existingDeliverable.canCancel
      ) {
        updatedState = deliverablesListAdapter.setOne(deliverable, updatedState);
        return true;
      } else {
        return false;
      }
    });
    return updatedState;
  }),

  on(updateDeliverableSuccess, (state, { deliverable }) => {
    let existingDeliverable = { ...state.entities[deliverable.deliverableId] };
    // There is a shortcoming in the API such that the deliverable patch/update methods do not calculate the "CanXXX" states,
    // moreover they return a DeliverableDto rather than a DeliverableWithTagsDto and so are missing certain properties:
    //   PlannedPercentageCompleted, TreeLevel (but TreeLevel is not part of the DeliverableDetail), Tags
    // AND
    //   have an additional CanAdd property that is NOT part of DeliverableWithTagsDto
    // ... so have some aligning to do ...
    // For the deliverables grid we're only actually interested in changes to:
    //   documentNumber
    //   title
    //   responsibleId
    //   responsible
    //   startDate
    //   endDate
    //   hours
    //   cost
    //   progressWorkflowId
    //   progressWorkflow
    //   progressWorkflowStatusId
    //   progressWorkflowStatus
    //   percentageCompleted
    //   controlledDocument

    if (existingDeliverable) {
      existingDeliverable.documentNumber = deliverable.documentNumber;
      existingDeliverable.title = deliverable.title;
      existingDeliverable.responsibleId = deliverable.responsibleId;
      existingDeliverable.responsible = deliverable.responsible;
      existingDeliverable.startDate = deliverable.startDate;
      existingDeliverable.endDate = deliverable.endDate;
      existingDeliverable.hours = deliverable.hours;
      existingDeliverable.cost = deliverable.cost;
      existingDeliverable.progressWorkflowId = deliverable.progressWorkflowId;
      existingDeliverable.progressWorkflow = deliverable.progressWorkflow;
      existingDeliverable.progressWorkflowStatusId = deliverable.progressWorkflowStatusId;
      existingDeliverable.progressWorkflowStatus = deliverable.progressWorkflowStatus;
      existingDeliverable.percentageCompleted = deliverable.percentageCompleted;
      existingDeliverable.controlledDocument = deliverable.controlledDocument;
      return deliverablesListAdapter.upsertOne(existingDeliverable, state);
    } else {
      // Not found ?? what are we doing recieving an updateDeliverableSuccess without it already being known to us?
      // Ignore the update by returning an unaltered state.
      return state;
    }
  }),

  // addDeliverableSuccess
  // NOTE: Adding a deliverable causes a reload that results in a getDeliverablesByFunctionalIdSuccess
  // so we don't do anyting on "addDeliverableSuccess" because that do the work twice

  on(deleteDeliverableSuccess, (state, { deliverableId }) => {
    return deliverablesListAdapter.removeOne(deliverableId, state);
  }),

  on(clearProjectRelatedData, () => {
    return deliverablesListAdapter.getInitialState();
  }),
);

const { selectEntities } = deliverablesListAdapter.getSelectors();
export const selectDeliverablesListEntities = selectEntities;
