<form [formGroup]="form" class="orms-cr-submission-form" *ngIf="!showSubmissionResult; else submissionResult">
  <h2>
    {{ (data.action === bulkAction.Approve ? 'changeRequest.crApproval' : 'changeRequest.crRejection') | translate }}
    <button mat-dialog-close type="button" class="orms-close-button"></button>
  </h2>
  <div class="subtitle">
    {{ 'changeRequest.theFollowing' | translate }}
    <b>({{ data.requests.length }})</b>
    {{ 'changeRequest.itemsWillBe' | translate }}
    {{
      (data.action === bulkAction.Approve ? 'changeRequest.approved' : 'changeRequest.rejected')
        | translate
        | lowercase
    }}:
  </div>

  <app-change-request-submission-table
    class="bigger-table-container"
    [requests]="data.requests"
    [action]="data.action"
  ></app-change-request-submission-table>

  <div class="rejection-reason" *ngIf="data.action === bulkAction.Reject">
    <textarea
      class="form-control orms-simple-input"
      formControlName="reason"
      [placeholder]="'changeRequest.rejectionReason' | translate"
      [class.is-invalid]="showInvalidState('reason')"
    ></textarea>
  </div>

  <div class="footer">
    <div>
      <button
        type="button"
        [disabled]="submitting"
        (click)="onSubmit()"
        class="orms-button orms-button-primary submit-button"
      >
        {{ data?.action | translate }}
      </button>
      <button type="button" class="orms-button orms-button-secondary" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </button>
    </div>
    <div>{{ 'changeRequest.approver' | translate }}: {{ data.approver }}</div>
  </div>
</form>

<ng-template #submissionResult>
  <app-partial-success
    [approver]="data.approver"
    (byClose)="done()"
    [failed]="failed"
    [success]="success"
    [action]="data.action"
    [totalRequestNumber]="data.requests.length"
  ></app-partial-success>
</ng-template>
