import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoginFailedComponent } from '../auth/login-failed/login-failed.component';
import { LoginComponent } from '../auth/login/login.component';
import { SharedControlsModule } from '../shared-controls/shared-controls.module';
import { MissingTranslationsHandler } from '../shared/translation/missing-translations-handler';
import { TranslateLanguageLoader } from '../shared/translation/translate.language.loader';
import { HeaderComponent } from './header/header.component';
import { LanguageChooserComponent } from './language-chooser/language-chooser.component';
import { MenuComponent } from './menu/menu.component';

@NgModule({
  declarations: [HeaderComponent, LoginComponent, LoginFailedComponent, MenuComponent, LanguageChooserComponent],
  imports: [
    CommonModule,
    SharedControlsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationsHandler,
      },
    }),
    MatLegacySelectModule,
    MatLegacyMenuModule,
    MatIconModule,
    MatLegacyDialogModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
