<div class="orms-cr-submission-form" *ngIf="failed.length === totalRequestNumber; else successfullySubmitted">
  <h2>
    {{
      (action === bulkAction.Approve ? 'changeRequest.submissionFailure' : 'changeRequest.rejectionFailure') | translate
    }}
    <button (click)="close()" type="button" class="orms-close-button"></button>
  </h2>
  <div class="submission-result">
    <mat-icon class="reject-icon icon">cancel</mat-icon>
    <p class="message-result-container">
      {{
        (action === bulkAction.Approve ? 'changeRequest.bulkApproveFailure' : 'changeRequest.bulkRejectFailure')
          | translate
      }}
    </p>
  </div>

  <app-change-request-submission-table [requests]="failed" [action]="action"></app-change-request-submission-table>

  <div class="footer">
    <button type="button" class="orms-button orms-button-primary" (click)="close()">
      {{ 'general.done' | translate }}
    </button>

    <div>{{ 'changeRequest.approver' | translate }}: {{ approver }}</div>
  </div>
</div>

<ng-template #successfullySubmitted>
  <div class="orms-cr-submission-form" *ngIf="success.length === totalRequestNumber; else partialFailure">
    <h2>
      {{ (action === bulkAction.Approve ? 'changeRequest.crApproval' : 'changeRequest.crRejection') | translate }}
      <button (click)="close()" type="button" class="orms-close-button"></button>
    </h2>
    <div class="submission-result">
      <mat-icon class="success-icon icon">check_circle</mat-icon>
      <p class="message-result-container">
        {{
          (action === bulkAction.Approve ? 'changeRequest.bulkApproveSuccess' : 'changeRequest.bulkRejectSuccess')
            | translate
        }}
      </p>
    </div>

    <app-change-request-submission-table [requests]="success" [action]="action"></app-change-request-submission-table>

    <div class="footer">
      <div>
        <button type="button" class="orms-button orms-button-primary" (click)="close()">
          {{ 'general.done' | translate }}
        </button>
      </div>

      <div>{{ 'changeRequest.approver' | translate }}: {{ approver }}</div>
    </div>
  </div>
</ng-template>

<ng-template #partialFailure>
  <div
    class="orms-cr-submission-form"
    *ngIf="failed.length && failed.length !== totalRequestNumber && showPartialFailure; else partialSuccess"
  >
    <h2>
      {{
        (action === bulkAction.Approve ? 'changeRequest.submissionFailure' : 'changeRequest.rejectionFailure')
          | translate
      }}
      <button (click)="close()" type="button" class="orms-close-button"></button>
    </h2>
    <div class="submission-result">
      <mat-icon class="warning-icon icon">info</mat-icon>
      <p class="message-result-container">
        <b>({{ failed.length }})</b>
        {{ 'general.outOf' | translate }} {{ totalRequestNumber }}
        {{
          (action === bulkAction.Approve
            ? 'changeRequest.bulkPartialApproveFailure'
            : 'changeRequest.bulkPartialRejectFailure'
          ) | translate
        }}
      </p>
    </div>

    <app-change-request-submission-table [requests]="failed"></app-change-request-submission-table>

    <div class="footer">
      <div class="footer-actions">
        <button
          type="button"
          (click)="showPartialFailure = false"
          class="orms-button orms-button-primary button-with-icon"
        >
          <mat-icon>arrow_forward</mat-icon>
          {{ 'general.next' | translate }}
        </button>
      </div>

      <div>{{ 'changeRequest.approver' | translate }}: {{ approver }}</div>
    </div>
  </div>
</ng-template>

<ng-template #partialSuccess>
  <div class="orms-cr-submission-form">
    <h2>
      {{ (action === bulkAction.Approve ? 'changeRequest.crApproval' : 'changeRequest.crRejection') | translate }}
      <button (click)="close()" type="button" class="orms-close-button"></button>
    </h2>
    <div class="submission-result">
      <mat-icon class="success-icon icon">check_circle</mat-icon>
      <p class="message-result-container">
        <b>({{ success.length }})</b>
        {{ 'general.outOf' | translate }} {{ totalRequestNumber }}
        {{
          (action === bulkAction.Approve
            ? 'changeRequest.bulkPartialApproveSuccess'
            : 'changeRequest.bulkPartialRejectSuccess'
          ) | translate
        }}
      </p>
    </div>

    <app-change-request-submission-table [requests]="success" [action]="action"></app-change-request-submission-table>

    <div class="footer">
      <div class="footer-actions">
        <button
          type="button"
          (click)="showPartialFailure = true"
          class="orms-button orms-button-primary button-with-icon"
        >
          <mat-icon>arrow_back</mat-icon>
          {{ 'general.back' | translate }}
        </button>
        <button type="button" class="orms-button orms-button-secondary" (click)="close()">
          {{ 'general.done' | translate }}
        </button>
      </div>

      <div>{{ 'changeRequest.approver' | translate }}: {{ approver }}</div>
    </div>
  </div>
</ng-template>
