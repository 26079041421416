import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface Filter {
  id: number | string;
  name: string;
}

@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
})
export class SearchableDropdownComponent {
  @Input() list: Filter[];
  @Input() control: UntypedFormControl;
  @Input() placeholderText: string;
  @Input() placeholderSearchText: string;
  @Input() defaultValue: string;
  @Input() showSelectAll = false;
  @Output() filterChanged = new EventEmitter<any>();

  private filteredItems: Filter[];

  onOpen(): void {
    this.filteredItems = this.list;
  }

  onChangeFilter($event): void {
    if (Array.isArray($event)) this.filterChanged.emit($event);
  }

  onSearch(event): void {
    this.filteredItems = event.items;
  }

  onSelectAll() {
    this.filterChanged.emit(this.filteredItems != null ? this.filteredItems : this.list);
  }
}
