<div class="diagram-radial-tree" style="padding-bottom: 0px">
  <ejs-toolbar id="toolbar" (clicked)="onItemClick($event)">
    <e-items>
      <e-item
        [text]="'general.zoomIn' | translate"
        [tooltipText]="'general.zoomIn' | translate"
        id="ZoomIn"
        prefixIcon="e-ddb-icons e-zoomin"
      ></e-item>
      <e-item type="Separator"> </e-item>
      <e-item
        [text]="'general.zoomOut' | translate"
        [tooltipText]="'general.zoomOut' | translate"
        id="ZoomOut"
        prefixIcon="e-ddb-icons e-zoomout"
      ></e-item>
      <e-item type="Separator"> </e-item>
      <e-item
        [text]="'general.reset' | translate"
        [tooltipText]="'general.reset' | translate"
        id="Reset"
        prefixIcon="e-ddb-icons e-reset"
      ></e-item>
    </e-items>
  </ejs-toolbar>
  <div style="width: 100%">
    <div class="content-wrapper">
      <ejs-diagram
        #diagram
        id="diagram"
        width="100%"
        height="585px"
        [tool]="tool"
        [layout]="layout"
        [snapSettings]="snapSettings"
        (created)="create($event)"
        (dataLoaded)="onDataLoaded($event)"
        [dataSourceSettings]="data"
        [pageSettings]="pageSettings"
        [contextMenuSettings]="contextMenuSettings"
      >
        <ng-template #nodeTemplate let-el>
          <mat-card class="tile-container">
            <div class="tile-header">
              <span class="icon-container">
                <mat-icon
                  [ngClass]="{
                    'material-icons-outlined': el.data.iconOutlined
                  }"
                  >{{ el.data.icon }}</mat-icon
                >
              </span>
              <app-status-chip [statusDetails]="el.data.statusDetails"></app-status-chip>
            </div>
            <div class="tile-body">
              <div>{{ el.data.title }}</div>
              <div>{{ el.data.responsible }}</div>
            </div>
          </mat-card>
        </ng-template>
      </ejs-diagram>
    </div>
  </div>
</div>
