import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChangeRequestCartStateModule } from '../project-management/change-request-cart/change-request-cart-state/change-request-cart-state.module';
import { TreeStateModule } from '../project-management/tree-navigation/tree-state/tree-state.module';
import { ProjectStateModule } from '../shared-state/project/project-state.module';
import { reducers } from './root-store.reducer';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ChangeRequestCartStateModule,
    TreeStateModule,
    ProjectStateModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers),
  ],
})
export class RootStoreModule {}
