import { Pipe, PipeTransform } from '@angular/core';
import { TreeNode } from './tree-structure.model';

@Pipe({
  name: 'nodeIconType',
})
export class NodeIconTypePipe implements PipeTransform {
  transform(data: TreeNode): string {
    return NodeTypeIconMap.get(data.typeCode);
  }
}

const NodeTypeIconMap = new Map<string, string>([
  ['PR', '/assets/icons/project.svg'],
  ['FN', '/assets/icons/function.svg'],
  ['SF', '/assets/icons/sub-function.svg'],
  ['PK', '/assets/icons/package.svg'],
]);
