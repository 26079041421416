import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import moment from 'moment';
import { Subscription, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { FormGroupControls } from 'src/app/project-setup/project-workflows-setup/add-edit-workflow/add-edit-workflow.component';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import { dashDayDateFormat, timezoneUTC } from 'src/app/shared/date-options/short-date-format';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SharedPackageService } from '../shared.service';
import {
  CreateSharePackage,
  ManageSettings,
  ShareAccess,
  SharePackage,
  User,
  getActiveDays,
  getIsExpired,
} from './share.model';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit, OnDestroy {
  submitting = false;
  isNewLink = false;
  form: UntypedFormGroup;
  successForm: UntypedFormGroup;
  manageSettingForm: UntypedFormGroup;
  expires = new UntypedFormControl('');
  link = new UntypedFormControl('');
  showSuccess = false;
  showEdit = false;
  isEdit = false;
  isManageSettings = false;
  timezoneUTC = timezoneUTC;
  shortDateFormat = dashDayDateFormat;
  dataSource: MatLegacyTableDataSource<ShareAccess>;
  manageSettingsDataSource: MatLegacyTableDataSource<ManageSettings>;
  sharedWithDataSource: MatLegacyTableDataSource<User>;
  actions: any[];
  manageSettingsActions: any[];
  shareWithActions: any[];
  displayedColumns = ['peopleWithAccess', 'daysActive', 'expiryDate', 'publicUrl'];
  managedSettingsDisplayedColumns = ['createdBy', 'daysActive', 'expiryDate', 'publicUrl'];
  sharedWithDisplayedColumns = ['name', 'lastAccessDate', 'numberOfVisits'];

  datepickerActionsComponent = DatepickerActionsComponent;

  subscription = new Subscription();

  constructor(
    public dialogRef: MatLegacyDialogRef<ShareComponent>,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private shareService: SharedPackageService,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      projectId: number;
      packageId: number;
      share: SharePackage;
      entityUnderChangeTitle: string;
      createdBy: string;
    },
  ) {}

  ngOnInit(): void {
    this.calculateShowSuccess(this.data?.share?.id);
    this.actions = [
      {
        icon: () => '/assets/icons/settings.svg',
        label: 'share.manageSettings',
        callBack: () => this.manageSettings(),
        isDisabled: () => false,
        isSvg: true,
      },
      {
        icon: () => '/assets/icons/icon-grey-cancel.secondary-color.svg',
        label: 'share.disableLink',
        callBack: () => this.disableLink(),
        isDisabled: () => false,
        isSvg: true,
      },
    ];

    this.shareWithActions = [
      {
        icon: () => '/assets/icons/icon-grey-cancel.secondary-color.svg',
        label: 'share.disableLink',
        callBack: () => this.disableLink(),
        isDisabled: () => false,
        confirm: 'share.disableLinkConfirmation',
        confirmLabel: 'share.disable',
        isSvg: true,
      },
    ];

    this.manageSettingsActions = [
      {
        icon: () => '/assets/icons/icon-grey-cancel.secondary-color.svg',
        label: 'share.revokeAccess',
        callBack: (x) => this.revokeAccess(x.email),
        isDisabled: () => false,
        confirm: 'share.revokeAccessConfirmation',
        confirmLabel: 'share.revoke',
        isSvg: true,
      },
    ];

    this.dataSource = new MatLegacyTableDataSource([
      {
        id: this.data.share.id,
        peopleWithAccess: this.data.share?.users ?? [],
        daysActive: this.data.share.id ? getActiveDays(this.data.share.createdAtUtc) : null,
        expiryDate: this.data.share.expirationDate,
        isExpired: getIsExpired(this.data.share.expirationDate),
        publicUrl: this.combineLinkIdWithAppUrl(this.data.share.linkIdentifier),
      },
    ]);

    this.manageSettingsDataSource = new MatLegacyTableDataSource([
      {
        createdBy: this.data.share.createdBy,
        daysActive: this.data.share.id ? getActiveDays(this.data.share.createdAtUtc) : null,
        expiryDate: this.data.share.expirationDate,
        isExpired: getIsExpired(this.data.share.expirationDate),
        publicUrl: this.combineLinkIdWithAppUrl(this.data.share.linkIdentifier),
      },
    ]);

    this.sharedWithDataSource = new MatLegacyTableDataSource(this.data.share?.users);

    this.form = this.fb.group({
      emails: new UntypedFormControl('', [Validators.required, this.emailsValidator]),
      expires: new UntypedFormControl('', [Validators.required]),
    });

    this.successForm = this.fb.group({
      link: new UntypedFormControl(
        this.data?.share?.linkIdentifier ? this.combineLinkIdWithAppUrl(this.data.share.linkIdentifier) : null,
      ),
      addEmails: new UntypedFormControl('', [Validators.required, this.emailsValidator]),
    });

    this.manageSettingForm = this.fb.group({
      link: new UntypedFormControl(
        this.data?.share?.linkIdentifier ? this.combineLinkIdWithAppUrl(this.data.share.linkIdentifier) : null,
      ),
      expires: new UntypedFormControl(this.data?.share?.expirationDate),
    });
  }

  generateNewLink() {
    this.isManageSettings = false;
    this.isNewLink = false;
    this.showEdit = false;

    this.data.share = {
      users: [],
      id: null,
      enabled: null,
      expirationDate: null,
      createdAtUtc: null,
      linkIdentifier: null,
      packageId: null,
      projectId: null,
      createdBy: null,
    };
    this.form = this.fb.group({
      emails: new UntypedFormControl('', [Validators.required, this.emailsValidator]),
      expires: new UntypedFormControl('', [Validators.required]),
    });
  }

  manageSettings() {
    this.isManageSettings = true;
  }

  backToShare() {
    this.isManageSettings = false;
  }

  disableLink() {
    this.shareService.disableLink(this.data.share.id).subscribe(() => {
      this.isManageSettings = true;
      this.isNewLink = true;
      this.data.share = {
        users: [],
        id: null,
        enabled: null,
        expirationDate: null,
        createdAtUtc: null,
        linkIdentifier: null,
        packageId: null,
        projectId: null,
        createdBy: null,
      };
    });
  }

  revokeAccess(email: string) {
    this.shareService.revokeAccess(this.data.share.id, email).subscribe(() => {
      this.sharedWithDataSource.data = [...this.sharedWithDataSource.data.filter((user) => user.email !== email)];
      this.dataSource.data[0].peopleWithAccess = [
        ...this.dataSource.data[0]?.peopleWithAccess.filter((user) => user.email !== email),
      ];

      this.data.share.users = [...this.sharedWithDataSource.data.filter((user) => user.email !== email)];

      if (!this.sharedWithDataSource.data.length) {
        this.isManageSettings = true;
        this.isNewLink = true;
        this.data.share = this.createEmptySharedPackage();
        this.enableValidators(this.successForm, 'addEmails', [Validators.required, this.emailsValidator]);
      }
    });
  }

  grantAccess() {
    if (this.successForm.invalid) {
      return;
    }

    const peopleWithAccess = this.dataSource.data[0].peopleWithAccess;
    const addedEmails = this.successForm.get('addEmails').value;
    const processed = this.extractEmails(addedEmails);

    this.successForm.get('addEmails').setValue('');

    of('')
      .pipe(
        tap(() => this.disableValidators(this.successForm, 'addEmails')),
        filter(() => !!processed?.length),
        switchMap(() =>
          this.shareService.addUsersToPackageLink({
            projectId: this.data.share.projectId,
            linkId: this.data.share.id,
            emails: processed,
          }),
        ),
        take(1),
      )
      .subscribe(
        (s) => {
          this.dataSource.data[0].peopleWithAccess = [
            ...peopleWithAccess,
            ...processed.map((email) => ({
              email,
              accessedCount: 0,
              lastAccessed: null,
            })),
          ];
          this.sharedWithDataSource.data = [
            ...peopleWithAccess,
            ...processed.map((email) => ({
              email,
              accessedCount: 0,
              lastAccessed: null,
            })),
          ];

          this.data.share.users = [...this.sharedWithDataSource.data];
        },
        () => {
          if (!this.data.share.users.length) {
            this.enableValidators(this.successForm, 'addEmails', [Validators.required, this.emailsValidator]);
          }
        },
      );
  }

  private enableValidators(form: UntypedFormGroup, fieldName: string, validators: ValidatorFn[]) {
    form.get(fieldName).setValidators(validators);
    this.successForm.get(fieldName).updateValueAndValidity();
  }

  private disableValidators(form: UntypedFormGroup, fieldName: string) {
    form.get(fieldName).clearValidators();
    this.successForm.get(fieldName).updateValueAndValidity();
  }

  private removeDuplicates(userEmail: string) {
    return !this.dataSource.data[0].peopleWithAccess.some((person) => person.email === userEmail);
  }

  emailsValidator(control: AbstractControl): ValidationErrors | null {
    if (
      control.value === '' ||
      !control.value ||
      control.value
        .split(/,| /)
        .map((email) => Validators.email(<AbstractControl>{ value: email }))
        .find((_) => _ !== null) === undefined
    ) {
      return null;
    }
    return { emails: true };
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);

    return control.invalid && (control.dirty || control.touched || this.submitting);
  }

  onSubmit(): void {
    const errors = this.getFormValidationErrors(this.form.controls);

    if (errors.length) {
      errors.forEach((error) => {
        let text;
        switch (error.error_name) {
          case 'required':
            text = error.control_name === 'emails' ? 'share.emailsRequiredError' : 'share.expirationDateRequiredError';
            break;
          case 'emails':
            text = 'share.invalidEmailError';
            break;
          default:
            text = `${error.control_name}: ${error.error_name}: ${error.error_value}`;
        }
        this.notificationService.error(text);
      });
      return;
    }

    if (!this.form.invalid) {
      const sharePackage: CreateSharePackage = {
        projectId: this.data.projectId,
        packageId: this.data.packageId,
        expirationDate: this.endOfTheDayUtc(this.form.get('expires').value).toDate(),
        emails: this.extractEmails(this.form.get('emails').value),
      };

      this.submitting = true;

      of('')
        .pipe(
          switchMap(() => (sharePackage.emails?.length ? this.shareService.addPackageLink(sharePackage) : null)),
          take(1),
        )
        .subscribe(
          (shareResponse) => {
            this.submitting = false;
            if (shareResponse) {
              this.data.share = shareResponse;
              this.expires.setValue(shareResponse.expirationDate);
              this.dataSource = new MatLegacyTableDataSource([
                {
                  id: shareResponse.id,
                  peopleWithAccess: shareResponse.users,
                  daysActive: 0,
                  expiryDate: shareResponse.expirationDate,
                  isExpired: getIsExpired(shareResponse.expirationDate),
                  publicUrl: this.combineLinkIdWithAppUrl(shareResponse.linkIdentifier),
                },
              ]);
              this.successForm.get('link').setValue(this.combineLinkIdWithAppUrl(shareResponse.linkIdentifier));
              this.manageSettingForm.get('link').setValue(this.combineLinkIdWithAppUrl(shareResponse.linkIdentifier));

              this.setExpiration(shareResponse.expirationDate);
              this.calculateShowSuccess(shareResponse.id);

              this.manageSettingsDataSource.data = [
                {
                  createdBy: shareResponse.createdBy,
                  daysActive: shareResponse.id ? getActiveDays(shareResponse.createdAtUtc) : null,
                  expiryDate: shareResponse.expirationDate,
                  isExpired: getIsExpired(shareResponse.expirationDate),
                  publicUrl: this.combineLinkIdWithAppUrl(shareResponse.linkIdentifier),
                },
              ];
              this.sharedWithDataSource.data = [...shareResponse.users];
            } else {
              this.calculateShowSuccess(shareResponse.id);
              this.dialogRef.close(true);
            }
          },
          () => {
            this.submitting = false;
          },
        );

      this.showSuccess = true;
    }
  }

  private setExpiration(expirationDate: Date) {
    this.manageSettingForm.get('expires').setValue(expirationDate);
    this.dataSource.data[0].expiryDate = expirationDate;
    this.data.share.expirationDate = expirationDate;

    this.dataSource.data[0].isExpired = getIsExpired(expirationDate);
    this.manageSettingsDataSource.data[0].isExpired = getIsExpired(expirationDate);
  }

  private extractEmails(emails: string) {
    return [
      ...new Set(
        emails
          .trim()
          .split(/,| /)
          .filter((email) => !!email.trim()),
      ),
    ].filter(this.removeDuplicates.bind(this));
  }

  changeExpiryDate(event: MatDatepickerInputEvent<any, any>) {
    this.shareService
      .changeExpirationDate(this.data.share.id, this.endOfTheDayUtc(event.value).format())
      .pipe(take(1))
      .subscribe(() => {
        const expiryDate = this.endOfTheDayUtc(event.value).toDate();
        this.dataSource.data[0].expiryDate = expiryDate;
        this.data.share.expirationDate = expiryDate;

        this.dataSource.data[0].isExpired = getIsExpired(expiryDate);
        this.manageSettingsDataSource.data[0].isExpired = getIsExpired(expiryDate);
      });
  }

  private endOfTheDayUtc(date: Date): moment.Moment {
    return moment(date).clone().endOf('day');
  }

  getFormValidationErrors(controls: FormGroupControls): any[] {
    let errors = [];
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      if (control instanceof UntypedFormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors = controls[key].errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push({
            control_name: key,
            error_name: keyError,
            error_value: controlErrors[keyError],
          });
        });
      }
    });
    return errors;
  }

  close() {
    this.dialogRef.close(this.data.share.id ? this.data.share : this.createEmptySharedPackage());
  }

  private calculateShowSuccess(id: number): void {
    this.showEdit = !!id;
  }

  private combineLinkIdWithAppUrl(linkIdentifier: string) {
    return `${window.location.origin}/#/project/${this.data.projectId}/share/${linkIdentifier}/package/${this.data.packageId}/detail`;
  }

  private createEmptySharedPackage() {
    return {
      users: [],
      id: null,
      enabled: null,
      expirationDate: null,
      createdAtUtc: null,
      linkIdentifier: null,
      packageId: null,
      projectId: null,
      createdBy: null,
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
