import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
  ) {}

  addUserFavoritePackage(projectId: number, functionalHierarchyPackageId: number): Observable<void> {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('functionalHierarchyPackageId', functionalHierarchyPackageId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const addUserFavoritePackageUrl = this.appConfigService.settings.api.endpoints.addUserFavoritePackage;
    return this.httpClient.post<void>(baseUrl + addUserFavoritePackageUrl, {}, { params });
  }

  removeUserFavoritePackage(projectId: number, functionalHierarchyPackageId: number): Observable<void> {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('functionalHierarchyPackageId', functionalHierarchyPackageId.toString());

    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const removeUserFavoritePackageUrl = this.appConfigService.settings.api.endpoints.removeUserFavoritePackage;
    return this.httpClient.delete<void>(baseUrl + removeUserFavoritePackageUrl, { params });
  }
}
