<form [formGroup]="form" class="provide-templatename-form" autocomplete="off">
  <h2>
    {{ 'projectTemplates.templateName' | translate }}
  </h2>
  <div class="template-name-form">
    <mat-form-field appearance="outline" class="orms-outline-input templateName">
      <mat-label>{{ 'projectTemplates.templateName' | translate }}</mat-label>
      <input
        autocomplete="off"
        matInput
        cdkFocusInitial
        placeholder="{{ 'projectTemplates.templateName' | translate }}"
        formControlName="templateName"
        [class.is-invalid]="showControlInvalidState('templateName')"
        maxlength="100"
      />
    </mat-form-field>
    <div class="alert">
      <ng-container *ngIf="getControl('templateName').invalid">
        <div *ngIf="getControl('templateName').errors?.['required']">
          {{ 'projectTemplates.templateNameRequired' | translate }}
        </div>
        <div *ngIf="getControl('templateName').errors?.['duplicateName']">
          {{ 'projectTemplates.duplicateName' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="orms-dialog-footer">
    <div class="orms-dialog-footer-actions">
      <button
        class="orms-button orms-button-primary submit-button"
        type="button"
        [disabled]="isSubmitDisabled"
        (click)="onSubmit()"
      >
        {{ 'general.continue' | translate }}
      </button>
      <button class="orms-button orms-button-secondary" type="button" (click)="cancel()">
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</form>
