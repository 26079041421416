import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripZeroTimeFromDate',
  pure: false,
})
export class StripZeroTimeFromDatePipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'string') {
      if (!value.length) {
        return '';
      }

      let positionOfT = value.indexOf('T00:00:00');
      if (positionOfT > 0) {
        return value.slice(0, positionOfT);
      }
    }
    return value;
  }
}
