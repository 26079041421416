<div class="feedback-list-container">
  <div *ngFor="let feedbackEntry of feedback">
    <ng-container *ngIf="feedbackEntry?.feedbackType">
      <span class="{{ feedbackEntry.feedbackType }}"
        >{{ feedbackEntry.feedbackTypeTranslationKey | translate | titlecase }}:
      </span>
      <span class="title-display-text">
        {{ feedbackEntry.translateCode | translate: feedbackEntry.translateParams }}
      </span>
    </ng-container>
  </div>
</div>
