import { createReducer, on } from '@ngrx/store';
import { ProjectDetails } from 'src/app/common-models/project.model';
import * as projectActions from './project.actions';

export interface ProjectState {
  details: ProjectDetails;
}

const initialState: ProjectState = {
  details: null,
};

export const projectReducer = createReducer(
  initialState,
  on(projectActions.clearProjectDetails, (state) => {
    return {
      ...state,
      details: null,
    };
  }),
  on(projectActions.getProjectDetailsSuccess, (state, { projectDetails }) => {
    return {
      ...state,
      details: projectDetails,
    };
  }),
);
