export enum HealthColor {
  None = 'none',
  Red = 'red',
  Yellow = 'yellow',
  Orange = 'orange',
  Green = 'green',
  Grey = 'grey',
  LightGrey = 'light-grey',
  Grey6 = 'grey-6',
  Grey5 = 'grey-5',
  LightGreen = 'light-green',
  DarkBlue = 'dark-blue',
  DarkGreen = 'dark-green',
  DarkYellow = 'dark-yellow',
  LightYellow = 'light-yellow',
  LightRed = 'light-red',
  DarkRed = 'dark-red',
  Grey600 = 'grey-600',
  DarkGrey = 'dark-grey',
}

export const HealthColorMap = new Map<number, HealthColor>([
  [0, HealthColor.None],
  [1, HealthColor.Red],
  [2, HealthColor.Yellow],
  [3, HealthColor.Orange],
  [4, HealthColor.Green],
  [5, HealthColor.Grey],
  [6, HealthColor.LightGrey],
  [7, HealthColor.Grey6],
  [8, HealthColor.Grey5],
  [9, HealthColor.LightGreen],
  [10, HealthColor.DarkBlue],
  [11, HealthColor.DarkGreen],
  [12, HealthColor.DarkYellow],
  [13, HealthColor.LightYellow],
  [14, HealthColor.LightRed],
  [15, HealthColor.DarkRed],
  [16, HealthColor.Grey600],
]);

export function getHealthValue(healthColor: HealthColor): number {
  return [...HealthColorMap.entries()].filter(({ 1: color }) => color === healthColor).map(([k]) => k)[0];
}
