import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { EditorOptions } from 'tinymce';
import { AppConfigService } from '../../../shared/services/app.config.service';
import { TreeActionsService } from '../tree-actions-menu/tree-actions.service';

@Component({
  selector: 'app-tree-action-publish-package',
  templateUrl: './tree-action-publish-package.component.html',
  styleUrls: ['./tree-action-publish-package.component.scss'],
})
export class TreeActionPublishNodeCrComponent {
  submissionInProgress = false;
  editorConfig: EditorOptions;

  form = new UntypedFormGroup({
    justification: new UntypedFormControl('', Validators.required),
  });

  constructor(
    appConfigService: AppConfigService,
    public dialogRef: MatLegacyDialogRef<TreeActionPublishNodeCrComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      title: string;
      functionalHierarchyId: number;
      successMessage: string;
    },
    private treeActionsService: TreeActionsService,
  ) {
    this.editorConfig = {
      ...appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...appConfigService.settings.richTextConfig.crJustificationOptions,
    };
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid;
  }

  confirm() {
    this.submissionInProgress = true;

    const { justification } = this.form.value;
    if (!justification) {
      return;
    }

    this.publishPackageCr(justification);
  }

  private publishPackageCr(reason: string) {
    this.treeActionsService
      .publishFunctionOrPackage(this.data.functionalHierarchyId, this.data.successMessage, reason)
      .subscribe({
        next: () => {
          this.finalize();
        },
        error: () => this.finalize(),
        complete: () => this.finalize(),
      });
  }

  finalize() {
    this.dialogRef.close();
    this.submissionInProgress = false;
  }
}
