<form class="search-form" autocomplete="off">
  <mat-form-field appearance="fill">
    <input
      matInput
      [placeholder]="placeholder"
      [matAutocomplete]="autoGroup"
      [formControl]="optionCtrl"
      (keydown)="onKeyPressed($event)"
      (focusout)="save()"
    />
    <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayFn">
      <mat-optgroup *ngFor="let option of filteredOptions | async" [label]="option.group">
        <mat-option *ngFor="let tag of option.tags" [value]="tag">
          <span>{{ tag.name }}</span>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</form>
