import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { getIdSelector, getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { PermissionEntry } from './permission-entry';
import { PermissionsService } from './permissions.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsComponent implements OnInit, OnDestroy {
  constructor(
    private store$: Store<AppState>,
    private permissionsService: PermissionsService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  nodeId: number;
  projectId: number;
  permissions: any;
  private subscription = new Subscription();
  private permissionsLoadSubscription = new Subscription();

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.permissionsLoadSubscription.unsubscribe();
  }

  private loadData(): void {
    this.permissionsLoadSubscription.unsubscribe();
    this.permissionsLoadSubscription = this.store$
      .select(getIdSelector)
      .pipe(
        filter((nodeId) => nodeId != null),
        withLatestFrom(this.store$.select(getProjectIdSelector)),
        switchMap(([nodeId, projectId]) => {
          this.nodeId = nodeId;
          this.projectId = projectId;
          return this.permissionsService.getPermissions(nodeId);
        }),
        map((permissions) => {
          this.permissions = permissions;
        }),
        tap(() => this.changeDetector.markForCheck()),
      )
      .subscribe();
  }

  grantPermission({ userIds, type }): void {
    const sub = this.permissionsService.grantPermission(userIds, type, this.nodeId).subscribe(() => this.loadData());
    this.subscription.add(sub);
  }

  revokePermission(permission: PermissionEntry): void {
    const sub = this.permissionsService
      .revokePermission(permission.applicationUserId, this.nodeId)
      .subscribe(() => this.loadData());
    this.subscription.add(sub);
  }

  changePermission({ permission, permissionType }): void {
    this.grantPermission({
      userIds: [permission.applicationUserId],
      type: permissionType,
    });
  }
}
