import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-loading-overlay',
  template: `<app-spinner></app-spinner>`,
})
export class CustomLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  private params: any;

  agInit(params): void {
    this.params = params;
  }
}
