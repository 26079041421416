import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ViewState } from 'src/app/project-management/common/navigation-tabs/model';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Directive({
  // eslint-disable-next-line
  selector: '[resizeColumn]',
})
export class ResizeColumnDirective implements OnInit, OnDestroy {
  // eslint-disable-next-line
  @Input('resizeColumn') resizable: string;
  // eslint-disable-next-line
  @Input('sessionStorageKey') sessionStorageKey: string;

  @Input() index: number;

  private startX: number;
  private startWidth: number;
  private tableWidth: number;
  private column: HTMLElement;
  private table: HTMLElement;
  private pressed: boolean;

  private mouseDownUnlistener: () => void;
  private mouseMoveUnlistener: () => void;
  private mouseUpUnlistener: () => void;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private sessionStorageService: SessionStorageService,
  ) {
    this.column = this.el.nativeElement;
  }

  ngOnInit(): void {
    const row = this.renderer.parentNode(this.column);
    const thead = this.renderer.parentNode(row);
    const viewState = this.sessionStorageService.getItem<ViewState>(this.sessionStorageKey);
    if (viewState) {
      this.renderer.setStyle(this.column, 'width', `${viewState.columns[this.resizable]}`);
    }

    this.table = this.renderer.parentNode(thead);

    const resizeHandler = this.renderer.createElement('span');
    this.renderer.addClass(resizeHandler, 'resize-holder');
    this.renderer.appendChild(this.column, resizeHandler);
    this.mouseDownUnlistener = this.renderer.listen(resizeHandler, 'mousedown', this.onMouseDown);
  }

  ngOnDestroy(): void {
    if (this.mouseDownUnlistener) {
      this.mouseDownUnlistener();
    }
  }

  onMouseDown = (event: MouseEvent) => {
    this.mouseMoveUnlistener = this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
    this.mouseUpUnlistener = this.renderer.listen('document', 'mouseup', this.onMouseUp);
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
    this.tableWidth = this.table.offsetWidth;
  };

  onMouseMove = (event: MouseEvent) => {
    const offset = 0;
    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, 'resizing');

      const width = this.startWidth + (event.pageX - this.startX - offset);
      if (width < 30) {
        return;
      }
      const tableCells = Array.from(this.table.querySelectorAll('.mat-row')).map((row: any) =>
        row.querySelectorAll('.mat-cell').item(this.index),
      );
      this.renderer.setStyle(this.column, 'width', `${width}px`);
      for (const cell of tableCells) {
        this.renderer.setStyle(cell, 'width', `${width}px`);
      }
      this.renderer.setStyle(this.table, 'width', `${this.tableWidth + (event.pageX - this.startX - offset)}px`);
    }
  };

  onMouseUp = () => {
    if (this.pressed) {
      this.pressed = false;
      if (this.resizable) {
        const viewState = this.sessionStorageService.getItem<ViewState>(this.sessionStorageKey);
        if (viewState) {
          viewState.columns[this.resizable] = `${this.column.offsetWidth}px`;
          this.sessionStorageService.setItem(this.sessionStorageKey, viewState);
        }
      }
      this.renderer.removeClass(this.table, 'resizing');
      this.mouseMoveUnlistener();
      this.mouseUpUnlistener();
    }
  };
}
