import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import * as currentContextActions from './current-context-function.actions';

@Injectable()
export class CurrentContextFunctionEffects {
  constructor(
    private actions: Actions,
    private store$: Store<AppState>,
  ) {}

  setCurrentContextFunctionForDeliverableAndPackageEffect = createEffect(() =>
    this.actions.pipe(
      ofType(
        currentContextActions.setCurrentContextFunctionForPackage,
        currentContextActions.setCurrentContextFunctionForDeliverable,
      ),
      switchMap((action) =>
        this.store$.select(TreeSelectors.getParentId({ id: action.packageHierarchyId })).pipe(
          map((functionId) => {
            return currentContextActions.setCurrentContextFunction({
              functionHierarchyId: functionId,
            });
          }),
        ),
      ),
    ),
  );
}
