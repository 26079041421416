<cdk-virtual-scroll-viewport class="history-list" tvsItemSize="64">
  <table mat-table [dataSource]="dataSource" attr.aria-label="{{ 'general.history' | translate }}">
    <ng-container matColumnDef="occurredAt">
      <th mat-header-cell class="table-date" *matHeaderCellDef scope="col">
        {{ 'changeRequest.eventDate' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.occurredAt | date: shortDateFormat }}</div>
        <div class="pale">{{ element.occurredAt | date: timeFormat }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef scope="col">
        {{ 'changeRequest.description' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <button type="button" class="orms-link-button" (click)="showHistoryDetails(element)">
          {{ element | historyChangeSummary: entityType }}
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="historyColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: historyColumns"></tr>
  </table>
  <app-spinner *ngIf="loading"></app-spinner>
</cdk-virtual-scroll-viewport>
