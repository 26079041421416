import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import { ChangeRequestListService } from '../../common/navigation-tabs/change-request-list/change-request-list.service';
import { BulkAction } from '../change-requests-pending-approval/change-requests-pending-approval.component';
import { PendingChangeRequest } from '../change-requests-pending-approval/penging-change-request.model';
import { BulkResult } from './bulk.model';

@Component({
  selector: 'app-bulk-action-submission',
  templateUrl: './bulk-action-submission.component.html',
  styleUrls: ['./bulk-action-submission.component.scss'],
})
export class BulkActionSubmissionComponent implements OnInit {
  submitting = false;
  successForm: UntypedFormGroup;
  showSubmissionResult = false;
  datepickerActionsComponent = DatepickerActionsComponent;
  subtitle = '';
  bulkAction = BulkAction;
  success = [];
  failed = [];

  form = new UntypedFormGroup(
    {
      reason: new UntypedFormControl('', Validators.required),
    },
    { updateOn: 'change' },
  );

  constructor(
    public dialogRef: MatLegacyDialogRef<BulkActionSubmissionComponent>,
    private crListService: ChangeRequestListService,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      projectId: number;
      action: any;
      requests: PendingChangeRequest[];
      approver: string;
      callback: any;
    },
  ) {}

  ngOnInit(): void {
    this.data.approver = this.data.requests.map((x) => x.approver)[0];
  }

  onSubmit(): void {
    if (this.form.invalid && this.data.action === BulkAction.Reject) {
      return;
    }

    this.submitting = true;

    of('')
      .pipe(
        switchMap(() =>
          this.data.action === BulkAction.Approve
            ? this.crListService.bulkApprove(
                this.data.requests.map((x) => ({
                  changeNoticeId: x.changeNoticeId,
                })),
              )
            : this.crListService.bulkReject({
                changeNoticeIds: this.data.requests.map((x) => x.changeNoticeId),
                reason: this.form.get('reason').value,
              }),
        ),
        take(1),
      )
      .subscribe(({ failed }: BulkResult) => {
        this.submitting = false;
        this.showSubmissionResult = true;

        this.failed = this.data.requests
          .filter((x) => failed.some((res) => res.id === x.changeNoticeId))
          .map((req) => {
            const cn = failed.find((res) => res.id === req.changeNoticeId);

            return { ...req, message: cn.message };
          });

        this.success = this.data.requests.filter((x) => !failed.some((res) => res.id === x.changeNoticeId));
      });
  }

  close() {
    this.dialogRef.close();
  }

  done() {
    this.data
      .callback()
      .pipe(take(1))
      .subscribe((crs) => {
        this.dialogRef.close(crs);
      });
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched || this.submitting);
  }
}
