import { GlobalConfig } from 'ngx-toastr';
import { ToastComponent } from 'src/app/shared-controls/toast/toast.component';

export const globalToastOptions: Partial<GlobalConfig> = {
  timeOut: 5000,
  positionClass: 'toast-bottom-left',
  toastClass: 'orms-toast',
  autoDismiss: true,
  toastComponent: ToastComponent,
};
