import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { setCurrentUserWithPortfolioPermission } from 'src/app/root-store/root-store.actions';
import {
  canUserManagePortfolio,
  getProjectIdSelector,
  getUserLastPortfolio,
} from 'src/app/root-store/root-store.selector';
import { clearProjectDetails } from 'src/app/shared-state/project/project.actions';
import { CurrentUserService } from 'src/app/shared/current-user/current-user.service';
import { ProjectService } from 'src/app/shared/services/project-service';
import { PortfolioService } from '../../shared/services/portfolio.service';
import { AddProjectComponent } from '../project/add-project/add-project.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  canManagePortfolio$: Observable<boolean>;
  subscription = new Subscription();

  portfolioId: number;
  constructor(
    private dialog: MatLegacyDialog,
    private store$: Store<AppState>,
    private currentUserService: CurrentUserService,
    private portfolioService: PortfolioService,
    private projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    this.currentUserService.currentUser$
      .pipe(
        map((currentUser) => {
          this.store$.dispatch(
            setCurrentUserWithPortfolioPermission({
              profile: currentUser,
              canManagePortfolio: this.portfolioService.canUserManagePortfolio(),
            }),
          );
        }),
        take(1),
      )
      .subscribe();

    const isCurrentUserProjectManagerSub = this.store$
      .select(getProjectIdSelector)
      .pipe(
        switchMap((projectId) => {
          if (projectId) {
            return this.projectService.canCurrentUserManageProject(projectId);
          }
          return of(false);
        }),
      )
      .subscribe((canCurrentUserManageProject) => {
        this.projectService.canCurrentUserManageProject$.next(canCurrentUserManageProject);
      });

    this.subscription.add(isCurrentUserProjectManagerSub);

    this.canManagePortfolio$ = this.store$.select(canUserManagePortfolio);

    this.store$
      .select(getUserLastPortfolio)
      .pipe(filter((portfolioId) => !!portfolioId))
      .subscribe((portfolioId) => (this.portfolioId = portfolioId));

    this.store$.dispatch(clearProjectDetails());
  }

  addProject(): void {
    this.dialog.open(AddProjectComponent, {
      panelClass: 'orms-add-project',
      disableClose: false,
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
