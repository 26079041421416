import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeDebug',
})
export class PipeDebugPipe implements PipeTransform {
  transform(value: any) {
    console.log(value);

    return value;
  }
}
