import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, maxSize: number): unknown {
    if (maxSize == null) {
      return value;
    }

    if (value && value.length > maxSize) {
      return value.substring(0, maxSize) + '...';
    } else {
      return value;
    }
  }
}
