import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Tag } from 'src/app/common-models/tag.model';
import { AddDeliverable, AddTag, DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';
import { Detail } from '../../common/navigation-tabs/detail/detail.model';

export enum DeliverableActionTypes {
  GET_DELIVERABLE_BY_ID = '[Deliverable] Get deliverable by ID',
  GET_VIEW_DELIVERABLE_BY_ID = '[Deliverable] Get view deliverable by ID',
  GET_DELIVERABLE_BY_ID_SUCCESS = '[Deliverable] Get deliverable by ID Success',
  UPDATE_DELIVERABLE_PROPERTY = '[Deliverable] Update Deliverable property',
  UPDATE_DELIVERABLE_PROPERTY_SUCCESS = '[Deliverable] Update Deliverable property Success',
  UPDATE_DELIVERABLE = '[Deliverable] Update Deliverable',
  UPDATE_DELIVERABLE_SUCCESS = '[Deliverable] Update Deliverable success',
  GET_TAGS_FOR_DELIVERABLE = '[Deliverable] Get tags for deliverable',
  GET_TAGS_FOR_DELIVERABLE_SUCCESS = '[Deliverable] Get tags for deliverable Success',
  ADD_DELIVERABLE = '[Deliverable] Add deliverable',
  ADD_DELIVERABLE_SUCCESS = '[Deliverable] Add deliverable Success',
  ADD_TAG = '[Deliverable] Add tag',
  ADD_TAG_SUCCESS = '[Deliverable] Add tag Success',
  REMOVE_TAG = '[Deliverable] Remove tag',
  REMOVE_TAG_SUCCESS = '[Deliverable] Remove tag Success',
  DELIVERABLE_NO_OP = '[Deliverable] No op action',
  Add_PACKAGE_TO_USER_FAVORITES = '[Package] Add to favorites',
  Add_PACKAGE_TO_USER_FAVORITES_SUCCESS = '[Package] Add to favorites Success',
  REMOVE_PACKAGE_FROM_USER_FAVORITES = '[Package] Remove from favorites',
  REMOVE_PACKAGE_FROM_USER_FAVORITES_SUCCESS = '[Package] Remove from favorites Success',
}

export const getDeliverableById = createAction(
  DeliverableActionTypes.GET_DELIVERABLE_BY_ID,
  props<{ deliverableId: number; projectId: number; forceRefresh?: boolean }>(),
);

export const getViewDeliverableById = createAction(
  DeliverableActionTypes.GET_VIEW_DELIVERABLE_BY_ID,
  props<{ deliverableId: number; linkIdentifier: string }>(),
);

export const getDeliverableByIdSuccess = createAction(
  DeliverableActionTypes.GET_DELIVERABLE_BY_ID_SUCCESS,
  props<{ deliverable: DeliverableDetail }>(),
);

export const updateDeliverableProperty = createAction(
  DeliverableActionTypes.UPDATE_DELIVERABLE_PROPERTY,
  props<{
    key: string;
    update: Update<DeliverableDetail>;
    parentId: number;
  }>(),
);

export const updateDeliverable = createAction(
  DeliverableActionTypes.UPDATE_DELIVERABLE,
  props<{ deliverable: Detail }>(),
);

export const updateDeliverableSuccess = createAction(
  DeliverableActionTypes.UPDATE_DELIVERABLE_SUCCESS,
  props<{ deliverable: DeliverableDetail }>(),
);

export const addDeliverable = createAction(
  DeliverableActionTypes.ADD_DELIVERABLE,
  props<{ deliverable: AddDeliverable }>(),
);

export const addDeliverableSuccess = createAction(
  DeliverableActionTypes.ADD_DELIVERABLE_SUCCESS,
  props<{ deliverable: DeliverableDetail }>(),
);

export const addDeliverableTag = createAction(DeliverableActionTypes.ADD_TAG, props<{ tag: AddTag }>());

export const addDeliverableTagSuccess = createAction(DeliverableActionTypes.ADD_TAG_SUCCESS, props<{ tag: Tag }>());

export const removeDeliverableTag = createAction(
  DeliverableActionTypes.REMOVE_TAG,
  props<{ deliverableId: number; tagId: number; projectId: number }>(),
);

export const removeDeliverableTagSuccess = createAction(
  DeliverableActionTypes.REMOVE_TAG_SUCCESS,
  props<{ tagId: number }>(),
);

export const addPackageToFavorites = createAction(
  DeliverableActionTypes.Add_PACKAGE_TO_USER_FAVORITES,
  props<{ projectId: number; functionalHierarchyPackageId: number }>(),
);

export const removePackageFromFavorites = createAction(
  DeliverableActionTypes.REMOVE_PACKAGE_FROM_USER_FAVORITES,
  props<{ projectId: number; functionalHierarchyPackageId: number }>(),
);

export const deliveryNoOpAction = createAction(DeliverableActionTypes.DELIVERABLE_NO_OP);
