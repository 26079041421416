import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CodeTypes } from '../common-models/node-types/node-types';
import { DeliverableDetail } from '../project-management/common/navigation-tabs/deliverables/DeliverableDetail';
import { DeliverablesService } from '../project-management/common/navigation-tabs/deliverables/deliverables.service';
import { NavigationService } from '../project-management/tree-navigation/navigation.service';
import { AppState } from '../root-store/app-state';
import * as routerSelectors from '../root-store/root-store.selector';
import { BreadcrumbOption } from '../shared-controls/breadcrumbs/breadcrumbs.interfaces';
import { StatusCodeDetail, getStatusDetail } from '../shared-controls/status/statusCodesMap';

interface PackageInfo {
  title: string;
  startDate: Date;
  endDate: Date;
  actualComplete: number;
  plannedComplete: number;
  criticalPackage: boolean;
  manager: string;
  status: number;
  packagePriority: boolean;
}

@Component({
  selector: 'app-print-node',
  templateUrl: './print-node.component.html',
  styleUrls: ['./print-node.component.scss'],
})
export class PrintNodeComponent implements OnInit, OnDestroy {
  headerDetails: DeliverableDetail;
  packageDetails: PackageInfo;
  statusDetails: StatusCodeDetail;
  packageStatusDetails: StatusCodeDetail;
  breadcrumbOptions: BreadcrumbOption[];
  subscription = new Subscription();
  header: any;
  notes: any;

  constructor(
    private store$: Store<AppState>,
    private deliverableService: DeliverablesService,
    private navigationService: NavigationService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.header = document.querySelectorAll('app-header')[0];
    this.hideHeader();
    const deliverableDetailsSubscription = this.store$
      .select(routerSelectors.getIdSelector)
      .pipe(switchMap((deliverableId) => this.deliverableService.getDeliverableByIdForPrint(deliverableId)))
      .subscribe(({ deliverable, path, projectId }) => {
        this.headerDetails = deliverable;
        this.breadcrumbOptions = this.getBreadcrumbsOptions(path, projectId, deliverable);
        this.statusDetails = getStatusDetail(deliverable?.status);
        this.packageDetails = path.find((x) => x.typeCode === CodeTypes.Package);
        this.packageStatusDetails = getStatusDetail(this.packageDetails.status);
        this.notes = this.headerDetails.notes ? this.sanitizer.bypassSecurityTrustHtml(this.headerDetails.notes) : '';
        setTimeout(() => {
          window.print();
        }, 500);
      });

    this.subscription.add(deliverableDetailsSubscription);
  }

  private getBreadcrumbsOptions(functionalHierarchyPath, projectId, deliverable): any {
    let breadcrumbs: BreadcrumbOption[] = functionalHierarchyPath.map((fh) => ({
      title: fh.title,
      url: this.navigationService.getDestinationUrl(projectId, fh.functionalHierarchyId, fh.typeCode),
      icon: fh.typeCode,
      invalid: fh.typeCode === CodeTypes.Package && !fh.isPackageValid,
      isDisabled: true,
    }));
    return breadcrumbs;
  }

  private hideHeader() {
    if (this.header?.style?.display !== undefined) {
      this.header.style.display = 'none';
    }
  }

  private showHeader() {
    if (this.header?.style?.display !== undefined) {
      this.header.style.display = 'block';
    }
  }

  ngOnDestroy(): void {
    this.showHeader();
    this.subscription?.unsubscribe();
  }
}
