import { DeliverableStatus, StatusCode } from '../../shared-controls/status/statusCodesMap';
import { TreeNode } from '../tree-navigation/tree-structure.model';

export class NodeHeaderModel {
  nodeId: number;
  title: string;
  code: string;
  typeCode: string;
  statusCode: string;
  isFavorite: boolean;
  status: StatusCode | DeliverableStatus;
  managerName: string;
  managerId: number;
  plannedComplete: number;
  actualComplete: number;
  spi: number;
  health: number;
  viewPermission: boolean;
  updatePermission: boolean;
  managePermission: boolean;
  editableFields: string[];
  packageId: number;
  underChangeControl: boolean;

  public static FromTreeNode(input: TreeNode): NodeHeaderModel {
    const model = new NodeHeaderModel();
    model.nodeId = input.functionalHierarchyId;
    model.typeCode = input.typeCode;
    model.code = input.code;
    model.title = input.title;
    model.managerName = input.manager;
    model.managerId = input.managerId;
    model.status = input.status;
    model.plannedComplete = input.plannedComplete;
    model.actualComplete = input.actualComplete;
    model.spi = input.spi;
    model.health = input.health;
    model.viewPermission = input.viewPermission;
    model.updatePermission = input.updatePermission;
    model.managePermission = input.managePermission;
    model.editableFields = input.editableFields;
    model.packageId = input.packageId;
    model.isFavorite = input.isFavoritePackage;
    model.underChangeControl = input.underChangeControl;
    return model;
  }
}

export enum NodeHeaderKeys {
  code = 'code',
}
