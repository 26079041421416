import { TreeNode } from '../tree-navigation/tree-structure.model';

export interface ISignalRResponse {
  projectId: number;
  subject: SignalRSubject;
  action: SignalRAction;
  functionalHierarchyIds: number[];
  treeNode: TreeNode;
}

export enum SignalRSubject {
  KPI = 'KPI',
  Curve = 'Curve',
  Node = 'Node',
  Deliverable = 'Deliverable',
  Project = 'Project',
}

export enum SignalRAction {
  New = 'New',
  Change = 'Change',
  Delete = 'Delete',
  LogoChanged = 'LogoChanged',
  Move = 'Move',
  Reload = 'Reload',
}
