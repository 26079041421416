import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/root-store/app-state';
import { getProjectIdSelector } from 'src/app/root-store/root-store.selector';
import { WizardData } from 'src/app/shared-controls/wizard/wizard.component';
import { ChooseSourceStepComponent } from './choose-source-step/choose-source-step.component';
import { ImportConfirmationStepComponent } from './import-confirmation-step/import-confirmation-step.component';
import { ProjectImportMode } from './project-import.models';
import { ReviewImportStepComponent } from './review-import-step/review-import-step.component';

@Component({
  selector: 'app-project-import',
  templateUrl: './project-import.component.html',
  styleUrls: ['./project-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectImportComponent implements OnInit {
  wizardData$: Observable<WizardData>;

  projectTitle: string;

  getImportOperationWizardData = (
    projectId: number,
    projectTitle: string,
    navigateBackTo: () => void,
    navigateToAccessDenied: () => void,
  ): WizardData => ({
    wizardTitle: 'projectImport.projectImport',
    projectTitle,
    onCancelAction: navigateBackTo,
    onCompleteAction: navigateBackTo,
    context: {
      projectId,
      projectTitle,
      mode: ProjectImportMode.Import,
      onAccessDeniedAction: navigateToAccessDenied,
    },
    steps: [
      {
        label: 'projectImport.chooseSourceStepLabel',
        visited: false,
        title: 'projectImport.chooseSourceStepTitle',
        subTitle: 'projectImport.chooseSourceStepSubTitle',
        content: ChooseSourceStepComponent,
      },
      {
        label: 'projectImport.reviewImportStepLabel',
        visited: false,
        title: 'projectImport.reviewImportStepTitle',
        subTitle: 'projectImport.reviewImportStepSubTitle',
        content: ReviewImportStepComponent,
      },
      {
        label: 'projectImport.importConfirmationStepLabel',
        visited: false,
        title: 'projectImport.importConfirmationStepTitle',
        content: ImportConfirmationStepComponent,
      },
    ],
  });

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    this.projectTitle = currentNavigationState?.projectTitle;
  }

  ngOnInit(): void {
    if (!this.projectTitle) {
      this.navigateBackToProjectManagement();
    }

    this.wizardData$ = this.store$
      .select(getProjectIdSelector)
      .pipe(
        map((projectId) =>
          this.getImportOperationWizardData(
            projectId,
            this.projectTitle,
            this.navigateBackToProjectManagement,
            this.navigateToAccessDenied,
          ),
        ),
      );
  }

  private navigateBackToProjectManagement = () => {
    this.router.navigate(['..'], { relativeTo: this.route });
  };

  private navigateToAccessDenied = () => {
    this.router.navigate(['/403'], {
      skipLocationChange: true,
      state: {
        isProjectImport: true,
      },
    });
  };
}
