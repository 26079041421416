<mat-menu #buildMenu="matMenu" [overlapTrigger]="false" class="tree-menu orms-menu">
  <ng-container *ngFor="let action of items">
    <ng-container *ngIf="action.children">
      <button mat-menu-item [routerLink]="action.link" [matMenuTriggerFor]="menu.buildMenu">
        <span class="icon-container" *ngIf="action.icon; else noIcon">
          <img class="icon" [src]="action.icon" alt="{{ 'imgAlt.menuIcon' | translate }}" />
        </span>
        {{ action.label | translate }}
      </button>
      <app-menu #menu [items]="action.children"></app-menu>
    </ng-container>
    <ng-container *ngIf="!action.children">
      <button mat-menu-item [routerLink]="action.link">
        <span class="icon-container" *ngIf="action.icon; else noIcon">
          <img class="icon" [src]="action.icon" alt="{{ 'imgAlt.menuIcon' | translate }}" />
        </span>
        {{ action.label | translate }}
      </button>
    </ng-container>
  </ng-container>
</mat-menu>

<ng-template #noIcon> <span class="no-icon"></span></ng-template>
