export function createCaseInsensitiveSortWithCustomRules(
  rules: Map<string, (item: any, prop: any) => void> = new Map(),
): any {
  return (item, prop) => {
    const fun = rules.get(prop);
    if (fun) {
      return fun(item, prop);
    }

    if (typeof item[prop] === 'string') {
      return item[prop].toLowerCase();
    }

    return item[prop];
  };
}
