import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CurveChartPoint, CurveChartPointList } from './curve-chart.model';

@Injectable({
  providedIn: 'root',
})
export class CurveChartService {
  constructor(
    private appConfigService: AppConfigService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
  ) {}

  getCurveChartData(projectId: number, functionalHierarchyId: number, atDate: string): Observable<CurveChartPoint[]> {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('functionalHierarchyId', functionalHierarchyId.toString())
      .set('atDate', atDate);
    const baseUrl = this.appConfigService.settings.api.endpoints.baseUrl;
    const curveChartDataUrl = this.appConfigService.settings.api.endpoints.curveChartData;
    return this.httpClient.get<CurveChartPointList>(baseUrl + curveChartDataUrl, { params }).pipe(
      catchError((err) => {
        showHttpErrorResponse(this.notificationService, err);
        return [];
      }),
      map((ccList) => {
        return ccList.calculationCurvePoints;
      }),
    );
  }
}
