import { ClipboardModule } from '@angular/cdk/clipboard';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyNativeDateModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DiagramAllModule } from '@syncfusion/ej2-angular-diagrams';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {
  EditService,
  ExcelExportService,
  ReorderService,
  RowDDService,
  SortService,
  ToolbarService,
  TreeGridModule,
} from '@syncfusion/ej2-angular-treegrid';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AgGridModule } from 'ag-grid-angular';
import { AngularSplitModule } from 'angular-split';
import { NgChartsModule } from 'ng2-charts';
import { SharedControlsModule } from '../shared-controls/shared-controls.module';
import { ChangeRequestSubmitComponent } from './change-request-cart/change-request-submit/change-request-submit.component';
import { RequestDeliverablesComponent } from './change-request-cart/change-request-submit/cr-deliverables/request-deliverables.component';
import { CrImpactComponent } from './change-request-cart/change-request-submit/cr-impact/cr-impact.component';
import { CrMoveDetailsComponent } from './change-request-cart/change-request-submit/cr-move-details/cr-move-details.component';
import { CrScheduleComponent } from './change-request-cart/change-request-submit/cr-schedule/cr-schedule.component';
import { SubmitRequestSnackBarComponent } from './change-request-cart/submit-request-snack-bar/submit-request-snack-bar.component';
import { ChangeRequestDetailsComponent } from './common/navigation-tabs/change-request-list/change-request-details/change-request-details.component';
import { ChangeRequestListComponent } from './common/navigation-tabs/change-request-list/change-request-list.component';
import { CrFiltersComponent } from './common/navigation-tabs/change-request-list/cr-filters/cr-filters.component';
import { CrHistoryComponent } from './common/navigation-tabs/change-request-list/cr-history/cr-history.component';
import { CrRejectPromptComponent } from './common/navigation-tabs/change-request-list/cr-reject-prompt/cr-reject-prompt.component';
import { CurveChartComponent } from './common/navigation-tabs/curve/curve-chart.component';
import { DeliverablePercentageCompleteComponent } from './common/navigation-tabs/deliverable-percentage-complete/deliverable-percentage-complete.component';
import { DeliverablesFiltersComponent } from './common/navigation-tabs/deliverables/deliverables-filters/deliverables-filters.component';
import { DeliverablesListStateModule } from './common/navigation-tabs/deliverables/deliverables-list-state/deliverables-list-state.module';
import { DeliverablesListComponent } from './common/navigation-tabs/deliverables/deliverables-list/deliverables-list.component';
import { DeliverablesRouteComponent } from './common/navigation-tabs/deliverables/deliverables-route/deliverables-route.component';
import { BudgedSectionComponent } from './common/navigation-tabs/detail/budged-section/budged-section.component';
import { InfoSectionComponent } from './common/navigation-tabs/detail/info-section/info-section.component';
import { IntentSectionComponent } from './common/navigation-tabs/detail/intent-section/intent-section.component';
import { PermissionsHeaderComponent } from './common/navigation-tabs/permissions/permissions-header/permissions-header.component';
import { PermissionsTableComponent } from './common/navigation-tabs/permissions/permissions-table/permissions-table.component';
import { PermissionsComponent } from './common/navigation-tabs/permissions/permissions.component';
import { AddDeliverableComponent } from './deliverable/add-deliverable/add-deliverable.component';
import { DeliverableCrUpdateSectionComponent } from './deliverable/deliverable-cr-update-section/deliverable-cr-update-section.component';
import { DeliverableDetailComponent } from './deliverable/deliverable-detail/deliverable-detail.component';
import { DeliverableHeaderComponent } from './deliverable/deliverable-header/deliverable-header.component';
import { DeliverableHistoryComponent } from './deliverable/deliverable-history/deliverable-history.component';
import { DeliverableSoftUpdateSectionComponent } from './deliverable/deliverable-soft-update-section/deliverable-soft-update-section.component';
import { DeliverableStateModule } from './deliverable/deliverable-state/deliverable-state.module';
import { FunctionChangeRequestsComponent } from './function/function-change-requests/function-change-requests.component';
import { FunctionCurveComponent } from './function/function-curve/function-curve.component';
import { FunctionDeliverablesComponent } from './function/function-deliverables/function-deliverables.component';
import { FunctionDetailComponent } from './function/function-detail/function-detail.component';
import { FunctionHistoryComponent } from './function/function-history/function-history.component';
import { FunctionScopeComponent } from './function/function-scope/function-scope.component';
import { DifferenceDialogComponent } from './history/difference-dialog/difference-dialog.component';
import { HistoryListComponent } from './history/history-list/history-list.component';
import { BulkActionSubmissionComponent } from './home-page/bulk-action-submission/bulk-action-submission.component';
import { ChangeRequestSubmissionTableComponent } from './home-page/change-request-submission-table/change-request-submission-table.component';
import { ChangeRequestsPendingApprovalComponent } from './home-page/change-requests-pending-approval/change-requests-pending-approval.component';
import { FavoritePackagesComponent } from './home-page/favorite-packages/favorite-packages.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PartialSuccessComponent } from './home-page/partial-success/partial-success.component';
import { RecentProjectsComponent } from './home-page/recent-projects/recent-projects.component';
import { NodeHeaderComponent } from './node-header/node-header.component';
import { ShareComponent } from './node-header/share/share.component';
import { CurveChartViewComponent } from './package-view/curve-chart-view/curve-chart-view.component';
import { DeliverableHeaderViewComponent } from './package-view/deliverable-header-view/deliverable-header-view.component';
import { DeliverableSoftUpdateSectionViewComponent } from './package-view/deliverable-soft-update-section-view/deliverable-soft-update-section-view.component';
import { DeliverableViewDetailComponent } from './package-view/deliverable-view-detail/deliverable-view-detail.component';
import { DeliverablesViewRouteComponent } from './package-view/deliverables-view-route/deliverables-view-route.component';
import { NodeHeaderViewComponent } from './package-view/node-header-view/node-header-view.component';
import { PackageCurveViewComponent } from './package-view/package-curve/package-curve-view.component';
import { PackageDeliverableViewComponent } from './package-view/package-deliverable/package-deliverable-view.component';
import { PackageDetailViewComponent } from './package-view/package-detail-view/package-detail-view.component';
import { RightSideViewPaneComponent } from './package-view/right-side-view-pane/right-side-view-pane.component';
import { PackageChangeRequestsComponent } from './package/package-change-requests/package-change-requests.component';
import { PackageCurveComponent } from './package/package-curve/package-curve.component';
import { PackageDeliverablesComponent } from './package/package-deliverables/package-deliverables.component';
import { PackageDetailComponent } from './package/package-detail/package-detail.component';
import { AddPackageRelationshipComponent } from './package/package-detail/package-relationships/add-package-relationship/add-package-relationship.component';
import { PackageRelationshipsTableComponent } from './package/package-detail/package-relationships/package-relationships-table/package-relationships-table.component';
import { PackageRelationshipsComponent } from './package/package-detail/package-relationships/package-relationships.component';
import { RelationshipTypeIconComponent } from './package/package-detail/package-relationships/relationship-type-icon/relationship-type-icon.component';
import { RelationshipTypeOptionComponent } from './package/package-detail/package-relationships/relationship-type-option/relationship-type-option.component';
import { PackageHistoryComponent } from './package/package-history/package-history.component';
import { PackageScopeComponent } from './package/package-scope/package-scope.component';
import { ChooseSourceStepComponent } from './project-import/choose-source-step/choose-source-step.component';
import { IncludeDataDialogComponent } from './project-import/choose-source-step/include-data-dialog/include-data-dialog.component';
import { ImportConfirmationStepComponent } from './project-import/import-confirmation-step/import-confirmation-step.component';
import { ProjectImportComponent } from './project-import/project-import.component';
import { AgCellChangesLinkComponent } from './project-import/review-import-step/ag-custom-components/ag-cell-changes-link/ag-cell-changes-link.component';
import { AgCellFeedbackComponent } from './project-import/review-import-step/ag-custom-components/ag-cell-feedback/ag-cell-feedback.component';
import { AgCellSimpleComponent } from './project-import/review-import-step/ag-custom-components/ag-cell-simple/ag-cell-simple.component';
import { CustomLoadingOverlayComponent } from './project-import/review-import-step/ag-custom-components/ag-custom-overlay.component';
import { CustomNoRowsOverlayComponent } from './project-import/review-import-step/ag-custom-components/ag-no-rows.component';
import { ChangeRequestDialogComponent } from './project-import/review-import-step/change-request-dialog/change-request-dialog.component';
import { NoChangesDialogComponent } from './project-import/review-import-step/no-changes-dialog/no-changes-dialog.component';
import { ReviewImportBreakdownComponent } from './project-import/review-import-step/review-import-breakdown/review-import-breakdown.component';
import { ReviewImportChangeDetailsComponent } from './project-import/review-import-step/review-import-change-details/review-import-change-details.component';
import { ReviewImportFiltersComponent } from './project-import/review-import-step/review-import-filters/review-import-filters.component';
import { ReviewImportStepComponent } from './project-import/review-import-step/review-import-step.component';
import { ProjectManagementRoutingModule } from './project-management-routing.module';
import { ProjectManagementComponent } from './project-management.component';
import { AddProjectComponent } from './project/add-project/add-project.component';
import { ProjectChangeRequestsComponent } from './project/project-change-requests/project-change-requests.component';
import { ProjectCurveComponent } from './project/project-curve/project-curve.component';
import { ProjectDeliverablesComponent } from './project/project-deliverables/project-deliverables.component';
import { ProjectDetailComponent } from './project/project-detail/project-detail.component';
import { RightSidePaneComponent } from './right-side-pane/right-side-pane.component';
import { SignalRService } from './signalr/signalr.service';
import { TreeActionMoveDeliverableComponent } from './tree-navigation/tree-action-move-deliverable/tree-action-move-deliverable.component';
import { TreeActionMovePackageCrComponent } from './tree-navigation/tree-action-move-package-cr/tree-action-move-package-cr.component';
import { TreeActionMovePackageComponent } from './tree-navigation/tree-action-move-package/tree-action-move-package.component';
import { TreeActionMoveSubfunctionCRComponent } from './tree-navigation/tree-action-move-subfunction-cr/tree-action-move-subfunction-cr.component';
import { TreeActionMoveSubfunctionComponent } from './tree-navigation/tree-action-move-subfunction/tree-action-move-subfunction.component';
import { TreeActionPublishNodeCrComponent } from './tree-navigation/tree-action-publish-package/tree-action-publish-package.component';
import { TreeActionAddComponent } from './tree-navigation/tree-actions-menu/tree-action-add/tree-action-add.component';
import { TreeActionCrSubmitComponent } from './tree-navigation/tree-actions-menu/tree-action-cr-submit/tree-action-cr-submit.component';
import { TreeActionDeleteSubmitComponent } from './tree-navigation/tree-actions-menu/tree-action-delete-submit/tree-action-delete-submit.component';
import { TreeActionsMenuComponent } from './tree-navigation/tree-actions-menu/tree-actions-menu.component';
import { TreeNavigationComponent } from './tree-navigation/tree-navigation.component';
import { UploadTreeStructureComponent } from './tree-navigation/upload-tree-structure/upload-tree-structure.component';
import { VisualizationContainerComponent } from './visualization/visualization-container.component';
import { VisualizationComponent } from './visualization/visualization.component';

@NgModule({
  declarations: [
    AgCellSimpleComponent,
    AgCellFeedbackComponent,
    AgCellChangesLinkComponent,
    CustomNoRowsOverlayComponent,
    CustomLoadingOverlayComponent,
    HomePageComponent,
    TreeNavigationComponent,
    RightSidePaneComponent,
    ProjectDetailComponent,
    ProjectCurveComponent,
    ProjectDeliverablesComponent,
    ProjectChangeRequestsComponent,
    FunctionDetailComponent,
    FunctionCurveComponent,
    FunctionScopeComponent,
    FunctionDeliverablesComponent,
    FunctionChangeRequestsComponent,
    PackageDetailComponent,
    PackageCurveComponent,
    PackageScopeComponent,
    PackageDeliverablesComponent,
    PackageChangeRequestsComponent,
    DeliverableDetailComponent,
    NodeHeaderComponent,
    BudgedSectionComponent,
    InfoSectionComponent,
    IntentSectionComponent,
    DeliverablesListComponent,
    DeliverableHeaderComponent,
    SubmitRequestSnackBarComponent,
    ChangeRequestSubmitComponent,
    DeliverablePercentageCompleteComponent,
    CrImpactComponent,
    CrScheduleComponent,
    RequestDeliverablesComponent,
    DeliverablePercentageCompleteComponent,
    AddDeliverableComponent,
    DeliverableSoftUpdateSectionComponent,
    DeliverableCrUpdateSectionComponent,
    ChangeRequestListComponent,
    CurveChartComponent,
    ChangeRequestDetailsComponent,
    CrHistoryComponent,
    CrRejectPromptComponent,
    ChangeRequestsPendingApprovalComponent,
    RecentProjectsComponent,
    ProjectManagementComponent,
    PermissionsComponent,
    PermissionsHeaderComponent,
    PermissionsTableComponent,
    DeliverableHistoryComponent,
    DifferenceDialogComponent,
    DeliverablesRouteComponent,
    HistoryListComponent,
    PackageHistoryComponent,
    FunctionHistoryComponent,
    TreeActionsMenuComponent,
    DeliverablesFiltersComponent,
    TreeActionCrSubmitComponent,
    TreeActionDeleteSubmitComponent,
    CrFiltersComponent,
    FavoritePackagesComponent,
    PackageRelationshipsComponent,
    TreeActionAddComponent,
    PackageRelationshipsTableComponent,
    RelationshipTypeIconComponent,
    RelationshipTypeOptionComponent,
    AddPackageRelationshipComponent,
    UploadTreeStructureComponent,
    AddProjectComponent,
    VisualizationComponent,
    VisualizationContainerComponent,
    TreeActionMovePackageCrComponent,
    TreeActionMovePackageComponent,
    TreeActionMoveDeliverableComponent,
    TreeActionMoveSubfunctionComponent,
    TreeActionMoveSubfunctionCRComponent,
    CrMoveDetailsComponent,
    BulkActionSubmissionComponent,
    ShareComponent,
    PartialSuccessComponent,
    ChangeRequestSubmissionTableComponent,
    PackageCurveViewComponent,
    PackageDeliverableViewComponent,
    PackageDetailViewComponent,
    RightSideViewPaneComponent,
    DeliverablesViewRouteComponent,
    DeliverableViewDetailComponent,
    NodeHeaderViewComponent,
    DeliverableHeaderViewComponent,
    DeliverableSoftUpdateSectionViewComponent,
    CurveChartViewComponent,
    TreeActionPublishNodeCrComponent,
    ProjectImportComponent,
    ChooseSourceStepComponent,
    ReviewImportBreakdownComponent,
    ReviewImportChangeDetailsComponent,
    ReviewImportStepComponent,
    ImportConfirmationStepComponent,
    ReviewImportFiltersComponent,
    IncludeDataDialogComponent,
    NoChangesDialogComponent,
    ChangeRequestDialogComponent,
  ],
  imports: [
    AgGridModule,
    ClipboardModule,
    BrowserModule,
    MatDividerModule,
    MatLegacyButtonModule,
    MatLegacyTabsModule,
    MatIconModule,
    ProjectManagementRoutingModule,
    AngularSplitModule,
    TranslateModule,
    TreeGridModule,
    DateRangePickerModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacySelectModule,
    MatLegacyTooltipModule,
    MatLegacyRadioModule,
    MatLegacyDialogModule,
    DeliverableStateModule,
    DeliverablesListStateModule,
    MatDatepickerModule,
    MatLegacyNativeDateModule,
    MatLegacyInputModule,
    MatLegacyMenuModule,
    MatExpansionModule,
    NgChartsModule,
    SharedControlsModule,
    DiagramAllModule,
    ToolbarModule,
  ],
  exports: [],
  providers: [
    ToolbarService,
    ExcelExportService,
    SortService,
    EditService,
    ReorderService,
    RowDDService,
    {
      provide: APP_INITIALIZER,
      useFactory: signalRInitializer,
      deps: [SignalRService],
      multi: true,
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
})
export class ProjectManagementModule {}
export function signalRInitializer(service: SignalRService): () => Promise<boolean> {
  return () => service.init();
}
