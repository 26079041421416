import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { AppState } from 'src/app/root-store/app-state';

@Component({
  selector: 'app-package-curve-view',
  templateUrl: './package-curve-view.component.html',
  styleUrls: ['./package-curve-view.component.scss'],
})
export class PackageCurveViewComponent implements OnDestroy {
  entityType = EntityTypes.package;
  isDraft: boolean;

  private subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
