import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { User } from 'src/app/common-models/user.model';
import { AppState } from 'src/app/root-store/app-state';
import { getCurrentUser, getUserLastPortfolio } from 'src/app/root-store/root-store.selector';
import { showHttpErrorResponse } from 'src/app/shared/display-error.helper';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectService } from 'src/app/shared/services/project-service';
import { UsersService } from 'src/app/shared/services/users.service';
import { Project } from '../../../common-models/project.model';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
})
export class AddProjectComponent implements OnInit {
  form: UntypedFormGroup;
  curveProfiles$: Observable<CurveProfile[]>;
  hierarchyDepths = [3, 4];
  defaultHierarchyLevel = 4;
  subscription: Subscription;
  users$: Observable<User[]>;
  defaultCurveProfileValue = 2;
  portfolioId: number;

  dataSource: (portfolio: number, term: string) => Observable<User[]>;

  @ViewChild('managerInput') managerInput: ElementRef;

  projectManager = new UntypedFormControl({ displayName: '', userId: null }, Validators.required);

  userInput$ = new Subject<string>();
  isSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private dialogRef: MatLegacyDialogRef<AddProjectComponent>,
    private curveProfilesService: CurveProfileService,
    private usersService: UsersService,
    private projectService: ProjectService,
    private store$: Store<AppState>,
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.dataSource = this.usersService.getUsersForPortfolioSearch.bind(this.usersService);
    this.curveProfiles$ = this.curveProfilesService.curveProfiles$;
    this.initForm();

    this.store$.select(getUserLastPortfolio).subscribe((portfolioId) => (this.portfolioId = portfolioId));
  }

  onSubmit(): void {
    if (!this.projectManager?.value?.userId) {
      this.projectManager.setErrors({ incorrect: true });
    }
    if (this.form.invalid || this.projectManager.invalid) {
      return;
    }

    const project = new Project();
    project.title = this.form.get('title').value;
    project.code = this.form.get('code').value;
    project.curveProfileId = this.form.get('curveProfile').value;
    project.projectManagerId = this.projectManager.value.userId;
    project.hierarchyDepth = this.form.get('hierarchyDepth').value;

    this.store$
      .select(getCurrentUser)
      .pipe(
        take(1),
        tap(() => this.isSubmitting$.next(true)),
        switchMap((user) => this.projectService.addProject(project, user.lastPortfolioId)),
        catchError((err: HttpErrorResponse) => {
          showHttpErrorResponse(this.notificationService, err);
          this.isSubmitting$.next(false);
          return of(null);
        }),
      )
      .subscribe((projectId) => {
        this.router.navigate(['project-management/', projectId]);
        this.dialogRef.close();
      });
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
        code: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        curveProfile: new UntypedFormControl(this.defaultCurveProfileValue, Validators.required),
        hierarchyDepth: new UntypedFormControl(this.defaultHierarchyLevel, Validators.required),
      },
      {
        updateOn: 'submit',
      },
    );
  }
}
