<mat-form-field class="lang-chooser">
  <mat-select
    [(value)]="currentLocale"
    (selectionChange)="translateSite($event.value)"
    panelClass="lang-chooser-select"
  >
    <mat-option *ngFor="let locale of localesList" [value]="locale.code">
      {{ locale.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
