import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { CurveProfile } from 'src/app/common-models/curve-profile.model';
import { ProgressWorkflow } from 'src/app/common-models/progress-workflow.model';
import * as currentContextFunctionSelectors from 'src/app/project-management/change-request-cart/change-request-cart-state/current-context-function/current-context-function.selectors';
import { TreeSelectors } from 'src/app/project-management/tree-navigation/tree-state/tree.selectors';
import { AppState } from 'src/app/root-store/app-state';
import * as projectSelectors from 'src/app/shared-state/project/project.selectors';
import { CurveProfileService } from 'src/app/shared/services/curve-profile.service';
import { v4 as uuidv4 } from 'uuid';
import {
  ChangeRequestDeliverable,
  RequestType,
} from '../../change-request-cart/change-request-cart-state/cart-item.model';
import { DeliverablesService } from '../../common/navigation-tabs/deliverables/deliverables.service';

@Component({
  selector: 'app-add-deliverable',
  templateUrl: './add-deliverable.component.html',
  styleUrls: ['./add-deliverable.component.scss'],
})
export class AddDeliverableComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  curveProfiles$: Observable<CurveProfile[]>;
  projectWorkflows$: Observable<ProgressWorkflow[]>;
  private subscription = new Subscription();
  private currentContextFunction: number;
  private packageFunctionalHierarchyId: number;
  private packageCode: string;
  private defaultCurveProfile: string;

  constructor(
    private deliverablesService: DeliverablesService,
    private curveProfilesService: CurveProfileService,
    private store$: Store<AppState>,
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: { title: string },
    private dialogRef: MatLegacyDialogRef<AddDeliverableComponent>,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.curveProfiles$ = this.curveProfilesService.curveProfiles$;

    const defaultCurveProfileSubscription = this.store$
      .select(projectSelectors.getProjectDefaultCurveProfileCode)
      .subscribe((defaultCurveProfile) => {
        this.defaultCurveProfile = defaultCurveProfile;
      });
    this.subscription.add(defaultCurveProfileSubscription);

    this.subscription.add(
      this.store$
        .select(currentContextFunctionSelectors.selectCurrentContextFunction)
        .subscribe((x) => (this.currentContextFunction = x)),
    );

    this.projectWorkflows$ = this.deliverablesService.getProjectWorkflowsForCurrentProject();

    this.subscription.add(
      this.store$.select(TreeSelectors.getCurrentTreeNode).subscribe((p) => {
        this.packageCode = p.code;
        this.packageFunctionalHierarchyId = p.functionalHierarchyId;
      }),
    );

    this.fillForms();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    const progressWorkflow =
      this.form.get('progressWorkflow').value === 0 ? null : this.form.get('progressWorkflow').value;
    const crd = new ChangeRequestDeliverable();
    crd.deliverableId = uuidv4();
    crd.functionId = this.currentContextFunction;
    crd.packageFunctionalHierarchyId = this.packageFunctionalHierarchyId;
    crd.documentNumber = this.form.get('documentNumber').value;
    crd.packageCode = this.packageCode;
    crd.request = RequestType.New;
    crd.newCost = this.form.get('budgetCost').value;
    crd.newHours = this.form.get('budgetHours').value;
    crd.newCurveProfileCode = this.form.get('curveProfile').value;
    crd.progressWorkflowId = progressWorkflow;
    crd.title = this.form.get('title').value;

    this.dialogRef.close(crd);
  }

  showInvalidState(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(
      {
        title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
        documentNumber: new UntypedFormControl(null, Validators.maxLength(100)),
        curveProfile: new UntypedFormControl(null, Validators.required),
        progressWorkflow: new UntypedFormControl(),
        budgetHours: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
        budgetCost: new UntypedFormControl(null, [Validators.min(0)]),
      },
      {
        updateOn: 'submit',
      },
    );
  }

  private fillForms(): void {
    this.form.patchValue(
      {
        curveProfile: this.defaultCurveProfile,
      },
      { emitEvent: false },
    );
  }
}
