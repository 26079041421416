export const shortDateFormat = 'dd/MM/yy';
export const shortDateFormatWithFullYear = 'dd/MM/yyyy';
export const monthDateFormat = 'MMM/yy';
export const dayDateFormat = 'dd/MMM/yy';
export const dashDayDateFormat = 'dd-MMM-yy';
export const dashDayDateFormatWithFullYear = 'dd-MMM-yyyy';
export const longDateFormat = 'MMM dd, yyyy';
export const ISOdateFormat = 'yyyy-MM-dd';
export const timeFormat = 'HH:mm:ss';
export const timezoneUTC = 'UTC';
