<form [formGroup]="form" class="package-selection" autocomplete="off" (ngSubmit)="onSubmit()">
  <div class="search-box">
    <ng-select
      class="package-search"
      [items]="packages$ | async"
      bindLabel="displayName"
      placeholder="{{ 'relationships.typePackageCodeOrName' | translate }}"
      [minTermLength]="3"
      formControlName="selectedPackages"
      [hideSelected]="true"
      [clearable]="false"
      [multiple]="true"
      [loading]="loading"
      [typeahead]="packageInput$"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label package-pill">{{ item.displayName }}</span>
        <span class="ng-value-icon right package-clear" (click)="clear(item)" aria-hidden="true">×</span>
      </ng-template>
    </ng-select>

    <mat-select
      class="orms-select package-search-type-select"
      formControlName="type"
      panelClass="package-search-type-select"
      [disableOptionCentering]="true"
    >
      <mat-select-trigger>
        <app-relationship-type-option [type]="form.value.type"></app-relationship-type-option>
      </mat-select-trigger>
      <mat-option [value]="RelationshipType.Predecessor">
        <app-relationship-type-option [type]="RelationshipType.Predecessor"></app-relationship-type-option>
      </mat-option>
      <mat-option [value]="RelationshipType.Successor">
        <app-relationship-type-option [type]="RelationshipType.Successor"></app-relationship-type-option>
      </mat-option>
      <mat-option [value]="RelationshipType.Related">
        <app-relationship-type-option [type]="RelationshipType.Related"></app-relationship-type-option>
      </mat-option>
    </mat-select>
  </div>
  <button class="orms-button orms-button-primary" type="submit">
    {{ 'relationships.addRelationship' | translate }}
  </button>
</form>
