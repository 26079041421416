<table
  class="permissions-table"
  mat-table
  [dataSource]="dataSource"
  attr.aria-label="{{ 'permissions.permissions' | translate }}"
>
  <ng-container matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'permissions.name' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
  </ng-container>

  <ng-container matColumnDef="permissionType">
    <th mat-header-cell *matHeaderCellDef scope="col">
      {{ 'permissions.access' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.permissionType }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef scope="col"></th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-icon-button
        [matMenuTriggerFor]="permissionsMenu"
        class="menu"
        [matMenuTriggerData]="{ permission: element }"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<p class="no-data" *ngIf="permissions && !permissions.length">
  {{ 'permissions.noPermissions' | translate }}
</p>

<app-spinner *ngIf="!permissions"></app-spinner>

<mat-menu #permissionsMenu="matMenu" class="orms-menu">
  <ng-template matMenuContent let-permission="permission">
    <button type="button" mat-menu-item (click)="revokePermission.emit(permission)">
      <img src="/assets/icons/icon-delete.svg" alt="{{ 'permissions.remove' | translate }}" />
      {{ 'permissions.remove' | translate }}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="changePermissionMenu" [matMenuTriggerData]="{ permission: permission }">
      <img src="/assets/icons/icon-edit.svg" alt="{{ 'permissions.changeAccess' | translate }}" />
      {{ 'permissions.changeAccess' | translate }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #changePermissionMenu="matMenu" class="orms-menu">
  <ng-template matMenuContent let-permission="permission">
    <button
      mat-menu-item
      (click)="
        changePermission.emit({
          permission: permission,
          permissionType: PermissionType.canRead
        })
      "
    >
      {{ 'permissions.canRead' | translate }}
    </button>
    <button
      mat-menu-item
      (click)="
        changePermission.emit({
          permission: permission,
          permissionType: PermissionType.canUpdate
        })
      "
    >
      {{ 'permissions.canUpdate' | translate }}
    </button>
    <button
      mat-menu-item
      (click)="
        changePermission.emit({
          permission: permission,
          permissionType: PermissionType.canAdminister
        })
      "
    >
      {{ 'permissions.canAdminister' | translate }}
    </button>
  </ng-template>
</mat-menu>
