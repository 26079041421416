import * as fromRouter from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

import { EntityTypes } from 'src/app/common-models/node-types/node-types';
import { CurrentUser } from '../shared/current-user/currentUser';
import { AppState, RouterStateUrl } from './app-state';
import { CurrentUserState } from './root-store.reducer';

export const getRouterState = (state: AppState) => state.router;
export const getCurrentUserState = (state: AppState) => state.currentUser;

export const getIdSelector = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>): number => {
    if (!state) {
      return null;
    }

    const num = Number(state.state.params.id);
    if (num) {
      return num;
    } else {
      return state.state.params.id;
    }
  },
);

export const isProjectManagementRouteWithoutId = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>): boolean | null => {
    if (state?.state?.url.includes('project-management') && state?.state?.params?.id === undefined) {
      return true;
    }

    return false;
  },
);

export const getRouteType = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>): EntityTypes => {
    if (!state || !state.state.data) {
      return null;
    }
    return state.state.data.type ?? null;
  },
);

export const getProjectIdSelector = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>): number => {
    if (!state) {
      return null;
    }
    const num = Number(state.state.params.projectId);
    if (num) {
      return num;
    } else {
      return state.state.params.projectId;
    }
  },
);

export const getShareIdSelector = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>): { hasState: boolean; id: string } => {
    if (!state) {
      return { hasState: false, id: null };
    }

    return { hasState: true, id: state.state?.params?.shareId };
  },
);

export const getCurrentUser = createSelector(getCurrentUserState, (state: CurrentUserState): CurrentUser => {
  return state.profile;
});

export const canUserManagePortfolio = createSelector(getCurrentUserState, (state: CurrentUserState): boolean => {
  return state.canManagePortfolio;
});

export const getUserLastPortfolio = createSelector(getCurrentUserState, (state: CurrentUserState): number => {
  return state.profile?.lastPortfolioId;
});

export const getIncludeCancelledItems = createSelector(getCurrentUserState, (state: CurrentUserState): boolean => {
  return state.includeCancelledItems;
});
