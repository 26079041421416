import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { getPriorities } from 'src/app/common-models/priority.model';
import { ProgressWorkflow } from 'src/app/common-models/progress-workflow.model';
import { UrlRegex } from 'src/app/shared-controls/constants';
import { DatepickerActionsComponent } from 'src/app/shared-controls/datepicker-actions/datepicker-actions.component';
import {
  disableFormFieldsBaseOnPermissions,
  shadowFormUpdateConfig,
} from 'src/app/shared-controls/shadow-input/shadow-input.helper';
import { AppConfigService } from 'src/app/shared/services/app.config.service';
import { DeliverableStatus } from '../../../shared-controls/status/statusCodesMap';
import { DeliverablePercentageCompleteComponent } from '../../common/navigation-tabs/deliverable-percentage-complete/deliverable-percentage-complete.component';
import { DeliverableDetail } from '../../common/navigation-tabs/deliverables/DeliverableDetail';

@Component({
  selector: 'app-deliverable-soft-update-section-view',
  templateUrl: './deliverable-soft-update-section-view.component.html',
  styleUrls: ['./deliverable-soft-update-section-view.component.scss'],
})
export class DeliverableSoftUpdateSectionViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  deliverableDetail: DeliverableDetail;

  Status = DeliverableStatus;
  init: any;
  isCompleteShown = false;
  isNotesFiledEditable: boolean;
  projectWorkflows$: Observable<ProgressWorkflow[]>;
  priorities: Map<number, string>;

  form: UntypedFormGroup;
  workflowStepName$ = new Subject<string>();
  reloadSteps$ = new Subject<number>();
  datepickerActionsComponent = DatepickerActionsComponent;
  isURLButtonEnabled = false;
  private subscription = new Subscription();

  constructor(
    private appConfigService: AppConfigService,
    private translateService: TranslateService,
    private dialog: MatLegacyDialog,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.priorities = getPriorities(this.translateService);

    this.init = {
      ...this.appConfigService.settings.richTextConfig.defaultEditorOptions,
      ...this.appConfigService.settings.richTextConfig.intentInitOptions,
      placeholder: this.translateService.instant('deliverable.notes'),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('deliverableDetail' in changes) {
      this.isCompleteShown = !this.deliverableDetail.progressWorkflowId;

      this.fillForms();
      disableFormFieldsBaseOnPermissions(this.form, this.isEditable.bind(this));
      this.isNotesFiledEditable = false;
      this.updateUrlButtonEnabledStatus();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showProgressUpdate(): void {
    this.dialog.open(DeliverablePercentageCompleteComponent, {
      data: this.deliverableDetail,
      panelClass: 'orms-deliverable-progress-dialog',
    });
  }

  private updateUrlButtonEnabledStatus(): void {
    this.isURLButtonEnabled = this.form.get('url').value && this.form.get('url').valid;
  }

  isEditable(name: string): boolean {
    return false;
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      notes: new UntypedFormControl(null, {
        updateOn: 'blur',
      }),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl(),
      progressWorkflowId: new UntypedFormControl(),
      progressWorkflowStatusId: new UntypedFormControl(),
      priority: new UntypedFormControl(),
      percentageCompleted: new UntypedFormControl(null, {
        validators: [Validators.maxLength(2), Validators.min(0), Validators.max(100)],
        updateOn: 'blur',
      }),
      controlledDocument: new UntypedFormControl(null, {
        updateOn: 'change',
      }),
      url: new UntypedFormControl(null, {
        validators: [Validators.pattern(UrlRegex)],
        updateOn: 'blur',
      }),
      onHold: new UntypedFormControl(),
      holdReason: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.minLength(1)],
        updateOn: 'blur',
      }),
    });
  }

  private fillForms(): void {
    const {
      progressWorkflowId,
      progressWorkflowStatusId,
      percentageCompleted,
      controlledDocument,
      url,
      onHold,
      holdReason,
      priority,
      startDate,
      endDate,
      notes,
    } = this.deliverableDetail;

    this.form.patchValue(
      {
        notes: notes || null,
        progressWorkflowId: progressWorkflowId || 0,
        progressWorkflowStatusId: progressWorkflowStatusId || 0,
        percentageCompleted: percentageCompleted || 0,
        controlledDocument: controlledDocument || false,
        priority: priority || 0,
        url: url || null,
        onHold: this.form.get('onHold').value ? this.form.get('onHold').value : onHold || false,
        holdReason: this.form.get('holdReason').value ? this.form.get('holdReason').value : holdReason || null,
        startDate: startDate || null,
        endDate: endDate || null,
      },
      { ...shadowFormUpdateConfig },
    );
  }
}
